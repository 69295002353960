import React from 'react'

export default function SearchCardView(props) {
    const {searchResult, handleShow} = props;

  return (
    <section className="catagory_section">
    {searchResult.length>0 &&<div className="catagory_container">
      <div className="container ">
        <div className="row">
        {searchResult.map((item,index)=>{return(
          <div key={index} className="col-sm-6 col-md-4 card-deck">
            <div className="box card" onClick={event=>handleShow(event, item)}>
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>{item.accessionNo}</h5>
                <p><i className='fa fa-book'></i> {item.bookTitle}</p>
                <p><i className="fa fa-user" aria-hidden="true"></i> {item.bookAuthor}</p>
              </div>
            </div>
            </div>)})}
            </div>
        </div>
    </div>}
    </section>
  )
}
