import React, { useEffect, useState } from 'react';
import { books_category } from '../ProData';
import {Helmet} from 'react-helmet-async';

export default function BookCategories() {
  const [booksCategory, setBooksCategory] = useState([]);

  useEffect(()=>{
    const tempBooks=books_category.filter((item)=>item.categoryDisplay==="Yes");
    setBooksCategory(tempBooks);
  })
  return (
    <div>
          <Helmet>
      <title>Book Categories - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Book Categories - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Book Categories - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        <section className="catagory_section layout_padding">
    <div className="catagory_container">
      <div className="container ">
        <div className="heading_container heading_center">
          <h2>
            Books Categories
          </h2>
          <p>
          Our library provides a wide range of reading materials, with 65 distinct book categories and over 2,000 specific classifications.
          </p>
        </div>
        <div className="row">
          {booksCategory.map((item, index)=><div className="col-sm-6 col-md-4" key={index}>
            <div className="box ">
              <div className="img-box">
                <img src={item.categoryImage} alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  {item.name}
                </h5>
                <p>
                  {item.categoryDesc}
                </p>
              </div>
            </div>
          </div>)}
          {/*<div className="col-sm-6 col-md-4 ">
            <div className="box ">
              <div className="img-box">
                <img src="images/cat2.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  Science
                </h5>
                <p>
                  fact that a reader will be distracted by the readable content of a page when looking at its layout.
                  The
                  point of using
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 ">
            <div className="box ">
              <div className="img-box">
                <img src="images/cat3.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  History
                </h5>
                <p>
                  fact that a reader will be distracted by the readable content of a page when looking at its layout.
                  The
                  point of using
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 ">
            <div className="box ">
              <div className="img-box">
                <img src="images/cat4.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  Biography
                </h5>
                <p>
                  fact that a reader will be distracted by the readable content of a page when looking at its layout.
                  The
                  point of using
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 ">
            <div className="box ">
              <div className="img-box">
                <img src="images/cat5.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  Adventure
                </h5>
                <p>
                  fact that a reader will be distracted by the readable content of a page when looking at its layout.
                  The
                  point of using
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 ">
            <div className="box ">
              <div className="img-box">
                <img src="images/cat6.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  Fantasy
                </h5>
                <p>
                  fact that a reader will be distracted by the readable content of a page when looking at its layout.
                  The
                  point of using
                </p>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}
