import React from 'react'
import BooksForm from './BooksForm'

export default function BooksPreview(props) {
    const {bookDetails, setBookDetails,viewOnly, handleClose, dispMsg}=props;
  return (
    <div>
  <div style={{backgroundColor:"#063547", borderRadius:"25px"}}>
      <h1>{dispMsg}</h1>
      </div>
    <BooksForm bookDetails={bookDetails} setBookDetails={setBookDetails} viewOnly={viewOnly} handleClose={handleClose}/>
</div>
  )
}
