import React from 'react'

export default function FooterInfo() {
  return (
    <div>
        <section className="info_section layout_padding2">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 info-col">
          <div className="info_detail">
            <h4>
              About Us
            </h4>
            <p>
            A digital library is a universe of knowledge at your fingertips.
            </p>
            <div className="info_social">
              <a href="">
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 info-col">
          <div className="info_contact">
            <h4>
              Address
            </h4>
            <div className="contact_link_box">
              <a href="">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>
                  Raj Niwas Merg, New Delhi
                </span>
              </a>
              <a href="">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <span>
                  +91 99711 86877
                </span>
              </a>
              <a href="">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <span>
                  principalvj@gmail.com
                </span>
              </a>
            </div>
          </div>
        </div>
       {/*} <div className="col-md-6 col-lg-3 info-col">
          <div className="info_contact">
            <h4>
              Newsletter
            </h4>
            <form action="#">
              <input type="text" placeholder="Enter email" />
              <button type="submit">
                Subscribe
              </button>
            </form>
          </div>
        </div>*/}
        {/*<div className="col-md-6 col-lg-3 info-col">
          <div className="map_container">
            <div className="map">
              <div id="googleMap"></div>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  </section>
    </div>
  )
}
