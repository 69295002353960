import React, {useContext, useEffect} from 'react';
import { ProductContext } from '../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function AboutUsMain() {
  const { loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
  },[loggedUser])

  return (
    <div>
          <Helmet>
      <title>About Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="About Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="About Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    <section class="about_section layout_padding">
    <div class="container ">
      <div class="row">
        <div class="col-md-6">
          <div class="img-box">
            <img src="images/about-img.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="detail-box">
            <div class="heading_container">
              <h2>
                About Our Library
              </h2>
            </div>
            <p>
            In the heart of the digital age, where information flows freely, a digital library stands as a beacon of faith and knowledge, offering a vast array of resources to nourish the soul and enlighten the mind. These libraries provide a comprehensive collection of digital resources, including ebooks, audiobooks, articles, journals, videos, and multimedia content, all curated with a perspective.
            </p>
            <h5>Comprehensive and Accessible Content</h5>
            <p>A digital library houses a treasure trove of content that spans the breadth and depth of  thought and practice. From classic theological works to contemporary devotional materials, users can explore a rich tapestry of spiritual wisdom. The library's collection extends beyond traditional texts, encompassing multimedia resources such as sermons, music, and documentaries that cater to diverse learning styles and preferences.</p>
            <h5>User-Friendly Interface</h5>
            <p>A well-designed  digital library prioritizes user experience, offering a seamless and intuitive interface that guides users through its vast collection. The library's search functionality is powerful and efficient, allowing users to easily find the resources they need. Moreover, the library's layout is visually appealing, making it a pleasure to explore and engage with the content.</p>
            <h5>Robust Technology Infrastructure</h5>
            <p>A robust technology infrastructure is essential for a successful  digital library. This infrastructure ensures the library's website is accessible and reliable, even during peak usage periods. It also safeguards user data and intellectual property through robust security measures. Additionally, the library's technology platform should be adaptable to accommodate future growth and the integration of new resources and technologies.</p>
            <h5>A Community of Faith and Learning</h5>
            <p>Beyond its digital resources, a  digital library fosters a sense of community among its users. It provides opportunities for interaction and engagement through forums, discussion groups, and online events. These platforms allow users to connect with like-minded individuals, share insights, and deepen their faith together.</p>
            <h5>Conclusion</h5>
            <p>A  digital library is a powerful tool for spiritual growth, education, and community building. By offering a rich collection of resources, a user-friendly interface, and a robust technology infrastructure, these libraries empower individuals to explore their faith, deepen their understanding of the Bible, and connect with a supportive community. As technology continues to evolve,  digital libraries will remain at the forefront of faith-based learning and engagement.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>  )
}
