import React, {useContext} from 'react';
import { ProductContext } from '../ProductProvider';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import VJLOGO from '../assets/logo/VJLOGO.png';
export default function Navbar() {
    const { loggedUser, setLoggedUser, myProfileImage } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate=useNavigate();

    const handleSignOut=async(event)=>{
      console.log("inside sighout");
      try{
          await signOut()
          .then((res)=>{
            console.log("res", res);
          setLoggedUser({...loggedUser,
            name:'',
            email:'',
           // currentStatus:'',
            DOB:'',
            profile:'',
            provinceName:'',
            website:'',
            isAuthenticated:false,
            isAdmin:false
          }); 
          navigate("/login");    
        }).catch((error)=>{
          console.log("error", error);
          navigate("/");
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        })
      }catch(error){
        console.log("error", error);
        navigate("/");
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
    }

  return (
    <div>
    <header className="header_section">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
          <a className="navbar-brand" href="https://library.vidyajyoticollege.in">
            <span>
              <img src={VJLOGO} style={{width:"40px", height:"40px"}} alt='Logo'/>
              {"   "}
              FLYNN - MEAGHER Library
            </span>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className=""> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <Link to="/"><a className="nav-link pl-lg-0" href="#">Home <span className="sr-only">(current)</span></a></Link>
              </li>
              <li className="nav-item">
                <Link to="/aboutus"><a className="nav-link" href="#"> About</a></Link>
              </li>
              {/*<li className="nav-item">
                <Link to="/categories"><a className="nav-link" href="#">Categories</a></Link>
              </li>*/}
              <li className="nav-item">
                <Link to="/search"><a className="nav-link" href="#"> Search </a></Link>
              </li>
              {/*<li className="nav-item">
                <Link to="/contactus"><a className="nav-link" href="#">Contact Us</a></Link>
              </li>*/}
              {loggedUser.isAdmin && 
                              <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}>Admin</a>
                <div className="dropdown-menu" style={{left:"auto"}}>
                    <Link to="/issuebooks"><a className="dropdown-item" href="#">Issue Books</a></Link>
                    <Link to="/returnbooks"><a className="dropdown-item" href="#">Return Books</a></Link>
                    <Link to="/managebooks"><a className="dropdown-item" href="#">Manage Books</a></Link>
                    <Link to="/reports"><a className="dropdown-item" href="#">Reports</a></Link>
                </div>
            </li>}
              {!loggedUser.isAuthenticated ? <li className="nav-item">
                <Link to="/login"><a className="nav-link" href="#">Sign In</a></Link>
              </li>:
              <li className="nav-item">
                <a className="dropdown-toggle nav-link" data-toggle="dropdown" style={{color:"white", cursor:"pointer"}}><span className="name_user"><img className="img-responsive rounded-circle profile_image" src={myProfileImage} alt="#" />  {loggedUser.name}</span></a>
                <div className="dropdown-menu" style={{left:"auto"}}>
                    <Link to="/myprofile"><a className="dropdown-item" href="#">My Profile</a></Link>
                    <Link to="/mybooks"><a className="dropdown-item" href="#">My Books</a></Link>
                    {/*<a className="dropdown-item" href="help.html">Help</a>*/}
                    <a className="dropdown-item" href="#" onClick={event=>handleSignOut(event)}><span>Log Out</span> <i className="fa fa-sign-out"></i></a>
                </div>
            </li>}
            </ul>
           {/*} <from className="search_form">
              <input type="text" className="form-control" placeholder="Search here..." />
              <button className="" type="submit">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </from>*/}
          </div>
        </nav>
      </div>
    </header>
    </div>
  )
}
