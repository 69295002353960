import React, { useEffect, useState } from 'react';
import { books_category } from '../ProData';
import Carousel from 'react-bootstrap/Carousel';

export default function BookCategoryCarosel() {
    const [booksCategory, setBooksCategory] = useState([]);

    useEffect(()=>{
        const tempBooks=books_category.filter((item)=>item.categoryDisplay==="Yes");
        setBooksCategory(tempBooks);
      },[])
    

  return (
<div>
        <section className="catagory_section layout_padding">
    <div className="catagory_container">
      <div className="container ">
        <div className="heading_container heading_center">
          <h2>
            Books Categories
          </h2>
          <p>
          Our library provides a wide range of reading materials, with 65 distinct book categories and over 2,000 specific classifications.
          </p>
        </div>
        <div className="row">
        <div className="col-sm-6 col-md-4">
        <Carousel pause={false}>
          {booksCategory.map((item, index)=>
          <Carousel.Item key={index+1}>
            <div className="box ">
              <div className="img-box">
                <img src={item.categoryImage} alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  {item.name}
                </h5>
              </div>
            </div>
          </Carousel.Item>
        )}
          </Carousel>
          </div>
          <div className="col-sm-6 col-md-4">

          <Carousel defaultActiveIndex={1} pause={false}>
          {booksCategory.map((item, index)=>
          <Carousel.Item key={index+1}>
            <div className="box ">
              <div className="img-box">
                <img src={item.categoryImage} alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  {item.name}
                </h5>
              </div>
            </div>
          </Carousel.Item>
                  
        )}
          </Carousel>
          </div>
          <div className="col-sm-6 col-md-4">

          <Carousel defaultActiveIndex={2} pause={false}>
          {booksCategory.map((item, index)=>
          <Carousel.Item key={index+1}>
            <div className="box ">
              <div className="img-box">
                <img src={item.categoryImage} alt="" />
              </div>
              <div className="detail-box">
                <h5>
                  {item.name}
                </h5>
              </div>
            </div>
          </Carousel.Item>
                  
        )}
          </Carousel>
          </div>

          </div>
      </div>
    </div>
  </section>
    </div>  )
}
