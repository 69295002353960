import React, { useEffect, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';

export default function BookReport(props) {
    const {searchResult, setSearchTopic, setSearchValue, searchTopic, searchValue } = props;
    const { generateDate, generateReturnDate } = useContext(ProductContext);
    const [issueDetails, setIssueDetails] =useState({dueBooks : [], nextDueBooks:[]})


  return (
    <div>
    <section className="catagory_section">
    <h3 style={{textAlign:"center"}}>Book's Report</h3>
    {searchResult.length>0 &&<div className="catagory_container">
      <div className="container ">
        <div className="row">
          <div className="col-sm-6 col-md-3 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>{searchResult[0]["Active"]+searchResult[0]["Issued"]+searchResult[0]["In-Active"]}</h5>
                <p>Total Books</p>
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5> {searchResult[0]["Active"]}</h5>
                <p>Available Books</p>
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5> {searchResult[0]["Issued"]}</h5>
                <p>Issued Books</p>
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-md-3 card-deck">
            <div className="box card">
              <div className="img-box">
                <img src="images/cat1.png" alt="" />
              </div>
              <div className="detail-box">
                <h5> {searchResult[0]["In-Active"]}</h5>
                <p>In Active Books</p>
              </div>
            </div>
            </div>
            </div>
        </div>
    </div>}
    </section>


        {/*searchResult.length>0 && <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>{searchResult[0]["Active"]+searchResult[0]["Issued"]+searchResult[0]["In-Active"]}</i>
                                </div>
                                <h5 className="features-title">Total Books</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>
                                    {searchResult[0]["Active"]}</i>
                                </div>
                                <h5 className="features-title">Available Books</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>
                                    {searchResult[0]["Issued"]}</i>
                                </div>
                                <h5 className="features-title">Issued Books</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                            <div className="features-small-item">
                                <div className="icon">
                                    <i style={{fontSize:"30px", fontStyle:"normal"}}>
                                    {searchResult[0]["In-Active"]}</i>
                                </div>
                                <h5 className="features-title">In Active Books</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}

    </div>
  )
}
