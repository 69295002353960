import React, { useEffect, useState } from 'react'

export default function Footer() {
  const [currentYear, setCurrentYear] = useState();
  useEffect(()=>{
    var currentDate = new Date();
    var tempYear = currentDate.getFullYear();
    setCurrentYear(tempYear);
  },[])
  return (
    <div>
         <footer class="footer_section">
    <div class="container">
      <p>
        &copy; <span id="displayYear">{currentYear}</span> All Rights Reserved By
        <a href="https://idntica.com/">  idntica.com</a>
      </p>
    </div>
  </footer>
    </div>
  )
}
