import React from 'react';
import { Link } from 'react-router-dom';

export default function MainSlider() {
  return (
    <div>
         <section className="slider_section ">
      <div id="customCarousel1" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="container ">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    {/*<h5>
                      Bostorek Bookstore
                    </h5>*/}
                    <h1>
                    Comprehensive and Accessible Content
                    </h1>
                    <p>
                    Diverse collection: A rich array of digital resources, including ebooks, audiobooks, articles, journals, videos, and multimedia content.
                    Easy discoverability: Intuitive search functions and metadata tagging to help users find what they need quickly.
                    </p>
                    <Link to="/aboutus" style={{cursor:"pointer"}}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container ">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    {/*<h5>
                      Bostorek Bookstore
                    </h5>*/}
                    <h1>
                    Robust Technology Infrastructure
                    </h1>
                    <p>
                    Reliability and scalability: A stable platform capable of handling increased user traffic and growing content collections.
                    Security: Protecting user data and intellectual property with robust security measures.
                    </p>
                    <Link to="/aboutus" style={{cursor:"pointer"}}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="container ">
              <div className="row">
                <div className="col-md-6">
                  <div className="detail-box">
                    {/*<h5>
                      Bostorek Bookstore
                    </h5>*/}
                    <h1>
                    Continuous Innovation and User Focus
                    </h1>
                    <p>
                    User-friendly interface: A clean, intuitive design that enhances user experience.
                    Adaptability: Staying up-to-date with technological advancements and user needs.
                    </p>
                    <Link to="/aboutus" style={{cursor:"pointer"}}>
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box">
                    <img src="images/slider-img.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel_btn_box">
          <a className="carousel-control-prev" href="#customCarousel1" role="button" data-slide="prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#customCarousel1" role="button" data-slide="next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
    </div>
    )
}
