import React from "react";
import { Chart } from "react-google-charts";

export function BarChartsGoogle(props) {
    const {regYearCount, titleMsg}=props;

    const options = {
        chart: {
          title: titleMsg,
          subtitle: "Active Book Issued User's",
        },
      };
      
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={regYearCount}
      options={options}
    />
  );
}
