import React, {useState, useContext, useEffect} from 'react';
import { Col, Row, Form, Card, Table, ButtonGroup, FormGroup } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import axios from 'axios';
//import { fetchAuthSession } from 'aws-amplify/auth';
import Preloader from '../../components/Preloader/Preloader';
import { ProductContext } from '../../ProductProvider';
import { useRef } from 'react';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function ReturnBooks() {
  const { validateLogin, loggedUser, generateDate } = useContext(ProductContext);
  const [searchBook, setSearchBook] = useState({type:'AccessionNo', value:''});
  const [loaded, setLoaded] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [bookResult, setBookResult] = useState([]);
  const [bookSelect, setBookSelect]= useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [sorting, setSorting]=useState({field:"",order:"asc"});
  const ref = useRef([]);
  const [viewOption,setViewOption] = useState("All");
  
    useEffect(()=>{
      if(!loggedUser.isAuthenticated){
        validateLogin();
      }else{
          handleReset();
          handleBookFetch('NA','All');
      }
    },[loggedUser])

    const handleBookFetch=async(value, type)=>{
      //event.preventDefault();
      //console.log("search book : ", searchBook);
      try{
          setLoaded(false);
          //setDispMsg('');
          //const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const clientID="VJCollege";
          const searchValue = value;
          const searchTopic = type;
          await axios.get(`${config.api.invokeURL}/vjlibrary/issue?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
          headers: {
           // Authorization: idToken,
           //'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("response : ", response.data);
         //setDispMsg(response.data.length+' - Books Found');
          setBookResult(response.data);
          setFilterResult(response.data);
          enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
          setLoaded(true);
      }).catch((error)=>{
        //console.log("error:", error);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
    })
      }catch(error){
          //console.log("error:", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
  

  }

   /* const handleBookSearch=async(event)=>{
      event.preventDefault();
      //console.log("search book : ", searchBook);
      try{
          setLoaded(false);
          //setDispMsg('');
          //const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const clientID="VJCollege";
          const searchValue = searchBook.value;
          const searchTopic = searchBook.type;
          await axios.get(`${config.api.invokeURL}/vjlibrary/issue?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
          headers: {
           // Authorization: idToken,
           //'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("response : ", response.data);
         //setDispMsg(response.data.length+' - Books Found');
              setBookResult(response.data);
          enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
          setLoaded(true);
      }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
      setLoaded(true);
    })
      }catch(error){
          //console.log("error:", error);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      }
  

  }*/

  const handleBookSelection=(event, item)=>{
    //console.log("event:",event.target.checked);
    //console.log("Item :", item);
    //const myObj = JSON.parse(item);
    var newArray=[];
    newArray.push(item);
    //temp1=item;
   //return newArray;
    //console.log("Temp1", newArray);
    if(event.target.checked){
     // const jsonArray = Object.values(item);
     // console.log("Temp :", jsonArray);
  var temp=bookSelect;
 // console.log("Temp :", temp);
  temp.push(newArray[0]);
  //console.log("Temp :", temp);
  setBookSelect(temp);
    }else{
      const temp=bookSelect;
      var filterValue=temp.filter((i)=>i.accessionNo!==item.accessionNo);
    //  console.log("Filtered arrary :", filterValue);
      setBookSelect(filterValue);
    }
}

const handleBookReturns=async(event)=>{
  event.preventDefault();
  try{
      if(!bookSelect){
        enqueueSnackbar("Select the Book!",{variant : 'info'});
      }else if(bookSelect.length<=0){
        enqueueSnackbar("Select the Book!",{variant : 'info'});
      }
          setLoaded(false);
          var uniqueArray = removeDuplicates("userEmailID");
          //console.log("unique:", uniqueArray);
          //setLoaded(true);
          const todayDate=await generateDate();
          const params={
            clientID:loggedUser.website,
            bookSelect:bookSelect,
            bookStatus:"Active",
            bookedTo:todayDate,
            updatedBy:loggedUser.nickname,
            updatedOn:todayDate,
            uniqueArray:uniqueArray
          }; 
          //console.log("Params :", params);
          await axios.patch(`${config.api.invokeURL}/vjlibrary/returns`,params, {  
            headers: {
             // Authorization: idToken,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            await axios.post(`${config.api.invokeURL}/vjlibrary/notification/returns`,params, {  
              headers: {
               // Authorization: idToken,
                'x-api-key':config.api.key
              }
            });
            enqueueSnackbar("Book '" + bookSelect.accessionNo + "' successfully returned",{variant:'success'});
            //handleReset();
            handleBookFetch('NA','All');
            Unchecked();
            setLoaded(true)
        }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        setLoaded(true);
        window.scrollTo(0,0);
      })
  }catch(error){
      //console.log("Error : ", error);
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
}

const removeDuplicates=(tprop)=> {
  var newArray = [];
  var lookupObject  = {};
  for(var i in bookSelect) {
      lookupObject[bookSelect[i][tprop]] = bookSelect[i];
  }
  for(i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
  return newArray;
}

const handleViewOptionChange=async(type)=>{
  const todayDate=await generateDate();
  if(type==="Today"){
    const tempOrginalValue=bookResult;
    var sortedOptionArray = tempOrginalValue.filter((item) => new Date(item.bookedTo) === new Date(todayDate));
    await setFilterResult(sortedOptionArray);
  }else if(type==="Over Due"){
    const tempOrginalValue=bookResult;
    sortedOptionArray = tempOrginalValue.filter((item) =>  new Date(item.bookedTo) < new Date(todayDate));
    await setFilterResult(sortedOptionArray);
  }else if(type==="All"){
    await setFilterResult(bookResult);
  }
  setViewOption(type);
}

const handleReset=()=>{
  setSearchBook({type:'AccessionNo', value:''});
  setBookResult([]);
  setBookSelect([]);
}

const handleSortKey =async(event, value, order)=>{
  event.preventDefault();
  if(value==="AccessionNo" && order==="asc"){
    const tempOrginalValue=bookResult;
    //setBookResult([]);
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.accessionNo - b.accessionNo;});
    await setBookResult(sortedOptionArray);
    setSorting({field:"AccessionNo",order:"asc"});
  }else if(value==="AccessionNo" && order==="desc"){
    const tempOrginalValue=bookResult;
    //setBookResult([]);
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    sortedOptionArray = tempOrginalValue.sort((a, b) => {return b.accessionNo - a.accessionNo});
    await setBookResult(sortedOptionArray);
    setSorting({field:"AccessionNo",order:"desc"});
  }else if(value==="BookTitle" && order==="asc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    sortedOptionArray = tempOrginalValue.sort((a, b) => {
      if (a.bookTitle < b.bookTitle) {
        return 1;
      }
      if (a.bookTitle > b.bookTitle) {
        return -1;
      }
      return 0;
    });
    setSorting({field:"BookTitle",order:"asc"});
    await setBookResult(sortedOptionArray);
  }else if(value==="BookTitle" && order==="desc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
      sortedOptionArray = tempOrginalValue.sort((a, b) => {
        if (a.bookTitle < b.bookTitle) {
          return -1;
        }
        if (a.bookTitle > b.bookTitle) {
          return 1;
        }
        return 0;
      }); 
    setSorting({field:"BookTitle",order:"desc"});
    await setBookResult(sortedOptionArray);
  }else if(value==="BookAuthor" && order==="asc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    sortedOptionArray = tempOrginalValue.sort((a, b) => {
      if (a.bookAuthor < b.bookAuthor) {
        return 1;
      }
      if (a.bookAuthor > b.bookAuthor) {
        return -1;
      }
      return 0;
    });
    setSorting({field:"BookAuthor",order:"asc"});
    await setBookResult(sortedOptionArray);
  }else if(value==="BookAuthor" && order==="desc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    sortedOptionArray = tempOrginalValue.sort((a, b) => {
        if (a.bookAuthor < b.bookAuthor) {
          return -1;
        }
        if (a.bookAuthor > b.bookAuthor) {
          return 1;
        }
        return 0;
      }); 
    setSorting({field:"BookAuthor",order:"desc"});
    await setBookResult(sortedOptionArray);
  }else if(value==="IssuedTo" && order==="asc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
    sortedOptionArray = tempOrginalValue.sort((a, b) => {
      if (a.bookedfor < b.bookedfor) {
        return 1;
      }
      if (a.bookedfor > b.bookedfor) {
        return -1;
      }
      return 0;
    });
    setSorting({field:"IssuedTo",order:"asc"});
    await setBookResult(sortedOptionArray);
  }else if(value==="IssuedTo" && order==="desc"){
    const tempOrginalValue=bookResult;
    //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
      sortedOptionArray = tempOrginalValue.sort((a, b) => {
        if (a.bookedfor < b.bookedfor) {
          return -1;
        }
        if (a.bookedfor > b.bookedfor) {
          return 1;
        }
        return 0;
      }); 
    setSorting({field:"IssuedTo",order:"desc"});
    await setBookResult(sortedOptionArray);
  }
}

const handleBookFilter = async(event)=>{
  event.preventDefault();
  //console.log("book result ", bookResult);
  await setSearchBook({...searchBook, 
    value:(event.target.value).toUpperCase()
  });
  const tempOrginalValue=bookResult;
  const searchValue = (event.target.value).toUpperCase();
  const searchTopic = searchBook.type;
  //console.log("search value :", searchValue);
  //console.log("search topic :", searchTopic);
  //console.log("bookResult :", tempOrginalValue);
  var tempFilter=[]
  if(searchTopic==="AccessionNo"){
    tempFilter = tempOrginalValue.filter((item)=>item.accessionNo.startsWith(searchValue));
  }else if(searchTopic==="BookTitle"){
    tempFilter = tempOrginalValue.filter((item)=>item.bookTitle.startsWith(searchValue));
  }else if(searchTopic==="UserName"){
    tempFilter = tempOrginalValue.filter((item)=>item.bookedfor.startsWith(searchValue));
  }else if(searchTopic==="Author"){
    tempFilter = tempOrginalValue.filter((item)=>item.bookAuthor.startsWith(searchValue));
  }else if(searchTopic==="IssueID"){
    tempFilter = tempOrginalValue.filter((item)=>item.issueID.startsWith(searchValue));
  }else if(searchTopic==="UserID"){
    tempFilter = tempOrginalValue.filter((item)=>item.userID.startsWith(searchValue));
  }
  if(searchValue===""){
    tempFilter = tempOrginalValue;
  }
  //console.log("filtered value ", tempFilter);
  setFilterResult(tempFilter);
}

const Unchecked = () => {

  //console.log(ref.current.length)
  for (let i = 0; i < ref.current.length; i++) {

      ref.current[i].checked = false;
  }

}
 
  return (
    <div className='container' style={{padding:"20px"}}>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    <div className="section">
    {!loaded && <Preloader show={loaded ? false : true} />}
    {/*Book Details*/}  
    <h3 style={{textAlign:"center"}}>Return Book's</h3>
    <Card style={{padding:"0px"}}>
      <Card.Body>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          {/*<FormGroup
            inline
            check className="pr-3"
          >
            <Form.Check type="checkbox" label="Label Print View" defaultChecked={false} checked={isLabelViewChecked} onChange={handleLabelViewCheck}/>
          </FormGroup>*/}
          <FormGroup inline check className="pr-3">
        <Form.Check
          type="radio"
          name="radioView"
          value="All"
          label="All"
          checked={viewOption==="All"}
          onClick={() => {
            handleViewOptionChange("All")
          }}
        />
      </FormGroup><span>
          <FormGroup inline check className="pr-3">
        <Form.Check
          type="radio"
          name="radioView"
          value="Today"
          label="Today"
          checked={viewOption==="Today"}
          onClick={() => {
            handleViewOptionChange("Today")
          }}
        />
      </FormGroup></span>
      <FormGroup inline check>
        <Form.Check
          type="radio"
          name="radioView"
          value="Over Due"
          label="Over Due"
          checked={viewOption==="Over Due"}
          onClick={() => {
            handleViewOptionChange("Over Due");
          }}
        />
        </FormGroup>
        </ButtonGroup>
        </div>
        </div>

        <Form>
        <Row>
            <Col lg={2} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Search Type</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                value={searchBook.type}
                onChange={event=>{setSearchBook({...searchBook, type:event.target.value})}} 
                    >
                  <option value="UserName">User Name</option>
                  <option value="AccessionNo">Accession No</option>
                  <option value="UserID">User ID</option>
                  <option value="BookTitle">Book Title</option>
                  <option value="Author">Author</option>
                  <option value="IssueID">Issue ID</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Search Details</Form.Label>
                <Form.Control required type="text" placeholder="Enter author name...."
                    value={searchBook.value} 
                    onChange={event=>{handleBookFilter(event)}}
          />
              </Form.Group>
            </Col>
            {/*<Col lg={3} className="mb-3">
                <button className='btn-box' style={{marginTop:"30px"}} onClick={event=>handleBookSearch(event)}>Search Book</button>
            </Col>*/}
            <Col lg={2} className="mb-3">
          <button className='btn-box' style={{marginTop:"30px"}} onClick={event=>handleBookReturns(event)}>Return</button>
        </Col>
        <Col lg={2} className="mb-3">
                <button className='btn-box' style={{marginTop:"30px"}} onClick={event=>handleBookFetch('NA','All')}>Refresh</button>
            </Col>
          </Row>
          </Form>
          {filterResult.length>0 ? <Table striped bordered hover>
        <thead >
        <tr className='text-center'>
          <th>#</th>
          <th>Accession No  {sorting.field==="AccessionNo" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "AccessionNo","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "AccessionNo", "asc")}></i>}</th>
          <th>Book Title {sorting.field==="BookTitle" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle", "asc")}></i>}</th>
          <th>Author  {sorting.field==="BookAuthor" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "BookAuthor","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "BookAuthor", "asc")}></i>}</th>
          <th>Classification No </th>
          <th>Issued To {sorting.field==="IssuedTo" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "IssuedTo","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "IssuedTo", "asc")}></i>}</th>
          <th>Booking Date  </th>
          <th>Return Date </th>
        </tr>
        </thead>
        <tbody>
        {filterResult.map((item,index)=>{return(
          <tr key={index}>
            <td><Form.Check inline
              type='checkbox'
              name="group1"
              id="bookChecks"
              onChange={event=>handleBookSelection(event, item)}
              ref={(element) => { ref.current[index] = element }}
            /></td>
            <td>{item.accessionNo}</td>
            <td>{item.bookTitle}</td>
            <td>{item.bookAuthor}</td>
            <td>{item.bookClassificationNo}</td>
            <td>{item.bookedfor} <p> ({item.userID})</p></td>
            <td>{item.bookedOn}</td>
            <td>{item.bookedTo}</td>
          </tr>
        )})}
        </tbody>
      </Table>:null}
          </Card.Body>
        </Card>
        <div className="mt-3 text-center">
          <button className='btn-box' onClick={event=>handleBookReturns(event)}>Return Books</button>
        </div>
    </div>
    </div>
  )
}
