import React, {useEffect, useContext } from 'react'
import { ProductContext } from '../../ProductProvider';
import ProfileImage from '../../components/profile/ProfileImage';
import {Helmet} from 'react-helmet-async';

export default function MyProfile() {
    const { validateLogin, loggedUser, myProfileImage } = useContext(ProductContext);
    //const [profileImage, setProfileImage] = useState(logo);

    useEffect(()=>{
        if(!loggedUser.isAuthenticated){
          validateLogin();
        }
        //console.log("logged user :", loggedUser);
      },[loggedUser])
    
  return (
    <div className='container' style={{padding:"50px"}}>
            <Helmet>
      <title>My Profile - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="My Profile - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="My Profile - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        <div className="row">
        <div>
            <div className="section">
            <h5 style={{textAlign:"center"}}>{loggedUser.name}'s Profile</h5>

                <ProfileImage profileImage={myProfileImage} />

                    <div className="row">
                    <div >
                        <fieldset>
                        <label>Name : </label>
                        <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required=""
                        value={loggedUser.name} /> 
                        </fieldset>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>Email Address:</label>
                        <fieldset>
                        <input name="email" type="email" className="form-control" id="email" placeholder="E-Mail Address" required=""
                        value={loggedUser.email} />
                        </fieldset>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>Phone No:</label>
                        <fieldset>
                        <input name="phoneno" type="text" className="form-control" id="phoneno" placeholder="Phone No" required=""
                        value={loggedUser.phone_number} />
                        </fieldset>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>Registration Number:</label>
                        <fieldset>
                        <input name="email" type="email" className="form-control" id="email" placeholder="E-Mail Address" required=""
                        value={loggedUser.nickname} />
                        </fieldset>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>Profile:</label>
                        <fieldset>
                        <input name="phoneno" type="text" className="form-control" id="phoneno" placeholder="Phone No" required=""
                        value={loggedUser.profile} />
                        </fieldset>
                    </div>
                    {/*} <div className="col-lg-12">
                        <fieldset>
                        <textarea name="message" rows="6" className="form-control" id="message" placeholder="Your Message" required=""></textarea>
                        </fieldset>
                    </div>
                    <div className="col-lg-12">
                        <fieldset>
                        <button type="submit" id="form-submit" className="main-button">Send Message</button>
                        </fieldset>
                    </div>*/}
                    </div>
            </div>
        </div>
    </div>
    </div>
  )
}
