import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
//import MainSlider from './pages/MainSlider';
import BookCategories from './pages/BookCategories';
import Footer from './pages/Footer';
import ContactUs from './pages/ContactUs';
import FooterInfo from './pages/FooterInfo';
//import BlogSection from './pages/BlogSection';
//import CustomerSection from './pages/CustomerSection';
//import AboutUs from './pages/AboutUs';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import HomePage from './pages/HomePage';
import BooksSearch from './pages/search/BooksSearch';
import MyProfile from './pages/profile/MyProfile';
import IssueBooks from './pages/admin/IssueBooks';
import ReturnBooks from './pages/admin/ReturnBooks';
import AddBooks from './pages/admin/AddBooks';
import HomeReports from './pages/reports/HomeReports';
//import { ProductContext } from './ProductProvider';
import AboutUsMain from './pages/AboutUsMain';
import MyBooks from './pages/profile/MyBooks';

function App() {

  return (
    <div>
      <Navbar />
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search" element={<BooksSearch />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/myprofile" element={<MyProfile />} />
      <Route path="/mybooks" element={<MyBooks />} />
      <Route path="/categories" element={<BookCategories />} />
      <Route path="/aboutus" element={<AboutUsMain />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/issuebooks" element={<IssueBooks />} />
      <Route path="/returnbooks" element={<ReturnBooks />} />
      <Route path="/managebooks" element={<AddBooks />} />
      <Route path="/reports" element={<HomeReports />} />
      <Route path="*" element={<Navigate to="/" replace />}/>
      </Routes>
      <FooterInfo />
      <Footer />
    </div>
  );
}

export default App;
