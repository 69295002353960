import React, {useState, useContext, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Preloader from '../components/Preloader/Preloader';
import { ProductContext } from '../ProductProvider';
import {Helmet} from 'react-helmet-async';

const config = require('../config.json');

export default function ContactUs() {
  const { loggedUser, generateDate, generateAccessionNo } = useContext(ProductContext);
  const [contactDetails, setContactDetails]= useState({username:"", emailID:"", phoneNumber:"", message:"" });
  const { enqueueSnackbar } = useSnackbar();
  const [loaded, setLoaded] = useState(true);

  useEffect(()=>{
    handleReset();
  },[])

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
      const validationResult=validateFields();
      if(validationResult==="passed"){
        const todayDate=await generateDate();
        const taccessionNo=await generateAccessionNo();

        const {username, emailID, phoneNumber, message } = contactDetails;
        const params={
          clientID:loggedUser.website,
          msgID:"MSG"+taccessionNo,
          username:loggedUser.nickname,
          emailID:emailID,
          phoneNumber:phoneNumber,
          message:message,
          dateSubmitted:todayDate,
          msgStatus:"New",
          dateClosed:"NA",
          comments:"NA",
          fullname:loggedUser.name,
          updatedBy:loggedUser.nickname,
          updatedOn:todayDate
        };
        console.log("params : ", params);
        await axios.post(`${config.api.invokeURL}/vjlibrary/contactus`,params, {  
          headers: {
           // Authorization: idToken,
            //'x-api-key':config.api.key
          }
        }).then(async(response) => {
         //console.log("Response : ", response);
            enqueueSnackbar("Request submitted successfully - " + params.msgID,{variant:'success'});
            handleReset();
          setLoaded(true)
      }).catch((error)=>{
        //console.log("Error : ", error);
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
      setLoaded(true);
      window.scrollTo(0,0);
    })
      }
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
    }
  }

  const handleReset=()=>{
    setContactDetails({...contactDetails, 
      username:loggedUser.nickname,
      emailID:loggedUser.email, 
      phoneNumber:loggedUser.phone_number,
      message:"" });
  }

  const validateFields=()=>{
    var returnValue="passed";
    const {username, emailID, phoneNumber, message } = contactDetails;
    if(username===""){
      enqueueSnackbar("Name is mandatory.",{variant : 'info'});
      returnValue="failed";
    }else if(emailID===""){
      enqueueSnackbar("emailID is mandatory.",{variant : 'info'});
      returnValue="failed";
    }else if(phoneNumber===""){
      enqueueSnackbar("Phone Number is mandatory.",{variant : 'info'});
      returnValue="failed";
    }else if(message===""){
      enqueueSnackbar("Message is mandatory!.",{variant : 'info'});
      returnValue="failed";
    }else {
  returnValue="passed";
  }
return returnValue;
}

  return (
    <div>
          <Helmet>
      <title>Contact Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Contact Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Contact Us - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    {!loaded && <Preloader show={loaded ? false : true} />}
    <section className="contact_section layout_padding">
    <div className="container">
      <div className="row">
        <div className="col-md-6 ">
          <div className="heading_container ">
            <h2 className="">
              Contact Us
            </h2>
          </div>
          <form action="#">
            <div>
              <input type="text" placeholder="Name" 
              value={contactDetails.username}
              onChange={event=>setContactDetails({...contactDetails,
                username:event.target.value})}/>
            </div>
            <div>
              <input type="email" placeholder="Email" 
              value={contactDetails.emailID}
              onChange={event=>setContactDetails({...contactDetails,
                emailID:event.target.value})}/>
            </div>
            <div>
              <input type="text" placeholder="Phone Number" 
              value={contactDetails.phoneNumber}
              onChange={event=>setContactDetails({...contactDetails,
                phoneNumber:event.target.value})}/>
            </div>
            <div>
              <input type="text" className="message-box" placeholder="Message" 
              value={contactDetails.message}
              onChange={event=>setContactDetails({...contactDetails,
                message:event.target.value})}/>
            </div>
            <div className="btn-box">
              <button onClick={event=>handleSubmit(event)}>
                SEND
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <div className="img-box">
            <img src="images/contact-img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}
