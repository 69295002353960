export const author_details = [
    {
      "id": 1,
      "name": "Aagaard Anna Marie & Bouteneff Peter C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2,
      "name": "Aasi Ghulam Haider",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3,
      "name": "Aaubry, J.-B",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4,
      "name": "Abbas, Shemeem Burney (1943-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5,
      "name": "Abbott, Edwin A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6,
      "name": "Abbott, T.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8,
      "name": "Abbott-Smith, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9,
      "name": "Abd al-Muhammad al-Hafyan, al-Shaykh (1919-1973) & Bili, Uthman Sayyid Ahmad Ismail [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 10,
      "name": "Abdiso, Mar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 11,
      "name": "Abdul Kalam, A.P.J. (1931-2015)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 12,
      "name": "Abdul-Jabar, Faleh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 13,
      "name": "Abdullah Ruqaiyah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 14,
      "name": "Abdullah Yusuf Ali, Allama [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 15,
      "name": "Abdullah, Achmed",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 16,
      "name": "Abdurrahman Abuhuraira",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 17,
      "name": "Abegg Martin G. Jr Flint Peter & Ulrich Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 18,
      "name": "Abel F.-M O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 21,
      "name": "Abellan, Pedro Ma, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 22,
      "name": "Abelly Louys",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 23,
      "name": "Abelly Ludovicus Episq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 24,
      "name": "Abercrombie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 25,
      "name": "Abernethy, Julian W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 26,
      "name": "Abesamis, Carlos H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 27,
      "name": "Abhyankar, Vasudeva Sastri & Commans, Michael [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 28,
      "name": "Abir, Antonysamy Peter (1952-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 29,
      "name": "Abner Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 30,
      "name": "Abou El Fadl, Khaled (1963-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 31,
      "name": "About, Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 32,
      "name": "Abraham, Gerald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 33,
      "name": "Abraham, Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 34,
      "name": "Abul Kalam Azad, Mawlana & Goyal, D.R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 35,
      "name": "Abun-Nasr, Jamil M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 36,
      "name": "Acarya, Sri Ram Sarma [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 37,
      "name": "Accattoli Luigi & Aumann Jordan O.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 38,
      "name": "Achtemeier Paul J. Green Joel B. (1956-  ) & Thompson Marianne Meye",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 39,
      "name": "Ackland, T.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 40,
      "name": "Acosta, Christofore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 41,
      "name": "Acton, Beatrice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 43,
      "name": "Adam, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 44,
      "name": "Adam, Leonhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 45,
      "name": "Adamnanus, Abhes, Stus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 46,
      "name": "Adams H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 47,
      "name": "Adams W.H. Davenport",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 48,
      "name": "Adams, Doug [ed.] & Apostolos-Cappadona, Diane [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 49,
      "name": "Adams, Elizabeth Laura",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 50,
      "name": "Adams, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 51,
      "name": "Addis William E. & Arnold Thomas W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 52,
      "name": "Addison, James Thayer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 53,
      "name": "Addison, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 54,
      "name": "Adeney Walter F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 55,
      "name": "Ady, Cecilia M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 56,
      "name": "Aegidius Romanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 57,
      "name": "Aernoudt, Petrus Joannes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 58,
      "name": "Aertnys, J., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 59,
      "name": "Aeschines",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 60,
      "name": "Aeschylus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 61,
      "name": "Aesopos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 62,
      "name": "Agarwal, A.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 63,
      "name": "Agarwal, Benimadhav",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 64,
      "name": "Agarwal, Satya P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 65,
      "name": "Agersnap, Soren",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 66,
      "name": "Agius, Laurentius M., O.E.S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 67,
      "name": "Agnel, Arnaud D' & Espiney, D'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 68,
      "name": "Aguiar, Benny M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 69,
      "name": "Ahearne, Pierce & Lane, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 70,
      "name": "Ahmad Madani, Husayn, Sayyid (1879-1957); Husain, Muhammad Anwar [transl.] & Imam, Hasan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 71,
      "name": "Ahmad Nafeez Mosaddeq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 72,
      "name": "Ahmad Rafiuddin [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 73,
      "name": "Ahmad, Imtiaz [ed.] & Reifeld, Helmut [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 74,
      "name": "Ahmad, Muhammad Isar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 75,
      "name": "Ahmadi Nader & Ahmadi Fereshteh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 76,
      "name": "Aicardo Jose Manuel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 77,
      "name": "Aichner Simon Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 78,
      "name": "Aigrain, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 80,
      "name": "Aiken, Charles Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 81,
      "name": "Ainger, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 82,
      "name": "Akbar M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 83,
      "name": "Akbarzadeh, Shahram [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 84,
      "name": "Akhilananda, Swami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 85,
      "name": "Alacoque Marguerite-Marie O. Visit. Ste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 88,
      "name": "Alam, Muzaffar [transl.] & Alavi, Seema [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 89,
      "name": "Alavi, Seema",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 91,
      "name": "Albertus Magnus, O.P., Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 92,
      "name": "Albertz, Rainer & Bowden, John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 93,
      "name": "Albright, William Foxwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 94,
      "name": "Alden, Edward C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 95,
      "name": "Aleaz, K.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 96,
      "name": "Alena, Zuster M., C.R.SS.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 98,
      "name": "Ales, Adhemar d', S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 99,
      "name": "Alex Emmanuel (Pseud)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 100,
      "name": "Alexander Natalis O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 101,
      "name": "Alexander O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 102,
      "name": "Alexander, Ruth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 103,
      "name": "Alexandre C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 104,
      "name": "Alexandre Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 105,
      "name": "Alexandre, Planche & Defaucoupret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 106,
      "name": "Alexis, M.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 107,
      "name": "Alexis, M.G. & Dalle, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 108,
      "name": "Alfano Giovanni Battista",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 109,
      "name": "Alfano, Giovanni Battista & Amitrano, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 110,
      "name": "Alfred, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 111,
      "name": "Algar Hamid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 112,
      "name": "Algernon, Cecil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 113,
      "name": "Algue Jose S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 114,
      "name": "Ali Shaheen Sardar & Rehman Javaid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 115,
      "name": "Ali Tariq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 116,
      "name": "Ali Tchelebi Ben Saleh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 117,
      "name": "Ali, Kecia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 118,
      "name": "Ali, Muhammad Mohar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 119,
      "name": "Ali, Muhammad, Mawlana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 120,
      "name": "Allard Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 121,
      "name": "Allard Paul & Cappadelta C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 122,
      "name": "Allegretti Joseph G. (1952-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 123,
      "name": "Allen B.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 124,
      "name": "Allen Joseph J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 125,
      "name": "Allen Prudence R.S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 126,
      "name": "Allen, Diogenes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 127,
      "name": "Allen, Grant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 128,
      "name": "Allen, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 129,
      "name": "Allen, John Barrow",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 130,
      "name": "Allen, Willoughby C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 132,
      "name": "Allers, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 133,
      "name": "Allier, Raoul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 134,
      "name": "Allies Mary H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 136,
      "name": "Allies, Thomas William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 137,
      "name": "Allison, Dale C., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 139,
      "name": "Allo, E. Bernard, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 140,
      "name": "Allport, Gordon W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 141,
      "name": "Almond, Ian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 142,
      "name": "Alonso Manuel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 143,
      "name": "Alphonse A. MSFS",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 144,
      "name": "Alphonse, S. Xavier, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 145,
      "name": "Alric J.F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 146,
      "name": "Alszeghy, Zoltan, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 147,
      "name": "Altamira, Rafael & Volkov, P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 148,
      "name": "Altaner, Berthold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 149,
      "name": "Alter, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 150,
      "name": "Alter, Robert [ed.] & Kermode, Frank [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 151,
      "name": "Alves Pereira Bernard O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 152,
      "name": "Alves, Mary Emmanuel, FSP",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 153,
      "name": "Alzog Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 154,
      "name": "Amaduni, Garabed",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 155,
      "name": "Amaladass, Anand, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 157,
      "name": "Amaladoss, Michael, S.J. (1936-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 159,
      "name": "Amand David O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 160,
      "name": "Amankwah, John M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 161,
      "name": "Amann Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 162,
      "name": "Amann, Emile & Dumas, Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 163,
      "name": "Amar, Joseph P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 164,
      "name": "Ambady, G.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 165,
      "name": "Ambard, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 167,
      "name": "Ambasta, G. Prasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 168,
      "name": "Ambedkar, B.R. (1891-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 169,
      "name": "Ambrosius Mediolanensis Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 171,
      "name": "Ambruzzi, Aloysius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 172,
      "name": "Ameal, Joao",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 173,
      "name": "Amelotte D. Congr. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 174,
      "name": "Amen-Em-Apt & Budge, E.A. Wallis [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 175,
      "name": "Amicis Edmondo De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 176,
      "name": "Amiel, Henri-Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 177,
      "name": "Amin Abdul Rahman A. Noor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 178,
      "name": "Amiot, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 179,
      "name": "Ammann A.M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 180,
      "name": "Anchukandam Thomas SDB [ed.]; Veliath Dominic SDB [ed.] & Kuttianimattathil Jose SDB [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 181,
      "name": "Anderl, Stephen & Ruth, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 182,
      "name": "Anderson A.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 183,
      "name": "Anderson George M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 184,
      "name": "Anderson, Edwin P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 185,
      "name": "Anderson, Paul B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 186,
      "name": "Anderson, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 187,
      "name": "Anderson, Sherry Ruth & Hopkins, Patricia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 188,
      "name": "Andrae, Tor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 189,
      "name": "Andre G. S.J. (1846-1916)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 190,
      "name": "Andre G. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 191,
      "name": "Andre Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 192,
      "name": "Andre Marius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 193,
      "name": "Andre, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 194,
      "name": "Andrews, William Eusebius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 195,
      "name": "Andrieu, Michel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 196,
      "name": "Andrieu-Guitrancourt, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 198,
      "name": "Angela Da Foligno, Bta.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 199,
      "name": "Angelini N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 200,
      "name": "Angelo Romano Di S. Teresa, C.Ss. Trin.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 201,
      "name": "Anglade F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 202,
      "name": "Anglade, Aloysius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 204,
      "name": "Angot Des Rotours, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 205,
      "name": "Angus, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 206,
      "name": "Angus, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 207,
      "name": "Annandale, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 208,
      "name": "Annibale Josephus d' Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 209,
      "name": "Ansari Iqbal A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 211,
      "name": "Anselmus, Cantuariensis, Archiep., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 212,
      "name": "Ansley, F. Clarke",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 213,
      "name": "Anson, Peter F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 214,
      "name": "Anson, William R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 215,
      "name": "Anthony, Francis Vincent, SDB",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 216,
      "name": "Anthuvan, M. Victor Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 217,
      "name": "Antoine Ch. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 218,
      "name": "Antoine, Robert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 220,
      "name": "Antonelli, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 221,
      "name": "Antonini, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 222,
      "name": "Antonis, Edward, S.J., Torfs, Alexis, S.J. & Reypens, Leonce, S.J. (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 223,
      "name": "Antonius Raymondus Eystettensis Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 224,
      "name": "Antony M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 225,
      "name": "Antony Margaret & Maheswaran G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 226,
      "name": "Antony, C.M. & Jarrett, Bede, O.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 227,
      "name": "Antony, M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 228,
      "name": "Antonysamy Johnson Colaco Edwin & Peter Praveen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 229,
      "name": "Anup",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 230,
      "name": "Ao A. Lanunungsang",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 231,
      "name": "Appaiah, Parvathy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 232,
      "name": "Apte, Vamana Sivarama",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 233,
      "name": "Apuleus, Lucius Africanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 234,
      "name": "Arabi, Oussama",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 235,
      "name": "Aragon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 236,
      "name": "Arayathinal Thomas M.O.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 237,
      "name": "Arbuckle, Gerald A., S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 239,
      "name": "Archambault, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 240,
      "name": "Archer Jeffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 241,
      "name": "Arcos Angelus Maria De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 242,
      "name": "Areeparambil, Mathew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 243,
      "name": "Arendt Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 244,
      "name": "Arendzen J.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 246,
      "name": "Arens, Bernard, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 247,
      "name": "Arias Francis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 248,
      "name": "Arimont, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 249,
      "name": "Arimont, Joseph, S.J. & Brou, Alexandre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 250,
      "name": "Aristoteles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 251,
      "name": "Aristotle & McKeon, Richard M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 252,
      "name": "Arizzoli Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 253,
      "name": "Arkoun, Muhammad & Lee, Robert D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 254,
      "name": "Armstrong Donald [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 255,
      "name": "Armstrong, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 256,
      "name": "Armstrong, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 257,
      "name": "Arnobius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 258,
      "name": "Arnobius, Bryce, Hamilton [transl.] & Campbell, Hugh [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 259,
      "name": "Arnold David (1946-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 260,
      "name": "Arnold Matthew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 261,
      "name": "Arnold, Bill T. [ed.] & Williamson, H.G.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 262,
      "name": "Arnold, Johann Christoph (1940-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 263,
      "name": "Arnold, Matthew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 264,
      "name": "Arnold, Thomas Kerchever",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 265,
      "name": "Arnold, Thomas W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 266,
      "name": "Arnold-Forster, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 267,
      "name": "Arnou Renatus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 269,
      "name": "Arnou, Rene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 270,
      "name": "Arnoux, Jacques D'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 271,
      "name": "Arockiam Michaelsami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 272,
      "name": "Arokiasamy Soosai S.J. (1937-2012) [ed.] & Chathanatt John S.J. (1947-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 273,
      "name": "Aron, Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 274,
      "name": "Aronson, Alex",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 275,
      "name": "Aronstein, PH.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 276,
      "name": "Arquilliere, H.-X.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 277,
      "name": "Arraj, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 278,
      "name": "Arras H.d' S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 280,
      "name": "Arregui, Antonius M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 281,
      "name": "Arrupe, Pedro, S.J. (1907-1991)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 282,
      "name": "Arrupe, Pedro, S.J. (1907-1991) & Burke, Kevin F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 283,
      "name": "Arseniev, Nicholas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 284,
      "name": "Arthur, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 285,
      "name": "Arthur, Ivan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 286,
      "name": "Arts, Herwig, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 287,
      "name": "Arun, C. Joe, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 288,
      "name": "Arvine, Kazlitt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 289,
      "name": "Arvisenet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 290,
      "name": "Arvisenet Claudes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 291,
      "name": "Aryhuysers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 292,
      "name": "Asani, Ali S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 293,
      "name": "Asch, Sholem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 294,
      "name": "Ascough, Richard S. & Cotton, Sandy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 295,
      "name": "Asford, Daisy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 296,
      "name": "Ashby, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 297,
      "name": "Ashley W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 298,
      "name": "Ashley, John M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 299,
      "name": "Ashmore, Sydney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 300,
      "name": "Ashtiyani, Jalal Al-Din, Sayyid [ed.], Matsubara, Takashi Iwami [ed.] & Matsumoto, Akiro [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 301,
      "name": "Asi, Muhammad & Bangash, Zafar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 302,
      "name": "Asin Palacios, Miguel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 303,
      "name": "Askee, Alice & Claude",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 304,
      "name": "Asquith Lady Cynthia [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 305,
      "name": "Assayag Jackie [ed.] & Tarabout Gilles (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 306,
      "name": "Astrain Antonio S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 307,
      "name": "Astrain Antonio S.J. & Hull Robert S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 309,
      "name": "Athanasius Magnus Alexandrinus, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 310,
      "name": "Athanasius Magnus Alexandrinus, Episc. & Robertson, A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 311,
      "name": "Athenagoras",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 312,
      "name": "Atholl Katharine Duchas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 313,
      "name": "Athyal, Jesudas M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 314,
      "name": "Athyal, Jesudas M. [ed.] & Thatamanil, John J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 315,
      "name": "Atkinson, F.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 316,
      "name": "Atkinson, J.A., Esq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 317,
      "name": "Atkinson, J.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 318,
      "name": "Atkinson, O'Brien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 319,
      "name": "Attwater, Donald (1892-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 321,
      "name": "Au, Wilkie, S.J. & Cannon, Noreen, C.S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 322,
      "name": "Aubert, Jeanne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 323,
      "name": "Aubert, Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 324,
      "name": "Aubert, Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 325,
      "name": "Aubigny M.M.D'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 326,
      "name": "Aubineau, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 327,
      "name": "Audin M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 328,
      "name": "Auffray, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 329,
      "name": "Augustinus Aurelius Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 330,
      "name": "Augustinus Aurelius Episc. Stus. Allies Mary H. [transl.] & Allies I.W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 331,
      "name": "Augustinus Aurelius Episc. Stus.; Matthew Tobie [transl.] & Hudleston Dom Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 332,
      "name": "Augustinus Aurelius, Episc., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 333,
      "name": "Augustinus Aurelius, Episc., Stus. & Brigg, C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 334,
      "name": "Augustinus Aurelius, Episc., Stus. & Combes, G. [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 335,
      "name": "Augustinus Aurelius, Episc., Stus. & Sheed, F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 336,
      "name": "Augustinus, Aurelius, Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 337,
      "name": "Ault-Dumesnil, Ed. D', Dubeux, Louis & Crampon, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 338,
      "name": "Aulus Agellus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 339,
      "name": "Aurelianus A Ssmo Sacramento, C",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 340,
      "name": "Austen, Jane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 341,
      "name": "Austin John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 342,
      "name": "Autran, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 343,
      "name": "Avadhnarayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 345,
      "name": "Avancinus, Nicolaus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 346,
      "name": "Avanzini Petrus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 347,
      "name": "Avasthi, Jagmohannath 'Mohan'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 348,
      "name": "Avasthi, Sundarsimh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 349,
      "name": "Avebury, Lord",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 351,
      "name": "Aveling, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 352,
      "name": "Avrillon, Min.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 353,
      "name": "Aykara, Thomas A., CMI [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 354,
      "name": "Ayre, G.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 355,
      "name": "Ayscowgh, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 356,
      "name": "Azariah M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 357,
      "name": "Azariah, V.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 358,
      "name": "Azarias, Bro.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 359,
      "name": "Baainbridge, Marion S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 360,
      "name": "Baart de la Faille, C.J. & Isbrucker, J.H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 361,
      "name": "Babcock, Barbara A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 362,
      "name": "Babu, Titus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 363,
      "name": "Bacci, Antonio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 364,
      "name": "Bachelard Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 365,
      "name": "Backes Ignaz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 366,
      "name": "Backx Rumoldus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 367,
      "name": "Bacon, Benjamin W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 368,
      "name": "Bacon, G.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 369,
      "name": "Bacquez L. P.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 370,
      "name": "Bacuez L. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 371,
      "name": "Badenoch, L.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 373,
      "name": "Baden-Powell, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 374,
      "name": "Badsworth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 375,
      "name": "Badts De Cugnac Albert De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 376,
      "name": "Baedeker, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 377,
      "name": "Baethgen, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 378,
      "name": "Bagchi Amiya Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 379,
      "name": "Bagchi, S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 380,
      "name": "Bagehot, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 381,
      "name": "Bagger, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 382,
      "name": "Bagshawe John B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 383,
      "name": "Baikie, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 384,
      "name": "Bailey, Cyril",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 385,
      "name": "Bailey, Gauvin Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 386,
      "name": "Baillie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 387,
      "name": "Bailly L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 388,
      "name": "Bain, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 389,
      "name": "Baines Peter Augustine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 391,
      "name": "Bainvel, J.V., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 392,
      "name": "Bainville, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 393,
      "name": "Baker B. Anstice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 394,
      "name": "Baker F.P. O.S.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 395,
      "name": "Baker, Albert E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 396,
      "name": "Baker, Alonzo L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 397,
      "name": "Baker, Augustin, O.S.B. & Sweeney, Dom Norbert, O.S.B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 398,
      "name": "Baker, Ernest A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 400,
      "name": "Baker, G.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 401,
      "name": "Baker, Morant W. & Harris, Vincent Dormer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 403,
      "name": "Balasubramanian, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 404,
      "name": "Balbi, Adrien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 405,
      "name": "Baldi G. O.S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 406,
      "name": "Baldwin, James Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 407,
      "name": "Balfour, Arthur J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 408,
      "name": "Balhan, Jean-Marc, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 409,
      "name": "Ball Robert Stawell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 410,
      "name": "Ball, William Platt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 411,
      "name": "Ballantyne, James R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 412,
      "name": "Ballantyne, R.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 413,
      "name": "Ballerini Antonius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 415,
      "name": "Ballings, M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 416,
      "name": "Ballou, Robert O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 417,
      "name": "Balmes Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 418,
      "name": "Balmes Jacques Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 419,
      "name": "Balmes James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 420,
      "name": "Balmes James & Browson F. Henry [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 421,
      "name": "Balmes, James & Mcdonald, William [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 422,
      "name": "Balthasar, N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 423,
      "name": "Bampton, J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 424,
      "name": "Banaphul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 425,
      "name": "Banaphula",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 426,
      "name": "Bances, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 427,
      "name": "Banckaert Henry C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 428,
      "name": "Bandas Rudolph G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 429,
      "name": "Bandyopadhyay, Bibhutibhusan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 430,
      "name": "Bandyopadhyay, Brojendra Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 431,
      "name": "Bandyopadhyay, Candicaran & Pandeya, R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 432,
      "name": "Bandyopadhyay, Harimohan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 433,
      "name": "Bandyopadhyay, Manik",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 434,
      "name": "Bandyopadhyay, R.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 435,
      "name": "Bandyopadhyay, Rakhaladas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 436,
      "name": "Bandyopadhyay, S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 437,
      "name": "Bandyopadhyay, Sekhar [ed.], Bagchi, Tilak [ed.] & Bhattacarya, Ranjit Kumar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 438,
      "name": "Bandyopadhyay, Tarasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 439,
      "name": "Bandyopadhyay-Dube, Ishita [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 440,
      "name": "Bangha Adalbert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 441,
      "name": "Bangha, Adalbertus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 442,
      "name": "Barackman, Deborah [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 443,
      "name": "Baran, Paul A. & Sweezy, Paul M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 444,
      "name": "Bar-Asher, Meir M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 445,
      "name": "Barbeau, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 446,
      "name": "Barboza, Francis Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 447,
      "name": "Barclay, Florence L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 448,
      "name": "Bardenhewer Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 449,
      "name": "Bardoloi, B.K. [Ed.] & Athaparia, R.K. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 451,
      "name": "Bardy, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 452,
      "name": "Bardy, Gustave & Tricot, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 453,
      "name": "Barenton, Hilaire de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 454,
      "name": "Bargilliat M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 455,
      "name": "Barillon, E., Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 456,
      "name": "Baring, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 457,
      "name": "Baring-Gould, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 458,
      "name": "Barker M.L. & Homeyer H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 459,
      "name": "Barker, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 460,
      "name": "Barker, Ethel Ross",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 461,
      "name": "Barker, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 462,
      "name": "Barker, Paul A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 463,
      "name": "Barker, W.H. & Brooks, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 464,
      "name": "Barnard Louis Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 465,
      "name": "Barnard, H. Clive",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 466,
      "name": "Barnard, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 467,
      "name": "Barnes Arthur Stapylton Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 468,
      "name": "Barnes, Michael Horace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 469,
      "name": "Barnes, W. Emery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 470,
      "name": "Barnet, Richard J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 471,
      "name": "Barnett, S.A. & Barnett, S.A., Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 472,
      "name": "Baron, Abbe Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 473,
      "name": "Barr, Allan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 474,
      "name": "Barraclough, Geoffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 475,
      "name": "Barre, H.L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 476,
      "name": "Barres Philippe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 477,
      "name": "Barrett E. Boyd S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 478,
      "name": "Barrett James Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 479,
      "name": "Barrett, Charles Kingsley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 480,
      "name": "Barrett, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 481,
      "name": "Barrett, Helen M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 482,
      "name": "Barrett, Michael, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 483,
      "name": "Barrois A.-G. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 485,
      "name": "Barry William A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 486,
      "name": "Barry, Christian [ed.], Herman, Barry [ed.] & Tomitova, Lydia [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 487,
      "name": "Barry, Michael Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 488,
      "name": "Barth Karl (1886-1968) Reiffen Hannelotte [ed.] & Bromiley Geoffrey W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 489,
      "name": "Barth Karl (1886-1968) Ritschi Dietrich [ed.] & Bromiley Geoffrey W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 490,
      "name": "Barth Markus & Blanke Helmut",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 491,
      "name": "Barth, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 492,
      "name": "Barth, Karl & Horton, Douglas [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 493,
      "name": "Barth, Karl & Hoskyns, Edwyn Clement [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 494,
      "name": "Barth, Karl & Hoyle, R. Birch [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 495,
      "name": "Barth, Karl, Haire, J.L.M. [transl.] & Henderson, Ian [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 496,
      "name": "Barth, Markus, Blanke, Helmut & Beck, Astrid B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 497,
      "name": "Barthe J.M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 499,
      "name": "Bartholomew, J.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 500,
      "name": "Bartholomew, John M.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 501,
      "name": "Bartimeus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 502,
      "name": "Bartlet, James Vernon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 504,
      "name": "Bartmann, Bernhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 505,
      "name": "Bartmann, Bernhard & Graf, Ernest, O.S.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 506,
      "name": "Bartoli, Daniello S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 508,
      "name": "Barton John (1948-  ) [ed.] & Muddiman John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 509,
      "name": "Barton John M.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 510,
      "name": "Barton, George Aaron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 511,
      "name": "Barton, John (1948-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 512,
      "name": "Barua Indira [ed.]; Sengupta Sarthak [ed.] & Das Deepanjana Dutta [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 513,
      "name": "Barua, Dipak Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 514,
      "name": "Baruva, Benimadhav",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 515,
      "name": "Baruzi, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 516,
      "name": "Basenach F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 517,
      "name": "Bashiruddin Mahmud Ahmad, Hazrat Mirza",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 518,
      "name": "Basilius I Magnus Caesar. Cap. Archiep. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 519,
      "name": "Baskerville, Geoffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 520,
      "name": "Basly, Deodat de, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 521,
      "name": "Basset, Bernard, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 522,
      "name": "Bassett William [ed.] & Huizing Peter S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 523,
      "name": "Bassler, Jouette M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 524,
      "name": "Bastable C.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 525,
      "name": "Bastide, Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 526,
      "name": "Bastien Pierre O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 527,
      "name": "Basu, Aparna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 528,
      "name": "Basu, Buddhadeb",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 529,
      "name": "Basu, Maladhara & Mitra, Khagendranatha [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 530,
      "name": "Basu, Yogindranath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 531,
      "name": "Batchelor Edward Jr. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 532,
      "name": "Bates, M. Searle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 533,
      "name": "Batiffol Pierre Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 535,
      "name": "Batliwala S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 536,
      "name": "Batra, N.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 537,
      "name": "Batra, R.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 538,
      "name": "Battandier Alber Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 539,
      "name": "Batten, Loring W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 540,
      "name": "Bauckham, Richard J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 542,
      "name": "Bauckham, Richard J. & Hart, Trevor A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 543,
      "name": "Baudhuin, Fernand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 544,
      "name": "Baudissin, Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 545,
      "name": "Baudissin, Wolf Wilhelm Grafen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 546,
      "name": "Baudot O.S.b.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 547,
      "name": "Baudot Prosper S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 548,
      "name": "Baudot, Jules, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 549,
      "name": "Baudot, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 550,
      "name": "Baudrillart Alfred Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 551,
      "name": "Baudrillart Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 552,
      "name": "Baudry, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 553,
      "name": "Bauer Florence Marvyne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 554,
      "name": "Bauer, Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 555,
      "name": "Bauer, Walter (1877-1960)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 556,
      "name": "Bauerochse Lothar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 557,
      "name": "Bauhofer, Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 558,
      "name": "Baulez, M.-J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 559,
      "name": "Baum Gregory G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 561,
      "name": "Baumann, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 562,
      "name": "Baumeister Ansgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 564,
      "name": "Baumgarten, N. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 565,
      "name": "Baunard Louis Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 566,
      "name": "Baur, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 567,
      "name": "Bauwens Evarist S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 569,
      "name": "Bauwens, Evarist, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 570,
      "name": "Bauwens, Isidoor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 571,
      "name": "Baxter, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 572,
      "name": "Bayard L. Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 573,
      "name": "Bayart, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 574,
      "name": "Bayfield, Rabbi Tony [ed.], Brichto, Rabbi Sidney [ed.] & Fisher, Dugene J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 575,
      "name": "Bayma I. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 576,
      "name": "Bayma, Giuseppe, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 577,
      "name": "Bazin Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 578,
      "name": "Bea, Augustinus, S.J. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 579,
      "name": "Beale Charles E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 580,
      "name": "Beales, A.C.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 581,
      "name": "Bean, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 582,
      "name": "Bearne, David, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 583,
      "name": "Beattie Tina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 585,
      "name": "Beaudenom Leopold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 586,
      "name": "Beaudenom, L., Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 587,
      "name": "Beauduin Lambert O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 588,
      "name": "Beaufretion Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 589,
      "name": "Beaumont Francis & Sargeaunt M.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 590,
      "name": "Beauvais, Gilles Francois de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 591,
      "name": "Becanus Martinus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 592,
      "name": "Beccari C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 593,
      "name": "Beck, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 594,
      "name": "Becke, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 595,
      "name": "Becke, Louis & Jeffery, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 596,
      "name": "Becker, Kenneth L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 597,
      "name": "Beckx Petrus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 598,
      "name": "Beckx Petrus S.J. & Hazeland Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 599,
      "name": "Beclard J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 600,
      "name": "Beda Venerabilis Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 601,
      "name": "Bedini Balduino O.Cist.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 602,
      "name": "Beech Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 603,
      "name": "Beeding, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 604,
      "name": "Beentjes, Pancratius C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 605,
      "name": "Beer, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 606,
      "name": "Beeton, Samuel Orchart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 607,
      "name": "Begasse De Dhaen P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 608,
      "name": "Beglar, J.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 609,
      "name": "Beguin, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 610,
      "name": "Begum, Jafri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 611,
      "name": "Behr-Sigel Elisabeth & Ware Kallistos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 612,
      "name": "Beissel Stephan S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 613,
      "name": "Belanger Auguste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 614,
      "name": "Bell, Bernard Iddings [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 615,
      "name": "Bell, C.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 616,
      "name": "Bell, G.K.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 618,
      "name": "Bell, R.S. Warren",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 619,
      "name": "Bellamann, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 620,
      "name": "Bellamy J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 622,
      "name": "Bellarminus, Robertus, S.J., Card., Stus. (1542-1621)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 623,
      "name": "Bellecius Aloysius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 624,
      "name": "Bellecius Aloysius S.J. & Berthon M. Lous [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 625,
      "name": "Bellecius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 626,
      "name": "Bellecius, Aloysius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 627,
      "name": "Bellessort Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 628,
      "name": "Bello, P. Stephane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 630,
      "name": "Belloc, Hilaire",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 631,
      "name": "Bellon K.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 632,
      "name": "Bellot Paul O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 634,
      "name": "Bellynck, A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 635,
      "name": "Belmond S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 636,
      "name": "Beltrami Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 637,
      "name": "Belvalkar, S.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 638,
      "name": "Benard, Edmond Darvil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 639,
      "name": "Benci, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 640,
      "name": "Benedictus Abbas O.S.B. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 642,
      "name": "Benedictus XIV, Pp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 643,
      "name": "Benedictus, Abbas, O.S.B., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 644,
      "name": "Benjamin Joshua M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 645,
      "name": "Benjamin, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 646,
      "name": "Benn, A.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 647,
      "name": "Bennett, Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 648,
      "name": "Bennett, Clinton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 649,
      "name": "Bennigsen, George [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 650,
      "name": "Bennigsen, George, Count [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 651,
      "name": "Benouville, Guillain de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 652,
      "name": "Benson Edward Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 653,
      "name": "Benson Robert Hugh Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 654,
      "name": "Benson, A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 655,
      "name": "Benson, Edward Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 656,
      "name": "Benson, Edward White",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 657,
      "name": "Benson, Robert Hugh, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 658,
      "name": "Bentham, Jeremy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 659,
      "name": "Bentley, E.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 660,
      "name": "Bentley, G.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 661,
      "name": "Bentwich, Norman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 662,
      "name": "Benziger, Marieli",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 663,
      "name": "Benzinger, I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 664,
      "name": "Benzinger, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 665,
      "name": "Berardi Aemilius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 666,
      "name": "Berault - Bercastel De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 667,
      "name": "Beraza Blasius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 668,
      "name": "Berchmans Joannes S.J. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 670,
      "name": "Berdyaev, Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 671,
      "name": "Berdyaev, Nicolas & French, R.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 672,
      "name": "Berenguer, Dominic, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 673,
      "name": "Beresford, J.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 674,
      "name": "Berg, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 675,
      "name": "Berger Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 676,
      "name": "Bergers, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 677,
      "name": "Bergier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 678,
      "name": "Bergier Nicolas Sylvestre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 679,
      "name": "Bergmann Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 680,
      "name": "Bergner, Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 681,
      "name": "Bergounioux, F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 682,
      "name": "Bergson, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 683,
      "name": "Bergson, Henri & Mitchell, Arthur [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 684,
      "name": "Beringer Franz S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 685,
      "name": "Berinton Joseph & Kirk John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 686,
      "name": "Berkeley, G.F.H. & Berkeley, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 688,
      "name": "Berkley, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 689,
      "name": "Berkouwer, G.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 691,
      "name": "Berliere, Ursmer, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 693,
      "name": "Bermejo, Luis M., S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 694,
      "name": "Bermond Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 696,
      "name": "Bernadot, M.V., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 697,
      "name": "Bernanos, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 698,
      "name": "Bernanos, Georges & Morris, Pamela [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 699,
      "name": "Bernanos, Georges & Varese, Louise [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 700,
      "name": "Bernard Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 701,
      "name": "Bernard Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 702,
      "name": "Bernard R. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 703,
      "name": "Bernard, H. Russell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 704,
      "name": "Bernard, J.H. & Mcneile, A.H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 705,
      "name": "Bernards Montique P.L.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 707,
      "name": "Bernardus Claraevallensis Abb. O.Cist. Stus. & Connolly L. Terence S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 708,
      "name": "Bernardus Claraevallensis, Abb. O. Cist., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 709,
      "name": "Berne, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 710,
      "name": "Berne-Joffroy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 711,
      "name": "Bernhardi, Friedrich von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 712,
      "name": "Bernhart, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 713,
      "name": "Bernheim Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 715,
      "name": "Bernier, Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 716,
      "name": "Bernoville Gaetan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 717,
      "name": "Bernstein J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 718,
      "name": "Berrigan, Daniel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 719,
      "name": "Berriozabal J.-E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 720,
      "name": "Berrueta, Juan Dominguez & Chevalier, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 721,
      "name": "Berry, E. Sylvester",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 722,
      "name": "Bertarelli, L.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 723,
      "name": "Bertenshaw T.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 724,
      "name": "Berthaut, H. & Georgin, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 725,
      "name": "Berthe, Auguste, C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 726,
      "name": "Berthelot, Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 727,
      "name": "Berthier, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 728,
      "name": "Berthier, Guillaume, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 729,
      "name": "Berthier, Joachim Joseph, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 730,
      "name": "Berthold-Ignace De Sainte-Anne, O.Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 731,
      "name": "Bertholet, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 732,
      "name": "Bertke, Stanley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 733,
      "name": "Berton Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 734,
      "name": "Bertram Adolf Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 735,
      "name": "Bertrand F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 736,
      "name": "Bertrand Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 737,
      "name": "Bertrand, Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 738,
      "name": "Bertrand, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 739,
      "name": "Bertrand, Louis & Chase, Cleveland B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 740,
      "name": "Bertrin Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 741,
      "name": "Bertrin Georges & Gibbs Philip SVD [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 742,
      "name": "Berulle, Pierre de C. Orat., Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 743,
      "name": "Berutti, Christophorus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 744,
      "name": "Besnier, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 745,
      "name": "Besse Clement Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 746,
      "name": "Besse Leon S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 747,
      "name": "Bessedovsky, Grigory & Norgate, Matthew [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 748,
      "name": "Bessieres Albert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 749,
      "name": "Besson Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 750,
      "name": "Besson Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 751,
      "name": "Best, Thomas F. [ed.] & Heller, Dagmar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 752,
      "name": "Beste, Udalricus, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 753,
      "name": "Besterfeld, Elisabeth de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 754,
      "name": "Bethune-Baker, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 755,
      "name": "Bett, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 756,
      "name": "Bettany, G.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 757,
      "name": "Bettenson, Henry [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 758,
      "name": "Bettiolo Paolo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 759,
      "name": "Betz, Hans Dieter [ed.]; Browning, Don S. [ed.]; Janowski, Bernd [ed.] & Jungel, Eberhard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 760,
      "name": "Betz, Hans Dieter & Collins, Adela Yarbro [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 761,
      "name": "Beuvellet Matthieu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 762,
      "name": "Bevans, Stephen B., SVD (1944-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 763,
      "name": "Bevenot, Hugh, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 764,
      "name": "Bevenot, Maurice, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 765,
      "name": "Beveridge, Sir William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 766,
      "name": "Beveridge, William H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 767,
      "name": "Beverley, Eric Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 768,
      "name": "Beylard, Hugues, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 769,
      "name": "Beyley, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 770,
      "name": "Beza, Theodorus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 771,
      "name": "Bhagat, Chetan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 772,
      "name": "Bhambhri, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 773,
      "name": "Bhandari, Sukhsampattirai",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 774,
      "name": "Bhandarkar, D.R. & Majumdar, Surendranath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 775,
      "name": "Bharati, Sadananda",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 776,
      "name": "Bharatiya [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 777,
      "name": "Bharatiya, Santosh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 778,
      "name": "Bharihoke, Neera [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 780,
      "name": "Bhatt, S.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 781,
      "name": "Bhattacarya Moitree",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 782,
      "name": "Bhattacarya Narendra Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 783,
      "name": "Bhattacarya, Sabyasachi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 784,
      "name": "Bhattasali, Nalini Kanta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 785,
      "name": "Bhuimali, Anil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 786,
      "name": "Bhushan K. & Katyal G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 787,
      "name": "Bhutto, Benazir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 788,
      "name": "Biaggi, Antonio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 789,
      "name": "Bibby, Peter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 790,
      "name": "Bickersteth, Edward, Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 791,
      "name": "Bicknell, E.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 792,
      "name": "Bidagor, Ramon, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 793,
      "name": "Bideau Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 794,
      "name": "Bidpai",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 795,
      "name": "Biederlack, Josephus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 796,
      "name": "Bigg, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 797,
      "name": "Biggar, Nigel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 798,
      "name": "Bilderdijk, Willem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 800,
      "name": "Billot, Louis, S.J., Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 802,
      "name": "Billuart, Rene, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 803,
      "name": "Billy, Dennis J. [ed.] & Orsuto, Donna Lynn [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 804,
      "name": "Binet, Etienne, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 805,
      "name": "Bingham, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 807,
      "name": "Biot, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 808,
      "name": "Biot, Rene & Galimard, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 809,
      "name": "Biot, Rene, Goedseels, L. & Mersch, Emile, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 810,
      "name": "Birbal Sahni & Willis, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 811,
      "name": "Bird, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 812,
      "name": "Bird, Thomas E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 813,
      "name": "Birdwood, George C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 814,
      "name": "Birkhaeuser J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 815,
      "name": "Birks, T.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 816,
      "name": "Birla, Dhanasyamdas & Simh, Parsanath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 817,
      "name": "Birrell Augustine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 818,
      "name": "Birt, Henry Norbert, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 819,
      "name": "Bischoff, William N., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 820,
      "name": "Bishop, Edmund (1846-1917)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 821,
      "name": "Bisi, Pramath Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 822,
      "name": "Biskupek, A., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 823,
      "name": "Biswas, Anirban",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 824,
      "name": "Biswas, Girban Ranjan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 825,
      "name": "Bivort, De La Saudee Jacques De & Dingle, Reginald J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 826,
      "name": "Blacam, Hugh de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 827,
      "name": "Black, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 828,
      "name": "Blackmore, R.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 829,
      "name": "Blackwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 830,
      "name": "Blaine, Bradford B., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 831,
      "name": "Blair, David Hunter, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 832,
      "name": "Blair, Hugh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 833,
      "name": "Blake, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 834,
      "name": "Blakely, Paul L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 835,
      "name": "Blanc De Saint Bonnet A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 836,
      "name": "Blanc, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 837,
      "name": "Blanchan, Neltje",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 838,
      "name": "Blanco-Hoevers, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 839,
      "name": "Blass Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 840,
      "name": "Blass, Fridericus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 842,
      "name": "Blatz, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 843,
      "name": "Blazy J. & Paynes Charles [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 844,
      "name": "Blee, Fabrice (1968-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 845,
      "name": "Bleek, Johannes Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 846,
      "name": "Blennerhassett, Charlotte Lady & Gulcher, Edith [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 847,
      "name": "Bless H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 848,
      "name": "Bloch, Gustave & Carcopino, Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 849,
      "name": "Blochet, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 850,
      "name": "Block, Daniel I. (1943-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 851,
      "name": "Blok, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 853,
      "name": "Blondel, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 854,
      "name": "Bloomfield, S.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 855,
      "name": "Bloomquist, Karen L. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 856,
      "name": "Blot, Francois, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 857,
      "name": "Blouet, J., P.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 858,
      "name": "Blouet, P., P.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 859,
      "name": "Blount Charles F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 860,
      "name": "Bloy, Leon & Coleman, John A., S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 861,
      "name": "Bluck, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 862,
      "name": "Blumenthal Uta-Renate [ed.] Winroth Anders [ed.] & Landau Peter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 863,
      "name": "Blumer, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 864,
      "name": "Blundell, Francis, Mrs. (Pseudon. Francis M.E.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 865,
      "name": "Blunt, John Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 866,
      "name": "Blunt, T.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 867,
      "name": "Blyton, W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 868,
      "name": "Boadt Lawrence E. C.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 869,
      "name": "Boase, Roger [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 870,
      "name": "Boase, T.S.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 871,
      "name": "Bobadilla Nicolaus Alphonsus De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 872,
      "name": "Bockmuehl Markus [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 873,
      "name": "Bockmuehl, Markus [ed.] & Hagner, Donald Alfred [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 874,
      "name": "Boda, Mark J. [ed.] & Smith, Gordon T. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 875,
      "name": "Boddeke, H., C.ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 876,
      "name": "Bodding, P.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 877,
      "name": "Bodin Eug. C. Miss.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 878,
      "name": "Bodington, Oliver E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 879,
      "name": "Bodley, R.V.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 880,
      "name": "Body, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 881,
      "name": "Boeckl Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 882,
      "name": "Boedder Bernardus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 883,
      "name": "Boehmer, Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 885,
      "name": "Boero Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 886,
      "name": "Boero, Giuseppe, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 887,
      "name": "Boesak, Willa (1948-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 888,
      "name": "Boeteman, Lud., S.J. (Pseud. Montabee)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 889,
      "name": "Boeve, Lieven [ed.], Geybels, Hans [ed.] & Van Den Bossche, Stijn [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 890,
      "name": "Bogaerts Theo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 891,
      "name": "Bogaerts, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 892,
      "name": "Bohl, F.M. Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 894,
      "name": "Boigelot, Rene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 895,
      "name": "Boirac, Emile & Kerlor, W. De [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 896,
      "name": "Boismard Marie-Emile & Beaumont Madeleine [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 897,
      "name": "Boissarie Dr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 899,
      "name": "Boissier, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 900,
      "name": "Boissonnet, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 901,
      "name": "Boitard M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 902,
      "name": "Boland, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 903,
      "name": "Bolliger, Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 904,
      "name": "Bolton L.B.V. & Relf R.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 905,
      "name": "Bolton Rel. Of The Cen.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 906,
      "name": "Bominghaus E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 907,
      "name": "Bon Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 909,
      "name": "Bona, Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 911,
      "name": "Bonaventura, O.F.M., Card., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 912,
      "name": "Bonaventura, O.F.M., Card., Stus. & Lewis, Bernard [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 913,
      "name": "Bonaventura, O.F.M., Card., Stus. & Nemmers, Erwin Esser [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 914,
      "name": "Bondel, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 915,
      "name": "Bondini, Aloisius, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 916,
      "name": "Bongioanni, Fausto M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 917,
      "name": "Bonhoeffer, Dietrich & Weber, Manfred [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 918,
      "name": "Bonifacius, Winifridus, Mogunt. Arch. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 919,
      "name": "Bonk, Jonathan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 920,
      "name": "Bonkamp, Bernhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 921,
      "name": "Bonnar, A., O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 922,
      "name": "Bonnard, Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 923,
      "name": "Bonneau Guy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 924,
      "name": "Bonnechose Henri-Marie-Gaston de Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 925,
      "name": "Bonnefoy Jean-Francois O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 926,
      "name": "Bonnegent C. Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 927,
      "name": "Bonner Campbell [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 928,
      "name": "Bonner, Hypatia Bradlaugh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 929,
      "name": "Bonneval, P.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 930,
      "name": "Bonney, Richard (1947-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 931,
      "name": "Bonniot J. de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 932,
      "name": "Bonniwell, William R., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 933,
      "name": "Bonomelli Geremia Vesc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 935,
      "name": "Bonsirven, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 936,
      "name": "Bonus John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 937,
      "name": "Bonzelet, Honoratus, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 938,
      "name": "Booth, Bramwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 939,
      "name": "Booth, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 940,
      "name": "Boothby, Guy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 941,
      "name": "Booth-Tucker, F. De L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 942,
      "name": "Borchardt, W.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 943,
      "name": "Bord J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 944,
      "name": "Bordeaux Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 945,
      "name": "Borden, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 946,
      "name": "Borelli John W. [ed.] & Erickson John H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 947,
      "name": "Borgia Franciscus S.J. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 948,
      "name": "Borgo Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 949,
      "name": "Borkowski Stanislaus V. Dunin S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 950,
      "name": "Borne Etienne & Henry Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 951,
      "name": "Borrow George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 952,
      "name": "Bos, P.R. & Niermeyer, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 953,
      "name": "Bosanquet, Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 955,
      "name": "Bosanquet, Helen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 956,
      "name": "Bosco, Don Giovanni, SDB (1815-1888)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 957,
      "name": "Bose, Ajoy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 958,
      "name": "Bose, Basanta Coomar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 959,
      "name": "Bose, P.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 960,
      "name": "Bose, Tarun",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 961,
      "name": "Bosmans H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 963,
      "name": "Bossu, Antonin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 966,
      "name": "Bossuet, Jacques Benigne, Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 967,
      "name": "Boswin S. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 968,
      "name": "Bosworth, F.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 969,
      "name": "Bottalla Emmanuelle S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 970,
      "name": "Bottalla, Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 971,
      "name": "Botte Bernard O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 972,
      "name": "Botte, Bernard, O.S.B., Charlier, O.P., Robeyns, A. & Capelle, Bernard, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 973,
      "name": "Boubee Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 974,
      "name": "Boubee, Joseph, S.J. & Parra, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 975,
      "name": "Boucharlat J.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 976,
      "name": "Bouchat J. Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 977,
      "name": "Bouchon Lucien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 978,
      "name": "Boudinhon A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 979,
      "name": "Boudon Henri-Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 980,
      "name": "Boudou, Adrien, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 981,
      "name": "Boudreaux F.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 982,
      "name": "Bouesse, Humbert, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 983,
      "name": "Bouffier Gabriel S.J. & Cecilia Madame [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 984,
      "name": "Bougaud Emile Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 985,
      "name": "Bougaud Emile Eveq. & Hazeland Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 986,
      "name": "Bouhours Dominic S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 987,
      "name": "Bouilhet, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 988,
      "name": "Bouillet Marie-Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 989,
      "name": "Bouillet, N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 990,
      "name": "Bouix D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 991,
      "name": "Bouix Marcel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 992,
      "name": "Boukharine, N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 993,
      "name": "Boule Marcellin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 994,
      "name": "Boulgakoff, Serge",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 995,
      "name": "Boullard, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 996,
      "name": "Boullon, A.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 997,
      "name": "Boulter, B.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 998,
      "name": "Bouman R. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 999,
      "name": "Bouman, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1000,
      "name": "Bouma-Prediger, Steven",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1001,
      "name": "Bouquillon, Thomas Jos.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1002,
      "name": "Bourdaloue Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1003,
      "name": "Bourdieu, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1005,
      "name": "Bourget, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1006,
      "name": "Bourke, Vernon J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1007,
      "name": "Bourne Francis Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1008,
      "name": "Bourne, E.C.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1009,
      "name": "Bourzes, Louis Noel De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1010,
      "name": "Bouscaren Timothy Lincoln S.J. & Ellis Adam C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1011,
      "name": "Bouscaren, Pierre, S.J. & Hornsby, William L., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1012,
      "name": "Bouscaren, Timothy Lincoln, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1013,
      "name": "Bouscaren, Timothy Lincoln, S.J. & Ellis, Adam C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1014,
      "name": "Bouscaren, Timothy Lincoln, S.J.; O'Connor, James I., S.J. & Pfnausch, Edward G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1015,
      "name": "Bousquet Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1016,
      "name": "Bousset, Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1017,
      "name": "Boutaric, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1018,
      "name": "Boutell, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1020,
      "name": "Boutroux, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1021,
      "name": "Bouvat, Lucien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1022,
      "name": "Bouvet Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1023,
      "name": "Bouvier J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1025,
      "name": "Bouvier, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1026,
      "name": "Bouyer, Louis Congr. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1027,
      "name": "Bouyssonie A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1028,
      "name": "Bover, Joseph Maria, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1029,
      "name": "Bowden, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1030,
      "name": "Bowden, Witt, Karpovich, Michael & Usher, Abbott Payson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1031,
      "name": "Bowen J.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1032,
      "name": "Bowen, Marjorie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1033,
      "name": "Bowker, John W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1034,
      "name": "Box Hubert S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1035,
      "name": "Boyer Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1036,
      "name": "Boyer Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1037,
      "name": "Boyer d'Agen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1038,
      "name": "Boyle, Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1039,
      "name": "Boyle, Nicholas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1040,
      "name": "Boylesve Marin De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1042,
      "name": "Braaten, Carl E. (1929-    ) [ed.] & Jenson, Robert W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1043,
      "name": "Brabant, Formin, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1044,
      "name": "Brabham, Mouzon William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1045,
      "name": "Brace, Josh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1046,
      "name": "Bracken Joseph A. S.J. [ed.] & Suchocki Marjorie Hewitt [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1047,
      "name": "Braddon, M.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1048,
      "name": "Bradlaugh, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1049,
      "name": "Bradley, Edith",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1050,
      "name": "Bradley, F.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1051,
      "name": "Bradshaw, John (1933-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1052,
      "name": "Brailsford, H.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1053,
      "name": "Bramah, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1054,
      "name": "Brandreth, Henry R.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1055,
      "name": "Brandscheid Fridericus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1057,
      "name": "Branson, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1058,
      "name": "Brasnett, Bertrand R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1059,
      "name": "Brass Paul R. [ed.] & Vanaik Achin [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1060,
      "name": "Brassac Auguste S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1061,
      "name": "Brassac Auguste S.S. & Weidenhan J.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1062,
      "name": "Brassey, Annie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1063,
      "name": "Brauer, T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1064,
      "name": "Braun, AD.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1065,
      "name": "Braun, F.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1066,
      "name": "Braun, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1067,
      "name": "Brawley, Robert Lawson [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1068,
      "name": "Bray Max De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1069,
      "name": "Bray, A., C.J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1071,
      "name": "Breal, Michel & Bailly, Anatole",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1072,
      "name": "Breaver, Cobham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1073,
      "name": "Breda, Gregorius Von, O.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1074,
      "name": "Brehier Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1075,
      "name": "Brehier Louis & Aigrain Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1076,
      "name": "Brehier, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1077,
      "name": "Breman, Jan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1078,
      "name": "Bremmer, Jan N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1079,
      "name": "Bremond Henri & Child Harold [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1080,
      "name": "Bremond Olnobrie S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1081,
      "name": "Bremond, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1082,
      "name": "Bremond, Henri & Sheed, F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1083,
      "name": "Brengle, Commissioner S.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1084,
      "name": "Brennan Martin S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1085,
      "name": "Brenner, Athalya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1086,
      "name": "Bressolles, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1088,
      "name": "Breton, Valentin M., O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1089,
      "name": "Bretonneau, L.-J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1090,
      "name": "Breul, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1091,
      "name": "Breznay A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1092,
      "name": "Briault, M., C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1093,
      "name": "Bricout, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1094,
      "name": "Bridge, P.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1095,
      "name": "Bridgett T.E. C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1096,
      "name": "Briggs, Charles Augustus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1097,
      "name": "Brilioth, Yngve",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1099,
      "name": "Brillant, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1100,
      "name": "Brinckman, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1101,
      "name": "Brinkmeyer Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1102,
      "name": "Brinktrine, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1103,
      "name": "Brion, Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1104,
      "name": "Britten, James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1105,
      "name": "Britten, James & Grower, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1106,
      "name": "Britton, Joseph H. & Brayley, E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1107,
      "name": "Broadfield, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1108,
      "name": "Brocard Of St. Thomas T.O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1109,
      "name": "Brock, Brian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1110,
      "name": "Brodbeck, Simon [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1111,
      "name": "Brodrible, W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1113,
      "name": "Brodrick, James, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1114,
      "name": "Brodrick, M. & Morton, A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1115,
      "name": "Brody, Abraham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1117,
      "name": "Broeckaert, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1118,
      "name": "Broeckaert, Norbertus O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1119,
      "name": "Brogan, D.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1120,
      "name": "Broglie Abbe de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1121,
      "name": "Broglie Albert Duc De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1122,
      "name": "Broglie Emmanuel De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1123,
      "name": "Broglie Emmanuel De & Partridge Mildred [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1124,
      "name": "Broglie, Abbe De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1125,
      "name": "Broglie, G. De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1126,
      "name": "Broia Giuseppe S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1127,
      "name": "Bron, Ludovic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1129,
      "name": "Bronte, Charlotte (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1130,
      "name": "Brooke Christopher Nugent Lawrence (1927-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1131,
      "name": "Brooke, A.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1132,
      "name": "Brooke, Dorothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1133,
      "name": "Brooke, John & Cantor, Geoffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1134,
      "name": "Brooke, Stopford A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1135,
      "name": "Brookfield Charles H.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1136,
      "name": "Brookfield, Charles H.E. & Frances",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1137,
      "name": "Brooks, Phillips",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1138,
      "name": "Brooks, Stratton D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1139,
      "name": "Bros A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1140,
      "name": "Brosnan William S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1141,
      "name": "Brosnan, Gulielmus J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1142,
      "name": "Broster, D.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1144,
      "name": "Brou, Alexandre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1145,
      "name": "Brou, Alexandre, S.J. & Gibert, F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1146,
      "name": "Brouet Pasquier S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1147,
      "name": "Brouillon, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1148,
      "name": "Broussolle J.-C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1149,
      "name": "Brouwers, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1150,
      "name": "Browe Petrus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1151,
      "name": "Brown Alexandra R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1152,
      "name": "Brown Il, Sanger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1153,
      "name": "Brown Lester R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1154,
      "name": "Brown Raymond E. S.S. (1928-1998)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1155,
      "name": "Brown Stephen J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1156,
      "name": "Brown W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1157,
      "name": "Brown, Brian Arthur (1942-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1158,
      "name": "Brown, Charles Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1159,
      "name": "Brown, Deborah A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1160,
      "name": "Brown, Francis; Driver, S.R. & Briggs, Charles A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1161,
      "name": "Brown, George W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1162,
      "name": "Brown, Jeannine K. (1961-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1163,
      "name": "Brown, John & Cooke, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1164,
      "name": "Brown, Jonathan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1165,
      "name": "Brown, Judith M. (1944-  ) [ed.] & Frykenberg, Robert Eric [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1166,
      "name": "Brown, Mary Willcox",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1167,
      "name": "Brown, Stephen J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1168,
      "name": "Brown, Sydney L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1169,
      "name": "Brown, W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1170,
      "name": "Browne, Edward Harold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1171,
      "name": "Browne, Laurence E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1172,
      "name": "Browne, Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1173,
      "name": "Browne, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1174,
      "name": "Browning, Elizabeth Barrett",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1175,
      "name": "Browning, Robert & Blakeney, E.H. [ed. & Introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1176,
      "name": "Brucculeri, Angelo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1177,
      "name": "Bruce, Alexander Balmain",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1178,
      "name": "Bruce, Frederick Fyvie (1910-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1179,
      "name": "Brucker Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1180,
      "name": "Bruder, Carolus Hermannus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1181,
      "name": "Brueck Heinrich & Pruente E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1182,
      "name": "Brueggemann, Walter [ed.] & Stroup, George W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1184,
      "name": "Bruehl, Charles P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1185,
      "name": "Brueteau Beatrice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1186,
      "name": "Bruhl, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1187,
      "name": "Bruin P.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1188,
      "name": "Bruley, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1189,
      "name": "Brun J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1190,
      "name": "Bruneau Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1191,
      "name": "Brunetiere, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1192,
      "name": "Brunhes Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1193,
      "name": "Brunhes Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1194,
      "name": "Brunner August S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1195,
      "name": "Brunner Augustinus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1196,
      "name": "Brunner, Emil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1197,
      "name": "Brunner, Emil & Hottinger, Mary [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1198,
      "name": "Brunner, Emil & Wyon, Olive [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1199,
      "name": "Brunner, Emil; Barth, Karl & Fraenkel, Peter [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1200,
      "name": "Brunner, Rainer [ed.] & Ende, Werner [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1201,
      "name": "Bruno De Jesus-Marie O.C.D. & Zimmerman Benedict [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1202,
      "name": "Brunschvicg, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1203,
      "name": "Brunschwicg, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1204,
      "name": "Brutails, J.-A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1205,
      "name": "Bruun, Malte Conrad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1206,
      "name": "Bryan, David Burton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1207,
      "name": "Bryans C. & Hendy F.J.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1208,
      "name": "Bryce James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1209,
      "name": "Bryden H.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1210,
      "name": "Brys, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1211,
      "name": "Bsteh, Andreas [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1212,
      "name": "Buathier J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1214,
      "name": "Bucceroni, Januarius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1215,
      "name": "Buchanan, Donald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1216,
      "name": "Buchberger Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1217,
      "name": "Buckler, Reginald, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1218,
      "name": "Buckley, Theodore Alois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1219,
      "name": "Budde, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1220,
      "name": "Budde, Karl, Bertholet, Alfred & Wildeboer, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1221,
      "name": "Buddhadatta Mahathera, A.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1222,
      "name": "Budge, E.A. Wallis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1223,
      "name": "Buel, J.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1224,
      "name": "Buel, J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1225,
      "name": "Buell, Victor P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1226,
      "name": "Buffon, George Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1228,
      "name": "Buhl, Frants",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1229,
      "name": "Bulcke, Camille, S.J. (1909-1982)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1230,
      "name": "Bulgakov Sergius (1871-1944) & Kesich Lydia [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1231,
      "name": "Bull, Hedley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1232,
      "name": "Bullen, Frank T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1233,
      "name": "Bullet M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1234,
      "name": "Bulow, Bernard Furst Von; Dunlop, Geoffrey [transl.] & Voigt, F.A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1235,
      "name": "Bunsen, Christian Charles Josias",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1237,
      "name": "Bunyan, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1238,
      "name": "Burchett, W.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1239,
      "name": "Burckhardt Jacob & Middlemore S.G.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1240,
      "name": "Burckhardt, Carl J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1241,
      "name": "Burckhardt, Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1242,
      "name": "Bureau Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1243,
      "name": "Burger Lisbeth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1244,
      "name": "Burger, G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1245,
      "name": "Burgess, Gelett",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1246,
      "name": "Burgh, W.G.De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1247,
      "name": "Burghardt, Walter J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1248,
      "name": "Burke Thomas N. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1249,
      "name": "Burke, C.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1250,
      "name": "Burke, Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1251,
      "name": "Burke, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1252,
      "name": "Burke, S. Hubert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1253,
      "name": "Burke, Thomas N., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1254,
      "name": "Burkett, Delbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1256,
      "name": "Burkitt, F. Crawford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1257,
      "name": "Burnell, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1258,
      "name": "Burnett, Frances Hodgson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1259,
      "name": "Burney, C.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1260,
      "name": "Burnichon Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1261,
      "name": "Burns, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1262,
      "name": "Burns, George, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1263,
      "name": "Burns, Robert Ignatius, S.J. & Robertson, J. Logie [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1264,
      "name": "Burridge, Richard A. (1955-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1265,
      "name": "Burrows Millar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1266,
      "name": "Burrows, Eric, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1267,
      "name": "Burton Katherine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1268,
      "name": "Burton, E. De Witt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1269,
      "name": "Burton, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1270,
      "name": "Burton, Francis James, C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1271,
      "name": "Burton, Harold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1272,
      "name": "Burton, Katherine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1273,
      "name": "Burton, Miles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1274,
      "name": "Burton, Richard F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1275,
      "name": "Burton-Christie, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1276,
      "name": "Busaeus Joannes S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1277,
      "name": "Busch, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1278,
      "name": "Buschbell Godofredus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1279,
      "name": "Busembaum Hermann S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1280,
      "name": "Bushnell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1281,
      "name": "Bushnell, Katharine C. (1856-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1282,
      "name": "Bussard, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1283,
      "name": "Bussierre, Th. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1284,
      "name": "Bustamante, F. Corona",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1285,
      "name": "Butavand, Arlette",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1286,
      "name": "Butaye R. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1287,
      "name": "Butler",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1288,
      "name": "Butler Alban (1710-73)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1289,
      "name": "Butler Armand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1290,
      "name": "Butler Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1291,
      "name": "Butler Cuthbert O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1292,
      "name": "Butler Harold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1293,
      "name": "Butler, Alban (1710-73)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1294,
      "name": "Butler, Arthur Gardiner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1295,
      "name": "Butler, Cuthbert, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1296,
      "name": "Butler, H.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1297,
      "name": "Butler, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1298,
      "name": "Butler, William F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1299,
      "name": "Butterfield, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1301,
      "name": "Buxtorfius, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1302,
      "name": "Buysschaert J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1303,
      "name": "Buzy D. C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1305,
      "name": "Buzy, Denis, S.C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1306,
      "name": "Byel Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1307,
      "name": "Byles C.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1308,
      "name": "Byrne, Brendan, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1309,
      "name": "Byron, Lord",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1311,
      "name": "Bythner, Victorinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1312,
      "name": "Caba Ssutios, Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1313,
      "name": "Cabrini Francesco S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1314,
      "name": "Cabrol Fernand O.S.B. & Leclercq Henri O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1315,
      "name": "Cabrol, Fernand, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1316,
      "name": "Cachupin Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1317,
      "name": "Cadbury, Henry J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1318,
      "name": "Cadiou Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1319,
      "name": "Cadiou Rene & Southwell John A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1320,
      "name": "Cadoux, Arthur Temple",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1322,
      "name": "Caels, Felix, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1323,
      "name": "Caesar, Caius Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1324,
      "name": "Caesarius Arelatensis Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1325,
      "name": "Cafferata, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1326,
      "name": "Cagatai, Mirza Azimbeg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1327,
      "name": "Cahill E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1329,
      "name": "Cahill, Lisa Sowle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1330,
      "name": "Caiger, Stephen L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1331,
      "name": "Caird, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1332,
      "name": "Caird, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1333,
      "name": "Cairns, D.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1334,
      "name": "Cakravarti Somen & Pal R.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1335,
      "name": "Cakravarti, Ashoke Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1336,
      "name": "Cakravarti, Bidyut [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1337,
      "name": "Calcott, Wellins",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1338,
      "name": "Caldecott, Stratford [ed.] & Morrill, John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1339,
      "name": "Calderwood, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1340,
      "name": "Caldiroli, Gioachino, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1341,
      "name": "Calepinus Ambrosius (C.1440-1510)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1342,
      "name": "Cales Jean S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1343,
      "name": "Calisch J.M. & Calisch N.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1345,
      "name": "Callan, Charles J., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1346,
      "name": "Callan, Charles J., O.P. & Mchugh, John A., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1347,
      "name": "Callebaert, K.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1348,
      "name": "Callewaert, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1349,
      "name": "Calmes Thomas Ss.Cc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1350,
      "name": "Calmette, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1351,
      "name": "Calmette, Joseph & Deprez, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1352,
      "name": "Calverley Edwin E. [ed.] Pollock James W. [ed.] Baydawi Abd Allah B. Umar B. Muhammad (D. 716/1316) & Isfahani Mahmud",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1353,
      "name": "Calvert, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1354,
      "name": "Calvet Arthur S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1355,
      "name": "Calvet Arthur S.J. & Doyle W. S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1356,
      "name": "Calvin, Jean (1509-1564)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1357,
      "name": "Calza, Guido",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1358,
      "name": "Cambell, G.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1359,
      "name": "Cambier O.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1361,
      "name": "Camerlynck Achilles & Coppieters H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1362,
      "name": "Camerlynck, Achilles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1363,
      "name": "Cameron, Evan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1364,
      "name": "Cameron, George G. & Levy, E.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1365,
      "name": "Camm, Bede, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1366,
      "name": "Cammack, J.S., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1367,
      "name": "Camoens",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1368,
      "name": "Campbell Margaret M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1369,
      "name": "Campbell Thomas J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1370,
      "name": "Campbell, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1371,
      "name": "Campbell, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1372,
      "name": "Campbell, Robert B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1373,
      "name": "Campeau, Lucien, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1374,
      "name": "Campigny H.-M.de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1375,
      "name": "Campion, Edmund, S.J., Btus. & Maggius L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1376,
      "name": "Camps Arnulf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1377,
      "name": "Camus, Jean-Pierre, Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1378,
      "name": "Cance Adrien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1379,
      "name": "Candlish, James S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1380,
      "name": "Candrankunnel, Mathew, CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1381,
      "name": "Candrasekharan C.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1382,
      "name": "Canger Ferdinando S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1383,
      "name": "Canisius Petrus S.J. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1384,
      "name": "Cannan, Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1386,
      "name": "Cantalamessa, Raniero & Villa, Frances, Lonergan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1387,
      "name": "Cantu Cesar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1388,
      "name": "Capart, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1389,
      "name": "Capelle, G.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1391,
      "name": "Caperan, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1392,
      "name": "Capes, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1393,
      "name": "Capitan L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1395,
      "name": "Cappello, Felix M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1396,
      "name": "Capreolus Johannes O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1397,
      "name": "Caputo, John D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1400,
      "name": "Caraman, Philip, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1401,
      "name": "Carbone Vincenzo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1402,
      "name": "Carbonelle I. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1403,
      "name": "Carbury, S., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1404,
      "name": "Carcopino, Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1405,
      "name": "Carcopino, Jerome; Rowell, Henry T. [ed.] & Lorimer, E.O. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1406,
      "name": "Caret, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1407,
      "name": "Carey, Ann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1408,
      "name": "Carleton, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1409,
      "name": "Carlleyle, A.C.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1410,
      "name": "Carlyle, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1411,
      "name": "Carmilla M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1412,
      "name": "Carmody, Denise Lardner (1935-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1414,
      "name": "Carnegie, Dale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1415,
      "name": "Carnegie, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1416,
      "name": "Carney, Edward J., OSFS",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1417,
      "name": "Carnot, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1419,
      "name": "Carnoy, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1420,
      "name": "Carpanius, Melchior, O. Barn.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1421,
      "name": "Carpenter, J. Estlin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1422,
      "name": "Carpenter, S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1423,
      "name": "Carpenter, William Boyd",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1424,
      "name": "Carpentier D.P. O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1425,
      "name": "Carr, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1426,
      "name": "Carr, John Dickson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1427,
      "name": "Carr, Robert M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1428,
      "name": "Carra de Vaux Baron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1429,
      "name": "Carre, A.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1430,
      "name": "Carre, Meyrick H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1431,
      "name": "Carretto, Carlo (1910-  ) & Diele, Joseph [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1432,
      "name": "Carrez Lud. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1434,
      "name": "Carriere, Josephus, S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1435,
      "name": "Carrieres, De S.S. & Menochius, J.-E., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1436,
      "name": "Carrin, Marine & Tambs-Lyche, Harald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1437,
      "name": "Carrington, Hereward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1438,
      "name": "Carrington, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1439,
      "name": "Carroll James R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1440,
      "name": "Carroll John T. & Carroll James R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1441,
      "name": "Carroll, Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1442,
      "name": "Carr-Saunders, A.M., Florence, P.S. & Peers, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1443,
      "name": "Cart, Th., Merckens, M. & Berthelot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1444,
      "name": "Carter Edward J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1445,
      "name": "Carter George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1446,
      "name": "Carter, Charles Sydney & Weeks, G.E. Alison",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1447,
      "name": "Carter, Howard & Mace, A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1448,
      "name": "Carter, T.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1450,
      "name": "Carton De Wiart, Comte",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1451,
      "name": "Carton, C., Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1452,
      "name": "Carton, Raoul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1453,
      "name": "Carty P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1454,
      "name": "Carty, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1455,
      "name": "Carus, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1456,
      "name": "Carvalho Sebastian A. S.D.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1457,
      "name": "Casalius, Joannes Baptista",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1458,
      "name": "Casanuovas I. S.J. & O'Leary M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1459,
      "name": "Casel, Otto, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1460,
      "name": "Casey, P.H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1461,
      "name": "Casey, R.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1462,
      "name": "Caspar, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1463,
      "name": "Caspari, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1464,
      "name": "Caspari, C.P. [Hersg.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1465,
      "name": "Casserley, J.V. Langmead",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1466,
      "name": "Cassianus Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1467,
      "name": "Cassidy Richard J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1468,
      "name": "Cassidy, Eoin G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1469,
      "name": "Cassidy, Frank, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1470,
      "name": "Cassirer, Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1471,
      "name": "Castelein A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1472,
      "name": "Castelli, Edmundus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1473,
      "name": "Castets, G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1474,
      "name": "Castiello Jaime S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1475,
      "name": "Castillon, Raymond de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1476,
      "name": "Castle, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1477,
      "name": "Caterina da Genova, Sta.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1478,
      "name": "Caterina da Siena, Sta.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1479,
      "name": "Cather, Willa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1480,
      "name": "Cathrein Victor S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1481,
      "name": "Catlow, Agnes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1482,
      "name": "Cator, Murray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1483,
      "name": "Cattopadhyay, Ashok Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1484,
      "name": "Cattopadhyay, Bankimcandra (1838-1894)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1485,
      "name": "Cattopadhyay, Bankimcandra (1838-1894) & Das, Sajanikanta [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1486,
      "name": "Cattopadhyay, Bankimcandra (1838-1894) & Misra, P.N. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1487,
      "name": "Cattopadhyay, Bankimcandra (1838-1894), Bandyopadhyay, B.N. [ed.] & Das, S.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1488,
      "name": "Cattopadhyay, Bankimcandra (1838-1894), Bandyopadhyay, Brojendra Nath [ed.] & Das, Sajanikant [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1489,
      "name": "Cattopadhyay, Chidvilas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1490,
      "name": "Cattopadhyay, D.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1491,
      "name": "Cattopadhyay, Gaurisankara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1492,
      "name": "Cattopadhyay, Sarajit Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1493,
      "name": "Cattopadhyay, Sarat Candra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1494,
      "name": "Cattopadhyay, Sarat Candra & Cattopadhyay, N. [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1495,
      "name": "Cattopadhyay, Sarat Candra & Pandey, R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1496,
      "name": "Cattopadhyay, Sarat Candra & Pandeya, R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1497,
      "name": "Cattopadhyay, Sita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1498,
      "name": "Caturvedi, Jagapati",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1499,
      "name": "Caturvedi, Pradeep [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1500,
      "name": "Caturvedi, Sitaram & Misra, Sivaprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1501,
      "name": "Cauboue, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1502,
      "name": "Caubriere, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1503,
      "name": "Caudhuri Arindam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1504,
      "name": "Caudhuri, Binay Bhushan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1505,
      "name": "Caudhuri, Laksmiprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1506,
      "name": "Caudhuri, Nirad C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1507,
      "name": "Caudhuri, Satinath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1508,
      "name": "Caudhuri, Sushil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1510,
      "name": "Caussade, J.P. De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1511,
      "name": "Cavagnis Felix Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1512,
      "name": "Cavaignac, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1513,
      "name": "Cavallera Ferdinand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1514,
      "name": "Cavallera Fernand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1515,
      "name": "Cave Sydney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1516,
      "name": "Cavendish, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1517,
      "name": "Cayotte, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1518,
      "name": "Cayre, F., A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1519,
      "name": "Cayre, F., A.A. & Howitt, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1520,
      "name": "Cazenove, John Gibson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1521,
      "name": "Cecil, Algernon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1523,
      "name": "Cecilia, Madame",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1524,
      "name": "Celestinus A. Sancto Joseph Ord. Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1525,
      "name": "Celier Leonce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1526,
      "name": "Cepari Virgilius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1527,
      "name": "Cercia, Raphael, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1529,
      "name": "Ceresko, Anthony R., OSFS",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1530,
      "name": "Cerfaux, Lucien (1883-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1532,
      "name": "Cernicchi, Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1533,
      "name": "Cerri, Urbano, Secret. C.de P.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1534,
      "name": "Cervantes-Saavedra Miguel De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1535,
      "name": "Cervantes-Saavedra Miguel De & Clark J.W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1536,
      "name": "Cesary, Cajetan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1537,
      "name": "Cestre, CH",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1538,
      "name": "Ceulemans, F.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1541,
      "name": "Ceuppens, F., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1542,
      "name": "Ceuppens, P.F., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1543,
      "name": "Chabot J.-H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1544,
      "name": "Chadwick Habert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1545,
      "name": "Chadwick, H. Munro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1546,
      "name": "Chahal S.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1547,
      "name": "Chaigne, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1548,
      "name": "Chaigne, Louis & Garreau, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1549,
      "name": "Chaignon Marielle De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1551,
      "name": "Chaignon Pierre S.J. & De Goesbriand L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1552,
      "name": "Chaignon, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1553,
      "name": "Chaillan M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1555,
      "name": "Chaine, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1557,
      "name": "Chaineux, Jacques, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1558,
      "name": "Challoner Richard Bishop",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1560,
      "name": "Chamberlin, William Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1561,
      "name": "Chambers Robert W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1562,
      "name": "Chambers, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1563,
      "name": "Chambers, F.W. & Kerr, A.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1564,
      "name": "Chambers, Robert W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1565,
      "name": "Champagny, Franz De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1566,
      "name": "Chance, John Newton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1567,
      "name": "Chancellor William Estabrook",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1568,
      "name": "Chancerel, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1569,
      "name": "Chanda, Ipshita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1570,
      "name": "Chanda, Ramaprasada",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1571,
      "name": "Chandler, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1572,
      "name": "Chandlery J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1573,
      "name": "Chandlery, P.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1574,
      "name": "Chandulal, Anand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1575,
      "name": "Chang, Jeffrey G.L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1576,
      "name": "Channa Subhadra Mitra (1951-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1577,
      "name": "Channing, William Ellery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1578,
      "name": "Chanson Abbe J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1579,
      "name": "Chantepie de la Saussaye, P.-D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1580,
      "name": "Chapman John O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1581,
      "name": "Chapman John O.S.B. & Barton John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1582,
      "name": "Chapman Michael Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1583,
      "name": "Chapman, F. Spencer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1584,
      "name": "Chapman, J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1585,
      "name": "Chapman, John, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1586,
      "name": "Chapman, Michael Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1587,
      "name": "Chapple Christopher Key [ed.] & Tucker Mary Evelyn [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1588,
      "name": "Chappoulie, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1589,
      "name": "Charbonneau, Joseph, Archbp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1590,
      "name": "Charland Th.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1591,
      "name": "Charles Eugene S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1592,
      "name": "Charles J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1593,
      "name": "Charles Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1594,
      "name": "Charles Pierre S.J. & Monahan Maud [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1595,
      "name": "Charles, Enid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1596,
      "name": "Charles, I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1597,
      "name": "Charles, J.N. & Schmitt, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1598,
      "name": "Charles, Madeleine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1599,
      "name": "Charles, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1600,
      "name": "Charles, R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1601,
      "name": "Charles, Rodger, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1602,
      "name": "Charlevoix, Pierre Francois-Xavier De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1603,
      "name": "Charlier, L., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1605,
      "name": "Charmot, Francois, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1606,
      "name": "Charruau Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1607,
      "name": "Charteris, Leslie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1608,
      "name": "Charue Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1609,
      "name": "Chase, Frederic Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1610,
      "name": "Chasle Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1612,
      "name": "Chassang, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1613,
      "name": "Chastonay, Paul De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1614,
      "name": "Chateaubriand Francois-Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1615,
      "name": "Chaube, S.P. & Chaube, Akhilesh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1616,
      "name": "Chaucer Geoffrey & Macklin W. Roy [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1617,
      "name": "Chaussegros de Lery, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1618,
      "name": "Chautard J.B. O.C.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1619,
      "name": "Chautard J.B. O.C.R. & Girardey Ferreol C.Ss.R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1620,
      "name": "Chauvin A. Jos. S.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1621,
      "name": "Chauvin, Paul, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1622,
      "name": "Cheetham, Tom",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1623,
      "name": "Cheever, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1624,
      "name": "Cheminant, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1625,
      "name": "Cheminant, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1626,
      "name": "Chenu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1627,
      "name": "Chenu, M.-D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1628,
      "name": "Cherel, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1629,
      "name": "Cherian, Shibu [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1630,
      "name": "Cheron Jean O.Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1631,
      "name": "Chester, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1632,
      "name": "Chesterton Gilbert K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1633,
      "name": "Chesterton, Cecil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1634,
      "name": "Chesterton, Gilbert K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1635,
      "name": "Chevaillier, Lucien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1636,
      "name": "Chevalier Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1637,
      "name": "Chevalier Ulysse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1638,
      "name": "Cheve C.-F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1639,
      "name": "Chevrillon, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1640,
      "name": "Cheyne, T.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1641,
      "name": "Cheyne, T.K. [ed.] & Sutherland, Black, J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1642,
      "name": "Cheyney Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1643,
      "name": "Cheyney, Edward P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1644,
      "name": "Chia, Edmund, FSC [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1645,
      "name": "Chiba, Shin [ed.], Hunsberger, George R. [ed.] & Ruiz, Lester Edwin J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1646,
      "name": "Childe, Gordon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1647,
      "name": "Childs, James M., Jr. (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1648,
      "name": "Chillikulam, Thomas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1649,
      "name": "Chillingworth, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1650,
      "name": "Chiper Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1651,
      "name": "Chisholm, D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1652,
      "name": "Chokhamela & Mokashi-Punekar, Rohini [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1653,
      "name": "Cholmeley, Katharine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1654,
      "name": "Cholmondeley, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1655,
      "name": "Chomsky Noam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1656,
      "name": "Chop, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1657,
      "name": "Choupin L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1658,
      "name": "Chrestien, Edith Corinne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1659,
      "name": "Christ G.E. & Haydon A.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1660,
      "name": "Christelbach, Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1661,
      "name": "Christian, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1662,
      "name": "Christiansen, Broder",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1663,
      "name": "Christie Agatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1664,
      "name": "Christie Albany James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1665,
      "name": "Christie, Agatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1666,
      "name": "Christie, O.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1667,
      "name": "Christitch A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1668,
      "name": "Church, R.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1669,
      "name": "Churchill, Winston S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1670,
      "name": "Cianfarra, Camille M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1671,
      "name": "Ciano, Galeazzo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1672,
      "name": "Cicero, Marcus Tullius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1673,
      "name": "Cicero, Marcus Tullius & Donnelly, Francis P., S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1674,
      "name": "Cicognani, Amleto Giovanni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1675,
      "name": "Ciliga, Anton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1676,
      "name": "Cintamani C.Y.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1677,
      "name": "Cioflec, Virgil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1678,
      "name": "Civezza, Marcellino Da, M.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1679,
      "name": "Claes, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1680,
      "name": "Claeys-Bouuaert F. & Simenon G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1681,
      "name": "Claeys-Bouuaert Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1682,
      "name": "Claeys-Bouuaert, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1683,
      "name": "Clair S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1684,
      "name": "Clamer, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1685,
      "name": "Clancy, Patrick, M.J., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1686,
      "name": "Clanton, Dan W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1687,
      "name": "Clapham, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1688,
      "name": "Clare J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1689,
      "name": "Clare, George & Crump, Norman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1690,
      "name": "Claret de la Touche, Louise Marguerite",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1691,
      "name": "Claretie Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1692,
      "name": "Clark Elizabeth A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1693,
      "name": "Clark, T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1694,
      "name": "Clark, William P., OMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1695,
      "name": "Clarke A.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1696,
      "name": "Clarke C.P.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1697,
      "name": "Clarke John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1698,
      "name": "Clarke Richard F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1699,
      "name": "Clarke Robertus O.Cart.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1700,
      "name": "Clarke, Arthur J., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1701,
      "name": "Clarke, Isabel C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1702,
      "name": "Clarke, Peter B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1703,
      "name": "Clarke, Thomas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1704,
      "name": "Claudel, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1705,
      "name": "Claudel, Paul & O'Connon, John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1706,
      "name": "Claudel, Paul; Sells, A. Lytton [ed.] & Girdlestone, C.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1707,
      "name": "Clave M.M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1708,
      "name": "Clavier Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1709,
      "name": "Clay, Edmund R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1710,
      "name": "Clayton Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1711,
      "name": "Clayton, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1712,
      "name": "Clayton, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1713,
      "name": "Clemen, August",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1714,
      "name": "Clemen, Carl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1715,
      "name": "Clemen, Carl & Nisbet, R.G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1716,
      "name": "Clemens Alexandrinus, Titus Flavius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1717,
      "name": "Clemens Alexandrinus, Titus Flavius & Mondesert, Claude [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1718,
      "name": "Clemens Alexandrinus, Titus Flavius & Wilson, William, McF [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1720,
      "name": "Clemens I Romanus, Papa, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1721,
      "name": "Clemens Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1722,
      "name": "Clemens Romanus (Ps.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1723,
      "name": "Clemens Samuel Langhorne (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1724,
      "name": "Clemens XIV Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1725,
      "name": "Clemens, Romanus (Ps.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1726,
      "name": "Clemens, Samuel Langhorne (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1727,
      "name": "Clement G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1728,
      "name": "Clermont-Ganeau, Ch. [et al.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1729,
      "name": "Clifford, Paul Rowntree",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1730,
      "name": "Clines, David J.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1731,
      "name": "Clodd, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1732,
      "name": "Cloete, Stuart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1733,
      "name": "Clonmore, Lord",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1734,
      "name": "Clooney, Francis X., S.J. (1950-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1735,
      "name": "Clop Eusebe O.F.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1736,
      "name": "Cloriviere, Pierre de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1737,
      "name": "Closen, Gustave, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1738,
      "name": "Clowry William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1739,
      "name": "Clump, Cyril C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1740,
      "name": "Clune, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1741,
      "name": "Clutton-Brock, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1742,
      "name": "Coady, M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1743,
      "name": "Cobban, J.-Maclaren",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1744,
      "name": "Cobbett William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1745,
      "name": "Cobham, Claude Delaval",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1746,
      "name": "Cobley, Paul, Jansz, Litza & Appignanesi, Richard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1748,
      "name": "Cocchi, Guidus, C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1750,
      "name": "Cochem Martin Von O.F.M. Cap. & Mohr Heinrich [hrsg.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1751,
      "name": "Cochem, Martin von, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1752,
      "name": "Cochin Denis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1753,
      "name": "Cochin Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1754,
      "name": "Cochrane, Charles Norris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1755,
      "name": "Codrington R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1756,
      "name": "Coelho, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1757,
      "name": "Coemans Augustus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1758,
      "name": "Coemans, Auguste, S.J. & Germing, Matthew, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1759,
      "name": "Coemans, Augustus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1760,
      "name": "Coffey, Reginald M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1761,
      "name": "Coggan, Donald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1762,
      "name": "Coghlan Philip G.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1763,
      "name": "Cogt Petrus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1764,
      "name": "Cohausz, Otto, S.J. & Emery, L.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1765,
      "name": "Cohausz, Otto, S.J. & Smith, George D. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1766,
      "name": "Cohen, Emmeline W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1767,
      "name": "Cohn-Sherbok, Dan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1768,
      "name": "Coillard Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1769,
      "name": "Cole G.D.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1770,
      "name": "Cole, G.D.H. & M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1771,
      "name": "Cole, G.D.H. & Postgate, Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1772,
      "name": "Cole, Juan R.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1773,
      "name": "Cole, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1774,
      "name": "Coleman Gerald S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1775,
      "name": "Coleman, John A., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1776,
      "name": "Coleridge Henry James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1777,
      "name": "Coleridge J.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1778,
      "name": "Coleridge James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1779,
      "name": "Coleridge Samuel Taylor & Dunn S.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1780,
      "name": "Coleridge, Samuel Taylor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1781,
      "name": "Colin, P.L., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1782,
      "name": "Colle, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1783,
      "name": "Collet, Collect Dobson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1784,
      "name": "Collier, William Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1785,
      "name": "Collin, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1786,
      "name": "Collin, Remy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1787,
      "name": "Collin, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1788,
      "name": "Collinge, William J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1790,
      "name": "Collins, Harold E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1791,
      "name": "Collins, John J., S.J. [ed.] & Evang, Craig A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1792,
      "name": "Collins, John N. (1931-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1793,
      "name": "Collins, Paul M. (1940-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1794,
      "name": "Collomp, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1795,
      "name": "Coloma, Luis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1796,
      "name": "Colombey Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1797,
      "name": "Columbus, Christopher & Janes, Cecil [transl. & Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1798,
      "name": "Colvin, Ian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1799,
      "name": "Colvin, Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1800,
      "name": "Colwell, John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1801,
      "name": "Combalot Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1802,
      "name": "Combaz, Gisbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1803,
      "name": "Combes, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1804,
      "name": "Combes, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1805,
      "name": "Comerford M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1806,
      "name": "Commodianus Gazaeus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1807,
      "name": "Compaing de la Tour Girard M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1808,
      "name": "Compaing R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1809,
      "name": "Compton Piers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1810,
      "name": "Compton-Rickett, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1811,
      "name": "Comyns, Joseph J., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1812,
      "name": "Concilio J. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1813,
      "name": "Condamin Albert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1814,
      "name": "Condliffe, J.B. & Stevenson, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1815,
      "name": "Confrey, Burton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1816,
      "name": "Congar, Yves M.-J., O.P. (d. 1995)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1817,
      "name": "Conn Walter E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1818,
      "name": "Connell, Francis J., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1819,
      "name": "Conolly, Violet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1820,
      "name": "Conrad of Provvidenti, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1821,
      "name": "Conrad, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1822,
      "name": "Conroy George Bp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1823,
      "name": "Conroy, Joseph P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1824,
      "name": "Considine Daniel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1825,
      "name": "Considine, Daniel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1826,
      "name": "Considine, John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1828,
      "name": "Constant M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1829,
      "name": "Constant, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1830,
      "name": "Constantinides, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1831,
      "name": "Contenau Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1832,
      "name": "Contenau, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1833,
      "name": "Contenson Vincentius O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1834,
      "name": "Contessa, Fabrizio & Carmel, Neelu, C.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1835,
      "name": "Contzen Adamus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1836,
      "name": "Conway John Placid O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1837,
      "name": "Conway, Bertrand L., C.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1838,
      "name": "Conway, Bertrand L., C.S.P. & Dent, Paul, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1839,
      "name": "Cooey Paula M. [ed.] Eakin William R. [ed.] & McDaniel Jay B. (1949-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1840,
      "name": "Coogan, Michael David [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1841,
      "name": "Cook John Granger (1955-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1842,
      "name": "Cook, Arthur Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1843,
      "name": "Cook, David (1966-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1844,
      "name": "Cook, F.C. & Wace, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1845,
      "name": "Cook, Thos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1846,
      "name": "Cooke A.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1848,
      "name": "Cooke, G.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1849,
      "name": "Coomaraswamy, Ananda",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1850,
      "name": "Coomaraswamy, Ananda K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1851,
      "name": "Cooper John M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1853,
      "name": "Cooper, William Earnshaw",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1854,
      "name": "Copleston, Frederick C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1855,
      "name": "Coppee, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1857,
      "name": "Coppens Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1858,
      "name": "Coppens, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1859,
      "name": "Coppens, Charles, S.J. & Spalding, Henry S., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1860,
      "name": "Coppens, Cypr., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1861,
      "name": "Coppens, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1862,
      "name": "Copra, Deepak",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1863,
      "name": "Coquilhat, Camille",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1864,
      "name": "Corcoran James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1865,
      "name": "Cordara Julius Caesar S.J. (1704-1785)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1867,
      "name": "Corelli, Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1869,
      "name": "Coret, Jacques, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1870,
      "name": "Coriden James A.; Green Thomas J. & Heintschel Donald E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1871,
      "name": "Corless George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1872,
      "name": "Corley, Francis J., S.J. & Willmes, Robert J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1873,
      "name": "Cornelius Nepos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1874,
      "name": "Cornill, Carl Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1875,
      "name": "Cornish, Gerald Warre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1876,
      "name": "Coronas Jose S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1877,
      "name": "Corre Joannes Maria Soc. Mis. Ext.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1878,
      "name": "Corrigan Raymond S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1879,
      "name": "Corte, Marcel de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1880,
      "name": "Cortes, Juan Donoso",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1881,
      "name": "Corts Grau, Jose",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1882,
      "name": "Cory, Herbert Ellsworth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1883,
      "name": "Cosgrove, Charles H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1884,
      "name": "Costa, Cosme Jose, SFX & Mascarenhas, Seby, SFX",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1885,
      "name": "Costantini, Celso",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1886,
      "name": "Costello Timothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1887,
      "name": "Costerus, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1888,
      "name": "Cotel Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1889,
      "name": "Cotel Pierre S.J. & Jombart Emile S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1890,
      "name": "Cotel, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1891,
      "name": "Cotes, Everard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1892,
      "name": "Coton, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1894,
      "name": "Cotter, Anthony C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1895,
      "name": "Cotterill, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1896,
      "name": "Couailhac Marius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1897,
      "name": "Couannier, Maurice Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1899,
      "name": "Coube, Etienne, S.J., Griffiss, Ida [transl.] & Brady, F.X., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1900,
      "name": "Coube, Stephen, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1902,
      "name": "Couchoud, Paul Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1903,
      "name": "Coucke Valere J. & Walsh James J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1904,
      "name": "Coudenhove Ida Friederike",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1905,
      "name": "Coudenhove Ida Friederike & Bonacina Conrad M.R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1906,
      "name": "Couderc J.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1907,
      "name": "Couderc Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1909,
      "name": "Coughlin, Chas. E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1910,
      "name": "Coughlin, Kathryn M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1911,
      "name": "Couissinier M.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1912,
      "name": "Coulanges, Fustel de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1913,
      "name": "Coulet Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1914,
      "name": "Coulin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1915,
      "name": "Coulton, G.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1916,
      "name": "Coulton, G.G. & Lunn, Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1917,
      "name": "Coupin, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1918,
      "name": "Coupland, Reginald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1919,
      "name": "Courberive Jean de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1920,
      "name": "Courbet Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1921,
      "name": "Courson, Comtesse R. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1922,
      "name": "Courthope, W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1923,
      "name": "Courtois G., Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1924,
      "name": "Courtois, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1925,
      "name": "Courtois, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1926,
      "name": "Cousins, James H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1927,
      "name": "Couto, Maria Aurora",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1928,
      "name": "Couturier Jean S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1929,
      "name": "Covey Sean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1930,
      "name": "Covey, Stephen R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1931,
      "name": "Cowan A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1933,
      "name": "Coward, Harold G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1934,
      "name": "Cowley, Abraham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1935,
      "name": "Cowman, Chas E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1936,
      "name": "Cowper, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1937,
      "name": "Cox, George W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1938,
      "name": "Cozzens, Donald B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1939,
      "name": "Crabtree Harriet (1958- )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1941,
      "name": "Cragg, Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1942,
      "name": "Craig Alice Evelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1943,
      "name": "Craig, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1944,
      "name": "Craig, Philippa Stewart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1945,
      "name": "Craisson D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1946,
      "name": "Crampon A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1947,
      "name": "Crampon, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1948,
      "name": "Crane, Stephen & Barr, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1950,
      "name": "Crasset, Jean, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1951,
      "name": "Crathorn O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1952,
      "name": "Crawford David S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1953,
      "name": "Crawford Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1954,
      "name": "Crawford, Francis Marion",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1955,
      "name": "Crawford, R.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1956,
      "name": "Crawford, V.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1957,
      "name": "Crawley-Boevey Mateo SS.CC.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1958,
      "name": "Crawley-Boevey, Mateo, SS.CC.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1959,
      "name": "Creighton, Louise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1960,
      "name": "Creighton, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1961,
      "name": "Cremer Herman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1962,
      "name": "Cremer Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1963,
      "name": "Cremers Victor S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1964,
      "name": "Crepaz, Adele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1965,
      "name": "Crepieux-Jamin, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1966,
      "name": "Cretineau-Joly J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1968,
      "name": "Creusen Joseph S.J. & Van Eyen Felix S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1969,
      "name": "Creusen, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1970,
      "name": "Cribb, Ian, S.J., Scroope, Martin, Stoney, Michael, S.J. & Coleman, Gerald, S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1971,
      "name": "Cristiani Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1972,
      "name": "Crivelli, Camillo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1973,
      "name": "Croce, Isidorus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1975,
      "name": "Croegaert, Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1976,
      "name": "Croft, Taylor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1977,
      "name": "Crofts Freeman Wills",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1978,
      "name": "Croiset Jean S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1979,
      "name": "Croiset, Alfred & Croiset, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1980,
      "name": "Croiset, Jean, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1981,
      "name": "Cronin C. Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1982,
      "name": "Cronin, A.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1983,
      "name": "Cronin, Charles J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1984,
      "name": "Croonenberghs, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1985,
      "name": "Cros Leonard-Joseph-Marie S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1986,
      "name": "Cros, Leonard-Joseph-Marie, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1987,
      "name": "Crossley, James G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1988,
      "name": "Crouse, M. Elizabeth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1989,
      "name": "Crouzil Lucien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1990,
      "name": "Crowe, Martin T., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1991,
      "name": "Crowell, Daniel W. (1975- )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1992,
      "name": "Crowfoot, J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1993,
      "name": "Crprianus, Thasius Caecilius, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1994,
      "name": "Cruden, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1995,
      "name": "Cruikshank, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1996,
      "name": "Cruls J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1997,
      "name": "Crump, C.G. & Jacob, E.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1998,
      "name": "Cruse A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 1999,
      "name": "Cruysberghs, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2000,
      "name": "Csaba, Marguerite",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2001,
      "name": "Cucherat F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2002,
      "name": "Cuenot Lucien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2003,
      "name": "Cuenot, Lucien & Rostand, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2004,
      "name": "Culpepper, R. Alan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2005,
      "name": "Cumming, A.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2006,
      "name": "Cumming, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2007,
      "name": "Cumont, Franz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2008,
      "name": "Cumplido Felix S.J. & MacLeod G. John S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2009,
      "name": "Cunningham, Adrian & Garrick, H.B.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2010,
      "name": "Cunningham, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2011,
      "name": "Cunningham, Henry-Stewart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2012,
      "name": "Cunningham, Philip A. [ed.], Hofmann, Norbert J., SDB [ed.] & Sievers, Joseph [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2013,
      "name": "Cunningham, William H. & Cunningham, Isabella",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2014,
      "name": "Cunnington, R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2015,
      "name": "Cunnington, Susan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2016,
      "name": "Cuppers, Adam Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2017,
      "name": "Curiosus Tiro (Pseudon.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2018,
      "name": "Curran, Charles A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2019,
      "name": "Curran, Francis X., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2020,
      "name": "Curran, John Philpot & Davis, Thomas [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2021,
      "name": "Currey E. Hamilton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2022,
      "name": "Curry S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2023,
      "name": "Curtayne, Alice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2024,
      "name": "Curteis, George Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2025,
      "name": "Curtis John S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2026,
      "name": "Curtis, Edward Lewis & Madsen, Albert Alonzo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2027,
      "name": "Curtis, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2028,
      "name": "Curtiss, John Shelton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2029,
      "name": "Curtius, Georg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2030,
      "name": "Cush, Denise [ed.], Robinson, Catherine [ed.] & York, Michael [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2031,
      "name": "Cust, Robert Needham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2034,
      "name": "Cuthbert, O.F.M., Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2035,
      "name": "Cuthbert, O.S.F.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2036,
      "name": "Cuttaz Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2037,
      "name": "Cutts, Edward L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2038,
      "name": "Cuvelhier Michael S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2039,
      "name": "Cuvier, M.G., Richard, A. & Drapiez",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2040,
      "name": "Cuzin, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2041,
      "name": "Cyprianus Gallus Poeta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2043,
      "name": "Cyprianus, Thascius Caecilius, Episc., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2044,
      "name": "Cyprianus, Thascius Caecilius, Episc., Stus. & Wallis, Robert Ernest [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2045,
      "name": "Cyrillus Hierosolymitanus, Archiep, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2046,
      "name": "Czerwinska-Schupp, Ewa [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2047,
      "name": "Da Cruz Fernandes Caetano",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2048,
      "name": "D'A F.M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2049,
      "name": "Da Fonseca Jose",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2050,
      "name": "Da Fonseca, Luigi Gonzaga, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2051,
      "name": "Da Gama, Carlos Evaristo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2052,
      "name": "Da Silva Rego, Antonio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2053,
      "name": "Da Varazze, Beato Iacopo, O.P. & Cabases, Felix Juan, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2054,
      "name": "Dabhi, Jimmy C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2056,
      "name": "Dabin Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2057,
      "name": "Dabin, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2058,
      "name": "Daelman Carolus Gislenus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2060,
      "name": "Daeschler, Rene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2061,
      "name": "Daffara Marcolinus O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2062,
      "name": "Daftary, Farhad [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2063,
      "name": "Dahlmann, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2064,
      "name": "Dainville, Francois de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2065,
      "name": "Dalai Lama (1935-    ) & Barr, Robert R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2067,
      "name": "Dalberg-Acton, John Emerich Edward; Figgis, J.N. [ed.] & Laurence, R.V. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2068,
      "name": "Dalbiez, Roland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2069,
      "name": "Dale, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2070,
      "name": "Dalgairns, John Bernard; Newman, John Henry [ed.] & Herbert, Thurston, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2071,
      "name": "Dallas R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2072,
      "name": "Dallet Ch. M.E.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2073,
      "name": "Dalman, Gustaf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2074,
      "name": "Dalton Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2075,
      "name": "Dalton William Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2076,
      "name": "Dalton, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2078,
      "name": "Daly, Edwin J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2079,
      "name": "Daly, James J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2080,
      "name": "Damanet Auguste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2081,
      "name": "Dandavate Madhu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2082,
      "name": "Dandoy, George, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2083,
      "name": "Daneshyar Osama",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2084,
      "name": "Daniel, Evan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2085,
      "name": "Danielou, Jean, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2086,
      "name": "Daniel-Rops, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2087,
      "name": "Danks, Canon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2088,
      "name": "Dante, Alighieri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2089,
      "name": "Dante, Alighieri & Cary, H.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2090,
      "name": "Danzas J.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2091,
      "name": "Dara Shikoh, Muhammad (D.1659) & Mahfuz ul-Haq, M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2092,
      "name": "D'Arcy Martin C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2093,
      "name": "D'Arcy M'gee, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2094,
      "name": "D'Arcy, Charles F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2095,
      "name": "D'Arcy, Martin C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2096,
      "name": "Daremberg, Ch., Saglio, E. & Pottier, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2097,
      "name": "D'Aria, F.M., S.J..",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2098,
      "name": "Dario Joannes-Maria S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2099,
      "name": "Dart, J.L.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2100,
      "name": "Dart, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2101,
      "name": "Darwin, Charles Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2102,
      "name": "Das, Jnanendramohan & Jha, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2103,
      "name": "Das, K.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2104,
      "name": "Das, Sajanikant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2105,
      "name": "Das, Veena [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2106,
      "name": "Dasa, Sajanikanta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2107,
      "name": "Dasgupta, Kaliprasanna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2108,
      "name": "Dasgupta, Sameer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2109,
      "name": "Dasnoy, Eugene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2110,
      "name": "Dasuqi Burhan al-Din Ibrahim b. Abi 'l-Majid 'Abd al-'Aziz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2111,
      "name": "Datta Probal K. & John Nalini Nupur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2112,
      "name": "Datta, Krishna & Robinson, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2113,
      "name": "Datta, Rajat [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2114,
      "name": "Datta, Ramescandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2115,
      "name": "Datta-Gupta, Amulya Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2116,
      "name": "Daubenton S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2117,
      "name": "Daudet Alphonse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2118,
      "name": "Daudet Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2119,
      "name": "Daudet Ernest & Wall A.H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2120,
      "name": "Daumas Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2121,
      "name": "Daurignac J.M.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2122,
      "name": "Dauvillier, Jean & De Clercq, Carlo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2123,
      "name": "David Anton S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2125,
      "name": "David, M.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2126,
      "name": "Davidoff Henry [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2127,
      "name": "Davids Caroline A.F. Rhys",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2128,
      "name": "Davids, Peter H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2129,
      "name": "Davidson, A.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2130,
      "name": "Davidson, Thomas [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2131,
      "name": "Davies, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2132,
      "name": "Davies, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2133,
      "name": "Davies, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2134,
      "name": "Davies, Joseph E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2135,
      "name": "Davis Frank P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2136,
      "name": "Davis Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2137,
      "name": "Davis Mike",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2138,
      "name": "Davis, Henri, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2139,
      "name": "Davis, Henry, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2140,
      "name": "Davis, J. Merle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2141,
      "name": "Davis, John D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2142,
      "name": "Davy, M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2143,
      "name": "Dawn, Marva J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2144,
      "name": "Dawson Charles B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2146,
      "name": "Dawson, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2147,
      "name": "Dawson, William Harbutt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2148,
      "name": "Day, Arthur, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2149,
      "name": "Day, Dorothy (1897-1980)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2150,
      "name": "Day, Juliette",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2151,
      "name": "Dayakisora",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2152,
      "name": "Dayal, John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2153,
      "name": "Daye, Russell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2154,
      "name": "D'Azevedo Luis G. S.J..",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2155,
      "name": "D'Cunha, Joel [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2156,
      "name": "De Areilza, Jose Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2157,
      "name": "de Avila Juan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2158,
      "name": "De Backer J.V. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2159,
      "name": "De Backer Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2160,
      "name": "De Backer Stanislaus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2161,
      "name": "De Bie S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2163,
      "name": "De Block, Vincent, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2164,
      "name": "De Brune Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2165,
      "name": "De Bruyne, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2167,
      "name": "De Buck, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2168,
      "name": "De Calvo Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2169,
      "name": "De Carpo Aloisius Maria O.F.M. & Moretti Aloisius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2170,
      "name": "De Carpo Alphonsus Maria O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2171,
      "name": "De Castro-Palao Ferdinandeus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2172,
      "name": "De Clercq, Aug., Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2173,
      "name": "De Clercq, Carlo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2174,
      "name": "De Decker J.-V S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2175,
      "name": "De Dreuille, Mayeul, OSB (1920-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2176,
      "name": "De Fonseca G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2177,
      "name": "de Fonseca Luigi Gonzaga S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2178,
      "name": "de Franciosi Xavier S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2179,
      "name": "De Geyser S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2180,
      "name": "De Giovanni Giuseppe S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2181,
      "name": "De Graeve, Rob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2182,
      "name": "De Greeff, Etienne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2183,
      "name": "De Groot J.F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2184,
      "name": "De Groot, D., Leopold, L. & Rijkens, R.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2186,
      "name": "De Guchteneere, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2187,
      "name": "De Hales Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2188,
      "name": "De Herdt P.J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2189,
      "name": "De Hert Francis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2190,
      "name": "De Hovre Frans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2192,
      "name": "De Jaegher, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2193,
      "name": "De la Vega, Garcilazo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2194,
      "name": "De Laet, Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2195,
      "name": "De lai Gaetano Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2196,
      "name": "De Lama Marianus O.S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2197,
      "name": "De Letter, Prudentius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2198,
      "name": "De Luca Marianus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2199,
      "name": "de Martinis Raphaelis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2200,
      "name": "De Meester A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2201,
      "name": "De Meester, Placide, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2202,
      "name": "De Mello, Anthony, S.J. (1931-1987) & Dych, William V., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2203,
      "name": "De Mesmaecker, Henricus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2204,
      "name": "De Meulder, E., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2205,
      "name": "De Montefeltro Agostino",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2206,
      "name": "De Moor Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2207,
      "name": "De Munter Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2208,
      "name": "De Pillecyn Filip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2209,
      "name": "De Pillecyn, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2210,
      "name": "De Potter, Frans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2211,
      "name": "De Quincey, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2212,
      "name": "De Riess, Ricardus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2213,
      "name": "De Sales, Francis & Bangley, Bernard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2214,
      "name": "De San Ludovicus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2215,
      "name": "De Santi Angelo S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2216,
      "name": "De Schepper Gratianus O.M.Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2217,
      "name": "De Smedt Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2219,
      "name": "De Smedt, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2220,
      "name": "De Smet, Aloysius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2221,
      "name": "De Smet, Etienne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2222,
      "name": "De Smet, Pierre-Jean, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2223,
      "name": "De Souza, Teotonio R., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2224,
      "name": "De Valentia Gregorius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2225,
      "name": "De Velics, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2226,
      "name": "De Ville, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2228,
      "name": "De Vleeschauwer, H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2229,
      "name": "De Vocht, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2230,
      "name": "De Vooght Paul O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2231,
      "name": "De Vreese, Jacob, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2232,
      "name": "De Vries Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2233,
      "name": "De Vries, Hent [ed.] & Sullivan, Lawrence E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2234,
      "name": "de Vries, Wilhelm, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2235,
      "name": "De Weerdt Kan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2236,
      "name": "De Wit Greg. O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2238,
      "name": "De Wulf, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2240,
      "name": "De Zulueta, F.M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2241,
      "name": "Deams, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2242,
      "name": "Deane-Drummond, Celia E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2243,
      "name": "Dear, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2244,
      "name": "Deb, Bimal J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2245,
      "name": "Debaisieux Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2247,
      "name": "Debout, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2248,
      "name": "Debuchy Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2249,
      "name": "Decaux, Alain",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2250,
      "name": "Dechanet, J.-M., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2251,
      "name": "Decoster, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2252,
      "name": "Dedieu Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2253,
      "name": "Defau Toussaint S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2254,
      "name": "Defoe, Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2255,
      "name": "Defoe, Daniel & Jha, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2256,
      "name": "Defoe, Daniel & Lazarus, E.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2257,
      "name": "Dehaisnes C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2258,
      "name": "Dehan, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2260,
      "name": "Deharbe, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2261,
      "name": "Dehejia, Vidya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2262,
      "name": "Dehon, Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2263,
      "name": "Dehove H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2264,
      "name": "Dei Mattei P. S.J. & Rocca F.X. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2265,
      "name": "Dei Mattei, P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2266,
      "name": "Deibert Richard I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2267,
      "name": "Deissmann, Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2268,
      "name": "Deissmann, G. Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2269,
      "name": "Dekker Thomas & Sheldon G.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2270,
      "name": "Dekkers, E., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2271,
      "name": "Del Marmol, B., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2272,
      "name": "Del Prado N. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2273,
      "name": "Del Rio Cisneros A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2274,
      "name": "Del Rio Cisneros A. & Montes Agudo G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2275,
      "name": "Del Vasto, Lanza",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2276,
      "name": "Delacroix Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2277,
      "name": "Delafield, E.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2278,
      "name": "Delaney, John P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2279,
      "name": "Delany Selden Peabody",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2281,
      "name": "Delaporte, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2282,
      "name": "Delaporte, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2284,
      "name": "Delaporte, V., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2285,
      "name": "Delattre Alphonse S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2286,
      "name": "Delattre, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2287,
      "name": "Delavenne, Henri, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2289,
      "name": "Delbos, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2290,
      "name": "Delbrel J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2291,
      "name": "Delcuve, George, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2292,
      "name": "Delehaye Hippolyte S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2293,
      "name": "D'Elia, Pascal M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2294,
      "name": "Delitzsch, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2295,
      "name": "Delitzsch, Franz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2296,
      "name": "Dell, Ethel M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2297,
      "name": "Delmas Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2298,
      "name": "Delmas, M.Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2299,
      "name": "Delong-Bas, Natana J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2301,
      "name": "Delplace, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2302,
      "name": "Demaison, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2303,
      "name": "Demedts, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2304,
      "name": "Demeuran J.-Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2306,
      "name": "Demimuid Mgr. & Buchanan A.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2307,
      "name": "Demimuid, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2308,
      "name": "Demosthenes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2309,
      "name": "Demoulin, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2310,
      "name": "Dempsey, Bernard W., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2311,
      "name": "Dempsy, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2312,
      "name": "Dengel, Anna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2313,
      "name": "Denifle, Heinrich, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2314,
      "name": "Deniker J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2315,
      "name": "Denis A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2316,
      "name": "Denis Antoine S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2317,
      "name": "Denis Antonius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2318,
      "name": "Denis, Aloys, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2319,
      "name": "Denis, Anthony, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2320,
      "name": "Denis, Antoine, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2321,
      "name": "Denis, Leopold, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2323,
      "name": "Dennefeld, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2324,
      "name": "Dennett R.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2325,
      "name": "Dennis, James S., Beach, H.P. & Fahs, Charles H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2326,
      "name": "Dennis, Trevor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2327,
      "name": "Denny, Edwardus & Lacey, T.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2328,
      "name": "Dens Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2329,
      "name": "Denti Di Pirajno, Alberto & Naylor, Kathleen [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2330,
      "name": "Denton, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2331,
      "name": "Denzer, George A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2332,
      "name": "Denzinger Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2333,
      "name": "Deplace, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2334,
      "name": "Deploige, Simon & Miltner, Charles C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2335,
      "name": "Deprins, J., S.J. & Hosten, H., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2336,
      "name": "Derbaix, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2337,
      "name": "Derely, J.-M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2338,
      "name": "Derkenne, Francoise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2339,
      "name": "Des Planques F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2340,
      "name": "Des Verrieres Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2341,
      "name": "Desai, Kiran",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2342,
      "name": "Desai, Mahadev",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2343,
      "name": "Desai, Murli",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2345,
      "name": "Desbuquois, Gustave, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2347,
      "name": "Descamps, Baron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2348,
      "name": "Descartes, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2349,
      "name": "Descartes, Rene & Veitch, John [transl. & introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2350,
      "name": "Deschamps Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2351,
      "name": "Deschamps, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2352,
      "name": "Deschamps, Paul & Leclercq, Jean, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2354,
      "name": "Descoqs, Pedro, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2355,
      "name": "Desgodins, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2356,
      "name": "Deshayes F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2357,
      "name": "Desmarais, M.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2358,
      "name": "Desmarchelier H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2360,
      "name": "Desnoyers, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2361,
      "name": "Desplanques, F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2362,
      "name": "Despras, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2363,
      "name": "Dessal Jean-Baptiste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2364,
      "name": "Dessau H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2365,
      "name": "Destrez, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2366,
      "name": "Detilleux, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2367,
      "name": "Deutschland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2368,
      "name": "Dev, Anup",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2369,
      "name": "Devanand M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2370,
      "name": "Devas Charles S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2371,
      "name": "Devasthali, G.V. & Jha, V.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2372,
      "name": "Devavarma, Samarendracandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2373,
      "name": "Devey, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2374,
      "name": "Devillers, Luc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2375,
      "name": "Devine Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2376,
      "name": "Devine, Edward T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2378,
      "name": "Devivier, Walther, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2380,
      "name": "Devotus, Joannes, Archiep.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2381,
      "name": "Devroye Eudore S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2382,
      "name": "Dewailly, L.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2383,
      "name": "Dewick, E.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2384,
      "name": "Dey, Bishnu & Irwin, John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2385,
      "name": "Dey, Nundo Lal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2386,
      "name": "Deynoodt Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2387,
      "name": "Deynoodt Francois S.J. & Gullerton G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2388,
      "name": "Dhanaraj S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2389,
      "name": "D'Harcourt, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2390,
      "name": "D'Harcourt, Robert & Dingle, Reginald J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2391,
      "name": "Dhavamony Mariasusai S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2392,
      "name": "Dhavan Rajeev",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2393,
      "name": "Dhavan, Deviprasad 'Vikal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2395,
      "name": "Dhorme, Edouard & Dussaud, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2396,
      "name": "Dhorme, Paul, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2397,
      "name": "Dhumketu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2398,
      "name": "Di Gregorio, Michael, O.S.R. & Babu, Sebastian Francis [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2399,
      "name": "Di Santarosa Santorre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2400,
      "name": "Diadochus Photices, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2401,
      "name": "Diana Antonini",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2402,
      "name": "Dias, Mario Saturnino [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2403,
      "name": "Diaz Garriz, Manuel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2404,
      "name": "Dibelius, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2405,
      "name": "Dicey, A.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2406,
      "name": "Dick, Steven [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2407,
      "name": "Dickens Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2408,
      "name": "Dickens Maria Angela",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2409,
      "name": "Dickens, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2410,
      "name": "Dickie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2411,
      "name": "Dickinson, H.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2412,
      "name": "Dickson, M.D.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2413,
      "name": "Didache",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2415,
      "name": "Didon, Henri, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2416,
      "name": "Didry, M. & Wallemacq, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2417,
      "name": "Dieckmann Hermannus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2418,
      "name": "Diehl, Charles & Marcais, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2419,
      "name": "Diehl, Charles, Oeconomos, L, Guilland, R. & Grousset, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2420,
      "name": "Diekamp, Franz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2421,
      "name": "Dien Mawil Izzi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2422,
      "name": "Dierickx, M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2423,
      "name": "Diertins Ignatius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2424,
      "name": "Dies Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2425,
      "name": "Dietrich Gabriele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2426,
      "name": "Digby Kenelm Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2427,
      "name": "Dignam Augustus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2428,
      "name": "Dignant, O.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2429,
      "name": "Dilard, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2430,
      "name": "Dilimann, August",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2431,
      "name": "Dilke, Charles Wentworth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2432,
      "name": "Dillard Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2433,
      "name": "Dillard, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2434,
      "name": "Dillenberger, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2436,
      "name": "Dillersberger Josef",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2437,
      "name": "Dillon E.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2439,
      "name": "Dimnet, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2440,
      "name": "Dinkar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2441,
      "name": "Dion, Petrus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2442,
      "name": "Dionysius Areopagita, Stus. & Rolt, C.E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2443,
      "name": "Diosy, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2445,
      "name": "Dirckinck, Joannes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2446,
      "name": "Diskalkar, D.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2447,
      "name": "Disraeli, B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2448,
      "name": "Disraeli, I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2449,
      "name": "Dissard Jean S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2450,
      "name": "Distant W.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2451,
      "name": "Dix, Gregory",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2452,
      "name": "Dixon Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2453,
      "name": "Dixon, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2454,
      "name": "D'Mello, John, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2455,
      "name": "D'Netto, T.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2456,
      "name": "Dobbins, Dustan, O.M.Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2457,
      "name": "Dobschutz, Ernest Von & Bremer, George [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2458,
      "name": "Dobson, Austin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2459,
      "name": "Dodd, C.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2460,
      "name": "Doddridge, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2461,
      "name": "Doheny, William J., C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2462,
      "name": "Doherty, Eddie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2464,
      "name": "Dohet, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2465,
      "name": "Dohring, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2466,
      "name": "Dolger Franz Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2467,
      "name": "Dom, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2468,
      "name": "Domet de Vorges Comte",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2469,
      "name": "Dominic, A. Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2470,
      "name": "Doms Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2471,
      "name": "Donald, James [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2472,
      "name": "Donaldson Aug. B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2473,
      "name": "Donaldson, St. Clair",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2474,
      "name": "Donat Josef",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2475,
      "name": "Donat, J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2477,
      "name": "Doncoeur, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2478,
      "name": "Dondaine H., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2479,
      "name": "Doniger Wendy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2480,
      "name": "Donnelly D. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2482,
      "name": "Donnelly, Francis P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2483,
      "name": "Donovan John S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2484,
      "name": "Dontaine, A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2486,
      "name": "Dontaine, Andre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2487,
      "name": "Doolan Gregory T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2488,
      "name": "Dooly Patrick J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2489,
      "name": "Doring, Oscar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2490,
      "name": "Dorner Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2491,
      "name": "Dornisch Loretta O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2493,
      "name": "Dorsch, Aemilius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2494,
      "name": "Dorsey, Anna H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2495,
      "name": "Dortng Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2496,
      "name": "Dorval Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2497,
      "name": "Dosi, Sambhu Lal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2498,
      "name": "Doss, Adolf Von, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2499,
      "name": "Dostoyevsky, Fyodor Mikhaylovich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2500,
      "name": "Douais, Cel., Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2501,
      "name": "Doublet Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2502,
      "name": "Douillet, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2503,
      "name": "Douwes Dekker, Eduard (Pseudon. Multatuli)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2504,
      "name": "Dowd William A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2505,
      "name": "Dowden, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2506,
      "name": "Dowe, Arthur W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2507,
      "name": "Downey Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2508,
      "name": "Downey Richard Archb.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2509,
      "name": "Doyle Arthur Conan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2510,
      "name": "Doyle Dennis M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2511,
      "name": "Doyle Francis Cuthbert O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2512,
      "name": "Doyle James P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2513,
      "name": "Doyle Juan S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2514,
      "name": "Doyle William S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2515,
      "name": "Doyle, Arthur Canon & Mccabe, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2516,
      "name": "Doyle, Arthur Conan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2517,
      "name": "Doyle, Charles Hugo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2518,
      "name": "Dozey E.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2519,
      "name": "Drago C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2520,
      "name": "Drake Emma F. Angell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2521,
      "name": "Drakulic, Slavenka (1949-  ) & Ivic, Marko [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2522,
      "name": "Drane John W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2523,
      "name": "Draper John William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2524,
      "name": "Drewniak Franz O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2525,
      "name": "Drews, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2526,
      "name": "Drexel, Hieremias, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2527,
      "name": "Dreze, Jean & Sen, Amartya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2528,
      "name": "Driesch, Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2530,
      "name": "Drinkwater John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2531,
      "name": "Drinkwater, F.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2533,
      "name": "Drioton, E. & Vandier, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2534,
      "name": "Driver, G.R. [ed. & Introd.] & Hodgson, Leonard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2535,
      "name": "Driver, S.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2536,
      "name": "Driver, S.R. & Gray, George Buchanam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2537,
      "name": "Droeber, Julia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2538,
      "name": "Drujon, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2539,
      "name": "Drummond James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2540,
      "name": "Drummond, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2541,
      "name": "Drumont Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2542,
      "name": "Dryden, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2543,
      "name": "D'Silva, Brian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2544,
      "name": "D'Souza Patrick P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2545,
      "name": "D'Souza Philomena FMA [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2546,
      "name": "D'Souza, Diane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2547,
      "name": "D'Souza, Manuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2548,
      "name": "D'Souza, Rudolf Valerian, OCD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2549,
      "name": "Du Bois, Karel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2550,
      "name": "Du Boisrouvray, Bernard, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2551,
      "name": "Du Bourg O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2552,
      "name": "Du Bruyne, Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2553,
      "name": "Du Cange Carolus Dufresne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2554,
      "name": "Du Cleuzion, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2555,
      "name": "Du Coetlosquet C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2556,
      "name": "Du Hamel, Jean Baptiste, Cong. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2557,
      "name": "du Jarric, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2558,
      "name": "du Manoir de Juaye, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2559,
      "name": "Du Plessis, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2560,
      "name": "Du Pontet, R.L.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2561,
      "name": "Du Val Andre O. Carm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2562,
      "name": "Dubarle D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2563,
      "name": "Dubarle, André-Marie, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2564,
      "name": "Dube, Dayasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2565,
      "name": "Dube, Ganapat Janakiram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2566,
      "name": "Dubey, M.P. [ed.] & Padalia, Munni [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2567,
      "name": "Dubois L. Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2569,
      "name": "Dubosq, Rene, S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2570,
      "name": "Dubray C.A. S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2571,
      "name": "Dubruel Marc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2572,
      "name": "Duchesne Louis Marie Olivier & Mathew Arnold Harris [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2573,
      "name": "Duchesne, Louis Marie Olivier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2574,
      "name": "Duckworth, W.L.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2575,
      "name": "Ducout, Henry, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2576,
      "name": "Dudden F. Homes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2577,
      "name": "Dudley Eustace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2578,
      "name": "Dudley, Owen Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2579,
      "name": "Dudon Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2580,
      "name": "Duerlinger, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2581,
      "name": "Dufau Toussaint S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2582,
      "name": "Duffray A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2583,
      "name": "Duffy, Charles Gavan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2584,
      "name": "Duffy, T. Gavan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2585,
      "name": "Dufourcq Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2586,
      "name": "Dufoyer, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2587,
      "name": "Dugard, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2588,
      "name": "Dugmore A. Radclyffe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2589,
      "name": "Dugmore, C.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2590,
      "name": "Duhamel Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2591,
      "name": "Duhamel M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2592,
      "name": "Duhayon Ferdinand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2593,
      "name": "Duhm, Bernhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2594,
      "name": "Duhourcau Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2596,
      "name": "Duhr, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2597,
      "name": "Duine F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2599,
      "name": "Dujcev, Ivan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2600,
      "name": "Dukes, Ashley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2601,
      "name": "Dullard Maurice S.J. & Echaniz Ignacio S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2602,
      "name": "Dullard Maurice S.J. & Van Walleghem S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2603,
      "name": "Dulles Avery Robert S.J. (1918-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2604,
      "name": "Dumas Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2605,
      "name": "Dumas, Alexandre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2606,
      "name": "Dumas, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2607,
      "name": "Dumont P.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2608,
      "name": "Dumont, Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2609,
      "name": "Dumont, P.E.; Mercenier, F. & Lialine, Clement",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2610,
      "name": "Dumoulin, Louis, S.J. & Goeme, Valere, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2611,
      "name": "Dumoutet, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2612,
      "name": "Duncan William Wallace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2613,
      "name": "Duncan, George S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2614,
      "name": "Duncan, Georges S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2615,
      "name": "Dunford John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2616,
      "name": "Dunkin, Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2617,
      "name": "Dunn James D.G. (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2618,
      "name": "Dunn, Archibald J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2619,
      "name": "Dunne, Edmund M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2620,
      "name": "Dunne, J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2621,
      "name": "Dunne, Peter Masten, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2622,
      "name": "Dunney, Joseph A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2623,
      "name": "Dunraven, Earl Of",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2624,
      "name": "Duperray, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2626,
      "name": "Duplessy, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2627,
      "name": "Dupont A.H.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2628,
      "name": "Dupont H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2629,
      "name": "Dupont, G.M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2630,
      "name": "Dupouey, Mireille",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2631,
      "name": "Dupouey, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2632,
      "name": "Duprat, G.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2633,
      "name": "Dupre, Louis K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2634,
      "name": "Dupuis, James, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2635,
      "name": "Durand, A. & Cheikho, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2636,
      "name": "Durand, Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2637,
      "name": "Durandus De S. Porciano O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2638,
      "name": "Durandus Gulielmus Episc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2639,
      "name": "Durant, Will",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2640,
      "name": "Durantel J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2641,
      "name": "Durback, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2642,
      "name": "Durkheim, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2643,
      "name": "Durossy Abee",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2644,
      "name": "Durrany K.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2645,
      "name": "Duruy, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2646,
      "name": "Dussaud, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2647,
      "name": "Dussaule E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2648,
      "name": "Duval-Aumont Cl.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2649,
      "name": "Dvij, Janardan Prasad Jha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2650,
      "name": "Dvivedi Krisna Vallabha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2651,
      "name": "Dvivedi, Mahavirprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2652,
      "name": "Dvivedi, Santipriya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2653,
      "name": "Dvivedi, Sundarlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2654,
      "name": "Dwelshauvers Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2655,
      "name": "Dwyer, Rachel [ed.] & Pinney, Christopher [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2656,
      "name": "Dych William V. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2657,
      "name": "Dykstra Robert C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2658,
      "name": "Dymond, Jonathan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2659,
      "name": "Dyson, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2660,
      "name": "Eadie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2661,
      "name": "Earle, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2662,
      "name": "Easley, Kendell H. (1949-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2663,
      "name": "Eastman, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2664,
      "name": "Eaton Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2665,
      "name": "Eaton Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2666,
      "name": "Eaton, Richard M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2667,
      "name": "Eayrs, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2668,
      "name": "Ebner-Eschenback, Marie von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2669,
      "name": "Ebrard, John H.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2670,
      "name": "Echard, Laurent",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2671,
      "name": "Echardus Magister O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2672,
      "name": "Ecker, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2673,
      "name": "Eckermann, Johann Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2674,
      "name": "Eckert, Julia M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2675,
      "name": "Eckert, Penelope & Mcconnell-Ginet, Sally",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2676,
      "name": "Eco, Umberto & Bredin, Hugh [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2677,
      "name": "Edappilly, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2678,
      "name": "Edart, Jean-Baptiste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2679,
      "name": "Eddy, Mary Baker",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2680,
      "name": "Edelkoort, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2681,
      "name": "Edersheim, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2682,
      "name": "Edgar, Madalen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2683,
      "name": "Edgeworth, Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2684,
      "name": "Edghill, Ernest A. & Cooke, G.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2685,
      "name": "Edmunds, Albert J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2686,
      "name": "Edmunds, Albert J. & Anesaki M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2687,
      "name": "Edmunson, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2688,
      "name": "Edward Vi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2689,
      "name": "Edward, Duke Of Windsor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2690,
      "name": "Edwardes Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2691,
      "name": "Edwardes, Tickner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2692,
      "name": "Edwards Denis (1943-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2693,
      "name": "Edwards Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2694,
      "name": "Edwards William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2695,
      "name": "Edwards, Chilperic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2696,
      "name": "Edwards, Douglas R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2697,
      "name": "Edwards, E.J., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2698,
      "name": "Edwards, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2699,
      "name": "Edwards, G.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2700,
      "name": "Edwards, H. Sutherland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2701,
      "name": "Edwin, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2702,
      "name": "Edwin, Victor, S.J. [ed.] & Daly, Edwin J., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2703,
      "name": "Egan Maurice Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2704,
      "name": "Egerton, Wilbraham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2705,
      "name": "Egger Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2706,
      "name": "Ehl A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2707,
      "name": "Ehrenborg Ferdinand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2708,
      "name": "Ehrhard Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2709,
      "name": "Ehses Stephanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2710,
      "name": "Eichhorn, Albert (1856-1926) & Cayzer, Jeffrey F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2711,
      "name": "Eichler, Lillian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2712,
      "name": "Eisen Ute E. & Maloney Linda M. [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2713,
      "name": "Eisenhofer, Ludwig",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2714,
      "name": "Eisler, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2715,
      "name": "Ekka, Alexius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2716,
      "name": "Elders, Leo J., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2717,
      "name": "Elevamkudy Antony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2718,
      "name": "Elford, R. John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2719,
      "name": "Eliot George (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2720,
      "name": "Eliot Thomas D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2721,
      "name": "Eliot, Charles [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2722,
      "name": "Eliot, George (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2724,
      "name": "Elisabeth De La Trinite, O. Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2725,
      "name": "Ellard, Gerald, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2726,
      "name": "Ellendt-Seyfferts",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2727,
      "name": "Ellens, J. Harold (1932-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2728,
      "name": "Ellicot, C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2729,
      "name": "Ellinwood, Frank F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2730,
      "name": "Elliot, Henry M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2731,
      "name": "Elliott, Binns L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2732,
      "name": "Elliott, Mark W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2733,
      "name": "Elliott, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2735,
      "name": "Ellis, Havelock",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2736,
      "name": "Ellwood Charles A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2737,
      "name": "Eloy, Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2738,
      "name": "El-Saadawi, Nawal & Cobham, Catherine [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2739,
      "name": "Elwin, Warwick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2740,
      "name": "Ely, Mary Redington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2741,
      "name": "Emerson James G. Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2742,
      "name": "Emerson, Ralph Waldo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2743,
      "name": "Emerton Ephraim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2744,
      "name": "Emmanuel Dominic SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2745,
      "name": "Emmerich Anna-Catharina (1774-1824)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2746,
      "name": "Emmerich, Anna-Catharina (1774-1824) & Richardson, George H. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2747,
      "name": "Emmet, Dorothy M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2748,
      "name": "Enang, Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2749,
      "name": "Endean, Philip, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2750,
      "name": "Engel, James F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2751,
      "name": "Engelmann, Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2752,
      "name": "Engels Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2753,
      "name": "Engels Frederick Burns Emile [transl.] & Datta C.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2754,
      "name": "Engels, Frederick & Aveling, Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2755,
      "name": "Engineer, Asghar Ali (1940-2013)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2756,
      "name": "England, John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2757,
      "name": "Ennodius Magnus Felix Ticin Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2758,
      "name": "Enns, Fernando [ed.], Holland, Scott [ed.] & Riggs, Ann [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2759,
      "name": "Ensor, R.C.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2761,
      "name": "Ephraem Syrus, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2762,
      "name": "Epiphanius, Constantiae Episc., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2763,
      "name": "Eppstein, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2764,
      "name": "Ernest Brother",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2765,
      "name": "Ernst E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2766,
      "name": "Errandonea Ignatius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2767,
      "name": "Escande, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2768,
      "name": "Escarra, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2769,
      "name": "Eschbach, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2770,
      "name": "Escoffier, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2771,
      "name": "Eslinger, Richard Laurence (1940-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2772,
      "name": "Esmein, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2773,
      "name": "Ess, Charles [ed.] & Thorseth, May [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2774,
      "name": "Esser, F.X., S.J. & Jackson, Kathleen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2775,
      "name": "Esswein, Anthony Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2776,
      "name": "Estella Diego de O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2777,
      "name": "Esteller A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2778,
      "name": "Estius Guilielmus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2779,
      "name": "Etchegoyen, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2780,
      "name": "Etcheverry Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2781,
      "name": "Ethier, A.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2782,
      "name": "Eucken, Rudolf & Jones, W. Tudor [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2783,
      "name": "Eucken, Rudolf & Pogson, F.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2784,
      "name": "Eudes, Jean C.J.M., St.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2785,
      "name": "Eudes, John, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2786,
      "name": "Eudes, John, Stus. & Myrpby, W.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2787,
      "name": "Eugippius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2788,
      "name": "Euringer Sebastian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2789,
      "name": "Euripides",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2790,
      "name": "Eusebius Pamphili Caesariensis Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2791,
      "name": "Eusebius Pamphili Caesariensis Episc. & Bright William D.D. [introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2792,
      "name": "Eusebius Pamphili, Caesariensis Episc. & Street, Henry [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2793,
      "name": "Eusebius, Pamphili, Caesariensis Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2794,
      "name": "Evangelisti, Silvia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2795,
      "name": "Evans, G.R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2796,
      "name": "Evans, George R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2797,
      "name": "Evans, Gillian R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2798,
      "name": "Evans, Martin (1964-  ) & Phillips, John A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2799,
      "name": "Evans, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2800,
      "name": "Evdokimov Paul (1901-1970) Gythiel Anthony P. [transl.] & Steadman Victoria [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2801,
      "name": "Everts A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2802,
      "name": "Everts, Don",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2803,
      "name": "Ewald, G. Heinrich A.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2804,
      "name": "Ewer, F.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2805,
      "name": "Ewer, Mary Anita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2806,
      "name": "Eygun, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2807,
      "name": "Eymard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2808,
      "name": "Eymieu Antonin S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2809,
      "name": "Eymieu Antonin S.J. & Stoddard John L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2810,
      "name": "Eyton-Jones, Theodora",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2811,
      "name": "Ezhilarasu D. Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2813,
      "name": "Faa Di Bruno, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2815,
      "name": "Faber, Frederick William, C. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2816,
      "name": "Fabre Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2817,
      "name": "Fadli, Abd Al-Hadi & Virjee, Nazmina [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2818,
      "name": "Fahey Denis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2819,
      "name": "Fahey, Denis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2820,
      "name": "Fairbank, John King",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2821,
      "name": "Fairfiel Letitia Denny",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2822,
      "name": "Fairmaire L. & Berce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2823,
      "name": "Fajardo Jose Jimenez",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2824,
      "name": "Falcon, Joseph, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2825,
      "name": "Falise Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2826,
      "name": "Fallon Valere S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2827,
      "name": "Fallon, Michael, M.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2828,
      "name": "Fallon, S.W. & Temple, R.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2829,
      "name": "Fallon, Valere, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2830,
      "name": "Faltz, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2831,
      "name": "Fanfani, Amintore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2832,
      "name": "Fanfani, Ludovicus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2833,
      "name": "Faraone J. Mario",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2834,
      "name": "Fareed Muneer Goolam (1956-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2835,
      "name": "Farges Albert Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2836,
      "name": "Farges, Albert, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2837,
      "name": "Fargues Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2838,
      "name": "Faried, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2839,
      "name": "Farjeon J. Jefferson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2840,
      "name": "Farley, Margaret A., R.S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2841,
      "name": "Farmer, Herbert H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2842,
      "name": "Farnol, Jeffery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2843,
      "name": "Farnum, Mabel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2844,
      "name": "Farquhar, J.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2845,
      "name": "Farrar, Frederic W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2846,
      "name": "Farrell, Allan, P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2847,
      "name": "Farrell, Benjamin F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2848,
      "name": "Farrell, Walter, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2849,
      "name": "Farrow, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2850,
      "name": "Faruqi, Mehr Afshan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2851,
      "name": "Fasnacht, G. Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2852,
      "name": "Fatima, Tanzeem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2853,
      "name": "Fatma, Shabana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2854,
      "name": "Fattorusso G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2855,
      "name": "Faura Federico & Algue Jose S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2856,
      "name": "Faure, Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2857,
      "name": "Faurobert, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2858,
      "name": "Favre Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2859,
      "name": "Favre, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2860,
      "name": "Fawcett, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2861,
      "name": "Fawcett, Millicent Garret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2862,
      "name": "Fawtier, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2863,
      "name": "Faye H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2864,
      "name": "Faye, Eugene de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2865,
      "name": "Feather, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2866,
      "name": "Fedou, Michel, S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2867,
      "name": "Feeney, Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2868,
      "name": "Feeney, Leonard, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2869,
      "name": "Feiling C.E. & Oxenford J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2870,
      "name": "Felice Philippe De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2871,
      "name": "Felix, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2872,
      "name": "Felix, Richard O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2873,
      "name": "Feller, F.X. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2874,
      "name": "Fenech, Louis E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2875,
      "name": "Fenelon Francois De Salignac De La Mothe Eveq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2876,
      "name": "Fenelon, Francois De Salignac De La Mothe, Eveq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2877,
      "name": "Fenelon, Francois De Salignac De La Mother, Eveq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2878,
      "name": "Fenn, Richard K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2879,
      "name": "Fenton, Joseph Clifford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2880,
      "name": "Feret, H.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2881,
      "name": "Fergusson, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2882,
      "name": "Ferm, Vergilius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2883,
      "name": "Fernandes Gracy & Ray Cecily Stewart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2884,
      "name": "Fernandes, Andreas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2885,
      "name": "Fernandes, Denzil, S.J., Roy, Prodipto, Sebak, Dillip Kumar & Roy, Joya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2886,
      "name": "Fernandes, Walter [ed.] & Barbora, Sanjay [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2887,
      "name": "Fernandez Erasto S.S.S. [ed.] & Kunduru Joji S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2888,
      "name": "Fernandez Konrad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2889,
      "name": "Fernandez P.A. S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2890,
      "name": "Fernandez-Calienes, Raul [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2891,
      "name": "Fernando, C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2892,
      "name": "Fernando, Leonard, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2893,
      "name": "Fernando, Leonard, S.J. [ed.] & D'Sami, Bernard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2894,
      "name": "Fernando, Leonard, S.J. [ed.] & Massey, James (1943-2015 ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2895,
      "name": "Fernau, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2896,
      "name": "Fernessole, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2897,
      "name": "Ferrais Aemilius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2898,
      "name": "Ferrara, Orestes & Sheed, F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2899,
      "name": "Ferrari Josephus C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2900,
      "name": "Ferraris Lucius O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2901,
      "name": "Ferrer, Vincent, O.P., Stus. & Dixon, T.A., O.P. Rev. Fr. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2902,
      "name": "Ferrero, Guglielmo & Jaeckel, Theodor [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2903,
      "name": "Ferretto, Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2904,
      "name": "Ferrier David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2905,
      "name": "Ferroli D. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2906,
      "name": "Ferroli, D., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2907,
      "name": "Fessard Gaston S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2908,
      "name": "Fessard, Gaston, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2909,
      "name": "Fessler Joseph & Jungmann Bernardus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2910,
      "name": "Festugiere A.H. & Fabre Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2911,
      "name": "Festugiere A.J. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2912,
      "name": "Festugiere, A.J., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2913,
      "name": "Festugiere, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2914,
      "name": "Feuchtwanger, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2915,
      "name": "Feuerbach, Ludwig & Eliot, George (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2916,
      "name": "Feuerstein Georg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2917,
      "name": "Fichte, Johann Gottlieb (1762-1814)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2918,
      "name": "Fichter, Joseph H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2919,
      "name": "Fidelis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2920,
      "name": "Fidelis (Pseudon)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2921,
      "name": "Field, J.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2922,
      "name": "Fielding, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2923,
      "name": "Fierens, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2924,
      "name": "Fierens-Gevaert, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2925,
      "name": "Fieu S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2926,
      "name": "Filas, F.L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2927,
      "name": "Filastrius Brixiensis Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2928,
      "name": "Filibeck, Giorgio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2929,
      "name": "Filippi Bruna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2930,
      "name": "Fillion Louis-Claude S. S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2931,
      "name": "Fillion Louis-Claude S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2932,
      "name": "Fillion Louis-Claude S.S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2933,
      "name": "Fillmore, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2934,
      "name": "Filograssi, I., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2935,
      "name": "Findlay, Stephen William O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2936,
      "name": "Fine Eduardus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2937,
      "name": "Finegan, Jack",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2938,
      "name": "Finer Herman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2939,
      "name": "Finetti Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2940,
      "name": "Finn, Francis J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2941,
      "name": "Finney Patrick A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2942,
      "name": "Fiocchi Ambrogio M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2943,
      "name": "Firmicus Maternus Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2944,
      "name": "Fischer Georg; Hasitschka Martin & O'Connell Matthew J. S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2945,
      "name": "Fischer Hermann S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2946,
      "name": "Fischer Michael O.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2947,
      "name": "Fischer, Hermann, S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2948,
      "name": "Fischer, Johann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2949,
      "name": "Fischer, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2950,
      "name": "Fisher Mary Pat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2951,
      "name": "Fisher, H.A.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2952,
      "name": "Fisher, Harold H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2953,
      "name": "Fiske, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2954,
      "name": "Fitzgerald, Timothy (1950-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2955,
      "name": "Fitzgerald-Lee J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2956,
      "name": "Fitzpatrick Edward A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2957,
      "name": "Fitzpatrick, Edward A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2958,
      "name": "Fitzsimons, John & Maguire, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2959,
      "name": "Flachaire charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2960,
      "name": "Flamerion S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2961,
      "name": "Flanagan Roderick J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2962,
      "name": "Flanagan, Bernard Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2963,
      "name": "Flanagan, Desmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2964,
      "name": "Flanagan, Kieran [ed.] & Jupp, Peter C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2965,
      "name": "Flanagan, Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2966,
      "name": "Flanagan, Thos.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2967,
      "name": "Flannery, Sarah & Flannery, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2968,
      "name": "Flaubert, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2969,
      "name": "Fleege, Urban H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2970,
      "name": "Fleerackers, Emiel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2971,
      "name": "Fleg, Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2972,
      "name": "Fleming William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2973,
      "name": "Fleming, Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2974,
      "name": "Fleming, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2975,
      "name": "Fletcher Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2976,
      "name": "Fleuriau Bertrand Gabriel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2977,
      "name": "Fliche, Augustin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2978,
      "name": "Fliche, Augustin, Foreville, Raymonde & Rousset, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2979,
      "name": "Flick, Alexander Clarance",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2980,
      "name": "Flornoy E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2981,
      "name": "Flory Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2982,
      "name": "Flynn T.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2983,
      "name": "Flynn Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2984,
      "name": "Flynn, Anthony J., Loretto, Sister Vincent & Simeon, Mother Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2985,
      "name": "Flynn, Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2986,
      "name": "Foch Germain S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2987,
      "name": "Foch, Ferdinand, Marechal & Bentley Mott, Colonel T., D.S.M.C.M.G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2988,
      "name": "Fogazzaro Antonio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2989,
      "name": "Fogazzaro, Antonio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2990,
      "name": "Fokker, Anthony & Gould, Bruce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2991,
      "name": "Foley Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2992,
      "name": "Folghera, J.D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2993,
      "name": "Folliet Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2994,
      "name": "Folliet, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2995,
      "name": "Follis, Elainer R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2996,
      "name": "Fonck Leopold S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2997,
      "name": "Fonck, Leopold, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2998,
      "name": "Fonseca Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 2999,
      "name": "Fonsegrive George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3000,
      "name": "Fontenelle R. Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3001,
      "name": "Fontoynont, V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3002,
      "name": "Foote, R. Bruce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3003,
      "name": "Foran E.A. O.S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3004,
      "name": "Forbes, A.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3005,
      "name": "Forbes, F.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3006,
      "name": "Forcellini, Aegidius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3007,
      "name": "Ford D.W. Cleverley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3008,
      "name": "Ford, David F. (1948-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3009,
      "name": "Ford, David F. (1948-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3010,
      "name": "Ford, John C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3011,
      "name": "Forest Aime",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3012,
      "name": "Forest, Jim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3013,
      "name": "Forlong, J.G.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3014,
      "name": "Formby H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3015,
      "name": "Formby, C.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3016,
      "name": "Formont, Maxime",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3017,
      "name": "Forrester Duncan B. (1933-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3018,
      "name": "Forster Friedrich Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3019,
      "name": "Forster John L. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3020,
      "name": "Forster, E.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3021,
      "name": "Forsyth Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3022,
      "name": "Fortescue, Adrian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3023,
      "name": "Fortman, Bas De Gaay & Goldewijk, Berma Klein",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3024,
      "name": "Fosdick, Harry Emerson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3025,
      "name": "Foster Ellsworth D. [ed.] & Hughes James L. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3026,
      "name": "Fouard Constant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3027,
      "name": "Fouard, Constant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3028,
      "name": "Foucauld Charles de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3029,
      "name": "Foucauld, Charles de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3030,
      "name": "Foucher, A., Thomas, L.A. [transl.] & Thomas, F.W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3031,
      "name": "Foucher, Pere Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3032,
      "name": "Foulquier, Joseph H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3033,
      "name": "Fouqueray Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3034,
      "name": "Fowle, T.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3035,
      "name": "Fowler H.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3036,
      "name": "Fowler H.W. & Fowler F.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3037,
      "name": "Fowler, H.W. & Fowler, F.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3038,
      "name": "Fowler, H.W., Little, W., Coulson, John & Onions, C.T. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3039,
      "name": "Fowler, Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3040,
      "name": "Fowler, Sydney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3041,
      "name": "Fowler, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3042,
      "name": "Fowlie, Wallace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3043,
      "name": "Fox Matthew (1940-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3044,
      "name": "Fox Strangways, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3045,
      "name": "Fox Thomas Charles (1944-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3046,
      "name": "Fox, Thomas Charles (1944-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3047,
      "name": "Frame, Everett James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3048,
      "name": "Franc, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3049,
      "name": "France, Anatole",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3050,
      "name": "Francesco Saverio di Santa Teresa O.S.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3051,
      "name": "Francillon, R.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3052,
      "name": "Francis, Augusta L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3053,
      "name": "Franciscus Assissiensis, O.F.M., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3054,
      "name": "Franciscus, Episc. Heliop & Petrus, Episc., Beryt.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3055,
      "name": "Franco Fernando S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3056,
      "name": "Franco, Secundo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3057,
      "name": "Francois Augustin O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3058,
      "name": "Francotte E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3059,
      "name": "Frank Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3060,
      "name": "Frank, Karl, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3061,
      "name": "Franke, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3062,
      "name": "Franzelin Joannes Baptista S.J. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3063,
      "name": "Frassinetti, Joseph & Hutch, William [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3064,
      "name": "Frayssinous D. Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3065,
      "name": "Frazer, James George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3066,
      "name": "Fredet, Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3067,
      "name": "Freedman David Noel [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3068,
      "name": "Freeman, Anthony J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3069,
      "name": "Freeman, J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3070,
      "name": "Freitag Ulrike [ed.] & Clarence-Smith William Gervase [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3071,
      "name": "Frem, Vergilius [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3072,
      "name": "French, J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3073,
      "name": "French, R.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3074,
      "name": "French, Richard Valpy [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3075,
      "name": "French, W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3076,
      "name": "Freney, Leonard S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3077,
      "name": "Frenz, Margret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3078,
      "name": "Freppel Charles-Emile Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3079,
      "name": "Frere, Walter Howard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3080,
      "name": "Fretheim, Terence E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3081,
      "name": "Freud, Sigmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3082,
      "name": "Frey, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3083,
      "name": "Frick Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3084,
      "name": "Fried, John H.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3085,
      "name": "Friedmann, Yohanan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3086,
      "name": "Frings, J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3087,
      "name": "Frins Victor S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3088,
      "name": "Fritsch, Charles T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3089,
      "name": "Frobes Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3090,
      "name": "Froget Barthelemy O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3091,
      "name": "Froment Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3092,
      "name": "Frommel, Gaston, Wilson, J.M. [transl.] & Bartlet, J.V. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3093,
      "name": "Froom, Leroy Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3094,
      "name": "Frost Bede",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3095,
      "name": "Froude, James Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3096,
      "name": "Frovig, D.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3097,
      "name": "Fruhstorfer Karl Msgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3098,
      "name": "Fuchs Eric (1932-  ) & Daigle Marsha [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3099,
      "name": "Fuellenbach John SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3100,
      "name": "Fuellenbach, John, SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3101,
      "name": "Fuhlrott, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3102,
      "name": "Fuller J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3103,
      "name": "Fuller, Jean Overton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3104,
      "name": "Fulop-Miller Rene Flint F.S. [transl.] & Tait D.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3105,
      "name": "Fulop-Miller, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3106,
      "name": "Fulop-Miller, Rene, Gode, Alexander [transl.] & Fulop-Miller, Erika [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3107,
      "name": "Funck-Brentano Frantz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3108,
      "name": "Funck-Brentano, Frantz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3109,
      "name": "Funck-Brentano, Frantz & Willson, Herbert [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3110,
      "name": "Fung, Ronald Y.K. (1937-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3111,
      "name": "Funk Franciscus Xaverius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3112,
      "name": "Funk, Franciscus Xaverius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3113,
      "name": "Funk, Philipp",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3114,
      "name": "Furfey, Paul Hanly",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3115,
      "name": "Furnish, Victor Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3116,
      "name": "Furseth, Inger & Repstad, Pal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3117,
      "name": "Fuster, J.M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3118,
      "name": "Fyson P.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3119,
      "name": "Fyzee-Rahamin, Atiya Begum",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3120,
      "name": "Gaboriau, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3121,
      "name": "Gabriel a S. Maria Magdalena O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3122,
      "name": "Gabriel A S. Maria Magdalena, O. Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3123,
      "name": "Gabriel A S. Maria Magdalena, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3124,
      "name": "Gabriel Henry A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3125,
      "name": "Gabriel of St. Joseph C.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3126,
      "name": "Gabriel, Theodore P.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3127,
      "name": "Gachter, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3128,
      "name": "Gaertringen Hiller von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3129,
      "name": "Gaetan du S. Nom de Marie, SS. CC.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3130,
      "name": "Gagarin J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3131,
      "name": "Gagliardi Achilles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3132,
      "name": "Gahan William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3133,
      "name": "Gaillard, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3134,
      "name": "Gairdner, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3135,
      "name": "Gairdner, W.H. Temple",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3136,
      "name": "Gajano, S. Boesch, Farrugia, Edward G., S.J. & Pliukhanova, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3137,
      "name": "Gale, Christopher, Borden, Neil H., Jr. & Jeannet, Jean-Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3138,
      "name": "Gall, James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3139,
      "name": "Gallas K.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3140,
      "name": "Gallaud, Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3141,
      "name": "Galliffet Joseph De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3142,
      "name": "Gallo T.A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3143,
      "name": "Galloway, T.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3144,
      "name": "Gallwey P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3145,
      "name": "Galsworthy, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3146,
      "name": "Galtier Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3147,
      "name": "Galtier, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3148,
      "name": "Galtier-Boissiere",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3149,
      "name": "Gamba, Carlo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3150,
      "name": "Gamber Stanislas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3151,
      "name": "Gamble, Eliza Burt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3152,
      "name": "Gandhi, M.K. & Desai, Mahadev [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3153,
      "name": "Gandhi, Mohandas Karamcand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3154,
      "name": "Gandhi, Mohandas Karamcand & Cander, Jag Parvesh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3155,
      "name": "Gandhi, Mohandas Karamcand & Gandhi, Gopalkrishna [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3156,
      "name": "Gandhi, P. Jegadish [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3157,
      "name": "Gangopadhyaya, Benimadhava",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3158,
      "name": "Gangopadhyaya, Mano Mohan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3159,
      "name": "Gangopadhyaya, Tarakanatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3160,
      "name": "Gangopadhyaya, Varsha [ed.], Johar, Jhakiya [ed.] & Dabhi, Jimmy C., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3161,
      "name": "Gannon, Robert I., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3162,
      "name": "Garant, J.-S., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3163,
      "name": "Garbett, Cyril",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3164,
      "name": "Garcia Ramon S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3165,
      "name": "Gardeil Ambroise O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3166,
      "name": "Gardner, Percy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3167,
      "name": "Garesche Edward F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3168,
      "name": "Garesche, Edward F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3169,
      "name": "Garg, Giridharilal Sarma",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3170,
      "name": "Garner, James Wilford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3171,
      "name": "Garnett Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3172,
      "name": "Garnett, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3173,
      "name": "Garnier Adrien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3174,
      "name": "Garnier, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3175,
      "name": "Garraghan, Gilbert J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3176,
      "name": "Garraghan, Gilbert J., S.J. & Delanglez, Jean, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3177,
      "name": "Garratt, G.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3178,
      "name": "Garric, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3179,
      "name": "Garrick, H.B.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3180,
      "name": "Garrigou-Lagrange Reginald O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3181,
      "name": "Garrigou-Lagrange, Reginald, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3182,
      "name": "Garrigou-Lagrange, Reginald, O.P. & Doyle, M. Timothea, O.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3183,
      "name": "Garriguet, Leon Pierre & Parkinson, Henry, Mgr. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3184,
      "name": "Garrold R.P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3185,
      "name": "Garson John George & Read Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3186,
      "name": "Garstang, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3187,
      "name": "Garstang, John & Garstang, J.B.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3188,
      "name": "Garvan, F.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3189,
      "name": "Garvey John (1944-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3190,
      "name": "Gasparri Petrus Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3191,
      "name": "Gasparri, Petrus, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3192,
      "name": "Gasquet Francis Aidan O.S.B. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3193,
      "name": "Gaster, Moses",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3194,
      "name": "Gaston, Anne-Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3195,
      "name": "Gastoue Amedee",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3196,
      "name": "Gatterer Michael S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3197,
      "name": "Gatterer Michael S.J. & Krus Francis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3198,
      "name": "Gatterer, Michael, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3199,
      "name": "Gaubert, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3200,
      "name": "Gauche, William J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3201,
      "name": "Gaud Sarayupanda",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3202,
      "name": "Gaudier Antonius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3203,
      "name": "Gauefroy-Demombynes & Platonov",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3204,
      "name": "Gaume Jean Joseph Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3205,
      "name": "Gaume, Jean Joseph, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3206,
      "name": "Gausdal, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3207,
      "name": "Gautam Ram Avtar [ed.] & Prasad Kamalkant [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3208,
      "name": "Gauthier, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3209,
      "name": "Gautier, Theophile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3210,
      "name": "Gautrelet Francois-Xavier S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3211,
      "name": "Gavin M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3212,
      "name": "Gay Charles Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3213,
      "name": "Gaze, Harry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3214,
      "name": "Gearon Patrick J. O.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3215,
      "name": "Gebelin, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3216,
      "name": "Geddes Leonard S.J. & Thurston Herbert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3217,
      "name": "Geddes, Patrick & Thomson, J. Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3218,
      "name": "Geeraerts J.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3219,
      "name": "Geetha V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3220,
      "name": "Geiermann, Peter, C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3221,
      "name": "Geiger, L.-B., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3222,
      "name": "Geis Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3223,
      "name": "Geissler, Eugene S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3224,
      "name": "Gelle F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3225,
      "name": "Gellner, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3226,
      "name": "Gelpi, Donald L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3227,
      "name": "Gemelli Agostino O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3228,
      "name": "Gemelli, Agostino, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3229,
      "name": "Genard, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3230,
      "name": "Genelli S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3231,
      "name": "Genicot Eduardus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3232,
      "name": "Genicot, Eduardus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3233,
      "name": "Genicot, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3234,
      "name": "Genier Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3235,
      "name": "Genouillac, Henri De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3236,
      "name": "Gense James H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3237,
      "name": "Genung, John F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3238,
      "name": "Geny Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3239,
      "name": "Geny, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3240,
      "name": "Geoghegan, Arthur T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3241,
      "name": "Georg, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3242,
      "name": "George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3243,
      "name": "George Ross F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3244,
      "name": "George, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3245,
      "name": "George, Merlyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3246,
      "name": "George, S.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3247,
      "name": "Georgius, Augustinus Antonius, O.Erem.S.Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3248,
      "name": "Gerbet Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3249,
      "name": "Gerdil Hyacinthe Sigismond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3250,
      "name": "Geregin, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3251,
      "name": "Gereo A.S. Joseph O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3252,
      "name": "Gerest R. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3253,
      "name": "Germain De Saint Stanislas C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3254,
      "name": "Gerrard Thomas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3255,
      "name": "Gerson Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3256,
      "name": "Gerster Thomas Villanova A Zeil OM Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3257,
      "name": "Gertrudis, Abb., Sta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3258,
      "name": "Gesenius, Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3259,
      "name": "Geser, Fintan, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3260,
      "name": "Gessi, Leone",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3261,
      "name": "Geudens, Martin, O.Praem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3262,
      "name": "Geuser, Marie-Antoinette De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3263,
      "name": "Geyser, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3264,
      "name": "Gezelle Guido",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3265,
      "name": "Gezelle, Guido",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3266,
      "name": "Ghazali Abu Hamid (1058-1111) & Burrell David B. C.S.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3267,
      "name": "Ghazali Abu Hamid (1058-1111) & Mccarthy Richard J. S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3268,
      "name": "Ghazali Muhammad (1954-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3269,
      "name": "Ghazali, Abu Hamid (1058-1111)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3270,
      "name": "Ghazali, Abu Hamid (1058-1111) & Jackson, Sherman A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3271,
      "name": "Ghedini Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3272,
      "name": "Ghellinck Joseph de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3273,
      "name": "Ghellinck, Joseph de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3274,
      "name": "Gheon Henri (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3275,
      "name": "Ghika, Vladimir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3276,
      "name": "Ghos Buddhadeb",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3277,
      "name": "Ghos, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3278,
      "name": "Ghos, A. & Kuraishi, Muhammad Hamid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3279,
      "name": "Ghos, Amitav (1956-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3280,
      "name": "Ghos, Nilabja; Kar, Sabyasachi & Sarma, Suresh (1945-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3281,
      "name": "Ghos, Ratna [ed.] & Pramanik, Alok Kumar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3282,
      "name": "Giallanza, Joel, CSC",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3283,
      "name": "Gianotti Timothy J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3284,
      "name": "Gibb, Hamilton Alexander Rosskeen [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3285,
      "name": "Gibbon Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3286,
      "name": "Gibbon, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3287,
      "name": "Gibbons James Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3288,
      "name": "Gibbs, Philip, SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3289,
      "name": "Gibson Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3290,
      "name": "Gibson, Edgar C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3291,
      "name": "Gibson, Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3292,
      "name": "Gibson, Margaret Dunlop [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3293,
      "name": "Giddings Franklin Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3294,
      "name": "Gide, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3295,
      "name": "Gide, Charles & Rist, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3296,
      "name": "Gielemans Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3297,
      "name": "Gierens M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3298,
      "name": "Gigon H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3299,
      "name": "Gigot Francis E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3300,
      "name": "Gihr Nikolaus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3301,
      "name": "Gilbert, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3302,
      "name": "Gilchrist, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3303,
      "name": "Gilchrist, R.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3304,
      "name": "Gilchrist, Roberta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3305,
      "name": "Gildeaux, Paulin, Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3306,
      "name": "Giles, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3307,
      "name": "Gilkey, Charles W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3308,
      "name": "Gill Henry V. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3309,
      "name": "Gill, David W.J. [ed.] & Gempf, Conrad [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3310,
      "name": "Gill, Eric",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3311,
      "name": "Gille, Albert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3312,
      "name": "Gillespie, Thomas W. (1928-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3313,
      "name": "Gillet M.S. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3314,
      "name": "Gillet M.S. O.P. & Green B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3315,
      "name": "Gillet, C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3316,
      "name": "Gillet, Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3317,
      "name": "Gillet, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3318,
      "name": "Gilliard L. C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3319,
      "name": "Gilliard, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3320,
      "name": "Gillis James M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3321,
      "name": "Gilman, Charlotte Perkins",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3322,
      "name": "Gilson Etienne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3323,
      "name": "Gilson, Etienne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3324,
      "name": "Gilson, Etienne; Trethowan, Illtyd, O.S.B. [transl.] & Sheed, F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3325,
      "name": "Ginhac, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3326,
      "name": "Giordani, Igino",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3327,
      "name": "Giordani, Igino & Zizzamia, Alba I. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3328,
      "name": "Girardey Ferreol C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3329,
      "name": "Girardey, Ferreol, C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3330,
      "name": "Giraudo, Cesare, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3331,
      "name": "Giri V. Mohini [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3332,
      "name": "Giris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3333,
      "name": "Giroux Antoine S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3334,
      "name": "Giroux Antoine S.J. & Bernard J. Edgar S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3335,
      "name": "Gish, Arthur G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3336,
      "name": "Gismondi H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3337,
      "name": "Gisquiere, Emmanuel, O.Praem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3338,
      "name": "Gits, Ad.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3339,
      "name": "Gits, Alexander, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3340,
      "name": "Gladstone, W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3341,
      "name": "Glaesener, Adrien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3342,
      "name": "Glaire J. B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3343,
      "name": "Glancey Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3344,
      "name": "Glanville, S.R.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3345,
      "name": "Glasenapp, Helmuth von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3346,
      "name": "Glorieux, Chanoine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3347,
      "name": "Glorieux, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3348,
      "name": "Glotz, Gustave & Cohen, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3349,
      "name": "Glotz, Gustave, Cohen, Robert & Roussel, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3350,
      "name": "Glover, T.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3351,
      "name": "Gnanapragasam, Patrick [ed.] & Schussler Fiorenza, Elisabeth (1938-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3352,
      "name": "Gnaninathan, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3353,
      "name": "Gobat Georgius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3354,
      "name": "Gobillot, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3355,
      "name": "Goblet D'alviella, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3356,
      "name": "Godard Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3357,
      "name": "Godard Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3358,
      "name": "Godard, John George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3359,
      "name": "Godefroy, Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3360,
      "name": "Godet, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3361,
      "name": "Godfried, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3362,
      "name": "Godfroy S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3363,
      "name": "Godin, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3364,
      "name": "Godin, H. & Daniel, Yvan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3365,
      "name": "Goebel Edmund J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3366,
      "name": "Goelzer Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3367,
      "name": "Goelzer, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3368,
      "name": "Goerres-Coudenhove, Ida & Codd, Elsie [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3369,
      "name": "Goethe Johann Wolfgang Von & Taylor Bayard [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3370,
      "name": "Goethe, Johann Wolfgang von & Carlyle, Thomas [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3371,
      "name": "Goettsberger Johann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3372,
      "name": "Goffine Leonard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3373,
      "name": "Gogol, Nikolai V. (1809-1852)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3374,
      "name": "Gogol, Nikolai V. (1809-1852) & Hoggart, D.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3375,
      "name": "Goguel, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3376,
      "name": "Goheen, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3377,
      "name": "Gokhale Namita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3378,
      "name": "Gokulaji, Radhamohan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3379,
      "name": "Golden, Lawrence G. & Zimmerman, Donald A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3380,
      "name": "Goldewijk, Berma Klein & Fortman, Bas De Gaay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3381,
      "name": "Goldhagen Hermann S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3382,
      "name": "Goldie Francis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3383,
      "name": "Goldie, Agnes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3384,
      "name": "Goldie, Francis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3385,
      "name": "Goldsmith Oliver",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3386,
      "name": "Goldsmith, M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3387,
      "name": "Goldsmith, Oliver",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3388,
      "name": "Goldstein, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3389,
      "name": "Goldstein, David & Avery, Martha Moore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3390,
      "name": "Golenvaux Franciscus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3391,
      "name": "Gollock G.A. & Hewat E.G.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3392,
      "name": "Goma Y Tomas Isidro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3393,
      "name": "Gomes Manuel P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3394,
      "name": "Gomez Rodeles, Cecilio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3395,
      "name": "Gomme George Laurence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3396,
      "name": "Gondos, Grunhut, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3397,
      "name": "Gonella, Guido",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3398,
      "name": "Gonella, Guido & Bouscaren, Timothy Lincoln, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3399,
      "name": "Gonnelieu, Jerome De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3400,
      "name": "Gonsalves, Archie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3401,
      "name": "Gonsalves, Francis, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3402,
      "name": "Gonzalez Zephyrimus O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3403,
      "name": "Goodier Alban S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3404,
      "name": "Goodier, Alban, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3405,
      "name": "Goodridge, G.W.F.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3406,
      "name": "Goodseill Willystine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3407,
      "name": "Goodspeed Edgar J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3408,
      "name": "Goodspeed, Edgar J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3409,
      "name": "Goodwin, Harvey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3410,
      "name": "Gooptu, Suparna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3411,
      "name": "Goosen, Gideon C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3412,
      "name": "Goossens Werner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3413,
      "name": "Goossens, Werner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3414,
      "name": "Gopal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3415,
      "name": "Gopal, Lallanji [ed.] & Srivastava, V.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3416,
      "name": "Goradia Prafull",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3417,
      "name": "Gorce M.-M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3418,
      "name": "Gorce, M.-M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3419,
      "name": "Gorce, M.-M. & Mortier, Raoul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3420,
      "name": "Gordillo, Mauricius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3421,
      "name": "Gordon Charles George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3422,
      "name": "Gordon E.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3423,
      "name": "Gordon, Andrew, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3424,
      "name": "Gordon, Cyrus H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3425,
      "name": "Gordon, E.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3426,
      "name": "Gore, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3427,
      "name": "Gore, Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3428,
      "name": "Gorey, V.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3429,
      "name": "Gorham, Charles Turner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3430,
      "name": "Gorky, Maxim (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3431,
      "name": "Gosling, David L. (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3432,
      "name": "Gosse, Edmund W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3433,
      "name": "Gosselin J.-B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3434,
      "name": "Gosvami, Kisorilal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3435,
      "name": "Gosvami, Nityanandavinoda",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3436,
      "name": "Goswami B.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3437,
      "name": "Goswami, Amit & Goswami, Maggie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3438,
      "name": "Gott, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3439,
      "name": "Gotthelf, Jeremias",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3440,
      "name": "Gotti S.V. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3441,
      "name": "Gottschalk Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3442,
      "name": "Goudge, H.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3443,
      "name": "Goudin Antonius O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3444,
      "name": "Goudzwaard Bob; De Lange Harry & Vennen Mark R. Vander [ed. & Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3445,
      "name": "Gougnard, Armand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3446,
      "name": "Gouhier Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3447,
      "name": "Gouhier, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3448,
      "name": "Gouin, Francois, Swan, Howard [transl.] & Betis, Victor [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3449,
      "name": "Gould, Ezra P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3450,
      "name": "Gould, Frederick J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3451,
      "name": "Gould, Nat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3452,
      "name": "Govindadas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3453,
      "name": "Gowins, Phillip (1945-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3454,
      "name": "Goyaliya, Dayacandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3455,
      "name": "Goyau Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3456,
      "name": "Goyau, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3457,
      "name": "Goyau, Georges & Dreves, M., S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3458,
      "name": "Goyau, Georges, Perate, Andre & Fabre, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3459,
      "name": "Grabar Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3460,
      "name": "Graber Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3461,
      "name": "Grabmann Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3462,
      "name": "Grabmann Martin [hrg.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3463,
      "name": "Grabmann, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3464,
      "name": "Grabowski, John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3465,
      "name": "Gracian Baltasar S.J. & Monterio M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3466,
      "name": "Grady, Thomas [eds.] & Huston, Paula [eds.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3467,
      "name": "Graeser, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3468,
      "name": "Graf, Ernest, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3469,
      "name": "Graffin R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3470,
      "name": "Graffin, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3471,
      "name": "Graffin, R. & Nau, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3472,
      "name": "Graham R.B. Cunninghame",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3473,
      "name": "Graham, Aelred, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3474,
      "name": "Graham, Benjamin (1894-  ) & McGolrick, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3475,
      "name": "Graham, Frank D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3476,
      "name": "Graham, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3477,
      "name": "Graham, William C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3478,
      "name": "Grahame Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3479,
      "name": "Gramatica, Aloysius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3480,
      "name": "Grand, Sarah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3481,
      "name": "Granderath Theodore S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3482,
      "name": "Grandgent, C.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3483,
      "name": "Grandin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3484,
      "name": "Grandmaison, Leonce De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3485,
      "name": "Grant Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3486,
      "name": "Grant, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3487,
      "name": "Gratieux, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3488,
      "name": "Gratry A. C.Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3489,
      "name": "Gratry A. C.Orat. & Brown Stephen J. S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3490,
      "name": "Grattan, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3491,
      "name": "Gravelly, F.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3492,
      "name": "Gravelly, F.H. & Ramachandran, T.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3493,
      "name": "Graveson, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3494,
      "name": "Gray Tim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3495,
      "name": "Gray, A. Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3496,
      "name": "Gray, B. Kirkman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3497,
      "name": "Gray, George Buchanam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3498,
      "name": "Gray, George Buchanam & Peake, Arthur S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3499,
      "name": "Gray, Louis H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3500,
      "name": "Gray, Maxwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3501,
      "name": "Greaves, Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3502,
      "name": "Green, Alice Stopford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3503,
      "name": "Green, J. Reynolds",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3504,
      "name": "Green, J.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3505,
      "name": "Green, John Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3506,
      "name": "Green, Nile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3507,
      "name": "Green, Thomas H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3508,
      "name": "Greene, Graham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3509,
      "name": "Greene, W.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3510,
      "name": "Greenfield, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3511,
      "name": "Greenwood Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3512,
      "name": "Greg, W.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3513,
      "name": "Gregg, Cecil Freeman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3514,
      "name": "Gregg, John Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3515,
      "name": "Gregoire de Narek & Mahe, Annie et Jean-Pierre [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3516,
      "name": "Gregorius Nazianzenus Archiep. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3517,
      "name": "Gregorius Nyssenus Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3518,
      "name": "Gregorius Nyssenus, Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3519,
      "name": "Gregorius Nyssenus, Episc. Stus. & Danielou, De Jean [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3520,
      "name": "Gregorius Nyssenus, Episc. Stus. & Laplace, Jean, S.J. [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3521,
      "name": "Gregorius, Neocaesariensis, Episc. Stus & Salmond, S.D.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3522,
      "name": "Greive Wolfang [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3523,
      "name": "Grente Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3524,
      "name": "Gressmann, Hugo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3525,
      "name": "Greswell, Edvardus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3526,
      "name": "Grewal Royina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3527,
      "name": "Grey, Mary C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3528,
      "name": "Grey, Zane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3529,
      "name": "Grierson, Francis D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3530,
      "name": "Grierson, George Abraham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3531,
      "name": "Griese, N. Orville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3532,
      "name": "Grieve, Jane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3533,
      "name": "Griffith, Gwilym O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3534,
      "name": "Griffith-Jones, Robin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3535,
      "name": "Griffiths, Paul J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3536,
      "name": "Grignard, Adelin, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3537,
      "name": "Grignard, Andre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3538,
      "name": "Grimal J. S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3539,
      "name": "Grimaud Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3540,
      "name": "Grimault J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3541,
      "name": "Grimes, Cecil John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3542,
      "name": "Grimm C.L. Wilibald & Thayer Joseph Henry [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3543,
      "name": "Grimm Car.Lud. Wilibaldus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3544,
      "name": "Grimm, C.L. Wilibald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3545,
      "name": "Grimm, Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3546,
      "name": "Grimm, Jakob & Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3547,
      "name": "Grimmelshausen, Hans Jacob Christoffel von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3548,
      "name": "Grisar, Jos., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3549,
      "name": "Grober, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3550,
      "name": "Groenings James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3551,
      "name": "Groneman W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3552,
      "name": "Gronlund, Laurence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3553,
      "name": "Gros Jeffrey F.S.C. [ed.] & Rempel John D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3554,
      "name": "Grosch Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3555,
      "name": "Gross, Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3556,
      "name": "Grossi Tommaso",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3557,
      "name": "Grosso, Andrew T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3558,
      "name": "Grote G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3559,
      "name": "Grotius Hugo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3560,
      "name": "Grotius, Hugo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3561,
      "name": "Grou Jean Nicolas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3562,
      "name": "Grou Jean Nicolas S.J. & Stawell Rodolph [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3563,
      "name": "Grou, Jean Nicolas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3564,
      "name": "Grou, Jean Nicolas, S.J. & Clinton, Alex, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3565,
      "name": "Grou, Jean Nicolas, S.J. & Fitzgerald, Teresa [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3566,
      "name": "Groussau, Edmond S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3567,
      "name": "Grousset, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3568,
      "name": "Grousset, Rene, Auboyer, Jeannine & Buhot, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3569,
      "name": "Grubb, Kenneth G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3570,
      "name": "Gruender Hubert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3571,
      "name": "Grugni, Anthony, PIME (1941-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3572,
      "name": "Grundy J.B.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3573,
      "name": "Grunebaum, Gustave E. von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3574,
      "name": "Gualterus Cancellarius Bartholomaeus De Bononia O.F.M. & Deneffe Augustinus S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3575,
      "name": "Guardini Romano (1885-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3576,
      "name": "Guardini, Romano (1885-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3577,
      "name": "Guardini, Romano (1885-  ) & Lane, Ada [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3578,
      "name": "Guedalla Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3579,
      "name": "Guenon, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3580,
      "name": "Gueranger Prosper O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3581,
      "name": "Gueranger Prosper O.S.B. & Shepherd Dom Laurence [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3582,
      "name": "Guerin Paul Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3583,
      "name": "Guerreiro, Fernao, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3584,
      "name": "Guerreiro, Fernao, S.J. & Payne, C.H. [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3585,
      "name": "Guerry Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3586,
      "name": "Guerry, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3587,
      "name": "Guesdon Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3588,
      "name": "Guggenberger A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3589,
      "name": "Guibert J. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3590,
      "name": "Guibert Joseph De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3591,
      "name": "Guibert, J., S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3592,
      "name": "Guibert, Joseph de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3593,
      "name": "Guicciardinus Ludovicus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3594,
      "name": "Guierre, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3595,
      "name": "Guignebert, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3596,
      "name": "Guigou Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3597,
      "name": "Guilday, Peter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3598,
      "name": "Guillaume Alphonse S.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3599,
      "name": "Guillemin Amedee",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3600,
      "name": "Guillemin, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3601,
      "name": "Guilleminot H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3602,
      "name": "Guillois, Ambroise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3603,
      "name": "Guillon, Marie Nicolas Sylvestre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3604,
      "name": "Guillore, Francois, S.J. & Carter, T.T. [introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3605,
      "name": "Guillore, Francois, S.J. & Carter, T.T. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3606,
      "name": "Guimbert J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3607,
      "name": "Guinan, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3608,
      "name": "Guindon André O.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3609,
      "name": "Guiraud Jean & De Mattos Katharine [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3610,
      "name": "Guiraud, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3611,
      "name": "Guitton, Georges, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3612,
      "name": "Guitton, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3613,
      "name": "Guizot M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3614,
      "name": "Guizot, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3615,
      "name": "Gulabray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3616,
      "name": "Gunkel, Hermann (1862-1932)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3617,
      "name": "Gunn, Charles Hains [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3618,
      "name": "Gunther, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3619,
      "name": "Gunton, Colin E. [ed.], Holmes, Stephen R. [ed.] & Rae, Murray A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3620,
      "name": "Gupta G.B. [ed.] & Bandyopadhyay S.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3621,
      "name": "Gupta R.K. (1950-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3622,
      "name": "Gupta Som Raj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3623,
      "name": "Gupta Vijay Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3624,
      "name": "Gupta, Maithilisarana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3625,
      "name": "Gupta, Narottamlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3626,
      "name": "Gupta, Pratul C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3627,
      "name": "Gupta, S.C. & Kapoor, V.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3628,
      "name": "Gupta, V.P. & Gupta, Mohini",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3629,
      "name": "Gurian Waldemar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3630,
      "name": "Gurian, Waldemar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3631,
      "name": "Gurian, Waldemar & Peeler, E.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3632,
      "name": "Guru, Kamtaprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3633,
      "name": "Gury Joannes Petrus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3634,
      "name": "Gury, Joannes Petrus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3635,
      "name": "Gutbirius, Aegidius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3636,
      "name": "Guthlin A. Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3637,
      "name": "Guthrie, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3638,
      "name": "Guthrie, W.K.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3639,
      "name": "Gutierrez Gustavo (1928-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3640,
      "name": "Guy, H.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3641,
      "name": "Guyau, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3642,
      "name": "Guzman Luis De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3643,
      "name": "Gwatkin, H.M. [ed.]; Whitney, J.P. [ed.]; Tanner, J.R. [ed.]; Previte-Orton, C.W. [ed.] & Brooke, Z.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3644,
      "name": "Gwynn Denis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3645,
      "name": "Gwynn Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3646,
      "name": "Gwynn, Denis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3647,
      "name": "Gwynn, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3648,
      "name": "Gyan, Satish Chandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3649,
      "name": "Gyp",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3650,
      "name": "Haag, Herbert & Nowell, Robert [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3651,
      "name": "Haan Henricus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3652,
      "name": "Haas H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3653,
      "name": "Habermas, Jurgen & McCarthy, Thomas, O.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3654,
      "name": "Habert O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3655,
      "name": "Habets, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3656,
      "name": "Habib, Irfan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3657,
      "name": "Habibullah, A.B.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3658,
      "name": "Hacket, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3659,
      "name": "Hackett, J.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3660,
      "name": "Haddon Alfred C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3661,
      "name": "Haddon, Alfred C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3662,
      "name": "Haeck, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3663,
      "name": "Haeckel, Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3664,
      "name": "Haeckel, Ernst & Aveling, Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3665,
      "name": "Haeckel, Ernst & Mccabe, Joseph [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3666,
      "name": "Haecker Theodor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3667,
      "name": "Haegheberg, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3668,
      "name": "Haegy, Joseph, C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3669,
      "name": "Haeri, Fadhlalla, Shaykh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3670,
      "name": "Haessle Johannes Borne Etienne [trad.] & Linn Pierre [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3671,
      "name": "Haffner, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3672,
      "name": "Hagan John Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3673,
      "name": "Hagelberg, Dave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3674,
      "name": "Hagen Martinus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3675,
      "name": "Hagenbach, K.R. & Buch, Carl W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3676,
      "name": "Haggard, H. Rider",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3677,
      "name": "Haggeney Karl S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3678,
      "name": "Haghebaert, Pl.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3679,
      "name": "Hagspiel, Bruno M., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3680,
      "name": "Hahn, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3681,
      "name": "Hahn, Georg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3682,
      "name": "Hahn, Wilhelm Traugott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3683,
      "name": "Hahn-Hahn Countess",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3684,
      "name": "Hahnius Augustus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3685,
      "name": "Haig, Wolseley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3686,
      "name": "Haight Roger S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3687,
      "name": "Haight, Roger, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3688,
      "name": "Haines, C. Grove & Hoffman, Ross J.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3689,
      "name": "Hainsselin, Montague T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3690,
      "name": "Halbar, B.G. [Ed.], Morab, S.G. [Ed.], Patil, Suresh [Ed.] & Gupta, Ramji [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3691,
      "name": "Haldane, J.B.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3692,
      "name": "Haldane, J.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3693,
      "name": "Haldane, Viscount",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3694,
      "name": "Haldar, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3695,
      "name": "Halevy, Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3696,
      "name": "Halifax Viscount",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3697,
      "name": "Halifax, Lord [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3698,
      "name": "Halkin, Leon E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3699,
      "name": "Hall H.S. & Knight S.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3700,
      "name": "Hall Manly Palmer (1901-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3701,
      "name": "Hall, D.G.E. & Sen, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3702,
      "name": "Hall, Francis J. & Hallock Frank H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3703,
      "name": "Hallaberg L. Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3704,
      "name": "Hallack Cecily",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3705,
      "name": "Hallack, Cecily",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3706,
      "name": "Hallam, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3707,
      "name": "Hallaq Wael B. (1955-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3708,
      "name": "Halle, Fannina W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3709,
      "name": "Hallet F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3710,
      "name": "Halphen, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3711,
      "name": "Halpin P.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3712,
      "name": "Halpin, P.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3713,
      "name": "Haluscynskyj, Theodosius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3714,
      "name": "Hamelin O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3715,
      "name": "Hamerton, Philip Gilbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3716,
      "name": "Hamilton Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3717,
      "name": "Hamilton, Earl J. [ed.], Rees, Albert [ed.] & Johnson, Harry G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3718,
      "name": "Hamilton, Lady Anne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3719,
      "name": "Hamilton, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3720,
      "name": "Hamm Erton J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3721,
      "name": "Hammarlund Anders [ed.] Olsson Tord [ed.] & Ozdalga Elisabeth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3722,
      "name": "Hammerstein, L.von, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3723,
      "name": "Hammerton, J.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3724,
      "name": "Hammill, John Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3725,
      "name": "Hammond, C.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3726,
      "name": "Hamon S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3727,
      "name": "Hamon, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3728,
      "name": "Han Nathan E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3729,
      "name": "Hanbury H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3730,
      "name": "Hancock, Roger N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3731,
      "name": "Hanfstaengl, Franz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3732,
      "name": "Hanif N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3733,
      "name": "Hanna, H.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3734,
      "name": "Hannan Jerome D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3735,
      "name": "Hanotaux, Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3736,
      "name": "Hanozin, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3737,
      "name": "Hanrion Alexis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3738,
      "name": "Hansen Thomas Blom [ed.] & Jaffrelot Christophe [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3739,
      "name": "Hanson Anthony Tyrrell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3740,
      "name": "Hanson, Bradley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3741,
      "name": "Hanson, John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3742,
      "name": "Hanssens, Ioannes Michael, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3743,
      "name": "Haraprasad Shastri Mahamahopadhya [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3744,
      "name": "Harbrecht John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3745,
      "name": "Hardeman R. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3746,
      "name": "Hardwick Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3747,
      "name": "Hardwick, J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3748,
      "name": "Hardy, J.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3749,
      "name": "Hardy, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3750,
      "name": "Hardy, Thomas & Maini, Darshan Singh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3751,
      "name": "Hare, William Loftus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3752,
      "name": "Hares, W.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3753,
      "name": "Haris, Ranjana (Ed.) & Harisankar, V. Bharathi (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3754,
      "name": "Hariscandra, Bharatendu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3755,
      "name": "Harivans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3756,
      "name": "Harker, L. Allen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3757,
      "name": "Harless, Adolph Von & Morrison, A.W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3758,
      "name": "Harlez, Mgr De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3759,
      "name": "Harmless, J. William, S.J. (1953-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3760,
      "name": "Harnack, Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3761,
      "name": "Harnack, Adolf & Moffat, James [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3762,
      "name": "Harnack, Adolf & Saunders, T.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3763,
      "name": "Harnack, Adolf & Wilkinson, J.R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3764,
      "name": "Harnack, Adolf, Buchanan, Neil [trad.], Millar, James [trad.] & Speirs, E.B. [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3765,
      "name": "Harney, Martin P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3766,
      "name": "Harper, Henry A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3767,
      "name": "Harper, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3768,
      "name": "Harper, William Rainey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3769,
      "name": "Harraden, Beatrice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3770,
      "name": "Harrand, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3771,
      "name": "Harre, T. Everett",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3772,
      "name": "Harrell, Gilbert D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3773,
      "name": "Harrington Daniel J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3774,
      "name": "Harrington H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3775,
      "name": "Harrington, Daniel J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3776,
      "name": "Harris, Paul T. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3777,
      "name": "Harris, Rendel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3778,
      "name": "Harrison Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3779,
      "name": "Harrison Wilmot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3780,
      "name": "Harrison, Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3781,
      "name": "Harrisville, Roy A. & Sundberg, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3782,
      "name": "Harry, M.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3783,
      "name": "Harsin, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3784,
      "name": "Hartdegen, Stephen J., O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3785,
      "name": "Hartmann, Edouard, Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3786,
      "name": "Hartwig, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3787,
      "name": "Harvey, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3788,
      "name": "Hasan Al-Banna (1906-1949)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3789,
      "name": "Hasan, Mushirul [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3790,
      "name": "Hasan, Zoya (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3791,
      "name": "Hasanuzzaman S.M. (1932-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3792,
      "name": "Hasnain, Nadeem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3793,
      "name": "Hassall, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3794,
      "name": "Hastie, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3795,
      "name": "Hastings James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3796,
      "name": "Hastings James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3797,
      "name": "Hatch, Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3798,
      "name": "Hatch, Edwin & Redpath, Henry A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3799,
      "name": "Hattaway, Paul (1968-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3800,
      "name": "Hatton, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3801,
      "name": "Hatzfeld Ad.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3802,
      "name": "Hauck, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3803,
      "name": "Hauer, W., Heim, Karl & Adam, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3804,
      "name": "Haught John F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3805,
      "name": "Haughton, Graves C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3806,
      "name": "Haupt, D. Erich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3807,
      "name": "Hauser, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3808,
      "name": "Hausherr Melchior S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3809,
      "name": "Havell, E.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3810,
      "name": "Haweis, H.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3811,
      "name": "Hawkin, David J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3812,
      "name": "Hawkins, John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3813,
      "name": "Hawley, John Stratton [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3814,
      "name": "Hawthorne, Nathaniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3815,
      "name": "Hay, Ian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3816,
      "name": "Hay, Joannes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3817,
      "name": "Hay, Malcolm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3818,
      "name": "Haydock, G.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3819,
      "name": "Hayes, Carlton J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3820,
      "name": "Haynes E.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3821,
      "name": "Haynes, Carlyle B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3822,
      "name": "Haynes, E.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3823,
      "name": "Hazart, Cornelius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3824,
      "name": "Hazen, Charles Downer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3825,
      "name": "Headlam, Arthur C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3826,
      "name": "Healey, Charles J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3827,
      "name": "Heaps Bishop John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3828,
      "name": "Heard, Gerald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3829,
      "name": "Hearnshaw F.J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3830,
      "name": "Heath, Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3831,
      "name": "Heather, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3832,
      "name": "Hebrard, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3833,
      "name": "Hecht John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3834,
      "name": "Heck, Paul L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3835,
      "name": "Hedges Paul M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3836,
      "name": "Hedley John Cuthbert O.S.B. Bp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3837,
      "name": "Hedlund, Roger E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3838,
      "name": "Hedlund, Roger E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3839,
      "name": "Heeg, Aloysius J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3840,
      "name": "Heenan, John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3841,
      "name": "Heenan, John Carmel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3842,
      "name": "Heerinckx, Jacobus, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3843,
      "name": "Hefele Charles Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3844,
      "name": "Heffer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3845,
      "name": "Heffernan, Anne Eileen, Jablonski, Patricia Edward, FSP & Carmel, Neelu, C.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3846,
      "name": "Hefner, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3847,
      "name": "Hefner, Robert W. (1952-   ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3848,
      "name": "Hegel, Georg Wilhelm Friedrich (1770-1831)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3849,
      "name": "Hegel, Georg Wilhelm Friedrich (1770-1831) & Baillie, J.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3850,
      "name": "Hegel, Georg Wilhelm Friedrich (1770-1831) & Sibbee, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3851,
      "name": "Hegel, Georg Wilhelm Friedrich (1770-1831) & Sibree, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3852,
      "name": "Hegel, Georg Wilhelm Friedrich (1770-1831) & William, Wallace [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3853,
      "name": "Hegemonius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3854,
      "name": "Heiden Konrad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3855,
      "name": "Heiden, Konrad & Manhein, Ralph [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3856,
      "name": "Heigl Barthol",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3857,
      "name": "Heil, John Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3858,
      "name": "Heiler, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3859,
      "name": "Heiler, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3860,
      "name": "Heim S. Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3861,
      "name": "Heim, S. Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3862,
      "name": "Heimholtz, H. & Atkinson, E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3863,
      "name": "Heindel, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3864,
      "name": "Heine, Gerhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3865,
      "name": "Heineke, Reinold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3866,
      "name": "Heiner Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3867,
      "name": "Helleu, A., Chan. & Herlihy, Louis, Rev. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3868,
      "name": "Hellinghaus, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3869,
      "name": "Hello, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3870,
      "name": "Helmken, Irz.Theod. & Watkins, W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3871,
      "name": "Helps, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3872,
      "name": "Helyot, O.S.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3873,
      "name": "Hemans, Felicia Dorothea",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3874,
      "name": "Hembuche De Langenstein, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3875,
      "name": "Hemingway, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3876,
      "name": "Hempton David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3877,
      "name": "Henderson, Neville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3878,
      "name": "Hendrichs F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3879,
      "name": "Hendrichs, F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3880,
      "name": "Hengel Martin (1926-2009)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3881,
      "name": "Hengstenberg, Ernst Wilhelm (1802-1869)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3882,
      "name": "Hennequin, Antonius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3883,
      "name": "Hennin, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3884,
      "name": "Henninger, Joseph, S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3885,
      "name": "Hennrich, Kilian J., O.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3886,
      "name": "Henricus a Sancto Ignatio, O. Carm.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3887,
      "name": "Henrion, Baron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3888,
      "name": "Henriques, Basil L.Q.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3889,
      "name": "Henriquez Henricus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3890,
      "name": "Henry Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3891,
      "name": "Henry, John, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3892,
      "name": "Henry, Matthew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3893,
      "name": "Henry, O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3894,
      "name": "Henry, P., S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3895,
      "name": "Henry, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3896,
      "name": "Henry, Sarojini",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3897,
      "name": "Henry, VIII",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3898,
      "name": "Henslow, George, Wace, Henry, Margoliouth, D.S. & Welsh R.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3899,
      "name": "Henson, Herbert Hensley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3900,
      "name": "Henty, G.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3901,
      "name": "Heras Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3902,
      "name": "Herbermann C.G. [ed.] & Pace E.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3903,
      "name": "Herbermann, C.G. [ed.] & Pace, E.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3904,
      "name": "Herbert Lady Mary Elizabeth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3905,
      "name": "Herbert S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3906,
      "name": "Herbert, A.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3907,
      "name": "Herbert, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3908,
      "name": "Herbigny Michel d' S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3909,
      "name": "Herbigny Michel D' S.J. & Deubner Alexandre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3910,
      "name": "Herbigny, Michel d', S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3911,
      "name": "Herckenrath, C.R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3912,
      "name": "Herder",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3913,
      "name": "Heredero J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3914,
      "name": "Heredia, Jose Maria De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3915,
      "name": "Heredia, Rudolf C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3916,
      "name": "Herford, R. Travers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3917,
      "name": "Hergenrother, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3918,
      "name": "Heris Charles V. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3919,
      "name": "Heris, Charles V., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3920,
      "name": "Herkenne Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3921,
      "name": "Herling, Gustav & Marek, Joseph [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3922,
      "name": "Herman Jean-Baptiste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3923,
      "name": "Herman, Aemilius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3924,
      "name": "Herman, Aemilius, S.J. & Wuyts, Antonius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3925,
      "name": "Hermans, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3926,
      "name": "Hermas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3927,
      "name": "Hermes Trismegistus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3928,
      "name": "Hernandez Pablo S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3929,
      "name": "Herriot, Denys G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3930,
      "name": "Hertling, Ludwig von, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3931,
      "name": "Hertz Carl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3932,
      "name": "Hertzier, Joyce O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3933,
      "name": "Hervey, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3934,
      "name": "Herzfeld, Ernst E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3935,
      "name": "Heschel Abraham Joshua",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3936,
      "name": "Heser, Georguus, S.J. & Brucker, Jac., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3937,
      "name": "Hesnard A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3938,
      "name": "Hesse, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3939,
      "name": "Hessey, James Augustus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3940,
      "name": "Heston, Edward Louis, C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3941,
      "name": "Hetherington, H.J.W. & Muirhead, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3942,
      "name": "Hettinger Franz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3943,
      "name": "Hettinger, Franz & Bowden, Henry Sebastian [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3944,
      "name": "Hetzel Basil S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3945,
      "name": "Hetzel Basil S. & Pandav C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3946,
      "name": "Hetzenauer, Michael, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3947,
      "name": "Heurtevent, Raoul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3948,
      "name": "Heussi, Karl & Mulert, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3949,
      "name": "Heuterus, Pontus Delphius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3950,
      "name": "Hevenesi, Gabriel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3951,
      "name": "Hewitt J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3952,
      "name": "Heydon, J.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3953,
      "name": "Heygate, W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3954,
      "name": "Heylen Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3955,
      "name": "Heylen, V.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3956,
      "name": "Heymans A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3957,
      "name": "Heyns, Pol",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3958,
      "name": "Heyrman Jules S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3959,
      "name": "Hichens, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3960,
      "name": "Hick, John (1922-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3961,
      "name": "Hickok Michael Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3962,
      "name": "Hicks, J. Daws",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3963,
      "name": "Hieronymus Sophronius Eusebius Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3964,
      "name": "Hieronymus Sophronius Eusebius Stus. & Augustinus Aurelius Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3965,
      "name": "Higgins, Gregory C. (1960-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3966,
      "name": "Hilarius Pictaviensis Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3967,
      "name": "Hildebrand Dietrich Von (1889-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3968,
      "name": "Hildebrand, Dietrich Von (1889-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3969,
      "name": "Hill Brennan R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3970,
      "name": "Hill Owen A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3971,
      "name": "Hill, Charles E. (1931-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3972,
      "name": "Hill, Hilton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3973,
      "name": "Hill, John J. & Stone, Theodore C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3974,
      "name": "Hiltebeitel, Alf [ed.] & Erndl, Kathleen M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3975,
      "name": "Himbaza, Innocent, Schenker, Adrien, Edart, Jean-Baptiste & Guevin, Benedict M., O.S.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3976,
      "name": "Hime Maurice C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3977,
      "name": "Himmelreich, Laetus, O.F.M. & Smits, Crispinus, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3978,
      "name": "Hind, C. Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3979,
      "name": "Hinden, Rita [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3980,
      "name": "Hindley, Charles [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3981,
      "name": "Hindo, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3982,
      "name": "Hindus Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3983,
      "name": "Hinkle, Charles L. & Stineman, Esther F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3984,
      "name": "Hippolytus Romanus, Episc., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3985,
      "name": "Hippolytus Romanus, Episc., Stus. & Botte, Dom B. [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3986,
      "name": "Hippolytus Romanus, Episc., Stus. & Easton, Burton Scott [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3987,
      "name": "Hitch, C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3988,
      "name": "Hitchcock George S. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3989,
      "name": "Hitchcock, Edward B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3990,
      "name": "Hitchcock, Francis Ryan Montgomery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3991,
      "name": "Hitier, Henri & Hitier, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3992,
      "name": "Hitler, Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3993,
      "name": "Hitzig, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3994,
      "name": "Hoare, F.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3995,
      "name": "Hobbes, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3996,
      "name": "Hobhouse, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3997,
      "name": "Hobson, John A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3998,
      "name": "Hocedez Edgar S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 3999,
      "name": "Hocedez, Edgar, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4000,
      "name": "Hocking Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4001,
      "name": "Hocking, William Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4002,
      "name": "Hodge, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4003,
      "name": "Hodge, J.Z.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4004,
      "name": "Hodgman, Charles D. & Lange, Norbert A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4005,
      "name": "Hodgson, Leonard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4006,
      "name": "Hodgson, Leonard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4007,
      "name": "Hodgson, William B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4008,
      "name": "Hoelz, Max & Voigt, F.A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4009,
      "name": "Hoenen, P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4010,
      "name": "Hoensbroech, Paul von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4011,
      "name": "Hoexter Miriam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4012,
      "name": "Hoffelise Hyacinthe Comtesse d'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4013,
      "name": "Hoffman, P. John C.S. St.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4014,
      "name": "Hoffman, Ross J.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4015,
      "name": "Hoffmann, Jean G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4016,
      "name": "Hoffmann, John, S. J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4017,
      "name": "Hofmann Georg S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4018,
      "name": "Hofmann, Georg, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4019,
      "name": "Hofmann, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4020,
      "name": "Hofmeyr, Jan H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4021,
      "name": "Hogan, Linda F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4022,
      "name": "Hogarth, David George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4023,
      "name": "Hogben, Lancelot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4024,
      "name": "Hogg, A.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4025,
      "name": "Hogg, C.F. & Vine, William Edwy (1873-1949)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4026,
      "name": "Hogg, C.F. & Watson, John B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4027,
      "name": "Holden, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4028,
      "name": "Holder, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4029,
      "name": "Holizner, Josef",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4030,
      "name": "Hollams, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4031,
      "name": "Holland Robert E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4032,
      "name": "Holland, Henry Scott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4033,
      "name": "Holland, Henry Scott & Richmond, W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4034,
      "name": "Holland, Thomas Erskine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4035,
      "name": "Hollis, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4036,
      "name": "Holman, Bob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4037,
      "name": "Holmes & Bos, Lambert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4038,
      "name": "Holmes Christopher R.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4039,
      "name": "Holmes, C.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4040,
      "name": "Holmes, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4041,
      "name": "Holmes, J. Derek & Bickers, Bernard W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4042,
      "name": "Holmes, Michael W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4043,
      "name": "Holmes, Oliver Wendel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4044,
      "name": "Holmes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4045,
      "name": "Holmes, Samuel J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4046,
      "name": "Holt, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4047,
      "name": "Holtzmann, H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4048,
      "name": "Holtzmann, Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4049,
      "name": "Holyoake, George Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4050,
      "name": "Holzapfel Heribert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4051,
      "name": "Holzer, Bernard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4052,
      "name": "Holzinger, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4053,
      "name": "Holzmeister, Urbanus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4054,
      "name": "Homerus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4055,
      "name": "Homerus & Pope, Alexander [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4056,
      "name": "Homo Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4057,
      "name": "Homo, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4058,
      "name": "Honnay V. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4059,
      "name": "Honore Leon S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4060,
      "name": "Honore, Leon, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4061,
      "name": "Hontheim Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4062,
      "name": "Hood, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4063,
      "name": "Hooft W.A. Visser't",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4064,
      "name": "Hooke, S.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4065,
      "name": "Hooker, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4066,
      "name": "Hooper, A.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4067,
      "name": "Hoornaert Georges S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4068,
      "name": "Hoornaert, Georges, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4069,
      "name": "Hoornaert, Rodolphe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4070,
      "name": "Hoornaert, Rodolphe & Thorold, Algar [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4071,
      "name": "Hoover, Jon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4072,
      "name": "Hope Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4073,
      "name": "Hope, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4074,
      "name": "Hope, Graham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4075,
      "name": "Hope, Laurence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4076,
      "name": "Hope, Wingfield",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4077,
      "name": "Hopfl, Hildebrandus, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4078,
      "name": "Hopfner Theodorus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4079,
      "name": "Hoping, Helmut [hersg.] & Munk, Hans J. [hersg.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4080,
      "name": "Hopkins, Gerard Manley, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4081,
      "name": "Hopkins, Steven Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4082,
      "name": "Hopkins, Tighe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4083,
      "name": "Horatius Quintus Flaccus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4084,
      "name": "Horatius, Quintus Flaccus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4085,
      "name": "Hore, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4086,
      "name": "Horgan, John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4087,
      "name": "Hornback Florence M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4088,
      "name": "Horne, Thomas Hartwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4089,
      "name": "Hornibrook Ettie A. (Ettie A. Rout)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4090,
      "name": "Hornung, E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4091,
      "name": "Horrabin, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4092,
      "name": "Horst, Eberhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4093,
      "name": "Hort, Fenton John Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4094,
      "name": "Horvath, Alexander M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4095,
      "name": "Horwill, H.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4096,
      "name": "Hoskier, H.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4097,
      "name": "Hoskyns, Edwyn Clement & Davey, Francis Noel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4098,
      "name": "Hoskyns, Edwyn Clement & Davey, Francis Noel [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4099,
      "name": "Houbaut, Henri-Jean, Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4100,
      "name": "Houck, Frederick A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4101,
      "name": "Houliston, Victor (1954-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4102,
      "name": "Hourat Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4103,
      "name": "Hourticq, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4104,
      "name": "Houselander, Caryll",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4105,
      "name": "Housman, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4106,
      "name": "Houssay Frederic",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4107,
      "name": "Houssaye, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4108,
      "name": "Housse, Emile, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4109,
      "name": "Houtin, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4110,
      "name": "Houtsma, M. Th [ed.], Arnold, Thomas W. [ed.], Basset, R. [ed.] & Hartmann, Richard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4111,
      "name": "Hovelacque, Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4112,
      "name": "How, F.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4113,
      "name": "How, W. Walsham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4114,
      "name": "Howard, Janice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4115,
      "name": "Howard, W.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4116,
      "name": "Howard, Wilbert Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4117,
      "name": "Howard-Brook, Wes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4118,
      "name": "Howe G.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4119,
      "name": "Howe, G.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4120,
      "name": "Howells, W.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4121,
      "name": "Howey, M. Oldfield",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4122,
      "name": "Howitt A.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4123,
      "name": "Howorth, Henry H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4124,
      "name": "Hrdayes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4125,
      "name": "Hu Sheng",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4126,
      "name": "Hubbard, Bernard R., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4127,
      "name": "Huber, Raphael M., O.F.M.Conv.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4128,
      "name": "Hubert, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4129,
      "name": "Huby Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4130,
      "name": "Huby, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4131,
      "name": "Huby, Vincent, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4132,
      "name": "Huckin, H.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4133,
      "name": "Hudson D. Dennis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4134,
      "name": "Hudson, Cyril E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4135,
      "name": "Hudson, Cyril E. & Reckitt, Maurice B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4136,
      "name": "Hue Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4137,
      "name": "Huf Oscar S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4138,
      "name": "Huf, R., O.C.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4139,
      "name": "Hugel, Friedrich Baron Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4140,
      "name": "Hughes Henry Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4141,
      "name": "Hughes J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4142,
      "name": "Hughes Philip Edgcumbe (1915-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4143,
      "name": "Hughes Thomas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4144,
      "name": "Hughes William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4145,
      "name": "Hughes, Ernest Richard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4146,
      "name": "Hughes, John & Breckinridge, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4147,
      "name": "Hughes, Kathleen, R.S.C.J. [ed.] & Favazza, Joseph A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4148,
      "name": "Hughes, Philip Edgcumbe (1915-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4149,
      "name": "Hughes, Thomas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4150,
      "name": "Hugo a Sto Victore, Can. Reg., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4151,
      "name": "Hugo a Sto Victore, Can. Reg., Stus. & Smith, Dom Aloysius [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4152,
      "name": "Hugo, John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4153,
      "name": "Hugo, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4154,
      "name": "Hugo, Victor & Wilbour, Charles E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4155,
      "name": "Hugon Edouard O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4156,
      "name": "Hugon, Edouard, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4157,
      "name": "Hugon, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4158,
      "name": "Huleu J.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4159,
      "name": "Hull Ernest R. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4160,
      "name": "Hull, Ernest R., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4161,
      "name": "Hull, Robert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4162,
      "name": "Hulsman Anton S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4163,
      "name": "Hulst Maurice D' Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4164,
      "name": "Humbert, Augustine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4165,
      "name": "Humbert, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4166,
      "name": "Humbert, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4167,
      "name": "Humblet, Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4168,
      "name": "Hume, David (1711-76)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4169,
      "name": "Hume, Robert Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4170,
      "name": "Hummelauer Franciscus von S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4171,
      "name": "Hummelauer Franz von S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4172,
      "name": "Hummelauer, Franz von, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4173,
      "name": "Hunermann, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4174,
      "name": "Hunt & Wuillemin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4175,
      "name": "Hunt, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4176,
      "name": "Hunt, Pearson, Williams, Charles M. & Donaldson, Gordon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4177,
      "name": "Hunt, Steven A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4178,
      "name": "Hunter, A.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4179,
      "name": "Hunter, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4180,
      "name": "Huntingdon, Lord",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4181,
      "name": "Huonder, Anton, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4182,
      "name": "Huppertz, Andr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4183,
      "name": "Hurlimann, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4184,
      "name": "Hurst, Antony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4185,
      "name": "Hurter Hugo S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4186,
      "name": "Hurter Hugo S.J. & Kokenge B. John S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4187,
      "name": "Hurter, Hugo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4188,
      "name": "Hurtevent Sidoine Assompt.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4189,
      "name": "Hurth, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4190,
      "name": "Husain Monirul [ed.] & Ghos Lipi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4191,
      "name": "Husain, Muhammad Ashraf, Maulvi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4192,
      "name": "Husain, Muhammad Ashraf, Maulvi & Srivastava, H.L. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4193,
      "name": "Husserl, Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4194,
      "name": "Husslein Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4195,
      "name": "Husslein, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4196,
      "name": "Hutchings, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4197,
      "name": "Hutchins, Robert Maynard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4198,
      "name": "Hutchinson Walter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4199,
      "name": "Hutton, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4200,
      "name": "Hutton, Richard Holt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4201,
      "name": "Huxley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4202,
      "name": "Huxley Aldous",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4203,
      "name": "Huxley Julian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4204,
      "name": "Huxley, Aldous",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4205,
      "name": "Huxley, Thomas Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4206,
      "name": "Hwang, Soonil (1968-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4207,
      "name": "Hyacinthe, Father",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4208,
      "name": "Hyatte, Reginald [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4209,
      "name": "Hyde, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4210,
      "name": "Ibaecker Theodor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4211,
      "name": "Ibanez Vicente Blasco & Jordan Charlotte Brewster [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4212,
      "name": "Ibebrard O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4213,
      "name": "Ibn Baz, Shaykh & Uthaymeen, Muhammad Ibn Saalih, Shaykh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4214,
      "name": "Ibn Sina Abu Ali Al-Husayn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4215,
      "name": "Ibn Warraq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4216,
      "name": "Ibn Warraq [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4217,
      "name": "Ibsen, Henrik",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4218,
      "name": "Iggleden, A.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4219,
      "name": "Ignacimuthu S. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4220,
      "name": "Ignatius Antiochenus Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4221,
      "name": "Ignatius, Antiochenus, Episc., Stus. & Camelot, Pierre-Thomas, O.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4222,
      "name": "Ignatius, Antiochenus, Episc., Stus. & Srawley, J.H. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4223,
      "name": "Ilarino Da Milano, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4224,
      "name": "Illingworth J.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4225,
      "name": "Imbert, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4226,
      "name": "Imboden, Roberta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4227,
      "name": "Imchen, S. Temjen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4228,
      "name": "Ingalalli, Rachappa I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4229,
      "name": "Inge, William Ralph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4230,
      "name": "Ingersoll, R.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4231,
      "name": "Ingleby, J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4232,
      "name": "Ingle-Gillis, William C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4233,
      "name": "Ingram, John Kelis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4234,
      "name": "Ingram, Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4235,
      "name": "Ingram, T.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4236,
      "name": "Insha Allah Khan & Haqq 'abd Al [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4237,
      "name": "Iqbal, Muhammad, Sheikh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4238,
      "name": "Irenaeus Lugdunensis Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4239,
      "name": "Irenaeus, Lugdunensis, Episc., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4240,
      "name": "Irsay, Stephen d'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4241,
      "name": "Irvine Wm. C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4242,
      "name": "Isaac, T.M. Thomas & Franke, Richard W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4243,
      "name": "Isambert, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4244,
      "name": "Iskenderoglu, Muammer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4245,
      "name": "Islam, Najarula",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4246,
      "name": "Islam, Riazul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4247,
      "name": "Istanbult Yasin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4248,
      "name": "Iswolsky, Helen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4249,
      "name": "Iswolsky, Helen & Peeler, E.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4250,
      "name": "Iung, Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4251,
      "name": "Iuvenalis, Ivnus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4252,
      "name": "Ivens Michael S.J. & Munitiz Joseph A. S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4253,
      "name": "Ivens, Michel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4254,
      "name": "Iyer, Saroj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4255,
      "name": "Izard, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4256,
      "name": "Jabouley Michel-Ange",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4257,
      "name": "Jabrun Paul De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4258,
      "name": "Jackson F.J. Foakes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4259,
      "name": "Jackson, F.J. Foakes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4260,
      "name": "Jackson, F.J. Foakes & Lake, Kirsopp",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4261,
      "name": "Jackson, Roy (1962-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4262,
      "name": "Jacob Od. & Ned Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4263,
      "name": "Jacob Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4264,
      "name": "Jacob, Mani",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4265,
      "name": "Jacob, Mani [Comp.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4266,
      "name": "Jacob, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4267,
      "name": "Jacob, Reny [comp.] & John, Carolyne [comp.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4268,
      "name": "Jacobs, Od.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4269,
      "name": "Jacobs, W.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4270,
      "name": "Jacobsen, Knut A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4271,
      "name": "Jacolliot, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4272,
      "name": "Jacquemet, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4273,
      "name": "Jacques Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4274,
      "name": "Jacques de Jesus, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4275,
      "name": "Jacques Genevieve",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4276,
      "name": "Jacques, Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4277,
      "name": "Jacys, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4278,
      "name": "Jadot, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4279,
      "name": "Jaffrelot, Christophe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4280,
      "name": "Jagdisnarayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4281,
      "name": "Jagen, Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4282,
      "name": "Jaggi O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4283,
      "name": "Jahan Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4284,
      "name": "Jaideva, Paramanshi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4285,
      "name": "Jain Jasbir [ed.] & Rai Sudha [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4286,
      "name": "Jain, Surescandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4287,
      "name": "Jalibi, Jameel [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4288,
      "name": "Jalics, Franz, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4289,
      "name": "Jalland, Trevor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4290,
      "name": "Jalland, Trevor Gervase",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4291,
      "name": "Jamal, Nadia Eboo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4292,
      "name": "Jamar C.-H.-T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4293,
      "name": "James Stanley B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4294,
      "name": "James, E.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4295,
      "name": "James, G.P.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4296,
      "name": "James, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4297,
      "name": "James, J.L. Beaumont",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4298,
      "name": "James, Montague Rhodes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4299,
      "name": "James, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4300,
      "name": "James, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4301,
      "name": "Jamil, Mohammad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4302,
      "name": "Jammes Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4303,
      "name": "Janin, Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4304,
      "name": "Jankelevitch Vladmir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4305,
      "name": "Janninck Konraad S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4306,
      "name": "Janot, J.-Em., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4307,
      "name": "Janowiak Paul S.J. (1951-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4308,
      "name": "Jansenius Cornelius Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4309,
      "name": "Jansenius, Cornelius, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4310,
      "name": "Janssen Miek",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4311,
      "name": "Janssen, Emiel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4312,
      "name": "Janssens Alois C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4313,
      "name": "Janssens Hermann O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4314,
      "name": "Janssens J.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4315,
      "name": "Janssens, Alois, C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4316,
      "name": "Janssens, J.B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4317,
      "name": "Janssens, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4318,
      "name": "Janvier O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4319,
      "name": "Jarde, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4320,
      "name": "Jardine, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4321,
      "name": "Jarlot Georges S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4322,
      "name": "Jarrett Bede O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4323,
      "name": "Jarrett, Bede, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4324,
      "name": "Jarvis, Frank Washington (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4325,
      "name": "Jastrow Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4326,
      "name": "Jastrow, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4327,
      "name": "Jastrow, Morris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4328,
      "name": "Jaugey Jean-Baptiste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4329,
      "name": "Jayakumar, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4330,
      "name": "Jean Charles F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4331,
      "name": "Jean, Auguste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4332,
      "name": "Jean, Charles F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4333,
      "name": "Jeanniere Rene S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4334,
      "name": "Jeans James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4335,
      "name": "Jeans, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4336,
      "name": "Jebb, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4337,
      "name": "Jefferies, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4338,
      "name": "Jehan L.-F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4339,
      "name": "Jenkins Jean & Olsen Poul Rovsing",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4340,
      "name": "Jenkins, Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4341,
      "name": "Jennesseaux Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4342,
      "name": "Jensen Steven J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4343,
      "name": "Jeong, Paul Yonggap",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4344,
      "name": "Jepson, Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4345,
      "name": "Jequier Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4346,
      "name": "Jeremias, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4347,
      "name": "Jerome, K. Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4348,
      "name": "Jerphanion, Guillaume De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4349,
      "name": "Jerrold, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4350,
      "name": "Jerry, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4351,
      "name": "Jesudasan, Ignatius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4352,
      "name": "Jesuraja A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4353,
      "name": "Jeudwine, J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4354,
      "name": "Jevons, F.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4355,
      "name": "Jevons, W. Stanley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4356,
      "name": "Jeyaseelan, Thomas B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4357,
      "name": "Jha D.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4358,
      "name": "Jiva Gosvami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4359,
      "name": "Jnavali, Surya Vikram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4360,
      "name": "Joad, C.E.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4361,
      "name": "Joanne, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4362,
      "name": "Joannes Chrysostomus Constant. Arch. Stus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4363,
      "name": "Joannes Chrysostomus, Archiep., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4364,
      "name": "Joannes Chrysostomus, Archiep., Stus. & D'Alton, J.F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4365,
      "name": "Joannes G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4366,
      "name": "Joannes Moschus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4367,
      "name": "Joannes Paulus II, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4368,
      "name": "Joannes XXI, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4369,
      "name": "Joannes, Chrysostomus, Constant. Arch., Stus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4370,
      "name": "Joannes, S.C.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4371,
      "name": "Joannes, Stus., Ap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4372,
      "name": "Jog, N.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4373,
      "name": "Johanna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4374,
      "name": "Johannet Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4375,
      "name": "Johanns Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4376,
      "name": "Johanns, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4377,
      "name": "John Binu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4378,
      "name": "John Of Taize, Brother",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4379,
      "name": "John, Bishop Of Bristol",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4380,
      "name": "John, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4381,
      "name": "John, V.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4382,
      "name": "John-Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4383,
      "name": "Johns Rowland [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4384,
      "name": "Johns, C.H.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4385,
      "name": "Johns, David Holden Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4386,
      "name": "Johnson E. Thelma",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4387,
      "name": "Johnson Elizabeth A. (1941-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4388,
      "name": "Johnson Spencer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4389,
      "name": "Johnson Vernon D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4390,
      "name": "Johnson, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4391,
      "name": "Johnson, Douglas H. (1949-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4392,
      "name": "Johnson, Humphrey J.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4393,
      "name": "Johnson, Lionel & Shafer, Robert [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4394,
      "name": "Johnson, Marshall D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4395,
      "name": "Johnson, Samuel & Arnold, Matthew [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4396,
      "name": "Johnson, Sylvester",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4397,
      "name": "Johnson-DeBaufre, Melanie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4398,
      "name": "Johnston, Harry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4399,
      "name": "Johnston, William M. (1925-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4400,
      "name": "Johnstone, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4401,
      "name": "Johnstone, Laurence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4402,
      "name": "Joliat Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4403,
      "name": "Jolivet Regis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4404,
      "name": "Joly Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4405,
      "name": "Joly Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4406,
      "name": "Jonathan, F.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4407,
      "name": "Jones Edward & Sutton Ii Derek",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4408,
      "name": "Jones Hugh Percy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4409,
      "name": "Jones Owen & Woodward Marcus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4410,
      "name": "Jones, A.H.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4411,
      "name": "Jones, Abel J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4412,
      "name": "Jones, C.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4413,
      "name": "Jones, E.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4414,
      "name": "Jones, Edgar R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4415,
      "name": "Jones, Edward [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4416,
      "name": "Jones, G.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4417,
      "name": "Jones, H.L. & Sherratt C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4418,
      "name": "Jones, John P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4419,
      "name": "Jones, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4420,
      "name": "Jones, Spencer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4421,
      "name": "Jonson, Ben. & Wheatley, H.B. [ed. & Introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4422,
      "name": "Joppin, Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4423,
      "name": "Jordan Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4424,
      "name": "Jordan, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4425,
      "name": "Jordan, G.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4426,
      "name": "Jordan, Mark D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4427,
      "name": "Joret F.D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4428,
      "name": "Joret, F.D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4429,
      "name": "Jorgensen Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4430,
      "name": "Jorgensen Johannes & Lund Tngelborg [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4431,
      "name": "Jorgensen, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4432,
      "name": "Jorgensen, Johannes & Lund, Ingeborg [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4433,
      "name": "Jorio Dominique Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4434,
      "name": "Jose Susana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4435,
      "name": "Joseph Winfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4436,
      "name": "Joseph, George Gheverghese",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4437,
      "name": "Josephus Flavius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4438,
      "name": "Josephus, Flavius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4439,
      "name": "Joset, C.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4440,
      "name": "Josi R.P. & Narwani G.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4441,
      "name": "Josi S.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4442,
      "name": "Josi, Giriscandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4443,
      "name": "Josi, P.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4444,
      "name": "Josi, Sharmila & Menon, Meena",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4445,
      "name": "Josson Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4446,
      "name": "Jouffroy, De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4447,
      "name": "Jouguet, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4448,
      "name": "Jouon Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4449,
      "name": "Jouon, Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4450,
      "name": "Jouon, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4451,
      "name": "Jourdain Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4452,
      "name": "Jousse Marcel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4453,
      "name": "Jouvenroux",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4454,
      "name": "Jovino, Francesco Girolamo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4455,
      "name": "Jovino, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4456,
      "name": "Joy C.I. David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4457,
      "name": "Joyau E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4458,
      "name": "Jozon, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4459,
      "name": "Juan De La Cruz O.C.D. Stus. (1542-1591)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4460,
      "name": "Juan De La Cruz, O.C.D., Stus. (1542-1591)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4461,
      "name": "Juan De La Cruz, O.C.D., Stus. (1542-1591) & Carrigan, Henry L., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4462,
      "name": "Juan De La Cruz, O.C.D., Stus. (1542-1591) & Lewis, David [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4463,
      "name": "Jubaru P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4464,
      "name": "Judde Claude S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4465,
      "name": "Juenin Gaspare Congr. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4466,
      "name": "Juergens Sylvester P. S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4467,
      "name": "Juergensmeyer Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4468,
      "name": "Jugie Martin A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4469,
      "name": "Jugie, Martin A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4470,
      "name": "Jukes, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4471,
      "name": "Jukko, Risto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4472,
      "name": "Julian, Baiju [ed.] & Mynatty, Hormis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4473,
      "name": "Julianus, Pomerius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4474,
      "name": "Julicher, Adolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4475,
      "name": "Julie, Margaret [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4476,
      "name": "Julien, E.L., Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4477,
      "name": "Jullien Charlotte",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4478,
      "name": "Junglas Joh. Pet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4479,
      "name": "Jungling, Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4480,
      "name": "Jungmann Bernardus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4481,
      "name": "Jungmann Josef Andreas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4482,
      "name": "Jungmann, Josef Andreas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4483,
      "name": "Junker Hubert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4484,
      "name": "Junod A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4485,
      "name": "Jurgensmeier, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4486,
      "name": "Jurji, Edward Jabra [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4487,
      "name": "Jussila Paivi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4488,
      "name": "Justinus Philosophus Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4489,
      "name": "Justinus, Philosophus, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4490,
      "name": "Justinus, Philosophus, Stus., Dods, Marcus [transl.] & Reith, G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4491,
      "name": "Juvencius Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4492,
      "name": "Kabir; Hess, Linda [transl.] & Singh, Shukdeo [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4493,
      "name": "Kaegi, Adolf & Kleist, James A., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4494,
      "name": "Kaiser Walter C. Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4495,
      "name": "Kalathuveettil Thomas SDB [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4496,
      "name": "Kalinin M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4497,
      "name": "Kalliath Antony CMI [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4498,
      "name": "Kalt Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4499,
      "name": "Kalyanasimha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4500,
      "name": "Kalyanasimha, Sekhavat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4501,
      "name": "Kambodji, Alphinus [ed.], Senturias, Erlinda N. [ed.] & Longchar, A. Wati [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4502,
      "name": "Kanakaraj A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4503,
      "name": "Kane Robert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4504,
      "name": "Kane William F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4505,
      "name": "Kanichikattil, Francis, CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4506,
      "name": "Kaniyaraseril, Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4507,
      "name": "Kannengiesser, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4508,
      "name": "Kannugo, Gopinath [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4509,
      "name": "Kant, Immanuel (1724-1804)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4510,
      "name": "Kanters, Ch. G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4511,
      "name": "Kantowicz Edward R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4512,
      "name": "Kanungo Pralay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4513,
      "name": "Kapadiya Prem [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4514,
      "name": "Kapali, Stanislaus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4515,
      "name": "Kaplinsky, Raphael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4516,
      "name": "Kappen, Sebastian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4517,
      "name": "Kar, Gyana Chandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4518,
      "name": "Karaka, D.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4519,
      "name": "Karambai, Sebastian S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4520,
      "name": "Karg, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4521,
      "name": "Karmay, Samten Cyaltsen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4522,
      "name": "Karnik Vasant Bhagvant & Roy M.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4523,
      "name": "Karotemprel Sebastian SDB",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4524,
      "name": "Karotemprel, Gregory, CMI [ed.], Marangattu, Jacob, C.M.I. [ed.] & Barco, Mark, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4525,
      "name": "Karr, Alphonse & Wood, J.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4526,
      "name": "Karrer Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4527,
      "name": "Karrer, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4528,
      "name": "Karst, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4529,
      "name": "Kashyap, Subhash C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4530,
      "name": "Kasper, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4531,
      "name": "Kassmann Margot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4532,
      "name": "Katju, Manjari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4533,
      "name": "Katz, Marion Holmes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4534,
      "name": "Kaufmann, Carl Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4535,
      "name": "Kausherr S.J. & Vogt A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4536,
      "name": "Kaushik Asha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4537,
      "name": "Kautzsch, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4538,
      "name": "Kavungal Devis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4539,
      "name": "Kay, William K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4540,
      "name": "Kaye-Smith, Sheila",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4541,
      "name": "Kayser, Anna",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4542,
      "name": "Keable, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4543,
      "name": "Kealy, Thomas M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4544,
      "name": "Keane A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4545,
      "name": "Keane Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4546,
      "name": "Kearney, G.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4547,
      "name": "Kearney, John, C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4548,
      "name": "Keating Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4549,
      "name": "Keats, John & Forman, H. Buxton [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4550,
      "name": "Keble, J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4551,
      "name": "Keble, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4552,
      "name": "Kee Howard Clark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4553,
      "name": "Keeling, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4554,
      "name": "Keenan James F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4555,
      "name": "Keenan, Edward P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4556,
      "name": "Keene, Michael James, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4557,
      "name": "Keerankeri, George, S.J. (1944-2011)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4558,
      "name": "Keil, Carl Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4559,
      "name": "Keil, Carl Friedrich & Easton, M.G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4560,
      "name": "Keil, Carl Friedrich & Martin, James [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4561,
      "name": "Keil, Carl Friedrich, Delitzsch, F. & Martin, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4562,
      "name": "Keim, Theodor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4563,
      "name": "Keith, Arthur Berriedale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4564,
      "name": "Keith, Khodadad E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4565,
      "name": "Kelkar, Meena [ed.] & Gangavane, Deepti [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4566,
      "name": "Keller, Helen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4567,
      "name": "Keller, James A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4568,
      "name": "Kelley Francis C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4569,
      "name": "Kelley, Francis C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4570,
      "name": "Kellner, Menachem Marc [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4571,
      "name": "Kellogg, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4572,
      "name": "Kellogg, S.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4573,
      "name": "Kellor Frances E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4574,
      "name": "Kelly George A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4575,
      "name": "Kelly, Bernard J., C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4576,
      "name": "Kelly, Gerald, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4577,
      "name": "Kelly, Henry Ansgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4578,
      "name": "Kelly, T.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4579,
      "name": "Kelly, Vincent J., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4580,
      "name": "Kelly, William L., S.J. & Tallon, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4581,
      "name": "Keltie J. Scott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4582,
      "name": "Kempe, Margery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4583,
      "name": "Kempf Joseph G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4584,
      "name": "Kempf, Joseph G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4585,
      "name": "Kempson, F. Claude",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4586,
      "name": "Kendal, Ralph S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4587,
      "name": "Kengott, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4588,
      "name": "Kennedy Hugh [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4589,
      "name": "Kennedy, Eugene C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4590,
      "name": "Kennedy, G.A. Studdert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4591,
      "name": "Kennedy, James M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4592,
      "name": "Kennedy, Robert J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4593,
      "name": "Kenneson, Philip D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4594,
      "name": "Kenneth Robert H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4595,
      "name": "Kenny, Virginia Arville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4596,
      "name": "Kenrick Francis Patrick Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4597,
      "name": "Kenrick, Francis Patrick, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4598,
      "name": "Kent, Charles Foster",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4599,
      "name": "Kent, Sherman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4600,
      "name": "Kenyon Frederic G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4601,
      "name": "Kenyon, Frederic G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4602,
      "name": "Kepel, Gilles & Roberts, Anthony F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4603,
      "name": "Keppler, Paul William von.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4604,
      "name": "Keppler, Paul William Von. & MacDonald, Hamilton [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4605,
      "name": "Kerber, K.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4606,
      "name": "Kerby, William J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4607,
      "name": "Kereszty, Roch A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4608,
      "name": "Kern, F. & Chrimes, S.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4609,
      "name": "Kernahan, Coulson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4610,
      "name": "Kerr, Cecil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4611,
      "name": "Kerr, Cecil & Wood, J.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4612,
      "name": "Kerr, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4613,
      "name": "Kerry, Margaret Charles, Tebo, Mary Elizabeth & Carmel, Neelu, C.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4614,
      "name": "Kersten F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4615,
      "name": "Kersten Holger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4616,
      "name": "Kersten Holger & Agarwal Santosh [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4617,
      "name": "Kervyn, Louis, C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4618,
      "name": "Kesich Veselin (1921-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4619,
      "name": "Ketter, Peter & Koehler, Rugo C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4620,
      "name": "Kettlewell, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4621,
      "name": "Kettridge, J.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4622,
      "name": "Keun Odette",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4623,
      "name": "Key, Ellen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4624,
      "name": "Keyes, Frances Parkinson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4625,
      "name": "Keynes John Maynard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4626,
      "name": "Keynes John Neville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4627,
      "name": "Khair, Gajanan Shripat (1898-1986)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4628,
      "name": "Khan Abdul Rashid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4629,
      "name": "Khan Nazeer H. & Khan Zafar Ahmad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4630,
      "name": "Khan, Yasmin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4631,
      "name": "Khanam, Azra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4632,
      "name": "Khanna, Amar Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4633,
      "name": "Kharkrang, Roland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4634,
      "name": "Khatab, Sayed & Bouma, Gary D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4635,
      "name": "Khatami Mohammad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4636,
      "name": "Khera Shiv",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4637,
      "name": "Khoren Narbey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4638,
      "name": "Khuri, Fuad I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4639,
      "name": "Kidd Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4640,
      "name": "Kidd, B.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4641,
      "name": "Kiddle, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4642,
      "name": "Kierkegaard, Sores (1813-1855)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4643,
      "name": "Kierkels, Leo P., Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4644,
      "name": "Kilker, Adrian Jerome",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4645,
      "name": "Killen Patricia O'connell & De Beer John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4646,
      "name": "Kilner, John F. [ed.], Cunningham, Paige C. [ed.] & Hager, W. David [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4647,
      "name": "Kilwardby Robertus O.P. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4648,
      "name": "Kinane T.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4649,
      "name": "Kinast Robert L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4650,
      "name": "Kindt, Gerardus, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4651,
      "name": "King J. Leycester S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4652,
      "name": "King, Archdale A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4653,
      "name": "King, George & Panthing, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4654,
      "name": "King, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4655,
      "name": "King, Ursula [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4656,
      "name": "Kinglake, A.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4657,
      "name": "Kingsford, Anna & Maitland, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4658,
      "name": "Kingsley Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4659,
      "name": "Kingsley, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4660,
      "name": "Kingsley, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4661,
      "name": "Kinkead Thomas L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4662,
      "name": "Kipling, Rudyard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4663,
      "name": "Kirby, William & Jones, Rymer [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4664,
      "name": "Kirch Conradus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4665,
      "name": "Kirch, Felix M., O.M. Cap. & Brendan M., Sr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4666,
      "name": "Kircher, Clara J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4667,
      "name": "Kirk, Kenneth E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4668,
      "name": "Kirkpatrick, A.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4669,
      "name": "Kirkpatrick, Shane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4670,
      "name": "Kirsch, Felix M., O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4671,
      "name": "Kirsch, J.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4672,
      "name": "Kishpaugh, Sister Mary Jerome, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4673,
      "name": "Kishwar, Madhu Purnima",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4674,
      "name": "Kisku, Daniel Barka (1936-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4675,
      "name": "Kisku, Kalu [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4676,
      "name": "Kissane, Edward J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4677,
      "name": "Kithan, Zubeno [Comp.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4678,
      "name": "Kittel Gerhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4679,
      "name": "Kittel, Gerhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4680,
      "name": "Kittel, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4681,
      "name": "Kitzinger, Ernst & Senior, Elizabeth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4682,
      "name": "Kizhakeveetil Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4683,
      "name": "Kizhakeveetil, Jacob",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4684,
      "name": "Klein Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4685,
      "name": "Klein Gregory L. & Wolfe Robert A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4686,
      "name": "Klein, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4687,
      "name": "Klein, Felix & Sullivan, Daniel J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4688,
      "name": "Kleiser, Grenville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4689,
      "name": "Kleissler Thomas A; Le Bert Margo A. & Mcguinness Mary C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4690,
      "name": "Kleutgen Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4691,
      "name": "Klimke Fridericus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4692,
      "name": "Kline, M. Franklin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4693,
      "name": "Kloss C. Boden",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4694,
      "name": "Klostermaier, Klaus K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4695,
      "name": "Klug I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4696,
      "name": "Knabenbauer, Josephus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4697,
      "name": "Knecht Frederick Justus Bp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4698,
      "name": "Knecht, F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4699,
      "name": "Knight, Frances",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4700,
      "name": "Knoop, Douglas & Jones, G.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4701,
      "name": "Knoweing, R.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4702,
      "name": "Knowles, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4703,
      "name": "Knox Ronald A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4704,
      "name": "Knox, Ronald A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4705,
      "name": "Knox, Ronald A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4706,
      "name": "Knox, T.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4707,
      "name": "Knox, Thomas W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4708,
      "name": "Knox, Wilfred L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4709,
      "name": "Koch Alois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4710,
      "name": "Koch, Carl, FSC",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4711,
      "name": "Koch, H.J.M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4712,
      "name": "Koch, Ludwig, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4713,
      "name": "Kochuthara, Shaji George, CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4714,
      "name": "Koebel W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4715,
      "name": "Koenen, M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4716,
      "name": "Koenig, Harry C. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4717,
      "name": "Koenig, John H. (1938-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4718,
      "name": "Koerber, Lenka von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4719,
      "name": "Koester, Craig R. (1953-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4720,
      "name": "Koestler, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4721,
      "name": "Koffler P. Hubert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4722,
      "name": "Kohli, Sitaram & Tandan, Ramcandra [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4723,
      "name": "Kohnen P. [ed.] & Schumacher G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4724,
      "name": "Koilparampil, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4725,
      "name": "Kolb George S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4726,
      "name": "Kolbe F.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4727,
      "name": "Kologrivof Ivan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4728,
      "name": "Kolvenbach, Peter-Hans, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4729,
      "name": "Komal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4730,
      "name": "Konig, Friedrich Eduard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4731,
      "name": "Konings Matthaeus, Ord. S. Crucis.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4732,
      "name": "Kopp Clemens",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4733,
      "name": "Kormes, Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4734,
      "name": "Kortleitner Franciscus Xav. O.Praem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4735,
      "name": "Kortleitner Franciscus Xav. O.Praem.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4736,
      "name": "Kosambi, Damodar Dharmanand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4737,
      "name": "Kosters L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4738,
      "name": "Kothari, Rajni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4739,
      "name": "Kothen Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4740,
      "name": "Kottoor, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4741,
      "name": "Koven Bowen Louise De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4742,
      "name": "Kowalska, Saint Maria Faustina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4743,
      "name": "Koyre, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4744,
      "name": "Kozhamthadam Job S.J. (1945-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4745,
      "name": "Kozman, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4746,
      "name": "Kraemer, Hendrik",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4747,
      "name": "Kraepelin, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4748,
      "name": "Kraetzschmar, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4749,
      "name": "Kramer Herbert G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4750,
      "name": "Kramp Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4751,
      "name": "Kraus Francois-Xavier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4752,
      "name": "Kraus, Francois-Xavier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4753,
      "name": "Kraus, Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4754,
      "name": "Kravchenko, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4755,
      "name": "Kreciszewski L.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4756,
      "name": "Kreglinger, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4757,
      "name": "Kreider Alan (1941-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4758,
      "name": "Kreitzer Larry Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4759,
      "name": "Krempel, Bernhardin, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4760,
      "name": "Krier Johann Bernhard Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4761,
      "name": "Kripalani, Acharya J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4762,
      "name": "Kripalani, J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4763,
      "name": "Kroeger James H. MM (1945-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4764,
      "name": "Kroeger James H. MM (1945-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4765,
      "name": "Kronenburg J.A.F. C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4766,
      "name": "Kropatkin P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4767,
      "name": "Krose, H.A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4768,
      "name": "Kroust Joannes Michael S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4769,
      "name": "Krsna Daya (1924-2007)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4770,
      "name": "Krsna Daya (1924-2007); Lath Mukund [ed.] & Krsna Francine E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4771,
      "name": "Krsna Nanditha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4772,
      "name": "Krsna, K.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4773,
      "name": "Krsna, S. Sri [ed.] & Samudrala, Anil Kumar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4774,
      "name": "Krsnadas Ray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4775,
      "name": "Krsnadas, Kaviraj Gosvami & Gosvami, Madanagopala [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4776,
      "name": "Krsnamurti, G.R. & Singh, Awadhesh Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4777,
      "name": "Krsnamurti, J. (1895-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4778,
      "name": "Krueger, Anne O. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4779,
      "name": "Kruitwagen, B., O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4780,
      "name": "Krull, Vigilius, H., C.P.P.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4781,
      "name": "Krumbacher, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4782,
      "name": "Ku Hung-Ming",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4783,
      "name": "Kuan, Tse-Fu (1965-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4784,
      "name": "Kubicek, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4785,
      "name": "Kuehn, Regina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4786,
      "name": "Kuehnel Reynold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4787,
      "name": "Kuenen, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4788,
      "name": "Kugle, Scott Alan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4789,
      "name": "Kuhnett-Leddihn Erik Von & Collins I.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4790,
      "name": "Kujur, Joseph Marianus, S.J. [ed.] & Minz, Sonajharia [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4791,
      "name": "Kujur, Niclas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4792,
      "name": "Kulakkatt Augustine J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4793,
      "name": "Kulischer, Eugene M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4794,
      "name": "Kulkarni, V.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4795,
      "name": "Kumar Narender",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4796,
      "name": "Kumar V, Santhosh (1966-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4797,
      "name": "Kumar, Sunil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4798,
      "name": "Kummel, Konrad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4799,
      "name": "Kumpiluvell, Louis, SDB [ed.] & Panackel, Charles, S.D.B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4800,
      "name": "Kumurappa, J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4801,
      "name": "Kunduru Joji S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4802,
      "name": "Kunduru, Joji, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4803,
      "name": "Kung, Hans (1928-  ) & Bowden, John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4804,
      "name": "Kunhambu, Potheri & Menon, Dilip [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4805,
      "name": "Kunnumpuram, Joe, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4806,
      "name": "Kunnumpuram, Kurien, S.J. (1931-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4807,
      "name": "Kuortti, Joel [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4808,
      "name": "Kuriakose, P.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4809,
      "name": "Kurth Godefroid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4810,
      "name": "Kurth Godefroid & Crawford V.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4811,
      "name": "Kurth, Godefroid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4812,
      "name": "Kurtscheid Bertrandus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4813,
      "name": "Kuruvachira, Jose, SDB (1958-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4814,
      "name": "Kuruvachira, Jose, SDB (1958-  ) [ed.] & Kanjirathinkal, Francis, SDB [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4815,
      "name": "Kuruvachira, Jose, SDB (1958-  ) [ed.] & Thuruthel, Joseph, SDB [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4816,
      "name": "Kuruvilla Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4817,
      "name": "Kurzman, Charles [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4818,
      "name": "Kyle, Melvin Grove",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4819,
      "name": "La Bedoyere Michael de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4820,
      "name": "la Bedoyere, Michael de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4821,
      "name": "La Bouillerie De Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4822,
      "name": "La Briere Yves de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4823,
      "name": "La Briere Yves De S.J. & Colbach P.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4824,
      "name": "La Briere, Yves de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4825,
      "name": "La Broise Rene-Marie De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4826,
      "name": "La Bruyere, Jean de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4827,
      "name": "La Colombiere Claude De S.J. Bhx",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4828,
      "name": "La Colombiere, Claude De, S.J., Bhx",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4829,
      "name": "La Deveze, P. de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4830,
      "name": "La Fontaine, Jean de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4831,
      "name": "La Gorce Piere de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4832,
      "name": "La Gorce, Agnes De & Kynaston-Snell [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4833,
      "name": "La Gorce, Piere de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4834,
      "name": "La Palma Luis de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4835,
      "name": "La Place De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4836,
      "name": "La Puente Luis de S.J. Ven.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4837,
      "name": "La Puente Luis De S.J. Ven. & Heigham J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4838,
      "name": "La Puente, Luis De, s.J., Ven. & De Baenst, Aurel., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4839,
      "name": "La Rallaye Leonce de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4840,
      "name": "La Ravoire-Marrow, Louis, Bishop",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4841,
      "name": "La Roche Arnauld Martial Marcet De & Lepage Emile [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4842,
      "name": "La Rochelle, S.A., O.M.I. & Fink, C.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4843,
      "name": "La Serviere, J. De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4844,
      "name": "La Taille Maurice De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4845,
      "name": "La Taille Maurice De S.J. & Schimpf J.B. S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4846,
      "name": "La Taille, Maurice de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4847,
      "name": "La Vaissiere J. de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4848,
      "name": "La Vallee Pussin, Louis De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4849,
      "name": "Labahn, Michael [ed.] & Peerbolte, Bert Jan Lietaert [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4850,
      "name": "Labata Franciscus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4851,
      "name": "Labbeus Philippus S.J. & Cossartius Gabriel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4852,
      "name": "Laborans Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4853,
      "name": "Laborde E.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4854,
      "name": "Laborde J.E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4855,
      "name": "Laborde, E.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4856,
      "name": "Laborde, J.E., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4857,
      "name": "Labriolle Pierre de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4858,
      "name": "Labriolle Pierre de Bardy Gustave Brehier Louis & Plinval G. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4859,
      "name": "Labriolle, Pierre de & Wilson, Herbert [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4860,
      "name": "Lacau J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4861,
      "name": "Lacey T.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4862,
      "name": "Lacger Louis de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4863,
      "name": "Lachieze-Rey, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4864,
      "name": "Lacombe, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4865,
      "name": "Lacouture Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4866,
      "name": "Lacroix Claudius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4867,
      "name": "Lactantius Lucius Caelius Firmianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4868,
      "name": "Lactantius, Lucius Caelius Firmianus & Fletcher, William [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4869,
      "name": "Ladislaus, I. Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4870,
      "name": "LaFarge, John, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4871,
      "name": "Lafargue Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4872,
      "name": "Lafon Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4873,
      "name": "Laforet N.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4874,
      "name": "Lagerlof, Selma",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4875,
      "name": "Lagier C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4876,
      "name": "Lagrange Marie-Joseph O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4877,
      "name": "Lagrange Marie-Joseph O.P. & Lavergne Ceslas O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4878,
      "name": "Lagrange, Marie-Joseph, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4879,
      "name": "Lahey Thomas A. C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4880,
      "name": "Lahiri, Bankimcandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4881,
      "name": "Lahitton Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4882,
      "name": "Lahousse Gustavus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4883,
      "name": "Lahr Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4884,
      "name": "Laidler, Harry W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4885,
      "name": "Lainez Jacobs S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4886,
      "name": "Laing Gordon J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4887,
      "name": "Laing, Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4888,
      "name": "Laing, Samuel & Clodd, Edward [rev.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4889,
      "name": "Lajjaramji",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4890,
      "name": "Lake, Kirsopp",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4891,
      "name": "Lakeland, Paul F., S.J. (1946-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4892,
      "name": "Lal B. Suresh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4893,
      "name": "Lal, Seraphim John, S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4894,
      "name": "Lalande, Germain",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4895,
      "name": "Lalfakmawia, H. Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4896,
      "name": "Lallemant Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4897,
      "name": "Lallemant Louis S.J. & Mcdougall Alan G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4898,
      "name": "Lallemant, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4899,
      "name": "Lallement, D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4900,
      "name": "Lallulal & Diksit, K.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4901,
      "name": "Lalpekhlua, L.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4902,
      "name": "Lamartine, A.-M.-L. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4903,
      "name": "Lamarzelle, G. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4904,
      "name": "Lamb, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4905,
      "name": "Lamb, Charles & Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4906,
      "name": "Lambert J.M. & Whitty W. [trnsl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4907,
      "name": "Lambert, Elie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4908,
      "name": "Lambert, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4909,
      "name": "Lambert, L.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4910,
      "name": "Lambert, R.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4911,
      "name": "Lamberty Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4912,
      "name": "Lambing A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4913,
      "name": "Lambing, A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4914,
      "name": "Lambot, D.C., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4915,
      "name": "Lambrecht Jan S.J. & Harrington Daniel J. S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4916,
      "name": "Lambrecht, Jan, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4917,
      "name": "Lambrette Alphonse S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4918,
      "name": "Lamennais, Felicite De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4919,
      "name": "Lamennais, Hugues-Felicite-Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4920,
      "name": "Lamer Hans Bux Ernest & Schone Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4921,
      "name": "Lamers G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4922,
      "name": "Laminne Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4923,
      "name": "Lamm William R. S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4924,
      "name": "Lamotte, Etienne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4925,
      "name": "Lampen Willibrord O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4926,
      "name": "Lampman Lisa Barnes [ed.] & Shattuck Michelle D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4927,
      "name": "Lamy, Thomas Josephus, Episc. & Guebay, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4928,
      "name": "Lanciani Rodolfo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4929,
      "name": "Lancicius Nicolaus S.J. Ven.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4930,
      "name": "Lancicius, Nicolaus, S.J., Ven.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4931,
      "name": "Landais, Napoleon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4932,
      "name": "Landgraf Artur Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4933,
      "name": "Landor, Walter Savage & Colvin, Sidney [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4934,
      "name": "Landrieux Maurice Eveq. & Smith Leonora L. Yorke [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4935,
      "name": "Landrieux, Maurice, Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4936,
      "name": "Landriot Jean-Francois Arche",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4937,
      "name": "Landriot Jean-Francois Archev",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4938,
      "name": "Landry Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4939,
      "name": "Landry, C.-F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4940,
      "name": "Landsberg, P.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4941,
      "name": "Lang Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4942,
      "name": "Lang, Andrew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4943,
      "name": "Langdon, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4944,
      "name": "Langdon-Davies, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4945,
      "name": "Lange Hermann S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4946,
      "name": "Lange, Frederick Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4947,
      "name": "Lange, Hermann, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4948,
      "name": "Langer, Rita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4949,
      "name": "Langland, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4950,
      "name": "Langlois Ch. V. & Seignobos Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4951,
      "name": "Langlois, Ch. V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4952,
      "name": "Langton, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4953,
      "name": "Lanier Henri Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4954,
      "name": "Lankester, Edwin Ray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4955,
      "name": "Lankester, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4956,
      "name": "Lannoy Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4957,
      "name": "Lannoye, Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4958,
      "name": "Lanslots D.I. O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4959,
      "name": "Lanslots, D.I., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4960,
      "name": "Lanza A. Archp. & Palazzini P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4961,
      "name": "Lanzoni Luigi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4962,
      "name": "Lapide Cornelius a S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4963,
      "name": "Laplace L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4964,
      "name": "Laprade, Victor de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4965,
      "name": "Laravoire Morrow Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4966,
      "name": "Laravoire Morrow, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4967,
      "name": "Laros, Matthias & Sayer, George [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4968,
      "name": "Larousse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4969,
      "name": "LaRousse William J. MM",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4970,
      "name": "Larousse, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4971,
      "name": "Larranaga, Victorien, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4972,
      "name": "Larson, Gerald James [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4973,
      "name": "Las Vergnas, Raymond & Martindale, Cyril C., S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4974,
      "name": "Lasance, Francis Xavier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4975,
      "name": "Lasetre Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4976,
      "name": "Laski, Harold J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4977,
      "name": "Lasserre Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4978,
      "name": "Latouche Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4979,
      "name": "Latourette, Kenneth Scott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4980,
      "name": "Lattey Cuthbert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4981,
      "name": "Lattey Cuthbert S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4982,
      "name": "Lattey, Cuthbert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4983,
      "name": "Lauer, Arcturus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4984,
      "name": "Laumonier J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4985,
      "name": "Laun, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4986,
      "name": "Launay, Adrien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4987,
      "name": "Launay, Louis De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4988,
      "name": "Laurand L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4989,
      "name": "Laurat, Lucien & Fitzgerald, Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4990,
      "name": "Laurentie, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4991,
      "name": "Laurentius Josephus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4992,
      "name": "Laurie Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4993,
      "name": "Laurin Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4994,
      "name": "Laux John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4995,
      "name": "Lavania B.K. [ed.] Samanta D.K. [ed.] Mandal S.K. [ed.] & Vyas N.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4996,
      "name": "Lavarenne, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4997,
      "name": "Lavaud Benoit O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4998,
      "name": "Lavedan Henri & Leonard C.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 4999,
      "name": "Laveille Eugene S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5000,
      "name": "Laveille Eugene S.J. & Lindsay Mariam [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5001,
      "name": "Laveille Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5002,
      "name": "Laveille Mgr. & Fitzsimons M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5003,
      "name": "Laveille, Eugene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5004,
      "name": "Lavelle, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5005,
      "name": "Laverdiere, Jean De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5006,
      "name": "Lavergne Ceslas O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5007,
      "name": "Lavery, Emmet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5008,
      "name": "Lavisse Ernest & Rambaud Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5009,
      "name": "Law, Charlie & Ranjan, Pallav [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5010,
      "name": "Law, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5011,
      "name": "Lawler Michael G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5012,
      "name": "Lawrence T.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5013,
      "name": "Lawrence, Edward A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5014,
      "name": "Laymann Paulus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5015,
      "name": "Le Bachelet Xavier-Marie S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5016,
      "name": "Le Blanc Augustinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5017,
      "name": "Le Bon, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5018,
      "name": "Le Brun, Gaston-Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5019,
      "name": "Le Buffe Francis P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5020,
      "name": "Le Camus Emile-Paul-Constant-Ange Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5021,
      "name": "Le Carre John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5022,
      "name": "Le Chauff de Kerguenec, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5023,
      "name": "Le Comte, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5024,
      "name": "Le Fabvre, Turrian, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5025,
      "name": "Le Fur, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5026,
      "name": "Le Gaudier, Antoine, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5027,
      "name": "Le Gobien, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5028,
      "name": "Le Jeune Jean Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5029,
      "name": "Le Page, Renout, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5030,
      "name": "Le Pailleur, Alfred, C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5031,
      "name": "Le Picard Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5032,
      "name": "Le Play F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5033,
      "name": "Le Queux, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5034,
      "name": "Le Rohellec Joseph C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5035,
      "name": "Le Rohu Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5036,
      "name": "Le Roy A. C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5037,
      "name": "Le Roy Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5038,
      "name": "Le Roy, A., C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5039,
      "name": "Le Roy, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5040,
      "name": "Le Saux, Henri (Swami Abhishiktananda) (1910-1973) & Du Boulay, Shirley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5041,
      "name": "Le Senne, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5042,
      "name": "Le Tellier Adrian S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5043,
      "name": "Le Vavasseur Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5044,
      "name": "Le Vavasseur, Leon & Haegy, Joseph, C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5045,
      "name": "Lea, Henri Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5046,
      "name": "Leacock, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5047,
      "name": "Leadbeater, Charles Webster",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5048,
      "name": "Leahy Maurice [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5049,
      "name": "Leaman, Oliver [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5050,
      "name": "Leaning-Mizen, Brian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5051,
      "name": "Lear, H.L. Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5052,
      "name": "Lebacqz G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5053,
      "name": "Lebarq Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5054,
      "name": "Lebeer, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5055,
      "name": "Lebesconte, Francois, C.J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5056,
      "name": "Lebreton Jules S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5057,
      "name": "Lebreton Jules S.J. & Zeiller Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5058,
      "name": "Lebreton, Jules, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5059,
      "name": "Lebreton, Jules, S.J. & Zeiller, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5060,
      "name": "Lecensier, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5061,
      "name": "Lecky William Edward Hartpole",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5062,
      "name": "Leclerc, Constant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5063,
      "name": "Leclercq Henri O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5064,
      "name": "Leclercq Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5065,
      "name": "Leclercq, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5066,
      "name": "Leclercq, Jean, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5067,
      "name": "Lecompte Edouard S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5068,
      "name": "Lecomte Du Nouy, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5069,
      "name": "Leconte de Lisle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5070,
      "name": "Lederer, Emil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5071,
      "name": "Ledochowski, Vlodimir, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5072,
      "name": "Ledochowski, Wlodimirus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5073,
      "name": "Ledos Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5074,
      "name": "Ledrus, Michel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5075,
      "name": "Lee Philip [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5076,
      "name": "Lee, Edwin Kenneth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5077,
      "name": "Lee, Frederic George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5078,
      "name": "Leen Edward C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5079,
      "name": "Leen, Edward, C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5080,
      "name": "Leeson, Cecil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5081,
      "name": "Lefebvre Auguste O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5082,
      "name": "Lefebvre Gaspar O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5083,
      "name": "Lefebvre, Gaspar, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5084,
      "name": "Lefort, L. Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5085,
      "name": "Lefrancois-Pillion, Louise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5086,
      "name": "Lega Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5087,
      "name": "Legaut Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5088,
      "name": "Legaut, Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5089,
      "name": "Legendre Alphonse Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5090,
      "name": "Legendre, Alphonse, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5091,
      "name": "Legendre, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5092,
      "name": "Legendre, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5093,
      "name": "Legg S.C.E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5094,
      "name": "Legoux Mgr. Arsenio Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5095,
      "name": "Legrand C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5096,
      "name": "Legrand Lucien MEP (1927-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5097,
      "name": "Legrand Ph. E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5098,
      "name": "Legras, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5099,
      "name": "Lehen de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5100,
      "name": "Lehmann, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5101,
      "name": "Lehmen Alfons S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5102,
      "name": "Lehmkuhl Augustinus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5103,
      "name": "Lehmkuhl, Augustinus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5104,
      "name": "Lehodey, Vital, O.Cist.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5105,
      "name": "Leib Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5106,
      "name": "Leibniz, Gottfried Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5107,
      "name": "Leigh-Bennett, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5108,
      "name": "Leighton Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5109,
      "name": "Leirvik, Oddbjorn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5110,
      "name": "Leite, Serafim, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5111,
      "name": "Lejeune Freddy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5112,
      "name": "Lekeux, Martial, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5113,
      "name": "Leloir, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5114,
      "name": "Lelotte, Fernand, S.J. & Kelly, J.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5115,
      "name": "Lemaire Andre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5116,
      "name": "Lemaire, Andre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5117,
      "name": "Lemaitre, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5118,
      "name": "Leman, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5119,
      "name": "Lemonnyer A. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5120,
      "name": "Lemonnyer, A., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5121,
      "name": "Lenin V.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5122,
      "name": "Lennerz H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5123,
      "name": "Lennerz, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5124,
      "name": "Lenormant, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5125,
      "name": "Leo I Magnus Papa Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5126,
      "name": "Leo Xiii, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5127,
      "name": "Leo, Frater, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5128,
      "name": "Leomis, James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5129,
      "name": "Leon, Armelle De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5130,
      "name": "Leon, Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5131,
      "name": "Leonard, John [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5132,
      "name": "Leonard, William J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5133,
      "name": "Leonardo Da Porto Murizio O.F.M. Sto.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5134,
      "name": "Leontiev A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5135,
      "name": "Leontius of Damascus, Lamoreaux, John C. [ed.] & Fakhuri, Yannah b. Istafan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5136,
      "name": "Leopold, Ernest Fridericus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5137,
      "name": "Lepicier Alexius Henricus Maria O.S.M. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5138,
      "name": "Lepicier, Alexius Henricus Maria, O.S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5139,
      "name": "Lepicier, Alexius Henricus Maria, O.S.M., Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5140,
      "name": "Lepidi Albertus O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5141,
      "name": "Lepin M. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5142,
      "name": "Lepper, John Heron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5143,
      "name": "Leray A. C.J.M. Eud.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5144,
      "name": "Lerch, Joseph A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5145,
      "name": "Leroux, Emmanuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5146,
      "name": "Leroy M.D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5147,
      "name": "Leroy Olivier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5148,
      "name": "Leroy, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5149,
      "name": "Leroy, Olivier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5150,
      "name": "Leroy-Beaulieu, Anatole",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5151,
      "name": "Leroy-Beaulieu, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5152,
      "name": "Lesko, Barbara S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5153,
      "name": "Leslie, Shane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5154,
      "name": "Lesourd, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5155,
      "name": "Lesser R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5156,
      "name": "Lessius Leonardus S.J. Ven.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5157,
      "name": "Lessius, Leonardus, S.J., Ven.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5158,
      "name": "Lethbridge, Roper",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5159,
      "name": "Letherim Meier Halevy [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5160,
      "name": "Letierce Edmond S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5161,
      "name": "Letins Constantinus O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5162,
      "name": "Letourneau Charles & Trollope Henry M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5163,
      "name": "Letourneau Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5164,
      "name": "Letourneau, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5165,
      "name": "Letourneau, Charles & Trollope, Henry M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5166,
      "name": "Letson, Douglas & Higgins, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5167,
      "name": "Lettey Cathbert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5168,
      "name": "Levassor, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5169,
      "name": "Levasti, Arrigo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5170,
      "name": "Levaux Leopold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5171,
      "name": "Levecq, E",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5172,
      "name": "Levenq, G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5173,
      "name": "Levi, Israel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5174,
      "name": "Levi, Sylvain",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5175,
      "name": "Levie, Jean, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5176,
      "name": "Levy Louis-Germain",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5177,
      "name": "Levy, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5178,
      "name": "Levy-Bruhl, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5179,
      "name": "Levy-Bruhl, L. & Clare, Lilian A.  [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5180,
      "name": "Levy-Bruhl, L. & Lee, Elizabeth [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5181,
      "name": "Lewes, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5182,
      "name": "Lewis C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5183,
      "name": "Lewis Charlton T. & Short Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5184,
      "name": "Lewis Franklin D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5185,
      "name": "Lewis Hedwig S.J. (1945-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5186,
      "name": "Lewis, Abram Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5187,
      "name": "Lewis, C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5188,
      "name": "Lewis, D.B. Wyndham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5189,
      "name": "Lewis, Eric",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5190,
      "name": "Lewis, H. Spencer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5191,
      "name": "Lewis, Hedwig, S.J. (1945-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5192,
      "name": "Lewis, Hedwig, S.J. (1945-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5193,
      "name": "Lewis, Sinclair",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5194,
      "name": "Lewis, Wyndham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5195,
      "name": "Leys, M.D.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5196,
      "name": "L'Hoir, Francis Xavier, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5197,
      "name": "Li Jiubiao & Zurcher, Erik Jan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5198,
      "name": "Liardon, Roberts",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5199,
      "name": "Liberatore Matteo S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5200,
      "name": "Liberatore Matthaeus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5201,
      "name": "Libert, A.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5202,
      "name": "Libois Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5203,
      "name": "Lichtenberg, Henri & Kennedy, James M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5204,
      "name": "Lichtervelde, Comte Louis De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5205,
      "name": "Lichtervelde, Comte Louis De; Reed, Thomas H. [transl.] & Reed, H. Russel [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5206,
      "name": "Liddell Hart, B.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5207,
      "name": "Liddell Henry George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5208,
      "name": "Liddell Henry George & Scott Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5209,
      "name": "Liddell, Henry George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5210,
      "name": "Liddell, Henry George & Scott, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5211,
      "name": "Liddon, H.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5212,
      "name": "Liebermann, Leopold Br.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5213,
      "name": "Lierheimer Bernardus Maria O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5214,
      "name": "Lierheimer Fr. Xav.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5215,
      "name": "Lierheimer, Fr. Xav.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5216,
      "name": "Liertz Rhaban",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5217,
      "name": "Lieske, Aloisius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5218,
      "name": "Lietzmann, Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5219,
      "name": "Lievens, Constant, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5220,
      "name": "Lightfoot, J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5221,
      "name": "Ligny Francois De S.J. & Molloy Brian Arthur [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5222,
      "name": "Ligorio Alphonsus Maria De C.Ss.R. Episc. Stus. (1696-1787)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5223,
      "name": "Ligorio Alphonsus Maria De C.Ss.R. Episc. Stus. (1696-1787) & Grimm Eugene [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5224,
      "name": "Ligorio Alphonsus Maria De C.Ss.R. Episc. Stus. (1696-1787) & Jones James [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5225,
      "name": "Ligorio Alphonsus Maria De C.Ss.R. Episc. Stus. (1696-1787) & Livius Titus [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5226,
      "name": "Ligorio, Alphonsus Maria De, C.Ss.R. Episc., Stus. (1696-1787)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5227,
      "name": "Ligorio, Alphonsus Maria De, C.Ss.R. Episc., Stus. (1696-1787) & Dujardin, L.-J. [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5228,
      "name": "Ligorio, Alphonsus Maria De, C.Ss.R. Episc., Stus. (1696-1787) & Grimm, Eugene [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5229,
      "name": "Ligorio, Alphonsus Maria De, C.Ss.R. Episc., Stus. (1696-1787) & Mullock, J.T. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5230,
      "name": "Ligorio, Alphonsus Maria de, C.SS.R.,Episc.Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5231,
      "name": "Ligutti, Luigi G., Mgr. & Rawe, John C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5232,
      "name": "Lilasuka, Bilvamangala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5233,
      "name": "Lillie, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5234,
      "name": "Lilly William Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5235,
      "name": "Lin Tsiu-Sen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5236,
      "name": "Linafelt Tod (1965-  ) Beal Timothy K. & Cotter David W. O.S.B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5237,
      "name": "Linck Henry C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5238,
      "name": "Lindeboom J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5239,
      "name": "Linder, Josephus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5240,
      "name": "Lingard, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5241,
      "name": "Lingard, John & Belloc, Hilaire",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5242,
      "name": "Linnemann, Eta & Yarbrough, Robert W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5243,
      "name": "Lintelo Jules S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5244,
      "name": "Lippert Peter S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5245,
      "name": "Lippert, Peter, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5246,
      "name": "Lippert, Peter, S.J. & Shuster, George N. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5247,
      "name": "Lipsius, R.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5248,
      "name": "Lipson E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5249,
      "name": "Lithard Victor C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5250,
      "name": "Littel Therese",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5251,
      "name": "Little, A.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5252,
      "name": "Little, Spence V.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5253,
      "name": "Little, W.J. Knox",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5254,
      "name": "Livingstone, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5255,
      "name": "Livingstone, R.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5256,
      "name": "Livingstone, R.W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5257,
      "name": "Livingstone, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5258,
      "name": "Livius, Titus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5259,
      "name": "Lloyd Teresa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5260,
      "name": "Lloyd, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5261,
      "name": "Lloyd, Frances",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5262,
      "name": "Lloyd, Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5263,
      "name": "Lo Grasso, Joannes B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5264,
      "name": "Loane, George [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5265,
      "name": "Lobo, George V., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5266,
      "name": "Lobo, Lancy, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5267,
      "name": "Lobzang Mingyur Dorje, Lama",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5268,
      "name": "Loch C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5269,
      "name": "Loch Valentinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5270,
      "name": "Loch, C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5271,
      "name": "Locher Gabriel O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5272,
      "name": "Lock, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5273,
      "name": "Locke, Clinton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5274,
      "name": "Locke, John K., S.J. (1632-1704)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5275,
      "name": "Locke, William J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5276,
      "name": "Lockington, William J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5277,
      "name": "Lockton, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5278,
      "name": "Lockyer, J. Norman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5279,
      "name": "Loder, Vernon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5280,
      "name": "Lodge, Oliver",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5281,
      "name": "Lodge, Rupert Clendon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5282,
      "name": "Loewe, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5283,
      "name": "Loewengard Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5284,
      "name": "Lofthouse, W.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5285,
      "name": "Lohse Bernhard (1928-  ) & Harrisville Roy A. [ed.& Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5286,
      "name": "Loiseau, Capit.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5287,
      "name": "Loiselet J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5288,
      "name": "Loisy Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5289,
      "name": "Loisy, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5290,
      "name": "Loisy, Alfred & Galton, A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5291,
      "name": "Lombardi Carolus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5292,
      "name": "Lombardus, Petrus & Moran, Patrick Francis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5293,
      "name": "Lombez, Ambroise De, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5294,
      "name": "Lonan, Teresa, FDCC",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5295,
      "name": "London, Jack",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5296,
      "name": "Lonergan, Bernard J.F., S.J. (1904-84)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5297,
      "name": "Lonergan, William I., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5298,
      "name": "Longchar A. Wati",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5299,
      "name": "Longdon, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5300,
      "name": "Longenecker, Dwight [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5301,
      "name": "Longfellow, Henry Wadsworth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5302,
      "name": "Longhaye G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5303,
      "name": "Longkumer, Jungmayangla",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5304,
      "name": "Longman, Tremper, III",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5305,
      "name": "Longmuir, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5306,
      "name": "Longpre Ephrem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5307,
      "name": "Longridge W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5308,
      "name": "Longridge, C.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5309,
      "name": "Loofs, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5310,
      "name": "Loomba, Murti Ram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5311,
      "name": "Lorac, E.C.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5312,
      "name": "Lord Daniel A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5313,
      "name": "Lord, Daniel A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5314,
      "name": "Lortal R. P.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5315,
      "name": "Lortz Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5316,
      "name": "Loslever Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5317,
      "name": "Lossky Nicholas [ed.] Bonino Jose Miguez [ed.] Pobee John S. [ed.] & Stransky Thomas F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5318,
      "name": "Lot, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5319,
      "name": "Lot, Ferdinand, Pfister, Christian & Ganshof, F.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5320,
      "name": "Loth Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5321,
      "name": "Loti, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5322,
      "name": "Loti, Pierre & Morich, R.Y. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5323,
      "name": "Lottin Odon O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5324,
      "name": "Lottin, Odon, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5325,
      "name": "Louelle, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5326,
      "name": "Louis Prakash S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5327,
      "name": "Louis Prakash S.j. & Vashum R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5328,
      "name": "Louis, Prakash, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5329,
      "name": "Lounay, Louis de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5330,
      "name": "Lousse E. & Roland J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5331,
      "name": "Lousse, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5332,
      "name": "Louvet, Louis-Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5333,
      "name": "Louwers, Ch., S.J. & Gaspard, E., S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5334,
      "name": "Lovat, Alice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5335,
      "name": "Lover, Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5336,
      "name": "Lovrenovic Ivan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5337,
      "name": "Lowin, Shari L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5338,
      "name": "Lowis, Douglas W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5339,
      "name": "Lowndes, Marie Belloc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5340,
      "name": "Lowney, Chris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5341,
      "name": "Lowrie, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5342,
      "name": "Loyola Ignatius De S.J. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5343,
      "name": "Loyola Juan de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5344,
      "name": "Loyola Mary Mother",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5345,
      "name": "Loyola Mary Mother & Thurston Father S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5346,
      "name": "Loyola, Ignatius De, S.J., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5347,
      "name": "Loyola, Ignatius De, S.J., Stus. & Alice Wilmot Chetwode [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5348,
      "name": "Loyola, Ignatius De, S.J., Stus. & Rix, E.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5349,
      "name": "Loyola, Mary, Mother",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5350,
      "name": "Lualdi, Michelangelo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5351,
      "name": "Lubac Henri De S.J. (1896-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5352,
      "name": "Lubac, Henri De, S.J. (1896-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5353,
      "name": "Lubbock, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5354,
      "name": "Luca, Francisco",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5355,
      "name": "Luca, Francisco [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5356,
      "name": "Lucanus, Marcus Annaeus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5357,
      "name": "Lucas E.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5358,
      "name": "Lucas Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5359,
      "name": "Lucas Herbert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5360,
      "name": "Lucas, E.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5361,
      "name": "Lucas, Evang., Stus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5362,
      "name": "Luchaire, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5363,
      "name": "Luciani Rivero Rafael Francisco",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5364,
      "name": "Lucianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5365,
      "name": "Lucidi Angelus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5366,
      "name": "Lucie Christine (Pseudon.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5367,
      "name": "Lucie Christine (Pseudon.) & Poulain, Auguste, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5368,
      "name": "Luck, David J., Wales, Hugh G., Taylor, Donald A. & Rubin, Ronald S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5369,
      "name": "Luckenbach, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5370,
      "name": "Luckock, Herbert Mortimer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5371,
      "name": "Lucretius Carus, Titus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5372,
      "name": "Luddy, Ailbe J., O.Cist.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5373,
      "name": "Ludemann Gerd",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5374,
      "name": "Ludlow, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5375,
      "name": "Ludolphus De Saxonia O. Cart.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5376,
      "name": "Ludolphus De Saxonia O. Cart. (1300-1378)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5377,
      "name": "Ludwig Theodore M. [ed.] & Mwakabana Hance A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5378,
      "name": "Ludwig, Emil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5379,
      "name": "Luebeck Henricus De O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5380,
      "name": "Luis de Granada, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5381,
      "name": "Luis Ponce de Leon O.E.S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5382,
      "name": "Lukas, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5383,
      "name": "Luke Of St. Joseph O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5384,
      "name": "Luke, K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5385,
      "name": "Lumb T.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5386,
      "name": "Lumbreras, Petrus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5387,
      "name": "Lund, Nils Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5388,
      "name": "Lunn Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5389,
      "name": "Lupieri, Edmondo F., Johnson, Maria Poggi [transl.] & Kamesar, Adam [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5390,
      "name": "Lupton, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5391,
      "name": "Lusseau, H., Abbe & Collomb, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5392,
      "name": "Luthardt, Chr. Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5393,
      "name": "Luther, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5394,
      "name": "Luther, Martin & Amburger-Stuart, H.S.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5395,
      "name": "Lyall, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5396,
      "name": "Lyall, William Rowe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5397,
      "name": "Lyell, Sir Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5398,
      "name": "Lymington, Viscount",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5399,
      "name": "Lynch, P.M., P.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5400,
      "name": "Lyne, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5401,
      "name": "Lynk Frederick M. S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5402,
      "name": "Lyonnard J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5403,
      "name": "Lyons Pius O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5404,
      "name": "Lyttelton & Coldsmith",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5405,
      "name": "Lytton, Bulwer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5406,
      "name": "Maas A.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5407,
      "name": "Maas A.W. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5408,
      "name": "Maas Nicholas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5409,
      "name": "Maas, Nicholas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5410,
      "name": "Macabian C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5411,
      "name": "Macarius, Magnes, Episc. & Crafer, T.W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5412,
      "name": "Macaulay Thomas Babington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5413,
      "name": "Macaulay, Thackeray & Hadow, G.E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5414,
      "name": "Macaulay, Thomas Babington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5415,
      "name": "Macaulay, Thomas Babington & Bowen, Herbert Courthope [ed. & Anno.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5416,
      "name": "Macaulay, Thomas Babington & Innes, D. Arthur [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5417,
      "name": "Macaulay, Thomas Babington & Maxwell, E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5418,
      "name": "Macaulay, Thomas Babington & Storr, Francis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5419,
      "name": "MacCabe, William Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5420,
      "name": "MacCaffrey James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5421,
      "name": "Maccarrone, Michele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5422,
      "name": "MacDonald, A.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5423,
      "name": "MacDonald, A.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5424,
      "name": "MacDonald, Fergus, C.P. & Mcmahon, Thomas J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5425,
      "name": "MacDonnell, John De Courcy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5426,
      "name": "MacEachen Roderick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5427,
      "name": "MacGillivray G.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5428,
      "name": "Macgillivray, G.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5429,
      "name": "Machado, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5430,
      "name": "Machiavelli, Nicolo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5431,
      "name": "Mack Dana [ed.] & Blankenhorn David [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5432,
      "name": "Mackean, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5433,
      "name": "Mackenna, Robert W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5434,
      "name": "Mackenzie, Compton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5435,
      "name": "Mackenzie, John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5436,
      "name": "Mackenzie, Kenneth D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5437,
      "name": "Mackenzie, Robert Jameson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5438,
      "name": "Mackey, Albert G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5439,
      "name": "Mackie, R.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5440,
      "name": "Mackinder, Dorothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5441,
      "name": "Mackinder, H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5442,
      "name": "MacKinnon, Donald M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5443,
      "name": "Maclean, Annie Marion",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5444,
      "name": "Macmillan, Hugh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5445,
      "name": "MacMullen, Ramsay (1928-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5446,
      "name": "MacMunn, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5447,
      "name": "Macnaught John Campbell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5448,
      "name": "Macnaughtan, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5449,
      "name": "Macneice Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5450,
      "name": "Macnevin, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5451,
      "name": "Macnicol Nicol",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5452,
      "name": "Macphail, R.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5453,
      "name": "MacQuarrie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5454,
      "name": "Madan T.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5455,
      "name": "Madan, T.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5456,
      "name": "Madanu Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5457,
      "name": "Madoz Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5458,
      "name": "Madsen, A.W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5459,
      "name": "Maeterlinck Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5460,
      "name": "Maeterlinck, Maurice & De Mattos, Alexander Teixeira [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5461,
      "name": "Maeterlinck, Maurice & Sutro, Alfred [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5462,
      "name": "Maffei, Giovan Pietro, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5463,
      "name": "Magner, James A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5464,
      "name": "Magnien, Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5465,
      "name": "Magnin, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5466,
      "name": "Maharajan Mangal Man",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5467,
      "name": "Maheo, Lorho Mary (1970-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5468,
      "name": "Maheshwari, A.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5469,
      "name": "Mahieu Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5470,
      "name": "Mahmud, Safdar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5471,
      "name": "Mahoney Edward J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5472,
      "name": "Mahoney, Edward J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5473,
      "name": "Maillet, Germaine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5474,
      "name": "Mainage Th. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5475,
      "name": "Maine De Biran",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5476,
      "name": "Maine, Henry Sumner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5477,
      "name": "Mair, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5478,
      "name": "Maire Elie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5479,
      "name": "Maire, Elie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5480,
      "name": "Maire, Gilbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5481,
      "name": "Mais, S.P.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5482,
      "name": "Maistre Joseph Comte De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5483,
      "name": "Maistre, Joseph, Comte de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5484,
      "name": "Maiuri, Amedeo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5485,
      "name": "Maius Angelus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5486,
      "name": "Majeed, Akhtar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5487,
      "name": "Major, Andrea",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5488,
      "name": "Majumdar, B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5489,
      "name": "Majumdar, Bimanbihari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5490,
      "name": "Majumdar, Mohitabala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5491,
      "name": "Majumdar, Mohitalal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5492,
      "name": "Majumdar, Mohitalala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5493,
      "name": "Majumdar, N.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5494,
      "name": "Majumdar, Surendranath, Bandyopadhyay, V.N. [ed.] & Das, S.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5495,
      "name": "Mal Puran",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5496,
      "name": "Malard, Cita & Suzanne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5497,
      "name": "Malcor Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5498,
      "name": "Male, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5499,
      "name": "Malebranche, Nicolas, C. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5500,
      "name": "Malegue, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5501,
      "name": "Malek, Roman, S.V.D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5502,
      "name": "Malekandathil Pius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5503,
      "name": "Malet, Lucas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5504,
      "name": "Malhan, R.C. & Maheshwari, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5505,
      "name": "Malieckal, Louis, C.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5506,
      "name": "Malik, Jamal [ed.] & Reifeld, Helmut [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5507,
      "name": "Malina Bruce J. & Rohrbaugh Richard L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5508,
      "name": "Malipurathu, Thomas, SVD [ed.] & Stanislaus, Lazar, SVD [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5509,
      "name": "Mallampalli Chandra S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5510,
      "name": "Mallet F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5511,
      "name": "Mallinson, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5512,
      "name": "Mallock William Harrell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5513,
      "name": "Mallock, William Harrell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5514,
      "name": "Mallon Alexis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5515,
      "name": "Malone Mary T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5516,
      "name": "Malone, Mary T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5517,
      "name": "Maloney, George A., S.J. (1924-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5518,
      "name": "Malory, Thomas & Macardle, Dorothy M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5519,
      "name": "Malou Edouard S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5520,
      "name": "Malou Jean-Baptiste Eveq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5521,
      "name": "Malou, Edouard, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5522,
      "name": "Malou, Jean-Baptiste, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5523,
      "name": "Malraux, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5524,
      "name": "Maltary, Janne Haaland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5525,
      "name": "Malthus, Thomas Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5526,
      "name": "Malvy Antoine & Viller Marcel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5527,
      "name": "Manare Olivier S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5528,
      "name": "Manare, Olivier, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5529,
      "name": "Mandato Pius de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5530,
      "name": "Mandaule, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5531,
      "name": "Mandelkern, Solomon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5532,
      "name": "Mandolfo, Carleen R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5533,
      "name": "Mandonnet, Pierre, O.P. & Larkin Mary Benedicta, O.P., Sr. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5534,
      "name": "Mangalilal, Munsi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5535,
      "name": "Mangasarian, M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5536,
      "name": "Mangenot Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5537,
      "name": "Maniampra, Mathew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5538,
      "name": "Manickam, Shekhar, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5539,
      "name": "Maniparampil, Jose",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5540,
      "name": "Mann H.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5541,
      "name": "Mann, Thomas W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5542,
      "name": "Manna Paolo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5543,
      "name": "Mannin, Ethel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5544,
      "name": "Manning Henry Edward Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5545,
      "name": "Manning Henry Edward Card. & Paul C. Kegan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5546,
      "name": "Manning, Henry Edward, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5547,
      "name": "Manning, Robert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5548,
      "name": "Mansel, Henry Longueville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5549,
      "name": "Mansfield Ron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5550,
      "name": "Mansion J.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5551,
      "name": "Mansion, J.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5552,
      "name": "Mansion, J.E. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5553,
      "name": "Manson, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5554,
      "name": "Mantegazza, Paolo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5555,
      "name": "Manuwald Martin S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5556,
      "name": "Many S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5557,
      "name": "Manzoni Alessandro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5558,
      "name": "Manzoni, Alessandro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5559,
      "name": "Manzoni, Alessandro & Reville, John C., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5560,
      "name": "Maqsood Ruqaiyyah Waris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5561,
      "name": "Maqsood, Ruqaiyyah Waris",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5562,
      "name": "Maranget Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5563,
      "name": "Maranget, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5564,
      "name": "Marbach, Joseph Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5565,
      "name": "Marc Andre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5566,
      "name": "Marc Cl. & Gestermann Fr. X. C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5567,
      "name": "Marcaurelle Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5568,
      "name": "Marcel, Gabriel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5569,
      "name": "Marchal L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5570,
      "name": "Marchant, James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5571,
      "name": "Marchetto Agostino (1940-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5572,
      "name": "Marcus, S. Ph.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5573,
      "name": "Marcus, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5574,
      "name": "Marcuse, Ludwig",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5575,
      "name": "Marechal Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5576,
      "name": "Marechal Joseph S.J. & Thorold Algar [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5577,
      "name": "Marechal, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5578,
      "name": "Marett, R.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5579,
      "name": "Margaret, D.J., FMA",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5580,
      "name": "Margat de Tilly, Jean-Baptiste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5581,
      "name": "Margeire Amedee De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5582,
      "name": "Margenau, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5583,
      "name": "Margerie Amedee De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5584,
      "name": "Margoliouth, D.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5585,
      "name": "Maria do Divino Coracao, Sr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5586,
      "name": "Maria Michael de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5587,
      "name": "Maria, Emma, A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5588,
      "name": "Marichy, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5589,
      "name": "Marie De Jesus F.D.C.D.J. (Deluil-Martiny Marie)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5590,
      "name": "Marie de l'Incarnation",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5591,
      "name": "Marie du Saint-Sacrement Mere.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5592,
      "name": "Marie J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5593,
      "name": "Marie Sainte Cecile de Rome, R.J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5594,
      "name": "Marie-Colette Du Sacre-Coeur, O.S.Cl.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5595,
      "name": "Marietti, A., Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5596,
      "name": "Marin Elma",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5597,
      "name": "Marin-Sola F. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5598,
      "name": "Maritain Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5599,
      "name": "Maritain Jacques & Scanlan J.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5600,
      "name": "Maritain Jacques & Sheed F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5601,
      "name": "Maritain Jacques & Watkin E.T. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5602,
      "name": "Maritain, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5603,
      "name": "Maritain, Jacques & Andison, Mabelle L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5604,
      "name": "Maritain, Jacques & Landry, Lionel [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5605,
      "name": "Maritain, Jacques & Scanlan, J.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5606,
      "name": "Maritain, Jacques, Raissa & Thorold, Algar [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5607,
      "name": "Maritain, Jacques, Wall, Bernard [transl.] & Adamson R., Margot [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5608,
      "name": "Maritain, Raissa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5609,
      "name": "Marks, Darren C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5610,
      "name": "Marlier Arthur S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5611,
      "name": "Marlowe Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5612,
      "name": "Marlowe Christopher & Lock Albert A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5613,
      "name": "Marlowe, Dave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5614,
      "name": "Marmion Columba O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5615,
      "name": "Marmion Columba O.S.B. Dom Thibaut O.S.B. [ed.] & St Thomas Mary Mother [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5616,
      "name": "Marmion, Columba, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5617,
      "name": "Maroto, Philippus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5618,
      "name": "Marques-Riviere, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5619,
      "name": "Marrou, Henri-Irenee",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5620,
      "name": "Marryat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5621,
      "name": "Marsch Ngaio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5622,
      "name": "Marsch Ngaio & Jellett Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5623,
      "name": "Marsch Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5624,
      "name": "Marsden, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5625,
      "name": "Marsh, Gideon W.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5626,
      "name": "Marsh, H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5627,
      "name": "Marshall, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5628,
      "name": "Marshall, Alfred & Marshall, Mary Paley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5629,
      "name": "Marshall, Bruce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5630,
      "name": "Marshall, Bruce D. (1955-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5631,
      "name": "Marshall, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5632,
      "name": "Marshall, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5633,
      "name": "Marshall, John (Sir)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5634,
      "name": "Marshall, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5635,
      "name": "Martel, Antoine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5636,
      "name": "Martene Edmundus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5637,
      "name": "Martensen, H. & Spence, C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5638,
      "name": "Martensen, H. & Urwick, W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5639,
      "name": "Marti, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5640,
      "name": "Martimort, Aime-Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5641,
      "name": "Martin A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5642,
      "name": "Martin Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5643,
      "name": "Martin C. Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5644,
      "name": "Martin Felix S.J. & Shea John Gilmary [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5645,
      "name": "Martin Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5646,
      "name": "Martin G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5647,
      "name": "Martin Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5648,
      "name": "Martin James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5649,
      "name": "Martin Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5650,
      "name": "Martin Lealow E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5651,
      "name": "Martin Raymond M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5652,
      "name": "Martin, C., Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5653,
      "name": "Martin, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5654,
      "name": "Martin, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5655,
      "name": "Martin, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5656,
      "name": "Martin, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5657,
      "name": "Martin, Hugh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5658,
      "name": "Martin, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5659,
      "name": "Martin, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5660,
      "name": "Martin, Ralph P. (1942-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5661,
      "name": "Martin, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5662,
      "name": "Martindale C.C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5663,
      "name": "Martindale Cyril C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5664,
      "name": "Martindale, C.C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5665,
      "name": "Martindale, Cuthbert C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5666,
      "name": "Martindale, Cyril C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5667,
      "name": "Martineau James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5668,
      "name": "Martineau, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5669,
      "name": "Martineau, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5670,
      "name": "Martineau, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5671,
      "name": "Martini Carlo Maria & McGrath James [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5672,
      "name": "Martini, Martin, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5673,
      "name": "Martonne, Emmanuel, de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5674,
      "name": "Marty, Elie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5675,
      "name": "Marucchi Orazio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5676,
      "name": "Marvin F.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5677,
      "name": "Marx Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5678,
      "name": "Marx, Adolph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5679,
      "name": "Marx, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5680,
      "name": "Mary of Jesus, Sister",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5681,
      "name": "Mary Of St. Austin, Mother & Ryan, Nicholas, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5682,
      "name": "Masani, R.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5683,
      "name": "Mascall, E.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5684,
      "name": "Mascarenhas, Fio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5685,
      "name": "Masih Jagdhari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5686,
      "name": "Maso Miguel Saderra S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5687,
      "name": "Mason A.E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5688,
      "name": "Mason, A.E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5689,
      "name": "Mason, Arthur James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5690,
      "name": "Mason, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5691,
      "name": "Maspero, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5692,
      "name": "Maspero, Gaston, Sayce. A.H. [ed.] & McClure, M.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5693,
      "name": "Maspero, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5694,
      "name": "Masri Fouad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5695,
      "name": "Masseron, Alexandre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5696,
      "name": "Massey, Gerald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5697,
      "name": "Massey, James (1943-2015 ) & Seth, Deepak [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5698,
      "name": "Massillon Jean-Baptiste C. Orat. Eveq.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5699,
      "name": "Masson, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5700,
      "name": "Masson, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5701,
      "name": "Masson, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5702,
      "name": "Masson, J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5703,
      "name": "Masson-Oursel, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5704,
      "name": "Masterman, C.F.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5705,
      "name": "Masters R.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5706,
      "name": "Mastroyiannopoulos Elias",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5707,
      "name": "Masud, Muhammad Khalid (1939-  ) [ed.], Peters, Rudolph [ed.] & Powers, Davis S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5708,
      "name": "Masure Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5709,
      "name": "Masure, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5710,
      "name": "Matalene P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5711,
      "name": "Matera, Frank J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5712,
      "name": "Mathai, M.P. [ed.]; John, M.S. [ed.] & Joseph, Siby K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5713,
      "name": "Matharan M.M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5714,
      "name": "Matharan, M.M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5715,
      "name": "Matheson Peter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5716,
      "name": "Mathew George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5717,
      "name": "Mathew George [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5718,
      "name": "Mathew George & Nayak Ramesh Chandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5719,
      "name": "Mathew P.T. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5720,
      "name": "Mathew, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5721,
      "name": "Mathew, E.P. & Perumalil, Augustine, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5722,
      "name": "Mathew, K.S. [ed.], De Souza, Teotonio R., S.J. [ed.] & Malekandathil, Pius [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5723,
      "name": "Mathews Mohan M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5724,
      "name": "Mathews William S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5725,
      "name": "Mathewson, Steven D. (1961-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5726,
      "name": "Mathias, Louis, S.D.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5727,
      "name": "Matlubul Hasan, Saiyid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5728,
      "name": "Mattam Joseph S.J. (1936-  ) [ed.] & Arockiadoss P. S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5729,
      "name": "Mattam Joseph S.J. (1936-  ) [ed.] & Gabriel Reuben [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5730,
      "name": "Mattam Joseph S.J. (1936-  ) [ed.] & Marak Krickwin C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5731,
      "name": "Mattam, Joseph, S.J. (1936-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5732,
      "name": "Matthaeus a Coronata, O.M.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5733,
      "name": "Matthaeus, Stus. Apost. Evang",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5734,
      "name": "Matthaeus, Stus. Apost. Evang.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5735,
      "name": "Matthew David & Matthew Gervase O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5736,
      "name": "Matthews John Hobson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5737,
      "name": "Matthews, Cecily C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5738,
      "name": "Matthews, John V., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5739,
      "name": "Mattiussi Guido S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5740,
      "name": "Mattiussi, Guido, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5741,
      "name": "Maturin B.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5742,
      "name": "Maucourant F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5743,
      "name": "Maugham, W. Somerset",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5744,
      "name": "Maulde la Claviere, R. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5745,
      "name": "Maumigny Rene De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5746,
      "name": "Maumigny, Rene de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5747,
      "name": "Maunoury, A.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5748,
      "name": "Maupassant, Guy de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5749,
      "name": "Maurel A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5750,
      "name": "Mauri J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5751,
      "name": "Mauriac Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5752,
      "name": "Mauriac, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5753,
      "name": "Mauriac, Francois & Dufrency, Marie-Louise [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5754,
      "name": "Maurice, Frederick Denison (1805-1872)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5755,
      "name": "Maurin Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5756,
      "name": "Maurois, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5757,
      "name": "Maurois, Andre & D'Arcy, Ella [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5758,
      "name": "Mauron, A. & Mallet, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5759,
      "name": "Mauron, A. & Verrier, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5760,
      "name": "Maury, Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5761,
      "name": "Mausherr I. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5762,
      "name": "Maximus Confessor, Stus. & Pegon, Joseph [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5763,
      "name": "Maxwell-Scott Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5764,
      "name": "Maxwell-Scott, Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5765,
      "name": "May J. Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5766,
      "name": "May, F.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5767,
      "name": "Maycock, A.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5768,
      "name": "Mayer Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5769,
      "name": "Maynard Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5770,
      "name": "Maynard, Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5771,
      "name": "Maynard, Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5772,
      "name": "Mayne, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5773,
      "name": "McAstocker, David P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5774,
      "name": "Mcauliff, Harold J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5775,
      "name": "McCabe Francis X. C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5776,
      "name": "McCabe Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5777,
      "name": "Mccabe, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5778,
      "name": "McCabe, Joseph [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5779,
      "name": "Mccall, Samuel Walker",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5780,
      "name": "McCann Justin O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5781,
      "name": "Mccarthy George E. & Rhodes Royal W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5782,
      "name": "McCarthy, Denis Florence [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5783,
      "name": "McCarthy, Justin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5784,
      "name": "McClure John S. (1952-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5785,
      "name": "McColvin Lionel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5786,
      "name": "McConkey, Dale D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5787,
      "name": "McConnachie, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5788,
      "name": "McCoog Thomas M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5789,
      "name": "McCoog, Thomas M., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5790,
      "name": "Mccormick Richard A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5791,
      "name": "Mccown, Chester Charlton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5792,
      "name": "McDonald James I.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5793,
      "name": "McDonald Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5794,
      "name": "Mcdonald, Alexander F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5795,
      "name": "McDonald, Patrick J. & Mcdonald, Claudette M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5796,
      "name": "McDonald, Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5797,
      "name": "McDonnell Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5798,
      "name": "Mcdonough, Peter & Bianchi, Eugene C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5799,
      "name": "Mcdougall William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5800,
      "name": "McDougall, Eleanor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5801,
      "name": "Mcdowall, Stewart A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5802,
      "name": "McEvoy Hubert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5803,
      "name": "McFadden, Charles J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5804,
      "name": "McGarrigle, Francis J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5805,
      "name": "McGarry, William J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5806,
      "name": "McGavin, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5807,
      "name": "McGiffert, Arthur Cushman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5808,
      "name": "McGill John Bishop",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5809,
      "name": "McGinley, Laurence J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5810,
      "name": "McGlinchey, Joseph F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5811,
      "name": "Mcgovern, William Montgomery",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5812,
      "name": "McGrath Alister E. (1953-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5813,
      "name": "McGrath, Alister E. (1953-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5814,
      "name": "McGrath, Fergal, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5815,
      "name": "Mcgregor Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5816,
      "name": "McGucken William J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5817,
      "name": "McGucken, William J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5818,
      "name": "Mchugh John & Callan Charles J. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5819,
      "name": "Mchugh Mary Frances",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5820,
      "name": "Mckay, Matthew & Fanning, Patrick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5821,
      "name": "McKee, Elsie Anne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5822,
      "name": "Mckenna Lambert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5823,
      "name": "Mckenna, Marthe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5824,
      "name": "Mckenzie Alyce M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5825,
      "name": "McKenzie, John L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5826,
      "name": "McKeon, Richard M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5827,
      "name": "McKevitt, Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5828,
      "name": "McLaughlin J.B. O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5829,
      "name": "McLaughlin, Michael T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5830,
      "name": "McLaughlin, Patrick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5831,
      "name": "McLeod W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5832,
      "name": "McLeod, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5833,
      "name": "McMahon John T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5834,
      "name": "Mcmillan, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5835,
      "name": "Mcmordie, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5836,
      "name": "McNabb Vincent J.  O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5837,
      "name": "McNabb, Vincent J.,  O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5838,
      "name": "McNamara Thomas C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5839,
      "name": "McNell, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5840,
      "name": "McNichols, John P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5841,
      "name": "McPhillips, Kathleen [ed.] & Isherwood, Lisa [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5842,
      "name": "Mcreavy Lawrence L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5843,
      "name": "McSorley, Joseph, C.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5844,
      "name": "McSweeney, Alan, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5845,
      "name": "Mctaggart, Ellis & Mctaggart, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5846,
      "name": "McWilliam R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5847,
      "name": "Meadors, Edward P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5848,
      "name": "Meadowcroft, Tim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5849,
      "name": "Mechow, Karl Benno Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5850,
      "name": "Medebielle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5851,
      "name": "Medom, Noulezhalie Ricky",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5852,
      "name": "Mee, Erine B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5853,
      "name": "Meehan, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5854,
      "name": "Meerseman, P.G., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5855,
      "name": "Meherally, Yusuf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5856,
      "name": "Mehler Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5857,
      "name": "Mehrotra, Arvind Krishna [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5858,
      "name": "Mehta, Gangaprasada",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5859,
      "name": "Mehta, Prithvisingh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5860,
      "name": "Meiklejohn, J.M.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5861,
      "name": "Meilaender, Gilbert [ed.] & Werpehowski, William [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5862,
      "name": "Meinertz, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5863,
      "name": "Melage, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5864,
      "name": "Melchers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5865,
      "name": "Mellet M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5866,
      "name": "Melot D. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5867,
      "name": "Melville, G.J. Whyte",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5868,
      "name": "Melville, Helen & Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5869,
      "name": "Menachery, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5870,
      "name": "Mendez Gaite Ramon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5871,
      "name": "Mendigal, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5872,
      "name": "Mendonca, Clements",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5873,
      "name": "Mendonca, Delio De, S.J. (1958-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5874,
      "name": "Mendonca, Delio De, S.J. (1958-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5875,
      "name": "Menezes, Norbert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5876,
      "name": "Menochius Joannes Stephanus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5877,
      "name": "Menochius, Joannes Stephanus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5878,
      "name": "Menon T. Madhava [Ed.] Tyagi Deepak [Ed.] & Kulirani B. Francis [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5879,
      "name": "Menon, K.P. Padmanabha & Menon, Krishna T.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5880,
      "name": "Menzies, Allan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5881,
      "name": "Menzies, Stuart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5882,
      "name": "Mercenier, F. & Paris, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5883,
      "name": "Mercier Desire-Joseph Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5884,
      "name": "Mercier Jean L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5885,
      "name": "Mercier, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5886,
      "name": "Mercier, Desire-Joseph, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5887,
      "name": "Mercier, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5888,
      "name": "Mercier, Jean L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5889,
      "name": "Meredith, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5890,
      "name": "Meric Elie & Ringer J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5891,
      "name": "Merivale, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5892,
      "name": "Merk, Augustinus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5893,
      "name": "Merk, Augustinus, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5894,
      "name": "Merkelbach Benedictus H. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5895,
      "name": "Merkelbach, Benedictus H., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5896,
      "name": "Merkle Sebastianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5897,
      "name": "Merrick D.A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5898,
      "name": "Merriman, Henry Seton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5899,
      "name": "Mersch Emile S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5900,
      "name": "Mersch, Emile, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5901,
      "name": "Mersch, Emile, S.J., Lemaitre, Ch. & Mativa, Adrien, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5902,
      "name": "Merton, Reginald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5903,
      "name": "Merx, Adalbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5904,
      "name": "Meschler Moritz S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5905,
      "name": "Meschler Moritz S.J. & Levaux John S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5906,
      "name": "Meschler Moritz S.J. & Margaret Mary O.S.B. Sr. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5907,
      "name": "Meschler, Moritz, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5908,
      "name": "Messenger, E.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5909,
      "name": "Messina Giuseppe S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5910,
      "name": "Messmer, Joseph, Mgr. & Waitz, Sigismund Bshp",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5911,
      "name": "Messner, Johannes & Wheately, Sheila [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5912,
      "name": "Mestdagh, L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5913,
      "name": "Methodius, Olympius, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5914,
      "name": "Mets Seb. M. De O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5915,
      "name": "Metzger, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5916,
      "name": "Meunier O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5917,
      "name": "Meunier, Mario",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5918,
      "name": "Meyenberg A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5919,
      "name": "Meyendorff, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5920,
      "name": "Meyer Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5921,
      "name": "Meyer R.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5922,
      "name": "Meyer Theodorus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5923,
      "name": "Meyer, Arnold Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5924,
      "name": "Meyer, Fulgence, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5925,
      "name": "Meyer, Hans & Eckhoff, Frederic [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5926,
      "name": "Meyer, R.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5927,
      "name": "Meyer, T.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5928,
      "name": "Meyer, Wendelin O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5929,
      "name": "Meyerson, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5930,
      "name": "Meynard, Andre-Marie, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5931,
      "name": "Meynkens C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5932,
      "name": "Meynkens C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5933,
      "name": "Meyouhas, Joseph & Levi, Victor N. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5934,
      "name": "Mezard Denys O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5935,
      "name": "Miaskiewicz, Francis Sigismund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5936,
      "name": "Mibang, Tamo (1955-  ) [ed.] & Behera, M.C. (1959-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5937,
      "name": "Michael Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5938,
      "name": "Michaelis, C.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5939,
      "name": "Michaelis, John David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5940,
      "name": "Michalak-Pikulska, Barbara [ed.] & Pikulski, A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5941,
      "name": "Michel A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5942,
      "name": "Michel L. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5943,
      "name": "Michel, Virgil, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5944,
      "name": "Micheletti A.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5945,
      "name": "Michell, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5946,
      "name": "Michels, Thomas A., O.S.B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5947,
      "name": "Michelsen Edw. H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5948,
      "name": "Michiels Gommarus O.M.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5949,
      "name": "Michonneau",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5950,
      "name": "Micklem, Nathaniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5951,
      "name": "Micklem, P.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5952,
      "name": "Migne Jacques Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5953,
      "name": "Migne, Jacques Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5954,
      "name": "Mignon A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5955,
      "name": "Mignon, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5956,
      "name": "Mignot Eugene Irenee Archev.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5957,
      "name": "Miguel de la Purificacion O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5958,
      "name": "Mike, Paul, S.J. & Irudayam, Aloysius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5959,
      "name": "Miles, Eustace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5960,
      "name": "Miles, Farell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5961,
      "name": "Miles, William F.S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5962,
      "name": "Mill, Hugh Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5963,
      "name": "Mill, John Stuart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5964,
      "name": "Mill, John Stuart & Cohen, Marshall [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5965,
      "name": "Mill, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5966,
      "name": "Millard, Benjamin A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5967,
      "name": "Miller B.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5968,
      "name": "Miller Joannes S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5969,
      "name": "Miller, John W. (1926-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5970,
      "name": "Miller, Madelene S. & Miller, J. Lane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5971,
      "name": "Miller, Michael J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5972,
      "name": "Millet Jacques S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5973,
      "name": "Millet, Jacques, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5974,
      "name": "Millhouse, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5975,
      "name": "Millhouse, John & Bracciforti",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5976,
      "name": "Milligan, William J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5977,
      "name": "Mills, Mary E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5978,
      "name": "Milne, H.J.M. & Skeat, T.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5979,
      "name": "Milne, William C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5980,
      "name": "Milner John Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5981,
      "name": "Milton John & Harrox Lewis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5982,
      "name": "Milton, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5983,
      "name": "Milton, John & Arber, Edward [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5984,
      "name": "Milton, John & Bell, Will [introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5985,
      "name": "Milton, John & Browne, R.C. [ed. & introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5986,
      "name": "Milton, John & Macmillan, Michael [ed. & introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5987,
      "name": "Milton, John & Rossetti, W.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5988,
      "name": "Ming John J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5989,
      "name": "Minto, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5990,
      "name": "Minucius Felix Marcus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5991,
      "name": "Minz I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5992,
      "name": "Miramar Aloys",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5993,
      "name": "Mirbt, D. Carl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5994,
      "name": "Misciatelli, Piero & Roberts, Peters [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5995,
      "name": "Misermont, Lucien, C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5996,
      "name": "Mises, Ludwig von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5997,
      "name": "Mises, Ludwig von & Kahane, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5998,
      "name": "Misra P.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 5999,
      "name": "Misra, Pankaj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6000,
      "name": "Misra, Ramdahin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6001,
      "name": "Misra, Syambihari & Misra, Sukadevabihari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6002,
      "name": "Misson, Jules, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6003,
      "name": "Mistry Malika Babasaheb",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6004,
      "name": "Mital, Surendra Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6005,
      "name": "Mitchel, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6006,
      "name": "Mitchell Gladys",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6007,
      "name": "Mitchell, Hinckley G., Smith, John Merlin Powis & Bewer, Julius A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6008,
      "name": "Mitchell, P. Chaimers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6009,
      "name": "Mitra, N.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6010,
      "name": "Mitra, N.B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6011,
      "name": "Mitra, Premendra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6012,
      "name": "Mivart St. George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6013,
      "name": "Mlakuzhyil, George, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6014,
      "name": "Moberly, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6015,
      "name": "Mocchegiani Petro O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6016,
      "name": "Modi Jivanji Jamshedji",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6017,
      "name": "Moeller N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6018,
      "name": "Moeller, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6019,
      "name": "Moffatt John E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6020,
      "name": "Moffatt, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6021,
      "name": "Moghissi, Haideh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6022,
      "name": "Mohanty Bidyut [ed.] Kumar Girish [ed.] Roy Joya [ed.] & Gupta Samita [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6023,
      "name": "Mohanty J.N. & Gupta Bina [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6024,
      "name": "Mohanty, J.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6025,
      "name": "Mohler Johann Adam (1796-1838) & Cohen Jean [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6026,
      "name": "Mohler, Johann Adam (1796-1838)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6027,
      "name": "Mohr, Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6028,
      "name": "Moidrey, J.De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6029,
      "name": "Molanus Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6030,
      "name": "Mole A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6031,
      "name": "Moliere (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6032,
      "name": "Molina Ludovicus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6033,
      "name": "Molitor, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6034,
      "name": "Moll Albert & Hopkirk Arthur F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6035,
      "name": "Mollat, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6036,
      "name": "Moloney Francis James SDB (1940-  ) & Harrington Daniel J. S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6037,
      "name": "Moltmann, Jurgen & Kohl, Margaret [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6038,
      "name": "Mommsen, Theodor & Dickson, William P., Rev. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6039,
      "name": "Monceaux Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6040,
      "name": "Monceaux Paul & Sheed F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6041,
      "name": "Monceaux, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6042,
      "name": "Monckton, C.A.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6043,
      "name": "Monier P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6044,
      "name": "Monier, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6045,
      "name": "Monin Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6046,
      "name": "Monkhouse, Allan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6047,
      "name": "Monlaur, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6048,
      "name": "Monmarche, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6049,
      "name": "Monmarche, M. & Tillion, E.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6050,
      "name": "Monnin Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6051,
      "name": "Monro, Margaret T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6052,
      "name": "Monsabre J.-M.-L. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6053,
      "name": "Monserrate, Anthony, S.J. & Hosten, H., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6054,
      "name": "Montagu, Lady Mary Wortley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6055,
      "name": "Montaigne, Michel Eyquem de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6056,
      "name": "Montaigne, Michel Eyquem de & Florio, John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6057,
      "name": "Montalban, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6058,
      "name": "Montandon George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6059,
      "name": "Montcheuil, Yves de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6060,
      "name": "Monteils, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6061,
      "name": "Monteith, Mary E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6062,
      "name": "Montesquieu, Charles De Secondat, Baron de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6063,
      "name": "Montesquieu, Charles De Secondat, Baron De La Brede Et De & Nugent, Thomas [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6064,
      "name": "Montessori Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6065,
      "name": "Montfort Louis-Marie Grignon de S.M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6066,
      "name": "Montfort Louis-Marie Grignon de S.M.M. Bx.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6067,
      "name": "Montfort, Louis-Marie Grignon de, Bx.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6068,
      "name": "Montfort, Louis-Marie Grignon De, S.M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6069,
      "name": "Montfort, Louis-Marie Grignon De, S.M.M. & Faber, Frederick William, C. Orat. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6070,
      "name": "Montfort, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6071,
      "name": "Montgomery H.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6072,
      "name": "Montgomery, James A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6073,
      "name": "Montini, Giovanni Battista, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6074,
      "name": "Monypenny, William Flavelle & Buckle, George Barla",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6075,
      "name": "Moo Douglas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6076,
      "name": "Moody, D.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6077,
      "name": "Moody, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6078,
      "name": "Moore Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6079,
      "name": "Moore, F. Frankfort",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6080,
      "name": "Moore, George F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6081,
      "name": "Moore, Sebastian, O.S.B. & McCarthy, Stephen [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6082,
      "name": "Moore, Thomas H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6083,
      "name": "Moore, Thomas Verner, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6084,
      "name": "Moorman, John R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6085,
      "name": "Mootheden, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6086,
      "name": "More, Thomas, St. (1478-1535)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6087,
      "name": "More, Thomas, St. (1478-1535) & Rogers, E.F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6088,
      "name": "Moreau Edouard de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6089,
      "name": "Moreau, Edouard De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6090,
      "name": "Moreau, Hegesippe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6091,
      "name": "Moreau-Vauthier, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6092,
      "name": "Morel Jules Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6093,
      "name": "Moret, Alexandre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6094,
      "name": "Moretti Aloisius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6095,
      "name": "Moreux Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6096,
      "name": "Moreux, Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6097,
      "name": "Morgan G. Campbell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6098,
      "name": "Morgan, C. Lloyd",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6099,
      "name": "Morgan, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6100,
      "name": "Morgan, Edwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6101,
      "name": "Morgan, William De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6102,
      "name": "Morgenthaler, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6103,
      "name": "Morgenthau, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6104,
      "name": "Morice Henri & Leahy E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6105,
      "name": "Morice, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6106,
      "name": "Morin, Germain, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6107,
      "name": "Morineau B.-M. S.M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6108,
      "name": "Morison, E.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6109,
      "name": "Morison, James Cotter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6110,
      "name": "Morissette Herve C.S.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6111,
      "name": "Morley Henry [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6112,
      "name": "Morley John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6113,
      "name": "Morley, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6114,
      "name": "Morley, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6115,
      "name": "Morley, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6116,
      "name": "Mornand, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6117,
      "name": "Morneau Robert F. (1938-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6118,
      "name": "Morrell David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6119,
      "name": "Morris C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6120,
      "name": "Morris Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6121,
      "name": "Morris John S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6122,
      "name": "Morris John S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6123,
      "name": "Morris William Bullen C. Orat. [comm.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6124,
      "name": "Morris, Jeremy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6125,
      "name": "Morris, Jeremy [ed.] & Sagovsky, Nicholas [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6126,
      "name": "Morris, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6127,
      "name": "Morrison Bakewell S.J. & Rueve Stephen J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6128,
      "name": "Mors, Josephus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6129,
      "name": "Morsdorf, Klaus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6130,
      "name": "Mortier D.A. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6131,
      "name": "Mortier, Robrecht, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6132,
      "name": "Mortimer, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6133,
      "name": "Morton, H.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6134,
      "name": "Morwood Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6135,
      "name": "Mosane, Philippe (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6136,
      "name": "Mosconi Tonino & Berardi Cristiana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6137,
      "name": "Mosheim, John Lawrence & Maclaine, Archibald [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6138,
      "name": "Moss, Geoffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6139,
      "name": "Mostyn, Trevor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6140,
      "name": "Motais Al. Congr. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6141,
      "name": "Motry, Hubert Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6142,
      "name": "Moule, H.C.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6143,
      "name": "Moulton James Hope",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6144,
      "name": "Moulton, James Hope & Milligan, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6145,
      "name": "Moulton, W. F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6146,
      "name": "Mouroux, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6147,
      "name": "Mourret, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6148,
      "name": "Mouvet Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6149,
      "name": "Mowen, John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6150,
      "name": "Mowvley, Harry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6151,
      "name": "Mozley, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6152,
      "name": "Mpungu Muzinga, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6153,
      "name": "Mudge, Lewis Seymour",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6154,
      "name": "Mugnier Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6155,
      "name": "Mugnier, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6156,
      "name": "Muir, Ramsay & Philip, George (1976-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6157,
      "name": "Muir, Ramsay; Philip, George (1976-  ) & Mcelroy, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6158,
      "name": "Muirhead, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6159,
      "name": "Muirhead, J.H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6160,
      "name": "Mujib, Muhammad, Wasey, Akhtarul [ed.] & Ehsas, Farhat [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6161,
      "name": "Mukadam G.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6162,
      "name": "Mukhopadhyay Ruchira",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6163,
      "name": "Mukhopadhyay, Damodar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6164,
      "name": "Mukhopadhyay, Dhangopal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6165,
      "name": "Mukhopadhyay, H.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6166,
      "name": "Mukhopadhyay, Jibon Krsna & Kisku, Kalu [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6167,
      "name": "Mukhopadhyay, Partha Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6168,
      "name": "Mukhopadhyay, Prabhat Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6169,
      "name": "Mukhopadhyaya, Prabhatakumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6170,
      "name": "Mukhopadhyaya, Prabhatakumara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6171,
      "name": "Mukhopadhyaya, Radha Kamala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6172,
      "name": "Mukonyora Isabel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6173,
      "name": "Mukundan, M. & Jayakumar, Prema [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6174,
      "name": "Mulders Alphonse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6175,
      "name": "Mulford Clarence Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6176,
      "name": "Mullan Elder S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6177,
      "name": "Muller Albert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6178,
      "name": "Muller John Baptist S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6179,
      "name": "Muller, Albert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6180,
      "name": "Muller, F. Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6181,
      "name": "Muller, Ferd. Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6182,
      "name": "Muller, Karl, SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6183,
      "name": "Muller, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6184,
      "name": "Muller, Michael, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6185,
      "name": "Muller-Ahremberg, Erich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6186,
      "name": "Muller-Fahrenholz, Geiko",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6187,
      "name": "Muller-Freienfels Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6188,
      "name": "Mullie, Joseph, C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6189,
      "name": "Mullins, Patrick Jerome, Sister, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6190,
      "name": "Mumford James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6191,
      "name": "Mumford, James, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6192,
      "name": "Muncey, R.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6193,
      "name": "Muncunill, Joannes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6194,
      "name": "Mundakel, T.T., Blake, Victor [transl.] & Blake, Gretta [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6195,
      "name": "Munier, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6196,
      "name": "Munk, Hans J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6197,
      "name": "Munro Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6198,
      "name": "Munro, N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6199,
      "name": "Munsi, Kanaiyalal Maneklal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6200,
      "name": "Munsterberg, Hugo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6201,
      "name": "Mura, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6202,
      "name": "Muratori, Ludovicus Antonius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6203,
      "name": "Murawski, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6204,
      "name": "Murdoch, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6205,
      "name": "Muret-Sanders",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6206,
      "name": "Murphy Dennis J. M.S.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6207,
      "name": "Murphy J.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6208,
      "name": "Murphy John P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6209,
      "name": "Murphy, Charles K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6210,
      "name": "Murphy, D., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6211,
      "name": "Murphy, Francis X., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6212,
      "name": "Murray, Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6213,
      "name": "Murray, David Christie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6214,
      "name": "Murray, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6215,
      "name": "Murray, Lorna H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6216,
      "name": "Murray, Patricius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6217,
      "name": "Murray, Rosalind",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6218,
      "name": "Murry, J. Middleton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6219,
      "name": "Murtagh, James G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6220,
      "name": "Murti, Ranjani K. & Sankaran, Lakshmi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6221,
      "name": "Musset, Alfred De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6222,
      "name": "Mussolini, Benito",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6223,
      "name": "Mustafa, Faizan & Sarma, Anurag",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6224,
      "name": "Mutahhari Ayatullah Murtaza & Campbell R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6225,
      "name": "Muzzarelli, Fridericus, S.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6226,
      "name": "Myers Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6227,
      "name": "Myers, Denys P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6228,
      "name": "Myers, F.W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6229,
      "name": "Myers, Michael Warren",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6230,
      "name": "NA",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6231,
      "name": "Nadaillac Mis. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6232,
      "name": "Nadal Hieronymus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6233,
      "name": "Nagla, B.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6234,
      "name": "Naidu P.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6235,
      "name": "Naik Pundalik N. & Pai Vidya [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6236,
      "name": "Nairne, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6237,
      "name": "Naisbitt John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6238,
      "name": "Naish V. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6239,
      "name": "Nakamura, Hajime",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6240,
      "name": "Nalunnakkal,George Mathew [ed.] & Athyal, Abraham P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6241,
      "name": "Nambiaparambil Albert CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6242,
      "name": "Nambisan Vijay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6243,
      "name": "Namier, L.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6244,
      "name": "Nanda, Reena",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6245,
      "name": "Nandikesvara & Ghos [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6246,
      "name": "Nandy Ashis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6247,
      "name": "Nandy, Ashis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6248,
      "name": "Nanjunda D.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6249,
      "name": "Narain Brij",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6250,
      "name": "Narang A.S. (Ed.) & Srivastava Pramila (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6251,
      "name": "Narayan, Baldev",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6252,
      "name": "Narayan, J. Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6253,
      "name": "Narayana, E.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6254,
      "name": "Narayanan Renuka",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6255,
      "name": "Narayanan, Renuka",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6256,
      "name": "Nardi, Isabella (1970-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6257,
      "name": "Nash, H.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6258,
      "name": "Natale, Antonio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6259,
      "name": "Nau, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6260,
      "name": "Naughton, James W., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6261,
      "name": "Nautin, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6262,
      "name": "Naval Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6263,
      "name": "Navarangi, Peter Santi, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6264,
      "name": "Navavati, Manilal B. & Anjaria, J.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6265,
      "name": "Naville, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6266,
      "name": "Nayak, Ramesh Chandra & Kujur, Joseph Marianus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6267,
      "name": "Nayyar, Deepak [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6268,
      "name": "Naz, Raoul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6269,
      "name": "Nazir-Ali, Michael & Stone, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6270,
      "name": "Neale, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6271,
      "name": "Neale, J.M. & Forbes, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6272,
      "name": "Neander, Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6273,
      "name": "Nearing, Scott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6274,
      "name": "Nearing, Scott & Nellie, M.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6275,
      "name": "Nediani, Tommaso",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6276,
      "name": "Nedoncelle Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6277,
      "name": "Nedoncelle, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6278,
      "name": "Nedoncelle, Maurice & Vernon, M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6279,
      "name": "Nedungatt George S.J. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6280,
      "name": "Nedungatt, George, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6281,
      "name": "Neele, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6282,
      "name": "Nehru Jawaharlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6283,
      "name": "Nehru, Jawaharlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6284,
      "name": "Nehru, Jawaharlal & Upadhyaya, Haribhai [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6285,
      "name": "Neill, Stephen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6286,
      "name": "Nell-Breuning, Oswald von, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6287,
      "name": "Nepveu, Francois, S.J. & Wilmot, A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6288,
      "name": "Nerius, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6289,
      "name": "Nervegna Josephus Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6290,
      "name": "Nesfield J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6291,
      "name": "Nesfield, J.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6292,
      "name": "Nestle Eberhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6293,
      "name": "Nestle Eberhard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6294,
      "name": "Nestle, Eberhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6295,
      "name": "Nestle, Eberhard, Aland, Kurt & Nestle, Erwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6296,
      "name": "Netter, Nathan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6297,
      "name": "Neubert E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6298,
      "name": "Neudecker, Reinhard, S.J. (1938-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6299,
      "name": "Neumayr Franciscus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6300,
      "name": "Neumayr Franz S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6301,
      "name": "Neumayr, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6302,
      "name": "Neut Amand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6303,
      "name": "Neuville Charles Frey de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6304,
      "name": "Nevill Lady Dorothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6305,
      "name": "Newcomb, Covelle",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6306,
      "name": "Newcome, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6307,
      "name": "Newlands, George M. (1941- )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6308,
      "name": "Newman John Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6309,
      "name": "Newman, Francis William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6310,
      "name": "Newman, John Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6311,
      "name": "Newnham, W.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6312,
      "name": "Newton, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6313,
      "name": "Neyrand, Jos., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6314,
      "name": "Nicetas Stethatos & Chalendard, Marie [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6315,
      "name": "Nichol, Francis D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6316,
      "name": "Nichol, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6317,
      "name": "Nicholas, Beverley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6318,
      "name": "Nicholl, Donald & Hastings, Adrian [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6319,
      "name": "Nichols, Beverley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6320,
      "name": "Nicholson, J.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6321,
      "name": "Nickell, Stephen J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6322,
      "name": "Nicolaus, Cabasila & Salaville, S., A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6323,
      "name": "Nicolay Fernand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6324,
      "name": "Nicolay, Jean de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6325,
      "name": "Nicolini G.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6326,
      "name": "Nicolini, G.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6327,
      "name": "Nicoll, W. Robertson [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6328,
      "name": "Niebuhr, Reinhold (1892-1971)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6329,
      "name": "Niebuhr, Reinhold (1892-1971) & Brown, Robert McAfee (1920- ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6330,
      "name": "Niebuhr, Reinhold (1892-1971) & Niebuhr, Ursula M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6331,
      "name": "Niemoller, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6332,
      "name": "Nieremberg Joannes Eusebius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6333,
      "name": "Nieremberg, Joannes Eusebius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6334,
      "name": "Nietzsche, Friedrich & Tille, A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6335,
      "name": "Nietzsche, Friedrich, Kaufmann, Walter [transl.] & Hollingdale, R.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6336,
      "name": "Nieuwbarn M.C. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6337,
      "name": "Nigam, Shalu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6338,
      "name": "Nikolaus von Cues, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6339,
      "name": "Nilles Nicolaus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6340,
      "name": "Nilson, Martin P. & Fielden, F.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6341,
      "name": "Nirvedananda, Swami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6342,
      "name": "Nisard D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6343,
      "name": "Niskanen, Paul V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6344,
      "name": "Nist, James & Girardey, Ferreol, C.SS.R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6345,
      "name": "Nizami, Khaliq Ahmad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6346,
      "name": "Noaillat-Ponvert, Simone De & Donnelly, Mary Golden [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6347,
      "name": "Noble H.D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6348,
      "name": "Noble, H.D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6349,
      "name": "Noce, Carla, Pampaloni, Massimo, S.J. & Tavolieri, Claudia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6350,
      "name": "Nock, Arthur Darby",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6351,
      "name": "Noel, Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6352,
      "name": "Noire, Ludwig",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6353,
      "name": "Noldeke, Theodor & Crichton, James A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6354,
      "name": "Noldin H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6355,
      "name": "Noldin, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6356,
      "name": "Noll, John F., Bshp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6357,
      "name": "Nolle, Lambert, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6358,
      "name": "Nonell Jacobus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6359,
      "name": "Nonell Jaime S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6360,
      "name": "Nonell, Jaime, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6361,
      "name": "Nongsiej, T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6362,
      "name": "Noorani, A.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6363,
      "name": "Noratto Gutierrez, Jose Alfredo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6364,
      "name": "Norbert, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6365,
      "name": "Nordmann Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6366,
      "name": "Norman George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6367,
      "name": "Norman, G.A.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6368,
      "name": "Norman, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6369,
      "name": "Norris, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6370,
      "name": "Norris, Frank L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6371,
      "name": "Norris, John Pilkington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6372,
      "name": "Norris, Kathleen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6373,
      "name": "North, Robert G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6374,
      "name": "Northcote J. Spencer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6375,
      "name": "Notebaert, Urbaan, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6376,
      "name": "Nothomb Jacques S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6377,
      "name": "Nothomb, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6378,
      "name": "Notovitch, Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6379,
      "name": "Nouet, Jacques, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6380,
      "name": "Nouwen Henri J.M. (1932-  ) & O'Laughlin Michael [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6381,
      "name": "Nouwen, Henri J.M. (1932-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6382,
      "name": "Nouwen, Henri J.M. (1932-  ) & Greer, Wendy Wilson [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6383,
      "name": "Noval, Josephus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6384,
      "name": "Nowell Irene OSB (1940-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6385,
      "name": "Noyes Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6386,
      "name": "Noyes, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6387,
      "name": "Nuh V.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6388,
      "name": "Nuh, V.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6389,
      "name": "Nuh, V.k. & Lasuh, Wetshokhrolo [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6390,
      "name": "Nunn, H.P.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6391,
      "name": "Nurbakhsh Javad (1927-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6392,
      "name": "Nurser John (1929-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6393,
      "name": "Nyessen D.J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6394,
      "name": "Nygren, Anders",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6395,
      "name": "Nys Desire",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6396,
      "name": "Oakeley Frederick.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6397,
      "name": "Oakeshott, Michael Joseph (1901-1990)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6398,
      "name": "Oates Frank & Oates C.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6399,
      "name": "Oates, Whitney J. [ed.] & O'Neill, Eugene, Jr. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6400,
      "name": "Oberhammer Clement",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6401,
      "name": "Oberhammer, Gerhard & Amaladass, Anand, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6402,
      "name": "Oberthur, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6403,
      "name": "Obertynski Sdislaus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6404,
      "name": "O'Brien John A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6405,
      "name": "O'Brien Kevin J. C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6406,
      "name": "O'Brien Mary Consilia O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6407,
      "name": "O'Brien, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6408,
      "name": "O'Brien, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6409,
      "name": "O'Brien, Edward [ed.] & Cournos, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6410,
      "name": "O'Brien, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6411,
      "name": "O'Brien, Isidore, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6412,
      "name": "O'Brien, John A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6413,
      "name": "O'Brien, Joseph D., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6414,
      "name": "O'Brien, Mary Consilia, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6415,
      "name": "O'brien, Richard Baptist",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6416,
      "name": "Obsterley W.O.E. & Robinson, Theodore H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6417,
      "name": "O'Byrne, Patrick Justin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6418,
      "name": "O'Connell, Daniel C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6419,
      "name": "O'Connell, J. Byrne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6420,
      "name": "O'Connor Jerome F. S.J.. & Hayden William S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6421,
      "name": "O'Connor, William R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6422,
      "name": "O'Conor, J.F.X., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6423,
      "name": "O'Dannell, Brian & Hellwig, Elizabeth [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6424,
      "name": "Odelin Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6425,
      "name": "Odin, Jaishree K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6426,
      "name": "O'Donnell Thomas  J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6427,
      "name": "O'Donnell, Elliot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6428,
      "name": "O'Donnell, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6429,
      "name": "O'Donnell, Thomas  J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6430,
      "name": "O'Dowd W.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6431,
      "name": "Oduyoye Mercy Amba [ed.] & Kanyoro Musimbi R.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6432,
      "name": "Oesterley W.O.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6433,
      "name": "Oesterley, W.O.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6434,
      "name": "Oesterley, W.O.E. & Box, F.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6435,
      "name": "Oesterley, W.O.E. & Robinson, Theodore H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6436,
      "name": "O'Flaherty, Liam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6437,
      "name": "Ogara, Florentino, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6438,
      "name": "Ogden, C.K., Richards, I.A. & Myers, Adolph [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6439,
      "name": "Ogden, Steven G. (1955-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6440,
      "name": "Ogg, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6441,
      "name": "O'Gorman, Bob & Faulkner, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6442,
      "name": "O'Grady Ron",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6443,
      "name": "O'Grady Ron [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6444,
      "name": "Ohnet, Geroges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6445,
      "name": "Ojetti Benedictus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6446,
      "name": "O'Kane, Bernard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6447,
      "name": "O'Kane, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6448,
      "name": "O'Kane, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6449,
      "name": "O'Keefe, Mark, O.S.B. (1956-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6450,
      "name": "Okuma Peter Chidi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6451,
      "name": "O'Laverty H..",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6452,
      "name": "Oldenbourg R.-M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6453,
      "name": "Oldenburg, Veena Talwar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6454,
      "name": "Oldham, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6455,
      "name": "O'Leary Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6456,
      "name": "O'leary, C.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6457,
      "name": "O'Leary, De Lacy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6458,
      "name": "O'Leary, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6459,
      "name": "Olichon, Armand, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6460,
      "name": "Olier, J.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6461,
      "name": "Oliphant, Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6462,
      "name": "Olivaint Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6463,
      "name": "Olivelle, Patrick [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6464,
      "name": "Oliver, Alexandre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6465,
      "name": "Olivera George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6466,
      "name": "Olivier Karel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6467,
      "name": "Olivier V.L. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6468,
      "name": "Olle-Laprune Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6469,
      "name": "Olle-Laprune, Alice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6470,
      "name": "Ollivier M.J. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6471,
      "name": "Olmstead, A.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6472,
      "name": "Olsnbruzz Aloysius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6473,
      "name": "Olson, Carl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6474,
      "name": "O'Malley Austin & Walsh James J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6475,
      "name": "O'Malley, Austin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6476,
      "name": "Oman Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6477,
      "name": "Oman, John Campbell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6478,
      "name": "Omar, Irfan A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6479,
      "name": "O'Meara Kathleen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6480,
      "name": "Ommanney, G.D.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6481,
      "name": "Omont, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6482,
      "name": "O'Murchu Diarmuid MSC",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6483,
      "name": "Onclair Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6484,
      "name": "O'Neili, Charles E. [ed.] & Dominguez, Joaquin M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6485,
      "name": "O'Neill Arthur Barry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6486,
      "name": "O'Neill, Francis Joseph, C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6487,
      "name": "Onwuka, Peter Chidolue",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6488,
      "name": "Oommen, George [ed.] & Webster, John C.B. (1935-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6489,
      "name": "Oppenheim, E. Phillips",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6490,
      "name": "Optatus Milevitanus Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6491,
      "name": "O'Rahilly, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6492,
      "name": "Oraon Karma (1952-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6493,
      "name": "Oraon, Narayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6494,
      "name": "Orczy, Emmuska",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6495,
      "name": "Ordhendra C. Gangoly [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6496,
      "name": "O'Reil, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6497,
      "name": "O'Rell, Max [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6498,
      "name": "Origenes & Crombie, Frederick [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6499,
      "name": "Origenes, Doutreleau, Louis [trad.] & Lubac, Henri De, S.J. (1896-  ) [trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6500,
      "name": "Orlandini, Nicolaus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6501,
      "name": "Orme, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6502,
      "name": "Ormerod, Neil J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6503,
      "name": "Ormsby, A.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6504,
      "name": "O'Rourke, John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6505,
      "name": "Orsini Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6506,
      "name": "Orsy Ladislas M. S.J. (1921-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6507,
      "name": "Ortega y Gasset, Jose & Trask, Willard R. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6508,
      "name": "Ortega, Joannes O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6509,
      "name": "Ortegat Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6510,
      "name": "Ortiz De Urbina I. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6511,
      "name": "Orton, C.W. Previte",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6512,
      "name": "Osborn Henry Fairfield",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6513,
      "name": "Osborn, Lawrence [ed.] & Walker, Andrew [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6514,
      "name": "Osborne, Kenan B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6515,
      "name": "Osborne, Thomas Mott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6516,
      "name": "Osiek, Carolyn, Rscj; MacDonald, Margaret Y. & Tulloch, Janet H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6517,
      "name": "Osorius, Hieronymus, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6518,
      "name": "Ossendowski, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6519,
      "name": "Ostrogorski, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6520,
      "name": "Osty, E., P.S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6521,
      "name": "Oswald Augustinus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6522,
      "name": "Oswalt John N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6523,
      "name": "O'Toole, George Barry [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6524,
      "name": "Ottaviani Alaphridus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6525,
      "name": "Ottaviano, Carmelo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6526,
      "name": "Otten Bernardus J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6527,
      "name": "Ottley, Richard R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6528,
      "name": "Ottley, Robert L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6529,
      "name": "Ottmann, Nina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6530,
      "name": "Otto, Emil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6531,
      "name": "Otto, Joseph Albert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6532,
      "name": "Otto, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6533,
      "name": "Otto, Rudolf & Lunn, Brian [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6534,
      "name": "Oudot, Ioannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6535,
      "name": "Oursler Fulton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6536,
      "name": "Oursler, Fulton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6537,
      "name": "Ouseparampil, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6538,
      "name": "Overmans, Jakib",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6539,
      "name": "Overton, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6540,
      "name": "Ovidius, Publius Naso",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6541,
      "name": "Owen, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6542,
      "name": "Owen, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6543,
      "name": "Owens, John Joseph (1918-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6544,
      "name": "Oxbrow, Mark [ed.] & Garrow, Emma [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6545,
      "name": "Oxenham John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6546,
      "name": "Oxenham, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6547,
      "name": "Pacheu, Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6548,
      "name": "Pachomius Tabennensis Abbas Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6549,
      "name": "Pachtler G.M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6550,
      "name": "Pachuau, Lalsangkima",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6551,
      "name": "Padiyath, Thomas [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6552,
      "name": "Pagani, Giovanni Batista [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6553,
      "name": "Page, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6554,
      "name": "Page, Gertrude",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6555,
      "name": "Page, Sue",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6556,
      "name": "Page, T.E. & Walpole, A.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6557,
      "name": "Page, W. Sutton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6558,
      "name": "Pai, Anant",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6559,
      "name": "Pai, Rex A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6560,
      "name": "Paikada Mathew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6561,
      "name": "Painadath Sebastian S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6562,
      "name": "Painadath, Sebastian, S.J. [ed.] & Parappally, Jacob, MSFS [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6563,
      "name": "Paine, Thomas & Bonner, Hypatia Bradlaugh [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6564,
      "name": "Pakenham-Walsh, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6565,
      "name": "Pal, Manoj Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6566,
      "name": "Palachuvattil, Mathew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6567,
      "name": "Palanithurai, Ganapathy (1953-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6568,
      "name": "Palanithurai, Ganapathy (1953-  ) & Ragupathy, V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6569,
      "name": "Palanque Jean-Remy Bardy Gustave & Labriolle Pierre De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6570,
      "name": "Palatre, Gabriel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6571,
      "name": "Palau Gabriel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6572,
      "name": "Paley, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6573,
      "name": "Palgrave, R.H. Inglis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6574,
      "name": "Palhories F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6575,
      "name": "Palhories, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6576,
      "name": "Paliard Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6577,
      "name": "Palladius Helenopoleos Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6578,
      "name": "Pallas, P.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6579,
      "name": "Pallavicini Sforza S.J. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6580,
      "name": "Pallen, Conde B. & Wynne, John J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6581,
      "name": "Pallieri, Giorgio Balladore & Vismara, Giulio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6582,
      "name": "Pallottini Salvator",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6583,
      "name": "Palmer, Edwin James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6584,
      "name": "Palmer, R. Lidderdale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6585,
      "name": "Palmer, Ray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6586,
      "name": "Palmer, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6587,
      "name": "Palmieri Dominicus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6588,
      "name": "Palombo Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6589,
      "name": "Palvolsky, Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6590,
      "name": "Pande Govind Chandra (1923-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6591,
      "name": "Pande Govind Chandra (1923-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6592,
      "name": "Pande, Gopala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6593,
      "name": "Pande, Ramdin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6594,
      "name": "Pandey, Virendra K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6595,
      "name": "Pandian J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6596,
      "name": "Pandikattu, Kuruvilla, S.J. (1957-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6597,
      "name": "Pandya, Rameshwari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6598,
      "name": "Panici, P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6599,
      "name": "Panigrahy, Rajib Lochan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6600,
      "name": "Panikkar, K.M. (1894-1963)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6601,
      "name": "Panikkar, K.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6602,
      "name": "Panikkar, Raymond (1918-  ) & DiLascia, Alfred [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6603,
      "name": "Pannier E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6604,
      "name": "Pant Govindavallabh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6605,
      "name": "Pant, Govindavallabh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6606,
      "name": "Papini Giovanni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6607,
      "name": "Papini, Giovanni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6608,
      "name": "Papini, Giovanni & Curtayne, Alice [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6609,
      "name": "Papke, K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6610,
      "name": "Papken, Catholicos Gulesserian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6611,
      "name": "Paque Egidius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6612,
      "name": "Paquier J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6613,
      "name": "Parananda, Sri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6614,
      "name": "Parappally Jacob MSFS [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6615,
      "name": "Parappally, Jacob, MSFS [ed.] & Monteiro, Evelyn [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6616,
      "name": "Pare, Gerard, Brunet, A. & Tremblay, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6617,
      "name": "Parel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6618,
      "name": "Parent, J.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6619,
      "name": "Parente, Pascal P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6620,
      "name": "Parenti, Arnaldus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6621,
      "name": "Parikh, Kirit S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6622,
      "name": "Parikh, Nilam (1933-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6623,
      "name": "Paris Gerardus M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6624,
      "name": "Paris, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6625,
      "name": "Paris, Gerardus M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6626,
      "name": "Parish Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6627,
      "name": "Parish, Helen [ed.] & Naphy, William G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6628,
      "name": "Parisis, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6629,
      "name": "Park, Mungo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6630,
      "name": "Parker J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6631,
      "name": "Parker, T.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6632,
      "name": "Parker, Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6633,
      "name": "Parkhe, Camil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6634,
      "name": "Parkhurst, Helen Huss",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6635,
      "name": "Parkhurst, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6636,
      "name": "Parkinson Henry Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6637,
      "name": "Parkman, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6638,
      "name": "Parks M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6639,
      "name": "Parks, Mercedes Gallagher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6640,
      "name": "Parmelee, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6641,
      "name": "Parra Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6642,
      "name": "Parrish, J.M. & Crossland, J.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6643,
      "name": "Parry, Ken [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6644,
      "name": "Parry, Ken, Melling, David John, Brady, Dimitri & Griffith, Sidney H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6645,
      "name": "Parry, R. St. John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6646,
      "name": "Parskaracarya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6647,
      "name": "Parsons Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6648,
      "name": "Parsons Wilfrid S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6649,
      "name": "Parsons, Robert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6650,
      "name": "Partridge, Christopher H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6651,
      "name": "Parvez Zahid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6652,
      "name": "Pasanlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6653,
      "name": "Pascal Blaise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6654,
      "name": "Pascal, Blaise",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6655,
      "name": "Passaglia Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6656,
      "name": "Pastor, Ludwig, Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6657,
      "name": "Paswan, Sanjay [ed.] & Jaideva, Pramanshi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6658,
      "name": "Patel, Reena",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6659,
      "name": "Paterculus, Caius Velleius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6660,
      "name": "Pathak, R.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6661,
      "name": "Pathrapankal Joseph M. CMI (1930-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6662,
      "name": "Patil, V.T. & Gopal, D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6663,
      "name": "Patisz Georg S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6664,
      "name": "Patnaik Biju & Patil Shivraj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6665,
      "name": "Paton William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6666,
      "name": "Paton, Lewis B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6667,
      "name": "Patres Apostolici",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6668,
      "name": "Patrick, Denzil G.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6669,
      "name": "Patterson, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6670,
      "name": "Patterson, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6671,
      "name": "Patterson, Robert Leet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6672,
      "name": "Pattison, Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6673,
      "name": "Patton, John Hastings",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6674,
      "name": "Patton, Laurie L. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6675,
      "name": "Paul Cogan, Mary de, Sister",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6676,
      "name": "Paul Winifred Irene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6677,
      "name": "Paul, de la Croix-Meuse, O.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6678,
      "name": "Paul, M.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6679,
      "name": "Paul, O.M.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6680,
      "name": "Paul, O.M.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6681,
      "name": "Paul, Vincent de, St.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6682,
      "name": "Paul-Emile, S.G.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6683,
      "name": "Paulinus Pontius Meropius Nolanus Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6684,
      "name": "Paulus VI Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6685,
      "name": "Paulus, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6686,
      "name": "Pauwels Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6687,
      "name": "Pauwels P. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6688,
      "name": "Payen G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6689,
      "name": "Payen P.G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6690,
      "name": "Payen, G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6691,
      "name": "Payot Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6692,
      "name": "Payot, Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6693,
      "name": "Peake, Harold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6694,
      "name": "Peal Fred S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6695,
      "name": "Peal-Frederick S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6696,
      "name": "Peanus, Olemens Alexandrinus, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6697,
      "name": "Pearson, Edith",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6698,
      "name": "Pearson, Henry G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6699,
      "name": "Pearson, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6700,
      "name": "Peck, D.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6701,
      "name": "Pedahzur, Ami [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6702,
      "name": "Peder, Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6703,
      "name": "Peel, J.D.Y. (1941-2015)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6704,
      "name": "Peers, E. Allison",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6705,
      "name": "Peet, T. Eric",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6706,
      "name": "Peeters C. & Monsieurs J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6707,
      "name": "Peeters Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6708,
      "name": "Peeters Paul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6709,
      "name": "Peeters Paul S.J. & Michel Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6710,
      "name": "Peeters, Paul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6711,
      "name": "Peeters, Paul, S.J. & Michel, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6712,
      "name": "Peghaire, J., C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6713,
      "name": "Pegues Thomas O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6714,
      "name": "Pegues, Thomas, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6715,
      "name": "Peguy Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6716,
      "name": "Peguy Charles & Green Julian [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6717,
      "name": "Peguy, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6718,
      "name": "Peillaube E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6719,
      "name": "Pelham H.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6720,
      "name": "Pelikan, Jaroslav (1923-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6721,
      "name": "Pelland Lionello S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6722,
      "name": "Pelle, P., Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6723,
      "name": "Pelletier Victor Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6724,
      "name": "Pellico, Silvio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6725,
      "name": "Pellizari, Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6726,
      "name": "Peman, Jose Maria & De Blacam, Hugh [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6727,
      "name": "Pence, Owen E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6728,
      "name": "Pendered, Mary L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6729,
      "name": "Penido M.T.-L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6730,
      "name": "Penido, M.T.-L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6731,
      "name": "Penning W. Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6732,
      "name": "Penny W.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6733,
      "name": "Pepler Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6734,
      "name": "Perbal, Albert, O.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6735,
      "name": "Percival, Robert & Henry, P.F. [Trad.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6736,
      "name": "Percival, W.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6737,
      "name": "Percy, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6738,
      "name": "Percy, Ernst",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6739,
      "name": "Pereira De Britto Fernao",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6740,
      "name": "Pereira Jose",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6741,
      "name": "Pereira, C., S.J., Gafney, S.J. [ed.] & Yonjan, C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6742,
      "name": "Pereira, H.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6743,
      "name": "Pereira, Marlene [ed.] & Baltazar, Stella, FMM [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6744,
      "name": "Pereira, Vincent S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6745,
      "name": "Perera, S.G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6746,
      "name": "Peres, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6747,
      "name": "Pergmayr Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6748,
      "name": "Perinelle J. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6749,
      "name": "Perinelle, J., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6750,
      "name": "Perkins, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6751,
      "name": "Perles, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6752,
      "name": "Pernot Hubert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6753,
      "name": "Pernot, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6754,
      "name": "Perny, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6755,
      "name": "Peronne J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6756,
      "name": "Perowne, Stewart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6757,
      "name": "Perraudin, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6758,
      "name": "Perree, William, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6759,
      "name": "Perrin Elizabeth St. Marie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6760,
      "name": "Perrin, Alice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6761,
      "name": "Perrin, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6762,
      "name": "Perrin, Leonidas, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6763,
      "name": "Perris, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6764,
      "name": "Perrone Joannes S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6765,
      "name": "Perroy Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6766,
      "name": "Perry John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6767,
      "name": "Perry W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6768,
      "name": "Perry, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6769,
      "name": "Perry, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6770,
      "name": "Perry, W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6771,
      "name": "Persijn H. Denys A. & Noterdaeme J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6772,
      "name": "Perumalil, Augustine, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6773,
      "name": "Pesch Christianus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6774,
      "name": "Pesch Tilmann S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6775,
      "name": "Pesch, Christianus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6776,
      "name": "Pesci, Francis, O.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6777,
      "name": "Petavel J.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6778,
      "name": "Petavius Dionysius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6779,
      "name": "Petazzi, P.G., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6780,
      "name": "Peters, Norbert & Hulpiau, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6781,
      "name": "Peterson, Erik",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6782,
      "name": "Petin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6783,
      "name": "Petit Adolf S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6784,
      "name": "Petit Adolf S.J. & Lindsay Mariam [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6785,
      "name": "Petit de Julleville L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6786,
      "name": "Petit, Adolphe, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6787,
      "name": "Petitdidier Jean Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6788,
      "name": "Petit-Dutaillis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6789,
      "name": "Petit-Dutaillis & Guinard P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6790,
      "name": "Petitot Henry O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6791,
      "name": "Petitot, Henry, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6792,
      "name": "Petrani, Alexius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6793,
      "name": "Petre M.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6794,
      "name": "Petre, M.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6795,
      "name": "Petrovits, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6796,
      "name": "Pettazzoni, Raffaele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6797,
      "name": "Peultier, Etienne & Gantois, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6798,
      "name": "Pezzani Henricus Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6799,
      "name": "Pfeffer, Georg [ed.] & Behera, Deepak Kumar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6800,
      "name": "Pfeiffer, Franz & Evans, C.De B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6801,
      "name": "Pfleger Karl & Watkin E.I. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6802,
      "name": "Pfleiderer, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6803,
      "name": "Pfleiderer, Otto & Smith, Frederick [transl]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6804,
      "name": "Pfliegler, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6805,
      "name": "Pfulf Otto S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6806,
      "name": "Phaedrus & Chambers, R.H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6807,
      "name": "Phanos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6808,
      "name": "Phelan D.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6809,
      "name": "Phelps, Austin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6810,
      "name": "Philip A.A. & Murray H.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6811,
      "name": "Philip Mary Sister",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6812,
      "name": "Philip, T.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6813,
      "name": "Philipon, M.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6814,
      "name": "Philippe A. C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6815,
      "name": "Philippe, A., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6816,
      "name": "Philippus De Greve Cancellarius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6817,
      "name": "Phillips Stephen H. [Transl.] & Tatacarya Ramanuja [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6818,
      "name": "Phillips, A.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6819,
      "name": "Phillips, C.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6820,
      "name": "Phillips, John A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6821,
      "name": "Phillips, W. Allison",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6822,
      "name": "Phillpotts, Eden",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6823,
      "name": "Philo Judaeus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6824,
      "name": "Philo Judaeus Alexandrinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6825,
      "name": "Philomneste, G.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6826,
      "name": "Philpot, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6827,
      "name": "Phule, Jotirao (1827-1890) & Despande, G.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6828,
      "name": "Piacenza Petrus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6829,
      "name": "Pianciani G.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6830,
      "name": "Piat Clodius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6831,
      "name": "Piato F. O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6832,
      "name": "Picard, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6833,
      "name": "Picard, Gabriel, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6834,
      "name": "Picard, Herve",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6835,
      "name": "Picart, Jerome, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6836,
      "name": "Picavet, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6837,
      "name": "Piccirelli Josephus Maria S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6838,
      "name": "Pichler Vitus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6839,
      "name": "Pick, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6840,
      "name": "Picquigny Bernardin De O. Min. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6841,
      "name": "Picton, J. Allanson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6842,
      "name": "Pie de Langogne O.M.Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6843,
      "name": "Pieper, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6844,
      "name": "Pieron, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6845,
      "name": "Pierpont, Ivan de, S.J., Le Cocq, Victor, S.J. & Van Austen, Gregoire, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6846,
      "name": "Pierre Canisius De La T.S. Trinite, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6847,
      "name": "Pierre Hieromoine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6848,
      "name": "Pierre L'ermite (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6849,
      "name": "Pierse, Garrett",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6850,
      "name": "Piganiol, Andre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6851,
      "name": "Pigelet Rovert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6852,
      "name": "Pighi Joannes-Baptista",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6853,
      "name": "Pigou A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6854,
      "name": "Pigou, A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6855,
      "name": "Pimenta, Simon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6856,
      "name": "Pinard de la Boullaye Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6857,
      "name": "Pinard de la Boullaye, Henri, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6858,
      "name": "Pinart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6859,
      "name": "Pinelli Luc S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6860,
      "name": "Pinelli, Luc, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6861,
      "name": "Pinto, John J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6862,
      "name": "Pinto, Lawrence, MSIJ [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6863,
      "name": "Pinto, Pius Fidelis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6864,
      "name": "Piolet, J.-B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6865,
      "name": "Piolet, J.-B., S.J. & Vadot, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6866,
      "name": "Piozzi Hester Lynch",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6867,
      "name": "Pirenne, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6868,
      "name": "Pirenne, Henri & Miall, Bernard [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6869,
      "name": "Pirenne, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6870,
      "name": "Pirot Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6871,
      "name": "Pirsig, Robert M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6872,
      "name": "Pisani, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6873,
      "name": "Piscetta, A., S.S. & Gennaro, A., S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6874,
      "name": "Pistocchi, Marius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6875,
      "name": "Pistoni, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6876,
      "name": "Pitre, Brant James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6877,
      "name": "Pitres A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6878,
      "name": "Pitt, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6879,
      "name": "Pittar Fanny Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6880,
      "name": "Pitt-Lewis G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6881,
      "name": "Pius V, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6882,
      "name": "Pius X Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6883,
      "name": "Pius X Papa & Gregorius I Magnus Papa Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6884,
      "name": "Pius X, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6885,
      "name": "Pius XI Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6886,
      "name": "Pius XI, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6887,
      "name": "Pius XI, Papa & Benedictus XV, Pp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6888,
      "name": "Pius XI, Papa & Ryan, James H. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6889,
      "name": "Pius XII Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6890,
      "name": "Pius XII, Papa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6891,
      "name": "Pizzuto, Vincent A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6892,
      "name": "Placenza Pietro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6893,
      "name": "Placidus a S. Joseph, T.O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6894,
      "name": "Plantier, C.H.A. Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6895,
      "name": "Plassmann, Thomas, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6896,
      "name": "Platelius Jacobus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6897,
      "name": "Plater Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6898,
      "name": "Plater W.E. & White H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6899,
      "name": "Plato",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6900,
      "name": "Platonov",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6901,
      "name": "Platt, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6902,
      "name": "Plattner, Felix Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6903,
      "name": "Platts, John T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6904,
      "name": "Pleyer Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6905,
      "name": "Plinius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6906,
      "name": "Plotinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6907,
      "name": "Plou, Dafne Sabanes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6908,
      "name": "Plummer Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6909,
      "name": "Plummer, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6910,
      "name": "Plumptre, E.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6911,
      "name": "Plus Raoul S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6912,
      "name": "Plus, Raoul, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6913,
      "name": "Plus, Raoul, S.J., Bertille, Mary, Sr. [transl.] & St Thomas, Mary S.N.D. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6914,
      "name": "Plus, Raoul, S.J., Mary, Bertille Sister [transl.] & Mary, St. Thomas S.N.D., Sister [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6915,
      "name": "Plutarchus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6916,
      "name": "Plutscho, Henry & Ziegenbalg, Bartholomaeus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6917,
      "name": "Pobee John S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6918,
      "name": "Podipara, Placid J. C.M.I. (1899-1985) & Kalayil, Thomas, C.M.I. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6919,
      "name": "Podmore Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6920,
      "name": "Podmore, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6921,
      "name": "Poels Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6922,
      "name": "Poelzl Francois-Xavier Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6923,
      "name": "Poggi, Vincenzo M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6924,
      "name": "Pohl, Alfred, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6925,
      "name": "Pohle Joseph Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6926,
      "name": "Poincare, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6927,
      "name": "Pointud-Guillemot B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6928,
      "name": "Poire Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6929,
      "name": "Poirters, Adrianus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6930,
      "name": "Poitevin, Guy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6931,
      "name": "Poitevin, M.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6932,
      "name": "Pojman, Louis P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6933,
      "name": "Polanco Joannes Alphonsus De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6934,
      "name": "Pole, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6935,
      "name": "Polge Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6936,
      "name": "Pollard A.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6937,
      "name": "Pollen John Hungerford S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6938,
      "name": "Pollock Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6939,
      "name": "Pollock, Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6940,
      "name": "Polloi, Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6941,
      "name": "Polycarpus, Smyrnensis, Episc. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6942,
      "name": "Polzer-Hoditz, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6943,
      "name": "Pomes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6944,
      "name": "Poncelet Albert S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6945,
      "name": "Poncelet Alfred S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6946,
      "name": "Ponlevoy Armand De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6947,
      "name": "Ponmala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6948,
      "name": "Ponsard C. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6949,
      "name": "Pontaine, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6950,
      "name": "Pontet Maurice S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6951,
      "name": "Pontifex, Mark O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6952,
      "name": "Pontneau Clement S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6953,
      "name": "Poole, Geo. Ayliffe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6954,
      "name": "Pope Hugh O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6955,
      "name": "Pope Hugh O.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6956,
      "name": "Pope, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6957,
      "name": "Pope, Hugh, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6958,
      "name": "Pope, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6959,
      "name": "Pope, W.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6960,
      "name": "Poppe Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6961,
      "name": "Poppe Edward & Defoort H. Enz.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6962,
      "name": "Poppe, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6963,
      "name": "Porretanus Gilbertus Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6964,
      "name": "Porter George S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6965,
      "name": "Portmans A.M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6966,
      "name": "Porto Maurizio Leonardo Da O.F.M. Sto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6967,
      "name": "Poschmann Bernhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6968,
      "name": "Pospielovsky Dimitry (1935- )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6969,
      "name": "Pospielovsky, Dimitry (1935- )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6970,
      "name": "Possamai Adam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6971,
      "name": "Possamai, Adam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6972,
      "name": "Possoz Alexis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6973,
      "name": "Post S.A [transl. & introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6974,
      "name": "Potier Francis Abbe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6975,
      "name": "Potocek, Cyril J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6976,
      "name": "Potter Thomas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6977,
      "name": "Potter, Karl H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6978,
      "name": "Potter, Thomas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6979,
      "name": "Pottier Aloys S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6980,
      "name": "Pottier, Aloys, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6981,
      "name": "Pottier, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6982,
      "name": "Poucel Victor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6983,
      "name": "Pouget G. & Guitton Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6984,
      "name": "Poujol, F.-A-Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6985,
      "name": "Poulain, Auguste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6986,
      "name": "Poulet, Charles, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6987,
      "name": "Pouliot, Leon, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6988,
      "name": "Poullier Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6989,
      "name": "Pourrat Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6990,
      "name": "Pourrat, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6991,
      "name": "Pouvourville Albert de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6992,
      "name": "Power M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6993,
      "name": "Power Thomas Augustus Rev.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6994,
      "name": "Power, Edmond, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6995,
      "name": "Power, Philip Bennet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6996,
      "name": "Powicke, F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6997,
      "name": "Powys, Llewelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6998,
      "name": "Poynter Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 6999,
      "name": "Pradel, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7000,
      "name": "Pradel, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7001,
      "name": "Prakash Amit",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7002,
      "name": "Prakash Surendra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7003,
      "name": "Prakash, Acharya Daya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7004,
      "name": "Pranavanand, Svami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7005,
      "name": "Prasad B.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7006,
      "name": "Prasad Jayasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7007,
      "name": "Prasad, Ishwari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7008,
      "name": "Prasad, Jayasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7009,
      "name": "Prasad, K.S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7010,
      "name": "Prasad, Rajendra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7011,
      "name": "Prasad, S.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7012,
      "name": "Prat Ferdinand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7013,
      "name": "Prat Jean-Marie S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7014,
      "name": "Prat, Ferdinand S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7015,
      "name": "Prat, Ferdinand, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7016,
      "name": "Pratico, Gary D. & Van Pelt, Miles V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7017,
      "name": "Pratt, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7018,
      "name": "Pratt, James Bissett",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7019,
      "name": "Prem, Dhaniram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7020,
      "name": "Premcand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7021,
      "name": "Premi, Laksmi Narayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7022,
      "name": "Premoli Orazio M. (Barnabite)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7023,
      "name": "Prentice, Sartell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7024,
      "name": "Presbytre, Jean Le",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7025,
      "name": "Prescott, H.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7026,
      "name": "Prescott, William H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7027,
      "name": "Prestige G.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7028,
      "name": "Prestige, G.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7029,
      "name": "Preuschen Erwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7030,
      "name": "Preuschen, Erwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7031,
      "name": "Preuss Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7032,
      "name": "Prevost  L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7033,
      "name": "Pribilla Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7034,
      "name": "Pribilla, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7035,
      "name": "Price, E.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7036,
      "name": "Price, George McCready",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7037,
      "name": "Price, Maurice T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7038,
      "name": "Prick van Wely, F.P.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7039,
      "name": "Prideaux, Humphrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7040,
      "name": "Prideaux, S.P.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7041,
      "name": "Priestley, J.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7042,
      "name": "Pringle-Pattison, A. Seth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7043,
      "name": "Privat, Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7044,
      "name": "Privat-Deschanel A. & Everett J.D. [ed. & transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7045,
      "name": "Privat-Deschanel A. & Focillon Ad.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7046,
      "name": "Proces, Edmond, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7047,
      "name": "Procter, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7048,
      "name": "Procter, Francis & Maclear, G.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7049,
      "name": "Prohaszka, Ottokar, Bp. & Margaret de Pal [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7050,
      "name": "Proksch George S.V.D. (Guru Jnanprakas)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7051,
      "name": "Proserpio, Leo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7052,
      "name": "Prumm Karl S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7053,
      "name": "Prummer Dominicus M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7054,
      "name": "Prummer Dominicus M. O.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7055,
      "name": "Prunel Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7056,
      "name": "Prunel, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7057,
      "name": "Pruvost A. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7058,
      "name": "Przywara Erich S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7059,
      "name": "Puech Aime & Partridge Mildred [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7060,
      "name": "Puech, Aime",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7061,
      "name": "Puig, Ignacio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7062,
      "name": "Pulickal Joseph S.J. (1965-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7063,
      "name": "Pullan, Leighton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7064,
      "name": "Puller, F.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7065,
      "name": "Pully Henry de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7066,
      "name": "Puniet Jean De O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7067,
      "name": "Puniet, Pierre de, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7068,
      "name": "Puniyani Ram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7069,
      "name": "Pusey, E.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7070,
      "name": "Puskas, Charles B., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7071,
      "name": "Putti Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7072,
      "name": "Putz Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7073,
      "name": "Putz, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7074,
      "name": "Putzger F.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7075,
      "name": "Pym, T.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7076,
      "name": "Qadhi Abu Ammaar Yasir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7077,
      "name": "Quadrupani, Barnab.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7078,
      "name": "Quantin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7079,
      "name": "Quatrefages A.De & Starr Frederick [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7080,
      "name": "Quicherat L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7081,
      "name": "Quicherat, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7082,
      "name": "Quick, Oliver Chase",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7083,
      "name": "Quigley, Thomas J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7084,
      "name": "Quiller-Couch, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7085,
      "name": "Quiller-Couch, Arthur [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7086,
      "name": "Quin, Malcolm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7087,
      "name": "Quinton, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7088,
      "name": "Quinton, R.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7089,
      "name": "Qureshi, Iqbal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7090,
      "name": "Qutb, Sayyid (1906-1966) & David, Rami [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7091,
      "name": "Raab, Clement, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7092,
      "name": "Rabanus Maurus Abbas Fuldensis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7093,
      "name": "Rabbaey, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7094,
      "name": "Rabean, Guston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7095,
      "name": "Rabeau, Gaston",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7096,
      "name": "Rabelais, Francois",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7097,
      "name": "Rachmanova, Alia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7098,
      "name": "Racine, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7099,
      "name": "Rackham, R.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7100,
      "name": "Radcliffe, Jane",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7101,
      "name": "Radcliffe-Brown, A.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7102,
      "name": "Rademacher Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7103,
      "name": "Rademacher, Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7104,
      "name": "Radermacher H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7105,
      "name": "Radford, Lewis B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7106,
      "name": "Radhakrishnan, Sarvepalli",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7107,
      "name": "Radhakrsnan, Sarvepalli",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7108,
      "name": "Raeymaeker Louis De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7109,
      "name": "Ragey, Mar.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7110,
      "name": "Ragg, Lonsdale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7111,
      "name": "Rahi, Prabodh Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7112,
      "name": "Rahlfs, Alfred [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7113,
      "name": "Rahman A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7114,
      "name": "Rahman, A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7115,
      "name": "Rahman, Tariq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7116,
      "name": "Rahner, Hugo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7117,
      "name": "Rahner, Karl, S.J. (1904-1984  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7118,
      "name": "Rahul Sankrtyayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7119,
      "name": "Rai, Mridu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7120,
      "name": "Raina, Vinod [ed.], Caudhuri, Aditi [ed.] & Caudhuri, Sumit [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7121,
      "name": "Raine, Allen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7122,
      "name": "Rainy, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7123,
      "name": "Rait, Roberts S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7124,
      "name": "Raitz von Frentz E. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7125,
      "name": "Raj, M.C. & Jyothi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7126,
      "name": "Raja John Joshva",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7127,
      "name": "Rajam, J.B.R., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7128,
      "name": "Rajanis Acarya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7129,
      "name": "Rajasekhara, Dalal, C.D. [ed.] & Sastry, R.A. [ed. & Introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7130,
      "name": "Rajkumar, Dr. (1960-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7131,
      "name": "Rajpramukh, K.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7132,
      "name": "Raju, S.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7133,
      "name": "Raleigh, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7134,
      "name": "Ralte Lalrinawmi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7135,
      "name": "Ralte, Lalrinawmi [Ed.] & Anderson-Rajkumar, Evangeline [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7136,
      "name": "Ram Petrus Franc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7137,
      "name": "Ram, D. Sundar [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7138,
      "name": "Ramamurty, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7139,
      "name": "Ramaswami, Ayyar C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7140,
      "name": "Ramaswamy, Krishnan [ed.], De Nicolas, Antonio T. [ed.] & Bandyopadhyay, Aditi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7141,
      "name": "Ramaswamy, Uma, Vasudevan, Bhanumathy, Prasad, Anuradha & Sethi, Gagan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7142,
      "name": "Ramesh, Asha & Ali, Bharti",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7143,
      "name": "Ramier Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7144,
      "name": "Ramier, Henri, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7145,
      "name": "Ramiere Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7146,
      "name": "Rampolla Card. Leahy E. [transl.] & Thurston Herbert S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7147,
      "name": "Ramsay, William M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7148,
      "name": "Ranchan Som P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7149,
      "name": "Ranke, Leopold Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7150,
      "name": "Rankin, George Claus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7151,
      "name": "Ransome, Cyril",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7152,
      "name": "Rao, D.L. Prasada [Ed.], Rao, N.V. K. [Ed.] & Saheb, S. Yaseen [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7153,
      "name": "Rao, Gopinath T.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7154,
      "name": "Rao, Gurajada Venkata Appa, Vijayasree, C. [Transl.] & Kumar, T. Vijay [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7155,
      "name": "Rao, H.S. Gururaja",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7156,
      "name": "Rao, V.M. (1954-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7157,
      "name": "Rao, Vasudeva",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7158,
      "name": "Raoul, M.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7159,
      "name": "Rappel Simone",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7160,
      "name": "Rashdall, Hastings",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7161,
      "name": "Rasmussen, Lissi [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7162,
      "name": "Rast, Maxim., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7163,
      "name": "Ratcliff, A.J.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7164,
      "name": "Rattenburg, J. Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7165,
      "name": "Raubibson, A.E.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7166,
      "name": "Rauch Franz.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7167,
      "name": "Raumer, Frederick Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7168,
      "name": "Raupert, J. Godfrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7169,
      "name": "Raus J.B. C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7170,
      "name": "Rauschen Gerhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7171,
      "name": "Raven-Hill, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7172,
      "name": "Ravignan Xavier De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7173,
      "name": "Ravignan Xavier De S.J. & Ram Abel Mrs. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7174,
      "name": "Rawlinson, A.E.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7175,
      "name": "Rawlinson, H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7176,
      "name": "Rawls, John (1921-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7177,
      "name": "Rawls, John (1921-  ) & Kelly, Erin [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7178,
      "name": "Ray, Amarendranath [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7179,
      "name": "Ray, Annadasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7180,
      "name": "Ray, B.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7181,
      "name": "Ray, Kalidasa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7182,
      "name": "Ray, Rajat Kanta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7183,
      "name": "Ray, Srimahendracandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7184,
      "name": "Ray, Virendranath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7185,
      "name": "Ray, Visvanath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7186,
      "name": "Rayan, Samuel, S.J. (1920-2019) & Shahzad, Akif [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7187,
      "name": "Rayanna, Peter, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7188,
      "name": "Rayee T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7189,
      "name": "Raymond, Fr. V., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7190,
      "name": "Raymond, Fr. V., O.P. & Smith, Aloysius, C.R.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7191,
      "name": "Raymond, M., O.C.S.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7192,
      "name": "Raymondus Capuanus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7193,
      "name": "Raymundus Lullus, Btus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7194,
      "name": "Rayne Major H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7195,
      "name": "Razaud, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7196,
      "name": "Raze, Henri de, Lachaud, Edmond de & Flandrin, Jean-Baptiste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7197,
      "name": "Rea, James Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7198,
      "name": "Rea, Russel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7199,
      "name": "Reade, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7200,
      "name": "Reade, Winwood",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7201,
      "name": "Reany William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7202,
      "name": "Reau, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7203,
      "name": "Reau, Louis & Cohen, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7204,
      "name": "Rebello, Cedric, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7205,
      "name": "Rebillon, Armand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7206,
      "name": "Reck Francois Xavier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7207,
      "name": "Reckitt, Maurice B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7208,
      "name": "Reckitt, Maurice B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7209,
      "name": "Reclus, Elie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7210,
      "name": "Reddaway, W.F.; Penson, J.H.; Halecki, Oscar & Dyboski, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7211,
      "name": "Redlich, E. Basil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7212,
      "name": "Redmond, N.M., V.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7213,
      "name": "Redway, George [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7214,
      "name": "Reeb Georgeius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7215,
      "name": "Reed, S. [ed.]; Sheppard, S.T. [ed.] & Low, F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7216,
      "name": "Rees, A. Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7217,
      "name": "Rees, J. Aubrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7218,
      "name": "Reese, Thomas J., S.J. (1945-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7219,
      "name": "Reeve Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7220,
      "name": "Reeves Jeremiah Bascom",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7221,
      "name": "Reeves, John-Baptist, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7222,
      "name": "Reeves, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7223,
      "name": "Regard Paul F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7224,
      "name": "Regard Paul -F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7225,
      "name": "Reggio Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7226,
      "name": "Regis, L.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7227,
      "name": "Regnier Adolphe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7228,
      "name": "Regnon Theodore De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7229,
      "name": "Regnon, Theodore De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7230,
      "name": "Rego, Sebastiao do",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7231,
      "name": "Reicke, Bo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7232,
      "name": "Reid, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7233,
      "name": "Reiffenstuel Anacletus O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7234,
      "name": "Reijs, P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7235,
      "name": "Reilly, Thomas Francis, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7236,
      "name": "Reimmichl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7237,
      "name": "Reinach, Salomon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7238,
      "name": "Reinaud, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7239,
      "name": "Reis Santos, Luiz & Queiroz, Carlos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7240,
      "name": "Reischl Marcellinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7241,
      "name": "Reitzenstein, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7242,
      "name": "Rejwan, Nissim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7243,
      "name": "Remarque, Erich Maria & Wheen, A.W. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7244,
      "name": "Remec, Bogumil, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7245,
      "name": "Remer Vincentius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7246,
      "name": "Remler Francis J. C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7247,
      "name": "Renan, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7248,
      "name": "Renan, Ernest & Hutchinson, W.G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7249,
      "name": "Renard, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7250,
      "name": "Renard, Georges & Trotabas, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7251,
      "name": "Renard, Henri, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7252,
      "name": "Renard, Henri, S.J., Buzy, T.R.P.D., Weber, J. & Spicq, R.P.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7253,
      "name": "Renard, John (1944-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7254,
      "name": "Renard, R.G., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7255,
      "name": "Renie J. S.M. [et al.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7256,
      "name": "Rennie, Bryan S. [ed.] & Tite, Philip L. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7257,
      "name": "Rentz Placidus O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7258,
      "name": "Repura, Talukedar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7259,
      "name": "Resch P. S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7260,
      "name": "Retailleau, Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7261,
      "name": "Retzlaff, Erich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7262,
      "name": "Reu, Visvesvaranatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7263,
      "name": "Reuter James S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7264,
      "name": "Reuter Joannes S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7265,
      "name": "Reuter, Frederick A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7266,
      "name": "Reuter, Joannes, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7267,
      "name": "Reville, Jean & Leuliette, Victor [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7268,
      "name": "Reviron, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7269,
      "name": "Revre Pierre S.J. Bhx.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7270,
      "name": "Rey, Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7271,
      "name": "Reybaud, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7272,
      "name": "Reynen Antoine S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7273,
      "name": "Reynen, Antoine, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7274,
      "name": "Reypens Leonce S.J. (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7275,
      "name": "Reypens, Leonce, S.J. (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7276,
      "name": "Reys A.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7277,
      "name": "Rheinhard Arminius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7278,
      "name": "Rhind, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7279,
      "name": "Rhodes, Kathlyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7280,
      "name": "Rhys Grace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7281,
      "name": "Rhys, Jocelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7282,
      "name": "Ribadeneira Petrus De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7283,
      "name": "Ribadeneira, Petrus De, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7284,
      "name": "Ribes, Peter, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7285,
      "name": "Ribet, M.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7286,
      "name": "Ribot, Th",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7287,
      "name": "Ribot, Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7288,
      "name": "Ricardo, David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7289,
      "name": "Ricciotti, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7290,
      "name": "Ricciotti, Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7291,
      "name": "Richard L. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7292,
      "name": "Richard Timothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7293,
      "name": "Richard, Timothy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7294,
      "name": "Richardson, Alan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7295,
      "name": "Richardson, George H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7296,
      "name": "Richardson, J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7297,
      "name": "Riche, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7298,
      "name": "Richet Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7299,
      "name": "Richstaetter, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7300,
      "name": "Richter Gulielmus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7301,
      "name": "Rickaby John S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7302,
      "name": "Rickaby Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7303,
      "name": "Rickaby, John, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7304,
      "name": "Rickaby, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7305,
      "name": "Ricoeur, Paul & Buchanan, Emerson [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7306,
      "name": "Rideau, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7307,
      "name": "Ridgeon Lloyd V.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7308,
      "name": "Riehm, Eduard C., Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7309,
      "name": "Riesner, Albert Joseph, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7310,
      "name": "Riess Florian S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7311,
      "name": "Rigaux Beda O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7312,
      "name": "Rigaux, Maurice, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7313,
      "name": "Riki, Mira & Elliot, Jeffrey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7314,
      "name": "Rilke, Rainer Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7315,
      "name": "Rillo, Nicola A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7316,
      "name": "Rimaud Jean S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7317,
      "name": "Rindt Eugen Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7318,
      "name": "Ring, George C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7319,
      "name": "Rintelen, Captain von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7320,
      "name": "Riolern, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7321,
      "name": "Riondel, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7322,
      "name": "Riordan, D., P.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7323,
      "name": "Riordan, Patrick, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7324,
      "name": "Ripa, Matteo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7325,
      "name": "Rippmann, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7326,
      "name": "Rist Markus S.J. [hersg.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7327,
      "name": "Ritchie, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7328,
      "name": "Ritchie, Leith",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7329,
      "name": "Rivers W.H.R. [ed.] Morett R.R. & Thomas N.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7330,
      "name": "Riviere Ernest-M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7331,
      "name": "Riviere Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7332,
      "name": "Riviere, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7333,
      "name": "Riviere, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7334,
      "name": "Rivington, Luke",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7335,
      "name": "Rivoyre, Denis de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7336,
      "name": "Robberechts M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7337,
      "name": "Robbins Vernon K. (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7338,
      "name": "Robeck, Nesta de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7339,
      "name": "Roberti Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7340,
      "name": "Roberti, Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7341,
      "name": "Roberts, Michael J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7342,
      "name": "Roberts, Richard H. (1946-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7343,
      "name": "Robertson A.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7344,
      "name": "Robertson, A.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7345,
      "name": "Robertson, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7346,
      "name": "Robertson, Archibald & Plummer, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7347,
      "name": "Robertson, C.K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7348,
      "name": "Robertson, D.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7349,
      "name": "Robertson, Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7350,
      "name": "Robertson, Frederick W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7351,
      "name": "Robertson, James C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7352,
      "name": "Robertson, John M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7353,
      "name": "Robertson, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7354,
      "name": "Robertson, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7355,
      "name": "Robie, W.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7356,
      "name": "Robin Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7357,
      "name": "Robinson Adam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7358,
      "name": "Robinson Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7359,
      "name": "Robinson Gertrude",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7360,
      "name": "Robinson Gnana (1935-  ) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7361,
      "name": "Robinson Theodore H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7362,
      "name": "Robinson William F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7363,
      "name": "Robinson, Alan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7364,
      "name": "Robinson, Charles Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7365,
      "name": "Robinson, H. Wheeler (1872-1945) [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7366,
      "name": "Robinson, R.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7367,
      "name": "Robinson, Rowena (1967-    ) [ed.] & Clarke, Sathianathan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7368,
      "name": "Robinson, Theodore H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7369,
      "name": "Rocca, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7370,
      "name": "Rocca, F.X., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7371,
      "name": "Roche W. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7372,
      "name": "Roche, Aloysius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7373,
      "name": "Roche, Eugene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7374,
      "name": "Rocheblave, Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7375,
      "name": "Rochet Pierre S.J. & Le Cocq P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7376,
      "name": "Rochford, Vincent",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7377,
      "name": "Rocholl Norbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7378,
      "name": "Rock D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7379,
      "name": "Rocquain, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7380,
      "name": "Rodenbach, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7381,
      "name": "Rodin, Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7382,
      "name": "Rodino, Leopoldo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7383,
      "name": "Rodrigo, Lucius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7384,
      "name": "Rodrigues, Francisco, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7385,
      "name": "Rodrigues, Savio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7386,
      "name": "Rodrigues, Valerian [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7387,
      "name": "Rodriguez Alphonsus Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7388,
      "name": "Rodriguez de Cepeda Raphael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7389,
      "name": "Rodriguez, Alonso, S.J. & Rickaby, Joseph, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7390,
      "name": "Roerich, Nicholas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7391,
      "name": "Roes G.J. & Franssen M.H.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7392,
      "name": "Roger, Ch. & Servais, Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7393,
      "name": "Rogers Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7394,
      "name": "Rogers, Clement F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7395,
      "name": "Rogers, Eugene F., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7396,
      "name": "Rogerson, John W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7397,
      "name": "Roget Peter Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7398,
      "name": "Roget, Peter Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7399,
      "name": "Roguet, A.-M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7400,
      "name": "Rohde Jon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7401,
      "name": "Rohr, Ignaz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7402,
      "name": "Rohrmann, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7403,
      "name": "Roland-Gosselin M.-D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7404,
      "name": "Roland-Gosselin, M.-D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7405,
      "name": "Roll, Eric",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7406,
      "name": "Rolland C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7407,
      "name": "Rolle Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7408,
      "name": "Romanes, George John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7409,
      "name": "Romanoff, H.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7410,
      "name": "Romeyer Blaise S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7411,
      "name": "Romeyer, Blaise, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7412,
      "name": "Rommen, Heinrich A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7413,
      "name": "Romsee Tossani-Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7414,
      "name": "Rongen, Hieronymus, O. Cist. R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7415,
      "name": "Rooney, Miriam Theresa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7416,
      "name": "Roorda, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7417,
      "name": "Roothaan Joannes Philippus S.J",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7418,
      "name": "Roothaan Joannes Philippus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7419,
      "name": "Roothaan, Joannes Philippus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7420,
      "name": "Roothaan, Joannes Philippus, S.J., De Jonge, Ludovicus [ed.] & Pirri, Petrus, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7421,
      "name": "Rope, Henry E.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7422,
      "name": "Ropes, James Hardy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7423,
      "name": "Roquette J.-I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7424,
      "name": "Rosadi Giovanni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7425,
      "name": "Rosadini, Silvio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7426,
      "name": "Rosage, David E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7427,
      "name": "Rosales, Gaudencio B.; Arevalo, Catalino G., S.J. & Eileis, Frenz-Josef [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7428,
      "name": "Rosato Philipp J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7429,
      "name": "Rose Jessica",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7430,
      "name": "Rose Stewart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7431,
      "name": "Rose V. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7432,
      "name": "Rose, J. Holland",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7433,
      "name": "Rosenblatt, Kathleen Ferrick (1947-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7434,
      "name": "Rosenthal, Erwin I.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7435,
      "name": "Rosmini Antonio (1797-1855)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7436,
      "name": "Ross E.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7437,
      "name": "Ross W.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7438,
      "name": "Ross, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7439,
      "name": "Ross, Floyd Hiatt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7440,
      "name": "Ross, J. Elliot, C.S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7441,
      "name": "Ross, Janet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7442,
      "name": "Ross, Susan A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7443,
      "name": "Rossel Jacques & Lyle John M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7444,
      "name": "Rossetti Julius Costa S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7445,
      "name": "Rossetti, Dante Gabriel & Macartney, K.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7446,
      "name": "Rossi, Silva",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7447,
      "name": "Rossignoli, Carolo Gregorio, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7448,
      "name": "Rosswog, Bernard Lawrence, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7449,
      "name": "Rost Hans",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7450,
      "name": "Rostand Edmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7451,
      "name": "Rostand Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7452,
      "name": "Rostovtzeff, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7453,
      "name": "Rothacker Erich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7454,
      "name": "Rothenflue Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7455,
      "name": "Rother, Aloysius J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7456,
      "name": "Rothes Phil. Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7457,
      "name": "Rothfeld, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7458,
      "name": "Roton Placide De O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7459,
      "name": "Rotteck, K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7460,
      "name": "Roubiczek, Paul & Kalmer, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7461,
      "name": "Rouche Eugene & Comberousse Ch. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7462,
      "name": "Rouet de Journel M.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7463,
      "name": "Rouet De Journel, M.J., S.J. & Dutilleul, J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7464,
      "name": "Roulin, E., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7465,
      "name": "Roulin, E., O.S.B., Craige, C. Cornelia [transl.] & Southwell, John A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7466,
      "name": "Rountree B. Seebohm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7467,
      "name": "Roupain, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7468,
      "name": "Roure, Lucien, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7469,
      "name": "Rousseau, Dom Olivier, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7470,
      "name": "Rousseau, Jean Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7471,
      "name": "Rousseau, Jean Jacques & Foxley, Barbara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7472,
      "name": "Rousselot Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7473,
      "name": "Rousselot Pierre S.J. & O'Mahony James E. O.M.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7474,
      "name": "Rousselot, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7475,
      "name": "Routh, Martinus Josephus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7476,
      "name": "Rowe, C. Kavin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7477,
      "name": "Rowe, F.J. & Webb, W.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7478,
      "name": "Rowley, H.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7479,
      "name": "Roy Arundhati",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7480,
      "name": "Roy Burman B.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7481,
      "name": "Roy Burman, J.J. (1955-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7482,
      "name": "Roy Jules & Maitland Margaret [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7483,
      "name": "Roy M.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7484,
      "name": "Roy M.N. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7485,
      "name": "Roy Sanjay K. (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7486,
      "name": "Roy Sarat Chandra & Rai Bahadur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7487,
      "name": "Roy, Bhaktapada Sinha (1943-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7488,
      "name": "Roy, Henry, O.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7489,
      "name": "Roy, Janmajit",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7490,
      "name": "Roy, M.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7491,
      "name": "Roy, Nicolas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7492,
      "name": "Roz Francisco",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7493,
      "name": "Rubbrecht Raphael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7494,
      "name": "Ruckert, Hanns",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7495,
      "name": "Rudy, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7496,
      "name": "Ruffenach, Florentius, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7497,
      "name": "Ruffini E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7498,
      "name": "Rufinus Tyrannius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7499,
      "name": "Ruiz Campos, Mariano",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7500,
      "name": "Ruiz De Montoya Antonio S.J. (1585-1652) & Coronado Aguilar Jurandir [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7501,
      "name": "Rumbold, Horace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7502,
      "name": "Runes, Dagobert D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7503,
      "name": "Rung Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7504,
      "name": "Rupa Gosvami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7505,
      "name": "Rupp Joyce",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7506,
      "name": "Ruscheblatt H.J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7507,
      "name": "Ruskin, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7508,
      "name": "Russell Charles E.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7509,
      "name": "Russell Charles E.B. & Rigby Lilian M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7510,
      "name": "Russell Matthew S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7511,
      "name": "Russell, A.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7512,
      "name": "Russell, Bertrand (1872-1970)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7513,
      "name": "Russell, Brian D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7514,
      "name": "Russell, G.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7515,
      "name": "Russell, George W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7516,
      "name": "Russell, William Howard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7517,
      "name": "Rutherford, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7518,
      "name": "Rutherford, W.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7519,
      "name": "Ruthnaswamy, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7520,
      "name": "Rutter Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7521,
      "name": "Ruusbroec, Jan Van, Can., Reg.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7522,
      "name": "Ruusbroec, Jan Van, Can., Reg., Ven",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7523,
      "name": "Ruville Albert Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7524,
      "name": "Ruyssen Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7525,
      "name": "Ryan John A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7526,
      "name": "Ryan, Edward A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7527,
      "name": "Ryan, Gerald Aloysius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7528,
      "name": "Ryan, John A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7529,
      "name": "Ryan, John A. & Boland, Francis J., C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7530,
      "name": "Ryan, John Julian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7531,
      "name": "Ryan, John K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7532,
      "name": "Ryan, Kevin J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7533,
      "name": "Ryan, Thomas F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7534,
      "name": "Ryelandt, Idesbald, O.S.B. & Dillon, Dom Matthew [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7535,
      "name": "Ryken, Leland [ed.]; Wilhot, James C. [ed.] & Tremper [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7536,
      "name": "Rynne, Terrence J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7537,
      "name": "Sabahuddin, Abdul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7538,
      "name": "Sabatier, Auguste",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7539,
      "name": "Sabatier, Auguste & Leuliette, Victor [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7540,
      "name": "Sabatier, Paul & Houghton, Louise Seymour [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7541,
      "name": "Sabbe, Philemon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7542,
      "name": "Sabet, Amr G.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7543,
      "name": "Sacco Jos M. Gauci S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7544,
      "name": "Sacco, Ugo Colombo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7545,
      "name": "Sackville-West, V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7546,
      "name": "Sadangi, Himansu Charan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7547,
      "name": "Sadet Camille",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7548,
      "name": "Sadhale, S. Gajanan Shambhu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7549,
      "name": "Sa'di Shirazi, Shaykh Muslih Al-Din",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7550,
      "name": "Sadiki, Larbi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7551,
      "name": "Sadin, Fernand, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7552,
      "name": "Sadler, M.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7553,
      "name": "Sadler, Rodney Steven, Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7554,
      "name": "Saeed, Abdullah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7555,
      "name": "Saeed, Abdullah [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7556,
      "name": "Sagar Sunder Lal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7557,
      "name": "Sagehomme Georges S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7558,
      "name": "Saghie Hazim [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7559,
      "name": "Sahay, Brajanandan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7560,
      "name": "Sahay, Sivapujan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7561,
      "name": "Sahay, Vrajanandan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7562,
      "name": "Saillens, Emile & Holme, E.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7563,
      "name": "Saint-Aulaire, Comte De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7564,
      "name": "Saint-Clavein, L.F. Jehan de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7565,
      "name": "Sainte-Foi Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7566,
      "name": "Saint-Exupery, Antoine de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7567,
      "name": "Saint-Hillaire, Josephine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7568,
      "name": "Saint-John Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7569,
      "name": "Saint-Jure J.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7570,
      "name": "Saint-Jure, J.B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7571,
      "name": "Saint-Paul Mother Rel. Of The Retr. Of The S.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7572,
      "name": "Saint-Pierre, Jacques-Bernardin Henri de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7573,
      "name": "Saintrain H. C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7574,
      "name": "Saintrain, H., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7575,
      "name": "Saintsbury, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7576,
      "name": "Saintyves, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7577,
      "name": "Sajoo, Amyn B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7578,
      "name": "Sakariya, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7579,
      "name": "Saksena, Ramsankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7580,
      "name": "Salahi, Adil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7581,
      "name": "Salaville, S., A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7582,
      "name": "Salazar Francois de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7583,
      "name": "Salazar, Francois de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7584,
      "name": "Saldanha Jerome A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7585,
      "name": "Saldanha, Assisi, C.Ss.R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7586,
      "name": "Saleeby, C.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7587,
      "name": "Saleh Fauzan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7588,
      "name": "Sales, Francois de, Eveq., St.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7589,
      "name": "Salet Gaston S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7590,
      "name": "Salet, Gaston, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7591,
      "name": "Salis-Seewis Francesco S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7592,
      "name": "Salkinson Isaac & Ginsburg David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7593,
      "name": "Sallustius, Gaius Crispus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7594,
      "name": "Sallustius, Philosophus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7595,
      "name": "Salmeron Alfonsus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7596,
      "name": "Salmi Ralph H.; Majul Cesar Adib & Tanham George K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7597,
      "name": "Salmon, Arthur L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7598,
      "name": "Salmon, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7599,
      "name": "Salotti Carlo Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7600,
      "name": "Salotti, Carlo, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7601,
      "name": "Salsmans Jozef S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7602,
      "name": "Salsmans Jozef S.J. & McReavy R.L. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7603,
      "name": "Salsmans, Josef, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7604,
      "name": "Salsmans, Jozef, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7605,
      "name": "Salter Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7606,
      "name": "Saltet Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7607,
      "name": "Salvador Centelles, Recaredo Jose",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7608,
      "name": "Salvini, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7609,
      "name": "Samiuddin Abida [ed.] & Khanam R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7610,
      "name": "Sampson, Gary P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7611,
      "name": "Sampurnanand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7612,
      "name": "Samuel, Bishop of Oxford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7613,
      "name": "Samuels, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7614,
      "name": "Sanasarian, Eliz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7615,
      "name": "Sanchez Thomas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7616,
      "name": "Sanchez Zarinana, Humberto Jose, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7617,
      "name": "Sanchez-Giron Renedo, Jose Luis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7618,
      "name": "Sandall, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7619,
      "name": "Sanday, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7620,
      "name": "Sanday, William & Headlam, Arthur C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7621,
      "name": "Sanday, William & Williams, N.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7622,
      "name": "Sander, C.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7623,
      "name": "Sanders Jerome M. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7624,
      "name": "Sanders Leon O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7625,
      "name": "Sanders Nicomedes O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7626,
      "name": "Sanders, Jerome M., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7627,
      "name": "Sanderson, G.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7628,
      "name": "Sandilya, Caudhuri Sivanathasimh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7629,
      "name": "Sanford Alexander E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7630,
      "name": "Sankar Kalyani",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7631,
      "name": "Sankarathil John OSFS [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7632,
      "name": "Sankarathil, John, OSFS",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7633,
      "name": "Sankey, Ira D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7634,
      "name": "Sanson Pierre Congr. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7635,
      "name": "Sanson, Pierre, Congr., Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7636,
      "name": "Santamaria, B.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7637,
      "name": "Santarama",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7638,
      "name": "Santi Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7639,
      "name": "Santideva Sadhu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7640,
      "name": "Santina, Peter Della",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7641,
      "name": "Santini, Candido, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7642,
      "name": "Sanyala, Prabodhakumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7643,
      "name": "Sapur, Tej Bahadur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7644,
      "name": "Sarada, Kumari Devi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7645,
      "name": "Saran, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7646,
      "name": "Sarasa Alphonsus De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7647,
      "name": "Sardesai, G.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7648,
      "name": "Sareen Santosh K. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7649,
      "name": "Sargent Daniel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7650,
      "name": "Sarhindi, R.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7651,
      "name": "Sarkar, Jadunath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7652,
      "name": "Sarkar, Nirmal Chandra & De, Ratan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7653,
      "name": "Sarkar, R.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7654,
      "name": "Sarkar, S.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7655,
      "name": "Sarma Bulbul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7656,
      "name": "Sarma Caturvedi Dvarakaprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7657,
      "name": "Sarma Jaganathprasada",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7658,
      "name": "Sarma K.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7659,
      "name": "Sarma Pandey Becan 'ugra'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7660,
      "name": "Sarma Ram Nath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7661,
      "name": "Sarma Sima [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7662,
      "name": "Sarma, Calise Cakrapani",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7663,
      "name": "Sarma, Caturvedi Dvarakaprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7664,
      "name": "Sarma, Jagannath Ray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7665,
      "name": "Sarma, K.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7666,
      "name": "Sarma, Mehta Lajja Ram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7667,
      "name": "Sarma, N.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7668,
      "name": "Sarma, Nandakumardev",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7669,
      "name": "Sarma, Padmasimha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7670,
      "name": "Sarma, Ramjilal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7671,
      "name": "Sarma, Shyam Sunder Pd.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7672,
      "name": "Sarma, Sivanath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7673,
      "name": "Sarma, Somanatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7674,
      "name": "Sarma, Tulsiram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7675,
      "name": "Sarma, Visvambharanath 'kausik'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7676,
      "name": "Sarolea Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7677,
      "name": "Sartiaux, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7678,
      "name": "Sartori, Cosmas, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7679,
      "name": "Sastri, A. Mahadeva",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7680,
      "name": "Sastri, Catursen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7681,
      "name": "Sastri, Hirananda",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7682,
      "name": "Sastri, Sivanatha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7683,
      "name": "Sathianathan, W.R.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7684,
      "name": "Satpathy, Sarbeswar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7685,
      "name": "Satyabhakt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7686,
      "name": "Satyanarayana, G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7687,
      "name": "Saubin A.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7688,
      "name": "Saudreau, Auguste, Chan.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7689,
      "name": "Saudreau, Auguste, Chan. & Smith, Leonora S. Yorke [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7690,
      "name": "Sauer, C. Marquard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7691,
      "name": "Sauer, C. Marquard & Motti, Pietro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7692,
      "name": "Sauerlandt, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7693,
      "name": "Sauliere, A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7694,
      "name": "Sauvaget, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7695,
      "name": "Sauve Charles S.-S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7696,
      "name": "Savi, E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7697,
      "name": "Savitri, Devi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7698,
      "name": "Sawicki Marianne",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7699,
      "name": "Sayce, A.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7700,
      "name": "Sayers, Dorothy L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7701,
      "name": "Scaglia P. Sisto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7702,
      "name": "Scaramelli Giovanni Battista S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7703,
      "name": "Scavini Pietro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7704,
      "name": "Schaeffer, Claude F.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7705,
      "name": "Schafer, Timotheus, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7706,
      "name": "Schaffer, William & Kuebel, Matthew, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7707,
      "name": "Schaffle, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7708,
      "name": "Schaller, Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7709,
      "name": "Schanz Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7710,
      "name": "Schanz, John P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7711,
      "name": "Scharlaeken, Alphonsus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7712,
      "name": "Scharper, Stephen Bede",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7713,
      "name": "Schebesta, Paul & Griffin, Gerald [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7714,
      "name": "Scheeben Matthias Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7715,
      "name": "Scheeben, Matthias Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7716,
      "name": "Scheeben, Matthias Joseph & Geukers, T.L.M.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7717,
      "name": "Scheerlinck Emiel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7718,
      "name": "Scheffmacher Jean Jacques S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7719,
      "name": "Scheider, Karl Camillo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7720,
      "name": "Scheler Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7721,
      "name": "Scheler, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7722,
      "name": "Scheller, Imman. Jo. Gerh.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7723,
      "name": "Schelling, M. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7724,
      "name": "Scherer Marc",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7725,
      "name": "Scheuer, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7726,
      "name": "Scheuerer Franz Xaver",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7727,
      "name": "Scheurer J.B. & Lasance Francis Xavier [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7728,
      "name": "Scheyven, Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7729,
      "name": "Schiffini Sanctus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7730,
      "name": "Schilgen Hardy S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7731,
      "name": "Schilgen, Hardy, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7732,
      "name": "Schillebeeckx Edward H. O.P. (1914-  ) & Bowden John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7733,
      "name": "Schillebeeckx Edward H. O.P. (1914-  ) & Smith N.D. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7734,
      "name": "Schilling, D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7735,
      "name": "Schilpp, Paul Arthur [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7736,
      "name": "Schimberg, Albert Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7737,
      "name": "Schlauch, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7738,
      "name": "Schlegel, Frederick Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7739,
      "name": "Schlegel, Frederick Von & Morrison, A.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7740,
      "name": "Schleiermacher, Friedrich E.D. (1768-1834), Mackintosh, H.R. [ed.] & Stewart, J.S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7741,
      "name": "Schliemann Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7742,
      "name": "Schmalzgrueber Franciscus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7743,
      "name": "Schmid Bernard O.S.B. & Schobel V.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7744,
      "name": "Schmid, Christian Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7745,
      "name": "Schmidlin, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7746,
      "name": "Schmidt Kurt Dietrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7747,
      "name": "Schmidt P. Wilhelm S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7748,
      "name": "Schmidt P. Wilhelm S.V.D. & Koppers Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7749,
      "name": "Schmidt Traugott",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7750,
      "name": "Schmidt, P. Wilhelm, S.V.D. & Sieber, S.A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7751,
      "name": "Schmidts, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7752,
      "name": "Schmiedel, Paul Wilh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7753,
      "name": "Schmiedel, Paul Wilhelm & Canney, Maurice A. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7754,
      "name": "Schmiedeler, Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7755,
      "name": "Schmiedeler, Edgar, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7756,
      "name": "Schmier, Franciscus, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7757,
      "name": "Schmitt, James & Cafferata, H. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7758,
      "name": "Schmitz-Moormann Karl & Salmon James F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7759,
      "name": "Schmoller, Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7760,
      "name": "Schmutzer, J. & Ten Berge, J.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7761,
      "name": "Schmutzer, J., Ten Berge, J.J., S.J. & Maas, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7762,
      "name": "Schneider Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7763,
      "name": "Schneider Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7764,
      "name": "Schneider, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7765,
      "name": "Schneider, John R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7766,
      "name": "Schnitz, Leonhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7767,
      "name": "Schnurer Gustav",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7768,
      "name": "Schober Georgiis C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7769,
      "name": "Schoder, Raymond V., S.J. & Horrigan, V.C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7770,
      "name": "Schoeters K. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7771,
      "name": "Schofield, Alfred T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7772,
      "name": "Schofield, J.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7773,
      "name": "Scholberg, H.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7774,
      "name": "Scholefield, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7775,
      "name": "Scholes, Percy A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7776,
      "name": "Scholl Kaspar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7777,
      "name": "Scholtz Moses",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7778,
      "name": "Scholz, I.Mart. Augustinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7779,
      "name": "Schonsteiner, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7780,
      "name": "Schouppe Francois-Xavier S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7781,
      "name": "Schouppe Francois-Xavier S.J. & O'Hare F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7782,
      "name": "Schouppe Francois-Xavier S.J. & Quinn J.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7783,
      "name": "Schouppe, Francois-Xavier, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7784,
      "name": "Schreiber Ellis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7785,
      "name": "Schreiter Robert J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7786,
      "name": "Schrijvers Joseph C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7787,
      "name": "Schrijvers, Joseph, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7788,
      "name": "Schrodinger, Erwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7789,
      "name": "Schroeder, H.J., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7790,
      "name": "Schroer, Silvia, Staubli, Thomas & Maloney, Linda M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7791,
      "name": "Schroter, L. & Schroter, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7792,
      "name": "Schryvers, Jos., C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7793,
      "name": "Schuchhardt C. & Sellers Eugenie [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7794,
      "name": "Schuler, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7795,
      "name": "Schulte Chrysostom O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7796,
      "name": "Schulte, Chrysostom, O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7797,
      "name": "Schultes Reginald-Maria O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7798,
      "name": "Schulz, Fritz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7799,
      "name": "Schumacher M.A. Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7800,
      "name": "Schumacher, Gottlieb",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7801,
      "name": "Schumacher, M.A., Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7802,
      "name": "Schumann, Olaf H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7803,
      "name": "Schumarcher, Gottlieb",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7804,
      "name": "Schumpeter, Joseph A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7805,
      "name": "Schupp, Johann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7806,
      "name": "Schurer, Emil (1844-1910)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7807,
      "name": "Schurhammer Georg S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7808,
      "name": "Schuster Joannes B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7809,
      "name": "Schuster, I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7810,
      "name": "Schuster, I., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7811,
      "name": "Schwager, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7812,
      "name": "Schwane Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7813,
      "name": "Schweigl Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7814,
      "name": "Schweiker, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7815,
      "name": "Schweiker, William [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7816,
      "name": "Schweitzer Vincentius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7817,
      "name": "Schweitzer, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7818,
      "name": "Scofield, C.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7819,
      "name": "Scoles Ignatius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7820,
      "name": "Scorraille Raoul De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7821,
      "name": "Scott Eva",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7822,
      "name": "Scott S. Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7823,
      "name": "Scott, Charles A. Anderson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7824,
      "name": "Scott, James Brown",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7825,
      "name": "Scott, Martin J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7826,
      "name": "Scott, S. Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7827,
      "name": "Scott, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7828,
      "name": "Scott, Walter & Rosetti, W.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7829,
      "name": "Scott-Elliot, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7830,
      "name": "Scramelli, Joannes Bapt., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7831,
      "name": "Scrivener, Frederick Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7832,
      "name": "Scroope, Martin [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7833,
      "name": "Scudder, Vida D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7834,
      "name": "Scully, Vincent, C.R.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7835,
      "name": "Scupoli Lorenzo O. Theat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7836,
      "name": "Scupoli, Lorenzo, O. Theat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7837,
      "name": "Seabrook, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7838,
      "name": "Seaby, Allen W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7839,
      "name": "Seaton, James B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7840,
      "name": "Sebastiani Nicolaus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7841,
      "name": "Sedgwick Henry Dwight",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7842,
      "name": "Sedgwick, Mark",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7843,
      "name": "Sedillot, Rene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7844,
      "name": "Seduliuss",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7845,
      "name": "Seeberg, Reinhold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7846,
      "name": "Seeley J.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7847,
      "name": "Seely, John B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7848,
      "name": "Seemann, E.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7849,
      "name": "Segneri Paolo S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7850,
      "name": "Segond, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7851,
      "name": "Segretain, E.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7852,
      "name": "Seguin Eugene S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7853,
      "name": "Segur A. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7854,
      "name": "Segur Louis-Gaston de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7855,
      "name": "Segur, Louis-Gaston de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7856,
      "name": "Segur, Philippe-Paul, Cte. De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7857,
      "name": "Seignobos Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7858,
      "name": "Seignobos, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7859,
      "name": "Seilhac Leon de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7860,
      "name": "Seitz Christopher R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7861,
      "name": "Seitz, Christopher R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7862,
      "name": "Sejourne Xavier Auguste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7863,
      "name": "Sekhar, Vincent, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7864,
      "name": "Sekulow Jay; Ash Robert W. & French David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7865,
      "name": "Selbie, W.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7866,
      "name": "Self David",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7867,
      "name": "Seligman Edwin R.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7868,
      "name": "Seligman, Edwin R.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7869,
      "name": "Selincourt, Hugh De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7870,
      "name": "Sellar, Edmund Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7871,
      "name": "Sellers, R.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7872,
      "name": "Sels Henricus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7873,
      "name": "Seltzer, Robert M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7874,
      "name": "Selvanayagam, Israel [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7875,
      "name": "Selwyn, Edward Gordon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7876,
      "name": "Selwyn, Edward Gordon [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7877,
      "name": "Semeria Jean O. Barn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7878,
      "name": "Semionov, Youri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7879,
      "name": "Sempe Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7880,
      "name": "Semple, Henry Churchill, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7881,
      "name": "Sen Gupta, Subhadra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7882,
      "name": "Sen, Amartya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7883,
      "name": "Sen, Jaladhara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7884,
      "name": "Sen, Kesavacandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7885,
      "name": "Sen, Kusumara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7886,
      "name": "Sen, Mohit",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7887,
      "name": "Sen, Prabodhacandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7888,
      "name": "Senac, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7889,
      "name": "Sencourt, Robert (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7890,
      "name": "Senden Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7891,
      "name": "Senden, Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7892,
      "name": "Seneca, L.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7893,
      "name": "Sengupta, B.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7894,
      "name": "Sennyei Ladislaum S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7895,
      "name": "Sentroul, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7896,
      "name": "Seow Choon-Leong",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7897,
      "name": "Sepet Marius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7898,
      "name": "Sepharial",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7899,
      "name": "Seppelt Francis Xavier & Loeffler Clement",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7900,
      "name": "Sequeira A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7901,
      "name": "Seraphin Du Coeur De Jesus C.P. (John Giammaria) & Ward Lilian M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7902,
      "name": "Seraphin SS. CC.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7903,
      "name": "Seraphin, SS. CC.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7904,
      "name": "Serent, Antonine De, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7905,
      "name": "Sergeant, Philip W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7906,
      "name": "Serrao, Ronald",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7907,
      "name": "Serre-Balleyguier, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7908,
      "name": "Sertillanges A.D. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7909,
      "name": "Sertillanges A.D. O.P. & Ward L.M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7910,
      "name": "Sertillanges, A.D., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7911,
      "name": "Servaes, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7912,
      "name": "Servais-Marie De St. Ange O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7913,
      "name": "Sessevalle, Fr. de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7914,
      "name": "Set G.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7915,
      "name": "Severin Jules S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7916,
      "name": "Severin Tony S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7917,
      "name": "Severin, Tony, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7918,
      "name": "Sevrin, Oscar S.J. Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7919,
      "name": "Sevrin, Oscar, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7920,
      "name": "Sewell, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7921,
      "name": "Seyed-Gohrab, Ali Asghar (1968-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7922,
      "name": "Shackleton, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7923,
      "name": "Shadid, W.A.R. & Van Koningsveld, P.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7924,
      "name": "Shah Ghanshyam [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7925,
      "name": "Shah, K.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7926,
      "name": "Shah, Kanti",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7927,
      "name": "Shahani, T.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7928,
      "name": "Shah-Kazemi, Reza",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7929,
      "name": "Shahrazuri, Ibn al-Salah & Dickinson, Eerik [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7930,
      "name": "Shairp, Principal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7931,
      "name": "Shakespeare, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7932,
      "name": "Shakespeare, William & Beeching, H.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7933,
      "name": "Shakespeare, William & Cholmeles, R.F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7934,
      "name": "Shakespeare, William & Dodd, William [rev.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7935,
      "name": "Shakespeare, William & Gangaprasad [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7936,
      "name": "Shakespeare, William & Parry, Thomas [introd.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7937,
      "name": "Shakespeare, William & Rolfe, W.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7938,
      "name": "Shakespeare, William & Verity, A.W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7939,
      "name": "Shakespeare, William & Wright, A. William [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7940,
      "name": "Shakespeare, William, Clark, W.G. [ed.] & Wright, W. Aldis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7941,
      "name": "Shakespeare, William, Clarke, W. George [ed.] & Wright, W. Aldis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7942,
      "name": "Shand, Alexander Innes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7943,
      "name": "Sharan S.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7944,
      "name": "Sharp John K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7945,
      "name": "Sharpe Eric J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7946,
      "name": "Sharpe, A.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7947,
      "name": "Sharvananda, Swami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7948,
      "name": "Shaukat Ali Khan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7949,
      "name": "Shaw, Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7950,
      "name": "Shaw, Bernard [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7951,
      "name": "Shaw, Frances",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7952,
      "name": "Shaw, Sarah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7953,
      "name": "Shaw, Thomas B. & Smith, W. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7954,
      "name": "Shaw, Thomas B. & Smith, William [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7955,
      "name": "Sheed, F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7956,
      "name": "Sheehan Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7957,
      "name": "Sheehan P.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7958,
      "name": "Sheehan, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7959,
      "name": "Sheehan, P.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7960,
      "name": "Sheen Fulton J. (1895-1979)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7961,
      "name": "Sheen, Fulton J. (1895-1979)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7962,
      "name": "Shekhawat, Virendra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7963,
      "name": "Sheldon Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7964,
      "name": "Shelley Percy Bysshe & Rhys Ernest [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7965,
      "name": "Shelley Percy Bysshe & Rosetti W.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7966,
      "name": "Shelley Percy Bysshe & Scott William B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7967,
      "name": "Shepherd, Laurence, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7968,
      "name": "Sheppard John A. Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7969,
      "name": "Sheppard John G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7970,
      "name": "Sherer, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7971,
      "name": "Sheridan, John D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7972,
      "name": "Sherman, James Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7973,
      "name": "Sherwin, Michael S., O.P. (1963-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7974,
      "name": "Shields, B.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7975,
      "name": "Shiels, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7976,
      "name": "Shihadeh, Ayman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7977,
      "name": "Shihadeh, Ayman [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7978,
      "name": "Shilananda Swami S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7979,
      "name": "Shipley, Joseph T. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7980,
      "name": "Shipley, Laurence & McNabb, Vincent J.,  O.P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7981,
      "name": "Shipley, Orby",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7982,
      "name": "Shipley, Orby [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7983,
      "name": "Shirazi, Faegheh (1952-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7984,
      "name": "Shirazi, Imam Muhammad & Adam, Ali Ibn [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7985,
      "name": "Shirazi, Imam Muhammad; Adm, Ali Ibn [transl.] & Olyabeck, Z. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7986,
      "name": "Shiri, Godwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7987,
      "name": "Shirley, Walter Waddington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7988,
      "name": "Shivram, Balkrishan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7989,
      "name": "Shoen, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7990,
      "name": "Shokoohy, Mehrdad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7991,
      "name": "Sholokhov Mikhail & Garry Stephen [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7992,
      "name": "Shome, P. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7993,
      "name": "Short, Ernest H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7994,
      "name": "Shortland, John R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7995,
      "name": "Shrimali, Krishna Mohan (1947-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7996,
      "name": "Shuler, John L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7997,
      "name": "Shulman, David Dean (1949-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7998,
      "name": "Shults, F. LeRon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 7999,
      "name": "Shuster, George N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8000,
      "name": "Sian, Katy P. (1984-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8001,
      "name": "Sicard Augustin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8002,
      "name": "Sickenberger Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8003,
      "name": "Siddiqui Abdur Rashid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8004,
      "name": "Siddiqui, Z.M. Shahid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8005,
      "name": "Sidgwick Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8006,
      "name": "Sidgwick, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8007,
      "name": "Sidney, Philip & Macardle, Dorothy M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8008,
      "name": "Siegfried, Andre, Hemming, H.H. [transl.] & Hemming, Doris [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8009,
      "name": "Siegmund, Georg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8010,
      "name": "Sienkiewicz, Henryk",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8011,
      "name": "Sifton, Clifford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8012,
      "name": "Sigerus De Brabantia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8013,
      "name": "Signer Michael Alan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8014,
      "name": "Signoretto, Martino",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8015,
      "name": "Sikand, Yoginder S. (1967-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8016,
      "name": "Siksarthi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8017,
      "name": "Sil, Rita D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8018,
      "name": "Silberrad Una L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8019,
      "name": "Silliman Ives L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8020,
      "name": "Silva-Tarouca Carolus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8021,
      "name": "Silver, S.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8022,
      "name": "Silverman, Max",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8023,
      "name": "Simeon, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8024,
      "name": "Simh, Babu Damodar Sahay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8025,
      "name": "Simh, Durgaprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8026,
      "name": "Simha, Radhikaraman Prasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8027,
      "name": "Simha, Udayaraj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8028,
      "name": "Simha, V. B. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8029,
      "name": "Simon Sebastian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8030,
      "name": "Simon, J., O.S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8031,
      "name": "Simon, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8032,
      "name": "Simon, Pierre-Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8033,
      "name": "Simon, Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8034,
      "name": "Simon, Richard (Pseud.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8035,
      "name": "Simon, Richard C. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8036,
      "name": "Simon, Yves",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8037,
      "name": "Simonnet, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8038,
      "name": "Simons, Jozef",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8039,
      "name": "Simonson, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8040,
      "name": "Simpson, A.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8041,
      "name": "Simpson, Hubert L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8042,
      "name": "Simpson, W.J. Sparrow",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8043,
      "name": "Simson Wolfgang",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8044,
      "name": "Sinclair, May",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8045,
      "name": "Sinety Robert De S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8046,
      "name": "Singh David Emmanuel [Ed.] & Schick Robert Edwin [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8047,
      "name": "Singh Digvijay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8048,
      "name": "Singh Godwin Rajinder",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8049,
      "name": "Singh Khushwant & Kumar Neelam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8050,
      "name": "Singh Pashaura (Ed.) & Barrier N. Gerald [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8051,
      "name": "Singh S.S. Misra Suresh & Pratap Sanjay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8052,
      "name": "Singh Satya Prakash",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8053,
      "name": "Singh Uma",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8054,
      "name": "Singh Wazir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8055,
      "name": "Singh Wazir [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8056,
      "name": "Singh Wazir [ed.] & Singh Nagendra Kr. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8057,
      "name": "Singh, Awadhesh Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8058,
      "name": "Singh, Ekta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8059,
      "name": "Singh, Iqbal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8060,
      "name": "Singh, K.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8061,
      "name": "Singh, Maharaj Jagat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8062,
      "name": "Singh, Mohinder",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8063,
      "name": "Singh, Natal Peregrino",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8064,
      "name": "Singh, Pashaura",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8065,
      "name": "Singh, Sundar Sadhu",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8066,
      "name": "Singh, Upinder",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8067,
      "name": "Singhal, Arvind & Rogers, Everett M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8068,
      "name": "Singharoy, Debal K. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8069,
      "name": "Sinha, A.C. [ed.] & Subba, Tanka Bahadur [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8070,
      "name": "Sinha, Archana [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8071,
      "name": "Sinha, Nandita",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8072,
      "name": "Sinker, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8073,
      "name": "Sinn, Simone [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8074,
      "name": "Sinn, Simone [ed.] & Trice, Michael Reid [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8075,
      "name": "Sinnett, A.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8076,
      "name": "Sipos, Stephanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8077,
      "name": "Sire Volta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8078,
      "name": "Sirico Robert A. [ed.] & Zieba Maciej O.P.  [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8079,
      "name": "Sisson, C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8080,
      "name": "Sitalasahay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8081,
      "name": "Sitaramayya K.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8082,
      "name": "Sitaramayya, Pattabhi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8083,
      "name": "Siva Rao B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8084,
      "name": "Sivkumar Muni",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8085,
      "name": "Skeat Walter William & Blagden Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8086,
      "name": "Skinner, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8087,
      "name": "Skinner, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8088,
      "name": "Skrefsrud, Lars Olsen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8089,
      "name": "Sladen, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8090,
      "name": "Slafkosky, Andrew Leonard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8091,
      "name": "Slater Thomas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8092,
      "name": "Slater, Gilbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8093,
      "name": "Slater, T.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8094,
      "name": "Slater, Thomas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8095,
      "name": "Slaughter Edward S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8096,
      "name": "Slipyj Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8097,
      "name": "Sloan J.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8098,
      "name": "Slosson, Edwin E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8099,
      "name": "Smend, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8100,
      "name": "Smiles, Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8101,
      "name": "Smit, Wim [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8102,
      "name": "Smith Eric G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8103,
      "name": "Smith F. Harold.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8104,
      "name": "Smith F.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8105,
      "name": "Smith George D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8106,
      "name": "Smith Henry Preserved",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8107,
      "name": "Smith James Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8108,
      "name": "Smith Joseph Oswald O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8109,
      "name": "Smith Judith F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8110,
      "name": "Smith Lucy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8111,
      "name": "Smith Taylor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8112,
      "name": "Smith Walter George & Smith Helen Grace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8113,
      "name": "Smith William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8114,
      "name": "Smith William Carlson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8115,
      "name": "Smith, Adam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8116,
      "name": "Smith, David, MSC (1944-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8117,
      "name": "Smith, E.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8118,
      "name": "Smith, Edward F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8119,
      "name": "Smith, G. Elliot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8120,
      "name": "Smith, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8121,
      "name": "Smith, George Adam",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8122,
      "name": "Smith, Goldwin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8123,
      "name": "Smith, Grafton Elliot",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8124,
      "name": "Smith, H. Maynard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8125,
      "name": "Smith, Hannah Whitall",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8126,
      "name": "Smith, Harold Boyt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8127,
      "name": "Smith, J., Ward, Wilfrid & Rewer, J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8128,
      "name": "Smith, John Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8129,
      "name": "Smith, R. Bosworth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8130,
      "name": "Smith, Richard Travers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8131,
      "name": "Smith, S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8132,
      "name": "Smith, Samuel George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8133,
      "name": "Smith, Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8134,
      "name": "Smith, Theodore Clarke",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8135,
      "name": "Smith, Uriah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8136,
      "name": "Smith, Vincent A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8137,
      "name": "Smith, W. Robertson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8138,
      "name": "Smith, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8139,
      "name": "Smith, William & Cheetham, Samuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8140,
      "name": "Smith, William & Wace, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8141,
      "name": "Smith, William J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8142,
      "name": "Smollet Tobias",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8143,
      "name": "Smulders, J.-N.-J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8144,
      "name": "Smyth, J. Paterson",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8145,
      "name": "Snaith, Norman H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8146,
      "name": "Snead-Cox J.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8147,
      "name": "Sneyd-Kynnersley, E.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8148,
      "name": "Snieders, August",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8149,
      "name": "Snieders, Renier",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8150,
      "name": "Soares-Prabhu, George M., S.J. & D'Sa, Francis Xavier, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8151,
      "name": "Soares-Prabhu, George M., S.J. & Kuthirakkattel, Scaria, SVD [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8152,
      "name": "Soares-Prabhu, George M., S.J. & Padinjarekuttu, Isaac [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8153,
      "name": "Sobanaraj, S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8154,
      "name": "Soden, Hans Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8155,
      "name": "Soderini Eduardo Conte & Jenery-Shee Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8156,
      "name": "Sofield Loughlan S.T. & Kuhn Donald H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8157,
      "name": "Sofield Loughlan S.T. Hammett Rosine C.S.C. & Juliano Carroll SHCJ",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8158,
      "name": "Sofield Loughlan S.T. Juliano Carroll SHCJ & Hammett Rosine C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8159,
      "name": "Soglia Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8160,
      "name": "Sohm, Rudolf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8161,
      "name": "Soil Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8162,
      "name": "Solages Bruno De Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8163,
      "name": "Solages, Bruno de, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8164,
      "name": "Sollas W.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8165,
      "name": "Solonevich, Ivan & Harrow, Warren [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8166,
      "name": "Soloviev, Vladimir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8167,
      "name": "Soltau, Wilhelm",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8168,
      "name": "Sombart, Werner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8169,
      "name": "Somers, Andrew, S.M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8170,
      "name": "Somerville Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8171,
      "name": "Somerville, Augustus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8172,
      "name": "Somerville, E.O. & Ross, Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8173,
      "name": "Somerville, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8174,
      "name": "Somesvara, Suryarama, Yajnika, J.S. [transl.] & Bhatta, K.N. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8175,
      "name": "Sommer John G. (1941-   )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8176,
      "name": "Sommervogel Carlos S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8177,
      "name": "Sondhi S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8178,
      "name": "Sondhi, M.L. [ed.] & Mukhopadhyay, Apratim [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8179,
      "name": "Sontag, P.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8180,
      "name": "Sookhdeo, Patrick [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8181,
      "name": "Sopan (Real Name",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8182,
      "name": "Sophocles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8183,
      "name": "Soras, Alfred de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8184,
      "name": "Sorensen, Jorgen Skov",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8185,
      "name": "Sorley, W.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8186,
      "name": "Sorokin Pitirim A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8187,
      "name": "Soto, Dominicus, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8188,
      "name": "Soubiran Marie-Therese de Mere.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8189,
      "name": "Soubiran, Marie-Therese De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8190,
      "name": "Souilhe, Josephus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8191,
      "name": "Soulary, Josephin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8192,
      "name": "Soult, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8193,
      "name": "Souter, Alexandar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8194,
      "name": "Souter, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8195,
      "name": "Southwell Robert S.J. Bl.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8196,
      "name": "Spacil Theophilus S.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8197,
      "name": "Spacil, Theophilus, S.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8198,
      "name": "Spalding M.J. Archbp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8199,
      "name": "Spalding, Henry S., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8200,
      "name": "Spearman, Frank H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8201,
      "name": "Speight, T.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8202,
      "name": "Spellman, Francis J., Archb.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8203,
      "name": "Spence Lewis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8204,
      "name": "Spencer Baldwin & Gillen F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8205,
      "name": "Spencer Francis Aloysius O.P. Callan Charles J. O.P. [ed.] & Mchugh J.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8206,
      "name": "Spencer, Francis Aloysius, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8207,
      "name": "Spencer, Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8208,
      "name": "Spender, J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8209,
      "name": "Spengler, Oswald & Atkinson, Charles Francis [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8210,
      "name": "Spenser, Edmund & Herford, C.H. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8211,
      "name": "Sperry, Willard L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8212,
      "name": "Spicq, Ceslas, O.P. (1901-1933)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8213,
      "name": "Spiers, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8214,
      "name": "Spiess-Heynachers",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8215,
      "name": "Spillebout L. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8216,
      "name": "Spink Peter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8217,
      "name": "Spinks, Bryan D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8218,
      "name": "Spinola, Fabius Ambrosius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8219,
      "name": "Spinoza, Baruch",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8220,
      "name": "Spirago Francis & Clarke Richard F. S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8221,
      "name": "Spitzen, O.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8222,
      "name": "Spurgeon, C.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8223,
      "name": "Spurgeon, Charles Haddon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8224,
      "name": "Squire, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8225,
      "name": "Srampickal, Jacob, S.J. & Joseph, Leela",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8226,
      "name": "Srinathasimha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8227,
      "name": "Srinivas, M.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8228,
      "name": "Sripati & Rao, Hayavadana C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8229,
      "name": "Sris, Isadatt Sastri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8230,
      "name": "Srivastav, Navajadikalal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8231,
      "name": "Srivastava, Pratapnarayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8232,
      "name": "St Clair, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8233,
      "name": "St Leger, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8234,
      "name": "St. Austin, Mother, S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8235,
      "name": "Staab, Karl",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8236,
      "name": "Staal, Frits",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8237,
      "name": "Stack, Frederick William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8238,
      "name": "Stadelman, W.F., C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8239,
      "name": "Staley, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8240,
      "name": "Staley, Vernon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8241,
      "name": "Stalin, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8242,
      "name": "Stall Sylvanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8243,
      "name": "Stamp Josiah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8244,
      "name": "Stamp, L. Dudley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8245,
      "name": "Stamp, Lord",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8246,
      "name": "Standaert, Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8247,
      "name": "Stanislas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8248,
      "name": "Stanley, Arthur Penrhyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8249,
      "name": "Stanley, Henry M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8250,
      "name": "Stanton, A.J. Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8251,
      "name": "Stanton, Devas Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8252,
      "name": "Stanton, Graham N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8253,
      "name": "Stanton, Vincent Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8254,
      "name": "Stapledon, Olaf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8255,
      "name": "Stark, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8256,
      "name": "Stark, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8257,
      "name": "Starkie, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8258,
      "name": "Statham, H. Heathcote",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8259,
      "name": "Stebbing George C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8260,
      "name": "Stebbing, George, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8261,
      "name": "Stedmann, A.M.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8262,
      "name": "Steenkiste, J., S.J. Fr. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8263,
      "name": "Steffler, Alva Willaim",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8264,
      "name": "Steidle, Basilius, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8265,
      "name": "Stein Valerie A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8266,
      "name": "Stein, Edith (1891-1942), Gelber, L. [ed.], Leuven, Romaeus [ed.] & Koeppel, Josephine [transl.[]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8267,
      "name": "Stein, M. Aurel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8268,
      "name": "Stein, Sir Aurel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8269,
      "name": "Steinberg, S.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8270,
      "name": "Steinmann, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8271,
      "name": "Steinmueller, John E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8272,
      "name": "Stenger, Joseph Bernard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8273,
      "name": "Stenson M.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8274,
      "name": "Stenzel Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8275,
      "name": "Stephan, Leslie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8276,
      "name": "Stephanus, Henricus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8277,
      "name": "Stephen Muthunayakom (1959-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8278,
      "name": "Stephen, Leslie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8279,
      "name": "Stephen, Muthunayakom (1959-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8280,
      "name": "Stephen, S. Jeyaseela",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8281,
      "name": "Stephens, H. Morse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8282,
      "name": "Steppat, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8283,
      "name": "Sterckx R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8284,
      "name": "Stern, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8285,
      "name": "Sterne, Lawrence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8286,
      "name": "Stethatos Nicetas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8287,
      "name": "Steuart R.H.J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8288,
      "name": "Steuart, R.H.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8289,
      "name": "Steuernagel Dankfried",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8290,
      "name": "Stevens Abel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8291,
      "name": "Stevens, George Barker",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8292,
      "name": "Stevenson, Robert Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8293,
      "name": "Stewart, Dugald & Bart, William Hamilton [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8294,
      "name": "Stewart, Pamela J. [ed.] & Strathern, Andrew [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8295,
      "name": "Stier Rudolf & Theise K.G.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8296,
      "name": "Stier, Rudolf & Pope, W.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8297,
      "name": "Stiglitz, Joseph E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8298,
      "name": "Stiglmayr Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8299,
      "name": "Stirling John F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8300,
      "name": "Stirling, John F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8301,
      "name": "Stock, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8302,
      "name": "Stock, St. George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8303,
      "name": "Stocker, R. Dimsdale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8304,
      "name": "Stocqueler, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8305,
      "name": "Stoddard, John L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8306,
      "name": "Stokes, G.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8307,
      "name": "Stokes, G.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8308,
      "name": "Stolz, Anselme, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8309,
      "name": "Stone Howard W. & Duke James O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8310,
      "name": "Stone Michael Edward (1938-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8311,
      "name": "Stone, Darwell & Puller, F.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8312,
      "name": "Stonehouse, G.G.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8313,
      "name": "Stonner Anton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8314,
      "name": "Stopes, Marie Carmichael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8315,
      "name": "Storchenav Sigismundus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8316,
      "name": "Storkey Elaine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8317,
      "name": "Storm, Theodor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8318,
      "name": "Stormonth, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8319,
      "name": "Stormonth, James, Phelp, P.R. & Bayne, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8320,
      "name": "Stowe, Harriet Beecher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8321,
      "name": "Strabolgi, R.N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8322,
      "name": "Strachey, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8323,
      "name": "Strachey, Lytton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8324,
      "name": "Strack, Hermann L. & Billerbeck, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8325,
      "name": "Strack, Hermann L. & Siegfried, Carl.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8326,
      "name": "Strada, Famianus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8327,
      "name": "Strahan, S.A.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8328,
      "name": "Strappini Walter Diver S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8329,
      "name": "Straub, Werner",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8330,
      "name": "Straubinger Heinrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8331,
      "name": "Strauss, David Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8332,
      "name": "Streeter, Burnett Hillman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8333,
      "name": "Streit, F.C., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8334,
      "name": "Streton, Hesba",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8335,
      "name": "Streuvels, Styn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8336,
      "name": "Strindberg, August & Field, Claud [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8337,
      "name": "Strowski Fortunat",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8338,
      "name": "Struye, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8339,
      "name": "Stuart, Ernest I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8340,
      "name": "Stuart, Janet Erskine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8341,
      "name": "Stubbe, A., C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8342,
      "name": "Stubbs, Charles William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8343,
      "name": "Stuckrad, Kocku von [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8344,
      "name": "Stucley, Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8345,
      "name": "Stufler, Johann, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8346,
      "name": "Stummer Friederich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8347,
      "name": "Sturge, M. Carta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8348,
      "name": "Sturm Ch.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8349,
      "name": "Sturzo, Luigi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8350,
      "name": "Sturzo, Luigi & Carter, Barbara Barclay [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8351,
      "name": "Stutterheim, Willem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8352,
      "name": "Suarez Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8353,
      "name": "Suau Pierre S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8354,
      "name": "Suau, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8355,
      "name": "Subanar Gregorius Budi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8356,
      "name": "Subbarayappa B.V. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8357,
      "name": "Subbarayappa, B.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8358,
      "name": "Subramuniyaswami, Sivaya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8359,
      "name": "Sudan, Falendra K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8360,
      "name": "Sudarsan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8361,
      "name": "Sudbrack Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8362,
      "name": "Sue, Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8363,
      "name": "Suetonius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8364,
      "name": "Sugirtharajah, R.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8365,
      "name": "Suh, David Kwang-Sun [ed.], Meuthrath, Annette [ed.] & Hyondok, Choe [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8366,
      "name": "Sukla, Ram Candra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8367,
      "name": "Sukla, Ramsankar 'rasal'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8368,
      "name": "Sukumar Simon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8369,
      "name": "Sukumar, Sivakumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8370,
      "name": "Sullerot Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8371,
      "name": "Sullerot, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8372,
      "name": "Sullivan Francis A. S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8373,
      "name": "Sullivan, Maureen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8374,
      "name": "Sully Prudhomme",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8375,
      "name": "Sully, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8376,
      "name": "Sulpicius Severus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8377,
      "name": "Sumana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8378,
      "name": "Sumana, Ramnath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8379,
      "name": "Summer, Graham William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8380,
      "name": "Sumner, B.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8381,
      "name": "Sundararaj, S. James [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8382,
      "name": "Sundararajan, K.R. [ed.] & Mukhopadhyay, Bithika [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8383,
      "name": "Sunol Gregoire O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8384,
      "name": "Super, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8385,
      "name": "Surbled Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8386,
      "name": "Surin, Jean-Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8387,
      "name": "Surin, Jean-Joseph, S.J. & Bouix, Marcel, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8388,
      "name": "Surin, Jean-Joseph, S.J. & Christopher, M., O.S.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8389,
      "name": "Surin, Jean-Joseph, S.J., Michel, Louis [ed.] & Cavallera, Ferdinand, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8390,
      "name": "Suso, Henricus, O.P., Stus. (1295-1366)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8391,
      "name": "Suso, Henricus, O.P., Stus. (1295-1366) & Knox, Th. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8392,
      "name": "Sutcliffe, Edmund S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8393,
      "name": "Sutcliffe, Halliwell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8394,
      "name": "Sutherland Halliday",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8395,
      "name": "Sutherland John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8396,
      "name": "Sutherland, Alexander",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8397,
      "name": "Svensson, Jon, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8398,
      "name": "Svoboda, Melannie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8399,
      "name": "Swann Alfred J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8400,
      "name": "Swanson, O.L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8401,
      "name": "Swarts G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8402,
      "name": "Swarup, Ram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8403,
      "name": "Swedenborg, Emanuel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8404,
      "name": "Sweet, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8405,
      "name": "Sweetman, James Windrow",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8406,
      "name": "Swete, H. Barclay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8407,
      "name": "Swete, Henry Barclay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8408,
      "name": "Swete, Henry Barclay [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8409,
      "name": "Swete, Henry Barla",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8410,
      "name": "Swift, Jonathan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8411,
      "name": "Swolfs, J.J.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8412,
      "name": "Syamsundardas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8413,
      "name": "Sydow, E. Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8414,
      "name": "Sykes, Marjorie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8415,
      "name": "Sykes, Percy M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8416,
      "name": "Sykes, W. Stanley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8417,
      "name": "Sylvius, Franciscus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8418,
      "name": "Symonds H. Edward C.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8419,
      "name": "Symonds, J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8420,
      "name": "Symonds, John Addington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8421,
      "name": "Symonides Janusz [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8422,
      "name": "Symons A.J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8423,
      "name": "Szczepanski, Ladislaus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8424,
      "name": "Szekely Stephanus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8425,
      "name": "Szypula, Wojciech",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8426,
      "name": "Tabari Abu Ja'far Muhammad B. Jarir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8427,
      "name": "Tabart Jill",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8428,
      "name": "Taberd J.L. Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8429,
      "name": "Taberna Joannes-Baptista S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8430,
      "name": "Tacchi Venturi Pietro S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8431,
      "name": "Tachard, Aug., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8432,
      "name": "Tachard, Guy, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8433,
      "name": "Tacitus, Caius Cornelius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8434,
      "name": "Taft, Robert F., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8435,
      "name": "Taft, Robert F., S.J. [ed.] & Winkler, Gabriele [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8436,
      "name": "Taghi, Shokoufeh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8437,
      "name": "Tahon Joseph V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8438,
      "name": "Taine, H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8439,
      "name": "Talbert, Charles H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8440,
      "name": "Talbot, Michel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8441,
      "name": "Talmon, Shemaryahu [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8442,
      "name": "Tamborini A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8443,
      "name": "Tandan, Premanarayana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8444,
      "name": "Tandana, Premanarayana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8445,
      "name": "Tannehill, Robert C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8446,
      "name": "Tanner Norman P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8447,
      "name": "Tanner, Norman P., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8448,
      "name": "Tanquerey Adolphus S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8449,
      "name": "Tanquerey Adulfus S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8450,
      "name": "Tanquerey, Adolphus, S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8451,
      "name": "Taparelli D'Azeglio Luigi S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8452,
      "name": "Tapasyananda Swami [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8453,
      "name": "Tarcisius, O.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8454,
      "name": "Tarkington, Booth",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8455,
      "name": "Tarquini Camillus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8456,
      "name": "Tarr John C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8457,
      "name": "Tasso, Torquato & Wiffin, J.H. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8458,
      "name": "Tatius & Theophilus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8459,
      "name": "Tauber, Rudolph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8460,
      "name": "Tauler Joannes O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8461,
      "name": "Tauler, Joannes, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8462,
      "name": "Taunton Ethelred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8463,
      "name": "Taussig, F.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8464,
      "name": "Taveau Claude C. Orat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8465,
      "name": "Taveggia, Santino, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8466,
      "name": "Tawney, R.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8467,
      "name": "Taxil, Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8468,
      "name": "Tayler, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8469,
      "name": "Taylor Isaac",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8470,
      "name": "Taylor, A.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8471,
      "name": "Taylor, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8472,
      "name": "Taylor, Charles W. (1937-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8473,
      "name": "Taylor, F. Isabel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8474,
      "name": "Taylor, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8475,
      "name": "Taylor, Henry Osborn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8476,
      "name": "Taylor, Jeremy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8477,
      "name": "Taylor, Jeremy (1613-1667)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8478,
      "name": "Taylor, Meadows",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8479,
      "name": "Taylor, Vincent",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8480,
      "name": "Taylor, W. Cooke",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8481,
      "name": "Taymans d'Eypernon Francis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8482,
      "name": "Teeling, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8483,
      "name": "Teetaert Amedee O. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8484,
      "name": "Teil Roger De Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8485,
      "name": "Teilhard de Chardin, Pierre, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8486,
      "name": "Teixeira A.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8487,
      "name": "Tel, Othman Ismael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8488,
      "name": "Telch Carolus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8489,
      "name": "Tellenbach, Gerd. & Bennett, R.F. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8490,
      "name": "Tellier, L., S.J. & Holstain, H., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8491,
      "name": "Temple, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8492,
      "name": "Ten Bruggencate K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8493,
      "name": "Tennant, F.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8494,
      "name": "Tennent Timothy C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8495,
      "name": "Tennyson Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8496,
      "name": "Tennyson, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8497,
      "name": "Tennyson, Alfred & Rowe, F.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8498,
      "name": "Tensing, Robert H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8499,
      "name": "Teppa Alexandre Barnabite",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8500,
      "name": "Ter Haar, Franciscus, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8501,
      "name": "Ter Linden Nico & Bowden John [Transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8502,
      "name": "Terentius, Publius Afer",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8503,
      "name": "Teresa De Cepeda Y Ahumada, O.Carm., Sa. (1515-1582)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8504,
      "name": "Teresa De Cepeda Y Ahumada, O.Carm., Sa. (1515-1582) & Carrigan, Henry L., Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8505,
      "name": "Teresa De Cepeda Y Ahumada, O.Carm., Sa. (1515-1582) & Lewis, David [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8506,
      "name": "Teresa Gertrude Of The Bl. Sacrament Sister",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8507,
      "name": "Termier Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8508,
      "name": "Termier, Pierre",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8509,
      "name": "Ternest, K. L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8510,
      "name": "Terreni Guido",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8511,
      "name": "Terrien Jacques S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8512,
      "name": "Terrien, Jean-Baptiste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8513,
      "name": "Terstriep Dominik",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8514,
      "name": "Tertullianus Florens Quintus Septimus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8515,
      "name": "Tertullianus Florens, Quintus Septimius, Thelwall, S. [transl.] & Holmes [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8516,
      "name": "Tertullianus Florens, Quintus Septimus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8517,
      "name": "Terwecoren Ed. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8518,
      "name": "Tesniere, A., Congr. SS. Sacr. & Bennett-Gladstone, Anne R., Mrs. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8519,
      "name": "Testore Celestino S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8520,
      "name": "Testore, Celestino, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8521,
      "name": "Texier, Claude, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8522,
      "name": "Thacker, Cathy Gillen, Cresswell, Jasmine, Sanders, Glenda & Chittenden, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8523,
      "name": "Thackeray, H. St. John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8524,
      "name": "Thackeray, William Makepeace",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8525,
      "name": "Thadathil, George [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8526,
      "name": "Thakur Ravindranath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8527,
      "name": "Thakur, Jyotirmayi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8528,
      "name": "Thakur, Kesav Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8529,
      "name": "Thakur, Ravindranath",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8530,
      "name": "Thakur, Ravindranath & Dvivedi, D. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8531,
      "name": "Thakur, Ravindranath & Jha, J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8532,
      "name": "Thakur, Ravindranath & Saha, Jadu [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8533,
      "name": "Thamburaj, A.J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8534,
      "name": "Thamiry, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8535,
      "name": "Thaninayagam, Xavier Stanislaus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8536,
      "name": "Thapar, Romila",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8537,
      "name": "Tharappel, Thomas, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8538,
      "name": "Thatcher, G.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8539,
      "name": "Thayer Joseph Henry [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8540,
      "name": "Theil, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8541,
      "name": "Theile, Car. Godofr. Guil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8542,
      "name": "Theimer, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8543,
      "name": "Theissen, Gerd & Green, David E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8544,
      "name": "Thekkekara, Mathew V., SDB",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8545,
      "name": "Thellier de Poncheville",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8546,
      "name": "Theloz J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8547,
      "name": "Thenhaven Bernardinus O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8548,
      "name": "Theodoretus, Cyrensis, Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8549,
      "name": "Theophilus, Reypens, Leonce, S.J. (Pseudon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8550,
      "name": "Theoret, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8551,
      "name": "Therese De L'Enfant Jesus (1873-1897)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8552,
      "name": "Therese De L'Enfant Jesus (1873-1897) & De La Ste. Face O. Carm. Ste.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8553,
      "name": "Therese De L'Enfant Jesus (1873-1897) & De La Ste. Face, O. Carm., Ste.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8554,
      "name": "Therese De L'Enfant Jesus (1873-1897) De La Ste. Face O. Carm. Ste. & Taylor Th. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8555,
      "name": "Thermes Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8556,
      "name": "Thery, P.G., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8557,
      "name": "Thesaurus Carolus Antonius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8558,
      "name": "Thiagaraj, Henry [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8559,
      "name": "Thibaut Eugene S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8560,
      "name": "Thibaut Raymond O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8561,
      "name": "Thibaut, J.-B., C.A.As.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8562,
      "name": "Thibaut, Raymond, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8563,
      "name": "Thibaut, Rene, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8564,
      "name": "Thibon, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8565,
      "name": "Thibout, Marc & Dimier, M.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8566,
      "name": "Thiergen & Hamann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8567,
      "name": "Thiers, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8568,
      "name": "Thils, Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8569,
      "name": "Thiriet, Julien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8570,
      "name": "Thirion, Jules, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8571,
      "name": "Thiselton, Anthony C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8572,
      "name": "Thomas A Kempis Can. Reg. S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8573,
      "name": "Thomas A Kempis Can. Reg. S.A. & Paul C. Kegan [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8574,
      "name": "Thomas A Kempis Can. Reg. S.Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8575,
      "name": "Thomas A Kempis, Can. Reg. S.A",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8576,
      "name": "Thomas A Kempis, Can. Reg. S.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8577,
      "name": "Thomas A Kempis, Can. Reg. S.Aug.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8578,
      "name": "Thomas A Kempis, Can. Reg. S.Aug. & Arthur, J.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8579,
      "name": "Thomas A Kempis, Can. Reg. S.Aug. & Byrne, Mgr. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8580,
      "name": "Thomas Aquinas O.P. Stus. (1225?-1274)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8581,
      "name": "Thomas Aquinas, O.P., Stus. (1225?-1274)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8582,
      "name": "Thomas Aquinas, O.P., Stus. (1225?-1274) & Ashley, John M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8583,
      "name": "Thomas De Sutton O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8584,
      "name": "Thomas N.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8585,
      "name": "Thomas William I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8586,
      "name": "Thomas, David M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8587,
      "name": "Thomas, Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8588,
      "name": "Thomas, Mar, Bishop Of Marga & Budge, E.A. Wallis [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8589,
      "name": "Thomas, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8590,
      "name": "Thomas, P.C. & Carmel, Neelu, C.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8591,
      "name": "Thompson Francis & Pollen John Hungerford S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8592,
      "name": "Thompson Henry O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8593,
      "name": "Thompson, Daniel Greenleaf",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8594,
      "name": "Thompson, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8595,
      "name": "Thompson, J.V.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8596,
      "name": "Thompson, Newton & Stock, Raymond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8597,
      "name": "Thomson, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8598,
      "name": "Thomson, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8599,
      "name": "Thondiparambil, Joseph [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8600,
      "name": "Thonissen J.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8601,
      "name": "Thoonen, J.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8602,
      "name": "Thoppil, James, D.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8603,
      "name": "Thormeyer, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8604,
      "name": "Thorne, Guy",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8605,
      "name": "Thornhill, Alan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8606,
      "name": "Thornhill, John, S.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8607,
      "name": "Thornton, L.S., C.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8608,
      "name": "Thornton, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8609,
      "name": "Thorold, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8610,
      "name": "Thottakara Augustine CMI [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8611,
      "name": "Thouverez Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8612,
      "name": "Thucydides",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8613,
      "name": "Thumma Anthoniraj",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8614,
      "name": "Thurber, Robert Bruce & Rowell, Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8615,
      "name": "Thurston, Herbert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8616,
      "name": "Thurston, Katherine Cecil",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8617,
      "name": "Tickell George S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8618,
      "name": "Tierney, Helen [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8619,
      "name": "Till Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8620,
      "name": "Tillmann, Fritz",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8621,
      "name": "Timani, Hussam S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8622,
      "name": "Timasheff, N.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8623,
      "name": "Timbs, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8624,
      "name": "Timm Richard W. C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8625,
      "name": "Timmerman, Christiane [ed.], Hutsebaut, Dirk [ed.], Mels, Sara [ed.] & Nonneman, Walter [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8626,
      "name": "Timmermans, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8627,
      "name": "Tirinus Jacobus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8628,
      "name": "Tirkey Bonifas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8629,
      "name": "Tiroler, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8630,
      "name": "Tischendorf, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8631,
      "name": "Tischendorf, Constantinus De.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8632,
      "name": "Tischendorf, Constantius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8633,
      "name": "Tisdell, Clem",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8634,
      "name": "Tisserant Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8635,
      "name": "Tissier Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8636,
      "name": "Titmarsh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8637,
      "name": "Titmuss, Richard M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8638,
      "name": "Titus, P.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8639,
      "name": "Tiwari, Ravi & Kumar, Sunil [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8640,
      "name": "Tixeront J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8641,
      "name": "Tixeront, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8642,
      "name": "Tobac Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8643,
      "name": "Tobac, Edouard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8644,
      "name": "Tobdan & Dorje, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8645,
      "name": "Todd, John Murray",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8646,
      "name": "Todhunter, I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8647,
      "name": "Tolstoy Leo Nikolaevich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8648,
      "name": "Tolstoy, Leo Nikolaevich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8649,
      "name": "Tom, Melchior [et Al.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8650,
      "name": "Tombs, David [ed.] & Liechty, Joseph [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8651,
      "name": "Tomlin, Graham",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8652,
      "name": "Toner Jules J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8653,
      "name": "Tong, K. Hollington",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8654,
      "name": "Tongiorgi Salvator S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8655,
      "name": "Tonquedec, Joseph de, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8656,
      "name": "Topelius, Z.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8657,
      "name": "Topinard Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8658,
      "name": "Torella, Raffaele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8659,
      "name": "Torrance, Thomas F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8660,
      "name": "Torres Amat, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8661,
      "name": "Tosi, Clemente, Congr.Sylv",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8662,
      "name": "Toso, Albertus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8663,
      "name": "Toth Tihamer Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8664,
      "name": "Toth, Tihamer, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8665,
      "name": "Toth, Tihamer, Mgr. & Agotai V.G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8666,
      "name": "Totting De Oyta Henricus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8667,
      "name": "Toulemonde Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8668,
      "name": "Tournebize Francois S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8669,
      "name": "Toussaint J.P. & Miller J.P. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8670,
      "name": "Tout, T.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8671,
      "name": "Toutain, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8672,
      "name": "Touzard J. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8673,
      "name": "Tovey Derek",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8674,
      "name": "Towers E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8675,
      "name": "Townsend, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8676,
      "name": "Townsend, R.S. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8677,
      "name": "Toy, Crawford H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8678,
      "name": "Toynbee, Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8679,
      "name": "Traber, Michael [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8680,
      "name": "Tracey, Herbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8681,
      "name": "Traill, H.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8682,
      "name": "Trapp, Maria Augusta",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8683,
      "name": "Trappes-Lomax Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8684,
      "name": "Trappes-Lomax, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8685,
      "name": "Trautz, Friedrich M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8686,
      "name": "Travers, Ben",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8687,
      "name": "Travers-Borgstroem Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8688,
      "name": "Treble, H.A. & Vallins, G.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8689,
      "name": "Trebolle Barrera, Julio C. & Watson, Wilfred G.E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8690,
      "name": "Trehey, Harold Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8691,
      "name": "Trembly, Herve",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8692,
      "name": "Tremolieres, J.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8693,
      "name": "Trench, Richar Chenevix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8694,
      "name": "Trench, Richard Cheneivix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8695,
      "name": "Trench, Richard Chenevix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8696,
      "name": "Treston, Kevin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8697,
      "name": "Trevelyan G.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8698,
      "name": "Treves, Frederick",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8699,
      "name": "Trevor-Roper, H.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8700,
      "name": "Trible, Phyllis [ed.] & Russell, Letty M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8701,
      "name": "Tricard, Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8702,
      "name": "Tricot, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8703,
      "name": "Trigault, Nicolas, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8704,
      "name": "Trigg Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8705,
      "name": "Trilles H. C.S.Sp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8706,
      "name": "Trine Ralph Waldo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8707,
      "name": "Trine, Ralph Waldo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8708,
      "name": "Tripathi, Ram Prasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8709,
      "name": "Tripathi, Ramnares",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8710,
      "name": "Tripathi, Sridhar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8711,
      "name": "Tripathi, Suryakant 'Nirala'",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8712,
      "name": "Tripathi, Udaynarayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8713,
      "name": "Tripathy, Balaram [ed.] & Datta, S. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8714,
      "name": "Tripathy, S.N. & Samantaray, P.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8715,
      "name": "Tripole, Martin R., S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8716,
      "name": "Tripolitis Antonia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8717,
      "name": "Trivedi, B.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8718,
      "name": "Trivedi, Ramgovind",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8719,
      "name": "Troche, E.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8720,
      "name": "Trochu Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8721,
      "name": "Trogus Pompeius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8722,
      "name": "Troisfontaines, Roger, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8723,
      "name": "Trollope, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8724,
      "name": "Tromp Sebastianus Petrus Cornelis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8725,
      "name": "Tromp Sebastianus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8726,
      "name": "Tromp, Sebastianus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8727,
      "name": "Trompf, Garry W. [Ed.] & Hamel, Gildas [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8728,
      "name": "Tronson Louis S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8729,
      "name": "Trotsky, Leon & Eastman, Max [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8730,
      "name": "Truc, Gonzague",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8731,
      "name": "True Gonzague",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8732,
      "name": "Trugly Paulus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8733,
      "name": "Truyen, D., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8734,
      "name": "Tschierschky S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8735,
      "name": "Tsumura, David Toshio",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8736,
      "name": "Tuccius Stephanus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8737,
      "name": "Tuckey C. Lloyd",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8738,
      "name": "Turchi, Nicola",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8739,
      "name": "Turek Margaret M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8740,
      "name": "Turgenev, Ivan Sergeyevich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8741,
      "name": "Turkaiski, Luis, O.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8742,
      "name": "Turmel, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8743,
      "name": "Turmel, Joseph & Guest, Stephen Haden [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8744,
      "name": "Turner, Colin [ed.] & Luft, Paul [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8745,
      "name": "Turner, George W. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8746,
      "name": "Turner, H.J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8747,
      "name": "Turner, J.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8748,
      "name": "Turrell, Henry Stein",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8749,
      "name": "Tursellinus Horatius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8750,
      "name": "Tutu Archbishop Desmond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8751,
      "name": "Tyciok Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8752,
      "name": "Tylor Edward B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8753,
      "name": "Tylor, Edward B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8754,
      "name": "Tynan, Michael & Tirkey, A. Xavier [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8755,
      "name": "Tyndale, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8756,
      "name": "Tyndall John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8757,
      "name": "Tyndall, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8758,
      "name": "Tyrrell George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8759,
      "name": "Tyrrell, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8760,
      "name": "Tyrrell, Green E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8761,
      "name": "Ubach Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8762,
      "name": "Uberoi, J.P. Singh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8763,
      "name": "Uberweg Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8764,
      "name": "Uccello Sebastianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8765,
      "name": "Udaya",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8766,
      "name": "Ude Johann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8767,
      "name": "Uhl Leopold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8768,
      "name": "Ullathorne William Bernard Bp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8769,
      "name": "Ulrich de Strasbourg, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8770,
      "name": "Umar, Mustafa",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8771,
      "name": "Umberg, I.B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8772,
      "name": "Umberg, Johannes B., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8773,
      "name": "Underhill, Evelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8774,
      "name": "Underwood, Alfred Clair",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8775,
      "name": "Undset Sigrid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8776,
      "name": "Undset Sigrid & Ramsden E.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8777,
      "name": "Undset, Sigrid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8778,
      "name": "Upadhyay Brahmabandhab Lipner Julius J. [ed.] & Gispert-Sauch George S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8779,
      "name": "Upadhyay, Ramcarit",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8780,
      "name": "Upendranath Ashk",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8781,
      "name": "Upward, Allen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8782,
      "name": "Urbain Georges & Boll Marcel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8783,
      "name": "Urraburu Joannes Josephus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8784,
      "name": "Urtin, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8785,
      "name": "Usha, Mary, SND",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8786,
      "name": "Vacandard E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8787,
      "name": "Vacandard, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8788,
      "name": "Vacant Jean-Michel-Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8789,
      "name": "Vacant, Jean-Michel-Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8790,
      "name": "Vaccari Alberto S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8791,
      "name": "Vaccari, Alberto, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8792,
      "name": "Vachell, Horace Annesley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8793,
      "name": "Vadakkekara, Benedict",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8794,
      "name": "Vadakumpadan Paul SDB; Kariapuram Mathew George SDB & Puthenpurakal Joseph SDB",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8795,
      "name": "Vadakumthala Alex [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8796,
      "name": "Vaisya, Ramjidas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8797,
      "name": "Vajpeyi, Bhagavatiprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8798,
      "name": "Valensin Auguste S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8799,
      "name": "Valensin Auguste S.J. & Montcheuil Yves de S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8800,
      "name": "Valensin, Albert, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8801,
      "name": "Valensin, Auguste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8802,
      "name": "Valentine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8803,
      "name": "Valentine, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8804,
      "name": "Valentine, Simon Ross",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8805,
      "name": "Valentinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8806,
      "name": "Valerius, Maximus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8807,
      "name": "Valette, T.G.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8808,
      "name": "Valiamangalam, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8809,
      "name": "Valignano, Alessandro, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8810,
      "name": "Vallee O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8811,
      "name": "Valles Carlos G. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8812,
      "name": "Valles, Carlos G., S.j.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8813,
      "name": "Vallet P. S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8814,
      "name": "Valmiki, Omprakash & Mukhopdhyay, Prabha",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8815,
      "name": "Van Aken C. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8816,
      "name": "Van Bastelaer, D.-A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8817,
      "name": "Van Bekkum, Wout J. [ed.] & Cobb, P.M. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8818,
      "name": "Van Bogaert, J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8819,
      "name": "Van Bommel, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8820,
      "name": "Van Dale",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8821,
      "name": "Van De Kerckhove Isidorus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8822,
      "name": "Van De Vorst, Ch., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8823,
      "name": "Van de Walle J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8824,
      "name": "Van de Woestyne Zacharias O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8825,
      "name": "Van Den Berg",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8826,
      "name": "Van Den Bergh Van Eysinga, G.A. & Slack, S.B. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8827,
      "name": "Van Den Berghe Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8828,
      "name": "Van Den Berghe, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8829,
      "name": "Van Den Born A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8830,
      "name": "Van Den Bosch Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8831,
      "name": "Van Den Bosch, Jozef, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8832,
      "name": "Van Den Eynde Damien O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8833,
      "name": "Van Den Gheyn G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8834,
      "name": "Van Den Gheyn J. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8835,
      "name": "Van den Goorbergh, Edith A., OSC, Zweerman, Theodore H. [transl.], Looman-Graaskamp, Aline [transl.] & Teresa, Frances, OSC [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8836,
      "name": "Van Den Haak, W.J., Wessel, M.J. & Wit, L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8837,
      "name": "Van den Hengel, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8838,
      "name": "Van Den Oudenrijn M.A. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8839,
      "name": "Van Der Aa J.B. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8840,
      "name": "Van Der Essen, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8841,
      "name": "Van Der Hallen, Ernest",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8842,
      "name": "Van Der Hallen, Oskar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8843,
      "name": "Van Der Heeren Ach.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8844,
      "name": "Van Der Kerken, Georges, Heyse, Theodore & Leonard, Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8845,
      "name": "Van Der Leeuw, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8846,
      "name": "Van Der Leeuw, G. & Turner, J.E. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8847,
      "name": "Van Der Meersch Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8848,
      "name": "Van Der Meersch, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8849,
      "name": "Van Der Meersch, Maxence",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8850,
      "name": "Van Der Mensbrugghe, A.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8851,
      "name": "Van der Mueren Alf.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8852,
      "name": "Van Der Ploeg Johann P.M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8853,
      "name": "Van Der Stappen J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8854,
      "name": "Van Der Stappen, J.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8855,
      "name": "Van Derker, Jean-Baptiste, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8856,
      "name": "Van Durme P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8857,
      "name": "Van Dyke Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8858,
      "name": "Van Dyke, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8859,
      "name": "Van Eeckhout, Marie-Therese",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8860,
      "name": "van Gelder, Jeannette M. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8861,
      "name": "Van Gelderen I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8862,
      "name": "Van Gennep, Arnold",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8863,
      "name": "Van Goor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8864,
      "name": "Van Gorp Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8865,
      "name": "Van Hoeck F. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8866,
      "name": "Van Hoek, Kees",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8867,
      "name": "Van Hoof, Ernest, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8868,
      "name": "Van Hoonacker, Allen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8869,
      "name": "Van Hoorebeke, Ladislas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8870,
      "name": "Van Houcke, Alfons",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8871,
      "name": "Van Hove A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8872,
      "name": "Van Hove, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8873,
      "name": "Van Imschoot, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8874,
      "name": "Van Laak Hermannus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8875,
      "name": "Van Laak, Hermannus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8876,
      "name": "Van Laer, Jozef, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8877,
      "name": "Van Landingham, Chris (1963-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8878,
      "name": "Van Leeuwen, Antonie, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8879,
      "name": "Van Limbergen Jos",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8880,
      "name": "Van Noort G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8881,
      "name": "Van Noort, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8882,
      "name": "Van Oostayen Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8883,
      "name": "Van Ortroy, Francois, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8884,
      "name": "Van Passen, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8885,
      "name": "Van Puyvelde, Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8886,
      "name": "Van Rheede van Drakenstein, Henricus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8887,
      "name": "Van Riet, Georges",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8888,
      "name": "Van Rijckevorsel, L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8889,
      "name": "Van Roey Josephus Ernestus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8890,
      "name": "Van Seters John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8891,
      "name": "Van Steenberghen, Fernand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8892,
      "name": "Van Steenkiste J.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8893,
      "name": "Van Straelen C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8894,
      "name": "Van Sull Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8895,
      "name": "Van Sull Karel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8896,
      "name": "Van Sull, Charles, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8897,
      "name": "Van Term Jac P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8898,
      "name": "Van Tichelen, Th.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8899,
      "name": "Van Tieghem, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8900,
      "name": "Van Tricht Victor-Joseph S.J",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8901,
      "name": "Van Tricht Victor-Joseph S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8902,
      "name": "Van Trooy Aug. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8903,
      "name": "Van Volckxsom Jules S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8904,
      "name": "Van Volckxsom, Jules, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8905,
      "name": "Van Walenburch Adrianus & Petrus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8906,
      "name": "Van Zeller, Hubert, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8907,
      "name": "Van Zutphen, Geert Zerbolt",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8908,
      "name": "Vancandard E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8909,
      "name": "Vance, John G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8910,
      "name": "Vandekerckhove, Leo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8911,
      "name": "Vander Kerken, L., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8912,
      "name": "Vander Linden, Herman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8913,
      "name": "Vander Schueren T., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8914,
      "name": "Vanderkam James C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8915,
      "name": "Vanderkindere, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8916,
      "name": "Vanderspeeten H.P. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8917,
      "name": "Vandervorst, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8918,
      "name": "Vandesype, Francois, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8919,
      "name": "Vandeur Eugene O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8920,
      "name": "Vandeur, Eugene, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8921,
      "name": "Vandevelde A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8922,
      "name": "Vandewardt D'Onsel L.J.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8923,
      "name": "Vanelderen, Marlin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8924,
      "name": "Vanhaegendoren, M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8925,
      "name": "Vanhoozer, Kevin J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8926,
      "name": "Vanina, Eugenia [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8927,
      "name": "Vanmaele B.O.Praem.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8928,
      "name": "Vann Gerald O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8929,
      "name": "Vann, Gerald, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8930,
      "name": "Vapereau Gustave",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8931,
      "name": "Varadarajan, Lotika [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8932,
      "name": "Varayilan, Davis, CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8933,
      "name": "Varghese Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8934,
      "name": "Varghese, Kleetus K. (1968-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8935,
      "name": "Varickasseril, Jose, SDB [ed.] & Kariapuram, Mathew George, SDB [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8936,
      "name": "Varkey, C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8937,
      "name": "Varma Pavan K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8938,
      "name": "Varma, Jaganmohan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8939,
      "name": "Varma, Ramcandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8940,
      "name": "Varma, Sarvadanand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8941,
      "name": "Varma, Satyajivan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8942,
      "name": "Varma, Suryakumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8943,
      "name": "Varma, Vinayak Ganes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8944,
      "name": "Varma, Vrndavanlal",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8945,
      "name": "Varsevitius, Stanislaus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8946,
      "name": "Varshney Ashutosh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8947,
      "name": "Vasavara, Indra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8948,
      "name": "Vashum R. [ed.] & Louis Prakash S.J. (Ed.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8949,
      "name": "Vasiliev, A.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8950,
      "name": "Vasquez Gabriel S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8951,
      "name": "Vassaf, Osmanzade Huseyin & Yylmaz, Mehmet Akkup-Ali [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8952,
      "name": "Vassall-Phillips O.R. C.Ss.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8953,
      "name": "Vasseliev, Alexei",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8954,
      "name": "Vassiliadis, Petros",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8955,
      "name": "Vath, Alfons, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8956,
      "name": "Vatsyayan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8957,
      "name": "Vattanky, John, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8958,
      "name": "Vattathil, Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8959,
      "name": "Vattel, Emmer de, Lapradelle, Albert de [introd.] & Fenquick, Charles G. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8960,
      "name": "Vauaux Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8961,
      "name": "Vaubert L. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8962,
      "name": "Vaucher, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8963,
      "name": "Vaughan Bernard S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8964,
      "name": "Vaughan J.S. Bp.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8965,
      "name": "Vaughan John Stephen Bishop",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8966,
      "name": "Vaughan, C.E. & Little, A.G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8967,
      "name": "Vaughan, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8968,
      "name": "Vaughan, John S., Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8969,
      "name": "Vaughans, Bernard, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8970,
      "name": "Vaux G. De S.J. & Riondel H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8971,
      "name": "Vaux, Roland De, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8972,
      "name": "Vayson De Pradenne, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8973,
      "name": "Vayssiere, A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8974,
      "name": "Vazhayil Cyriac",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8975,
      "name": "Vedamitra, Vidyavacaspati",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8976,
      "name": "Veeraraghavan Vimala & Singh Shalini",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8977,
      "name": "Vellappallil Thomas SVD",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8978,
      "name": "Vempeny, Sebastian Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8979,
      "name": "Venema, Hermann",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8980,
      "name": "Veniprasad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8981,
      "name": "Veniprasada",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8982,
      "name": "Venkatesh, S. [Ed.] & Bhadauria, Sunita [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8983,
      "name": "Ventura de Raulica O. Theat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8984,
      "name": "Ventura Joachim O. Theat.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8985,
      "name": "Venturi Ettore S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8986,
      "name": "Verbeke Charles S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8987,
      "name": "Verbeke, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8988,
      "name": "Verbiest, Ferdinand, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8989,
      "name": "Verbockhaven, Victor, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8990,
      "name": "Vercors",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8991,
      "name": "Vercruysse Bruno S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8992,
      "name": "Vercruysse, Bruno, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8993,
      "name": "Verderber, Rudolph F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8994,
      "name": "Verdier, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8995,
      "name": "Verdier, Casimirus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8996,
      "name": "Verest, Jules, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8997,
      "name": "Verheylezoon Lodewijk S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8998,
      "name": "Verhoeven Marianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 8999,
      "name": "Verine",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9000,
      "name": "Verma, Som Prakash (1942-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9001,
      "name": "Vermeersch Arthur S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9002,
      "name": "Vermeersch Arthur S.J. & Page Humphrey [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9003,
      "name": "Vermeersch Arthur S.J. & Page W. Humphrey [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9004,
      "name": "Vermeersch, Arthur S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9005,
      "name": "Vermeersch, Arthur S.J. & Creusen, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9006,
      "name": "Vermeersch, Arthur, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9007,
      "name": "Vermeersch, Arthur, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9008,
      "name": "Vermeersch, Arthur, S.J. & Bouscaren, Timothy Lincoln, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9009,
      "name": "Vermeersch, Arthur, S.J. & Cecilia, Madame [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9010,
      "name": "Vermeersch, Arthur, S.J. & Creusen, Joseph, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9011,
      "name": "Vermeil, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9012,
      "name": "Vernet, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9013,
      "name": "Vernet, Felix, Mgr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9014,
      "name": "Vernon, S.D.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9015,
      "name": "Verrier, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9016,
      "name": "Verschaeve Cyril",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9017,
      "name": "Verschaeve, Cyril",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9018,
      "name": "Verstraeten Achilles-Maria S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9019,
      "name": "Vervoort C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9020,
      "name": "Vervoort, C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9021,
      "name": "Verwimp Edmond S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9022,
      "name": "Verwimp, Edmond, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9023,
      "name": "Vettickal, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9024,
      "name": "Veuillot Eugene",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9025,
      "name": "Veuillot Louis & Francios",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9026,
      "name": "Veuillot, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9027,
      "name": "Vialatoux Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9028,
      "name": "Vialatoux, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9029,
      "name": "Vialleton, Louis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9030,
      "name": "Vianey Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9031,
      "name": "Victor, O.C.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9032,
      "name": "Victor, Vinod [ed.], Nathaniel, Leslie [ed.] & Suryaprakash, P. Surya [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9033,
      "name": "Vidyabhusana, Satisacandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9034,
      "name": "Vidyalankara, Jayacandra",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9035,
      "name": "Vidyarthi, Divakar Prasad & Kumar, Kesari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9036,
      "name": "Vignaux Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9037,
      "name": "Vignaux, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9038,
      "name": "Vigny, Alfred De",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9039,
      "name": "Vigouroux Fulgentius S.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9040,
      "name": "Vilanova, Maria",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9041,
      "name": "Villada, Paulus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9042,
      "name": "Villaret Emile S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9043,
      "name": "Ville, Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9044,
      "name": "Villey, Michel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9045,
      "name": "Villien A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9046,
      "name": "Villon Francois Orleans Charles d' & Baude Henri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9047,
      "name": "Vimala",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9048,
      "name": "Vincent Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9049,
      "name": "Vincent Hugues O.P. & Abel F.M. O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9050,
      "name": "Vincent, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9051,
      "name": "Vincent, Hugues, O.P. & Abel, F.M., O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9052,
      "name": "Vincent, Marvin R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9053,
      "name": "Vincentius, Lirinesis, Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9054,
      "name": "Vine, William Edwy (1873-1949)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9055,
      "name": "Vineeth, Vadakethala Francis, CMI",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9056,
      "name": "Virgilius Publius Maro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9057,
      "name": "Virgilius, Publius Maro & Burmanni, Petri",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9058,
      "name": "Virtue, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9059,
      "name": "Visschers, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9060,
      "name": "Visser't Hooft, W.A. & Oldham, J.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9061,
      "name": "Visvanathan, Susan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9062,
      "name": "Viteau Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9063,
      "name": "Vitti Alfredus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9064,
      "name": "Vitti, Alfredo, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9065,
      "name": "Vittoz Roger",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9066,
      "name": "Viva Dominicus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9067,
      "name": "Vivekananda, Swami",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9068,
      "name": "Vives Josephus Calasanctius O.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9069,
      "name": "Vivian, Philip",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9070,
      "name": "Viyogi Hari",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9071,
      "name": "Viyogi, Naval",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9072,
      "name": "Vizetelli Ernest Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9073,
      "name": "Vlekke, Bernard H.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9074,
      "name": "Vloberg, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9075,
      "name": "Vloemans, Antoon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9076,
      "name": "Voet Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9077,
      "name": "Voetius, Gisbertus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9078,
      "name": "Vogel, J.PH. & Barnouw, A.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9079,
      "name": "Vogels H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9080,
      "name": "Vogels, H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9081,
      "name": "Vogt A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9082,
      "name": "Vogt, Petrus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9083,
      "name": "Voit Edmundus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9084,
      "name": "Volf, Miroslav [ed.] & Welker, Michael [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9085,
      "name": "Volpi Di Misurata, Giuseppe",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9086,
      "name": "Voltaire, Francois-Marie Arouet",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9087,
      "name": "Voltaire, Francois-Marie Arouet de",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9088,
      "name": "Vondel, Joost van den",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9089,
      "name": "Vonier Anscar O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9090,
      "name": "Vonier, Anscar, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9091,
      "name": "Voragine, Jacques De, Bhx.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9092,
      "name": "Vosen C.H. & Kaulen F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9093,
      "name": "Vosen C.H. & Vosen Kaulen",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9094,
      "name": "Voste Jacobus-Maria O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9095,
      "name": "Voste, Jacobus-Maria, O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9096,
      "name": "Vouaux Leon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9097,
      "name": "Voysey, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9098,
      "name": "Vrin, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9099,
      "name": "Vromant G. C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9100,
      "name": "Vromant, G., C.I.C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9101,
      "name": "Vyas Vinodasankar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9102,
      "name": "Vyathita Hrdaya (Pseud.)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9103,
      "name": "Waardenburg Jean-Jacques [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9104,
      "name": "Wade, G.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9105,
      "name": "Wadia Ardaser Sorabjee N.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9106,
      "name": "Waffelaert G.J. Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9107,
      "name": "Waffelaert, G.J., Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9108,
      "name": "Wahiduddin Khan, Mawlana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9109,
      "name": "Wainwright, Elaine M. (1948-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9110,
      "name": "Wainwright, G.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9111,
      "name": "Wakefield Gilbert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9112,
      "name": "Wakeman, H.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9113,
      "name": "Walbecq, E., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9114,
      "name": "Walcher, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9115,
      "name": "Wald S.N. S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9116,
      "name": "Wald, S.N., S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9117,
      "name": "Waldeck, August",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9118,
      "name": "Wali Allah Dihlawi, Shah (1114-1176 H.) & Hermansen, Marcia K. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9119,
      "name": "Walker Henry J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9120,
      "name": "Wall, Barbara",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9121,
      "name": "Wall, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9122,
      "name": "Wallace Ronald S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9123,
      "name": "Wallace William S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9124,
      "name": "Wallace, Alfred Russel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9125,
      "name": "Wallace, Edgar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9126,
      "name": "Wallace, Henry A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9127,
      "name": "Wallace, Kenneth E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9128,
      "name": "Wallace, Lew",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9129,
      "name": "Wallace, William, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9130,
      "name": "Waller, E.H.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9131,
      "name": "Walpole, Charles George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9132,
      "name": "Walpole, Hugh",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9133,
      "name": "Walsh James J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9134,
      "name": "Walsh Nicholas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9135,
      "name": "Walsh, Edmund A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9136,
      "name": "Walsh, Francis Augustine, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9137,
      "name": "Walsh, Gerald Groveland, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9138,
      "name": "Walsh, James Antony, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9139,
      "name": "Walsh, James F., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9140,
      "name": "Walsh, James J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9141,
      "name": "Walsh, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9142,
      "name": "Walsh, Walter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9143,
      "name": "Walsh, William Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9144,
      "name": "Walter, Ferdinand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9145,
      "name": "Wanenmacher, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9146,
      "name": "Wang Tch'ang-Tche J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9147,
      "name": "Wang, Peter Chen-Main [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9148,
      "name": "Wantage, Harriet S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9149,
      "name": "Warbasse, James Peter",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9150,
      "name": "Ward B. Bishop",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9151,
      "name": "Ward Humphry Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9152,
      "name": "Ward Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9153,
      "name": "Ward Lester Frank",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9154,
      "name": "Ward Maisie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9155,
      "name": "Ward Maisie [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9156,
      "name": "Ward Maisie & Sheed F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9157,
      "name": "Ward Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9158,
      "name": "Ward Wilfrid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9159,
      "name": "Ward, A.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9160,
      "name": "Ward, Adolphus William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9161,
      "name": "Ward, Adolphus William. [ed.]; Prothere, G.W. [ed.] & Leathes, Stanley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9162,
      "name": "Ward, Humphry, Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9163,
      "name": "Ward, Keith (1938-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9164,
      "name": "Ward, Leo R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9165,
      "name": "Ward, Maisie",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9166,
      "name": "Ward, Marcus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9167,
      "name": "Ward, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9168,
      "name": "Ward, Wilfrid",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9169,
      "name": "Ward, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9170,
      "name": "Ware Fabian",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9171,
      "name": "Ware, Bruce A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9172,
      "name": "Ware, Dora & Beatty, Betty",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9173,
      "name": "Waring, L.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9174,
      "name": "Warington, George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9175,
      "name": "Warner Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9176,
      "name": "Warren, W.H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9177,
      "name": "Wasmann Erich S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9178,
      "name": "Waterman, Lucius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9179,
      "name": "Waters, Brent",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9180,
      "name": "Waterworth J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9181,
      "name": "Watkin E.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9182,
      "name": "Watkin, E.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9183,
      "name": "Watkin, Edward Ingram",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9184,
      "name": "Watkin-Jones, Howard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9185,
      "name": "Watkins, Adrian [ed.] & Nathaniel, Leslie [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9186,
      "name": "Watkins, Oscar D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9187,
      "name": "Watrigant Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9188,
      "name": "Watson, Edward William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9189,
      "name": "Watson, Francis (1956-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9190,
      "name": "Watson, John B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9191,
      "name": "Watt Lewis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9192,
      "name": "Watt M. Caroline [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9193,
      "name": "Watt, Lewis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9194,
      "name": "Watterott Ignaz O.M.I.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9195,
      "name": "Watts, Fraser",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9196,
      "name": "Waugh Evelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9197,
      "name": "Waugh, Evelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9198,
      "name": "Waugh, W.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9199,
      "name": "Way, Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9200,
      "name": "Wayland, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9201,
      "name": "Weatherhead, Leslie D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9202,
      "name": "Weathers, John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9203,
      "name": "Weathers, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9204,
      "name": "Weaver, J.N., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9205,
      "name": "Weaver, Rich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9206,
      "name": "Webb Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9207,
      "name": "Webb, Bruno, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9208,
      "name": "Webb, Clement C.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9209,
      "name": "Webb, Sidney",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9210,
      "name": "Webb, Sidney & Beatrice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9211,
      "name": "Weber Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9212,
      "name": "Weber Jean-Julien Eveque",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9213,
      "name": "Weber, Albrecht",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9214,
      "name": "Weber, F.W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9215,
      "name": "Weber, Hans-Ruedi",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9216,
      "name": "Weber, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9217,
      "name": "Weber, Jean-Julien, Eveque",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9218,
      "name": "Weber, Norbert H., O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9219,
      "name": "Weber, W.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9220,
      "name": "Webster Hutton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9221,
      "name": "Webster Noah & Wheeler William A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9222,
      "name": "Webster, C.K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9223,
      "name": "Webster, Jean",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9224,
      "name": "Webster, John C.B. (1935-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9225,
      "name": "Webster, Noah",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9226,
      "name": "Wedgwood, Julia",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9227,
      "name": "Weemaes, E., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9228,
      "name": "Wefel, Franz & Newmark, Maxim [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9229,
      "name": "Weill Julien",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9230,
      "name": "Weinel Heinrich & Widgery Alban G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9231,
      "name": "Weingreen, J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9232,
      "name": "Weinrich, Friedrich",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9233,
      "name": "Weiser, Franz X., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9234,
      "name": "Weismantel, Leo",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9235,
      "name": "Weiss Albert Maria O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9236,
      "name": "Weiss, Bernard G. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9237,
      "name": "Weiss, Lyle K. (1966-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9238,
      "name": "Weisweiler Henri S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9239,
      "name": "Weizsacker, Carl Von",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9240,
      "name": "Welch, Adam C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9241,
      "name": "Weld Alfred S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9242,
      "name": "Weld F.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9243,
      "name": "Welfle, Richard A., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9244,
      "name": "Wellbye, Reginald & Stevens, H. Beresford",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9245,
      "name": "Welldon James Edward Cowley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9246,
      "name": "Wellens Jacobus Thomas Josephus Episc.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9247,
      "name": "Wellhausen, Julius",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9248,
      "name": "Wells H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9249,
      "name": "Wells, Charles L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9250,
      "name": "Wells, David F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9251,
      "name": "Wells, H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9252,
      "name": "Wells, Ronald A. (1941-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9253,
      "name": "Wenham Gordon J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9254,
      "name": "Wenham, Gordon J. & Heth, William E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9255,
      "name": "Wensinck, A.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9256,
      "name": "Werfel, Franz & Firth, Moray [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9257,
      "name": "Werfel, Franz & Lewisohn, Ludwig [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9258,
      "name": "Wernle, Paul",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9259,
      "name": "Wernz Franciscus Xaverius S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9260,
      "name": "Wernz, Franciscus Xaverius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9261,
      "name": "Wernz, Franciscus-Xaverius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9262,
      "name": "Wesley, Arun Kumar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9263,
      "name": "Wesseling Theodore L. O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9264,
      "name": "Wessels C., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9265,
      "name": "Wessels, Anton & Bowden, John [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9266,
      "name": "West, S. George",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9267,
      "name": "Westbury-Jones J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9268,
      "name": "Westcott, Arthur",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9269,
      "name": "Westcott, Brooke Foss",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9270,
      "name": "Westcott, Brooke Foss & Hort, Fenton John Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9271,
      "name": "Westcott, Edward Noyes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9272,
      "name": "Westerman, Hermann, S.V.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9273,
      "name": "Westermarck, Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9274,
      "name": "Wetzer H.J. & Welte Bernhard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9275,
      "name": "Whale, J.S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9276,
      "name": "Whatley, Arnold Robert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9277,
      "name": "Whelan, Russell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9278,
      "name": "Whitaker, Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9279,
      "name": "White Richard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9280,
      "name": "White Wendell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9281,
      "name": "White, Alexander & O'Mathoney, Edmond [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9282,
      "name": "White, E.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9283,
      "name": "White, E.G., Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9284,
      "name": "White, Ellen G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9285,
      "name": "White, Ellen G., Mrs.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9286,
      "name": "White, Gilbert & Jardine, Sir William [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9287,
      "name": "White, John T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9288,
      "name": "White, Steward Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9289,
      "name": "Whitehead, Alfred North",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9290,
      "name": "Whitelaw, Thomas (1840-1917)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9291,
      "name": "Whiteley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9292,
      "name": "Whitley, W.T. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9293,
      "name": "Whitney, William Dwight",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9294,
      "name": "Whittaker, E.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9295,
      "name": "Whittaker, Edmund",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9296,
      "name": "Whittaker, Thomas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9297,
      "name": "Wickham Harvey",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9298,
      "name": "Wickham, E.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9299,
      "name": "Wicksteed, Philip H.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9300,
      "name": "Wiebe Steven L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9301,
      "name": "Wiestner Jacobus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9302,
      "name": "Wiggers Joannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9303,
      "name": "Wijngaards J.N.M. MHM",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9304,
      "name": "Wijsen, Frans [ed.]; Henriot, Peter J., S.J. [ed.] & Mejia, Rodrigo, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9305,
      "name": "Wilbaux Jules",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9306,
      "name": "Wilber Ken",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9307,
      "name": "Wilberforce, Robert Isaac",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9308,
      "name": "Wilberforce, Robert Isaac.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9309,
      "name": "Wilbur, Earl Morse",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9310,
      "name": "Wilcocks Harry C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9311,
      "name": "Wilde, Melissa J. (1974-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9312,
      "name": "Wilde, Oscar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9313,
      "name": "Wildeboer, D.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9314,
      "name": "Wilfred Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9315,
      "name": "Wilfred Felix [ed.] & Maliekal Jose D. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9316,
      "name": "Wilfred, Felix",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9317,
      "name": "Wilhelm, Joseph & Scannell, Thomas B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9318,
      "name": "Wiliams, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9319,
      "name": "Willaert Clement S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9320,
      "name": "Willaert, Clement, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9321,
      "name": "Willaert, Leopold, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9322,
      "name": "Willelmus, Abbas Sancti Theoderici",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9323,
      "name": "Willems, P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9324,
      "name": "Willett, Robert A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9325,
      "name": "William, Franz Michael",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9326,
      "name": "William, Franz Michael & Eckhoff, Frederic [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9327,
      "name": "Williams Michael E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9328,
      "name": "Williams, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9329,
      "name": "Williams, Michael E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9330,
      "name": "Williams, N.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9331,
      "name": "Williams, Norman Powell",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9332,
      "name": "Williams, Rowan",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9333,
      "name": "Williams, Tom A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9334,
      "name": "Williams, Watkin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9335,
      "name": "Williamson Benedict",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9336,
      "name": "Williamson Benjamin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9337,
      "name": "Williamson, Claude, O.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9338,
      "name": "Willians, Isaac",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9339,
      "name": "Willkie, Wendell L.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9340,
      "name": "Willmann Otto",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9341,
      "name": "Willoughby, Harold R. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9342,
      "name": "Wills Garry (1934-    )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9343,
      "name": "Wills, Freeman",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9344,
      "name": "Wilmart, Andre, O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9345,
      "name": "Wilmet Louis S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9346,
      "name": "Wilmet, Louis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9347,
      "name": "Wilmot, Christopher J., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9348,
      "name": "Wilmot-Buxton, E.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9349,
      "name": "Wilson Albert",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9350,
      "name": "Wilson Georges Grafton & Tucker George Fox",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9351,
      "name": "Wilson R.N.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9352,
      "name": "Wilson, Alfred, C.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9353,
      "name": "Wilson, Bryan R. & Ikeda, Daisaku",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9354,
      "name": "Wilson, H.W. [ed.] & Hammerton, J.A. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9355,
      "name": "Wilson, John Mackay",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9356,
      "name": "Wilson, Woodrow",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9357,
      "name": "Wimmer, Franz Martin",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9358,
      "name": "Winant John G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9359,
      "name": "Windle Bertram C.A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9360,
      "name": "Wingfield, K.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9361,
      "name": "Winkler, Gabriele",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9362,
      "name": "Winnington-Ingram A.F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9363,
      "name": "Winslow, Francis Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9364,
      "name": "Wirceburgenses S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9365,
      "name": "Wirgman, Theodore",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9366,
      "name": "Wiseman Nicholas Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9367,
      "name": "Wiseman, Nicholas, Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9368,
      "name": "Witek, John W., S.J. & Sebes, Joseph S., S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9369,
      "name": "Withers, James",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9370,
      "name": "Wodehouse P.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9371,
      "name": "Wodehouse, P.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9372,
      "name": "Wolferstan Bertram S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9373,
      "name": "Wolferstan, Bertram, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9374,
      "name": "Wolff Henry Drummond",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9375,
      "name": "Wolff Henry W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9376,
      "name": "Wolff, D.L.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9377,
      "name": "Wollstonecraft, Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9378,
      "name": "Wolter Maurus O.S.B.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9379,
      "name": "Wood C.T. & Lanchester H.C.O.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9380,
      "name": "Wood, Ellen Meiksins",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9381,
      "name": "Wood, Eric",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9382,
      "name": "Wood, Evelyn",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9383,
      "name": "Wood, H.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9384,
      "name": "Wood, Henry",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9385,
      "name": "Wood, J.G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9386,
      "name": "Wood, James D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9387,
      "name": "Wood, Stuart",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9388,
      "name": "Wood-Allen Mary",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9389,
      "name": "Woodgate M.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9390,
      "name": "Woodgate, M.V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9391,
      "name": "Woodlock, Francis, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9392,
      "name": "Woodroffe, John (Pseud. Arthur Avalon)",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9393,
      "name": "Woodruff, Douglas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9394,
      "name": "Woods Henry S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9395,
      "name": "Woods, Francis F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9396,
      "name": "Woodville, R. Caton",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9397,
      "name": "Woodward S.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9398,
      "name": "Woodworth, Arthur V.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9399,
      "name": "Woolever James Joseph",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9400,
      "name": "Woolley, Leonard",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9401,
      "name": "Woolman John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9402,
      "name": "Wordsworth, Charles",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9403,
      "name": "Wordsworth, Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9404,
      "name": "Wordsworth, Christopher & Tozer, H.F. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9405,
      "name": "Wordsworth, Johannes",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9406,
      "name": "Wordsworth, Johannes & White, H.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9407,
      "name": "Wordsworth, Johannes & White, Henricus Julianus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9408,
      "name": "Wordsworth, William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9409,
      "name": "Wouters, Ludovicus, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9410,
      "name": "Woytinsky, Wladimir",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9411,
      "name": "Woywod, Stanislaus, O.F.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9412,
      "name": "Wren Percival Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9413,
      "name": "Wren, Percival Christopher",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9414,
      "name": "Wrench, G.T.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9415,
      "name": "Wrigh, Dudley",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9416,
      "name": "Wright Walter C. Jr.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9417,
      "name": "Wright William",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9418,
      "name": "Wright, G. Ernest [ed.] & Filson, Floyd Vivian [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9419,
      "name": "Wright, John J., D.D.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9420,
      "name": "Wuest, Joseph, C.SS.R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9421,
      "name": "Wust Peter & Watkin E.I. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9422,
      "name": "Wustenberg Ralf K. (1965-  ) & Stott Doug [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9423,
      "name": "Wuthnow, Robert [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9424,
      "name": "Wuyts, Antonius, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9425,
      "name": "Wyatt-Davies, E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9426,
      "name": "Wynne John H. S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9427,
      "name": "Xaverius Franciscus S.J. Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9428,
      "name": "Xaverius, Franciscus, S.J., Stus.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9429,
      "name": "Xavier G. Francis (1937-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9430,
      "name": "Xavier Hieronymus S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9431,
      "name": "Xavier, G. Francis (1937-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9432,
      "name": "Xavier, Peter, S.J. [Ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9433,
      "name": "Xenophon",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9434,
      "name": "Xiberta Bartholomaeus F.M.A. O. Carm. C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9435,
      "name": "Yadav, Aradhana",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9436,
      "name": "Yadav, K.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9437,
      "name": "Yadav, K.C. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9438,
      "name": "Yakovlev Y.A. & Strong Anna Louise [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9439,
      "name": "Yared, Nazik Saba",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9440,
      "name": "Yarshater Ehsan [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9441,
      "name": "Yates, W.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9442,
      "name": "Years-Brown, F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9443,
      "name": "Yearsley, Macleod",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9444,
      "name": "Yelle, G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9445,
      "name": "Yelle, G. & Fournier, R.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9446,
      "name": "Yeo Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9447,
      "name": "Yeo, Anthony",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9448,
      "name": "Yeo, Margaret",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9449,
      "name": "Yogi, Manasvini Madhubhashini",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9450,
      "name": "Yonge, Charlotte M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9451,
      "name": "Young Arthur & Maxwell Constantia [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9452,
      "name": "Young, Isabel F.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9453,
      "name": "Younghusband, Francis",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9454,
      "name": "Younghusband, Lady",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9455,
      "name": "Yurlova, Marina",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9456,
      "name": "Yvon R.P. O.F.M. Cap.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9457,
      "name": "Zacharias H.C.E.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9458,
      "name": "Zahir, Alfred",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9459,
      "name": "Zahm J.A. C.S.C.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9460,
      "name": "Zahn, Theodor",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9461,
      "name": "Zahurbakhs",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9462,
      "name": "Zakaria, Rafiq",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9463,
      "name": "Zaki, Muhammad",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9464,
      "name": "Zalenski Stanislas S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9465,
      "name": "Zaltman, Gerald [ed.], Kotler, Philip [ed.] & Kaufman, Ira [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9466,
      "name": "Zamboni G.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9467,
      "name": "Zamboni John Fortunatus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9468,
      "name": "Zamenhof, Ludoviko Lazaro",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9469,
      "name": "Zanecchia Dominicus O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9470,
      "name": "Zapico, D.F., S.J. & De Dalmases, Candidus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9471,
      "name": "Zapletal Vincentius O.P.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9472,
      "name": "Zarkov, Dubravka [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9473,
      "name": "Zavos John",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9474,
      "name": "Zeiger, Ivo A.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9475,
      "name": "Zeij Jac. J S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9476,
      "name": "Zeij, Jac. J, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9477,
      "name": "Zeiller Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9478,
      "name": "Zeiller, Jacques",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9479,
      "name": "Zeller Edward",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9480,
      "name": "Zerba, Caesar",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9481,
      "name": "Zernov, Nicolas",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9482,
      "name": "Ziegler, John J. (1939-  )",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9483,
      "name": "Zigliara Thomas Maria O.P. Card.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9484,
      "name": "Zimmermann Otto S.J. & Zybura John S. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9485,
      "name": "Zimmermann, Otto, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9486,
      "name": "Zitelli Zephyrinus",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9487,
      "name": "Zitnik, Maksimilijan, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9488,
      "name": "Zola, Emile",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9489,
      "name": "Zorell, Franciscus, S.J.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9490,
      "name": "Zualdi, Felix, C.M.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9491,
      "name": "Zubillaga, Felix, S.J. [ed.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9492,
      "name": "Zundel, Maurice",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9493,
      "name": "Zundel, Maurice & Sheed, S.J. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9494,
      "name": "Zundel, Maurice & Watkin, Edward [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9495,
      "name": "Zurbitu Florencio S.J. & Dias J.C. [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9496,
      "name": "Zweig, Arnold & Sutton, Eric [transl.]",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9497,
      "name": "Zweig, Ferdynand",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9498,
      "name": "Zybura John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9499,
      "name": "Zybura, John S.",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    },
    {
      "id": 9500,
      "name": "Zyzykine, Michel",
      "image": "NA",
      "desc": "NA",
      "genre": "NA"
    }
  ]

export const publisher_details =[
    {
      "publisherId": 1,
      "name": "15 rue Monsieur/35",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2,
      "name": "A Cuesta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 3,
      "name": "A Fireside Book",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 4,
      "name": "A. Siffer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 5,
      "name": "A.A.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 6,
      "name": "Abbaye",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 7,
      "name": "Abbaye de la Paix-N.-D.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 8,
      "name": "Abbaye de Maredsous",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 9,
      "name": "Abbaye de Saint-Andre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 10,
      "name": "Abbaye du Mont-Cesar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 11,
      "name": "Abbaye/Desclee De Brouwer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 12,
      "name": "Abdij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 13,
      "name": "Abdij Averbode",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 14,
      "name": "Abdij Keizersberg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 15,
      "name": "Acad. S.Thomae Aq.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 16,
      "name": "Achiasaf Publ. House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 17,
      "name": "Action Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 18,
      "name": "action cathol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 19,
      "name": "Action Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 20,
      "name": "Action Populaire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 21,
      "name": "Action Populaire/Spes/Ceras",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 22,
      "name": "Action Poulaire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 23,
      "name": "Administr. di Feiz Ha Breiz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 24,
      "name": "Administration",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 25,
      "name": "Adveniat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 26,
      "name": "Aegyptus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 27,
      "name": "Aertssius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 28,
      "name": "Affiliated East-West Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 29,
      "name": "Agardeep Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 30,
      "name": "Agenzia Internazianale Fides",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 31,
      "name": "Agnesotti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 32,
      "name": "Agustinos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 33,
      "name": "AIFOFDR",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 34,
      "name": "Aikiya Alayam",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 35,
      "name": "Aillaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 36,
      "name": "Akashdeep Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 37,
      "name": "Akashvani Pustakalaya",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 38,
      "name": "Alba House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 39,
      "name": "Alba House/St Pauls",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 40,
      "name": "Albatross",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 41,
      "name": "Alberts",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 42,
      "name": "Albin Michel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 43,
      "name": "Albini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 44,
      "name": "Alcan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 45,
      "name": "Alden",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 46,
      "name": "Aldine house",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 47,
      "name": "Alexander-Ouseley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 48,
      "name": "Alexandre Ouseley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 49,
      "name": "Alfurqan Book Depot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 50,
      "name": "Allen & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 51,
      "name": "Allen & Unwin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 52,
      "name": "Allied Publishers Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 53,
      "name": "Allman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 54,
      "name": "Alph. Picard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 55,
      "name": "Altajir World of Islam Trust",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 56,
      "name": "Amalgamated Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 57,
      "name": "Amat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 58,
      "name": "Ambroise Bray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 59,
      "name": "America Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 60,
      "name": "American College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 61,
      "name": "American Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 62,
      "name": "Ami du Clerge/Lethielleux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 63,
      "name": "Amjuman-I Taraqqi-I Urdu",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 64,
      "name": "Anaswara Printers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 65,
      "name": "Anbanel fils aine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 66,
      "name": "Anczyc",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 67,
      "name": "Anczyc & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 68,
      "name": "Andreaeisch. Buchhandl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 69,
      "name": "Ands",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 70,
      "name": "Angelus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 71,
      "name": "Angus & Robertson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 72,
      "name": "Anisson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 73,
      "name": "Anisson & J.Posuel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 74,
      "name": "Anissonios",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 75,
      "name": "Anthropol. Insiti.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 76,
      "name": "Anthropological Survey of India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 77,
      "name": "Antony Lodwick",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 78,
      "name": "Ap. Aegidium Denique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 79,
      "name": "ap. Analect. editorem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 80,
      "name": "Ap. H.Verdussen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 81,
      "name": "Ap.H. Verdussium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 82,
      "name": "APH Publishing Corporation.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 83,
      "name": "Apid D. Ercole",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 84,
      "name": "Apost. de la Prensa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 85,
      "name": "Apost. de la Priere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 86,
      "name": "Apost. Del La Priere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 87,
      "name": "Apostleship of Prayer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 88,
      "name": "Apostol. de la priere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 89,
      "name": "Apostolat de la Priere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 90,
      "name": "Apostolat Dela Priere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 91,
      "name": "Appelton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 92,
      "name": "Appleton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 93,
      "name": "Appleton / Encyclopedia Press / Gilmary Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 94,
      "name": "Appleton-Century",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 95,
      "name": "Apud aedes Facult. jurid. ad S. Apollin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 96,
      "name": "Apud Aegidium Denique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 97,
      "name": "Apud Analectorum edit.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 98,
      "name": "Apud Antonium Binart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 99,
      "name": "apud Antonium Polidorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 100,
      "name": "Apud auctores",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 101,
      "name": "Apud Bern. Iuntam Ciottum et Socios",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 102,
      "name": "Apud Bertanum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 103,
      "name": "Apud Collegium S. Alberti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 104,
      "name": "Apud Collegium Verbi Divini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 105,
      "name": "Apud Cur. Praep. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 106,
      "name": "Apud Curiam Generalitiam",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 107,
      "name": "Apud curiam Praep. Gener",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 108,
      "name": "Apud Curiam Praep. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 109,
      "name": "Apud editors",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 110,
      "name": "apud Eug. Cummiskey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 111,
      "name": "Apud Evangelistam Deuchinum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 112,
      "name": "Apud Everardum Kints",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 113,
      "name": "Apud Everardum Kints / Gandavi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 114,
      "name": "Apud F. Vivien",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 115,
      "name": "Apud Floranantem Pratum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 116,
      "name": "apud Fonteyn & apud Vanlinthout",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 117,
      "name": "Apud Foucher",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 118,
      "name": "apud Fr. Baba",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 119,
      "name": "apud Fratres Leseure",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 120,
      "name": "apud Fred. Leonardo",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 121,
      "name": "Apud Fred. Leonrad",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 122,
      "name": "Apud Garnier et J.P. Migne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 123,
      "name": "apud Gasparem Storti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 124,
      "name": "Apud Gve. Martin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 125,
      "name": "apud H. & C. Verdussen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 126,
      "name": "Apud Henr. Rommerskirchen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 127,
      "name": "Apud Heredum Martini Nutii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 128,
      "name": "Apud Horetium Cardon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 129,
      "name": "apud Iuntas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 130,
      "name": "apud J. Barbou",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 131,
      "name": "apud J.A. Bertanum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 132,
      "name": "apud J.B. & N.de Ville",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 133,
      "name": "apud J.B. Ciottum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 134,
      "name": "Apud J.B. Coignard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 135,
      "name": "apud J.B. Verdussen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 136,
      "name": "Apud J.M. Bruyset",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 137,
      "name": "apud Jac. Thomasinum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 138,
      "name": "apud Jacobum Lions",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 139,
      "name": "Apud Joannem Busaeum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 140,
      "name": "Apud Joannem Keerbergium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 141,
      "name": "Apud Joannem Manfre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 142,
      "name": "Apud Joannem Meursium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 143,
      "name": "Apud Joannem Verdussen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 144,
      "name": "Apud Jos. Bay Typ. Episcopalem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 145,
      "name": "apud Jos. Wilhelm Friessem jun.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 146,
      "name": "Apud Juntas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 147,
      "name": "Apud Lud. Vives",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 148,
      "name": "apud Marcum Antonium Zalterium & Michaelem Zanettum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 149,
      "name": "apud Maritnum Nutium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 150,
      "name": "Apud Martinim Nutium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 151,
      "name": "Apud Martinum Van Overbeke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 152,
      "name": "apud Mequignon juniorem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 153,
      "name": "apud Mich. Sonnium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 154,
      "name": "apud Nic Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 155,
      "name": "apud Nic. Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 156,
      "name": "apud Nicolaum Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 157,
      "name": "Apud Oecon. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 158,
      "name": "Apud Oeconomum Generalem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 159,
      "name": "Apud Oecononum Generalem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 160,
      "name": "Apud Off. Bibl. Cathol. Scriptorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 161,
      "name": "apud offic. Bibliothecae Catholicae Scriptorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 162,
      "name": "apud Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 163,
      "name": "apud Ponteyn & apud Vanlinthout",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 164,
      "name": "Apud Postul. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 165,
      "name": "Apud Postulatorem Gener. S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 166,
      "name": "Apud Proc. Prov. Belg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 167,
      "name": "Apud Procur. Prov. Belg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 168,
      "name": "apud Seb.Gryphium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 169,
      "name": "apud sedem Acad.Rom.S.Thomae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 170,
      "name": "Apud Silvanum de Grassortis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 171,
      "name": "Apud Societatem Brisc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 172,
      "name": "Apud Socios Bollandianos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 173,
      "name": "Apud Spiritum Billiot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 174,
      "name": "Apud Thomam Walwein",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 175,
      "name": "Apud vid. & her. J. Cnobbari",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 176,
      "name": "Archibald Constable and Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 177,
      "name": "Argosy Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 178,
      "name": "Armanni",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 179,
      "name": "Arnold",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 180,
      "name": "Arrowsmith",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 181,
      "name": "Art & Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 182,
      "name": "Art Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 183,
      "name": "Artheme Fayard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 184,
      "name": "Artheme Fayard & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 185,
      "name": "Artisan du livre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 186,
      "name": "Aryabusham Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 187,
      "name": "Aryan Books International",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 188,
      "name": "Ascendorff",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 189,
      "name": "Aschendorf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 190,
      "name": "Aschendorff",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 191,
      "name": "Asher & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 192,
      "name": "Asia Magazine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 193,
      "name": "Asian Trading Corporation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 194,
      "name": "ASOR",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 195,
      "name": "Assas Editions",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 196,
      "name": "Asschendorf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 197,
      "name": "Asselin & Houzeau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 198,
      "name": "Assoc. du mariage chret.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 199,
      "name": "Assoc. du Mariage Chretien",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 200,
      "name": "Assoc. for Theol. Educat. in S.E.Asia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 201,
      "name": "ASSR Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 202,
      "name": "Atma Ram",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 203,
      "name": "Au Mont-Vierge",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 204,
      "name": "Aubanel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 205,
      "name": "Aubier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 206,
      "name": "Aucam",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 207,
      "name": "Auer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 208,
      "name": "Aug. Picard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 209,
      "name": "Augustae Vindelicorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 210,
      "name": "Aurora Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 211,
      "name": "Auseley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 212,
      "name": "Austin & Winfield",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 213,
      "name": "Author",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 214,
      "name": "Aux bureaux du Journal de la Predication",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 215,
      "name": "Aux depens de Rigaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 216,
      "name": "AVE Maria Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 217,
      "name": "Averbode",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 218,
      "name": "Aviskar Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 219,
      "name": "Avondus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 220,
      "name": "Avrial",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 221,
      "name": "Awakening Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 222,
      "name": "B. O. & W.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 223,
      "name": "B.& O.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 224,
      "name": "B.&C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 225,
      "name": "B.H. Banerjee & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 226,
      "name": "B.Herder Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 227,
      "name": "B.O. & W.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 228,
      "name": "B.O. & W./Newman Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 229,
      "name": "B.R. Paperback",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 230,
      "name": "B.R. Publishing Corporation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 231,
      "name": "Bachem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 232,
      "name": "Badeker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 233,
      "name": "Baer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 234,
      "name": "Bagster",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 235,
      "name": "Bailliere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 236,
      "name": "Bailliere & Cie Felix Alcan Ed.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 237,
      "name": "Baker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 238,
      "name": "Baker Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 239,
      "name": "Baldwin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 240,
      "name": "Balleonius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 241,
      "name": "Balsaq",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 242,
      "name": "Baltenweck / Bureaux de l'annee dominicaine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 243,
      "name": "Balthasar Belleri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 244,
      "name": "Bandung Theological Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 245,
      "name": "Bangalore Social Action Trust",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 246,
      "name": "Bantam Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 247,
      "name": "Banziger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 248,
      "name": "Bapist Mission Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 249,
      "name": "Baraka Humanitarian Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 250,
      "name": "Barnes & Noble Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 251,
      "name": "Barth",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 252,
      "name": "Basel Mission Book & Tract Depository",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 253,
      "name": "Bath-Wood & Cunninghan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 254,
      "name": "Batsford",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 255,
      "name": "Bayard Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 256,
      "name": "Beauce-Rusand",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 257,
      "name": "Beauchesne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 258,
      "name": "Beauchesne & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 259,
      "name": "Beauchesnes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 260,
      "name": "Becheer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 261,
      "name": "Becquart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 262,
      "name": "Befani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 263,
      "name": "Beheer van Philologische Studien",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 264,
      "name": "Bekker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 265,
      "name": "Belgische Boekhandelmaastschappij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 266,
      "name": "Belier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 267,
      "name": "Belin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 268,
      "name": "Bell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 269,
      "name": "Bell & Daldy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 270,
      "name": "Bell & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 271,
      "name": "Bellemans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 272,
      "name": "Belles Lettres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 273,
      "name": "Bencard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 274,
      "name": "Benedictine Convent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 275,
      "name": "Benn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 276,
      "name": "Bentley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 277,
      "name": "Bentley & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 278,
      "name": "Benziger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 279,
      "name": "Benziger & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 280,
      "name": "Benziger Bros.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 281,
      "name": "Benzinger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 282,
      "name": "Benzinger.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 283,
      "name": "Berardi & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 284,
      "name": "Berche & Tralin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 285,
      "name": "Berche et Tralin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 286,
      "name": "Bermanskolleg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 287,
      "name": "Bernd Pattloch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 288,
      "name": "Bertanus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 289,
      "name": "Besacier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 290,
      "name": "Better Yourself Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 291,
      "name": "Beyaert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 292,
      "name": "Beyaert/Ed. Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 293,
      "name": "Beyaert-Defoort",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 294,
      "name": "Beyaert-Storie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 295,
      "name": "Beyart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 296,
      "name": "Bharati Bhandar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 297,
      "name": "Bharti Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 298,
      "name": "Bharti-bhandar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 299,
      "name": "Bhavana Books & Prints",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 300,
      "name": "Bibl. de l'Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 301,
      "name": "Bibl. del Clero",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 302,
      "name": "Bibl. des Exercices",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 303,
      "name": "Bibliotheca Cattolica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 304,
      "name": "Billaine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 305,
      "name": "Birckmann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 306,
      "name": "Bishop's House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 307,
      "name": "Black",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 308,
      "name": "Black & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 309,
      "name": "Blackfriars Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 310,
      "name": "Blackie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 311,
      "name": "Blackwell Publishing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 312,
      "name": "Blackwood",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 313,
      "name": "Blass",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 314,
      "name": "Bles",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 315,
      "name": "Bless",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 316,
      "name": "Blond & Gay",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 317,
      "name": "Blot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 318,
      "name": "Bloud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 319,
      "name": "Bloud & Barral",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 320,
      "name": "Bloud & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 321,
      "name": "Bloud & Gay",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 322,
      "name": "Blue Ribbon Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 323,
      "name": "Board of Islamic Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 324,
      "name": "Board of Theological Education",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 325,
      "name": "Bobbs-Merrill Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 326,
      "name": "Bode",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 327,
      "name": "Bode van het Heilig Hart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 328,
      "name": "Bodley Head",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 329,
      "name": "Boekengilde Die Poorte",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 330,
      "name": "Bogardus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 331,
      "name": "Boget",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 332,
      "name": "Bohn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 333,
      "name": "Bolle-Lasalle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 334,
      "name": "Bolvin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 335,
      "name": "Bomans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 336,
      "name": "Bombay",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 337,
      "name": "Bombay Urban Industrial League for Development",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 338,
      "name": "Bonifacius-Druckerei",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 339,
      "name": "Bonifacius-Druderei",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 340,
      "name": "Bonifacius-Druekerei",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 341,
      "name": "Bonifacuis-Druekere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 342,
      "name": "Bonne Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 343,
      "name": "Bonne Presse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 344,
      "name": "Booker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 345,
      "name": "Boosey & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 346,
      "name": "Borgo S. Spirito",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 347,
      "name": "Borremans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 348,
      "name": "Boston",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 349,
      "name": "Bouillon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 350,
      "name": "Bourguignon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 351,
      "name": "BOW",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 352,
      "name": "Boy Scouts of America",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 353,
      "name": "BPB Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 354,
      "name": "Brand",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 355,
      "name": "Braumueller",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 356,
      "name": "Bray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 357,
      "name": "Bray & Palme",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 358,
      "name": "Bray & Retaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 359,
      "name": "Breitfopf & Hartel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 360,
      "name": "Breitkopf & Hartel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 361,
      "name": "Brentano's",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 362,
      "name": "Brepols",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 363,
      "name": "Breposl",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 364,
      "name": "Breteaux-Bray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 365,
      "name": "Breton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 366,
      "name": "Briagio Diuersin e Fel. Cessaretti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 367,
      "name": "Briday",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 368,
      "name": "Briguet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 369,
      "name": "Brill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 370,
      "name": "Brit. & For. Bible Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 371,
      "name": "British Inf. Services",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 372,
      "name": "British Information Services",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 373,
      "name": "British Museum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 374,
      "name": "Broncart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 375,
      "name": "Brouwer & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 376,
      "name": "Browne & Nolan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 377,
      "name": "Browning",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 378,
      "name": "Brownson Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 379,
      "name": "Bruce Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 380,
      "name": "Bruce Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 381,
      "name": "Bruce Publishing Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 382,
      "name": "Bruck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 383,
      "name": "Brumennius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 384,
      "name": "Brunet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 385,
      "name": "Bruxelles",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 386,
      "name": "Bruylant - Christophe & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 387,
      "name": "Bruyset",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 388,
      "name": "Buchhandlung des Erziehungsvereins",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 389,
      "name": "Buendner Tagblatt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 390,
      "name": "Bulens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 391,
      "name": "Bullens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 392,
      "name": "Bur. des Oeuvres Euch.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 393,
      "name": "Bureau de Recueil",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 394,
      "name": "Bureau du Repertoire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 395,
      "name": "Bureaux de la ligue apostolique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 396,
      "name": "Bureaux de la revue",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 397,
      "name": "Bureaux de la Revue thomiste",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 398,
      "name": "Bureaux de la Revue/Lecoffre/Vrin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 399,
      "name": "Bureaux de l'Ami du Clerge",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 400,
      "name": "Bureaux de l'annee dominicaine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 401,
      "name": "Bureaux de l'Oeuvre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 402,
      "name": "Bureaux des Analectes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 403,
      "name": "Bureaux des Annales de Philosophie chretienne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 404,
      "name": "Bureaux du Correspondant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 405,
      "name": "Burleigh Cath. Press/ Sower/ Herald Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 406,
      "name": "Burmon Publ. House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 407,
      "name": "Burmons Publ. House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 408,
      "name": "Burnet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 409,
      "name": "Burns",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 410,
      "name": "Burns & Lambert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 411,
      "name": "Burns & Oates",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 412,
      "name": "Burns & Oates Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 413,
      "name": "Burns Oates",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 414,
      "name": "Burns Oates & Washbourne Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 415,
      "name": "Butterworth & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 416,
      "name": "C. Kegan Paul & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 417,
      "name": "C.F. Peters",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 418,
      "name": "C.I.S.R.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 419,
      "name": "C.O.P.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 420,
      "name": "C.T. Kapen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 421,
      "name": "C.T.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 422,
      "name": "C.T.S. & Messenger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 423,
      "name": "C.T.S. of India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 424,
      "name": "C.T.S. of Ireland",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 425,
      "name": "C.T.S.I.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 426,
      "name": "Cahiers Notre-Dame",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 427,
      "name": "Calmann-Levy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 428,
      "name": "Calzone-Villa Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 429,
      "name": "Cambridge University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 430,
      "name": "Cameron & Ferguson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 431,
      "name": "Campagne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 432,
      "name": "Campanella",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 433,
      "name": "Campion House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 434,
      "name": "Camun & Carnet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 435,
      "name": "Camus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 436,
      "name": "Canterbury Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 437,
      "name": "Cape",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 438,
      "name": "Caritas Bangladesh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 439,
      "name": "Caritasverlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 440,
      "name": "Carmel de Lisieux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 441,
      "name": "Carmelitani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 442,
      "name": "Carmelite Monastery of St. Therese",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 443,
      "name": "Carnegie Endowment for Inter. Peace",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 444,
      "name": "Casa ed. Risorgimento",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 445,
      "name": "Casa Editr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 446,
      "name": "Casa Editr. S. Lega Eucar.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 447,
      "name": "Casa editrice Nazionale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 448,
      "name": "Casa Gener. delle M.P.F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 449,
      "name": "Casa Generalizia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 450,
      "name": "Casals",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 451,
      "name": "Cassel & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 452,
      "name": "Cassell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 453,
      "name": "Cassell & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 454,
      "name": "Cassell And Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 455,
      "name": "Casteman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 456,
      "name": "Casterman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 457,
      "name": "Castermann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 458,
      "name": "Cath. Bibl. Assoc. of America",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 459,
      "name": "Cath. Book Club",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 460,
      "name": "Cath. Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 461,
      "name": "Cath. Book Crusade",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 462,
      "name": "Cath. Book Exchange",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 463,
      "name": "Cath. Educ. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 464,
      "name": "Cath. Foreign Mission Bureau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 465,
      "name": "Cath. Miss. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 466,
      "name": "Cath. Mission Press/Sanjivan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 467,
      "name": "Cath. near East Welfare Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 468,
      "name": "Cath. Orphan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 469,
      "name": "Cath. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 470,
      "name": "Cath. Record Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 471,
      "name": "Cath. Soc. Guild",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 472,
      "name": "Cath. Social Guild",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 473,
      "name": "Cath. Stud. Mission Crusade",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 474,
      "name": "Cath. Truth Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 475,
      "name": "Cath.Foreign Mission Bureau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 476,
      "name": "Catholic Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 477,
      "name": "Catholic Mission",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 478,
      "name": "Catholic Mission Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 479,
      "name": "Catholic Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 480,
      "name": "Catholic Protectory",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 481,
      "name": "Catholic Public. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 482,
      "name": "Catholic Publication Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 483,
      "name": "Catholic Publication Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 484,
      "name": "Catholic Social Guild",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 485,
      "name": "Catholic Truth Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 486,
      "name": "Catholic University of America Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 487,
      "name": "Catholic Worker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 488,
      "name": "Cattier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 489,
      "name": "CBCI Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 490,
      "name": "CCA",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 491,
      "name": "CCBI Secretariat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 492,
      "name": "Cecchini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 493,
      "name": "Centenary Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 494,
      "name": "Center for Contemporary Arab Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 495,
      "name": "Centraal Missie-Bureau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 496,
      "name": "Centre de recherche et de documentation Oratoire Saint-Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 497,
      "name": "Centre for Arab and Islamic Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 498,
      "name": "Centre for Comparative Studies in Religion and Gender",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 499,
      "name": "Centre for Dalit Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 500,
      "name": "Centre for Science and Environment",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 501,
      "name": "Centre for the Study of Islam & Christian-Muslim Relations/Selly Oak Colleges",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 502,
      "name": "Century Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 503,
      "name": "Ceuterick",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 504,
      "name": "Ceuterick & Lefebure/Ceutrick & De Smet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 505,
      "name": "Chambers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 506,
      "name": "Chamerot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 507,
      "name": "Chamuel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 508,
      "name": "Chapman & Hall",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 509,
      "name": "Charles Dolman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 510,
      "name": "Charpentier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 511,
      "name": "Chatto & Windus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 512,
      "name": "Chevallier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 513,
      "name": "Chez Edwe Covterot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 514,
      "name": "Chez J.-B.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 515,
      "name": "Chez Jean de Hevqueville",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 516,
      "name": "Chez Jean Goy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 517,
      "name": "Chez Jean-Matthieu Douladoure",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 518,
      "name": "Chez l'auteur",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 519,
      "name": "Chez Merigot le jeune",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 520,
      "name": "Chez Rolin Fils",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 521,
      "name": "Chez Simon t'Serstevens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 522,
      "name": "Chicago Tribune",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 523,
      "name": "Christ. Lit. Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 524,
      "name": "Christian Literature Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 525,
      "name": "Christian Literature Society for India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 526,
      "name": "Christian Study Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 527,
      "name": "Christian Theological Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 528,
      "name": "Christian Workers Fellowship",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 529,
      "name": "Chronique sociale de France",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 530,
      "name": "Church History Association of India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 531,
      "name": "Churchill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 532,
      "name": "Ciardetti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 533,
      "name": "Cinemaya",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 534,
      "name": "Cinquetti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 535,
      "name": "CISRS",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 536,
      "name": "Civ. Catt.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 537,
      "name": "Civilta Catholica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 538,
      "name": "Civilta Catt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 539,
      "name": "Civilta Cattol",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 540,
      "name": "Civilta Cattolica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 541,
      "name": "Civilta Cattolica/typis S.C.de propaganda fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 542,
      "name": "Civitas Dei Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 543,
      "name": "Clarendon Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 544,
      "name": "Claretian Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 545,
      "name": "Clark",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 546,
      "name": "Clarke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 547,
      "name": "Claudius Prost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 548,
      "name": "Clerc",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 549,
      "name": "Clere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 550,
      "name": "Clere & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 551,
      "name": "Cleyn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 552,
      "name": "Closson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 553,
      "name": "Closson & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 554,
      "name": "Codenne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 555,
      "name": "Codiabail Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 556,
      "name": "Codialbail Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 557,
      "name": "Coenestenii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 558,
      "name": "Cohen & West Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 559,
      "name": "Coldwell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 560,
      "name": "Coletti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 561,
      "name": "Colin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 562,
      "name": "Coll. Angel.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 563,
      "name": "Coll. S. Bonavent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 564,
      "name": "Coll. S. Bonaventurae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 565,
      "name": "Coll. S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 566,
      "name": "Coll.S.Bon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 567,
      "name": "College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 568,
      "name": "College of Social Work",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 569,
      "name": "College Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 570,
      "name": "Collegio Angelico",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 571,
      "name": "Collins",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 572,
      "name": "Collis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 573,
      "name": "Colombier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 574,
      "name": "Coloniae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 575,
      "name": "Coloniae Agrippinae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 576,
      "name": "Coloniae Bachemii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 577,
      "name": "Columbia University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 578,
      "name": "Columbus Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 579,
      "name": "Comdex Computer Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 580,
      "name": "Commission for Healthcare",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 581,
      "name": "Committee of the Palestine Exploration Fund",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 582,
      "name": "Communaute de l'Ador. repar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 583,
      "name": "Concept Publishing Company Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 584,
      "name": "Cones",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 585,
      "name": "Cones & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 586,
      "name": "Confer. of Religious in India / St. Paul's Press Training School / Catholic Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 587,
      "name": "Congr. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 588,
      "name": "Congr. de Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 589,
      "name": "Congregation of the Holy Family",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 590,
      "name": "Congress Inc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 591,
      "name": "Constable",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 592,
      "name": "Constable & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 593,
      "name": "Contemporary Targett Prafull Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 594,
      "name": "Continuum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 595,
      "name": "Contrucci",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 596,
      "name": "COP",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 597,
      "name": "Corgi Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 598,
      "name": "Cork Univ. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 599,
      "name": "Cosmo Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 600,
      "name": "Cosmo Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 601,
      "name": "Coulet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 602,
      "name": "Courcier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 603,
      "name": "Couvent de la Visitation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 604,
      "name": "Couvent des Dominicains/Libr. St. Thomas d'Aquin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 605,
      "name": "Couvent Jesus-Marie de Sillery",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 606,
      "name": "Cox & Wynan Printers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 607,
      "name": "Coyne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 608,
      "name": "Cranton & Ouseley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 609,
      "name": "CRI Permanent Secretariate",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 610,
      "name": "Crossroad Publishing Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 611,
      "name": "CTS",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 612,
      "name": "Cuesta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 613,
      "name": "Cuggiani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 614,
      "name": "Cuggiani/Typogr. della Pace",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 615,
      "name": "Cummiskey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 616,
      "name": "Curia General",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 617,
      "name": "Curia Praep. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 618,
      "name": "Curzon Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 619,
      "name": "D.C./C.B.A. of America/Cath. Univ. of America",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 620,
      "name": "D.K. Printworld (P) Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 621,
      "name": "Dakers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 622,
      "name": "Dalit Sahitya Akademy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 623,
      "name": "Dalton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 624,
      "name": "Dar al-Nafa'is",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 625,
      "name": "Darton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 626,
      "name": "Darul Musannefin Shibli Academy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 627,
      "name": "Darul Uloom",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 628,
      "name": "D'Auria",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 629,
      "name": "Davidsfonds",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 630,
      "name": "DBCIC Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 631,
      "name": "De Board/Bureaux de la Revue",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 632,
      "name": "De Bode Van Het H. Hart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 633,
      "name": "De Boeck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 634,
      "name": "De Brouwer & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 635,
      "name": "de Falco",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 636,
      "name": "de Gigord",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 637,
      "name": "de Girard & Josserand",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 638,
      "name": "De Katholieke arbeidersjeugd",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 639,
      "name": "de Luigi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 640,
      "name": "De Meester",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 641,
      "name": "De Nederlandsche Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 642,
      "name": "De Paul Univ. / Joseph F. Wagner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 643,
      "name": "de Perisse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 644,
      "name": "de Plaisia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 645,
      "name": "De Ridder & De Haas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 646,
      "name": "De Scheemaecker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 647,
      "name": "De Seyn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 648,
      "name": "de Verhoven-Debeur",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 649,
      "name": "de Vlaamsche boekenhalle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 650,
      "name": "De Vlaamsche Drukkery",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 651,
      "name": "De Vroom",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 652,
      "name": "De Waegemeer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 653,
      "name": "De Wageneer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 654,
      "name": "De Wit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 655,
      "name": "Decallonne-Liagre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 656,
      "name": "Deccan College Postgraduate & Research Inst.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 657,
      "name": "Dechriste",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 658,
      "name": "Deep & Deep Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 659,
      "name": "Deichert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 660,
      "name": "Deighton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 661,
      "name": "Dekker & Van de Vegt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 662,
      "name": "Delachaux & Niestle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 663,
      "name": "Delalain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 664,
      "name": "Delespine le fils",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 665,
      "name": "Delhomme & Briguet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 666,
      "name": "Delhomme et Briguet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 667,
      "name": "Dell Publishing Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 668,
      "name": "Delplace",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 669,
      "name": "Delvaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 670,
      "name": "Delwarde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 671,
      "name": "DeMichelis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 672,
      "name": "Denoel & Steele",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 673,
      "name": "Dent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 674,
      "name": "Dent & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 675,
      "name": "Department of Biblical Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 676,
      "name": "Dept. of Biblical Studies/Sheffield Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 677,
      "name": "Dept. of Christian Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 678,
      "name": "Derossi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 679,
      "name": "Desaint",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 680,
      "name": "Desckeem De Brouwer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 681,
      "name": "Desclee",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 682,
      "name": "Desclee & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 683,
      "name": "Desclee & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 684,
      "name": "Desclee & Soc./Academia Alfonsiana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 685,
      "name": "Desclee & Socii/Libr. Editr. Intern.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 686,
      "name": "Desclee De Brouwer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 687,
      "name": "Desclee De Brouwer & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 688,
      "name": "Desclee De Brouwer & Cie/De Meester",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 689,
      "name": "Desclee De Brouwer & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 690,
      "name": "Desclee De Brouwer & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 691,
      "name": "Dessain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 692,
      "name": "Dessain / Van In",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 693,
      "name": "Dessarn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 694,
      "name": "Dessian",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 695,
      "name": "Deutsche Buch-Gemeinschaft",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 696,
      "name": "Devaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 697,
      "name": "Devaux & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 698,
      "name": "Dewit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 699,
      "name": "Deyrolle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 700,
      "name": "Dharma Deepika",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 701,
      "name": "Dharmaram College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 702,
      "name": "Dharmaram Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 703,
      "name": "D'Hondt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 704,
      "name": "Di Gennaro",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 705,
      "name": "Dial Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 706,
      "name": "Didier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 707,
      "name": "Didier & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 708,
      "name": "Didier et Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 709,
      "name": "Didot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 710,
      "name": "Didot & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 711,
      "name": "Didot & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 712,
      "name": "Dierickx",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 713,
      "name": "Dierickx-Beke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 714,
      "name": "Dillen & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 715,
      "name": "Dillet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 716,
      "name": "Diocesan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 717,
      "name": "Dir. Gen. Apost. Orat.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 718,
      "name": "Dir. gener. Ap. Or.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 719,
      "name": "Direct & Admin. des Studia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 720,
      "name": "Direct. gener. des Oeuvres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 721,
      "name": "Directio & Administratio",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 722,
      "name": "Directio et Admin.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 723,
      "name": "Direction Gen. de l'Intronisation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 724,
      "name": "Distributist League",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 725,
      "name": "Documentation Information Dept.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 726,
      "name": "Doin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 727,
      "name": "Dolman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 728,
      "name": "Dolphin Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 729,
      "name": "Dominant Publ. and Distributors",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 730,
      "name": "Dominant Publishers and Distributors",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 731,
      "name": "Dominican Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 732,
      "name": "Dominion Research Found",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 733,
      "name": "Domus Probat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 734,
      "name": "Don Bosco Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 735,
      "name": "Don Bosco Youth Animation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 736,
      "name": "Dondey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 737,
      "name": "Dossier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 738,
      "name": "Doubleday",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 739,
      "name": "Doubleday Anchor Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 740,
      "name": "Douniol",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 741,
      "name": "Douniol & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 742,
      "name": "Douxfils",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 743,
      "name": "Dovivier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 744,
      "name": "Downside Abbey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 745,
      "name": "D'Rozario",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 746,
      "name": "D'rozario & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 747,
      "name": "Druck und Verlag von Friedrich Pustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 748,
      "name": "Drukkerij der Abdij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 749,
      "name": "Drukklerij Vander Schelden",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 750,
      "name": "Du fonds de MM. Anisson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 751,
      "name": "Duckworth",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 752,
      "name": "Duckworth & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 753,
      "name": "Duckworth Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 754,
      "name": "Ducoulombier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 755,
      "name": "Duculot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 756,
      "name": "Duffin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 757,
      "name": "Dufft",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 758,
      "name": "Duffy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 759,
      "name": "Duffy & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 760,
      "name": "Duffy/Murphy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 761,
      "name": "Dummlers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 762,
      "name": "Dumoulin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 763,
      "name": "Duncan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 764,
      "name": "Dupagne-Counet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 765,
      "name": "Dupuis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 766,
      "name": "Dupuy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 767,
      "name": "Duquesne University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 768,
      "name": "Dutton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 769,
      "name": "Dutton & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 770,
      "name": "Duvivier/Lecharlier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 771,
      "name": "e prelo surdo-mutorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 772,
      "name": "E Typogr. Acad.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 773,
      "name": "e Typogr. archiepiscopali",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 774,
      "name": "E.J. Brill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 775,
      "name": "E.J. Brill/Luzac & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 776,
      "name": "E.K. Sekretariaat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 777,
      "name": "E.N.I.T.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 778,
      "name": "East Asian Pastoral Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 779,
      "name": "Eastern Law House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 780,
      "name": "Eastern Theological College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 781,
      "name": "Echter-Verlag GmbH",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 782,
      "name": "Ecole Apostolique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 783,
      "name": "Ecole des Hautes Etudes en Sciences Sociales",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 784,
      "name": "Ecumenical Association of Third World Theologians",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 785,
      "name": "Ecumenical Christian Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 786,
      "name": "Ed Off. Polygr. Editr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 787,
      "name": "Ed. \"Alsatia\", Colmar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 788,
      "name": "Ed. \"Je Sers\", Paris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 789,
      "name": "Ed. \"Melos\", Gubbio",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 790,
      "name": "Ed. C.E.P.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 791,
      "name": "Ed. Correa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 792,
      "name": "Ed. de la Connaissance",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 793,
      "name": "Ed. de la Pensee Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 794,
      "name": "Ed. de la Universidad del Salvador",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 795,
      "name": "Ed. de l'Hist. Sup. de Philos.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 796,
      "name": "Ed. du Cerf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 797,
      "name": "Ed. du Chalet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 798,
      "name": "Ed. Illustrato",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 799,
      "name": "Ed. J. Gabalda et Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 800,
      "name": "Ed. L'Oricanno",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 801,
      "name": "Ed. Mariage & Famille",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 802,
      "name": "Ed. Pont. Univ. Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 803,
      "name": "Ed. Privat / Lecoffre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 804,
      "name": "Ed. Psalterium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 805,
      "name": "Ed. Spes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 806,
      "name": "Ed. Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 807,
      "name": "Ed. Universelle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 808,
      "name": "Ediciones Mensajero",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 809,
      "name": "Edinburgh House Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 810,
      "name": "Edit. de Cerf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 811,
      "name": "Edit. de la Cite Chret",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 812,
      "name": "Edit. de la Cite Chret.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 813,
      "name": "Edit. de la J.E.C.F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 814,
      "name": "Edit. de l'Orante",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 815,
      "name": "Edit. De la Baconniere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 816,
      "name": "Edit. Du Cerf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 817,
      "name": "Edit. franciscaines",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 818,
      "name": "Edit. Mariage et Famille",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 819,
      "name": "Edit. Revue des Jeunes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 820,
      "name": "Edit. Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 821,
      "name": "Edition Spes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 822,
      "name": "Edition Universelle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 823,
      "name": "Editions \"Alsatia\", Paris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 824,
      "name": "Editions Alsatia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 825,
      "name": "Editions Charles Paillart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 826,
      "name": "Editions d'Art et d'Histoire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 827,
      "name": "Editions de la Cite Chretienne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 828,
      "name": "Editions de la Revue des Jeunes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 829,
      "name": "Editions de la Vie Spir.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 830,
      "name": "Editions du Lys",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 831,
      "name": "Editions Education Integrale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 832,
      "name": "Editions Jocistes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 833,
      "name": "Editions Mariage & Famille",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 834,
      "name": "Editions Montaigne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 835,
      "name": "Editions Publiroc",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 836,
      "name": "Editions Rex",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 837,
      "name": "Editions salvator",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 838,
      "name": "Editions Sobeli",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 839,
      "name": "Editions Spes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 840,
      "name": "Editions: Des Pretres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 841,
      "name": "Editor",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 842,
      "name": "Editores Bibliothecae novae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 843,
      "name": "Editorial Cromos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 844,
      "name": "Editorial iberica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 845,
      "name": "Editrice Pontificia Universita Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 846,
      "name": "Editrice Studium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 847,
      "name": "Edizioni Glossa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 848,
      "name": "Edizioni Studium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 849,
      "name": "Education Society's Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 850,
      "name": "Educational Co. of Ireland",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 851,
      "name": "Educational Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 852,
      "name": "Eerdmans Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 853,
      "name": "Effingham Wilson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 854,
      "name": "Ein for Human and Social Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 855,
      "name": "Eisenbrauns",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 856,
      "name": "El mensajero del C. de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 857,
      "name": "El Mensajero del Cor. de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 858,
      "name": "El Mensajero del Corazon de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 859,
      "name": "El Perpetuo Socorro / Marietti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 860,
      "name": "El Salvador: Centro de Reflexion Teologica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 861,
      "name": "Elexpuru",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 862,
      "name": "Elexpuru Hermanos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 863,
      "name": "Elexpuru Hnos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 864,
      "name": "Elliot Stock",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 865,
      "name": "Emiliana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 866,
      "name": "Emmaus Road",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 867,
      "name": "Encyclopedia Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 868,
      "name": "Encyclopedie-Roret",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 869,
      "name": "Endert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 870,
      "name": "Engelcke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 871,
      "name": "ENIT",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 872,
      "name": "Environment and Urbanization",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 873,
      "name": "Ephrem's Theological Association",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 874,
      "name": "Epworth Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 875,
      "name": "Escuel Biblica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 876,
      "name": "Estienne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 877,
      "name": "Eudes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 878,
      "name": "Eusebius Andrews",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 879,
      "name": "Eveleigh Nash & Grayson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 880,
      "name": "Ex architypogragr. Plantiniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 881,
      "name": "Ex off. Henrici Hovii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 882,
      "name": "Ex off. Joan. Masii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 883,
      "name": "Ex Off. Plantiniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 884,
      "name": "Ex off. Plantiniana Balth. Moreti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 885,
      "name": "ex off. typog. viduae B. Belleri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 886,
      "name": "ex Offi. Metternichiana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 887,
      "name": "ex offic. Francisci Hackii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 888,
      "name": "ex officina Boutasteiniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 889,
      "name": "ex officina Elseva rinna",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 890,
      "name": "Ex officina polygraphica editrice",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 891,
      "name": "Ex Typ. Balleoniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 892,
      "name": "Ex typ. Coll. S. Bona",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 893,
      "name": "Ex typ. M. Armanni",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 894,
      "name": "Ex Typ. Rev. Cam. Apost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 895,
      "name": "Ex typis consoc. S. Pauli",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 896,
      "name": "ex typis RR.PP. Coelestinorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 897,
      "name": "Ex Typogr. Benedicti Cano",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 898,
      "name": "Ex Typogr. R. Leroy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 899,
      "name": "Ex Typogr. Remondini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 900,
      "name": "Ex. Off. Typogr. Macioce & Pisani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 901,
      "name": "Ex. Typog. eiusdem coll.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 902,
      "name": "Examiner Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 903,
      "name": "Examiner Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 904,
      "name": "Exc. Tho. Royeroft",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 905,
      "name": "Excelsior",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 906,
      "name": "Excud. F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 907,
      "name": "Excudebat Antonius Fulgonius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 908,
      "name": "expensis Bartholomaei Occhi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 909,
      "name": "Expression Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 910,
      "name": "Extension Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 911,
      "name": "Eyre & Spottiswoode",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 912,
      "name": "F.C. & J. Rivington",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 913,
      "name": "Faber & Faber",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 914,
      "name": "Fabrian Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 915,
      "name": "Facultad de Teologia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 916,
      "name": "Faculte des Lettres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 917,
      "name": "Facultes Catholiques",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 918,
      "name": "Faculty of Canon Law",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 919,
      "name": "Faith Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 920,
      "name": "Farm Street/114 Mount Street",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 921,
      "name": "Farran",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 922,
      "name": "Fasquelle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 923,
      "name": "Fattorusso",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 924,
      "name": "Fayard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 925,
      "name": "Feder. des Coll. Belges S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 926,
      "name": "Federat des Colleges Belges",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 927,
      "name": "Ferdinand Schoningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 928,
      "name": "Ferrari",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 929,
      "name": "Festa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 930,
      "name": "Fiaccadori",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 931,
      "name": "Filiziani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 932,
      "name": "Firmin-Didot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 933,
      "name": "Firmin-Didot & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 934,
      "name": "Fischbacher",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 935,
      "name": "Fisher",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 936,
      "name": "Fisher Unwin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 937,
      "name": "Flammarion",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 938,
      "name": "Flammerion",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 939,
      "name": "Flanagan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 940,
      "name": "Flood & Vincent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 941,
      "name": "Florentiae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 942,
      "name": "Fons Vitae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 943,
      "name": "Fontemoing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 944,
      "name": "Fontey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 945,
      "name": "Fonteyn",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 946,
      "name": "Foppens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 947,
      "name": "Fordham University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 948,
      "name": "Fortress Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 949,
      "name": "Forward Movement Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 950,
      "name": "Forzani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 951,
      "name": "Forzani & Desclee",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 952,
      "name": "Forzani & Socii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 953,
      "name": "Foulis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 954,
      "name": "Fr. Luciano Colussi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 955,
      "name": "Franciscaanasche Standaard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 956,
      "name": "Franciscan Friars",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 957,
      "name": "Francois-Marie Braun",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 958,
      "name": "Frank",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 959,
      "name": "Fredebeul & Koenen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 960,
      "name": "Fricx",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 961,
      "name": "Frowde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 962,
      "name": "Fues",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 963,
      "name": "Full Circle Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 964,
      "name": "Fulvio",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 965,
      "name": "Furtado",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 966,
      "name": "G. Beauchesne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 967,
      "name": "G.P. Maisonneuve",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 968,
      "name": "Gabalada",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 969,
      "name": "Gabalda",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 970,
      "name": "Gabalda & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 971,
      "name": "Gabalde & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 972,
      "name": "Gabanda",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 973,
      "name": "Gabriel Beauchesne & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 974,
      "name": "Gaertner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 975,
      "name": "Gallimard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 976,
      "name": "Gandhi Peace Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 977,
      "name": "Ganga Granthagar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 978,
      "name": "Gangapustakmala",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 979,
      "name": "Gardner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 980,
      "name": "Garnier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 981,
      "name": "Garzoni-Provenzani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 982,
      "name": "Gasterman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 983,
      "name": "Gaston Doin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 984,
      "name": "Gateway",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 985,
      "name": "Gaudilliere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 986,
      "name": "Gaume",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 987,
      "name": "Gaume & Duprey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 988,
      "name": "Gausgruber",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 989,
      "name": "Gauther",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 990,
      "name": "Gauthier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 991,
      "name": "Gauthier-Villars",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 992,
      "name": "Gauthier-Villars & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 993,
      "name": "Geloofsverdediging",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 994,
      "name": "Gem Printing Works",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 995,
      "name": "Gembloux Duculot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 996,
      "name": "Geneva Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 997,
      "name": "Gentili",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 998,
      "name": "Genuine Publ. & Media Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 999,
      "name": "Geoffrey Chapman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1000,
      "name": "Geomaere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1001,
      "name": "Georgetown University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1002,
      "name": "Gerald",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1003,
      "name": "Gerald Bellew",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1004,
      "name": "Gerard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1005,
      "name": "Germer Bailliere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1006,
      "name": "Gernay-Bouten",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1007,
      "name": "Gerold",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1008,
      "name": "Gervais",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1009,
      "name": "Geslin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1010,
      "name": "Geuthner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1011,
      "name": "Giachethi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1012,
      "name": "Giachetti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1013,
      "name": "Giachetti & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1014,
      "name": "Giachetti & Soc./Univ. Greg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1015,
      "name": "Giannini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1016,
      "name": "Giard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1017,
      "name": "Gill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1018,
      "name": "Gill & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1019,
      "name": "Gill et Filius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1020,
      "name": "Gillequin & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1021,
      "name": "Gilmary Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1022,
      "name": "Giraud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1023,
      "name": "Gita Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1024,
      "name": "Global Vision Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1025,
      "name": "Glock",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1026,
      "name": "Godenne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1027,
      "name": "Goede Pers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1028,
      "name": "Goemaere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1029,
      "name": "Goemare",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1030,
      "name": "Gomes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1031,
      "name": "Good Pastor Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1032,
      "name": "Goodword Books Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1033,
      "name": "Government of India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1034,
      "name": "Government Printing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1035,
      "name": "Govt. Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1036,
      "name": "Grace",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1037,
      "name": "Grand Seminaire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1038,
      "name": "Grandmont",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1039,
      "name": "Grange",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1040,
      "name": "Grant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1041,
      "name": "Granthmala",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1042,
      "name": "Grasset",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1043,
      "name": "Green & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1044,
      "name": "Greening & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1045,
      "name": "Greuse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1046,
      "name": "Griffin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1047,
      "name": "Griggs",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1048,
      "name": "Grimaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1049,
      "name": "Grolier Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1050,
      "name": "Grunewald",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1051,
      "name": "Gschwend",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1052,
      "name": "Guerin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1053,
      "name": "Guerra & Mirri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1054,
      "name": "Guild of St. Luke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1055,
      "name": "Guillard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1056,
      "name": "Guinart & Pujolar-Bruch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1057,
      "name": "Gujarat Sahitya Prakash",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1058,
      "name": "Gyan Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1059,
      "name": "H. & C. McGrath",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1060,
      "name": "H. Bomans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1061,
      "name": "H. Stevenart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1062,
      "name": "H.F. & G. Witherby",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1063,
      "name": "H.S. Crocker Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1064,
      "name": "Haas & Grabherr",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1065,
      "name": "Habbel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1066,
      "name": "Hachette",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1067,
      "name": "Hachette & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1068,
      "name": "Hachette & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1069,
      "name": "Haenen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1070,
      "name": "Hagae-Comitum & Francofurti ad Moenum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1071,
      "name": "Hall",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1072,
      "name": "Hall & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1073,
      "name": "Hanicq",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1074,
      "name": "Hanstein",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1075,
      "name": "Happach",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1076,
      "name": "Harcourt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1077,
      "name": "Harding & More",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1078,
      "name": "Harper",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1079,
      "name": "HarperCollins Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1080,
      "name": "Harrap",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1081,
      "name": "Harrap & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1082,
      "name": "Harrigan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1083,
      "name": "Hartford Seminary Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1084,
      "name": "Harvard University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1085,
      "name": "Haseldonckx",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1086,
      "name": "Haton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1087,
      "name": "Havaux-Houdart",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1088,
      "name": "Havez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1089,
      "name": "Hayes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1090,
      "name": "Hayez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1091,
      "name": "Headline Book Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1092,
      "name": "Health Action",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1093,
      "name": "Heath & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1094,
      "name": "Heberle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1095,
      "name": "Hebrail",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1096,
      "name": "Hebrew University of Jerusalem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1097,
      "name": "Heffer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1098,
      "name": "Hegner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1099,
      "name": "Heinemann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1100,
      "name": "Hendrickson Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1101,
      "name": "Henry Litolff's Verlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1102,
      "name": "Henry Martyn Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1103,
      "name": "Herald Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1104,
      "name": "Herder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1105,
      "name": "Herder & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1106,
      "name": "Herder & Herder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1107,
      "name": "Herder Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1108,
      "name": "Herissant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1109,
      "name": "Herissant & Estienne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1110,
      "name": "Herker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1111,
      "name": "Herman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1112,
      "name": "Hernando",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1113,
      "name": "Hertogenbosch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1114,
      "name": "Hetzel & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1115,
      "name": "Heugel & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1116,
      "name": "Heythrop College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1117,
      "name": "Hickling",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1118,
      "name": "Hidaayah Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1119,
      "name": "Himal Inc. Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1120,
      "name": "Hinduism Today",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1121,
      "name": "Hindustani Academy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1122,
      "name": "Hindustani Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1123,
      "name": "Hinrich",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1124,
      "name": "Hodder & Stoughton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1125,
      "name": "Hodges",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1126,
      "name": "Hodges Figgis & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1127,
      "name": "Hodson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1128,
      "name": "Hoe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1129,
      "name": "Hoffmann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1130,
      "name": "Hogg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1131,
      "name": "Hogles",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1132,
      "name": "Holdsworth",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1133,
      "name": "Holtze",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1134,
      "name": "Holy Spirit Study Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1135,
      "name": "Houdmont",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1136,
      "name": "Houdmont-Carbonez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1137,
      "name": "Houghton Mifflin Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1138,
      "name": "Howard Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1139,
      "name": "Huber",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1140,
      "name": "Hueber",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1141,
      "name": "Human Rights Watch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1142,
      "name": "Humphrey Toulmin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1143,
      "name": "Hunt Pen Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1144,
      "name": "Hurst & Blackett",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1145,
      "name": "Hurst & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1146,
      "name": "Hurst and Blackett",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1147,
      "name": "Hutchibson & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1148,
      "name": "Hutchinson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1149,
      "name": "Hutchinson & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1150,
      "name": "Hutchison & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1151,
      "name": "Huttler",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1152,
      "name": "Huyshauwer & Scheerder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1153,
      "name": "I. W. Aschendorff",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1154,
      "name": "I.B. Herder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1155,
      "name": "I.B. Tauris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1156,
      "name": "I.C.T.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1157,
      "name": "I.L.O.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1158,
      "name": "Ickx & Geets",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1159,
      "name": "Idarah-i Tabligh-i Din",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1160,
      "name": "Ignatius Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1161,
      "name": "IIC Max Muller Marg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1162,
      "name": "IJA Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1163,
      "name": "Imp. J. Mersch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1164,
      "name": "Impensis haeredum Martini Nutii et Johannis Hertsroy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1165,
      "name": "Impensis J.G. Schonwetteri & G.Gymnici",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1166,
      "name": "impensis Joannis Manfre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1167,
      "name": "Impensis Martini Nutu et Joannis Hertsroy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1168,
      "name": "Impensis Soc typ. Libr. eccles. jussu Regis Constitutae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1169,
      "name": "Impr. Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1170,
      "name": "Impr. de \"La Montreuilloise\", Montreuil-sur-Mer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1171,
      "name": "Impr. de la Bibl. Eccl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1172,
      "name": "Impr. de la Civilta Cattolica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1173,
      "name": "Impr. de la Grotte",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1174,
      "name": "Impr. de la Revue Belge & Etrangere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1175,
      "name": "Impr. de l'Eure",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1176,
      "name": "Impr. del Corazon de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1177,
      "name": "Impr. du Courrier d'Afrique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1178,
      "name": "Impr. du Messager",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1179,
      "name": "Impr. du Patronage Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1180,
      "name": "Impr. du Scolasticat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1181,
      "name": "Impr. N.-D. des Pres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1182,
      "name": "Impr. Nacional/Tipogrfia Rangel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1183,
      "name": "Impr. Regionale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1184,
      "name": "Impr. S. Alphonse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1185,
      "name": "Impr. St. Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1186,
      "name": "Impr.-libr. de N.D.de Montligeon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1187,
      "name": "Imprimerie \"Nova et Vetera\", Louvain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1188,
      "name": "Imprimerie Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1189,
      "name": "Imprimerie de Bethune",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1190,
      "name": "Imprimerie Foch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1191,
      "name": "Imprimerie Nationale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1192,
      "name": "Imprimerie S. Pacteau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1193,
      "name": "Ind. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1194,
      "name": "India International Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1195,
      "name": "India Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1196,
      "name": "Indialog Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1197,
      "name": "Indian Academy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1198,
      "name": "Indian Assoc. for Cultural Freedom",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1199,
      "name": "Indian Catechetical Association",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1200,
      "name": "Indian Catholic Truth Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1201,
      "name": "Indian Council of Philosophical Research",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1202,
      "name": "Indian Currents",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1203,
      "name": "Indian histor. research instit.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1204,
      "name": "Indian Institute of Public Administration",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1205,
      "name": "Indian Institute of Social Order/Indian Social Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1206,
      "name": "Indian Jesuit Delegation/St Xavier's High School",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1207,
      "name": "Indian Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1208,
      "name": "Indian Renaissance Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1209,
      "name": "Indian Social Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1210,
      "name": "Indiana University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1211,
      "name": "Indica Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1212,
      "name": "Information Section",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1213,
      "name": "Inner Growth Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1214,
      "name": "Inner Growth Books and Videos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1215,
      "name": "Inspirational Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1216,
      "name": "Inst. d'etudes de religions",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1217,
      "name": "Inst. Intern. de Rech. & de Formation en vue du developpement harmonise",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1218,
      "name": "Inst. of Socio Political Dynamics",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1219,
      "name": "Inst. Pii IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1220,
      "name": "Inst. Sup. De Philos.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1221,
      "name": "Inst. Super. de Phil.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1222,
      "name": "Instit. Sup. de Philos.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1223,
      "name": "Institut biblique Pontifical",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1224,
      "name": "Institut des Belles Lettres Arabes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1225,
      "name": "Institute of Jesuit Sources",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1226,
      "name": "Institute of Missiology",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1227,
      "name": "Institute of Objective Studies",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1228,
      "name": "Institute of Social Sciences",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1229,
      "name": "Institutum Historicum Societatis Iesu",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1230,
      "name": "Instytut Wydawniczy PAX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1231,
      "name": "Int. L. Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1232,
      "name": "Intercultural Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1233,
      "name": "Inter-India Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1234,
      "name": "Intern. Lab. Office./I.L.O.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1235,
      "name": "Intern. Labour Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1236,
      "name": "Internat. Cath. Distrib.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1237,
      "name": "International Institute of Islamic Thought",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1238,
      "name": "Internnational Review of Mission",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1239,
      "name": "Inter-Varsity Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1240,
      "name": "Ioannes Norton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1241,
      "name": "Irish Messenger Off.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1242,
      "name": "Islam and the Modern Age Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1243,
      "name": "Islamic Academy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1244,
      "name": "Islamic Culture Board",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1245,
      "name": "Islamic Human Rights Commission",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1246,
      "name": "Islamic Publications International",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1247,
      "name": "Islamic Studies Association",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1248,
      "name": "ISPCK",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1249,
      "name": "Istas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1250,
      "name": "Izquierdo",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1251,
      "name": "J. Gabalda Et Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1252,
      "name": "J.E.a Cuesta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1253,
      "name": "J.M. Dent & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1254,
      "name": "J.O.C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1255,
      "name": "Jack",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1256,
      "name": "Jacobi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1257,
      "name": "Jacobs",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1258,
      "name": "Jacobsz. Paets",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1259,
      "name": "Jacquin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1260,
      "name": "Jaico Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1261,
      "name": "Jain Bhawan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1262,
      "name": "Jamar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1263,
      "name": "James Clarke & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1264,
      "name": "James Parker and Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1265,
      "name": "Jamit Building",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1266,
      "name": "Jansonius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1267,
      "name": "Janssens & Van Merlen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1268,
      "name": "Jarrolds",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1269,
      "name": "Jawahar Publishers & Distributors",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1270,
      "name": "Jawaharlal Nehru Memorial Fund",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1271,
      "name": "Jenkins",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1272,
      "name": "Jennings & Graham",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1273,
      "name": "Jerusalem Academic Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1274,
      "name": "Jesuit Mission Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1275,
      "name": "Jesuit Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1276,
      "name": "Jivan Jagrti Kendra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1277,
      "name": "Joan. Jac. Mauracher",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1278,
      "name": "Joannes Fernandez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1279,
      "name": "Joannies Gorgius Muffat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1280,
      "name": "Jobard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1281,
      "name": "John Lane",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1282,
      "name": "John Murphy & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1283,
      "name": "John Murray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1284,
      "name": "John Weale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1285,
      "name": "Johnston",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1286,
      "name": "Jonby & Roger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1287,
      "name": "Jones",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1288,
      "name": "Joseph F. Wagner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1289,
      "name": "Jost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1290,
      "name": "Jouby",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1291,
      "name": "Jouby & Roger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1292,
      "name": "Journal of Feminist Studies in Religion",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1293,
      "name": "JSOT Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1294,
      "name": "Jules Gervais",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1295,
      "name": "Julian Saldanha",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1296,
      "name": "Julien",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1297,
      "name": "K. Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1298,
      "name": "Kajottersuitgaven",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1299,
      "name": "Kanishka Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1300,
      "name": "Kasel & Pustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1301,
      "name": "Katholieke Universiteit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1302,
      "name": "Katholischen Pressverein",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1303,
      "name": "Keating & Brown",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1304,
      "name": "Kegan Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1305,
      "name": "Kegan Paul Trench & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1306,
      "name": "Keiseisha",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1307,
      "name": "Kelly",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1308,
      "name": "Kenedy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1309,
      "name": "Kenedy & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1310,
      "name": "Kenedy and Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1311,
      "name": "Kennedy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1312,
      "name": "Kersten",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1313,
      "name": "Kersterns",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1314,
      "name": "Keyeux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1315,
      "name": "Khaniqahi-Nimatullahi Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1316,
      "name": "Khuda Bakhsh Oriental Public Library",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1317,
      "name": "Kilner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1318,
      "name": "Kimpton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1319,
      "name": "King",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1320,
      "name": "King & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1321,
      "name": "King & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1322,
      "name": "Kirchheim",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1323,
      "name": "Kirchhem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1324,
      "name": "Kiryat Sepher",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1325,
      "name": "Klein",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1326,
      "name": "Kluczycki",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1327,
      "name": "Kluczycki & Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1328,
      "name": "Koenig",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1329,
      "name": "Koersen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1330,
      "name": "Kohlhammer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1331,
      "name": "Kok Pharos Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1332,
      "name": "Kolff & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1333,
      "name": "Konark Publishers Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1334,
      "name": "Konig",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1335,
      "name": "Kosel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1336,
      "name": "Kosel & Pustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1337,
      "name": "Kosel-Pustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1338,
      "name": "Kosel-Verlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1339,
      "name": "Kristene Arbeidersjeugd",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1340,
      "name": "Kristu Jyoti College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1341,
      "name": "Kristu Jyoti Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1342,
      "name": "Kroener",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1343,
      "name": "Kuendig",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1344,
      "name": "L. Muraille",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1345,
      "name": "La Demi-heure d'Ecrit.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1346,
      "name": "La Pensee Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1347,
      "name": "Labergerie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1348,
      "name": "Laborde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1349,
      "name": "Lacroix-Gauthier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1350,
      "name": "L'Action Paroissiale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1351,
      "name": "Lafitte",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1352,
      "name": "Lafoley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1353,
      "name": "Lafolye",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1354,
      "name": "Lahiri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1355,
      "name": "Laine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1356,
      "name": "Lallemant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1357,
      "name": "Lambert-Caron",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1358,
      "name": "Landry",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1359,
      "name": "Lane",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1360,
      "name": "Langenscheidt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1361,
      "name": "Lanier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1362,
      "name": "Lardinois",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1363,
      "name": "Laroche",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1364,
      "name": "Larose",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1365,
      "name": "Larousse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1366,
      "name": "Latour",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1367,
      "name": "Laumann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1368,
      "name": "Launay-Gagnot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1369,
      "name": "Laupp",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1370,
      "name": "Laurens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1371,
      "name": "Lavandier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1372,
      "name": "Law Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1373,
      "name": "Le Clere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1374,
      "name": "Le Clere & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1375,
      "name": "Leader Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1376,
      "name": "League of Nations Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1377,
      "name": "League of Nations Public",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1378,
      "name": "Lebegue",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1379,
      "name": "Leborgne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1380,
      "name": "Lecoffre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1381,
      "name": "L'Ecole Apostolique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1382,
      "name": "Lecture au Foyer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1383,
      "name": "Leday",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1384,
      "name": "Lefebvre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1385,
      "name": "Lefever/Polleunis & Ceuterick/Ceuterick/Soc. des Bollandistes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1386,
      "name": "Lefort",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1387,
      "name": "Leliaert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1388,
      "name": "Lelong",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1389,
      "name": "Lemerre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1390,
      "name": "Lentner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1391,
      "name": "Leocoffre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1392,
      "name": "Lepoitvin Delacroix",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1393,
      "name": "Leroux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1394,
      "name": "Leroy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1395,
      "name": "Les belles lettres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1396,
      "name": "Les editions du Cerf",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1397,
      "name": "Lethellieux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1398,
      "name": "Lethielleux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1399,
      "name": "Letouzey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1400,
      "name": "Letouzey & Ane",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1401,
      "name": "Letouzey & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1402,
      "name": "Letouzey Ane",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1403,
      "name": "Leve",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1404,
      "name": "Levy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1405,
      "name": "Lib. Ed. Vaticana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1406,
      "name": "Liberia Editrice Vaticana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1407,
      "name": "Libr. Cathol. de l'Oeuvre de St. Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1408,
      "name": "Libr. du.S. Coeur",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1409,
      "name": "Libr. eccl. & class. de Briday",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1410,
      "name": "Libr. Edit. Florentina",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1411,
      "name": "Libr. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1412,
      "name": "Libr. Hachette",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1413,
      "name": "Libr. Internat. Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1414,
      "name": "Libr. P. Geuthner/Libr. P. Desbarax",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1415,
      "name": "Libr. Religiosa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1416,
      "name": "Libr. S. Alphonse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1417,
      "name": "Libr. S. Thomas d'Aquin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1418,
      "name": "Libr. Saint-Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1419,
      "name": "Libr. Valois",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1420,
      "name": "Libr. Victor Lecoffre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1421,
      "name": "Libraires-Editeurs",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1422,
      "name": "Librairie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1423,
      "name": "Librairie De L'Action Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1424,
      "name": "Librairie de la Jeunesse ouvriere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1425,
      "name": "Librairie du Pantheon classique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1426,
      "name": "Librairie du S.C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1427,
      "name": "Librairie Liturgique -catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1428,
      "name": "Librairie Nouvelle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1429,
      "name": "Librairie St. Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1430,
      "name": "Libraria Academ. Wagneriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1431,
      "name": "Libraria Academica Wagneriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1432,
      "name": "Libraria Societatis Marianae.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1433,
      "name": "Librarie Catholice",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1434,
      "name": "Libreria Academica Wagneriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1435,
      "name": "Light of the East",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1436,
      "name": "Lithogr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1437,
      "name": "Litollf's Verlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1438,
      "name": "Little Flower Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1439,
      "name": "Liturgical Conference",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1440,
      "name": "Liturgical Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1441,
      "name": "L'Oeuvre d'Orient",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1442,
      "name": "Loiez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1443,
      "name": "Loksevak Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1444,
      "name": "London",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1445,
      "name": "Long",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1446,
      "name": "Longman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1447,
      "name": "Longmans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1448,
      "name": "Longmans/Burns & OAtes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1449,
      "name": "Lopez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1450,
      "name": "Lopez del Horno",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1451,
      "name": "Loss",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1452,
      "name": "Lotus Collection",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1453,
      "name": "Loubens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1454,
      "name": "Louvain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1455,
      "name": "Louvain Xaveriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1456,
      "name": "Lovanii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1457,
      "name": "Lovaui",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1458,
      "name": "Loyola college",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1459,
      "name": "Loyola College of Social Science",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1460,
      "name": "Loyola College Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1461,
      "name": "Loyola University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1462,
      "name": "Lutetiae Parisiorum Goupy & Soc.Morini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1463,
      "name": "Lutheran World Federation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1464,
      "name": "Lutterworth Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1465,
      "name": "Lux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1466,
      "name": "Lynne Reinner Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1467,
      "name": "M.C.C. Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1468,
      "name": "M.H. Gill and Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1469,
      "name": "Macdonald & Evans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1470,
      "name": "Macioce & Pisami",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1471,
      "name": "Macioce & Pisani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1472,
      "name": "Macmillan & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1473,
      "name": "Macmillan & Co./Clarendon Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1474,
      "name": "MacMillan Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1475,
      "name": "Macmillan India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1476,
      "name": "Macmillan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1477,
      "name": "Madras Law Journal Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1478,
      "name": "Maggs Bros.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1479,
      "name": "Magne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1480,
      "name": "Magnin & Fils",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1481,
      "name": "Maguntiae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1482,
      "name": "Maharshi Sandipani Rashtriya Veda Vidya Pratishthan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1483,
      "name": "Mainstream",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1484,
      "name": "Maison de la Bonne Presse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1485,
      "name": "Maison de l'Action Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1486,
      "name": "Maison du livre francais",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1487,
      "name": "Maison-mere des Soeurs de la Charite",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1488,
      "name": "Maktaba Jamiah",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1489,
      "name": "Maktaba Salafia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1490,
      "name": "Maktabah al-Jamhuriyyah al-'Arabiyyah",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1491,
      "name": "Malmberg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1492,
      "name": "Malmerg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1493,
      "name": "Maloine",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1494,
      "name": "Mame",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1495,
      "name": "Management Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1496,
      "name": "Manager of Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1497,
      "name": "Manak Publications Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1498,
      "name": "Manfree",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1499,
      "name": "Manohar Publishers & Distributors",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1500,
      "name": "Manorama",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1501,
      "name": "Manresa House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1502,
      "name": "Manresa Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1503,
      "name": "Manz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1504,
      "name": "Marcel Riviere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1505,
      "name": "Marchessou",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1506,
      "name": "Marescq & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1507,
      "name": "Marian Library",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1508,
      "name": "Marietti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1509,
      "name": "Marknil Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1510,
      "name": "Marlborough & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1511,
      "name": "Marpon & Flammarion",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1512,
      "name": "Marshall",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1513,
      "name": "Martin neveu & Audier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1514,
      "name": "Marymatha Major Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1515,
      "name": "Masieres & Garnier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1516,
      "name": "Masius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1517,
      "name": "Masson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1518,
      "name": "Masson & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1519,
      "name": "Matthias-Grunewald",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1520,
      "name": "Maxwell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1521,
      "name": "Mayer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1522,
      "name": "Mayfair/Malhotra Building",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1523,
      "name": "Mayolex",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1524,
      "name": "Mayr",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1525,
      "name": "Mazda Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1526,
      "name": "Mazieres et Garnier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1527,
      "name": "McCurtain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1528,
      "name": "McGlashan & Gill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1529,
      "name": "Mealli & Stianti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1530,
      "name": "Media House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1531,
      "name": "Meester",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1532,
      "name": "Meher Printing Works",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1533,
      "name": "Melisende Publishing Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1534,
      "name": "Mellier Freres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1535,
      "name": "Mens. del Cor. de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1536,
      "name": "Mensajero del Cor. de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1537,
      "name": "Mequignon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1538,
      "name": "Mequignon Jun. & Leroux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1539,
      "name": "Mercure de France",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1540,
      "name": "Messager du Coeur de Jesus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1541,
      "name": "Messager du Sacre-Coeur",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1542,
      "name": "Messager S.C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1543,
      "name": "Messenger Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1544,
      "name": "Messenger Offices",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1545,
      "name": "Messenger Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1546,
      "name": "Methodist Book Concern",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1547,
      "name": "Methuen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1548,
      "name": "Methuen & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1549,
      "name": "Meulemans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1550,
      "name": "Michael Glazier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1551,
      "name": "Michel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1552,
      "name": "Michelin & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1553,
      "name": "Microsoft Corp.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1554,
      "name": "Mignard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1555,
      "name": "Migne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1556,
      "name": "Migne/Garnier/Karuophulle/Parnassou",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1557,
      "name": "Milford",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1558,
      "name": "Millennium Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1559,
      "name": "Milner & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1560,
      "name": "Mira Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1561,
      "name": "Mission catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1562,
      "name": "Mission Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1563,
      "name": "Mission Secretariat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1564,
      "name": "Missionswissenschaftliches Institut Missio",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1565,
      "name": "Missionswissenschaftliches Institut Missio e.v.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1566,
      "name": "Mittal Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1567,
      "name": "Mizan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1568,
      "name": "Mizoram Theological Journal Board",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1569,
      "name": "Mohr",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1570,
      "name": "Mohr Siebeck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1571,
      "name": "Monaldi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1572,
      "name": "Monnom",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1573,
      "name": "Monnoyer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1574,
      "name": "Monsieur le Cure de l'Immaculee Conception",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1575,
      "name": "Mont Cesar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1576,
      "name": "Mont-Cesar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1577,
      "name": "Mont-Cesar & St. Andre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1578,
      "name": "Monteils",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1579,
      "name": "Morang & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1580,
      "name": "Morgan & Lester Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1581,
      "name": "Morgan and Scott Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1582,
      "name": "Morini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1583,
      "name": "Morning Star",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1584,
      "name": "Mosaic Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1585,
      "name": "Moser",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1586,
      "name": "Mosers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1587,
      "name": "Motilal Banarsidass Publishers Private Limited",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1588,
      "name": "Mowbray & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1589,
      "name": "MSA Secretariat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1590,
      "name": "MSFS Study House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1591,
      "name": "Muguet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1592,
      "name": "Mundell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1593,
      "name": "Munshiram Manoharlal Publishers Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1594,
      "name": "Murray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1595,
      "name": "Muse. Less",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1596,
      "name": "Museum Lesianum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1597,
      "name": "Museum Less",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1598,
      "name": "Museum Less.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1599,
      "name": "Museum Lessianum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1600,
      "name": "Museum Lessionum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1601,
      "name": "Museum Lessuianum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1602,
      "name": "Muslim India Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1603,
      "name": "N.E.S.T. Theological Review",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1604,
      "name": "N.p.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1605,
      "name": "N.P.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1606,
      "name": "NA",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1607,
      "name": "NA",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1608,
      "name": "Nadwat al-Musannifin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1609,
      "name": "Nadwatul-Ulama",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1610,
      "name": "Nagari Pracarini Sabha",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1611,
      "name": "Nageswara Rao Estates",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1612,
      "name": "Nalam Veliyeedu",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1613,
      "name": "NAMEDIA Foundations",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1614,
      "name": "Nanceii apud Leseure",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1615,
      "name": "Nanzan Inst. for Religion & Culture",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1616,
      "name": "National Book Agency",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1617,
      "name": "National Book Shop",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1618,
      "name": "National Book Trust",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1619,
      "name": "National Council of Churches",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1620,
      "name": "National Geographic Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1621,
      "name": "Navdin Prakashan Kendra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1622,
      "name": "Nelson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1623,
      "name": "Nelson & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1624,
      "name": "Nelson & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1625,
      "name": "Nelson & Sons.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1626,
      "name": "Neut",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1627,
      "name": "Neveu",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1628,
      "name": "New City Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1629,
      "name": "New Delhi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1630,
      "name": "New Review",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1631,
      "name": "New York",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1632,
      "name": "Newman Bookshop",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1633,
      "name": "Newman Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1634,
      "name": "Newnes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1635,
      "name": "Nicholas Brealey Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1636,
      "name": "NIHIL OBSTAT",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1637,
      "name": "Nijhoff & Sijthoff",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1638,
      "name": "Nimmo",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1639,
      "name": "Nisbet & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1640,
      "name": "Nischkowsky",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1641,
      "name": "Noronha",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1642,
      "name": "Nouv. libr. nation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1643,
      "name": "Nouvelle Cite",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1644,
      "name": "Novelli",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1645,
      "name": "Novelli & Castellani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1646,
      "name": "Novello",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1647,
      "name": "Novello & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1648,
      "name": "O.P.E.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1649,
      "name": "Observatorio S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1650,
      "name": "Oeuvre cath. diffus. S. Evang.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1651,
      "name": "Oeuvre Cath.de la diffusion du Saint Evangile",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1652,
      "name": "Oeuvre de la propagation de la Foi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1653,
      "name": "Oeuvre de St. Charles",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1654,
      "name": "Oeuvre pontif. de la Prop. de la Foi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1655,
      "name": "Oeuvres Cathol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1656,
      "name": "Off. tipogr. Ausonia",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1657,
      "name": "Off. typog. Artif. a S. Jos.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1658,
      "name": "Offic. Pont.S.Bernardini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1659,
      "name": "Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1660,
      "name": "Office Central de Lisieux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1661,
      "name": "Office Central de St Therese",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1662,
      "name": "Office of Prayer & Service",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1663,
      "name": "Office of the Herald",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1664,
      "name": "Officina Poligrafica editrice",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1665,
      "name": "O-ge-O",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1666,
      "name": "O'Gorman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1667,
      "name": "Oldenbourg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1668,
      "name": "Oliva",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1669,
      "name": "Oliver & Boyd",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1670,
      "name": "OM Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1671,
      "name": "Oneworld Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1672,
      "name": "Onze Studien",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1673,
      "name": "Oquaga Publishing Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1674,
      "name": "Orbis Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1675,
      "name": "Ordine dei Frati Cappuccini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1676,
      "name": "Orient Longman Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1677,
      "name": "Oriental Book Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1678,
      "name": "Orphan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1679,
      "name": "Orphan. S.Hier. Aem.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1680,
      "name": "Ortolf & Walther",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1681,
      "name": "O'Shea",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1682,
      "name": "Osmont",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1683,
      "name": "Other Media",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1684,
      "name": "Oudin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1685,
      "name": "Our Sunday Visitor Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1686,
      "name": "Our Sunday Visitor Publishing Division",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1687,
      "name": "Ouseley",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1688,
      "name": "Outhenin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1689,
      "name": "Overseas Ministries Study Center",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1690,
      "name": "Oxford University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1691,
      "name": "P. Lethielleux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1692,
      "name": "P. Tequi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1693,
      "name": "P.J. Kenedy & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1694,
      "name": "P.K. Brothers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1695,
      "name": "Pace Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1696,
      "name": "Pacifica Theological Studi Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1697,
      "name": "Pacteau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1698,
      "name": "Palme",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1699,
      "name": "Palme/Beauchesne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1700,
      "name": "Palme/Delhomme & Briguet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1701,
      "name": "Palme/Polleunis/Soc. Bolland",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1702,
      "name": "Pan Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1703,
      "name": "Pantheon Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1704,
      "name": "Paray-le Monial",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1705,
      "name": "Paris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1706,
      "name": "Parker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1707,
      "name": "Parker & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1708,
      "name": "Pasquali",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1709,
      "name": "Pastoral Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1710,
      "name": "Pastoral Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1711,
      "name": "Paternoster Publishing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1712,
      "name": "Path Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1713,
      "name": "Patriarcat Grec-Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1714,
      "name": "Patriarchal Seminary of Rachol",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1715,
      "name": "Pauline Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1716,
      "name": "Paulist Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1717,
      "name": "Paulist Press/The Seabury Press/Burns & Oates / T & T Clark",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1718,
      "name": "Paustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1719,
      "name": "Payot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1720,
      "name": "Pears",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1721,
      "name": "Pearson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1722,
      "name": "Peelman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1723,
      "name": "Peeters",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1724,
      "name": "Peeters/Vromant/Secretariat de la Societe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1725,
      "name": "Pelagaud & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1726,
      "name": "Pellegrini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1727,
      "name": "Penguin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1728,
      "name": "Penguin Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1729,
      "name": "Penguin Books (Allen Lane)",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1730,
      "name": "Penguin Books India Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1731,
      "name": "Pensee Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1732,
      "name": "People's Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1733,
      "name": "People's Reporter",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1734,
      "name": "Periose",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1735,
      "name": "Perisse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1736,
      "name": "Permanent Black",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1737,
      "name": "Perniagaan Jahabersa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1738,
      "name": "Perrin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1739,
      "name": "Perrin & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1740,
      "name": "Perrin & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1741,
      "name": "Persenaire",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1742,
      "name": "Perthes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1743,
      "name": "Pesnot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1744,
      "name": "Peter Hanstein Verlagsbuchhandlung",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1745,
      "name": "Peter Lang",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1746,
      "name": "Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1747,
      "name": "Pfeiffer & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1748,
      "name": "Philip",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1749,
      "name": "Philippi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1750,
      "name": "PHISPC",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1751,
      "name": "Phoenicilrum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1752,
      "name": "Piazza S. Apollinare",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1753,
      "name": "Picador",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1754,
      "name": "Picard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1755,
      "name": "Picard & Fils / Bureaux des Etudes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1756,
      "name": "Picard-Balon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1757,
      "name": "Pickering",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1758,
      "name": "Pierre Tequi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1759,
      "name": "Pilgrim Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1760,
      "name": "Pilgrim Publishers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1761,
      "name": "Pinet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1762,
      "name": "Pisai",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1763,
      "name": "Pitman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1764,
      "name": "Pitman & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1765,
      "name": "Plantijn-Moretus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1766,
      "name": "Plantinian-Moreti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1767,
      "name": "Plon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1768,
      "name": "Plon-Nourrit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1769,
      "name": "Plot-Nourrit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1770,
      "name": "Pocket Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1771,
      "name": "Pocket Books Inc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1772,
      "name": "Poelman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1773,
      "name": "Poision",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1774,
      "name": "Poleman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1775,
      "name": "Polleunis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1776,
      "name": "Polleunis & Ceuterick",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1777,
      "name": "Polskie Towarzystwo Theologiczne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1778,
      "name": "Pompeati",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1779,
      "name": "Pont. Comm. di Archeol. Sacra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1780,
      "name": "Pont. Comm. per la Pastorale delle Migrazioni e del Turismo",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1781,
      "name": "Pont. Inst. Bibl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1782,
      "name": "Pont. Inst. Bibl. (lithogr.)",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1783,
      "name": "Pont. Inst. Orient. Studiorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1784,
      "name": "Pont. Inst. Pio IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1785,
      "name": "Pont. Inst. Pio XI",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1786,
      "name": "Pont. Inst. Utriusque Iuris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1787,
      "name": "Pont. Instit. \"Angelicum\", Romae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1788,
      "name": "Pont. Instit. Bibl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1789,
      "name": "Pont. Instit. Orient. Studiorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1790,
      "name": "Pont. Instit. Pii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1791,
      "name": "Pont. Univ. Greg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1792,
      "name": "Pont. Univ. Gregor.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1793,
      "name": "Pont. Univ. Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1794,
      "name": "Pont. Univ. Lateranensis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1795,
      "name": "Pont. Univ. Sanctae Crucis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1796,
      "name": "Pontif. Univ. Gregor.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1797,
      "name": "Pontifical Council For The Laity",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1798,
      "name": "Pontificia Universitas Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1799,
      "name": "Pontificio Istituto Orientale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1800,
      "name": "Pontificium Consilium de Cultura",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1801,
      "name": "Pontificium Institutum Orientalium Studiorum",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1802,
      "name": "Popular Prakashan Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1803,
      "name": "Por Gabriel Nogues",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1804,
      "name": "Porter",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1805,
      "name": "Poussielgue",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1806,
      "name": "Poussielgue-de Gigord",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1807,
      "name": "Poussielgue-Rusand",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1808,
      "name": "Prabhat Prakasan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1809,
      "name": "Prades",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1810,
      "name": "Pressverein",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1811,
      "name": "Prieure d'Amay-sur-Meuse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1812,
      "name": "Princeton University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1813,
      "name": "Prinsstraat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1814,
      "name": "Printed by order of the Trustees",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1815,
      "name": "Privat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1816,
      "name": "Private",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1817,
      "name": "Privilegierte Wurttembergische Bibelanstalt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1818,
      "name": "Procure de la Miss. du Madure",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1819,
      "name": "Procure des Soeurs Blanches",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1820,
      "name": "Procure Generale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1821,
      "name": "Prometheus Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1822,
      "name": "Proost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1823,
      "name": "Prop. of the Faith",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1824,
      "name": "Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1825,
      "name": "Prost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1826,
      "name": "Provincial",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1827,
      "name": "Publication Bureau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1828,
      "name": "Punjab Vernacular Catholic Truth Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1829,
      "name": "Punthi Pustak",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1830,
      "name": "Pustet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1831,
      "name": "Pustet Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1832,
      "name": "Putiois-Crette",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1833,
      "name": "Putna & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1834,
      "name": "Putnam",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1835,
      "name": "Putnam's Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1836,
      "name": "Putois-Crette",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1837,
      "name": "Queen's Work",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1838,
      "name": "R.K. Boek-Centrale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1839,
      "name": "R.K.Propag. Genootschap",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1840,
      "name": "Raber & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1841,
      "name": "Radha Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1842,
      "name": "Radio League of the Little Flower",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1843,
      "name": "Raeber",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1844,
      "name": "Rajkamal Parkashan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1845,
      "name": "Ramakrishna",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1846,
      "name": "Randolph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1847,
      "name": "Ratisbonae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1848,
      "name": "Rauch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1849,
      "name": "Rauch/Herder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1850,
      "name": "Rauche",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1851,
      "name": "Ravi Academic Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1852,
      "name": "Rawat Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1853,
      "name": "Rawat Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1854,
      "name": "Razon y Fe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1855,
      "name": "Reader's Digest Association",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1856,
      "name": "Rebaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1857,
      "name": "Recueil Sirey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1858,
      "name": "Red Star Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1859,
      "name": "Reeves",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1860,
      "name": "Refle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1861,
      "name": "Regis-Ruffet & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1862,
      "name": "Reiss",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1863,
      "name": "Relfe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1864,
      "name": "Religious Tract Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1865,
      "name": "Remacle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1866,
      "name": "Remy",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1867,
      "name": "Renaissance du Livre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1868,
      "name": "Renault",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1869,
      "name": "Repos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1870,
      "name": "Retaust - Brey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1871,
      "name": "Retaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1872,
      "name": "Retaux-Bray",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1873,
      "name": "Retraitenhuis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1874,
      "name": "Reuther",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1875,
      "name": "Reuther & Richard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1876,
      "name": "Rev. Asc. Myst.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1877,
      "name": "Rev. Cam. Apostol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1878,
      "name": "Rev. des Jeunes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1879,
      "name": "Rev. des Sc. Phil. & Theol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1880,
      "name": "Rev. des Sciences Philos. & Theol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1881,
      "name": "Review & Herald Publ. Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1882,
      "name": "Revista Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1883,
      "name": "Revival Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1884,
      "name": "Revue des auteurs & des livres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1885,
      "name": "Revue des Jeunes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1886,
      "name": "Revue Thomiste",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1887,
      "name": "Reynell",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1888,
      "name": "Ricci",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1889,
      "name": "Rich & Cowan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1890,
      "name": "Richards",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1891,
      "name": "Richardson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1892,
      "name": "Richardson & Son",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1893,
      "name": "Ricker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1894,
      "name": "Ridgway",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1895,
      "name": "Rieder & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1896,
      "name": "Rigaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1897,
      "name": "Rion-Reuze",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1898,
      "name": "Riviere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1899,
      "name": "Rivington",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1900,
      "name": "Rivingtons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1901,
      "name": "Rochouse",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1902,
      "name": "Roder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1903,
      "name": "Roermondsche Stoomdrukkerij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1904,
      "name": "Roger & Chernoviz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1905,
      "name": "Romae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1906,
      "name": "Rome",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1907,
      "name": "Romen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1908,
      "name": "Romiere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1909,
      "name": "Rosmini House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1910,
      "name": "Rosso",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1911,
      "name": "Rousseau",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1912,
      "name": "Routledge",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1913,
      "name": "Routledge & Kegan Paul",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1914,
      "name": "Rozez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1915,
      "name": "Rue des Recollets",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1916,
      "name": "Rue des Ursulines",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1917,
      "name": "rue du Bac.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1918,
      "name": "Ruffet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1919,
      "name": "Rupa & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1920,
      "name": "Ryckmans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1921,
      "name": "S Gabalda",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1922,
      "name": "S. Congr. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1923,
      "name": "S. Martino",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1924,
      "name": "S.C. de Prop. Fide.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1925,
      "name": "S.C. De Propaganda Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1926,
      "name": "S.C.de Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1927,
      "name": "S.I.L.I.C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1928,
      "name": "s.n.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1929,
      "name": "Sabrang Communications & Publishing Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1930,
      "name": "Saccidananda Ashram",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1931,
      "name": "Sacred Congregation of Religious",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1932,
      "name": "Sadlier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1933,
      "name": "Sadlier & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1934,
      "name": "Sage Publications India Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1935,
      "name": "Saint Anselm's Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1936,
      "name": "Salani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1937,
      "name": "Salesian Journal of Spirituality",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1938,
      "name": "Salesian Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1939,
      "name": "Sameeksha Trust Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1940,
      "name": "Sampson Low",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1941,
      "name": "SAMYA",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1942,
      "name": "Sands",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1943,
      "name": "Sands & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1944,
      "name": "Sands/Herder",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1945,
      "name": "Sangam (JDVSC)",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1946,
      "name": "Sang-e-Meel Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1947,
      "name": "Sanjivan Niwas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1948,
      "name": "Sanjivan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1949,
      "name": "Saqi Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1950,
      "name": "Sarasvati Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1951,
      "name": "Sarlit",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1952,
      "name": "Sarup & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1953,
      "name": "Satpracar Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1954,
      "name": "Satprakashan Sanchar Kendra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1955,
      "name": "Satya Manthan Sanstha",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1956,
      "name": "Satya Nilayam Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1957,
      "name": "Saulchoir",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1958,
      "name": "Saxon & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1959,
      "name": "Sayyid Ahmad Qadiri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1960,
      "name": "Scepter",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1961,
      "name": "SCEPTRE",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1962,
      "name": "Schaerer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1963,
      "name": "Schepens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1964,
      "name": "Schieningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1965,
      "name": "Schlebusch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1966,
      "name": "Schnoningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1967,
      "name": "Schoening",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1968,
      "name": "Schoeningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1969,
      "name": "Schol. Industr",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1970,
      "name": "Schola sup. phil. S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1971,
      "name": "Scholars Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1972,
      "name": "Schoningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1973,
      "name": "Schott & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1974,
      "name": "Schulz & Thuillie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1975,
      "name": "Schuter & Happach",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1976,
      "name": "Schwabenverlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1977,
      "name": "Schwann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1978,
      "name": "Scientific American",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1979,
      "name": "SCJM Provincialate",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1980,
      "name": "SCM Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1981,
      "name": "Scott",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1982,
      "name": "Scribner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1983,
      "name": "Scribner's Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1984,
      "name": "Scuola tip. \"S.Nilo\", Grottaferrata",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1985,
      "name": "Scuola tipogr. Pio",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1986,
      "name": "Scuola Tipogr. Pio X.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1987,
      "name": "Scuola Tipogr. Salesiana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1988,
      "name": "Seabury Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1989,
      "name": "Secr. Centr. Congr. B.M.V.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1990,
      "name": "Secr. de la C.E.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1991,
      "name": "Secr. of the Light of the East",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1992,
      "name": "Secret of the Light of the East",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1993,
      "name": "Secret. der Xaverianen...",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1994,
      "name": "Secret. des Congragations",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1995,
      "name": "Secret. Des Semaines Sociales",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1996,
      "name": "Secret. for Promoting Christian Unity",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1997,
      "name": "Secret. Gen. J.O.C.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1998,
      "name": "Secretariat de la Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 1999,
      "name": "Secretariat de Redaction & Administration",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2000,
      "name": "Secretariat du CSCO",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2001,
      "name": "Secretariat General d'Action Catholique",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2002,
      "name": "Secretariat General de l'A.C.J.E.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2003,
      "name": "Secretariat General de l'A.C.J.F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2004,
      "name": "Secretariat General des Unions Professionelles chretiennes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2005,
      "name": "SEDOS",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2006,
      "name": "Seeley & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2007,
      "name": "Seely & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2008,
      "name": "Seguin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2009,
      "name": "Sekr. van den E.K.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2010,
      "name": "Sekr. van E.K.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2011,
      "name": "Sekret. der Catholica Unio Fur Osterreich",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2012,
      "name": "Sekret. E.K.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2013,
      "name": "Sekretariaat E.K.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2014,
      "name": "Sem. Pont.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2015,
      "name": "Seminaire Sainte-Anne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2016,
      "name": "Seminar Schoneck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2017,
      "name": "Seminarium",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2018,
      "name": "Seminarium S. Mariae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2019,
      "name": "Seminarium S. Mariae (lithogr.)",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2020,
      "name": "Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2021,
      "name": "Seminary of Our Lady",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2022,
      "name": "Serials Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2023,
      "name": "Seymour & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2024,
      "name": "Sheed & Ward",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2025,
      "name": "Sheffield Academic Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2026,
      "name": "Sheldon Press/Oriental Books Repr. Corp.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2027,
      "name": "Shepherds of Christ Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2028,
      "name": "Shoeningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2029,
      "name": "Shoningh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2030,
      "name": "Shubhi Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2031,
      "name": "Shuman",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2032,
      "name": "Sidgwick & Jackson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2033,
      "name": "Siffer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2034,
      "name": "Simon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2035,
      "name": "Simpkin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2036,
      "name": "Simpkin & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2037,
      "name": "Simpkin...& Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2038,
      "name": "Singh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2039,
      "name": "Sloan-Duployan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2040,
      "name": "Smeesters",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2041,
      "name": "Smith",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2042,
      "name": "Soc. anon. d'impr. Montbeliardaise",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2043,
      "name": "Soc. Anon. fra Cattolici Vicentini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2044,
      "name": "Soc. Belg. Libr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2045,
      "name": "Soc. Belge de Libr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2046,
      "name": "Soc. belge de Libr./A. Roger et F. Chernoviz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2047,
      "name": "Soc. Belge de Librairie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2048,
      "name": "Soc. Bibliogr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2049,
      "name": "Soc. d'edit. \"Les belles lettres\", Paris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2050,
      "name": "Soc. des Bollandistes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2051,
      "name": "Soc. des Bonnes Lectures",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2052,
      "name": "Soc. des Nations",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2053,
      "name": "Soc. Ed. Anon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2054,
      "name": "Soc. Edit. Anon.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2055,
      "name": "Soc. Edit. Rom.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2056,
      "name": "Soc. Editr. De Forel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2057,
      "name": "Soc. editr. internaz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2058,
      "name": "Soc. for Distributing the H. Scripture to the Jews",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2059,
      "name": "Soc. Gen. de Libr. Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2060,
      "name": "Soc. Generale de Libr. Cathol.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2061,
      "name": "Soc. Intern.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2062,
      "name": "Soc. Nat. Pour la des bons livres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2063,
      "name": "Soc. Nationale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2064,
      "name": "Soc. Nationale pour la propagation des bons livres",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2065,
      "name": "Soc. of St. Jerome",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2066,
      "name": "Soc. Polygr. Edit. Ital.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2067,
      "name": "Soc. Tip.- Ed. Naz.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2068,
      "name": "Soc. Tip.-Ed. Naz.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2069,
      "name": "Soc. Tip.-Edit. Nazionale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2070,
      "name": "Soc.des Bollandistes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2071,
      "name": "Societe Belge de Librairie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2072,
      "name": "Societe Des Bollandistes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2073,
      "name": "Societe Nationale",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2074,
      "name": "Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2075,
      "name": "Society Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2076,
      "name": "South African Missiological Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2077,
      "name": "Spaeth & Linde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2078,
      "name": "Spanoghe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2079,
      "name": "SPCK",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2080,
      "name": "SPCK in India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2081,
      "name": "Spectrum Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2082,
      "name": "Speirani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2083,
      "name": "Spencer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2084,
      "name": "Spes",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2085,
      "name": "Spic. S. Lov. Bureaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2086,
      "name": "Spic. Sac. Lov.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2087,
      "name": "Spicilegium Sacrum Lovan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2088,
      "name": "Spiritus",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2089,
      "name": "Splichal-Roosen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2090,
      "name": "Spokane",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2091,
      "name": "Sri Ramakrishna Math",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2092,
      "name": "Sri Ramanasramam",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2093,
      "name": "St Anselm'S Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2094,
      "name": "St Anthony Messenger Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2095,
      "name": "St Anthony'S Friary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2096,
      "name": "St Joseph's Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2097,
      "name": "St Mary's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2098,
      "name": "St. Albert's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2099,
      "name": "St. Aloysius College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2100,
      "name": "St. Andrew's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2101,
      "name": "St. Anselm's Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2102,
      "name": "St. Anselm's Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2103,
      "name": "St. Anthony Guild Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2104,
      "name": "St. Francis Book Shop",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2105,
      "name": "St. Gildard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2106,
      "name": "St. John's Regional Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2107,
      "name": "St. Joseph'a Pontifical Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2108,
      "name": "St. Joseph's",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2109,
      "name": "St. Joseph's Cath. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2110,
      "name": "St. Joseph's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2111,
      "name": "St. Joseph's I.S.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2112,
      "name": "St. Joseph's I.S. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2113,
      "name": "St. Joseph's Industr. School Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2114,
      "name": "St. Joseph's Inst. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2115,
      "name": "St. Joseph's Mill Hill",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2116,
      "name": "St. Joseph's Monastery",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2117,
      "name": "St. Joseph's Pontifical Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2118,
      "name": "St. Joseph's Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2119,
      "name": "St. Mary's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2120,
      "name": "St. Patrick's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2121,
      "name": "St. Paul Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2122,
      "name": "St. Paul Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2123,
      "name": "St. Paul.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2124,
      "name": "St. Pauls",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2125,
      "name": "St. Pauls Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2126,
      "name": "St. Pauls Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2127,
      "name": "St. Peter's Pontifical Institute of Theology",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2128,
      "name": "St. Thomas Apostolic Seminary",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2129,
      "name": "St. Vladimir's Seminary Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2130,
      "name": "St. Xavier's College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2131,
      "name": "St.Louis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2132,
      "name": "Stagni",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2133,
      "name": "Stahel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2134,
      "name": "Stahel'sche Verlags Anstalt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2135,
      "name": "Stanbrook Abbey Worcester",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2136,
      "name": "Standaard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2137,
      "name": "Standaard Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2138,
      "name": "Standaard-Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2139,
      "name": "Standard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2140,
      "name": "Standard Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2141,
      "name": "Standard-Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2142,
      "name": "Stanley Paul & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2143,
      "name": "State University of New York Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2144,
      "name": "Steinhauss",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2145,
      "name": "Steinheil",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2146,
      "name": "Sterling Publishers Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2147,
      "name": "Stewart & Kidd Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2148,
      "name": "Stock",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2149,
      "name": "Stratford Company",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2150,
      "name": "Stree",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2151,
      "name": "Stroheckeri",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2152,
      "name": "Strong",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2153,
      "name": "Student Christ. Mov. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2154,
      "name": "Student Christina Movement Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2155,
      "name": "Styria",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2156,
      "name": "Subirana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2157,
      "name": "Sueur-Charruey",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2158,
      "name": "Sumpt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2159,
      "name": "sumpt. auctoris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2160,
      "name": "Sumpt. Borde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2161,
      "name": "Sumpt. Christ.Simonis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2162,
      "name": "Sumpt. Congr. Mechithar",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2163,
      "name": "Sumpt. F. Craetz & T. Summer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2164,
      "name": "Sumpt. Jac. et Petri Prost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2165,
      "name": "Sumpt. Mart. Veith & Jod. Miiller",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2166,
      "name": "Sumpt. Matthaei Rieger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2167,
      "name": "Sumpt. Ordinis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2168,
      "name": "Sumpt. Petri Henningij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2169,
      "name": "Sumpt. Phil.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2170,
      "name": "Sumpt. Philippi Borde",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2171,
      "name": "Sumpt. Prost.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2172,
      "name": "Sumpt. Rommerskirchen & Simonis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2173,
      "name": "Sumpt. Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2174,
      "name": "Sumpt. Societatis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2175,
      "name": "Sumpt.Bourlier & Aubin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2176,
      "name": "Sumpt.Petri Rigaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2177,
      "name": "Sumptibas N. Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2178,
      "name": "Sumptibus Evangelistae Deuchini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2179,
      "name": "Sumptibus Gasparis Fritsch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2180,
      "name": "Sumptibus I. Haffrey & I. Heursii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2181,
      "name": "Sumptibus Nicolai Pezzana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2182,
      "name": "Sumptibus Prost",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2183,
      "name": "Sumptibus Remondinianis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2184,
      "name": "Sumptibus Sebastiani Haupt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2185,
      "name": "Sumptibus Sebastiani Ketteler",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2186,
      "name": "Suore di carita",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2187,
      "name": "Surabhi Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2188,
      "name": "Suruchi Prakashan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2189,
      "name": "SVD Central Indian Province",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2190,
      "name": "Swan Sonnenschein & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2191,
      "name": "Swedish Research Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2192,
      "name": "Sydney Lee",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2193,
      "name": "Szelinski & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2194,
      "name": "T & T Clark",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2195,
      "name": "T. Baker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2196,
      "name": "T. Longmans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2197,
      "name": "t'Serstevens",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2198,
      "name": "T&T Clark",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2199,
      "name": "Tablet Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2200,
      "name": "Taffin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2201,
      "name": "Taffin-Lefort",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2202,
      "name": "Ta-Ha Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2203,
      "name": "Tandou & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2204,
      "name": "Tao Fong Shan Ecum. Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2205,
      "name": "Taraporevalla",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2206,
      "name": "Tardy-Pigelet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2207,
      "name": "Tata Institute",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2208,
      "name": "Tauchnitii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2209,
      "name": "Tauchnitz",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2210,
      "name": "Taylor & Francis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2211,
      "name": "Tegg & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2212,
      "name": "Tempsky",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2213,
      "name": "ten Hagens' drukkerij en uitgevers-Mij.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2214,
      "name": "Tequi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2215,
      "name": "Teubner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2216,
      "name": "Teulings",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2217,
      "name": "Thacker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2218,
      "name": "The America Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2219,
      "name": "The Ave Maria Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2220,
      "name": "The Bruce Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2221,
      "name": "The Cathedral Libr. Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2222,
      "name": "The Century Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2223,
      "name": "The Crime Club",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2224,
      "name": "The Dolphin Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2225,
      "name": "The Don Bosco Industrial School",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2226,
      "name": "The Educational Book Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2227,
      "name": "The Encyclopedia Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2228,
      "name": "The Examiner Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2229,
      "name": "The Fort printing Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2230,
      "name": "The Good Pastor Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2231,
      "name": "The Grolier Soc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2232,
      "name": "The Islamic Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2233,
      "name": "The Labour Party",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2234,
      "name": "The Little Flower Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2235,
      "name": "The Macmillan Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2236,
      "name": "The Maharaja Sayajirao Univ. of Baroda",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2237,
      "name": "The Manresa Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2238,
      "name": "The Mar Thoma Sliha Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2239,
      "name": "The New American Library/The New English Library",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2240,
      "name": "The New Review",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2241,
      "name": "The New York World-Telegram",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2242,
      "name": "The Pilot publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2243,
      "name": "The Queen's Work",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2244,
      "name": "The Queen's Work Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2245,
      "name": "The Religious Tract Soc",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2246,
      "name": "The Theosophical Society",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2247,
      "name": "The Univ. Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2248,
      "name": "The Universal Knowledge Foundation",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2249,
      "name": "The Vir Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2250,
      "name": "The World's Work",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2251,
      "name": "Theissing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2252,
      "name": "Theissingina Libraria",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2253,
      "name": "Theodoric & Chevalier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2254,
      "name": "Theologica Societatis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2255,
      "name": "Theological Publications in India",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2256,
      "name": "Theology Centre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2257,
      "name": "Theonville",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2258,
      "name": "Thibaud",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2259,
      "name": "Think AchieveProsper Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2260,
      "name": "Third World Network",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2261,
      "name": "Thomaen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2262,
      "name": "Thomas Baker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2263,
      "name": "Thorin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2264,
      "name": "Thornton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2265,
      "name": "Thornton Butterworth",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2266,
      "name": "Tip. Pont. Inst. Pio IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2267,
      "name": "Tip. Pont. Istit. Pio IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2268,
      "name": "Tip. Salesiana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2269,
      "name": "Tip.Poligi.Vat.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2270,
      "name": "Tipogr. Poligl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2271,
      "name": "Tolra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2272,
      "name": "Tolra & Haton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2273,
      "name": "Tonkin Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2274,
      "name": "Topelmann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2275,
      "name": "Topo-lit. Romolo Pioda",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2276,
      "name": "Tournachon-Molin & H. Seguin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2277,
      "name": "Tournai",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2278,
      "name": "T'ou-Se-We Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2279,
      "name": "Tralin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2280,
      "name": "Treen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2281,
      "name": "Treves",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2282,
      "name": "Trichinopoly",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2283,
      "name": "Triltsch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2284,
      "name": "Trinity Press International",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2285,
      "name": "Trinity St Mungo Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2286,
      "name": "Trognaesius",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2287,
      "name": "Trowitzsch",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2288,
      "name": "Trubner & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2289,
      "name": "Tuebner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2290,
      "name": "Tulika Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2291,
      "name": "Tup. coll. S. Bonaventurae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2292,
      "name": "Twenty-Third Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2293,
      "name": "Tyogr. iuvenum opificum a S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2294,
      "name": "Typ. \"San Polino\", Utini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2295,
      "name": "typ. & sumpt. Joan.Caspari Bencard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2296,
      "name": "Typ. a SS. Conceptione",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2297,
      "name": "Typ. Aemiliana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2298,
      "name": "Typ. Balleoniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2299,
      "name": "Typ. Brancadoro",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2300,
      "name": "typ. Civilitatis Cath.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2301,
      "name": "Typ. Coll. S. Bonaventurae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2302,
      "name": "Typ. Coll. S.Bona",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2303,
      "name": "Typ. Consoc. S.Pauli",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2304,
      "name": "Typ. Dom. Probat.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2305,
      "name": "Typ. domus missionum S.Mich. Arch.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2306,
      "name": "Typ. Florentinii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2307,
      "name": "Typ. G. Lopez del Horno",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2308,
      "name": "Typ. Iuv. Opif. a S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2309,
      "name": "Typ. Juv. Ofif. A.S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2310,
      "name": "Typ. Juv. Opif. S. Joseph/Univ. Greg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2311,
      "name": "Typ. Juven. Opif. a S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2312,
      "name": "Typ. Kerchouianis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2313,
      "name": "Typ. Loyolaea",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2314,
      "name": "Typ. missionis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2315,
      "name": "Typ. Polygl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2316,
      "name": "Typ. Polygl. Vatic.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2317,
      "name": "Typ. Polygl./Giachetti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2318,
      "name": "Typ. Polyglo",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2319,
      "name": "Typ. Polyglotta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2320,
      "name": "Typ. Pont. in Inst. Pii IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2321,
      "name": "Typ. Pont. Inst. Pii IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2322,
      "name": "Typ. Pont.Inst. Pii IX/Typis Proto-coenobii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2323,
      "name": "typ. S. Congr. de prop. fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2324,
      "name": "Typ. S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2325,
      "name": "Typ. S.Cong.Propag./Spithoever/Typ. Vatic.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2326,
      "name": "Typ. S.Congr. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2327,
      "name": "Typ. Scholae Industr.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2328,
      "name": "Typ. Scholae Industrialis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2329,
      "name": "Typ. Societatis Divini Salvatoris",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2330,
      "name": "typ. Valentini",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2331,
      "name": "Typ. Vatic.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2332,
      "name": "Typ. Vaticanis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2333,
      "name": "Typ. Vicar. apost. buscoduc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2334,
      "name": "Typis \"Grafia\", Romae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2335,
      "name": "Typis Abbatiae.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2336,
      "name": "Typis Academicis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2337,
      "name": "Typis Aemilianis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2338,
      "name": "Typis Avrial/La editorial iberica/Borgo S. Spirito",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2339,
      "name": "Typis Avrial/Typis Fortanet",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2340,
      "name": "Typis avrial/Typis Lopez del Horno",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2341,
      "name": "typis Bartholomaei Occhi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2342,
      "name": "Typis Bened. Milochi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2343,
      "name": "typis C.R. Scient. Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2344,
      "name": "Typis Catholicis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2345,
      "name": "Typis Collegii S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2346,
      "name": "Typis Congr. Mechithrasticae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2347,
      "name": "Typis Dilgerianis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2348,
      "name": "Typis Francisci Seguin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2349,
      "name": "Typis Haeredum Widmanstadii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2350,
      "name": "Typis Joan Van Overbeke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2351,
      "name": "Typis L. Johnson",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2352,
      "name": "Typis Lopez Del Horno",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2353,
      "name": "Typis Missionis S.J.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2354,
      "name": "Typis P.J. Hanicq",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2355,
      "name": "Typis polygl. Vaticani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2356,
      "name": "Typis polygl. Vaticanis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2357,
      "name": "Typis Polygl. Vaticanis/Giachetti /S. Joannis/Apud Curiam Praep. Gener.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2358,
      "name": "Typis Polyglottis Vaticanis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2359,
      "name": "Typis Ribadeneyra",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2360,
      "name": "Typis S. Congr. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2361,
      "name": "Typis S. Congr. de Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2362,
      "name": "Typis S.C. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2363,
      "name": "Typis S.C. de Prop. Fides",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2364,
      "name": "typis S.C. de Propa",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2365,
      "name": "Typis S.C. de Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2366,
      "name": "Typis S.C.de Propag. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2367,
      "name": "Typis S.C.de Propag.Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2368,
      "name": "typis S.Cong. de Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2369,
      "name": "Typis Soc. Miss. ad Ext.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2370,
      "name": "Typis Vaticanis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2371,
      "name": "Typog. \"Buona stampa\", Romae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2372,
      "name": "Typog. \"Tata Giovanni\", Romae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2373,
      "name": "Typog. artif. a S. Joseph",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2374,
      "name": "Typog. Augustiniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2375,
      "name": "Typog. Balleoniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2376,
      "name": "Typog. Collegii S. Bonaventurae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2377,
      "name": "Typog. Congr. Orat. Typog. Vatic.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2378,
      "name": "Typog. de prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2379,
      "name": "Typog. Gisleni Janssenii",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2380,
      "name": "Typog. iuv. opificum a S. Josepho",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2381,
      "name": "Typog. juvenum opificum a S.Josepho",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2382,
      "name": "Typog. O.S.B. Rajhard",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2383,
      "name": "Typog. Polygl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2384,
      "name": "Typog. Polygl. / Pont. Instit. Pie IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2385,
      "name": "Typog. Polyglotta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2386,
      "name": "Typog. Polyglotta Prop. Fide",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2387,
      "name": "Typog. Pont. in Instit. Pii IX",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2388,
      "name": "Typog. S. Josephi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2389,
      "name": "Typog. S.C. de P.F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2390,
      "name": "Typog. vulgo \"Commerciale\", Viglevani",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2391,
      "name": "Typog.Polyglotta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2392,
      "name": "Typogr. \"La editorial iberica\", Matriti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2393,
      "name": "Typogr. a SS. Conceptione",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2394,
      "name": "Typogr. academica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2395,
      "name": "Typogr. Archiepisc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2396,
      "name": "Typogr. Cooper. Social",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2397,
      "name": "Typogr. gia \"Vera Roma\", Roma",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2398,
      "name": "Typogr. Loyolaea",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2399,
      "name": "typogr. perugina",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2400,
      "name": "Typogr. Polygl.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2401,
      "name": "Typogr. polyglotta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2402,
      "name": "Typogr. S. Francisci",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2403,
      "name": "Typogr. Viduae Car. Boscardi",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2404,
      "name": "Typographia academica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2405,
      "name": "Typographia Polyglotta",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2406,
      "name": "Typographia Provinciae",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2407,
      "name": "Typographorum Societas",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2408,
      "name": "U.K.F.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2409,
      "name": "U.M.I. Dissertation Information Service",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2410,
      "name": "U.S. Government Printing Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2411,
      "name": "Ubhodan Kavyalaya",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2412,
      "name": "Ufficio Succursale della Civilta Cattolica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2413,
      "name": "Uitgeverij-Boekhandel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2414,
      "name": "UNICEF House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2415,
      "name": "Union Missionnaire du Clerge",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2416,
      "name": "Union Seminary Quarterly Review",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2417,
      "name": "Unione Mission. del Clero / Pont. Universita Urbaniana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2418,
      "name": "Unione Tip. Editr. Torinese",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2419,
      "name": "Unionis Editricis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2420,
      "name": "United States Catholic Conference",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2421,
      "name": "United Theological College",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2422,
      "name": "Univ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2423,
      "name": "Univ. Cattolica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2424,
      "name": "Univ. Greg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2425,
      "name": "Univ. Gregor.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2426,
      "name": "Univ. Gregoriana Ed.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2427,
      "name": "Univ. Knowl. Foundat.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2428,
      "name": "Univ. of Connecticut",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2429,
      "name": "Univ. of Poona",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2430,
      "name": "Univ. Tutorial Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2431,
      "name": "Universe",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2432,
      "name": "Universe/B.O. & W./Office",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2433,
      "name": "Universidad Catolica Boliviana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2434,
      "name": "Universidad del Salvador",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2435,
      "name": "Universidad Intercontinental",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2436,
      "name": "Universita Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2437,
      "name": "Universitas Catholica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2438,
      "name": "Universitate Gregoriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2439,
      "name": "University",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2440,
      "name": "University of Chicago Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2441,
      "name": "University of Dayton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2442,
      "name": "University of Michigan Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2443,
      "name": "University of Notre Dame Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2444,
      "name": "University of Ottawa Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2445,
      "name": "University of Poona",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2446,
      "name": "University of Texas Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2447,
      "name": "University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2448,
      "name": "University Press of America",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2449,
      "name": "Univiversitas Catholica",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2450,
      "name": "Unwin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2451,
      "name": "Uystpruyst",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2452,
      "name": "Vaigarai Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2453,
      "name": "Valinthout & Vandenzande",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2454,
      "name": "Valkenburg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2455,
      "name": "Van Aalst",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2456,
      "name": "Van de Weijer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2457,
      "name": "Van den Broeck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2458,
      "name": "Van der Schelden",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2459,
      "name": "Van Druten",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2460,
      "name": "Van Gompel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2461,
      "name": "Van In",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2462,
      "name": "Van Langenhuijzen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2463,
      "name": "Van Langenhuysen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2464,
      "name": "Van Linthout",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2465,
      "name": "Van Linthout / Houdmont",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2466,
      "name": "Van Linthout & Vandenzande",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2467,
      "name": "Van Oest",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2468,
      "name": "Van Os",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2469,
      "name": "Van Overbeke",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2470,
      "name": "Van Velsen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2471,
      "name": "Van Vlaenderen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2472,
      "name": "Vanden Berghe-Denaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2473,
      "name": "Vandenberghe-Denaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2474,
      "name": "Vandenbroeck",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2475,
      "name": "Vandenhoeck & Ruprecht",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2476,
      "name": "Vander Haert",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2477,
      "name": "Vander Schelden",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2478,
      "name": "Vanderborght",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2479,
      "name": "Vanderbought",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2480,
      "name": "Vandereydt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2481,
      "name": "Vandereydt / Vromant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2482,
      "name": "Vanhee",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2483,
      "name": "Vanhee-Wante",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2484,
      "name": "Vanhoebroeck Goidts",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2485,
      "name": "Vanlinthout",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2486,
      "name": "Vanlinthout & Vandenzande",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2487,
      "name": "Vanlinthout et Vandenzande",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2488,
      "name": "Vanos",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2489,
      "name": "Vanryckegem",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2490,
      "name": "Vanryckegem-Hovaere",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2491,
      "name": "Varret",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2492,
      "name": "Vatican Polyglot Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2493,
      "name": "Vaticanis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2494,
      "name": "Vaton",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2495,
      "name": "Vautrain",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2496,
      "name": "Vedams ebooks",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2497,
      "name": "Veen",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2498,
      "name": "Veith",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2499,
      "name": "Velhagen & Klafing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2500,
      "name": "Velhagen & Klasing",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2501,
      "name": "Vendrame Institute Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2502,
      "name": "Venetiis",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2503,
      "name": "Verbeke & Cie",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2504,
      "name": "Verboeckhoven & Cie.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2505,
      "name": "Verhoeven",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2506,
      "name": "Veritas Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2507,
      "name": "Verlag",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2508,
      "name": "Verlag Alfred Topelmann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2509,
      "name": "Vermilion",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2510,
      "name": "Vermot",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2511,
      "name": "Verso",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2512,
      "name": "Versok",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2513,
      "name": "Via Carlo Alberto/Polyglot Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2514,
      "name": "Via Del Seminario",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2515,
      "name": "Vic & Amat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2516,
      "name": "Vic et Amat",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2517,
      "name": "Victor Retaux",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2518,
      "name": "Vidyajyoti",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2519,
      "name": "Vidyajyoti/ISPCK",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2520,
      "name": "Vie Spirituelle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2521,
      "name": "Vikas Publishing House Pvt. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2522,
      "name": "Viking",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2523,
      "name": "Viking in Association with Rupa & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2524,
      "name": "Viking Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2525,
      "name": "Villanova University",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2526,
      "name": "Vincent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2527,
      "name": "Vincentian Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2528,
      "name": "Virtue & Coe.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2529,
      "name": "Vision Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2530,
      "name": "Visvabharati",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2531,
      "name": "Visva-bharati",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2532,
      "name": "Visvas Prasiksan Kendre",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2533,
      "name": "Vita e Paneiero",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2534,
      "name": "Vitte",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2535,
      "name": "Vitte & Perrussel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2536,
      "name": "Vitte & Perussel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2537,
      "name": "Vives",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2538,
      "name": "Vix",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2539,
      "name": "Vlaa msche Boekenhalle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2540,
      "name": "Vlaamsche Boekenhalle",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2541,
      "name": "Vlaamsche Drukkerij",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2542,
      "name": "Vlaamsche Drukkery",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2543,
      "name": "Vogel",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2544,
      "name": "Volksdrukkery",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2545,
      "name": "von Mayer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2546,
      "name": "Vrin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2547,
      "name": "Vromant",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2548,
      "name": "Vulliez",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2549,
      "name": "vv. pp.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2550,
      "name": "Wagner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2551,
      "name": "Wahbourne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2552,
      "name": "Wales Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2553,
      "name": "Walhalla",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2554,
      "name": "Walker",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2555,
      "name": "Walsh",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2556,
      "name": "Walter de Gruyter & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2557,
      "name": "Walter de Gruyter GmbH & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2558,
      "name": "Walter Scott",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2559,
      "name": "Walter Scott Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2560,
      "name": "Waltor Scott",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2561,
      "name": "Walzer",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2562,
      "name": "Ward",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2563,
      "name": "Ward & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2564,
      "name": "Ward & Lock",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2565,
      "name": "Warne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2566,
      "name": "Warne & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2567,
      "name": "Warny",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2568,
      "name": "Warren",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2569,
      "name": "Warrent",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2570,
      "name": "Washbourne",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2571,
      "name": "Wats & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2572,
      "name": "Watt",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2573,
      "name": "Watts & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2574,
      "name": "Way Books",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2575,
      "name": "WCC Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2576,
      "name": "Weger",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2577,
      "name": "Weidmann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2578,
      "name": "Wells Gardner",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2579,
      "name": "Welter",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2580,
      "name": "Wesmael",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2581,
      "name": "Wesmael-Charlier",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2582,
      "name": "Westermann",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2583,
      "name": "Westminster John Knox Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2584,
      "name": "White Orchid Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2585,
      "name": "Whittaker & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2586,
      "name": "Wilfrid Laurier University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2587,
      "name": "William & Norgate",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2588,
      "name": "William Blackwood & Sons",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2589,
      "name": "William Collins Sons & Co. Ltd.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2590,
      "name": "Williams & Norgate",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2591,
      "name": "Williams & Norgate/ Constable & Co./Allen & Unwin",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2592,
      "name": "Wm. B. Eerdmans",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2593,
      "name": "Wm. B. Eerdmans/WCC Publications",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2594,
      "name": "Wolff",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2595,
      "name": "Wolters",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2596,
      "name": "Wolter's",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2597,
      "name": "Woodstock",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2598,
      "name": "Woodstock College Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2599,
      "name": "Woodward & Tiernan",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2600,
      "name": "World Council of Churches",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2601,
      "name": "World of Islam Festival Publ. Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2602,
      "name": "Writers Workshop Publ.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2603,
      "name": "Wyman & Co.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2604,
      "name": "Wyon",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2605,
      "name": "Xaveriana",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2606,
      "name": "Xaveriana Uitg.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2607,
      "name": "Xaveriana-uitg",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2608,
      "name": "Xaveriana-uitgaven",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2609,
      "name": "Yale University Press",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2610,
      "name": "Young",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2611,
      "name": "Youth's Assoc.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2612,
      "name": "Zaunrith",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2613,
      "name": "Zech",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2614,
      "name": "Zegers",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2615,
      "name": "Zehl",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2616,
      "name": "ZOE Brotherhood of Theols.",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2617,
      "name": "Zondervan Publishing House",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    },
    {
      "publisherId": 2618,
      "name": "Zurich",
      "publisherImage": "NA",
      "publisherAddress": "NA",
      "publisherDesc": "NA"
    }
  ]

export const books_info=[{
    "bookId":"1",
    "bookTitle":"Title 1",
    "bookAuthor":"Author 1",
    "bookStatus":"Active",
    "bookClassNo":"Class 1",
    "bookPublisher":"Publisher 1",
    "bookPagesNo":"20"
},{
    "bookId":"2",
    "bookTitle":"Title 2",
    "bookAuthor":"Author 2",
    "bookStatus":"Active",
    "bookClassNo":"Class 2",
    "bookPublisher":"Publisher 2",
    "bookPagesNo":"20"
}]

export const books_category=[{
    "categoryID": 101,
    "subCategoryID": "A",
    "name": "INTRODUCTION",
    "subCategoryName": "Introduction to Theology",
    "categoryDisplay": "Yes",
    "categoryDesc": "Immerse yourself in the pages of a book and embark on a journey of discovery, imagination, and personal growth. Books offer a vast universe of ideas, stories, and perspectives, waiting to be explored.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 101,
    "subCategoryID": "B",
    "name": "INTRODUCTION",
    "subCategoryName": "Bibliographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "C",
    "name": "INTRODUCTION",
    "subCategoryName": "Serial Bibliographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "D",
    "name": "INTRODUCTION",
    "subCategoryName": "Methodology of writing papers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "E",
    "name": "INTRODUCTION",
    "subCategoryName": "Theology: Serials & Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "F",
    "name": "INTRODUCTION",
    "subCategoryName": "Thomistic School",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Ft B",
    "name": "INTRODUCTION",
    "subCategoryName": "Thomas Aquinas: Writings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Ft M",
    "name": "INTRODUCTION",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Ft P",
    "name": "INTRODUCTION",
    "subCategoryName": "Thomas Aquinas: Philosophical Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Ft T",
    "name": "INTRODUCTION",
    "subCategoryName": "Thomas Aquinas: Theological Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Ft V",
    "name": "INTRODUCTION",
    "subCategoryName": "Thomas Aquinas: Biography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "G",
    "name": "INTRODUCTION",
    "subCategoryName": "Carmelite Theologians (Up to 19th Cent)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "H",
    "name": "INTRODUCTION",
    "subCategoryName": "Franciscan Theologians (Up to 19th Cent)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "J",
    "name": "INTRODUCTION",
    "subCategoryName": "Jesuit Theologians (Up to 19th Cent)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "L",
    "name": "INTRODUCTION",
    "subCategoryName": "Luther",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "M",
    "name": "INTRODUCTION",
    "subCategoryName": "Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "N",
    "name": "INTRODUCTION",
    "subCategoryName": "Canon Law",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "P",
    "name": "INTRODUCTION",
    "subCategoryName": "Bibliographica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "R",
    "name": "INTRODUCTION",
    "subCategoryName": "Liturgy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Sa",
    "name": "INTRODUCTION",
    "subCategoryName": "Scripture Text",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Sc",
    "name": "INTRODUCTION",
    "subCategoryName": "Scripture Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Sd",
    "name": "INTRODUCTION",
    "subCategoryName": "Concordances",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Sf",
    "name": "INTRODUCTION",
    "subCategoryName": "History /Archaeology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "Sg",
    "name": "INTRODUCTION",
    "subCategoryName": "Geography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 101,
    "subCategoryID": "V",
    "name": "INTRODUCTION",
    "subCategoryName": "Church History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Aa",
    "name": "SCRIPTURE",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Sacred texts, passed down through generations, offer spiritual guidance, moral teachings, and a connection to the divine. Scripture provides solace, inspiration, and a framework for understanding life's purpose.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ab",
    "name": "SCRIPTURE",
    "subCategoryName": "Textbooks",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ac",
    "name": "SCRIPTURE",
    "subCategoryName": "Canon",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ae",
    "name": "SCRIPTURE",
    "subCategoryName": "Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ah",
    "name": "SCRIPTURE",
    "subCategoryName": "Hermeneutics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ai",
    "name": "SCRIPTURE",
    "subCategoryName": "Inspiration/Authority",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "As",
    "name": "SCRIPTURE",
    "subCategoryName": "Serial Publications",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Az",
    "name": "SCRIPTURE",
    "subCategoryName": "Concordances",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ba",
    "name": "SCRIPTURE",
    "subCategoryName": "Text History/Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Bg",
    "name": "SCRIPTURE",
    "subCategoryName": "Greek",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Bh",
    "name": "SCRIPTURE",
    "subCategoryName": "Hebrew",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Bl",
    "name": "SCRIPTURE",
    "subCategoryName": "Latin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Bs",
    "name": "SCRIPTURE",
    "subCategoryName": "Syriac",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ca",
    "name": "SCRIPTURE",
    "subCategoryName": "Translation: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ce",
    "name": "SCRIPTURE",
    "subCategoryName": "English",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Cf",
    "name": "SCRIPTURE",
    "subCategoryName": "French",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Cg",
    "name": "SCRIPTURE",
    "subCategoryName": "Germanic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ci",
    "name": "SCRIPTURE",
    "subCategoryName": "Indian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Cm",
    "name": "SCRIPTURE",
    "subCategoryName": "Italian – Spanish",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Co",
    "name": "SCRIPTURE",
    "subCategoryName": "Others",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "D",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries: in serial…….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Da",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries: OT/NT (One author/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "E",
    "name": "SCRIPTURE",
    "subCategoryName": "Introduction to the Old Testament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "F",
    "name": "SCRIPTURE",
    "subCategoryName": "Historical Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fa",
    "name": "SCRIPTURE",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fb",
    "name": "SCRIPTURE",
    "subCategoryName": "Pentateuch",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fc",
    "name": "SCRIPTURE",
    "subCategoryName": "Genesis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fd",
    "name": "SCRIPTURE",
    "subCategoryName": "Exodus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fe",
    "name": "SCRIPTURE",
    "subCategoryName": "Leviticus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ff",
    "name": "SCRIPTURE",
    "subCategoryName": "Numbers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fg",
    "name": "SCRIPTURE",
    "subCategoryName": "Deuteronomy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fh",
    "name": "SCRIPTURE",
    "subCategoryName": "Joshua",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fi",
    "name": "SCRIPTURE",
    "subCategoryName": "Judges",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fj",
    "name": "SCRIPTURE",
    "subCategoryName": "Ruth",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fk",
    "name": "SCRIPTURE",
    "subCategoryName": "Samuel/Kings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fn",
    "name": "SCRIPTURE",
    "subCategoryName": "Chronicles",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fp",
    "name": "SCRIPTURE",
    "subCategoryName": "Ezra – Nehemiah",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fp E",
    "name": "SCRIPTURE",
    "subCategoryName": "Ezra",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fp N",
    "name": "SCRIPTURE",
    "subCategoryName": "Nehemiah",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fq",
    "name": "SCRIPTURE",
    "subCategoryName": "Tobit-Judith-Esther",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "FqA",
    "name": "SCRIPTURE",
    "subCategoryName": "Women in the OT",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fqb",
    "name": "SCRIPTURE",
    "subCategoryName": "Women in the NT",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fq T",
    "name": "SCRIPTURE",
    "subCategoryName": "Tobit",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fq J",
    "name": "SCRIPTURE",
    "subCategoryName": "Judth",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Fq E",
    "name": "SCRIPTURE",
    "subCategoryName": "Esther",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ft",
    "name": "SCRIPTURE",
    "subCategoryName": "Maccabees",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ga",
    "name": "SCRIPTURE",
    "subCategoryName": "Didactic Books: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gc",
    "name": "SCRIPTURE",
    "subCategoryName": "Canticle of Canticles(Song of Song)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gj",
    "name": "SCRIPTURE",
    "subCategoryName": "Job",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gp",
    "name": "SCRIPTURE",
    "subCategoryName": "Psalms",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs",
    "name": "SCRIPTURE",
    "subCategoryName": "Sapiential",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs C",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries (collective)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs P",
    "name": "SCRIPTURE",
    "subCategoryName": "Proverbs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs Q",
    "name": "SCRIPTURE",
    "subCategoryName": "Qoheleth/Ecclesiastes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs S",
    "name": "SCRIPTURE",
    "subCategoryName": "Sirach",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Gs W",
    "name": "SCRIPTURE",
    "subCategoryName": "Wisdom",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ha",
    "name": "SCRIPTURE",
    "subCategoryName": "Prophets",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hc",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries – Collective + Minor Prophets in one book",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hi",
    "name": "SCRIPTURE",
    "subCategoryName": "Isaiah",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hj",
    "name": "SCRIPTURE",
    "subCategoryName": "Jeremiah",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hk",
    "name": "SCRIPTURE",
    "subCategoryName": "Ezekiel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "HL",
    "name": "SCRIPTURE",
    "subCategoryName": "Daniel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hm",
    "name": "SCRIPTURE",
    "subCategoryName": "Minor Prophets – Individual Prophets in separate books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Hr",
    "name": "SCRIPTURE",
    "subCategoryName": "Apocalypticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ht",
    "name": "SCRIPTURE",
    "subCategoryName": "OT Themes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "I",
    "name": "SCRIPTURE",
    "subCategoryName": "Introduction to the New Testament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Is",
    "name": "SCRIPTURE",
    "subCategoryName": "Introduction to NT Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "J",
    "name": "SCRIPTURE",
    "subCategoryName": "Gospels – General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ja",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries – Collective/Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jb",
    "name": "SCRIPTURE",
    "subCategoryName": "Commentaries – Individual: Mk/Mt/Lk/Jn",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jf",
    "name": "SCRIPTURE",
    "subCategoryName": "Synoptic Question",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jf Mk",
    "name": "SCRIPTURE",
    "subCategoryName": "Mark",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jf Mt",
    "name": "SCRIPTURE",
    "subCategoryName": "Mathew",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jf Lk",
    "name": "SCRIPTURE",
    "subCategoryName": "Luke",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jh",
    "name": "SCRIPTURE",
    "subCategoryName": "Gospel Criticism John",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ji",
    "name": "SCRIPTURE",
    "subCategoryName": "Particular Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ji OF",
    "name": "SCRIPTURE",
    "subCategoryName": "Our Father",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ji P",
    "name": "SCRIPTURE",
    "subCategoryName": "Parables",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Jk",
    "name": "SCRIPTURE",
    "subCategoryName": "Serial",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ka",
    "name": "SCRIPTURE",
    "subCategoryName": "Christ: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Kc",
    "name": "SCRIPTURE",
    "subCategoryName": "Chronology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Kd",
    "name": "SCRIPTURE",
    "subCategoryName": "Lives of Christ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ki",
    "name": "SCRIPTURE",
    "subCategoryName": "Infancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Km",
    "name": "SCRIPTURE",
    "subCategoryName": "Ministry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Kp",
    "name": "SCRIPTURE",
    "subCategoryName": "Passion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Kp S",
    "name": "SCRIPTURE",
    "subCategoryName": "Shroud",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Kr",
    "name": "SCRIPTURE",
    "subCategoryName": "Resurrection",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ks",
    "name": "SCRIPTURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "L",
    "name": "SCRIPTURE",
    "subCategoryName": "Acts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "M",
    "name": "SCRIPTURE",
    "subCategoryName": "Pauline Letters: Introduction",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc",
    "name": "SCRIPTURE",
    "subCategoryName": "General/Collective – Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Rom",
    "name": "SCRIPTURE",
    "subCategoryName": "Romans",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Cor",
    "name": "SCRIPTURE",
    "subCategoryName": "Corinthians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc 1 Cor. 1",
    "name": "SCRIPTURE",
    "subCategoryName": "Corinthians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc 2 Cor. 2",
    "name": "SCRIPTURE",
    "subCategoryName": "Corinthians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Gal",
    "name": "SCRIPTURE",
    "subCategoryName": "Galatians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Capt.",
    "name": "SCRIPTURE",
    "subCategoryName": "Captivity Letters (Ephesians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Thess 1",
    "name": "SCRIPTURE",
    "subCategoryName": "Thessalonians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Thess 2",
    "name": "SCRIPTURE",
    "subCategoryName": "Thessalonians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mc Past.",
    "name": "SCRIPTURE",
    "subCategoryName": "Pastoral Letters (Timothy)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Mh",
    "name": "SCRIPTURE",
    "subCategoryName": "Hebrews",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Na",
    "name": "SCRIPTURE",
    "subCategoryName": "Catholic Epistles",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Nc",
    "name": "SCRIPTURE",
    "subCategoryName": "James",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Nd",
    "name": "SCRIPTURE",
    "subCategoryName": "1-2 Peter – Jude",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Nj",
    "name": "SCRIPTURE",
    "subCategoryName": "1-2 John",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Nr",
    "name": "SCRIPTURE",
    "subCategoryName": "Revelation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pa",
    "name": "SCRIPTURE",
    "subCategoryName": "Biblical Theology: Notion and Method",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pb",
    "name": "SCRIPTURE",
    "subCategoryName": "OT – NT General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pc",
    "name": "SCRIPTURE",
    "subCategoryName": "Biblical Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pd",
    "name": "SCRIPTURE",
    "subCategoryName": "OT Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pf",
    "name": "SCRIPTURE",
    "subCategoryName": "NT Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "PfC",
    "name": "SCRIPTURE",
    "subCategoryName": "Christology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "PfE",
    "name": "SCRIPTURE",
    "subCategoryName": "Ecclesiology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "PfL",
    "name": "SCRIPTURE",
    "subCategoryName": "Liberation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "PfS",
    "name": "SCRIPTURE",
    "subCategoryName": "Spiritual Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pg",
    "name": "SCRIPTURE",
    "subCategoryName": "Gospels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pj",
    "name": "SCRIPTURE",
    "subCategoryName": "John",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pk",
    "name": "SCRIPTURE",
    "subCategoryName": "Acts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp",
    "name": "SCRIPTURE",
    "subCategoryName": "Pauline Theology (General)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp C",
    "name": "SCRIPTURE",
    "subCategoryName": "Pauline Christology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp D",
    "name": "SCRIPTURE",
    "subCategoryName": "Salvation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp E",
    "name": "SCRIPTURE",
    "subCategoryName": "Ecclesiology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp F",
    "name": "SCRIPTURE",
    "subCategoryName": "Faith",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp G",
    "name": "SCRIPTURE",
    "subCategoryName": "Pneumatology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp H",
    "name": "SCRIPTURE",
    "subCategoryName": "Baptism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp J",
    "name": "SCRIPTURE",
    "subCategoryName": "Eucharist",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp M",
    "name": "SCRIPTURE",
    "subCategoryName": "Mysticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp N",
    "name": "SCRIPTURE",
    "subCategoryName": "Christian Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp Q",
    "name": "SCRIPTURE",
    "subCategoryName": "Eschatology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Pp V",
    "name": "SCRIPTURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ra",
    "name": "SCRIPTURE",
    "subCategoryName": "Biblical Persons: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Rc",
    "name": "SCRIPTURE",
    "subCategoryName": "OT: Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Re",
    "name": "SCRIPTURE",
    "subCategoryName": "Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Rg",
    "name": "SCRIPTURE",
    "subCategoryName": "Gospels: Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Rh",
    "name": "SCRIPTURE",
    "subCategoryName": "Gospels: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Rm",
    "name": "SCRIPTURE",
    "subCategoryName": "Blessed Virgin Mary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Rp",
    "name": "SCRIPTURE",
    "subCategoryName": "Paul",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Sa",
    "name": "SCRIPTURE",
    "subCategoryName": "Judaism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Sd",
    "name": "SCRIPTURE",
    "subCategoryName": "Dead Sea Scrolls/Qumran",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ta",
    "name": "SCRIPTURE",
    "subCategoryName": "History of Israel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Ti",
    "name": "SCRIPTURE",
    "subCategoryName": "Institutions in Israel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "V",
    "name": "SCRIPTURE",
    "subCategoryName": "Archeology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Va",
    "name": "SCRIPTURE",
    "subCategoryName": "OT Environment. Ancient Texts & Monuments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "W",
    "name": "SCRIPTURE",
    "subCategoryName": "Geography: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Wa",
    "name": "SCRIPTURE",
    "subCategoryName": "Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Wc",
    "name": "SCRIPTURE",
    "subCategoryName": "Historical Sites",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "X",
    "name": "SCRIPTURE",
    "subCategoryName": "Linguistics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xa",
    "name": "SCRIPTURE",
    "subCategoryName": "Assyrian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xg",
    "name": "SCRIPTURE",
    "subCategoryName": "Greek Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xg D",
    "name": "SCRIPTURE",
    "subCategoryName": "Greek Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xh",
    "name": "SCRIPTURE",
    "subCategoryName": "Hebrew Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xh D",
    "name": "SCRIPTURE",
    "subCategoryName": "Hebrew Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xk",
    "name": "SCRIPTURE",
    "subCategoryName": "Coptic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xs",
    "name": "SCRIPTURE",
    "subCategoryName": "Syriac Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xs D",
    "name": "SCRIPTURE",
    "subCategoryName": "Syriac Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xs Da",
    "name": "SCRIPTURE",
    "subCategoryName": "Aramaic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xv",
    "name": "SCRIPTURE",
    "subCategoryName": "Vulgate",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Xv D",
    "name": "SCRIPTURE",
    "subCategoryName": "Vulgate Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Y",
    "name": "SCRIPTURE",
    "subCategoryName": "Apocrypha",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Za",
    "name": "SCRIPTURE",
    "subCategoryName": "History of Exegesis: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Zb",
    "name": "SCRIPTURE",
    "subCategoryName": "History of Exegesis: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 102,
    "subCategoryID": "Zv",
    "name": "SCRIPTURE",
    "subCategoryName": "Varia Scripturistica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A",
    "name": "TRADITION",
    "subCategoryName": "Patristics Texts",
    "categoryDisplay": "Yes",
    "categoryDesc": "Books preserve the customs, beliefs, and stories of our ancestors, connecting us to our heritage. They offer insights into the evolution of cultures, societies, and traditions, helping us understand our place in the world.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 103,
    "subCategoryID": "A1",
    "name": "TRADITION",
    "subCategoryName": "Corpus Christianorum:  Greek/Latin/Medieval",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A2",
    "name": "TRADITION",
    "subCategoryName": "CSCO",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A3",
    "name": "TRADITION",
    "subCategoryName": "Die Criechischen Christlichen Schriftssteller",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A4",
    "name": "TRADITION",
    "subCategoryName": "C.S.E.L. Corpus Vindebonense",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A5",
    "name": "TRADITION",
    "subCategoryName": "Nicene & Post – Nicene Fathers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A6",
    "name": "TRADITION",
    "subCategoryName": "Ante – Nicene Fathers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A7",
    "name": "TRADITION",
    "subCategoryName": "Library of the Fathers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A8",
    "name": "TRADITION",
    "subCategoryName": "Ancient Xtian Writers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A9",
    "name": "TRADITION",
    "subCategoryName": "Fathers of the Church",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A10",
    "name": "TRADITION",
    "subCategoryName": "Sources Christiennes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A11",
    "name": "TRADITION",
    "subCategoryName": "Patrology (Minge)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A11G",
    "name": "TRADITION",
    "subCategoryName": "Patrologia Graeca",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A11L",
    "name": "TRADITION",
    "subCategoryName": "Patrologia Latina",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A11O",
    "name": "TRADITION",
    "subCategoryName": "Patrologia Orientalis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A11S",
    "name": "TRADITION",
    "subCategoryName": "Patrologia Syriaca",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A12",
    "name": "TRADITION",
    "subCategoryName": "Bibliothek de Kirchenvater",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A14",
    "name": "TRADITION",
    "subCategoryName": "Texts & Documents (Hemmer & Lejay)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A15",
    "name": "TRADITION",
    "subCategoryName": "Florilegium Patristicum",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A18",
    "name": "TRADITION",
    "subCategoryName": "Early Church Classics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "A20",
    "name": "TRADITION",
    "subCategoryName": "SS. Pp. Opuscula Selecta",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "C",
    "name": "TRADITION",
    "subCategoryName": "Texts (non-serial)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Ca",
    "name": "TRADITION",
    "subCategoryName": "Didache",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Cb",
    "name": "TRADITION",
    "subCategoryName": "Texts 4 – Fol.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Cc",
    "name": "TRADITION",
    "subCategoryName": "Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Ci",
    "name": "TRADITION",
    "subCategoryName": "Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Cs",
    "name": "TRADITION",
    "subCategoryName": "Eastern",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "D",
    "name": "TRADITION",
    "subCategoryName": "Patrology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Db",
    "name": "TRADITION",
    "subCategoryName": "Bibliographia Patristica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Dg",
    "name": "TRADITION",
    "subCategoryName": "Guides to the Fathers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "E",
    "name": "TRADITION",
    "subCategoryName": "Particular Topics (Patrology)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Es",
    "name": "TRADITION",
    "subCategoryName": "Patristica Sobornensia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "F",
    "name": "TRADITION",
    "subCategoryName": "Patristic Studies (of Fathers)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Fs",
    "name": "TRADITION",
    "subCategoryName": "Church Fathers: Various Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "G",
    "name": "TRADITION",
    "subCategoryName": "History of Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Gd",
    "name": "TRADITION",
    "subCategoryName": "Handbuch de Dogmengeschichts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Ge",
    "name": "TRADITION",
    "subCategoryName": "Herder History of Dogma",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Gf",
    "name": "TRADITION",
    "subCategoryName": "L’Histoire des Dogmes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Go",
    "name": "TRADITION",
    "subCategoryName": "History of Orthodox Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Ha",
    "name": "TRADITION",
    "subCategoryName": "Early Centuries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Hc",
    "name": "TRADITION",
    "subCategoryName": "Early Heresies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Hg",
    "name": "TRADITION",
    "subCategoryName": "Gnosis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Hm",
    "name": "TRADITION",
    "subCategoryName": "Manicheism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Hn",
    "name": "TRADITION",
    "subCategoryName": "Nestorianism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "I",
    "name": "TRADITION",
    "subCategoryName": "Middle Ages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "J",
    "name": "TRADITION",
    "subCategoryName": "Tridentine Period",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Jj",
    "name": "TRADITION",
    "subCategoryName": "Jansenism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "K",
    "name": "TRADITION",
    "subCategoryName": "Modern Period",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Km",
    "name": "TRADITION",
    "subCategoryName": "Modernism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Kn",
    "name": "TRADITION",
    "subCategoryName": "Contemporary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "La",
    "name": "TRADITION",
    "subCategoryName": "Councils: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lc",
    "name": "TRADITION",
    "subCategoryName": "Particular Councils",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lv",
    "name": "TRADITION",
    "subCategoryName": "Vatican II",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lv D",
    "name": "TRADITION",
    "subCategoryName": "Vatican II: Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lv H",
    "name": "TRADITION",
    "subCategoryName": "Vatican II: History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lv S",
    "name": "TRADITION",
    "subCategoryName": "Vatican II: Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Lw",
    "name": "TRADITION",
    "subCategoryName": "Synods",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Ma",
    "name": "TRADITION",
    "subCategoryName": "Pontifical Documents: Popes Writings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Me",
    "name": "TRADITION",
    "subCategoryName": "“Enseignements Pontificaux” (Collections)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Mf",
    "name": "TRADITION",
    "subCategoryName": "“Papal Teachings” (Collections)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 103,
    "subCategoryID": "Mv",
    "name": "TRADITION",
    "subCategoryName": "Varia Magisterium – From Holy See (exc. Pops)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Aa",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Fundamental Theology: Manuals",
    "categoryDisplay": "Yes",
    "categoryDesc": "Fundamental theology delves into the foundations of faith, exploring questions about God's existence, nature, and relationship to humanity. It seeks to understand the ultimate meaning and purpose of life, providing a framework for religious belief and practice.",
    "categoryImage": require("./assets/images/booksCat/theology.png")
  },
  {
    "categoryID": 104,
    "subCategoryID": "B",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Apologetics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ca",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Conversions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Cb",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Biographies: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Cc",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Biographies: Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Da",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Miracles",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Db",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Lourdes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ea",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Church: Manuals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Eb",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "General Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ed",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Theology of the Church/Ecclesiology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ee",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Basic Christian Community/Small Christian Communities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ef",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Authority in the Church/Leadership",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ei",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Papacy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ej",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Bishops",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "El",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Laity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Em",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Present Trends",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ep",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "The Church Questioned",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "F",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Religious Freedom",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ha",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Revelation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Hd",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Development of Dogma",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "J",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Church & Science",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "L",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Controversies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ma",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Theology of Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mb",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Bibliography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mc",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Serial Publications",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Md",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Inculturation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Me",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Missionary activities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mh",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "History of Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mj",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "S.J. Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mk",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Religious Orders",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Mp",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Non Catholic Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "N",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Qn",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "North American Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Qs",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "South American Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Ra",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rb",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Near – East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rc",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "China",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rf",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "S. E. Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rj",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Japan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rk",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Korea",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rs",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Australia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Rv",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Sa",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Sb",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "U",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Unum Sanctam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Za",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Varia Apologetica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Zc",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Varia the Church",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 104,
    "subCategoryID": "Zm",
    "name": "FUNDAMENTAL THEOLOGY",
    "subCategoryName": "Varia Missionalia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "A",
    "name": "ECUMENISM",
    "subCategoryName": "Christian Denominations (Non-Catholic)",
    "categoryDisplay": "Yes",
    "categoryDesc": "Ecumenism strives to foster unity and cooperation among different Christian denominations, seeking to overcome divisions and promote a shared understanding of the Gospel. It emphasizes the commonalities of faith and encourages dialogue and collaboration among believers.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 105,
    "subCategoryID": "C",
    "name": "ECUMENISM",
    "subCategoryName": "Oriental Churches",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "F",
    "name": "ECUMENISM",
    "subCategoryName": "Protestantism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "G",
    "name": "ECUMENISM",
    "subCategoryName": "Anglicanism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Ga",
    "name": "ECUMENISM",
    "subCategoryName": "Constitution of the Church of England",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Gb",
    "name": "ECUMENISM",
    "subCategoryName": "Book of Common Prayer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Gc",
    "name": "ECUMENISM",
    "subCategoryName": "Doctrines",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Gm",
    "name": "ECUMENISM",
    "subCategoryName": "Modernism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Go",
    "name": "ECUMENISM",
    "subCategoryName": "Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Go P",
    "name": "ECUMENISM",
    "subCategoryName": "Pusey",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "H",
    "name": "ECUMENISM",
    "subCategoryName": "History of the Sects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Ha",
    "name": "ECUMENISM",
    "subCategoryName": "Salvation Army",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hb",
    "name": "ECUMENISM",
    "subCategoryName": "Baptists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hc",
    "name": "ECUMENISM",
    "subCategoryName": "Congregationalists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hj",
    "name": "ECUMENISM",
    "subCategoryName": "Jehovah’s Witnesses",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hk",
    "name": "ECUMENISM",
    "subCategoryName": "Pentecostals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hm",
    "name": "ECUMENISM",
    "subCategoryName": "Methodists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hn",
    "name": "ECUMENISM",
    "subCategoryName": "Non-Conformists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hp",
    "name": "ECUMENISM",
    "subCategoryName": "Puritanism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hq",
    "name": "ECUMENISM",
    "subCategoryName": "Quakers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hq A",
    "name": "ECUMENISM",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hq B",
    "name": "ECUMENISM",
    "subCategoryName": "Meeting",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hq C",
    "name": "ECUMENISM",
    "subCategoryName": "Pamphlets",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hr",
    "name": "ECUMENISM",
    "subCategoryName": "Moral Rearmament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hs",
    "name": "ECUMENISM",
    "subCategoryName": "Christian Scientists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hu",
    "name": "ECUMENISM",
    "subCategoryName": "Unitarians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hv",
    "name": "ECUMENISM",
    "subCategoryName": "Miscellaneous Sects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Hz",
    "name": "ECUMENISM",
    "subCategoryName": "Seventh Day Adventists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "L",
    "name": "ECUMENISM",
    "subCategoryName": "Ecumenical Movement",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Lb",
    "name": "ECUMENISM",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "M",
    "name": "ECUMENISM",
    "subCategoryName": "Faith & Order",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "N",
    "name": "ECUMENISM",
    "subCategoryName": "WCC (World Council of Churches)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "P",
    "name": "ECUMENISM",
    "subCategoryName": "CCA (Christian Conference of Asia)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Q",
    "name": "ECUMENISM",
    "subCategoryName": "Non-catholic Denominations in India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "S",
    "name": "ECUMENISM",
    "subCategoryName": "Catholic Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "T",
    "name": "ECUMENISM",
    "subCategoryName": "Non-Catholic Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "U",
    "name": "ECUMENISM",
    "subCategoryName": "Common Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Uf",
    "name": "ECUMENISM",
    "subCategoryName": "Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "V",
    "name": "ECUMENISM",
    "subCategoryName": "Judaism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "X",
    "name": "ECUMENISM",
    "subCategoryName": "Yearbooks",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 105,
    "subCategoryID": "Z",
    "name": "ECUMENISM",
    "subCategoryName": "Varia Ecumenica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "A",
    "name": "THEOLOGY",
    "subCategoryName": "Theologians – Individual",
    "categoryDisplay": "Yes",
    "categoryDesc": "Theology explores the nature of God, the relationship between God and humanity, and the meaning of religious belief and practice. It seeks to understand the ultimate questions of existence and provide a framework for spiritual understanding.",
    "categoryImage": require("./assets/images/booksCat/theology.png")
  },
  {
    "categoryID": 106,
    "subCategoryID": "A1",
    "name": "THEOLOGY",
    "subCategoryName": "Theologians – Collective – Medieval theologians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Aa",
    "name": "THEOLOGY",
    "subCategoryName": "General Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ad",
    "name": "THEOLOGY",
    "subCategoryName": "Intercultural Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ah",
    "name": "THEOLOGY",
    "subCategoryName": "Theology of Language",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ai",
    "name": "THEOLOGY",
    "subCategoryName": "Contextual & Asian Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ak",
    "name": "THEOLOGY",
    "subCategoryName": "African  & Black Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Al",
    "name": "THEOLOGY",
    "subCategoryName": "Liberation Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Am",
    "name": "THEOLOGY",
    "subCategoryName": "Feminist Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ao",
    "name": "THEOLOGY",
    "subCategoryName": "Oriental Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ap",
    "name": "THEOLOGY",
    "subCategoryName": "Process Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "As",
    "name": "THEOLOGY",
    "subCategoryName": "Serial Publications",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ba",
    "name": "THEOLOGY",
    "subCategoryName": "Manuals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Bb",
    "name": "THEOLOGY",
    "subCategoryName": "Non-Catholic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ca",
    "name": "THEOLOGY",
    "subCategoryName": "God",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Cg",
    "name": "THEOLOGY",
    "subCategoryName": "Belief in God",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ch",
    "name": "THEOLOGY",
    "subCategoryName": "Secularism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Cm",
    "name": "THEOLOGY",
    "subCategoryName": "Atheism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Da",
    "name": "THEOLOGY",
    "subCategoryName": "Trinity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Db",
    "name": "THEOLOGY",
    "subCategoryName": "The Word",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Dc",
    "name": "THEOLOGY",
    "subCategoryName": "Holy Spirit",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ea",
    "name": "THEOLOGY",
    "subCategoryName": "Creation: Tracts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ec",
    "name": "THEOLOGY",
    "subCategoryName": "Creation: Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ed",
    "name": "THEOLOGY",
    "subCategoryName": "Eco-Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ee",
    "name": "THEOLOGY",
    "subCategoryName": "Evolution",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Eh A",
    "name": "THEOLOGY",
    "subCategoryName": "Angels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Eh D",
    "name": "THEOLOGY",
    "subCategoryName": "Devils",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ek",
    "name": "THEOLOGY",
    "subCategoryName": "Evil",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Em",
    "name": "THEOLOGY",
    "subCategoryName": "Anthropology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "En",
    "name": "THEOLOGY",
    "subCategoryName": "Woman",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ep",
    "name": "THEOLOGY",
    "subCategoryName": "Theology of History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Eq",
    "name": "THEOLOGY",
    "subCategoryName": "Work",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Es",
    "name": "THEOLOGY",
    "subCategoryName": "Supernatural",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ez",
    "name": "THEOLOGY",
    "subCategoryName": "Universal Salvation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "G",
    "name": "THEOLOGY",
    "subCategoryName": "Original Sin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ha",
    "name": "THEOLOGY",
    "subCategoryName": "Grace",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hb",
    "name": "THEOLOGY",
    "subCategoryName": "Grace in Tradition",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "He",
    "name": "THEOLOGY",
    "subCategoryName": "Divine Indwelling",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hg",
    "name": "THEOLOGY",
    "subCategoryName": "Predestination",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hk",
    "name": "THEOLOGY",
    "subCategoryName": "Virtues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hm",
    "name": "THEOLOGY",
    "subCategoryName": "Faith",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hn",
    "name": "THEOLOGY",
    "subCategoryName": "Hope",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hp",
    "name": "THEOLOGY",
    "subCategoryName": "Love",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Hv",
    "name": "THEOLOGY",
    "subCategoryName": "Moral Virtues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ia",
    "name": "THEOLOGY",
    "subCategoryName": "Christology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ib",
    "name": "THEOLOGY",
    "subCategoryName": "Person/Mystery of Christ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ic",
    "name": "THEOLOGY",
    "subCategoryName": "Incarnation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ir",
    "name": "THEOLOGY",
    "subCategoryName": "Redemption",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Is",
    "name": "THEOLOGY",
    "subCategoryName": "Sacred Heart",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "L",
    "name": "THEOLOGY",
    "subCategoryName": "Mariology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "La",
    "name": "THEOLOGY",
    "subCategoryName": "Mariology in Tradition",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Lb",
    "name": "THEOLOGY",
    "subCategoryName": "Immaculate Conception",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Lc",
    "name": "THEOLOGY",
    "subCategoryName": "Assumption",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ld",
    "name": "THEOLOGY",
    "subCategoryName": "Mother of God",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Le",
    "name": "THEOLOGY",
    "subCategoryName": "Mediatrix",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Lf",
    "name": "THEOLOGY",
    "subCategoryName": "Mary & the Church",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Lm",
    "name": "THEOLOGY",
    "subCategoryName": "Marian Congresses",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Lo",
    "name": "THEOLOGY",
    "subCategoryName": "Marian Sanctuaries/Cult",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "M",
    "name": "THEOLOGY",
    "subCategoryName": "Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "N",
    "name": "THEOLOGY",
    "subCategoryName": "Sacraments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ns",
    "name": "THEOLOGY",
    "subCategoryName": "Symbols",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Pa",
    "name": "THEOLOGY",
    "subCategoryName": "Christian Initiation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Pb",
    "name": "THEOLOGY",
    "subCategoryName": "Baptism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Pc",
    "name": "THEOLOGY",
    "subCategoryName": "Confirmation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Q",
    "name": "THEOLOGY",
    "subCategoryName": "Eucharist",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Qa",
    "name": "THEOLOGY",
    "subCategoryName": "Eucharistic Congresses",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "R",
    "name": "THEOLOGY",
    "subCategoryName": "The Mass",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "S",
    "name": "THEOLOGY",
    "subCategoryName": "Reconciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Sd",
    "name": "THEOLOGY",
    "subCategoryName": "Sin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "T",
    "name": "THEOLOGY",
    "subCategoryName": "Sacrament of the Sick",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "V",
    "name": "THEOLOGY",
    "subCategoryName": "Priesthood",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Va",
    "name": "THEOLOGY",
    "subCategoryName": "Anglican Orders",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Vb",
    "name": "THEOLOGY",
    "subCategoryName": "Priestly People",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Vd",
    "name": "THEOLOGY",
    "subCategoryName": "Diaconate",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Ve",
    "name": "THEOLOGY",
    "subCategoryName": "Episcopate",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Vl",
    "name": "THEOLOGY",
    "subCategoryName": "Lay/New Ministries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Vw",
    "name": "THEOLOGY",
    "subCategoryName": "Women and Ordination",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "X",
    "name": "THEOLOGY",
    "subCategoryName": "Marriage",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Y",
    "name": "THEOLOGY",
    "subCategoryName": "The Hereafter/Eschatology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yd",
    "name": "THEOLOGY",
    "subCategoryName": "Death",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yh",
    "name": "THEOLOGY",
    "subCategoryName": "Heaven",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yi",
    "name": "THEOLOGY",
    "subCategoryName": "Hell",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yj",
    "name": "THEOLOGY",
    "subCategoryName": "Judgment",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yp",
    "name": "THEOLOGY",
    "subCategoryName": "Purgatory",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Yr",
    "name": "THEOLOGY",
    "subCategoryName": "Resurrection",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Z",
    "name": "THEOLOGY",
    "subCategoryName": "Varia Dogmatica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 106,
    "subCategoryID": "Zt",
    "name": "THEOLOGY",
    "subCategoryName": "Theol. Theres (SMC/VJ)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "A",
    "name": "MORALS",
    "subCategoryName": "Manuals",
    "categoryDisplay": "Yes",
    "categoryDesc": "Morals provide guidelines for right and wrong behavior, shaping our values and guiding our actions. They help us navigate complex situations, make ethical decisions, and contribute to a just and compassionate society.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 107,
    "subCategoryID": "B",
    "name": "MORALS",
    "subCategoryName": "General Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Bb",
    "name": "MORALS",
    "subCategoryName": "Biblical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Bc",
    "name": "MORALS",
    "subCategoryName": "Non – Catholic Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Bs",
    "name": "MORALS",
    "subCategoryName": "Serial",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "C",
    "name": "MORALS",
    "subCategoryName": "Casus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Cy",
    "name": "MORALS",
    "subCategoryName": "Cyber Crimes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "D",
    "name": "MORALS",
    "subCategoryName": "History of Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "E",
    "name": "MORALS",
    "subCategoryName": "Conscience",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "En",
    "name": "MORALS",
    "subCategoryName": "Environment",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "F",
    "name": "MORALS",
    "subCategoryName": "Virtues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "G",
    "name": "MORALS",
    "subCategoryName": "Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Gf",
    "name": "MORALS",
    "subCategoryName": "Secret Sects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ha",
    "name": "MORALS",
    "subCategoryName": "Truthfulness",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ia",
    "name": "MORALS",
    "subCategoryName": "Political Morality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ib",
    "name": "MORALS",
    "subCategoryName": "War/Violence/Peace",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ic",
    "name": "MORALS",
    "subCategoryName": "Corpus Hisp. De Pace (Series)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Id",
    "name": "MORALS",
    "subCategoryName": "Liberation/Development",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ii",
    "name": "MORALS",
    "subCategoryName": "Business Ethics/International Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ij",
    "name": "MORALS",
    "subCategoryName": "Inter-Disciplinary Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "J",
    "name": "MORALS",
    "subCategoryName": "Justice",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ja",
    "name": "MORALS",
    "subCategoryName": "Fol.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Jb",
    "name": "MORALS",
    "subCategoryName": "Bio-ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Jr",
    "name": "MORALS",
    "subCategoryName": "Human Rights",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "K",
    "name": "MORALS",
    "subCategoryName": "Morality of Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "R",
    "name": "MORALS",
    "subCategoryName": "Religious sociology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Rb",
    "name": "MORALS",
    "subCategoryName": "sociology of Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ta",
    "name": "MORALS",
    "subCategoryName": "Marriage in General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Td",
    "name": "MORALS",
    "subCategoryName": "Sexual Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Tf",
    "name": "MORALS",
    "subCategoryName": "Family Planning",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Th",
    "name": "MORALS",
    "subCategoryName": "Sexuality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ti",
    "name": "MORALS",
    "subCategoryName": "Marriage Guidance",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Tj",
    "name": "MORALS",
    "subCategoryName": "Marriage Preparation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Tk",
    "name": "MORALS",
    "subCategoryName": "Sexual Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ua",
    "name": "MORALS",
    "subCategoryName": "Pastoral Theology: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "UL",
    "name": "MORALS",
    "subCategoryName": "Social Concern",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Um",
    "name": "MORALS",
    "subCategoryName": "Migration",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Us",
    "name": "MORALS",
    "subCategoryName": "Sacraments: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Us P",
    "name": "MORALS",
    "subCategoryName": "Penance & Reconciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "V",
    "name": "MORALS",
    "subCategoryName": "Psychology: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vc",
    "name": "MORALS",
    "subCategoryName": "Clinical Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vd",
    "name": "MORALS",
    "subCategoryName": "Depth Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ve",
    "name": "MORALS",
    "subCategoryName": "Encounter Groups",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vf",
    "name": "MORALS",
    "subCategoryName": "Existential Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vg",
    "name": "MORALS",
    "subCategoryName": "Experimental Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vh",
    "name": "MORALS",
    "subCategoryName": "Humanistic Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vj",
    "name": "MORALS",
    "subCategoryName": "Personality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vj I",
    "name": "MORALS",
    "subCategoryName": "Children",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vk",
    "name": "MORALS",
    "subCategoryName": "Psychology of Morality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vl",
    "name": "MORALS",
    "subCategoryName": "Counseling",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vn",
    "name": "MORALS",
    "subCategoryName": "Para Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vo",
    "name": "MORALS",
    "subCategoryName": "Pastoral Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vq",
    "name": "MORALS",
    "subCategoryName": "Psychoanalysis/Psychiatry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vr",
    "name": "MORALS",
    "subCategoryName": "Religious Psychology/Spiritual Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vs",
    "name": "MORALS",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Vu",
    "name": "MORALS",
    "subCategoryName": "Social Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Y",
    "name": "MORALS",
    "subCategoryName": "Medical Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Ya",
    "name": "MORALS",
    "subCategoryName": "Ministry of Healing/Pastoral",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Yd",
    "name": "MORALS",
    "subCategoryName": "Ethics of Healing",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 107,
    "subCategoryID": "Z",
    "name": "MORALS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "A",
    "name": "CANNON LAW",
    "subCategoryName": "History of Cannon Law",
    "categoryDisplay": "Yes",
    "categoryDesc": "Canon law governs the organization, discipline, and practices of the Catholic Church. It provides a set of rules and regulations that guide the Church's teachings, sacraments, and governance, ensuring the preservation of its faith and traditions.",
    "categoryImage": require("./assets/images/booksCat/law.png")
  },
  {
    "categoryID": 108,
    "subCategoryID": "B",
    "name": "CANNON LAW",
    "subCategoryName": "Sources & Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Ba",
    "name": "CANNON LAW",
    "subCategoryName": "Codex 1917 CIC (Codex Iuris Communici)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Bc",
    "name": "CANNON LAW",
    "subCategoryName": "Codex 1983 CIC (Codex Iuris Communici)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "C",
    "name": "CANNON LAW",
    "subCategoryName": "Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Cs",
    "name": "CANNON LAW",
    "subCategoryName": "Cannon Law: South America",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "D",
    "name": "CANNON LAW",
    "subCategoryName": "General Rules",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "E",
    "name": "CANNON LAW",
    "subCategoryName": "Canonical Persons",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "F",
    "name": "CANNON LAW",
    "subCategoryName": "Religious",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Fa",
    "name": "CANNON LAW",
    "subCategoryName": "Laity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Hb",
    "name": "CANNON LAW",
    "subCategoryName": "Sacraments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Hm",
    "name": "CANNON LAW",
    "subCategoryName": "Marriage Law: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Hn",
    "name": "CANNON LAW",
    "subCategoryName": "Impediments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Hp",
    "name": "CANNON LAW",
    "subCategoryName": "Dissolution",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Ht",
    "name": "CANNON LAW",
    "subCategoryName": "Indulgences",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "I",
    "name": "CANNON LAW",
    "subCategoryName": "Sacred Places & Times",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "K",
    "name": "CANNON LAW",
    "subCategoryName": "Index",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "M",
    "name": "CANNON LAW",
    "subCategoryName": "Benefices/Properties",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "N",
    "name": "CANNON LAW",
    "subCategoryName": "Canonical Procedure",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Q",
    "name": "CANNON LAW",
    "subCategoryName": "Penal Code",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "R",
    "name": "CANNON LAW",
    "subCategoryName": "Rota",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "S",
    "name": "CANNON LAW",
    "subCategoryName": "Prov. Councils/Statutes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "T",
    "name": "CANNON LAW",
    "subCategoryName": "Church and State",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "V",
    "name": "CANNON LAW",
    "subCategoryName": "Oriental Codex – CCEO Codex Canonum",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 108,
    "subCategoryID": "Z",
    "name": "CANNON LAW",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "A",
    "name": "CATECHETICS",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Catechetics involves the instruction and formation of individuals in the Christian faith. It aims to deepen understanding of religious beliefs, practices, and traditions, equipping believers to live their lives according to Gospel values.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 109,
    "subCategoryID": "Ad",
    "name": "CATECHETICS",
    "subCategoryName": "Canon Drink water",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "B",
    "name": "CATECHETICS",
    "subCategoryName": "Catechisms",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "C",
    "name": "CATECHETICS",
    "subCategoryName": "Children",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Ca",
    "name": "CATECHETICS",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "D",
    "name": "CATECHETICS",
    "subCategoryName": "Youth",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "De",
    "name": "CATECHETICS",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "E",
    "name": "CATECHETICS",
    "subCategoryName": "Adults",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "H",
    "name": "CATECHETICS",
    "subCategoryName": "Moral Science",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Hb",
    "name": "CATECHETICS",
    "subCategoryName": "Practical Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "L",
    "name": "CATECHETICS",
    "subCategoryName": "Liturgy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "M",
    "name": "CATECHETICS",
    "subCategoryName": "Scripture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "N",
    "name": "CATECHETICS",
    "subCategoryName": "Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "P",
    "name": "CATECHETICS",
    "subCategoryName": "Catechetical Helps (Pictorial………)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pa",
    "name": "CATECHETICS",
    "subCategoryName": "Religious Experience",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pa C",
    "name": "CATECHETICS",
    "subCategoryName": "Religious Experience Children",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pa P",
    "name": "CATECHETICS",
    "subCategoryName": "Religious Experience Programme",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pb",
    "name": "CATECHETICS",
    "subCategoryName": "Bible",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pd",
    "name": "CATECHETICS",
    "subCategoryName": "Dove Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pm",
    "name": "CATECHETICS",
    "subCategoryName": "Men – God Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Po",
    "name": "CATECHETICS",
    "subCategoryName": "Praca – Joti Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pp",
    "name": "CATECHETICS",
    "subCategoryName": "Hi – Time Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pt",
    "name": "CATECHETICS",
    "subCategoryName": "Topics in Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Pw",
    "name": "CATECHETICS",
    "subCategoryName": "Wonderful Life Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Q",
    "name": "CATECHETICS",
    "subCategoryName": "Folios/Albums",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Ra",
    "name": "CATECHETICS",
    "subCategoryName": "Preaching: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Re",
    "name": "CATECHETICS",
    "subCategoryName": "English",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Rf",
    "name": "CATECHETICS",
    "subCategoryName": "French",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Rg",
    "name": "CATECHETICS",
    "subCategoryName": "Germanic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Ri",
    "name": "CATECHETICS",
    "subCategoryName": "Italian/Spanish",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Y",
    "name": "CATECHETICS",
    "subCategoryName": "Indian Catechetics (Vernacular)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 109,
    "subCategoryID": "Z",
    "name": "CATECHETICS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "A",
    "name": "LITURGY",
    "subCategoryName": "Collection of Official Decrees",
    "categoryDisplay": "Yes",
    "categoryDesc": "Liturgy is the official public worship of the Christian Church, consisting of prayers, hymns, and rituals. It provides a framework for connecting with God, expressing faith, and participating in the community of believers.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 110,
    "subCategoryID": "At",
    "name": "LITURGY",
    "subCategoryName": "Early Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "B",
    "name": "LITURGY",
    "subCategoryName": "Roman Liturgical Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Bb",
    "name": "LITURGY",
    "subCategoryName": "Non-Catholic Liturgies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "C",
    "name": "LITURGY",
    "subCategoryName": "General Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "D",
    "name": "LITURGY",
    "subCategoryName": "History of Liturgies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "E",
    "name": "LITURGY",
    "subCategoryName": "Oriental Liturgies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fa",
    "name": "LITURGY",
    "subCategoryName": "Oriental Texts: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fb",
    "name": "LITURGY",
    "subCategoryName": "Byzantine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fc",
    "name": "LITURGY",
    "subCategoryName": "Armenian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fd",
    "name": "LITURGY",
    "subCategoryName": "Coptic – Ehthiopian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fe",
    "name": "LITURGY",
    "subCategoryName": "East Syrian/Syro-Malabar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Ff",
    "name": "LITURGY",
    "subCategoryName": "West Syrian/Malankara",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Fm",
    "name": "LITURGY",
    "subCategoryName": "Maronite",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "G",
    "name": "LITURGY",
    "subCategoryName": "Rubrics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "H",
    "name": "LITURGY",
    "subCategoryName": "Mass (Studies)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Hb",
    "name": "LITURGY",
    "subCategoryName": "Commentaries on Lectionaries/Homilies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Hh",
    "name": "LITURGY",
    "subCategoryName": "Historical Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Hm",
    "name": "LITURGY",
    "subCategoryName": "Missals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Hn",
    "name": "LITURGY",
    "subCategoryName": "New Liturgies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Hr",
    "name": "LITURGY",
    "subCategoryName": "Rites & Rituals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "I",
    "name": "LITURGY",
    "subCategoryName": "Breviary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "K",
    "name": "LITURGY",
    "subCategoryName": "Liturgical Year",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Kp",
    "name": "LITURGY",
    "subCategoryName": "Holy Week",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "M",
    "name": "LITURGY",
    "subCategoryName": "Church Music",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Ms",
    "name": "LITURGY",
    "subCategoryName": "Hymnals – Song Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "N",
    "name": "LITURGY",
    "subCategoryName": "Buildings/Furniture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "P",
    "name": "LITURGY",
    "subCategoryName": "Para-liturgies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Sa",
    "name": "LITURGY",
    "subCategoryName": "Sacraments: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Sb",
    "name": "LITURGY",
    "subCategoryName": "Christian Initiation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Sm",
    "name": "LITURGY",
    "subCategoryName": "Matrimony",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "So",
    "name": "LITURGY",
    "subCategoryName": "Reconciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Sp",
    "name": "LITURGY",
    "subCategoryName": "Ordination",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "T",
    "name": "LITURGY",
    "subCategoryName": "Sacraments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "U",
    "name": "LITURGY",
    "subCategoryName": "Serial Publications",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "W",
    "name": "LITURGY",
    "subCategoryName": "Liturgical Weeks",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "X",
    "name": "LITURGY",
    "subCategoryName": "Liturgical Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 110,
    "subCategoryID": "Z",
    "name": "LITURGY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "A",
    "name": "Religions",
    "subCategoryName": "General/Comparative Studies",
    "categoryDisplay": "Yes",
    "categoryDesc": "Religions offer diverse systems of belief, values, and rituals, shaping the worldview and behavior of millions of people. They provide a sense of meaning, purpose, and connection to something larger than oneself.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 112,
    "subCategoryID": "B",
    "name": "Religions",
    "subCategoryName": "Religious Phenomena",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "D",
    "name": "Religions",
    "subCategoryName": "Descriptive/Historical Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "De",
    "name": "Religions",
    "subCategoryName": "Semitic Religions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Dh",
    "name": "Religions",
    "subCategoryName": "Roman-Hellenistic Religions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Dk",
    "name": "Religions",
    "subCategoryName": "Chinese and Far Eastern Religions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Dm",
    "name": "Religions",
    "subCategoryName": "African",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "P",
    "name": "Religions",
    "subCategoryName": "Philosophy of Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Sa",
    "name": "Religions",
    "subCategoryName": "Sociology of Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "T",
    "name": "Religions",
    "subCategoryName": "Theology of Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Tf",
    "name": "Religions",
    "subCategoryName": "Faith Meets Faith Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "Ti",
    "name": "Religions",
    "subCategoryName": "Inter-Religious Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "TL",
    "name": "Religions",
    "subCategoryName": "Interfaith Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 112,
    "subCategoryID": "X",
    "name": "Religions",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "A",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Introduction",
    "categoryDisplay": "Yes",
    "categoryDesc": "Church history chronicles the development, growth, and challenges faced by the Christian Church throughout its centuries-long existence. It explores key events, figures, and theological developments that have shaped the Church's beliefs and practices.",
    "categoryImage": require("./assets/images/booksCat/cat3.png")
  },
  {
    "categoryID": 113,
    "subCategoryID": "B",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "C",
    "name": "CHURCH HISTORY",
    "subCategoryName": "General Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "D",
    "name": "CHURCH HISTORY",
    "subCategoryName": "African Churches",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "E",
    "name": "CHURCH HISTORY",
    "subCategoryName": "First Centuries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "F",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Middle Ages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Fb",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "G",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Reformation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Gc",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Counter – Reformation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "J",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Modern Times",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ka",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Church in Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Kb",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Belgium",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ke",
    "name": "CHURCH HISTORY",
    "subCategoryName": "England",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Kf",
    "name": "CHURCH HISTORY",
    "subCategoryName": "France",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ki",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Ireland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Km",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Malta",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Kn",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Netherlands",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lb",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Church in Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lc",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Bohemia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ld",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Denmark",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lg",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Germany",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lh",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Hungary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Li",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Italy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lp",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Poland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Lr",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Portugal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ls",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Spain",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ly",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Yugoslavia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "M",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Oriental Churches",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Mb",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Mc",
    "name": "CHURCH HISTORY",
    "subCategoryName": "USSR",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Md",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Coptic/Syrian Churches",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Mf",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Maronites",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Mn",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Nestorian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Nn",
    "name": "CHURCH HISTORY",
    "subCategoryName": "North America",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ns",
    "name": "CHURCH HISTORY",
    "subCategoryName": "South –America",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Nt",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Third World",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Q",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "R",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Papacy: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ra",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rb",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rc",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Re",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rf",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Pope Francis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rg",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ri",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rj",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rl",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Rp",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Ru",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Individual Popes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "S",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Hierarchy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "T",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Under Biographies: Church man (Collective & Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 113,
    "subCategoryID": "Z",
    "name": "CHURCH HISTORY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 201,
    "subCategoryID": "A",
    "name": "TRAVELS",
    "subCategoryName": "World Travels",
    "categoryDisplay": "Yes",
    "categoryDesc": "Travelogues offer captivating accounts of journeys to unfamiliar places, providing vivid descriptions of cultures, landscapes, and personal experiences. They inspire wanderlust and broaden our understanding of the world.",
    "categoryImage": require("./assets/images/booksCat/cat5.png")
  },
  {
    "categoryID": 201,
    "subCategoryID": "B",
    "name": "TRAVELS",
    "subCategoryName": "Asian Travels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 201,
    "subCategoryID": "C",
    "name": "TRAVELS",
    "subCategoryName": "Select",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 201,
    "subCategoryID": "D",
    "name": "TRAVELS",
    "subCategoryName": "Indian Travels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 201,
    "subCategoryID": "H",
    "name": "TRAVELS",
    "subCategoryName": "Travelogues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 201,
    "subCategoryID": "M",
    "name": "TRAVELS",
    "subCategoryName": "Folios",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "A",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Asian: general",
    "categoryDisplay": "Yes",
    "categoryDesc": "From the bustling cities of China to the serene temples of Thailand, Asian countries offer a fascinating blend of traditions, languages, and landscapes. Exploring these diverse nations reveals a rich tapestry of human history and culture.",
    "categoryImage": require("./assets/images/booksCat/travel.png")
  },
  {
    "categoryID": 202,
    "subCategoryID": "As",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "South Asia (Subaltern)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "B",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "West Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Ba",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Afghanistan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bo",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Pakistan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bp",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Persia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bs",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Serials",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bt",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Turkey",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bu",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Bangladesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Bw",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "C",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Himalayan Countries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Cb",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Bhutan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Cn",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Nepal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Cs",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Sikkim",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Ct",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Tibet",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "D",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Burma",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "E",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Sri Lanka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "F",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Far East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Fc",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "China",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Fj",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Japan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Fk",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Korea",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Fv",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Vietnam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "G",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "South East Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gi",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Indonesia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gl",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Laos",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gm",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Malaysia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gp",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Philippines",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gt",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Thailand",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Gv",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Extreme – Orient",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 202,
    "subCategoryID": "Z",
    "name": "ASIAN COUNTRIES",
    "subCategoryName": "Varia (in Room No 113)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "A",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Ancient Geography",
    "categoryDisplay": "Yes",
    "categoryDesc": "India's vast and diverse geography, from the towering Himalayas to the fertile plains of the Ganges, has shaped its unique cultural and ethnic landscape. Ethnographic studies explore the rich tapestry of India's diverse communities, traditions, and languages.",
    "categoryImage": require("./assets/images/booksCat/travel.png")
  },
  {
    "categoryID": 203,
    "subCategoryID": "B",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Geographical Accounts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ca",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Regional Geography: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Cb",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Regional Geography: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Cc",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Local Geography like Delhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "D",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Gazetteers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "F",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "I",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Ethnography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ib",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ic",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Central India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ie",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "East India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "In",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "North India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ip",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "North – East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Is",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "South India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Iw",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "West India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "J",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Tribes: General/Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jb",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Bhils",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jf",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Gonds",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jg",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Garos",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jh",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "HO",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ji",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Juangs (Orissa)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jk",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Kharias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jl",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Lepchas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jm",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Mundas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jn",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Nagas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jo",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Maria",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Js",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Santals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ju",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Uraons",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Jv",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Others",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "K",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Caste",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "M",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Census of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Ms",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "N",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Indian States Development Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "S",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Fauna of British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "T",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Indian Fauna",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "V",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Indian Flora",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Z",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 203,
    "subCategoryID": "Za",
    "name": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "subCategoryName": "Varia Geological",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "A",
    "name": "INDIAN HISTORY",
    "subCategoryName": "General History",
    "categoryDisplay": "Yes",
    "categoryDesc": "India's rich and complex history spans thousands of years, from ancient civilizations to the British colonial era and modern independence. Exploring India's past reveals a fascinating blend of cultures, empires, and social movements.",
    "categoryImage": require("./assets/images/booksCat/cat3.png")
  },
  {
    "categoryID": 205,
    "subCategoryID": "B",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Cultural History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ba",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Indian Foreign Relations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "C",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Records",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "D",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Ancient Indian History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "E",
    "name": "INDIAN HISTORY",
    "subCategoryName": "History of Muslims India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ea",
    "name": "INDIAN HISTORY",
    "subCategoryName": "General History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Eb",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Biographical (Collective/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ef",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "G",
    "name": "INDIAN HISTORY",
    "subCategoryName": "History: Colonial Period",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Gd",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Dutch",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Gf",
    "name": "INDIAN HISTORY",
    "subCategoryName": "French",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Gp",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Portuguese/Goa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "I",
    "name": "INDIAN HISTORY",
    "subCategoryName": "History of British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "K",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Early British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "M",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Revolt of 1857",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "O",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Later British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "P",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Independence Movement",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Q",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Post – Independence India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "R",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Regional Histories: North India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ra",
    "name": "INDIAN HISTORY",
    "subCategoryName": "North East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rb",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Bengal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rc",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Bihar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rd",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Delhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rg",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Gujarat",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rh",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Haryana",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rj",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Jharkhand",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rh",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Chhattisgarh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rk",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Kashmir",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rm",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Maharashtra",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ro",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Orissa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rp",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Punjab",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rr",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Rajasthan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ru",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Uttar Pradesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ru",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Uttaranchal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Rv",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Himachal Pradesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "S",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Regional Histories: South India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Sa",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Andhra Pradesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Sd",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Deccan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Sk",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Kerala",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Ska",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Karnataka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "St",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Tamilnadu",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "U",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Biographical History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Us",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Biographical Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "V",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Biographies of Foreigners (Collective/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "W",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Biographies Indians (Collective/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Wn",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Nehru",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Xa",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Writing of Gandhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Xb",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Studies on Gandhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Xc",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Gandhian Bibliography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Z",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Zb",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Zc",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Antiquities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Zf",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Festschriften/Felicitation volume",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 205,
    "subCategoryID": "Zp",
    "name": "INDIAN HISTORY",
    "subCategoryName": "Various Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "A",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Socio-Economic: General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Socio-political books explore the intricate relationships between individuals, groups, and institutions within society. They analyze power structures, social dynamics, and political systems, providing insights into the forces that shape our world.",
    "categoryImage": require("./assets/images/booksCat/politics.png")
  },
  {
    "categoryID": 206,
    "subCategoryID": "C",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Indian Economics/Globalization",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Cp",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Planning",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Da",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Social Questions: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Db",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Social Research/Research Methodology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Dc",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Ecology/Environment",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Dd",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Labour",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "De",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Rural – Panjayati Raj",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Df",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Co-operatives",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Do",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Sarvodaya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Dp",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Population",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Dv",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Children",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Dw",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Women",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "E",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Indian Medicine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "F",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Folios",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "H",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Indian Society",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hc",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Caste",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hd",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Dalits",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hg",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Transgender",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hm",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Minorities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hs",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Hs1",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Social Concerns",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Ht",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Tribes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "I",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Constitution",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Ip",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Indian Politics/Polity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Jp",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Press",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Jq",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Communalism/Secularism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Jr",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Revolutionary Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Js",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Social Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Jt",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Terrorism/Violence",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Jv",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Violence-Riots",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "M",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Tagore Law Lectures",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "N",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Law (Acts….); General – Criminal/Civil/Acts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Na",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Landmark Judgments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Ne",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Legal Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "P",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "R",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "U",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Dharma Sastra",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "V",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "W",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Wp",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "X",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Y",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 206,
    "subCategoryID": "Z",
    "name": "SOCIO-POLITICAL",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 207,
    "subCategoryID": "E",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "Medicine",
    "categoryDisplay": "Yes",
    "categoryDesc": "India has a long and illustrious history of scientific discoveries, from ancient medical texts to groundbreaking advancements in mathematics and astronomy. Exploring Indian science reveals a rich heritage of intellectual curiosity and innovation.",
    "categoryImage": require("./assets/images/booksCat/cat2.png")
  },
  {
    "categoryID": 207,
    "subCategoryID": "Ea",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "History of Indian Medicine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 207,
    "subCategoryID": "Ed",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "Health Surveys",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 207,
    "subCategoryID": "Ef",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "Alternative medicine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 207,
    "subCategoryID": "Eh",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "Health Service",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 207,
    "subCategoryID": "V",
    "name": "INDIAN SCIENCE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 208,
    "subCategoryID": "A",
    "name": "INDIAN PSYCHOLOGY",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Indian psychology draws on ancient philosophical traditions and spiritual practices to explore the nature of the mind, consciousness, and human experience. It emphasizes mindfulness, meditation, and the interconnectedness of body, mind, and spirit.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 208,
    "subCategoryID": "B",
    "name": "INDIAN PSYCHOLOGY",
    "subCategoryName": "Hindu Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 208,
    "subCategoryID": "C",
    "name": "INDIAN PSYCHOLOGY",
    "subCategoryName": "Psychiatry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 208,
    "subCategoryID": "E",
    "name": "INDIAN PSYCHOLOGY",
    "subCategoryName": "Erotica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "A",
    "name": "LINGUISTICS",
    "subCategoryName": "Indian Linguistics",
    "categoryDisplay": "Yes",
    "categoryDesc": "Linguistics explores the structure, function, and evolution of language, examining everything from the sounds we make to the meaning we convey. It provides insights into how language shapes our thoughts, culture, and identity.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 209,
    "subCategoryID": "C",
    "name": "LINGUISTICS",
    "subCategoryName": "Grammars of Ancient Indian Languages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "E",
    "name": "LINGUISTICS",
    "subCategoryName": "Survey of Indian Linguistics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "H",
    "name": "LINGUISTICS",
    "subCategoryName": "Grammars of Modern Indian Languages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ha",
    "name": "LINGUISTICS",
    "subCategoryName": "Assamese",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hb",
    "name": "LINGUISTICS",
    "subCategoryName": "Bengali",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hc",
    "name": "LINGUISTICS",
    "subCategoryName": "Marathi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hd",
    "name": "LINGUISTICS",
    "subCategoryName": "Dravidian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "He",
    "name": "LINGUISTICS",
    "subCategoryName": "Sindhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hg",
    "name": "LINGUISTICS",
    "subCategoryName": "Gujarati",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hh",
    "name": "LINGUISTICS",
    "subCategoryName": "Hindi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hk",
    "name": "LINGUISTICS",
    "subCategoryName": "Konkani",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hm",
    "name": "LINGUISTICS",
    "subCategoryName": "Malayalam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hn",
    "name": "LINGUISTICS",
    "subCategoryName": "Nepali",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ho",
    "name": "LINGUISTICS",
    "subCategoryName": "Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hp",
    "name": "LINGUISTICS",
    "subCategoryName": "Punjabi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hs",
    "name": "LINGUISTICS",
    "subCategoryName": "Sinhalese",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ht",
    "name": "LINGUISTICS",
    "subCategoryName": "Tamil",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hu",
    "name": "LINGUISTICS",
    "subCategoryName": "Tribal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hv",
    "name": "LINGUISTICS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hx",
    "name": "LINGUISTICS",
    "subCategoryName": "Tibetan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Hz",
    "name": "LINGUISTICS",
    "subCategoryName": "Dialects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "J",
    "name": "LINGUISTICS",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ja",
    "name": "LINGUISTICS",
    "subCategoryName": "Sanskrit",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jb",
    "name": "LINGUISTICS",
    "subCategoryName": "Bengali",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jg",
    "name": "LINGUISTICS",
    "subCategoryName": "Gujarati",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jh",
    "name": "LINGUISTICS",
    "subCategoryName": "Hindi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ji",
    "name": "LINGUISTICS",
    "subCategoryName": "English – India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jk",
    "name": "LINGUISTICS",
    "subCategoryName": "Kannada",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jj",
    "name": "LINGUISTICS",
    "subCategoryName": "Pali",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jl",
    "name": "LINGUISTICS",
    "subCategoryName": "Lepcha",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jm",
    "name": "LINGUISTICS",
    "subCategoryName": "Malayalam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jn",
    "name": "LINGUISTICS",
    "subCategoryName": "Nepali",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jo",
    "name": "LINGUISTICS",
    "subCategoryName": "Tribal: Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jp",
    "name": "LINGUISTICS",
    "subCategoryName": "Persian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jt",
    "name": "LINGUISTICS",
    "subCategoryName": "Tamil",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Ju",
    "name": "LINGUISTICS",
    "subCategoryName": "Urdu/ Hindustani",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Jz",
    "name": "LINGUISTICS",
    "subCategoryName": "Varia – Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 209,
    "subCategoryID": "Z",
    "name": "LINGUISTICS",
    "subCategoryName": "Varia – Linguistics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "A",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "History of Indian Literature",
    "categoryDisplay": "Yes",
    "categoryDesc": "Literature, whether in general or specifically Sanskrit, offers a rich tapestry of stories, poems, and plays that explore the human experience. It provides insights into different cultures, time periods, and perspectives, inspiring imagination and thought.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 210,
    "subCategoryID": "B",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "History of Modern Indian Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Bm",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Serial Publications",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "D",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Sanskrit Poetics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "La",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Anthologies: Skt. Lit.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Lc",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Sanskrit Drama: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Lk",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Kalidasa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Lp",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Other Sanskrit Playwrights",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Lr",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Kavya (excl. Kalidasa)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "Lt",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Sanskrit Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "N",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Prakrit & Others – Pali Language + Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 210,
    "subCategoryID": "U",
    "name": "LIETRATURE: GENERAL / SANSKRITIC",
    "subCategoryName": "Treatises",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "A",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Vernacular literature, written in the native language of a region, captures the unique culture, traditions, and experiences of its people. It often reflects the everyday lives, struggles, and aspirations of ordinary folks, providing a valuable window into diverse perspectives.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ba",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Bengali Literature: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literature  Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Be",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bf",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bh",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bm",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Prose/Shorts Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bn",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt A",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Tagore Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt C",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Tagore Collected Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt D",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Tagore: Plays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt E",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Tagore: Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt N",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Bt P",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poems",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "C",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Kashmiri Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Cc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Library Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "D",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Nepali Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Da",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays/Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Dd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Dramas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Df",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Dl",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Dp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poems",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "G",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Gujarati Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ga",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "General/History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Gd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Gg",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Gk",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Gm",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Gp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Short stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "I",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Indo-Anglican Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ia",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literary Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ic",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Id",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Plays & Dramas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ic",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Humour",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "In",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ip",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poems",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Iv",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "……..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "K",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Kannada Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ka",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Kc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literary Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Kd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Kp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Kt",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "L",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Konkani Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "La",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Linguistics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ln",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels/Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "M",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Marathi Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Mn",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels/Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Mp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Na",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Malayalam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Nc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literary Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Nd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ng",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Nk",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Nm",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Np",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Short Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qa",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Telugu Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qd",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qe",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qm",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qn",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qs",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Classics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Qt",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ta",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Tamil Literature: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tb",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "History of Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literary Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Td",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Te",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tn",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tp",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry: Classical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tq",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry: Modern",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ts",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Tv",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ua",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Urdu Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Uc",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Literary Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ud",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Urdu Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Ue",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Urdu Essays & Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 211,
    "subCategoryID": "Up",
    "name": "VERNACULAR LITERATURE",
    "subCategoryName": "Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "A",
    "name": "Hindi",
    "subCategoryName": "Hindi Literature: General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Hindi, one of the most widely spoken languages in the world, is a cornerstone of Indian culture and literature. It offers a rich tapestry of words, expressions, and stories that reflect the diversity and beauty of India.",
    "categoryImage": require("./assets/images/booksCat/lang.png")
  },
  {
    "categoryID": 212,
    "subCategoryID": "D",
    "name": "Hindi",
    "subCategoryName": "Nirgun Bhakti Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "E",
    "name": "Hindi",
    "subCategoryName": "Sagun Bhakti Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "G",
    "name": "Hindi",
    "subCategoryName": "Ritikal Literature: Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "J",
    "name": "Hindi",
    "subCategoryName": "Modern Hindi Poetry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "L",
    "name": "Hindi",
    "subCategoryName": "Hindi Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "N",
    "name": "Hindi",
    "subCategoryName": "Novels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 212,
    "subCategoryID": "P",
    "name": "Hindi",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "A",
    "name": "INDIAN CULTURE",
    "subCategoryName": "A.B.O.R.I (Series)",
    "categoryDisplay": "Yes",
    "categoryDesc": "Indian culture, shaped by centuries of history, religious traditions, and diverse ethnic groups, is a vibrant and complex tapestry. It encompasses everything from art and music to language, cuisine, and philosophy, offering a rich and fascinating exploration of human expression.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 214,
    "subCategoryID": "E",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Folk Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "L",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Bala Sahitya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "M",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Heritage of India Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "N",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Studies on Indian Culture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Q",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Indian Music",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "T",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Indian Dance",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "V",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Indian Film & Theatre",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "W",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Arts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "X",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Congress/Conference",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Xh",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Indian History Congress",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Xi",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Punjab History Conference",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Xo",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Oriental Congress",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Xp",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Philosophical Congress",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Xs",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Sanskrit Conferences",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 214,
    "subCategoryID": "Z",
    "name": "INDIAN CULTURE",
    "subCategoryName": "Festschriften/Felicitation volumes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "A",
    "name": "HINDUISM",
    "subCategoryName": "Series",
    "categoryDisplay": "Yes",
    "categoryDesc": "Hinduism, one of the world's oldest religions, is a diverse and complex system of beliefs and practices. It encompasses a wide range of traditions, philosophies, and deities, offering a rich and multifaceted understanding of the human experience.",
    "categoryImage": require("./assets/images/booksCat/hindu.png")
  },
  {
    "categoryID": 216,
    "subCategoryID": "Aa",
    "name": "HINDUISM",
    "subCategoryName": "Anandasram Skt. Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ad",
    "name": "HINDUISM",
    "subCategoryName": "Sacred Books of Hindus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ae",
    "name": "HINDUISM",
    "subCategoryName": "Sacred Books of the East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Af",
    "name": "HINDUISM",
    "subCategoryName": "Muir’s Sanskrit. Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ak",
    "name": "HINDUISM",
    "subCategoryName": "The Religious Life of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Am",
    "name": "HINDUISM",
    "subCategoryName": "Pamphlets on Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ap",
    "name": "HINDUISM",
    "subCategoryName": "Publications de l’ Inst. D’Indologie",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Av",
    "name": "HINDUISM",
    "subCategoryName": "De Nobili Collection (Vienna)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "C",
    "name": "HINDUISM",
    "subCategoryName": "Collected Eastern Religious Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "D",
    "name": "HINDUISM",
    "subCategoryName": "Indian Religious: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "F",
    "name": "HINDUISM",
    "subCategoryName": "Hindu Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "G",
    "name": "HINDUISM",
    "subCategoryName": "Hindu Philosophy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "I",
    "name": "HINDUISM",
    "subCategoryName": "Vedism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ia",
    "name": "HINDUISM",
    "subCategoryName": "Vedic Bibl./Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ic",
    "name": "HINDUISM",
    "subCategoryName": "Vedic Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Id",
    "name": "HINDUISM",
    "subCategoryName": "Samhitas (Texts & Translation)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "If",
    "name": "HINDUISM",
    "subCategoryName": "Brahmanas /Aranyakas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ig",
    "name": "HINDUISM",
    "subCategoryName": "Kalpa Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ih",
    "name": "HINDUISM",
    "subCategoryName": "Upanishads",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "K",
    "name": "HINDUISM",
    "subCategoryName": "Gita",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "L",
    "name": "HINDUISM",
    "subCategoryName": "Epics and Puranas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Lb",
    "name": "HINDUISM",
    "subCategoryName": "Epics: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Lm",
    "name": "HINDUISM",
    "subCategoryName": "Mahabharata",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Lr",
    "name": "HINDUISM",
    "subCategoryName": "Ramayana",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Lu",
    "name": "HINDUISM",
    "subCategoryName": "AITM (Ancient Indian Tradition & Mythology) Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Lv",
    "name": "HINDUISM",
    "subCategoryName": "Puranas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "M",
    "name": "HINDUISM",
    "subCategoryName": "Philosophy of Language",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "N",
    "name": "HINDUISM",
    "subCategoryName": "Purva Mimamsa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Oc",
    "name": "HINDUISM",
    "subCategoryName": "Vedanta: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Of",
    "name": "HINDUISM",
    "subCategoryName": "Kevaladvaita (Sankara)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Oh",
    "name": "HINDUISM",
    "subCategoryName": "Visistadvaita",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Om",
    "name": "HINDUISM",
    "subCategoryName": "Dvaita",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Op",
    "name": "HINDUISM",
    "subCategoryName": "Suddhadvaita (Vallbha)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Os",
    "name": "HINDUISM",
    "subCategoryName": "Bhedabheda",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "P",
    "name": "HINDUISM",
    "subCategoryName": "Samkhya Yoga",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Q",
    "name": "HINDUISM",
    "subCategoryName": "Nyaya Vaisrsika",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "R",
    "name": "HINDUISM",
    "subCategoryName": "Tantrism/Saktism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "S",
    "name": "HINDUISM",
    "subCategoryName": "Bhakti: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sc",
    "name": "HINDUISM",
    "subCategoryName": "Vaisnavism: General/Krishna",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Se",
    "name": "HINDUISM",
    "subCategoryName": "South Indian Vaisnavism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sg",
    "name": "HINDUISM",
    "subCategoryName": "Maharastrian Vaisnavism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sh",
    "name": "HINDUISM",
    "subCategoryName": "North Indian Vaisnavism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sj",
    "name": "HINDUISM",
    "subCategoryName": "Assam & Bengal Vaisnavism/Chaitanya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sl",
    "name": "HINDUISM",
    "subCategoryName": "Saivism: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sm",
    "name": "HINDUISM",
    "subCategoryName": "Tamil Saiva Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "So",
    "name": "HINDUISM",
    "subCategoryName": "Saiva Siddhanta",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Sq",
    "name": "HINDUISM",
    "subCategoryName": "Vira Saivism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "St",
    "name": "HINDUISM",
    "subCategoryName": "Kasmir Saivism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "U",
    "name": "HINDUISM",
    "subCategoryName": "Modern Hinduism+ Krishnamurthi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ub",
    "name": "HINDUISM",
    "subCategoryName": "Biographies: Modern Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ud",
    "name": "HINDUISM",
    "subCategoryName": "Hindi Writings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Uo",
    "name": "HINDUISM",
    "subCategoryName": "Aurobindo",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ur",
    "name": "HINDUISM",
    "subCategoryName": "Radhaswami",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Uv",
    "name": "HINDUISM",
    "subCategoryName": "Rajnish: Varia Contemporary Hindism- Hare Krishna",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Va",
    "name": "HINDUISM",
    "subCategoryName": "Ramakrisna Mission",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Vc",
    "name": "HINDUISM",
    "subCategoryName": "Vivekananda",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Vd",
    "name": "HINDUISM",
    "subCategoryName": "Ramakrishna Mission Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "W",
    "name": "HINDUISM",
    "subCategoryName": "Arya Samaj",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Wr",
    "name": "HINDUISM",
    "subCategoryName": "RSS/Hindutva",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "X",
    "name": "HINDUISM",
    "subCategoryName": "Theosophy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Y",
    "name": "HINDUISM",
    "subCategoryName": "Varia Hinduism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Ya",
    "name": "HINDUISM",
    "subCategoryName": "Festschrift",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Z",
    "name": "HINDUISM",
    "subCategoryName": "Hinduism & Christianity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zb",
    "name": "HINDUISM",
    "subCategoryName": "Indian Views of Christ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zd",
    "name": "HINDUISM",
    "subCategoryName": "Comparative Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zr",
    "name": "HINDUISM",
    "subCategoryName": "Christian Interpretation of Hindism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zh",
    "name": "HINDUISM",
    "subCategoryName": "Hindu/Christian Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zi",
    "name": "HINDUISM",
    "subCategoryName": "Indian Theological Association Report",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zj",
    "name": "HINDUISM",
    "subCategoryName": "Indian Christian Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zj D",
    "name": "HINDUISM",
    "subCategoryName": "Dalit Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zj T",
    "name": "HINDUISM",
    "subCategoryName": "Tribal Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zk",
    "name": "HINDUISM",
    "subCategoryName": "Theological Comparisons",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zm",
    "name": "HINDUISM",
    "subCategoryName": "Persons/Institutions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 216,
    "subCategoryID": "Zs",
    "name": "HINDUISM",
    "subCategoryName": "Indian Christian Theology Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "A",
    "name": "ISLAM",
    "subCategoryName": "Quran",
    "categoryDisplay": "Yes",
    "categoryDesc": "Islam, one of the world's major religions, is centered on the belief in one God, Allah, and the teachings of the Prophet Muhammad as revealed in the Quran. It emphasizes submission to God's will, charity, and compassion for others.",
    "categoryImage": require("./assets/images/booksCat/muslim.png")
  },
  {
    "categoryID": 217,
    "subCategoryID": "Aa",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ab",
    "name": "ISLAM",
    "subCategoryName": "Translations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ac",
    "name": "ISLAM",
    "subCategoryName": "Concordance/Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ad",
    "name": "ISLAM",
    "subCategoryName": "Tafir Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ae",
    "name": "ISLAM",
    "subCategoryName": "Cilm al-Tafsir",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Af",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ag",
    "name": "ISLAM",
    "subCategoryName": "Cilm al-gir’ah",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "C",
    "name": "ISLAM",
    "subCategoryName": "Tradition",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ca",
    "name": "ISLAM",
    "subCategoryName": "Hadith Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Cb",
    "name": "ISLAM",
    "subCategoryName": "Hadith Translations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Cd",
    "name": "ISLAM",
    "subCategoryName": "Hadith Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ce",
    "name": "ISLAM",
    "subCategoryName": "Cilm al-Hadith",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Cf",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Cq",
    "name": "ISLAM",
    "subCategoryName": "Qisas al-anbia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "E",
    "name": "ISLAM",
    "subCategoryName": "Kalam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ea",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Eb",
    "name": "ISLAM",
    "subCategoryName": "Expositions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Eb M",
    "name": "ISLAM",
    "subCategoryName": "by Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Eb N",
    "name": "ISLAM",
    "subCategoryName": "by Non Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ef",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ek",
    "name": "ISLAM",
    "subCategoryName": "Inneislamic Controversy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Es",
    "name": "ISLAM",
    "subCategoryName": "Sermons",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "F",
    "name": "ISLAM",
    "subCategoryName": "Falsafah Adab",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Fa",
    "name": "ISLAM",
    "subCategoryName": "Texts (Author)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Fb",
    "name": "ISLAM",
    "subCategoryName": "Expositions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ff",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Fh",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "G",
    "name": "ISLAM",
    "subCategoryName": "Figh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ga",
    "name": "ISLAM",
    "subCategoryName": "Texts (authors)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Gc",
    "name": "ISLAM",
    "subCategoryName": "Usul al-figh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Gf",
    "name": "ISLAM",
    "subCategoryName": "Research/Law",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Gm",
    "name": "ISLAM",
    "subCategoryName": "Modern Problems",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Gp",
    "name": "ISLAM",
    "subCategoryName": "Muslim Society in Body Politic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "I",
    "name": "ISLAM",
    "subCategoryName": "Tasawwuf",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ia",
    "name": "ISLAM",
    "subCategoryName": "Texts (Authors)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ic",
    "name": "ISLAM",
    "subCategoryName": "Expositions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "If",
    "name": "ISLAM",
    "subCategoryName": "Research (incl. Sufi Orders)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ig",
    "name": "ISLAM",
    "subCategoryName": "Sufi Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "K",
    "name": "ISLAM",
    "subCategoryName": "Firaq",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ka",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kb",
    "name": "ISLAM",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "KbA",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kd",
    "name": "ISLAM",
    "subCategoryName": "Shi’a: Ithana ‘Ashariyya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kd A",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kd F",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kh",
    "name": "ISLAM",
    "subCategoryName": "Shi’a: Mutatarrifa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kh A",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "KhF",
    "name": "ISLAM",
    "subCategoryName": "Research",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ki",
    "name": "ISLAM",
    "subCategoryName": "Non-Shi’Ite Sects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ki A",
    "name": "ISLAM",
    "subCategoryName": "…..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ki F",
    "name": "ISLAM",
    "subCategoryName": "……",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Kn",
    "name": "ISLAM",
    "subCategoryName": "Babism – Bahaism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "M",
    "name": "ISLAM",
    "subCategoryName": "Islam in General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ma",
    "name": "ISLAM",
    "subCategoryName": "Reference Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mb M",
    "name": "ISLAM",
    "subCategoryName": "Expositions by Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mb N",
    "name": "ISLAM",
    "subCategoryName": "Expositions by non-Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mc",
    "name": "ISLAM",
    "subCategoryName": "Prayer/Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Md",
    "name": "ISLAM",
    "subCategoryName": "Popular Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Me",
    "name": "ISLAM",
    "subCategoryName": "Islam & Modern Ideologies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mg",
    "name": "ISLAM",
    "subCategoryName": "Classical Islamic Sciences",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mh",
    "name": "ISLAM",
    "subCategoryName": "History including geography & Travel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mi",
    "name": "ISLAM",
    "subCategoryName": "Regional History/Sociology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ml",
    "name": "ISLAM",
    "subCategoryName": "Analysis of Modern Islam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mm",
    "name": "ISLAM",
    "subCategoryName": "Sira of the Prophet",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mp",
    "name": "ISLAM",
    "subCategoryName": "Renewal of Religious Thought",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Mq",
    "name": "ISLAM",
    "subCategoryName": "Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "My",
    "name": "ISLAM",
    "subCategoryName": "Islam in Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "N",
    "name": "ISLAM",
    "subCategoryName": "Indian Sub-continent",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Na",
    "name": "ISLAM",
    "subCategoryName": "General Information",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nc",
    "name": "ISLAM",
    "subCategoryName": "Indian Sufism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nd",
    "name": "ISLAM",
    "subCategoryName": "Outstanding Religious Authors",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ne",
    "name": "ISLAM",
    "subCategoryName": "Political History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ni",
    "name": "ISLAM",
    "subCategoryName": "Sociological Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Np",
    "name": "ISLAM",
    "subCategoryName": "Religious Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nq",
    "name": "ISLAM",
    "subCategoryName": "Education (Institution)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nr",
    "name": "ISLAM",
    "subCategoryName": "Renewal of Religious thought",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ns",
    "name": "ISLAM",
    "subCategoryName": "Problems of Past – 1947 India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nv",
    "name": "ISLAM",
    "subCategoryName": "Pakistan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Nx",
    "name": "ISLAM",
    "subCategoryName": "Bangladesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ny",
    "name": "ISLAM",
    "subCategoryName": "Islam in Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Q",
    "name": "ISLAM",
    "subCategoryName": "Islamic Literatures in the Indian Sub-Continent",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qa",
    "name": "ISLAM",
    "subCategoryName": "Arabic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qa A",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "QaF",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qp",
    "name": "ISLAM",
    "subCategoryName": "Persian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qp A",
    "name": "ISLAM",
    "subCategoryName": "Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qp F",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qu",
    "name": "ISLAM",
    "subCategoryName": "Urdu",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qu A",
    "name": "ISLAM",
    "subCategoryName": "Text",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qu F",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Qv",
    "name": "ISLAM",
    "subCategoryName": "India Regional Languages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ra",
    "name": "ISLAM",
    "subCategoryName": "Islam & Christianity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Rb",
    "name": "ISLAM",
    "subCategoryName": "Past Theol. Debate",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Rc",
    "name": "ISLAM",
    "subCategoryName": "Jesus in Islam (& Mary)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Rd",
    "name": "ISLAM",
    "subCategoryName": "Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Re",
    "name": "ISLAM",
    "subCategoryName": "Christine Practice",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Rm",
    "name": "ISLAM",
    "subCategoryName": "Muslim Da’wah: Saints as seen by Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Rt",
    "name": "ISLAM",
    "subCategoryName": "Christianity Explained to Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "S",
    "name": "ISLAM",
    "subCategoryName": "Islam & the non-Christian Religions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Sa",
    "name": "ISLAM",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Sb",
    "name": "ISLAM",
    "subCategoryName": "Islam & OT & Later Jewish Religion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Sc",
    "name": "ISLAM",
    "subCategoryName": "Islam & Pre-Islam Iran",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Sd",
    "name": "ISLAM",
    "subCategoryName": "Islam & Hinduism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Se",
    "name": "ISLAM",
    "subCategoryName": "Islam & Buddhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Sf",
    "name": "ISLAM",
    "subCategoryName": "Islam & Sikhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "T",
    "name": "ISLAM",
    "subCategoryName": "Arabs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ta",
    "name": "ISLAM",
    "subCategoryName": "Language",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Tb",
    "name": "ISLAM",
    "subCategoryName": "Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Te",
    "name": "ISLAM",
    "subCategoryName": "Travelogues etc.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "U",
    "name": "ISLAM",
    "subCategoryName": "Arab Christians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ua",
    "name": "ISLAM",
    "subCategoryName": "History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ub",
    "name": "ISLAM",
    "subCategoryName": "Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Uc",
    "name": "ISLAM",
    "subCategoryName": "Translations of Christian Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "V",
    "name": "ISLAM",
    "subCategoryName": "Islamic Iran  & Turkey",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Va",
    "name": "ISLAM",
    "subCategoryName": "Language",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Vb",
    "name": "ISLAM",
    "subCategoryName": "Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Ve",
    "name": "ISLAM",
    "subCategoryName": "Travelogues etc.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "W",
    "name": "ISLAM",
    "subCategoryName": "Islamic Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Wa",
    "name": "ISLAM",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Wb",
    "name": "ISLAM",
    "subCategoryName": "Architecture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Wd",
    "name": "ISLAM",
    "subCategoryName": "calligraphy & Painting",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Wm",
    "name": "ISLAM",
    "subCategoryName": "Other Arts & Crafts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "X",
    "name": "ISLAM",
    "subCategoryName": "Biographies & Tezkirahs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Xa",
    "name": "ISLAM",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Xd",
    "name": "ISLAM",
    "subCategoryName": "Indian Sub-Continent",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Xf",
    "name": "ISLAM",
    "subCategoryName": "Islamologists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Y",
    "name": "ISLAM",
    "subCategoryName": "Festschriften/ Felicitation volumes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Yc",
    "name": "ISLAM",
    "subCategoryName": "Collected Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Yf",
    "name": "ISLAM",
    "subCategoryName": "Festschriften/ Felicitation volumes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Yp",
    "name": "ISLAM",
    "subCategoryName": "Proceedings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": "Z",
    "name": "ISLAM",
    "subCategoryName": "Varia Islamic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 1,
    "name": "ISLAM",
    "subCategoryName": "Generalia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 2,
    "name": "ISLAM",
    "subCategoryName": "Theologica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 3,
    "name": "ISLAM",
    "subCategoryName": "Christana",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 4,
    "name": "ISLAM",
    "subCategoryName": "Historica (Incl. Biographical)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 5,
    "name": "ISLAM",
    "subCategoryName": "Sufism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 6,
    "name": "ISLAM",
    "subCategoryName": "Christians & Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 7,
    "name": "ISLAM",
    "subCategoryName": "Ahmadiyya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 217,
    "subCategoryID": 8,
    "name": "ISLAM",
    "subCategoryName": "Indian Muslims",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "A",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Jainism",
    "categoryDisplay": "Yes",
    "categoryDesc": "Beyond Hinduism and Islam, the world is home to a vast array of diverse religions, each with its own unique beliefs, practices, and traditions. Exploring these other faiths offers a deeper understanding of humanity's spiritual diversity and the ways in which people seek meaning and purpose in their lives.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 218,
    "subCategoryID": "Ab",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Sacred Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "B",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Buddhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Bb",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Buddha",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Bv",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Varia Buddhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Bz",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Zen",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "E",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Buddhist Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Ep",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Series on Buddhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "G",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Buddhist – Christian Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Hb",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Souvenirs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "I",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Indian Judaism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "J",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Baha’ism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "S",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Sikhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Sa",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Sikh Gurus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Sc",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Sikh – Christian Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Sm",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Sikh Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "V",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Zoroastrianism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 218,
    "subCategoryID": "Z",
    "name": "OTHER RELIGIONS",
    "subCategoryName": "Varia Buddhistica – Islamica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "A",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "History: General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Christianity has a long and vibrant history in South Asia, dating back to the early centuries of the Common Era. It has influenced the region's culture, art, and social dynamics, leaving a lasting legacy that continues to shape the lives of millions of people.",
    "categoryImage": require("./assets/images/booksCat/travel.png")
  },
  {
    "categoryID": 221,
    "subCategoryID": "Aa",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Catholic Church",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ab",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Other Churches/Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ac",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Religious Order/Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ad",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "St. Thomas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "B",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "South India: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ba",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Kerala",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Bb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Tamil Nadu",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Bc",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Andhra",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Bd",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Karnataka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "C",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "West India: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ca",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Goa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Cb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Maharashtra",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Cc",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Gujarat",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "D",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Central India: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "E",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "East India: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ea",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Bengal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Eb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Chotanagpur",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ec",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Orissa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "F",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "North India - General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fa",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "The Mughal Mission",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "The Tibetan Mission",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fc",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "(North) Bihar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fd",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Delhi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fk",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Jammu-Kasmir",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fp",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Punjab/Haryana/Hp",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fr",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Rajasthan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Fu",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "U.P./Uttarckhand",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "G",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "North East India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "H",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Sri Lanka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "I",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Pakistan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "J",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Nepal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "K",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Biographies (Alphabetical)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ka",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Kb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "M",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Church Organization: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ma",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Bishop/CBCI",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "CBCI",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mc",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "NCCI",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mf",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "CRI & Religious",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mg",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Particular Religious Congregations (Alphabetical)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mh",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Secular Institutes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mj",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Clergy and Seminars",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mk",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Laity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ml",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Youth",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mp",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Ecumenical/Protestant Organ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Mw",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Women",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "P",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Church Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pa",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Seminar/Congress",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Liturgy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pc",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Catechetics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pd",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Dialogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pe",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Development",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ph",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Health",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pi",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Inculturation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pm",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Mission/Ministries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pp",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Press",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Ps",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Social Institutions/Caste",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Pt",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Theology/Bible",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Q",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Ancient Missionary Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Qa",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Theological/Devotional Texts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Qb",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Descriptions of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "R",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Christian Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "V",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Apologetics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "W",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Controversy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "X",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Pamphlet Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Y",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Za",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Bibliography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 221,
    "subCategoryID": "Zh",
    "name": "CHRISTIANITY IN SOUTH ASIA",
    "subCategoryName": "Hosten Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "A",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Indian Education: General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Indian education has undergone significant transformations over the years, striving to provide quality education to a vast and diverse population. It plays a crucial role in shaping the nation's future, fostering intellectual development, and promoting social mobility.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 224,
    "subCategoryID": "As",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Student Service",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "C",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Christian Education in India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "D",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "Dv",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "E",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Institutions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 224,
    "subCategoryID": "V",
    "name": "INDIAN EDUCATION",
    "subCategoryName": "Varia Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 230,
    "subCategoryID": "A",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "Christian Directories",
    "categoryDisplay": "Yes",
    "categoryDesc": "Serial publications, such as journals, magazines, and newspapers, provide regular updates on a wide range of topics, ensuring readers stay informed and engaged with the latest news, research, and trends. They offer a valuable source of knowledge and analysis, keeping readers connected to the world around them.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 230,
    "subCategoryID": "C",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "Non – Christian Annual/Directories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 230,
    "subCategoryID": "D",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "ISI Documentation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 230,
    "subCategoryID": "F",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "Bibliographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 230,
    "subCategoryID": "H",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "Subject Bibliographies/Handbooks/Lib. Science",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 230,
    "subCategoryID": "I",
    "name": "SERIAL PUBLICATIONS",
    "subCategoryName": "Reference Guides",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "A",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Early Christian Mission",
    "categoryDisplay": "Yes",
    "categoryDesc": "Art and architecture are powerful forms of human expression, reflecting cultures, values, and emotions. They inspire, provoke thought, and enrich our lives, leaving a lasting legacy of beauty and innovation.",
    "categoryImage": require("./assets/images/booksCat/art.png")
  },
  {
    "categoryID": 241,
    "subCategoryID": "Aa",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "History of Art/Sciences",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "D",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Archaeology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "F",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Guides (Tourists)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "H",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "L",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Art Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "M",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Handicrafts/Miniatures",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "P",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Painting",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "R",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Architecture/Iconography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 241,
    "subCategoryID": "Z",
    "name": "ART/ARCHETECTURE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "A",
    "name": "PHILOSOPHY",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Philosophy explores the fundamental questions of life, such as the nature of reality, knowledge, morality, and existence. It challenges our assumptions, stimulates critical thinking, and provides insights into the meaning and purpose of life.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 301,
    "subCategoryID": "Ad",
    "name": "PHILOSOPHY",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Ae",
    "name": "PHILOSOPHY",
    "subCategoryName": "Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "B",
    "name": "PHILOSOPHY",
    "subCategoryName": "Manuals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "C",
    "name": "PHILOSOPHY",
    "subCategoryName": "Logic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "D",
    "name": "PHILOSOPHY",
    "subCategoryName": "Metaphysics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "E",
    "name": "PHILOSOPHY",
    "subCategoryName": "Theodicy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "F",
    "name": "PHILOSOPHY",
    "subCategoryName": "Cosmology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "H",
    "name": "PHILOSOPHY",
    "subCategoryName": "Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Hb",
    "name": "PHILOSOPHY",
    "subCategoryName": "Freud/Psychoanalysis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Hc",
    "name": "PHILOSOPHY",
    "subCategoryName": "Counselling/Psychotherapy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "HL",
    "name": "PHILOSOPHY",
    "subCategoryName": "Philosophy of Language",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Hs",
    "name": "PHILOSOPHY",
    "subCategoryName": "Social Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "K",
    "name": "PHILOSOPHY",
    "subCategoryName": "Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "M",
    "name": "PHILOSOPHY",
    "subCategoryName": "History of Philosophy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "N",
    "name": "PHILOSOPHY",
    "subCategoryName": "Greek Philosophy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "P",
    "name": "PHILOSOPHY",
    "subCategoryName": "Christian Philosophy Ancient & Medieval",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Q",
    "name": "PHILOSOPHY",
    "subCategoryName": "Contemporary Philosophy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Qe",
    "name": "PHILOSOPHY",
    "subCategoryName": "Existentialism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Qf",
    "name": "PHILOSOPHY",
    "subCategoryName": "Feminism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "R",
    "name": "PHILOSOPHY",
    "subCategoryName": "Philosophers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "V",
    "name": "PHILOSOPHY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 301,
    "subCategoryID": "Z",
    "name": "PHILOSOPHY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "A",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Constitutions",
    "categoryDisplay": "Yes",
    "categoryDesc": "The Society of Jesus, also known as the Jesuits, is a Catholic religious order founded by St. Ignatius of Loyola. They are dedicated to education, mission work, and social justice, making a significant impact on the world through their intellectual, spiritual, and humanitarian endeavors.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 302,
    "subCategoryID": "Aa",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Institutum",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Ab",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Rules/Ordinances",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Ac",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Commentaries & Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Ad",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Decrees General Congregation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Ag",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Letters of Sup. Gen.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "C",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Views about S.J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "D",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "History of S.J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "E",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Suppression",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Ez",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "One book",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "F",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Monumenta Historica S.J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Fm",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Monumenta Missionum",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "G",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Bibl. Hist. S. J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Gs",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Subsidia  ad Hist. S. J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "H",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Italian Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "I",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Spanish Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "J",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Portuguese Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "K",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "French Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "L",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "English Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "M",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "German Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "N",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "North American Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "O",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "East Asian Assistancy/South Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "P",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "South American Assistancy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Q",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Folios",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "R",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "China",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "S",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Biographies S.J. Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Sa",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "St. Ignatius",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Sb",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "St. Francis Xavier",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Si",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Others",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "V",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Menologies S.J.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "W",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Apostolate",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "X",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Y",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Jesuit Province Catalogue",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 302,
    "subCategoryID": "Z",
    "name": "SOCIETY OF JESUS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Jesuit catalogues and directories provide valuable information about the activities, personnel, and locations of Jesuit institutions and missions worldwide. They serve as historical records and resources for researchers, scholars, and those interested in the Jesuit legacy.",
    "categoryImage": require("./assets/images/booksCat/direct.png")
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 2.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Directories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 2.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "South India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 2.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "West India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 2.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "North India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "ASIA (EXE. India)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "China",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.5,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Far East",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.6,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.7,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Hong Kong/Macau",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.8,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.9,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Indonesia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.11,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.12,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Japan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.13,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.14,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.15,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Korea",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.16,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.17,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Near East/ Proximi orientalis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.18,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.19,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Philippines",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.21,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.22,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.23,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.24,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 3.25,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Sri. Lanka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "AUSTRALIA/PACIFIC",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "AFRICA",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "North Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "North West Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Central Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "East Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.5,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "West Africa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.6,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.7,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.8,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.9,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Madagascar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.11,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.12,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.13,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.14,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Zambia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.15,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 5.16,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Zimbabwe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "EUROPE",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Austria",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Belgium",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.5,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Bohemia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.6,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Britain",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.7,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "….",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.8,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Croatia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.9,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Czechoslovakia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.11,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Slavonia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.12,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "France",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.13,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.14,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Germany",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.15,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.16,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Holland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.17,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.18,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Hungary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.19,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Ireland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.21,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.22,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Italy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.23,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.24,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.25,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Lithuania",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.26,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Malta",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.27,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.28,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Poland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.29,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Portugal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.31,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.32,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Romania",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.33,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.34,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.35,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Russia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.36,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.37,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Slovakia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.38,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.39,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Spain",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.41,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.42,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 6.43,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Switzerland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "AMERICA",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Argentina",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.4,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.5,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Bolivia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.6,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Brazil",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.7,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.8,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Canada",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.9,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.1,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.11,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Central America Antilles",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.12,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Chile",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.13,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Columbia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.14,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Ecuador",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.15,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.16,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Mexico",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.17,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Paraguay",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.18,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Peru",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.19,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.2,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Puerto Rico",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.21,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Uruguay",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.22,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.23,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.24,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.25,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "U.S.A.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.26,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.27,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.28,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.29,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "…",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": "302Y",
    "subCategoryID": 7.3,
    "name": "JESUIT CATELOGUES & DIRECTORIES",
    "subCategoryName": "Venezuela",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "A",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Spiritual Exercises: Texts",
    "categoryDisplay": "Yes",
    "categoryDesc": "Spiritual Exercises is a series of meditations and reflections developed by St. Ignatius of Loyola to help individuals deepen their relationship with God and discern their spiritual vocation. It offers a structured approach to prayer and contemplation, guiding individuals towards greater self-awareness and spiritual growth.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 303,
    "subCategoryID": "Ad",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Directories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "B",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "General Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "C",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Particular Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "D",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "E",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Retreats (8-10 days)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "F",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "On Giving Retreats",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "G",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Aids",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "H",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Vocation Retreats",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "I",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Popular Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "M",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Non-Ignatian Retreats",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 303,
    "subCategoryID": "R",
    "name": "SPIRITUAL EXERCISES",
    "subCategoryName": "Varia Exercises",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "A",
    "name": "SPIRITUALITY",
    "subCategoryName": "History",
    "categoryDisplay": "Yes",
    "categoryDesc": "Spirituality explores the human search for meaning, purpose, and connection to something greater than oneself. It encompasses a wide range of beliefs, practices, and experiences, offering individuals a path to inner peace, fulfillment, and a deeper understanding of life's mysteries.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ad",
    "name": "SPIRITUALITY",
    "subCategoryName": "Dictionaries/Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "As",
    "name": "SPIRITUALITY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "B",
    "name": "SPIRITUALITY",
    "subCategoryName": "Monasticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Bs",
    "name": "SPIRITUALITY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "C",
    "name": "SPIRITUALITY",
    "subCategoryName": "Religious Institutes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Cb",
    "name": "SPIRITUALITY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "D",
    "name": "SPIRITUALITY",
    "subCategoryName": "Augustinian Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Dc",
    "name": "SPIRITUALITY",
    "subCategoryName": "Statutes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "E",
    "name": "SPIRITUALITY",
    "subCategoryName": "Benedictine Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Eb",
    "name": "SPIRITUALITY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "F",
    "name": "SPIRITUALITY",
    "subCategoryName": "Cistercian Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Fb",
    "name": "SPIRITUALITY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "G",
    "name": "SPIRITUALITY",
    "subCategoryName": "Norbertine Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "H",
    "name": "SPIRITUALITY",
    "subCategoryName": "Franciscan Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ha",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Francis of Assisi",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Hc",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Clare",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Hd",
    "name": "SPIRITUALITY",
    "subCategoryName": "Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "He",
    "name": "SPIRITUALITY",
    "subCategoryName": "Franciscan History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "He C",
    "name": "SPIRITUALITY",
    "subCategoryName": "Capuchins",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Hs",
    "name": "SPIRITUALITY",
    "subCategoryName": "Franciscan Spirituality/Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "I",
    "name": "SPIRITUALITY",
    "subCategoryName": "Dominican Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ib",
    "name": "SPIRITUALITY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "J",
    "name": "SPIRITUALITY",
    "subCategoryName": "Jesuit Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ja",
    "name": "SPIRITUALITY",
    "subCategoryName": "Jesuit Spirituality Manuals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jb",
    "name": "SPIRITUALITY",
    "subCategoryName": "Exhortations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jc",
    "name": "SPIRITUALITY",
    "subCategoryName": "General Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jd",
    "name": "SPIRITUALITY",
    "subCategoryName": "Devotional",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Je",
    "name": "SPIRITUALITY",
    "subCategoryName": "16th Centaury",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jf",
    "name": "SPIRITUALITY",
    "subCategoryName": "17th Centaury etc.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jg",
    "name": "SPIRITUALITY",
    "subCategoryName": "Prayer/Meditations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jh",
    "name": "SPIRITUALITY",
    "subCategoryName": "Sp. Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ji",
    "name": "SPIRITUALITY",
    "subCategoryName": "Spiritual Masters",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jj",
    "name": "SPIRITUALITY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Jp",
    "name": "SPIRITUALITY",
    "subCategoryName": "..",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "K",
    "name": "SPIRITUALITY",
    "subCategoryName": "Thomas a Kempis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "L",
    "name": "SPIRITUALITY",
    "subCategoryName": "Common Life Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "M",
    "name": "SPIRITUALITY",
    "subCategoryName": "Carmelite Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ma",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Teresa of Avila",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Mb",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. John of the Cross",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Mc",
    "name": "SPIRITUALITY",
    "subCategoryName": "Carmelite Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Md",
    "name": "SPIRITUALITY",
    "subCategoryName": "Carmelite Spiritual Writers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Mt",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Teresa of Lisieux",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "N",
    "name": "SPIRITUALITY",
    "subCategoryName": "French School",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Na",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Francis de Sales",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nc",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Jeanne Francis de Chantal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nj",
    "name": "SPIRITUALITY",
    "subCategoryName": "Soc. Don Bosco",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nk",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. Vincent de Paul",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nm",
    "name": "SPIRITUALITY",
    "subCategoryName": "St. John Etudes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "No",
    "name": "SPIRITUALITY",
    "subCategoryName": "Oliver",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ns",
    "name": "SPIRITUALITY",
    "subCategoryName": "De la Salle",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nt",
    "name": "SPIRITUALITY",
    "subCategoryName": "De Berrulle",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nv",
    "name": "SPIRITUALITY",
    "subCategoryName": "Sulpitian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Nx",
    "name": "SPIRITUALITY",
    "subCategoryName": "De Montfort",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "R",
    "name": "SPIRITUALITY",
    "subCategoryName": "Resemptorist Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "T",
    "name": "SPIRITUALITY",
    "subCategoryName": "Other Schools",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ta",
    "name": "SPIRITUALITY",
    "subCategoryName": "Pauline Family",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Tc",
    "name": "SPIRITUALITY",
    "subCategoryName": "Claretian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Tf",
    "name": "SPIRITUALITY",
    "subCategoryName": "Foucauldian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Tg",
    "name": "SPIRITUALITY",
    "subCategoryName": "Batharram Fathers (SCJ)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "To",
    "name": "SPIRITUALITY",
    "subCategoryName": "Oratorians",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Tp",
    "name": "SPIRITUALITY",
    "subCategoryName": "Passionists",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ts",
    "name": "SPIRITUALITY",
    "subCategoryName": "Social Spirituality (Liberation)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Tt",
    "name": "SPIRITUALITY",
    "subCategoryName": "Theatines",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Ty",
    "name": "SPIRITUALITY",
    "subCategoryName": "Modern Studies (Pscho-Social)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "X",
    "name": "SPIRITUALITY",
    "subCategoryName": "Festschrift",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 306,
    "subCategoryID": "Z",
    "name": "SPIRITUALITY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "A",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Asceticism",
    "categoryDisplay": "Yes",
    "categoryDesc": "Ascetical theology explores the practices and disciplines that can help individuals develop a deeper relationship with God and achieve spiritual perfection. It emphasizes self-denial, prayer, meditation, and other spiritual practices as tools for overcoming sin and growing in virtue.",
    "categoryImage": require("./assets/images/booksCat/theology.png")
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ab",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Interior Life/Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "B",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Direction (Spiritual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Bs",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Scruples",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "C",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Vocations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Da",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Christine Life: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Dj",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Joy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Dr",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Reconciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ds",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Suffering",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Dv",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Death",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "E",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Religious Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ea",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Eb",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Sisters",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ec",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Brothers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ed",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Superiors",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ee",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Vows",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ee C",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Chastity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ee O",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Obedience",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ee P",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Poverty",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ef",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Eg",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Spiritual Instructions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Em",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Religious Life Today",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "En",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Spiritual Discernment",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Ep",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Religious & Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Es",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Secular Institutes/Soc. Of Apostolic Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "F",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Layman Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Fb",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Bibliography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "H",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Celibacy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "K",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "T",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Oriental Spirituality",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Tb",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 307,
    "subCategoryID": "Z",
    "name": "ASCETICAL THEOLOGY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "A",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Mysticism",
    "categoryDisplay": "Yes",
    "categoryDesc": "Prayer and mysticism explore the profound experience of communion with God or a higher power. They offer individuals a means of transcending the ordinary and experiencing a deeper sense of peace, love, and unity with the divine.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 308,
    "subCategoryID": "B",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Theology of Prayer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "C",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Mystical Phenomena",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "D",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "History of Mysticism (General/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Da",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Women Mystics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Ds",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "H",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Devotional Prayer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Ha",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Prayer Book/Hymnals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hb",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "God – Trinity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hc",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Christ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "He",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Eucharist",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hh",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Sacred Heart",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hp",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Passion",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hq",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Sacraments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hs",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Holy Spirit",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hs C",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Charismatic Movement/Revival Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hv",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "BVM Mary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hv C",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Marian cult",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hv L",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Legion of Mary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hv R",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Rosary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hv S",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Sodalities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Hz",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "M",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Meditations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Ma",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Mb",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Scriptural",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Mc",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Gospels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Ml",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Liturgical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Mp",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Apostleship of Prayer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 308,
    "subCategoryID": "Mv",
    "name": "PRAYER/MYSTICISM",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "A",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "General Works",
    "categoryDisplay": "Yes",
    "categoryDesc": "Priestly spirituality explores the unique calling and challenges faced by priests in their service to the Church. It emphasizes a deep commitment to prayer, study, and pastoral care, guiding priests towards a life of holiness and dedication to the spiritual needs of their flock.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 309,
    "subCategoryID": "Ad",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Pontifical /Episcopal Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "Ba",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Priestly Identity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "Bc",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Priestly Celibacy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "C",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Priestly Vocation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "D",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Diocesan Clergy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "Da",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Biographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "E",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Priestly Formation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "H",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Meditations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "M",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Eucharistic Celebration",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "P",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Breviary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 309,
    "subCategoryID": "T",
    "name": "PRIESTLY SPIRITUALITY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 310,
    "subCategoryID": "A",
    "name": "LAITY",
    "subCategoryName": "Catholic Action",
    "categoryDisplay": "Yes",
    "categoryDesc": "Laity, the lay members of the Church, play a vital role in the life and mission of the Christian community. They are called to live their faith in their daily lives, participating actively in the Church's activities and contributing to its growth and vitality.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 310,
    "subCategoryID": "B",
    "name": "LAITY",
    "subCategoryName": "Reports / Manuals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 310,
    "subCategoryID": "C",
    "name": "LAITY",
    "subCategoryName": "Students",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 310,
    "subCategoryID": "D",
    "name": "LAITY",
    "subCategoryName": "Christian Workers: Men",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 310,
    "subCategoryID": "E",
    "name": "LAITY",
    "subCategoryName": "Christian Workers: Women",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 310,
    "subCategoryID": "Z",
    "name": "LAITY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "A",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Hagiography, the writing of saints' lives, explores the inspiring stories of individuals who have lived exemplary Christian lives. These accounts often include miracles, visions, and other supernatural events, offering examples of faith, devotion, and heroic virtue.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 311,
    "subCategoryID": "B",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Hagiography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "C",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Martyrs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "D",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Stylites",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "M",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Saints: Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "S",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Saints: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 311,
    "subCategoryID": "Z",
    "name": "HAGIOGRAPHY",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 312,
    "subCategoryID": "A",
    "name": "BIOGRAPHIES – SECULAR",
    "subCategoryName": "Political (Collective / Individual)",
    "categoryDisplay": "Yes",
    "categoryDesc": "Biographies offer fascinating glimpses into the lives of remarkable individuals, exploring their achievements, challenges, and contributions to society. They inspire, motivate, and provide valuable lessons for readers of all ages.",
    "categoryImage": require("./assets/images/booksCat/cat4.png")
  },
  {
    "categoryID": 312,
    "subCategoryID": "C",
    "name": "BIOGRAPHIES – SECULAR",
    "subCategoryName": "Civil/Cultural (Collective/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 312,
    "subCategoryID": "E",
    "name": "BIOGRAPHIES – SECULAR",
    "subCategoryName": "Varia (Collective/Individual)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "A",
    "name": "HISTORY",
    "subCategoryName": "Introduction/ Methodology",
    "categoryDisplay": "Yes",
    "categoryDesc": "History chronicles the events, people, and cultures that have shaped our world. It provides insights into the past, helps us understand the present, and shapes our vision for the future.",
    "categoryImage": require("./assets/images/booksCat/cat3.png")
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ac",
    "name": "HISTORY",
    "subCategoryName": "Congresses",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "B",
    "name": "HISTORY",
    "subCategoryName": "History of Culture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "C",
    "name": "HISTORY",
    "subCategoryName": "General History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ca",
    "name": "HISTORY",
    "subCategoryName": "The Ancient World",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "D",
    "name": "HISTORY",
    "subCategoryName": "Historical Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "E",
    "name": "HISTORY",
    "subCategoryName": "Ancient Semitic World",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "F",
    "name": "HISTORY",
    "subCategoryName": "Ancient Greece",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Fr",
    "name": "HISTORY",
    "subCategoryName": "Ancient Rome",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "G",
    "name": "HISTORY",
    "subCategoryName": "Medieval Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "H",
    "name": "HISTORY",
    "subCategoryName": "Modern History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "I",
    "name": "HISTORY",
    "subCategoryName": "Contemporary History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "K",
    "name": "HISTORY",
    "subCategoryName": "England",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "L",
    "name": "HISTORY",
    "subCategoryName": "Ireland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "M",
    "name": "HISTORY",
    "subCategoryName": "Belgium",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ml",
    "name": "HISTORY",
    "subCategoryName": "Luxembourg",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Mn",
    "name": "HISTORY",
    "subCategoryName": "Netherlands",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "N",
    "name": "HISTORY",
    "subCategoryName": "France",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "P",
    "name": "HISTORY",
    "subCategoryName": "Central Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Pa",
    "name": "HISTORY",
    "subCategoryName": "Austria",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Pg",
    "name": "HISTORY",
    "subCategoryName": "Germany",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ph",
    "name": "HISTORY",
    "subCategoryName": "Hungary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Q",
    "name": "HISTORY",
    "subCategoryName": "Italy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Rp",
    "name": "HISTORY",
    "subCategoryName": "Portugal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Rs",
    "name": "HISTORY",
    "subCategoryName": "Spain",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Sa",
    "name": "HISTORY",
    "subCategoryName": "Eastern Europe",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Sc",
    "name": "HISTORY",
    "subCategoryName": "Russia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Sf",
    "name": "HISTORY",
    "subCategoryName": "Finland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Sp",
    "name": "HISTORY",
    "subCategoryName": "Poland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Sr",
    "name": "HISTORY",
    "subCategoryName": "USSR (excl. Russia)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ss",
    "name": "HISTORY",
    "subCategoryName": "Ukraine/Siberia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "T",
    "name": "HISTORY",
    "subCategoryName": "Balkans",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "U",
    "name": "HISTORY",
    "subCategoryName": "USA (N. American Continent)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Uc",
    "name": "HISTORY",
    "subCategoryName": "Canada",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "V",
    "name": "HISTORY",
    "subCategoryName": "Latin America (S. American Continent)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "W",
    "name": "HISTORY",
    "subCategoryName": "Middle East/West Asia/South Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Wj",
    "name": "HISTORY",
    "subCategoryName": "Israel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Ws",
    "name": "HISTORY",
    "subCategoryName": "Syria",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Wx",
    "name": "HISTORY",
    "subCategoryName": "Explorers",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Xa",
    "name": "HISTORY",
    "subCategoryName": "Africa: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Xb",
    "name": "HISTORY",
    "subCategoryName": "Algeria",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Xc",
    "name": "HISTORY",
    "subCategoryName": "Congo",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Xe",
    "name": "HISTORY",
    "subCategoryName": "Egypt",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Y",
    "name": "HISTORY",
    "subCategoryName": "Memoirs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 313,
    "subCategoryID": "Z",
    "name": "HISTORY",
    "subCategoryName": "Varia Historica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "A",
    "name": "SOCIOLOGY",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Sociology explores the relationships between individuals, groups, and institutions within society. It examines social structures, behaviors, and patterns, providing insights into the forces that shape our world.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 314,
    "subCategoryID": "B",
    "name": "SOCIOLOGY",
    "subCategoryName": "Christian Sociology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bc",
    "name": "SOCIOLOGY",
    "subCategoryName": "Christian Communities",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bc A",
    "name": "SOCIOLOGY",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bc D",
    "name": "SOCIOLOGY",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bc H",
    "name": "SOCIOLOGY",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bp",
    "name": "SOCIOLOGY",
    "subCategoryName": "Church Documents/Social Teaching",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bs",
    "name": "SOCIOLOGY",
    "subCategoryName": "Ordo Socialis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Bu",
    "name": "SOCIOLOGY",
    "subCategoryName": "Christianity and Justice Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "C",
    "name": "SOCIOLOGY",
    "subCategoryName": "Capitalism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Ca",
    "name": "SOCIOLOGY",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Ck",
    "name": "SOCIOLOGY",
    "subCategoryName": "Marketing/Management",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Cm",
    "name": "SOCIOLOGY",
    "subCategoryName": "Multinationals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "D",
    "name": "SOCIOLOGY",
    "subCategoryName": "Social Development/ World Development Report",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Da",
    "name": "SOCIOLOGY",
    "subCategoryName": "Third World",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Do",
    "name": "SOCIOLOGY",
    "subCategoryName": "Organization",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "E",
    "name": "SOCIOLOGY",
    "subCategoryName": "Social Services",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "F",
    "name": "SOCIOLOGY",
    "subCategoryName": "Economy & Sociology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "G",
    "name": "SOCIOLOGY",
    "subCategoryName": "Political Economy/Globalization",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "H",
    "name": "SOCIOLOGY",
    "subCategoryName": "Social Congress",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "I",
    "name": "SOCIOLOGY",
    "subCategoryName": "Labour",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "J",
    "name": "SOCIOLOGY",
    "subCategoryName": "Co-Operative",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "K",
    "name": "SOCIOLOGY",
    "subCategoryName": "Population",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Kc",
    "name": "SOCIOLOGY",
    "subCategoryName": "Women/Gender",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Ke",
    "name": "SOCIOLOGY",
    "subCategoryName": "Children",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Kh",
    "name": "SOCIOLOGY",
    "subCategoryName": "Food",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Ki",
    "name": "SOCIOLOGY",
    "subCategoryName": "Health",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Km",
    "name": "SOCIOLOGY",
    "subCategoryName": "Migrants",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "L",
    "name": "SOCIOLOGY",
    "subCategoryName": "Urban Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "M",
    "name": "SOCIOLOGY",
    "subCategoryName": "Environment/Ecology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "N",
    "name": "SOCIOLOGY",
    "subCategoryName": "Dictionaries/Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "P",
    "name": "SOCIOLOGY",
    "subCategoryName": "Marxism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Pc",
    "name": "SOCIOLOGY",
    "subCategoryName": "Christianity & Marxism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Pe",
    "name": "SOCIOLOGY",
    "subCategoryName": "Engels",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Pl",
    "name": "SOCIOLOGY",
    "subCategoryName": "Lenin/Stalin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Pm",
    "name": "SOCIOLOGY",
    "subCategoryName": "Marx",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Q",
    "name": "SOCIOLOGY",
    "subCategoryName": "Christian Social Studies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "R",
    "name": "SOCIOLOGY",
    "subCategoryName": "Socialism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Rp",
    "name": "SOCIOLOGY",
    "subCategoryName": "PCI",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "V",
    "name": "SOCIOLOGY",
    "subCategoryName": "Communism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Va",
    "name": "SOCIOLOGY",
    "subCategoryName": "Fascism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "X",
    "name": "SOCIOLOGY",
    "subCategoryName": "Varia Sociology (Xeroxed)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 314,
    "subCategoryID": "Z",
    "name": "SOCIOLOGY",
    "subCategoryName": "Varia Socialis",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "A",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "The International Labour Organization (ILO) is a United Nations agency dedicated to promoting social justice and improving working conditions worldwide. It sets international labor standards, facilitates dialogue between workers, employers, and governments, and provides technical assistance to countries in developing their labor policies.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 315,
    "subCategoryID": "C",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Studies and reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "D",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Studies and reports National Survey (N.S).",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "G",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Conferences/Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "I",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Conferences/Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "M",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Conferences/Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "P",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Record of Proceedings",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "R",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Minutes ILC/ILO",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "T",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Legislative Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "V",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Year Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 315,
    "subCategoryID": "Z",
    "name": "INTERNATIONAL LABOUR ORGANISATIONS (ILO)",
    "subCategoryName": "Technical Guides",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "A",
    "name": "EDUCATION",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Education is the foundation of personal and societal growth. It empowers individuals with knowledge, skills, and critical thinking abilities, enabling them to contribute meaningfully to their communities and realize their full potential.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 316,
    "subCategoryID": "B",
    "name": "EDUCATION",
    "subCategoryName": "School Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "C",
    "name": "EDUCATION",
    "subCategoryName": "Youth",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "Ca",
    "name": "EDUCATION",
    "subCategoryName": "Religious/ Value education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "D",
    "name": "EDUCATION",
    "subCategoryName": "De-schooling",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "E",
    "name": "EDUCATION",
    "subCategoryName": "Encyclopedia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "L",
    "name": "EDUCATION",
    "subCategoryName": "Leadership",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 316,
    "subCategoryID": "Z",
    "name": "EDUCATION",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "A",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Media",
    "categoryDisplay": "Yes",
    "categoryDesc": "Communication and media play a vital role in connecting people, sharing information, and shaping public opinion. They influence our understanding of the world, foster dialogue, and drive social change.",
    "categoryImage": require("./assets/images/booksCat/cd.png")
  },
  {
    "categoryID": 317,
    "subCategoryID": "B",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Christian Communication",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "C",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Cinema",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "G",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "General Communication",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "L",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Media Law",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "P",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Publishing",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "R",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Radio",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "T",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Theatre",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "V",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "Photography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 317,
    "subCategoryID": "Z",
    "name": "COMMUNICATION/ MEDIA",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "A",
    "name": "POLITICS",
    "subCategoryName": "Ancient Roman Law",
    "categoryDisplay": "Yes",
    "categoryDesc": "Politics involves the study and practice of government, leadership, and public policy. It shapes the way societies are organized, laws are enacted, and resources are allocated, influencing the lives of millions of people.",
    "categoryImage": require("./assets/images/booksCat/politics.png")
  },
  {
    "categoryID": 318,
    "subCategoryID": "B",
    "name": "POLITICS",
    "subCategoryName": "Jurisprudence",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "C",
    "name": "POLITICS",
    "subCategoryName": "Various Laws",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "D",
    "name": "POLITICS",
    "subCategoryName": "Government",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Dc",
    "name": "POLITICS",
    "subCategoryName": "Christian Principles of Politics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "E",
    "name": "POLITICS",
    "subCategoryName": "Constitutions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "F",
    "name": "POLITICS",
    "subCategoryName": "International Law",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Fd",
    "name": "POLITICS",
    "subCategoryName": "The Race Question",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "M",
    "name": "POLITICS",
    "subCategoryName": "International Organization",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Ma",
    "name": "POLITICS",
    "subCategoryName": "League of Nations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Mb",
    "name": "POLITICS",
    "subCategoryName": "UNO",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Mc",
    "name": "POLITICS",
    "subCategoryName": "Non-Aligned Countries (NAM)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "Md",
    "name": "POLITICS",
    "subCategoryName": "Disarmament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "P",
    "name": "POLITICS",
    "subCategoryName": "International Conciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "S",
    "name": "POLITICS",
    "subCategoryName": "Classics of International Law",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 318,
    "subCategoryID": "T",
    "name": "POLITICS",
    "subCategoryName": "Global Issues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "A",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Reference",
    "categoryDisplay": "Yes",
    "categoryDesc": "Non-Christian art and archaeology explore the rich and diverse cultural heritage of ancient societies, revealing their beliefs, practices, and artistic expressions. These artifacts provide valuable insights into the history, traditions, and achievements of non-Christian civilizations.",
    "categoryImage": require("./assets/images/booksCat/art.png")
  },
  {
    "categoryID": 319,
    "subCategoryID": "Ba",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Archeology: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Bb",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Semitic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Bc",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Greco-Roman",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Bv",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Various Others",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Da",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Art: Introduction",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Db",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "History of Art/Painting",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "E",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Architecture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "F",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Paleography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Ha",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Masters: Collective",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "Hb",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Masters: Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 319,
    "subCategoryID": "M",
    "name": "ART ARCHAELOGY (Non-Christian)",
    "subCategoryName": "Music",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "A",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "General",
    "categoryDisplay": "Yes",
    "categoryDesc": "Christian art and archaeology explore the visual representations of faith, beliefs, and practices within Christianity. These artifacts, ranging from paintings and sculptures to architecture and artifacts, offer a tangible connection to the past and provide insights into the development of Christian art and culture.",
    "categoryImage": require("./assets/images/booksCat/art.png")
  },
  {
    "categoryID": 320,
    "subCategoryID": "Aa",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Christian Archeology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Ab",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Christian Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "E",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Ea",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Epigraphy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Ec",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Catacombs",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Ee",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Epistolography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Em",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Manuscripts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "F",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Symbolism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "H",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Architecture (incl. Cathedrals)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Hb",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Byzantine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "I",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Iconography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Ic",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Christ",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Im",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Mary",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Is",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Sacraments",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Iv",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "Kb",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Masters Collective & Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 320,
    "subCategoryID": "V",
    "name": "ART  ARCHAELOGY (Christian)",
    "subCategoryName": "Varia?",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 510,
    "subCategoryID": "A",
    "name": "GENERAL LITERATURE",
    "subCategoryName": "Linguistics",
    "categoryDisplay": "Yes",
    "categoryDesc": "General literature encompasses a wide range of literary genres, including fiction, poetry, drama, and essays. It offers a rich tapestry of stories, ideas, and perspectives, exploring the human experience in all its complexity.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 510,
    "subCategoryID": "D",
    "name": "GENERAL LITERATURE",
    "subCategoryName": "Quotes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 510,
    "subCategoryID": "E",
    "name": "GENERAL LITERATURE",
    "subCategoryName": "Lit. History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 510,
    "subCategoryID": "F",
    "name": "GENERAL LITERATURE",
    "subCategoryName": "Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "A",
    "name": "GREEK",
    "subCategoryName": "Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "Greek, a classical language with a rich history, has significantly influenced Western culture, philosophy, and literature. It remains a valuable tool for understanding ancient texts, exploring cultural heritage, and appreciating the beauty of language.",
    "categoryImage": require("./assets/images/booksCat/lang.png")
  },
  {
    "categoryID": 515,
    "subCategoryID": "Ad",
    "name": "GREEK",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "B",
    "name": "GREEK",
    "subCategoryName": "Classics (Texts)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "C",
    "name": "GREEK",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "D",
    "name": "GREEK",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "P",
    "name": "GREEK",
    "subCategoryName": "Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 515,
    "subCategoryID": "V",
    "name": "GREEK",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "A",
    "name": "LATIN",
    "subCategoryName": "Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "Latin, a classical language that once dominated much of Europe, has had a profound impact on modern languages and cultures. It remains an important tool for studying history, literature, and law, preserving the rich heritage of the Roman Empire.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 516,
    "subCategoryID": "Ad",
    "name": "LATIN",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "Ae",
    "name": "LATIN",
    "subCategoryName": "Ecclesiastical Latin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "B",
    "name": "LATIN",
    "subCategoryName": "History / Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "D",
    "name": "LATIN",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "P",
    "name": "LATIN",
    "subCategoryName": "Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 516,
    "subCategoryID": "V",
    "name": "LATIN",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "Ai",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Italian Grammar/Dictionaries",
    "categoryDisplay": "Yes",
    "categoryDesc": "Romanic languages, such as Spanish, Italian, and Portuguese, descended from Latin and share many similarities in grammar and vocabulary. They have evolved over centuries, reflecting the cultural and historical influences of their respective regions, while retaining the core elements of their Latin heritage.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 517,
    "subCategoryID": "Ap",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Portuguese Grammar/Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "As",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Spanish Grammar/Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "B",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Lit. Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "C",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "Cl",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Italian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 517,
    "subCategoryID": "Cs",
    "name": "ROMANIC (Excluding  French)",
    "subCategoryName": "Spanish",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "A",
    "name": "FRENCH",
    "subCategoryName": "General Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "French, a Romance language descended from Latin, is widely spoken across the world. It is known for its elegance, beauty, and cultural significance, playing a prominent role in literature, art, and diplomacy.",
    "categoryImage": require("./assets/images/booksCat/lang.png")
  },
  {
    "categoryID": 519,
    "subCategoryID": "Ad",
    "name": "FRENCH",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "B",
    "name": "FRENCH",
    "subCategoryName": "Hist. of French Literature/Anthologies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "C",
    "name": "FRENCH",
    "subCategoryName": "Lit. Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "D",
    "name": "FRENCH",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "P",
    "name": "FRENCH",
    "subCategoryName": "Prose-Novels/Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 519,
    "subCategoryID": "V",
    "name": "FRENCH",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "A",
    "name": "ENGLISH",
    "subCategoryName": "Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "English, a global language, has a rich history and diverse influences. It is widely used in business, education, and communication, reflecting the interconnectedness of our world.",
    "categoryImage": require("./assets/images/booksCat/english.png")
  },
  {
    "categoryID": 521,
    "subCategoryID": "B",
    "name": "ENGLISH",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "C",
    "name": "ENGLISH",
    "subCategoryName": "Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "E",
    "name": "ENGLISH",
    "subCategoryName": "Anthologies from the Reader’s Digest",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "F",
    "name": "ENGLISH",
    "subCategoryName": "Essays",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "G",
    "name": "ENGLISH",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "H",
    "name": "ENGLISH",
    "subCategoryName": "Oratory",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "K",
    "name": "ENGLISH",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "M",
    "name": "ENGLISH",
    "subCategoryName": "Novels/Short Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 521,
    "subCategoryID": "Z",
    "name": "ENGLISH",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "A",
    "name": "GERMAN",
    "subCategoryName": "Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "German, a Germanic language with a long history, is widely spoken in Germany, Austria, and Switzerland. It is known for its precision, complexity, and rich literary tradition, contributing significantly to European culture and thought.",
    "categoryImage": require("./assets/images/booksCat/lang.png")
  },
  {
    "categoryID": 524,
    "subCategoryID": "B",
    "name": "GERMAN",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "C",
    "name": "GERMAN",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "Ca",
    "name": "GERMAN",
    "subCategoryName": "Anthologies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "Ch",
    "name": "GERMAN",
    "subCategoryName": "History of German Literature",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "D",
    "name": "GERMAN",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "P",
    "name": "GERMAN",
    "subCategoryName": "Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 524,
    "subCategoryID": "V",
    "name": "GERMAN",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "A",
    "name": "DUTCH",
    "subCategoryName": "General (Incl. Grammar)",
    "categoryDisplay": "Yes",
    "categoryDesc": "Dutch, a Germanic language spoken primarily in the Netherlands and Belgium, has a rich history and cultural significance. It is known for its precision, simplicity, and directness, reflecting the Dutch people's pragmatic and straightforward approach to life.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 525,
    "subCategoryID": "Ad",
    "name": "DUTCH",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "B",
    "name": "DUTCH",
    "subCategoryName": "History/Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "C",
    "name": "DUTCH",
    "subCategoryName": "Anthologies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "D",
    "name": "DUTCH",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "P",
    "name": "DUTCH",
    "subCategoryName": "Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 525,
    "subCategoryID": "V",
    "name": "DUTCH",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "D",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Danish",
    "categoryDisplay": "Yes",
    "categoryDesc": "Slavonic languages, such as Russian, Polish, and Czech, form a large language family with a rich history and diverse cultural heritage. They share linguistic similarities, reflecting their common Slavic roots, while also exhibiting unique features that reflect the individual cultures of their speakers.",
    "categoryImage": require("./assets/images/booksCat/lang.png")
  },
  {
    "categoryID": 526,
    "subCategoryID": "Dm",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Danish  Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Dp",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Danish Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "I",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Icelandic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Ip",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Icelandic Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "N",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Norwegian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Na",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Norwegian Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Nd",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Norwegian Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Np",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Norwegian Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "P",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Polish",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Pb",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Polish Short Stories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Pp",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Polis Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "S",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Swedish",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Sa",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Swedish Grammar",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Sp",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Swedish Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Y",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Yugoslavian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 526,
    "subCategoryID": "Yp",
    "name": "SLAVONIC LANGUAGES",
    "subCategoryName": "Yugoslavian Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 527,
    "subCategoryID": "A",
    "name": "RUSSIAN",
    "subCategoryName": "Grammar",
    "categoryDisplay": "Yes",
    "categoryDesc": "Russian, one of the world's major languages, has a rich literary tradition and cultural significance. It is widely spoken in Russia, Ukraine, and other parts of Eastern Europe, reflecting the vastness and diversity of its linguistic and cultural heritage.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 527,
    "subCategoryID": "C",
    "name": "RUSSIAN",
    "subCategoryName": "Criticism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 527,
    "subCategoryID": "D",
    "name": "RUSSIAN",
    "subCategoryName": "Drama",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 527,
    "subCategoryID": "P",
    "name": "RUSSIAN",
    "subCategoryName": "Prose",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 527,
    "subCategoryID": "V",
    "name": "RUSSIAN",
    "subCategoryName": "Verse",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "A",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Varia",
    "categoryDisplay": "Yes",
    "categoryDesc": "Beyond the major language families, there exists a vast array of diverse languages spoken around the world, each with its own unique history, culture, and beauty. These languages reflect the rich tapestry of human diversity and contribute to the vibrant cultural landscape of our planet.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 529,
    "subCategoryID": "C",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Chinese",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "E",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Esperanto",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "J",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Japanese",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "M",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Malay",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "S",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Swahili",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 529,
    "subCategoryID": "T",
    "name": "VARIOUS OTHER LANGUAGES",
    "subCategoryName": "Thai",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "A",
    "name": "SCIENCES",
    "subCategoryName": "Geography: General Introduction & Atlases + Maps",
    "categoryDisplay": "Yes",
    "categoryDesc": "Sciences, encompassing fields like physics, chemistry, biology, and astronomy, delve into the mysteries of the universe. They provide insights into the natural world, drive technological advancements, and shape our understanding of the cosmos.",
    "categoryImage": require("./assets/images/booksCat/cat2.png")
  },
  {
    "categoryID": 560,
    "subCategoryID": "Aa",
    "name": "SCIENCES",
    "subCategoryName": "Australia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ab",
    "name": "SCIENCES",
    "subCategoryName": "Belgium",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ad",
    "name": "SCIENCES",
    "subCategoryName": "Denmark",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ae",
    "name": "SCIENCES",
    "subCategoryName": "England",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Af",
    "name": "SCIENCES",
    "subCategoryName": "France",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ag",
    "name": "SCIENCES",
    "subCategoryName": "Germany",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ai",
    "name": "SCIENCES",
    "subCategoryName": "Italy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Aj",
    "name": "SCIENCES",
    "subCategoryName": "Japan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Am",
    "name": "SCIENCES",
    "subCategoryName": "Mauritius",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ap",
    "name": "SCIENCES",
    "subCategoryName": "South East Asia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ar",
    "name": "SCIENCES",
    "subCategoryName": "Romania",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "As",
    "name": "SCIENCES",
    "subCategoryName": "Spain",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Au",
    "name": "SCIENCES",
    "subCategoryName": "America",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Av",
    "name": "SCIENCES",
    "subCategoryName": "Greece",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Aw",
    "name": "SCIENCES",
    "subCategoryName": "Canada",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ay",
    "name": "SCIENCES",
    "subCategoryName": "Israel",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Az",
    "name": "SCIENCES",
    "subCategoryName": "Switzerland",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "B",
    "name": "SCIENCES",
    "subCategoryName": "Science",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ba",
    "name": "SCIENCES",
    "subCategoryName": "Encyclopedias/Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Bb",
    "name": "SCIENCES",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "C",
    "name": "SCIENCES",
    "subCategoryName": "Pure Science",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ca",
    "name": "SCIENCES",
    "subCategoryName": "Physics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Cc",
    "name": "SCIENCES",
    "subCategoryName": "Chemistry",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "D",
    "name": "SCIENCES",
    "subCategoryName": "Botany",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "F",
    "name": "SCIENCES",
    "subCategoryName": "Fauna",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "H",
    "name": "SCIENCES",
    "subCategoryName": "Anthropology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ha",
    "name": "SCIENCES",
    "subCategoryName": "Genetics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Hb",
    "name": "SCIENCES",
    "subCategoryName": "Physiology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Hc",
    "name": "SCIENCES",
    "subCategoryName": "Medicine",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "K",
    "name": "SCIENCES",
    "subCategoryName": "Astronomy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "L",
    "name": "SCIENCES",
    "subCategoryName": "Geology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "M",
    "name": "SCIENCES",
    "subCategoryName": "Mathematics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "O",
    "name": "SCIENCES",
    "subCategoryName": "Occult Sciences",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "S",
    "name": "SCIENCES",
    "subCategoryName": "Technology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Se",
    "name": "SCIENCES",
    "subCategoryName": "Electrical /Electronic/Computer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Sf",
    "name": "SCIENCES",
    "subCategoryName": "Mechanical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ss",
    "name": "SCIENCES",
    "subCategoryName": "Space",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "T",
    "name": "SCIENCES",
    "subCategoryName": "Miscellaneous",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Tg",
    "name": "SCIENCES",
    "subCategoryName": "Gardening",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Tp",
    "name": "SCIENCES",
    "subCategoryName": "Printing/Binding",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Ts",
    "name": "SCIENCES",
    "subCategoryName": "Sports/Games",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "Tt",
    "name": "SCIENCES",
    "subCategoryName": "Drawing/Typewriting/Shorthand",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 560,
    "subCategoryID": "V",
    "name": "SCIENCES",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "A",
    "name": "REFERENCE",
    "subCategoryName": "Encyclopedias",
    "categoryDisplay": "Yes",
    "categoryDesc": "Reference books, such as dictionaries, encyclopedias, and atlases, provide valuable information and resources. They are essential tools for learning, research, and understanding the world around us.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  },
  {
    "categoryID": 575,
    "subCategoryID": "B",
    "name": "REFERENCE",
    "subCategoryName": "Historica Geographical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "Bc",
    "name": "REFERENCE",
    "subCategoryName": "Christian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "C",
    "name": "REFERENCE",
    "subCategoryName": "Biographical",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "Cc",
    "name": "REFERENCE",
    "subCategoryName": "Christian",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "D",
    "name": "REFERENCE",
    "subCategoryName": "Directories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "L",
    "name": "REFERENCE",
    "subCategoryName": "Literary Directories",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "N",
    "name": "REFERENCE",
    "subCategoryName": "Book Catalogues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 575,
    "subCategoryID": "P",
    "name": "REFERENCE",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 576,
    "subCategoryID": "A",
    "name": "MTh THESISES",
    "subCategoryName": "-",
    "categoryDisplay": "Yes",
    "categoryDesc": "MTh theses are original research papers written by students pursuing a Master of Theology degree. They delve into specific theological topics, contributing to the ongoing conversation and advancing knowledge within the field.",
    "categoryImage": require("./assets/images/booksCat/thesis.png")
  },
  {
    "categoryID": 585,
    "subCategoryID": "A",
    "name": "CONDENSED DISKS",
    "subCategoryName": "ENCYCLOPEDIAS/DICTIONARIES- GENERAL & THEOLOGICAL",
    "categoryDisplay": "Yes",
    "categoryDesc": "Books on CD, also known as audiobooks, offer a convenient way to enjoy literature. They are typically read aloud by professional narrators, allowing listeners to immerse themselves in stories without the need for physical books.",
    "categoryImage": require("./assets/images/booksCat/cd.png")
  },
  {
    "categoryID": 585,
    "subCategoryID": "B",
    "name": "CONDENSED DISKS",
    "subCategoryName": "BIBLE",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Commentaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Be",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Encyclopedias",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bf",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biblical Persons",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Gospels Mt/Mk/Lk/Jn",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bh",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biblical Places",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bi",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biblical Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bj",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Judaism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bk",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Jesus",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bl",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Acts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Prophets",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bn",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Old Testament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bo",
    "name": "CONDENSED DISKS",
    "subCategoryName": "New Testament",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Bp",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Programme",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "C",
    "name": "CONDENSED DISKS",
    "subCategoryName": "TRADITION",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ca",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Cb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Councils",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Cc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Papal Documents",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "D",
    "name": "CONDENSED DISKS",
    "subCategoryName": "THEOLOGY",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Da",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General Theology – Theologians – Theology of Language – Contextual and Asian Theology – African and Black Theology – Liberation Theology – Feminist Theology – Oriental Theology – Process Theology.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Db",
    "name": "CONDENSED DISKS",
    "subCategoryName": "God – Belief in God – Secularism – Atheism – Trinity – The Word – Holy Sprit",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Dogmatic – Creation – Eco-Theology – Evolution – Angels – Devils –Evil – Anthropology – Women – Theology of History – Work – Supernatural – Universal Salvation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "De",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Eschatology – The Hereafter – Death – Heaven – Hell – Judgment – Purgatory – Resurrection",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Original sin",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dh",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Grace – Grace in Tradition – Divine Indwelling – Predestination – Virtues – Faith – Hope – Love – Moral Virtues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Di",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Christology – Person/Mystery of Christ – Incarnation – Redemption – Sacred Heart",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dl",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Mariology – Immaculate Conception – Assumption – Mother of God – Mediatrix – Mary & the Church – Marian Congress – Marian Sanctuaries/Cult",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dn",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Saints – Sacraments - -Symbols",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dp",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Christian Initiation -  Baptism – Confirmation – Eucharistic Congress – The Mass – Reconciliation – Sin – Sacrament of the sick -  Priesthood – Anglican Orders – Priestly People – Diaconate – Episcopate -  Lay/New Ministries – Women and Ordination - Marriage",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Dt",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Aquinas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "E",
    "name": "CONDENSED DISKS",
    "subCategoryName": "ECUMENISM",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "F",
    "name": "CONDENSED DISKS",
    "subCategoryName": "EDUCATION",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Fu",
    "name": "CONDENSED DISKS",
    "subCategoryName": "University",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "G",
    "name": "CONDENSED DISKS",
    "subCategoryName": "MORALS",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ga",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General Moral Theology – Biblical – non Catholic Moral Theology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Casus – History of Moral Theology – Conscience – Virtues",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Religious – Occult – Secret Sects",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gh",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Faith Therapy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gl",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Political Morality – War – Violence – Peace – Liberation- Development – Business Ethics – International Ethics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gj",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Justice – Human Rights",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gk",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Morality of Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gt",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Marriage in General – Sexual Ethics – Family Planning – Sexuality –Marriage Guidance – Marriage Preparation – Sexual Education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gu",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Pastoral Theology – Social Concern – Migration – Sacraments in General – Penance and Reconciliation",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gv",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Psychology in General – Clinical Psychology – Depth Psychology – Encounter Groups – Existential Psychology – Experimental Psychology – Humanistic Psychology – Personality – Children – Psychology of Morality – Counseling – Parapsychology – Pastoral psychology – Psychoanalytic Psychiatry – Religious Psychology – Social Psychology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Gy",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Medical Ethics – Ministry of Healing/Pastoral – Ethics of Healing",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "H",
    "name": "CONDENSED DISKS",
    "subCategoryName": "HISTORY",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ha",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Encyclopedias (Church History)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Hb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General Church History – Missions",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Hc.",
    "name": "CONDENSED DISKS",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Hd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "World History",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Hi",
    "name": "CONDENSED DISKS",
    "subCategoryName": "(Look under T for Indian History)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "I",
    "name": "CONDENSED DISKS",
    "subCategoryName": "INDICA",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ia",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ib",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Bibliographies",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ic",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Dictionaries",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ie",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Religions in general",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "If",
    "name": "CONDENSED DISKS",
    "subCategoryName": "-",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ih",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Hinduism/Buddhism",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ii",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Islam",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "J",
    "name": "CONDENSED DISKS",
    "subCategoryName": "DICTIONARIES ETC.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ja",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Dictionaries of Languages",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Jb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Culture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Je",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Atlases",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Jf",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Dictionaries & Statistics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "K",
    "name": "CONDENSED DISKS",
    "subCategoryName": "LANGUAGE",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ka",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Greek",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Kb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Hebrew",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "L",
    "name": "CONDENSED DISKS",
    "subCategoryName": "LITURGY & PRAYER",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "La",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Lb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Mass",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Lc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Rituals",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ld",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Prayer",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Lf",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Feasts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Lg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Sermon/Homilies/Preaching",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "M",
    "name": "CONDENSED DISKS",
    "subCategoryName": "MOVIES",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "N",
    "name": "CONDENSED DISKS",
    "subCategoryName": "NEWSPAPERS",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Na",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Hindustan Times",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Nb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "The Hindu",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Nc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Asian Age",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Nd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Times of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "O",
    "name": "CONDENSED DISKS",
    "subCategoryName": "SOCIO-POLITICAL",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Oa",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Socio-economic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Oc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Ecology – environmental",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Od",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Labour",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Oe",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Rural – Panchayatiraj – Cooperatives – Sarvodaya",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Of",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Population – Children – Women",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Og",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Indian Society – Caste – Dalits – Minorities – Tribes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Oh",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Constitution – Indian politics – polity",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Oi",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Press – Communalism – Secularism – Revolutionary movements – Social Movements",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ok",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Law – Landmark judgments – Legal education",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "P",
    "name": "CONDENSED DISKS",
    "subCategoryName": "CHRISTIANITY IN SOUTH ASIA",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pa",
    "name": "CONDENSED DISKS",
    "subCategoryName": "History: General – Catholic Church – Other Churches/Missions – religious Order/Movements – St. Thomas",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "South India: General – Kerala – Tamil Nadu – Andhra – Karnataka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "West India: Central – Goa – Maharashtra – Gujarat",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Central India: General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pe",
    "name": "CONDENSED DISKS",
    "subCategoryName": "East India: General – Bengal – Chotanagpur – Jharkhand",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pf",
    "name": "CONDENSED DISKS",
    "subCategoryName": "North India: General – the Mughal Mission – the Tibetan mission – Bihar – Delhi – Jammu-Kashmir – Punjab – Haryana – Rajasthan – U.P.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "North-East India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ph",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Sri Lanka",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pi",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Pakistan",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pj",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Nepal",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pk",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biographies – Collective – individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Church Organization: General – Bishop – CBCI – CCBI – NCCI – CRI – and religious – Particular Religious congregations – Secular institutes – Clergy & seminaries – laity – youth – ecumenical/protest organizations – Women",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pp",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Church Life – Seminar/Congress – Liturgy – Catechetics – Dialogue – Development – Health – Mission/Ministries – Press – Social Institutions – Theology/Bible.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pq",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Ancient missionary literature – theological/devotional texts – description of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pr",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Christian Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pv",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Apologetic",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pw",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Controversy",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Px",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Pamphlet series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Py",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Pz",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Bibliography – Hosten works",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Q",
    "name": "CONDENSED DISKS",
    "subCategoryName": "ART/ARCHITECTURE",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qa",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Indian Christian Mission",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Archeology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qf",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Guides (Tourist)",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qi",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Art",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qj",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Music",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ql",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Art Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qk",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Movies/Films",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Handicrafts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qp",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Painting",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qr",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Architecture",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Qz",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "R",
    "name": "CONDENSED DISKS",
    "subCategoryName": "INDIAN GEOGRAPHY/ETHNOGRAPHY",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ra",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Ancient Geography – Geographical Accounts",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Regional Geography – Local Geography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rd",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Gazetteers – Varia",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Re",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Social Action",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ri",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Ethnography – General – Central India – East India – North India – North-East India – South India – West India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rj",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Tribes – General – Bhils – Gonds – Garos – HO – Juangs –Kharias – Lepchas – Mundas – Nagas – Maria  - Santals – Uraons – Others",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rk",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Caste",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Census of India – Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rn",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Indian States Developmental Reports",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rs",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Fauna of British India – Indian Fauna – Indian Flora – Ecology",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Rz",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Varia Geological",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "S",
    "name": "CONDENSED DISKS",
    "subCategoryName": "SPIRITUALITY",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Sa",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Sb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Hagiography – Saints",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Si",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Jesuitica",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ss",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Religious Life",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "T",
    "name": "CONDENSED DISKS",
    "subCategoryName": "INDIAN HISTORY",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ta",
    "name": "CONDENSED DISKS",
    "subCategoryName": "General History of India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tb",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Cultural History – Indian Foreign Relations",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tc",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Records",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Td",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Ancient Indian History – General History – Biographical (Collective/Individual) – Special Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tg",
    "name": "CONDENSED DISKS",
    "subCategoryName": "History: Colonial Period – Dutch – French – Portuguese/Goa",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ti",
    "name": "CONDENSED DISKS",
    "subCategoryName": "History of British India – Early British India – Later British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tm",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Revolt of 1857",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "To",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Later British India",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tp",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Independence Movement",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tq",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Post Independence",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tr",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Regional Histories of North East India – Bengal – Bihar – Delhi – Gujarat – NSND – Kashmir – Maharashtra – Orissa – Punjab – Jharkhand – Chhattisgarh – Uttaranchal – Rajasthan – Uttar Pradesh",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ts",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Regional Histories of South India – Andhra Pradesh – Deccan – Kerala – Karnataka – Tamil Nadu",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tu",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biographical History and Series",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tv",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biographies of Foreigners Collective & Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tw",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Biographies of Indians Collective & Individual",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "TWn",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Jawhar Lal Nehru.",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tx",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Writings of Gandhi – Studies on Gandhi – Gandhi a Bibliography",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Tz",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Varia – Atlases – Antiquities – Festschrift – Various Topics",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "U",
    "name": "CONDENSED DISKS",
    "subCategoryName": "FILMS/MOVIES",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Z",
    "name": "CONDENSED DISKS",
    "subCategoryName": "JOURNALS AND  INDEXES",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Ze",
    "name": "CONDENSED DISKS",
    "subCategoryName": "E- Books",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },
  {
    "categoryID": 585,
    "subCategoryID": "Zz",
    "name": "CONDENSED DISKS",
    "subCategoryName": "Librarian’s Programmes",
    "categoryDisplay": "No",
    "categoryDesc": "-",
    "categoryImage": "-"
  },{
    "categoryID": "NA",
    "subCategoryID": "NA",
    "name": "OTHERS",
    "subCategoryName": "Not Available",
    "categoryDisplay": "Yes",
    "categoryDesc": "Other categories of books encompass a wide range of topics that don't fall neatly into more specific genres, offering diverse perspectives and knowledge on various subjects.",
    "categoryImage": require("./assets/images/booksCat/cat1.png")
  }]

export const author_group=[{
    id:64,
    count:10
},{
    id:65,
    count:14
},{
    id:66,
    count:29
},{
    id:67,
    count:32
},{
    id:68,
    count:32
},{
    id:69,
    count:32
},{
    id:70,
    count:32
},{
    id:71,
    count:32
},{
    id:72,
    count:32
},{
    id:73,
    count:32
},{
    id:74,
    count:32
},{
    id:75,
    count:32
},{
    id:76,
    count:32
},{
    id:77,
    count:32
},{
    id:78,
    count:32
},{
    id:79,
    count:32
},{
    id:80,
    count:32
},{
    id:81,
    count:32
},{
    id:82,
    count:32
},{
    id:83,
    count:32
},{
    id:84,
    count:32
},{
    id:85,
    count:32
},{
    id:86,
    count:32
},{
    id:87,
    count:32
},{
    id:88,
    count:32
},{
    id:89,
    count:32
},{
    id:90,
    count:32
}]

export const country_list=[
    {
        "name": "Afghanistan",
        "capital": "Kabul",
        "region": "Asia",
        "alpha2Code": "AF",
        "flag": "https://restcountries.eu/data/afg.svg"
    },
    {
        "name": "Åland Islands",
        "capital": "Mariehamn",
        "region": "Europe",
        "alpha2Code": "AX",
        "flag": "https://restcountries.eu/data/ala.svg"
    },
    {
        "name": "Albania",
        "capital": "Tirana",
        "region": "Europe",
        "alpha2Code": "AL",
        "flag": "https://restcountries.eu/data/alb.svg"
    },
    {
        "name": "Algeria",
        "capital": "Algiers",
        "region": "Africa",
        "alpha2Code": "DZ",
        "flag": "https://restcountries.eu/data/dza.svg"
    },
    {
        "name": "American Samoa",
        "capital": "Pago Pago",
        "region": "Oceania",
        "alpha2Code": "AS",
        "flag": "https://restcountries.eu/data/asm.svg"
    },
    {
        "name": "Andorra",
        "capital": "Andorra la Vella",
        "region": "Europe",
        "alpha2Code": "AD",
        "flag": "https://restcountries.eu/data/and.svg"
    },
    {
        "name": "Angola",
        "capital": "Luanda",
        "region": "Africa",
        "alpha2Code": "AO",
        "flag": "https://restcountries.eu/data/ago.svg"
    },
    {
        "name": "Anguilla",
        "capital": "The Valley",
        "region": "Americas",
        "alpha2Code": "AI",
        "flag": "https://restcountries.eu/data/aia.svg"
    },
    {
        "name": "Antarctica",
        "capital": "",
        "region": "Polar",
        "alpha2Code": "AQ",
        "flag": "https://restcountries.eu/data/ata.svg"
    },
    {
        "name": "Antigua and Barbuda",
        "capital": "Saint John's",
        "region": "Americas",
        "alpha2Code": "AG",
        "flag": "https://restcountries.eu/data/atg.svg"
    },
    {
        "name": "Argentina",
        "capital": "Buenos Aires",
        "region": "Americas",
        "alpha2Code": "AR",
        "flag": "https://restcountries.eu/data/arg.svg"
    },
    {
        "name": "Armenia",
        "capital": "Yerevan",
        "region": "Asia",
        "alpha2Code": "AM",
        "flag": "https://restcountries.eu/data/arm.svg"
    },
    {
        "name": "Aruba",
        "capital": "Oranjestad",
        "region": "Americas",
        "alpha2Code": "AW",
        "flag": "https://restcountries.eu/data/abw.svg"
    },
    {
        "name": "Australia",
        "capital": "Canberra",
        "region": "Oceania",
        "alpha2Code": "AU",
        "flag": "https://restcountries.eu/data/aus.svg"
    },
    {
        "name": "Austria",
        "capital": "Vienna",
        "region": "Europe",
        "alpha2Code": "AT",
        "flag": "https://restcountries.eu/data/aut.svg"
    },
    {
        "name": "Azerbaijan",
        "capital": "Baku",
        "region": "Asia",
        "alpha2Code": "AZ",
        "flag": "https://restcountries.eu/data/aze.svg"
    },
    {
        "name": "Bahamas",
        "capital": "Nassau",
        "region": "Americas",
        "alpha2Code": "BS",
        "flag": "https://restcountries.eu/data/bhs.svg"
    },
    {
        "name": "Bahrain",
        "capital": "Manama",
        "region": "Asia",
        "alpha2Code": "BH",
        "flag": "https://restcountries.eu/data/bhr.svg"
    },
    {
        "name": "Bangladesh",
        "capital": "Dhaka",
        "region": "Asia",
        "alpha2Code": "BD",
        "flag": "https://restcountries.eu/data/bgd.svg"
    },
    {
        "name": "Barbados",
        "capital": "Bridgetown",
        "region": "Americas",
        "alpha2Code": "BB",
        "flag": "https://restcountries.eu/data/brb.svg"
    },
    {
        "name": "Belarus",
        "capital": "Minsk",
        "region": "Europe",
        "alpha2Code": "BY",
        "flag": "https://restcountries.eu/data/blr.svg"
    },
    {
        "name": "Belgium",
        "capital": "Brussels",
        "region": "Europe",
        "alpha2Code": "BE",
        "flag": "https://restcountries.eu/data/bel.svg"
    },
    {
        "name": "Belize",
        "capital": "Belmopan",
        "region": "Americas",
        "alpha2Code": "BZ",
        "flag": "https://restcountries.eu/data/blz.svg"
    },
    {
        "name": "Benin",
        "capital": "Porto-Novo",
        "region": "Africa",
        "alpha2Code": "BJ",
        "flag": "https://restcountries.eu/data/ben.svg"
    },
    {
        "name": "Bermuda",
        "capital": "Hamilton",
        "region": "Americas",
        "alpha2Code": "BM",
        "flag": "https://restcountries.eu/data/bmu.svg"
    },
    {
        "name": "Bhutan",
        "capital": "Thimphu",
        "region": "Asia",
        "alpha2Code": "BT",
        "flag": "https://restcountries.eu/data/btn.svg"
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "capital": "Sucre",
        "region": "Americas",
        "alpha2Code": "BO",
        "flag": "https://restcountries.eu/data/bol.svg"
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "capital": "Kralendijk",
        "region": "Americas",
        "alpha2Code": "BQ",
        "flag": "https://restcountries.eu/data/bes.svg"
    },
    {
        "name": "Bosnia and Herzegovina",
        "capital": "Sarajevo",
        "region": "Europe",
        "alpha2Code": "BA",
        "flag": "https://restcountries.eu/data/bih.svg"
    },
    {
        "name": "Botswana",
        "capital": "Gaborone",
        "region": "Africa",
        "alpha2Code": "BW",
        "flag": "https://restcountries.eu/data/bwa.svg"
    },
    {
        "name": "Bouvet Island",
        "capital": "",
        "region": "",
        "alpha2Code": "BV",
        "flag": "https://restcountries.eu/data/bvt.svg"
    },
    {
        "name": "Brazil",
        "capital": "Brasília",
        "region": "Americas",
        "alpha2Code": "BR",
        "flag": "https://restcountries.eu/data/bra.svg"
    },
    {
        "name": "British Indian Ocean Territory",
        "capital": "Diego Garcia",
        "region": "Africa",
        "alpha2Code": "IO",
        "flag": "https://restcountries.eu/data/iot.svg"
    },
    {
        "name": "United States Minor Outlying Islands",
        "capital": "",
        "region": "Americas",
        "alpha2Code": "UM",
        "flag": "https://restcountries.eu/data/umi.svg"
    },
    {
        "name": "Virgin Islands (British)",
        "capital": "Road Town",
        "region": "Americas",
        "alpha2Code": "VG",
        "flag": "https://restcountries.eu/data/vgb.svg"
    },
    {
        "name": "Virgin Islands (U.S.)",
        "capital": "Charlotte Amalie",
        "region": "Americas",
        "alpha2Code": "VI",
        "flag": "https://restcountries.eu/data/vir.svg"
    },
    {
        "name": "Brunei Darussalam",
        "capital": "Bandar Seri Begawan",
        "region": "Asia",
        "alpha2Code": "BN",
        "flag": "https://restcountries.eu/data/brn.svg"
    },
    {
        "name": "Bulgaria",
        "capital": "Sofia",
        "region": "Europe",
        "alpha2Code": "BG",
        "flag": "https://restcountries.eu/data/bgr.svg"
    },
    {
        "name": "Burkina Faso",
        "capital": "Ouagadougou",
        "region": "Africa",
        "alpha2Code": "BF",
        "flag": "https://restcountries.eu/data/bfa.svg"
    },
    {
        "name": "Burundi",
        "capital": "Bujumbura",
        "region": "Africa",
        "alpha2Code": "BI",
        "flag": "https://restcountries.eu/data/bdi.svg"
    },
    {
        "name": "Cambodia",
        "capital": "Phnom Penh",
        "region": "Asia",
        "alpha2Code": "KH",
        "flag": "https://restcountries.eu/data/khm.svg"
    },
    {
        "name": "Cameroon",
        "capital": "Yaoundé",
        "region": "Africa",
        "alpha2Code": "CM",
        "flag": "https://restcountries.eu/data/cmr.svg"
    },
    {
        "name": "Canada",
        "capital": "Ottawa",
        "region": "Americas",
        "alpha2Code": "CA",
        "flag": "https://restcountries.eu/data/can.svg"
    },
    {
        "name": "Cabo Verde",
        "capital": "Praia",
        "region": "Africa",
        "alpha2Code": "CV",
        "flag": "https://restcountries.eu/data/cpv.svg"
    },
    {
        "name": "Cayman Islands",
        "capital": "George Town",
        "region": "Americas",
        "alpha2Code": "KY",
        "flag": "https://restcountries.eu/data/cym.svg"
    },
    {
        "name": "Central African Republic",
        "capital": "Bangui",
        "region": "Africa",
        "alpha2Code": "CF",
        "flag": "https://restcountries.eu/data/caf.svg"
    },
    {
        "name": "Chad",
        "capital": "N'Djamena",
        "region": "Africa",
        "alpha2Code": "TD",
        "flag": "https://restcountries.eu/data/tcd.svg"
    },
    {
        "name": "Chile",
        "capital": "Santiago",
        "region": "Americas",
        "alpha2Code": "CL",
        "flag": "https://restcountries.eu/data/chl.svg"
    },
    {
        "name": "China",
        "capital": "Beijing",
        "region": "Asia",
        "alpha2Code": "CN",
        "flag": "https://restcountries.eu/data/chn.svg"
    },
    {
        "name": "Christmas Island",
        "capital": "Flying Fish Cove",
        "region": "Oceania",
        "alpha2Code": "CX",
        "flag": "https://restcountries.eu/data/cxr.svg"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "capital": "West Island",
        "region": "Oceania",
        "alpha2Code": "CC",
        "flag": "https://restcountries.eu/data/cck.svg"
    },
    {
        "name": "Colombia",
        "capital": "Bogotá",
        "region": "Americas",
        "alpha2Code": "CO",
        "flag": "https://restcountries.eu/data/col.svg"
    },
    {
        "name": "Comoros",
        "capital": "Moroni",
        "region": "Africa",
        "alpha2Code": "KM",
        "flag": "https://restcountries.eu/data/com.svg"
    },
    {
        "name": "Congo",
        "capital": "Brazzaville",
        "region": "Africa",
        "alpha2Code": "CG",
        "flag": "https://restcountries.eu/data/cog.svg"
    },
    {
        "name": "Congo (Democratic Republic of the)",
        "capital": "Kinshasa",
        "region": "Africa",
        "alpha2Code": "CD",
        "flag": "https://restcountries.eu/data/cod.svg"
    },
    {
        "name": "Cook Islands",
        "capital": "Avarua",
        "region": "Oceania",
        "alpha2Code": "CK",
        "flag": "https://restcountries.eu/data/cok.svg"
    },
    {
        "name": "Costa Rica",
        "capital": "San José",
        "region": "Americas",
        "alpha2Code": "CR",
        "flag": "https://restcountries.eu/data/cri.svg"
    },
    {
        "name": "Croatia",
        "capital": "Zagreb",
        "region": "Europe",
        "alpha2Code": "HR",
        "flag": "https://restcountries.eu/data/hrv.svg"
    },
    {
        "name": "Cuba",
        "capital": "Havana",
        "region": "Americas",
        "alpha2Code": "CU",
        "flag": "https://restcountries.eu/data/cub.svg"
    },
    {
        "name": "Curaçao",
        "capital": "Willemstad",
        "region": "Americas",
        "alpha2Code": "CW",
        "flag": "https://restcountries.eu/data/cuw.svg"
    },
    {
        "name": "Cyprus",
        "capital": "Nicosia",
        "region": "Europe",
        "alpha2Code": "CY",
        "flag": "https://restcountries.eu/data/cyp.svg"
    },
    {
        "name": "Czech Republic",
        "capital": "Prague",
        "region": "Europe",
        "alpha2Code": "CZ",
        "flag": "https://restcountries.eu/data/cze.svg"
    },
    {
        "name": "Denmark",
        "capital": "Copenhagen",
        "region": "Europe",
        "alpha2Code": "DK",
        "flag": "https://restcountries.eu/data/dnk.svg"
    },
    {
        "name": "Djibouti",
        "capital": "Djibouti",
        "region": "Africa",
        "alpha2Code": "DJ",
        "flag": "https://restcountries.eu/data/dji.svg"
    },
    {
        "name": "Dominica",
        "capital": "Roseau",
        "region": "Americas",
        "alpha2Code": "DM",
        "flag": "https://restcountries.eu/data/dma.svg"
    },
    {
        "name": "Dominican Republic",
        "capital": "Santo Domingo",
        "region": "Americas",
        "alpha2Code": "DO",
        "flag": "https://restcountries.eu/data/dom.svg"
    },
    {
        "name": "Ecuador",
        "capital": "Quito",
        "region": "Americas",
        "alpha2Code": "EC",
        "flag": "https://restcountries.eu/data/ecu.svg"
    },
    {
        "name": "Egypt",
        "capital": "Cairo",
        "region": "Africa",
        "alpha2Code": "EG",
        "flag": "https://restcountries.eu/data/egy.svg"
    },
    {
        "name": "El Salvador",
        "capital": "San Salvador",
        "region": "Americas",
        "alpha2Code": "SV",
        "flag": "https://restcountries.eu/data/slv.svg"
    },
    {
        "name": "Equatorial Guinea",
        "capital": "Malabo",
        "region": "Africa",
        "alpha2Code": "GQ",
        "flag": "https://restcountries.eu/data/gnq.svg"
    },
    {
        "name": "Eritrea",
        "capital": "Asmara",
        "region": "Africa",
        "alpha2Code": "ER",
        "flag": "https://restcountries.eu/data/eri.svg"
    },
    {
        "name": "Estonia",
        "capital": "Tallinn",
        "region": "Europe",
        "alpha2Code": "EE",
        "flag": "https://restcountries.eu/data/est.svg"
    },
    {
        "name": "Ethiopia",
        "capital": "Addis Ababa",
        "region": "Africa",
        "alpha2Code": "ET",
        "flag": "https://restcountries.eu/data/eth.svg"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "capital": "Stanley",
        "region": "Americas",
        "alpha2Code": "FK",
        "flag": "https://restcountries.eu/data/flk.svg"
    },
    {
        "name": "Faroe Islands",
        "capital": "Tórshavn",
        "region": "Europe",
        "alpha2Code": "FO",
        "flag": "https://restcountries.eu/data/fro.svg"
    },
    {
        "name": "Fiji",
        "capital": "Suva",
        "region": "Oceania",
        "alpha2Code": "FJ",
        "flag": "https://restcountries.eu/data/fji.svg"
    },
    {
        "name": "Finland",
        "capital": "Helsinki",
        "region": "Europe",
        "alpha2Code": "FI",
        "flag": "https://restcountries.eu/data/fin.svg"
    },
    {
        "name": "France",
        "capital": "Paris",
        "region": "Europe",
        "alpha2Code": "FR",
        "flag": "https://restcountries.eu/data/fra.svg"
    },
    {
        "name": "French Guiana",
        "capital": "Cayenne",
        "region": "Americas",
        "alpha2Code": "GF",
        "flag": "https://restcountries.eu/data/guf.svg"
    },
    {
        "name": "French Polynesia",
        "capital": "Papeetē",
        "region": "Oceania",
        "alpha2Code": "PF",
        "flag": "https://restcountries.eu/data/pyf.svg"
    },
    {
        "name": "French Southern Territories",
        "capital": "Port-aux-Français",
        "region": "Africa",
        "alpha2Code": "TF",
        "flag": "https://restcountries.eu/data/atf.svg"
    },
    {
        "name": "Gabon",
        "capital": "Libreville",
        "region": "Africa",
        "alpha2Code": "GA",
        "flag": "https://restcountries.eu/data/gab.svg"
    },
    {
        "name": "Gambia",
        "capital": "Banjul",
        "region": "Africa",
        "alpha2Code": "GM",
        "flag": "https://restcountries.eu/data/gmb.svg"
    },
    {
        "name": "Georgia",
        "capital": "Tbilisi",
        "region": "Asia",
        "alpha2Code": "GE",
        "flag": "https://restcountries.eu/data/geo.svg"
    },
    {
        "name": "Germany",
        "capital": "Berlin",
        "region": "Europe",
        "alpha2Code": "DE",
        "flag": "https://restcountries.eu/data/deu.svg"
    },
    {
        "name": "Ghana",
        "capital": "Accra",
        "region": "Africa",
        "alpha2Code": "GH",
        "flag": "https://restcountries.eu/data/gha.svg"
    },
    {
        "name": "Gibraltar",
        "capital": "Gibraltar",
        "region": "Europe",
        "alpha2Code": "GI",
        "flag": "https://restcountries.eu/data/gib.svg"
    },
    {
        "name": "Greece",
        "capital": "Athens",
        "region": "Europe",
        "alpha2Code": "GR",
        "flag": "https://restcountries.eu/data/grc.svg"
    },
    {
        "name": "Greenland",
        "capital": "Nuuk",
        "region": "Americas",
        "alpha2Code": "GL",
        "flag": "https://restcountries.eu/data/grl.svg"
    },
    {
        "name": "Grenada",
        "capital": "St. George's",
        "region": "Americas",
        "alpha2Code": "GD",
        "flag": "https://restcountries.eu/data/grd.svg"
    },
    {
        "name": "Guadeloupe",
        "capital": "Basse-Terre",
        "region": "Americas",
        "alpha2Code": "GP",
        "flag": "https://restcountries.eu/data/glp.svg"
    },
    {
        "name": "Guam",
        "capital": "Hagåtña",
        "region": "Oceania",
        "alpha2Code": "GU",
        "flag": "https://restcountries.eu/data/gum.svg"
    },
    {
        "name": "Guatemala",
        "capital": "Guatemala City",
        "region": "Americas",
        "alpha2Code": "GT",
        "flag": "https://restcountries.eu/data/gtm.svg"
    },
    {
        "name": "Guernsey",
        "capital": "St. Peter Port",
        "region": "Europe",
        "alpha2Code": "GG",
        "flag": "https://restcountries.eu/data/ggy.svg"
    },
    {
        "name": "Guinea",
        "capital": "Conakry",
        "region": "Africa",
        "alpha2Code": "GN",
        "flag": "https://restcountries.eu/data/gin.svg"
    },
    {
        "name": "Guinea-Bissau",
        "capital": "Bissau",
        "region": "Africa",
        "alpha2Code": "GW",
        "flag": "https://restcountries.eu/data/gnb.svg"
    },
    {
        "name": "Guyana",
        "capital": "Georgetown",
        "region": "Americas",
        "alpha2Code": "GY",
        "flag": "https://restcountries.eu/data/guy.svg"
    },
    {
        "name": "Haiti",
        "capital": "Port-au-Prince",
        "region": "Americas",
        "alpha2Code": "HT",
        "flag": "https://restcountries.eu/data/hti.svg"
    },
    {
        "name": "Heard Island and McDonald Islands",
        "capital": "",
        "region": "",
        "alpha2Code": "HM",
        "flag": "https://restcountries.eu/data/hmd.svg"
    },
    {
        "name": "Holy See",
        "capital": "Rome",
        "region": "Europe",
        "alpha2Code": "VA",
        "flag": "https://restcountries.eu/data/vat.svg"
    },
    {
        "name": "Honduras",
        "capital": "Tegucigalpa",
        "region": "Americas",
        "alpha2Code": "HN",
        "flag": "https://restcountries.eu/data/hnd.svg"
    },
    {
        "name": "Hong Kong",
        "capital": "City of Victoria",
        "region": "Asia",
        "alpha2Code": "HK",
        "flag": "https://restcountries.eu/data/hkg.svg"
    },
    {
        "name": "Hungary",
        "capital": "Budapest",
        "region": "Europe",
        "alpha2Code": "HU",
        "flag": "https://restcountries.eu/data/hun.svg"
    },
    {
        "name": "Iceland",
        "capital": "Reykjavík",
        "region": "Europe",
        "alpha2Code": "IS",
        "flag": "https://restcountries.eu/data/isl.svg"
    },
    {
        "name": "India",
        "capital": "New Delhi",
        "region": "Asia",
        "alpha2Code": "IN",
        "flag": "https://restcountries.eu/data/ind.svg"
    },
    {
        "name": "Indonesia",
        "capital": "Jakarta",
        "region": "Asia",
        "alpha2Code": "ID",
        "flag": "https://restcountries.eu/data/idn.svg"
    },
    {
        "name": "Côte d'Ivoire",
        "capital": "Yamoussoukro",
        "region": "Africa",
        "alpha2Code": "CI",
        "flag": "https://restcountries.eu/data/civ.svg"
    },
    {
        "name": "Iran (Islamic Republic of)",
        "capital": "Tehran",
        "region": "Asia",
        "alpha2Code": "IR",
        "flag": "https://restcountries.eu/data/irn.svg"
    },
    {
        "name": "Iraq",
        "capital": "Baghdad",
        "region": "Asia",
        "alpha2Code": "IQ",
        "flag": "https://restcountries.eu/data/irq.svg"
    },
    {
        "name": "Ireland",
        "capital": "Dublin",
        "region": "Europe",
        "alpha2Code": "IE",
        "flag": "https://restcountries.eu/data/irl.svg"
    },
    {
        "name": "Isle of Man",
        "capital": "Douglas",
        "region": "Europe",
        "alpha2Code": "IM",
        "flag": "https://restcountries.eu/data/imn.svg"
    },
    {
        "name": "Israel",
        "capital": "Jerusalem",
        "region": "Asia",
        "alpha2Code": "IL",
        "flag": "https://restcountries.eu/data/isr.svg"
    },
    {
        "name": "Italy",
        "capital": "Rome",
        "region": "Europe",
        "alpha2Code": "IT",
        "flag": "https://restcountries.eu/data/ita.svg"
    },
    {
        "name": "Jamaica",
        "capital": "Kingston",
        "region": "Americas",
        "alpha2Code": "JM",
        "flag": "https://restcountries.eu/data/jam.svg"
    },
    {
        "name": "Japan",
        "capital": "Tokyo",
        "region": "Asia",
        "alpha2Code": "JP",
        "flag": "https://restcountries.eu/data/jpn.svg"
    },
    {
        "name": "Jersey",
        "capital": "Saint Helier",
        "region": "Europe",
        "alpha2Code": "JE",
        "flag": "https://restcountries.eu/data/jey.svg"
    },
    {
        "name": "Jordan",
        "capital": "Amman",
        "region": "Asia",
        "alpha2Code": "JO",
        "flag": "https://restcountries.eu/data/jor.svg"
    },
    {
        "name": "Kazakhstan",
        "capital": "Astana",
        "region": "Asia",
        "alpha2Code": "KZ",
        "flag": "https://restcountries.eu/data/kaz.svg"
    },
    {
        "name": "Kenya",
        "capital": "Nairobi",
        "region": "Africa",
        "alpha2Code": "KE",
        "flag": "https://restcountries.eu/data/ken.svg"
    },
    {
        "name": "Kiribati",
        "capital": "South Tarawa",
        "region": "Oceania",
        "alpha2Code": "KI",
        "flag": "https://restcountries.eu/data/kir.svg"
    },
    {
        "name": "Kuwait",
        "capital": "Kuwait City",
        "region": "Asia",
        "alpha2Code": "KW",
        "flag": "https://restcountries.eu/data/kwt.svg"
    },
    {
        "name": "Kyrgyzstan",
        "capital": "Bishkek",
        "region": "Asia",
        "alpha2Code": "KG",
        "flag": "https://restcountries.eu/data/kgz.svg"
    },
    {
        "name": "Lao People's Democratic Republic",
        "capital": "Vientiane",
        "region": "Asia",
        "alpha2Code": "LA",
        "flag": "https://restcountries.eu/data/lao.svg"
    },
    {
        "name": "Latvia",
        "capital": "Riga",
        "region": "Europe",
        "alpha2Code": "LV",
        "flag": "https://restcountries.eu/data/lva.svg"
    },
    {
        "name": "Lebanon",
        "capital": "Beirut",
        "region": "Asia",
        "alpha2Code": "LB",
        "flag": "https://restcountries.eu/data/lbn.svg"
    },
    {
        "name": "Lesotho",
        "capital": "Maseru",
        "region": "Africa",
        "alpha2Code": "LS",
        "flag": "https://restcountries.eu/data/lso.svg"
    },
    {
        "name": "Liberia",
        "capital": "Monrovia",
        "region": "Africa",
        "alpha2Code": "LR",
        "flag": "https://restcountries.eu/data/lbr.svg"
    },
    {
        "name": "Libya",
        "capital": "Tripoli",
        "region": "Africa",
        "alpha2Code": "LY",
        "flag": "https://restcountries.eu/data/lby.svg"
    },
    {
        "name": "Liechtenstein",
        "capital": "Vaduz",
        "region": "Europe",
        "alpha2Code": "LI",
        "flag": "https://restcountries.eu/data/lie.svg"
    },
    {
        "name": "Lithuania",
        "capital": "Vilnius",
        "region": "Europe",
        "alpha2Code": "LT",
        "flag": "https://restcountries.eu/data/ltu.svg"
    },
    {
        "name": "Luxembourg",
        "capital": "Luxembourg",
        "region": "Europe",
        "alpha2Code": "LU",
        "flag": "https://restcountries.eu/data/lux.svg"
    },
    {
        "name": "Macao",
        "capital": "",
        "region": "Asia",
        "alpha2Code": "MO",
        "flag": "https://restcountries.eu/data/mac.svg"
    },
    {
        "name": "Macedonia (the former Yugoslav Republic of)",
        "capital": "Skopje",
        "region": "Europe",
        "alpha2Code": "MK",
        "flag": "https://restcountries.eu/data/mkd.svg"
    },
    {
        "name": "Madagascar",
        "capital": "Antananarivo",
        "region": "Africa",
        "alpha2Code": "MG",
        "flag": "https://restcountries.eu/data/mdg.svg"
    },
    {
        "name": "Malawi",
        "capital": "Lilongwe",
        "region": "Africa",
        "alpha2Code": "MW",
        "flag": "https://restcountries.eu/data/mwi.svg"
    },
    {
        "name": "Malaysia",
        "capital": "Kuala Lumpur",
        "region": "Asia",
        "alpha2Code": "MY",
        "flag": "https://restcountries.eu/data/mys.svg"
    },
    {
        "name": "Maldives",
        "capital": "Malé",
        "region": "Asia",
        "alpha2Code": "MV",
        "flag": "https://restcountries.eu/data/mdv.svg"
    },
    {
        "name": "Mali",
        "capital": "Bamako",
        "region": "Africa",
        "alpha2Code": "ML",
        "flag": "https://restcountries.eu/data/mli.svg"
    },
    {
        "name": "Malta",
        "capital": "Valletta",
        "region": "Europe",
        "alpha2Code": "MT",
        "flag": "https://restcountries.eu/data/mlt.svg"
    },
    {
        "name": "Marshall Islands",
        "capital": "Majuro",
        "region": "Oceania",
        "alpha2Code": "MH",
        "flag": "https://restcountries.eu/data/mhl.svg"
    },
    {
        "name": "Martinique",
        "capital": "Fort-de-France",
        "region": "Americas",
        "alpha2Code": "MQ",
        "flag": "https://restcountries.eu/data/mtq.svg"
    },
    {
        "name": "Mauritania",
        "capital": "Nouakchott",
        "region": "Africa",
        "alpha2Code": "MR",
        "flag": "https://restcountries.eu/data/mrt.svg"
    },
    {
        "name": "Mauritius",
        "capital": "Port Louis",
        "region": "Africa",
        "alpha2Code": "MU",
        "flag": "https://restcountries.eu/data/mus.svg"
    },
    {
        "name": "Mayotte",
        "capital": "Mamoudzou",
        "region": "Africa",
        "alpha2Code": "YT",
        "flag": "https://restcountries.eu/data/myt.svg"
    },
    {
        "name": "Mexico",
        "capital": "Mexico City",
        "region": "Americas",
        "alpha2Code": "MX",
        "flag": "https://restcountries.eu/data/mex.svg"
    },
    {
        "name": "Micronesia (Federated States of)",
        "capital": "Palikir",
        "region": "Oceania",
        "alpha2Code": "FM",
        "flag": "https://restcountries.eu/data/fsm.svg"
    },
    {
        "name": "Moldova (Republic of)",
        "capital": "Chișinău",
        "region": "Europe",
        "alpha2Code": "MD",
        "flag": "https://restcountries.eu/data/mda.svg"
    },
    {
        "name": "Monaco",
        "capital": "Monaco",
        "region": "Europe",
        "alpha2Code": "MC",
        "flag": "https://restcountries.eu/data/mco.svg"
    },
    {
        "name": "Mongolia",
        "capital": "Ulan Bator",
        "region": "Asia",
        "alpha2Code": "MN",
        "flag": "https://restcountries.eu/data/mng.svg"
    },
    {
        "name": "Montenegro",
        "capital": "Podgorica",
        "region": "Europe",
        "alpha2Code": "ME",
        "flag": "https://restcountries.eu/data/mne.svg"
    },
    {
        "name": "Montserrat",
        "capital": "Plymouth",
        "region": "Americas",
        "alpha2Code": "MS",
        "flag": "https://restcountries.eu/data/msr.svg"
    },
    {
        "name": "Morocco",
        "capital": "Rabat",
        "region": "Africa",
        "alpha2Code": "MA",
        "flag": "https://restcountries.eu/data/mar.svg"
    },
    {
        "name": "Mozambique",
        "capital": "Maputo",
        "region": "Africa",
        "alpha2Code": "MZ",
        "flag": "https://restcountries.eu/data/moz.svg"
    },
    {
        "name": "Myanmar",
        "capital": "Naypyidaw",
        "region": "Asia",
        "alpha2Code": "MM",
        "flag": "https://restcountries.eu/data/mmr.svg"
    },
    {
        "name": "Namibia",
        "capital": "Windhoek",
        "region": "Africa",
        "alpha2Code": "NA",
        "flag": "https://restcountries.eu/data/nam.svg"
    },
    {
        "name": "Nauru",
        "capital": "Yaren",
        "region": "Oceania",
        "alpha2Code": "NR",
        "flag": "https://restcountries.eu/data/nru.svg"
    },
    {
        "name": "Nepal",
        "capital": "Kathmandu",
        "region": "Asia",
        "alpha2Code": "NP",
        "flag": "https://restcountries.eu/data/npl.svg"
    },
    {
        "name": "Netherlands",
        "capital": "Amsterdam",
        "region": "Europe",
        "alpha2Code": "NL",
        "flag": "https://restcountries.eu/data/nld.svg"
    },
    {
        "name": "New Caledonia",
        "capital": "Nouméa",
        "region": "Oceania",
        "alpha2Code": "NC",
        "flag": "https://restcountries.eu/data/ncl.svg"
    },
    {
        "name": "New Zealand",
        "capital": "Wellington",
        "region": "Oceania",
        "alpha2Code": "NZ",
        "flag": "https://restcountries.eu/data/nzl.svg"
    },
    {
        "name": "Nicaragua",
        "capital": "Managua",
        "region": "Americas",
        "alpha2Code": "NI",
        "flag": "https://restcountries.eu/data/nic.svg"
    },
    {
        "name": "Niger",
        "capital": "Niamey",
        "region": "Africa",
        "alpha2Code": "NE",
        "flag": "https://restcountries.eu/data/ner.svg"
    },
    {
        "name": "Nigeria",
        "capital": "Abuja",
        "region": "Africa",
        "alpha2Code": "NG",
        "flag": "https://restcountries.eu/data/nga.svg"
    },
    {
        "name": "Niue",
        "capital": "Alofi",
        "region": "Oceania",
        "alpha2Code": "NU",
        "flag": "https://restcountries.eu/data/niu.svg"
    },
    {
        "name": "Norfolk Island",
        "capital": "Kingston",
        "region": "Oceania",
        "alpha2Code": "NF",
        "flag": "https://restcountries.eu/data/nfk.svg"
    },
    {
        "name": "Korea (Democratic People's Republic of)",
        "capital": "Pyongyang",
        "region": "Asia",
        "alpha2Code": "KP",
        "flag": "https://restcountries.eu/data/prk.svg"
    },
    {
        "name": "Northern Mariana Islands",
        "capital": "Saipan",
        "region": "Oceania",
        "alpha2Code": "MP",
        "flag": "https://restcountries.eu/data/mnp.svg"
    },
    {
        "name": "Norway",
        "capital": "Oslo",
        "region": "Europe",
        "alpha2Code": "NO",
        "flag": "https://restcountries.eu/data/nor.svg"
    },
    {
        "name": "Oman",
        "capital": "Muscat",
        "region": "Asia",
        "alpha2Code": "OM",
        "flag": "https://restcountries.eu/data/omn.svg"
    },
    {
        "name": "Pakistan",
        "capital": "Islamabad",
        "region": "Asia",
        "alpha2Code": "PK",
        "flag": "https://restcountries.eu/data/pak.svg"
    },
    {
        "name": "Palau",
        "capital": "Ngerulmud",
        "region": "Oceania",
        "alpha2Code": "PW",
        "flag": "https://restcountries.eu/data/plw.svg"
    },
    {
        "name": "Palestine, State of",
        "capital": "Ramallah",
        "region": "Asia",
        "alpha2Code": "PS",
        "flag": "https://restcountries.eu/data/pse.svg"
    },
    {
        "name": "Panama",
        "capital": "Panama City",
        "region": "Americas",
        "alpha2Code": "PA",
        "flag": "https://restcountries.eu/data/pan.svg"
    },
    {
        "name": "Papua New Guinea",
        "capital": "Port Moresby",
        "region": "Oceania",
        "alpha2Code": "PG",
        "flag": "https://restcountries.eu/data/png.svg"
    },
    {
        "name": "Paraguay",
        "capital": "Asunción",
        "region": "Americas",
        "alpha2Code": "PY",
        "flag": "https://restcountries.eu/data/pry.svg"
    },
    {
        "name": "Peru",
        "capital": "Lima",
        "region": "Americas",
        "alpha2Code": "PE",
        "flag": "https://restcountries.eu/data/per.svg"
    },
    {
        "name": "Philippines",
        "capital": "Manila",
        "region": "Asia",
        "alpha2Code": "PH",
        "flag": "https://restcountries.eu/data/phl.svg"
    },
    {
        "name": "Pitcairn",
        "capital": "Adamstown",
        "region": "Oceania",
        "alpha2Code": "PN",
        "flag": "https://restcountries.eu/data/pcn.svg"
    },
    {
        "name": "Poland",
        "capital": "Warsaw",
        "region": "Europe",
        "alpha2Code": "PL",
        "flag": "https://restcountries.eu/data/pol.svg"
    },
    {
        "name": "Portugal",
        "capital": "Lisbon",
        "region": "Europe",
        "alpha2Code": "PT",
        "flag": "https://restcountries.eu/data/prt.svg"
    },
    {
        "name": "Puerto Rico",
        "capital": "San Juan",
        "region": "Americas",
        "alpha2Code": "PR",
        "flag": "https://restcountries.eu/data/pri.svg"
    },
    {
        "name": "Qatar",
        "capital": "Doha",
        "region": "Asia",
        "alpha2Code": "QA",
        "flag": "https://restcountries.eu/data/qat.svg"
    },
    {
        "name": "Republic of Kosovo",
        "capital": "Pristina",
        "region": "Europe",
        "alpha2Code": "XK",
        "flag": "https://restcountries.eu/data/kos.svg"
    },
    {
        "name": "Réunion",
        "capital": "Saint-Denis",
        "region": "Africa",
        "alpha2Code": "RE",
        "flag": "https://restcountries.eu/data/reu.svg"
    },
    {
        "name": "Romania",
        "capital": "Bucharest",
        "region": "Europe",
        "alpha2Code": "RO",
        "flag": "https://restcountries.eu/data/rou.svg"
    },
    {
        "name": "Russian Federation",
        "capital": "Moscow",
        "region": "Europe",
        "alpha2Code": "RU",
        "flag": "https://restcountries.eu/data/rus.svg"
    },
    {
        "name": "Rwanda",
        "capital": "Kigali",
        "region": "Africa",
        "alpha2Code": "RW",
        "flag": "https://restcountries.eu/data/rwa.svg"
    },
    {
        "name": "Saint Barthélemy",
        "capital": "Gustavia",
        "region": "Americas",
        "alpha2Code": "BL",
        "flag": "https://restcountries.eu/data/blm.svg"
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "capital": "Jamestown",
        "region": "Africa",
        "alpha2Code": "SH",
        "flag": "https://restcountries.eu/data/shn.svg"
    },
    {
        "name": "Saint Kitts and Nevis",
        "capital": "Basseterre",
        "region": "Americas",
        "alpha2Code": "KN",
        "flag": "https://restcountries.eu/data/kna.svg"
    },
    {
        "name": "Saint Lucia",
        "capital": "Castries",
        "region": "Americas",
        "alpha2Code": "LC",
        "flag": "https://restcountries.eu/data/lca.svg"
    },
    {
        "name": "Saint Martin (French part)",
        "capital": "Marigot",
        "region": "Americas",
        "alpha2Code": "MF",
        "flag": "https://restcountries.eu/data/maf.svg"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "capital": "Saint-Pierre",
        "region": "Americas",
        "alpha2Code": "PM",
        "flag": "https://restcountries.eu/data/spm.svg"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "capital": "Kingstown",
        "region": "Americas",
        "alpha2Code": "VC",
        "flag": "https://restcountries.eu/data/vct.svg"
    },
    {
        "name": "Samoa",
        "capital": "Apia",
        "region": "Oceania",
        "alpha2Code": "WS",
        "flag": "https://restcountries.eu/data/wsm.svg"
    },
    {
        "name": "San Marino",
        "capital": "City of San Marino",
        "region": "Europe",
        "alpha2Code": "SM",
        "flag": "https://restcountries.eu/data/smr.svg"
    },
    {
        "name": "Sao Tome and Principe",
        "capital": "São Tomé",
        "region": "Africa",
        "alpha2Code": "ST",
        "flag": "https://restcountries.eu/data/stp.svg"
    },
    {
        "name": "Saudi Arabia",
        "capital": "Riyadh",
        "region": "Asia",
        "alpha2Code": "SA",
        "flag": "https://restcountries.eu/data/sau.svg"
    },
    {
        "name": "Senegal",
        "capital": "Dakar",
        "region": "Africa",
        "alpha2Code": "SN",
        "flag": "https://restcountries.eu/data/sen.svg"
    },
    {
        "name": "Serbia",
        "capital": "Belgrade",
        "region": "Europe",
        "alpha2Code": "RS",
        "flag": "https://restcountries.eu/data/srb.svg"
    },
    {
        "name": "Seychelles",
        "capital": "Victoria",
        "region": "Africa",
        "alpha2Code": "SC",
        "flag": "https://restcountries.eu/data/syc.svg"
    },
    {
        "name": "Sierra Leone",
        "capital": "Freetown",
        "region": "Africa",
        "alpha2Code": "SL",
        "flag": "https://restcountries.eu/data/sle.svg"
    },
    {
        "name": "Singapore",
        "capital": "Singapore",
        "region": "Asia",
        "alpha2Code": "SG",
        "flag": "https://restcountries.eu/data/sgp.svg"
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "capital": "Philipsburg",
        "region": "Americas",
        "alpha2Code": "SX",
        "flag": "https://restcountries.eu/data/sxm.svg"
    },
    {
        "name": "Slovakia",
        "capital": "Bratislava",
        "region": "Europe",
        "alpha2Code": "SK",
        "flag": "https://restcountries.eu/data/svk.svg"
    },
    {
        "name": "Slovenia",
        "capital": "Ljubljana",
        "region": "Europe",
        "alpha2Code": "SI",
        "flag": "https://restcountries.eu/data/svn.svg"
    },
    {
        "name": "Solomon Islands",
        "capital": "Honiara",
        "region": "Oceania",
        "alpha2Code": "SB",
        "flag": "https://restcountries.eu/data/slb.svg"
    },
    {
        "name": "Somalia",
        "capital": "Mogadishu",
        "region": "Africa",
        "alpha2Code": "SO",
        "flag": "https://restcountries.eu/data/som.svg"
    },
    {
        "name": "South Africa",
        "capital": "Pretoria",
        "region": "Africa",
        "alpha2Code": "ZA",
        "flag": "https://restcountries.eu/data/zaf.svg"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "capital": "King Edward Point",
        "region": "Americas",
        "alpha2Code": "GS",
        "flag": "https://restcountries.eu/data/sgs.svg"
    },
    {
        "name": "Korea (Republic of)",
        "capital": "Seoul",
        "region": "Asia",
        "alpha2Code": "KR",
        "flag": "https://restcountries.eu/data/kor.svg"
    },
    {
        "name": "South Sudan",
        "capital": "Juba",
        "region": "Africa",
        "alpha2Code": "SS",
        "flag": "https://restcountries.eu/data/ssd.svg"
    },
    {
        "name": "Spain",
        "capital": "Madrid",
        "region": "Europe",
        "alpha2Code": "ES",
        "flag": "https://restcountries.eu/data/esp.svg"
    },
    {
        "name": "Sri Lanka",
        "capital": "Colombo",
        "region": "Asia",
        "alpha2Code": "LK",
        "flag": "https://restcountries.eu/data/lka.svg"
    },
    {
        "name": "Sudan",
        "capital": "Khartoum",
        "region": "Africa",
        "alpha2Code": "SD",
        "flag": "https://restcountries.eu/data/sdn.svg"
    },
    {
        "name": "Suriname",
        "capital": "Paramaribo",
        "region": "Americas",
        "alpha2Code": "SR",
        "flag": "https://restcountries.eu/data/sur.svg"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "capital": "Longyearbyen",
        "region": "Europe",
        "alpha2Code": "SJ",
        "flag": "https://restcountries.eu/data/sjm.svg"
    },
    {
        "name": "Swaziland",
        "capital": "Lobamba",
        "region": "Africa",
        "alpha2Code": "SZ",
        "flag": "https://restcountries.eu/data/swz.svg"
    },
    {
        "name": "Sweden",
        "capital": "Stockholm",
        "region": "Europe",
        "alpha2Code": "SE",
        "flag": "https://restcountries.eu/data/swe.svg"
    },
    {
        "name": "Switzerland",
        "capital": "Bern",
        "region": "Europe",
        "alpha2Code": "CH",
        "flag": "https://restcountries.eu/data/che.svg"
    },
    {
        "name": "Syrian Arab Republic",
        "capital": "Damascus",
        "region": "Asia",
        "alpha2Code": "SY",
        "flag": "https://restcountries.eu/data/syr.svg"
    },
    {
        "name": "Taiwan",
        "capital": "Taipei",
        "region": "Asia",
        "alpha2Code": "TW",
        "flag": "https://restcountries.eu/data/twn.svg"
    },
    {
        "name": "Tajikistan",
        "capital": "Dushanbe",
        "region": "Asia",
        "alpha2Code": "TJ",
        "flag": "https://restcountries.eu/data/tjk.svg"
    },
    {
        "name": "Tanzania, United Republic of",
        "capital": "Dodoma",
        "region": "Africa",
        "alpha2Code": "TZ",
        "flag": "https://restcountries.eu/data/tza.svg"
    },
    {
        "name": "Thailand",
        "capital": "Bangkok",
        "region": "Asia",
        "alpha2Code": "TH",
        "flag": "https://restcountries.eu/data/tha.svg"
    },
    {
        "name": "Timor-Leste",
        "capital": "Dili",
        "region": "Asia",
        "alpha2Code": "TL",
        "flag": "https://restcountries.eu/data/tls.svg"
    },
    {
        "name": "Togo",
        "capital": "Lomé",
        "region": "Africa",
        "alpha2Code": "TG",
        "flag": "https://restcountries.eu/data/tgo.svg"
    },
    {
        "name": "Tokelau",
        "capital": "Fakaofo",
        "region": "Oceania",
        "alpha2Code": "TK",
        "flag": "https://restcountries.eu/data/tkl.svg"
    },
    {
        "name": "Tonga",
        "capital": "Nuku'alofa",
        "region": "Oceania",
        "alpha2Code": "TO",
        "flag": "https://restcountries.eu/data/ton.svg"
    },
    {
        "name": "Trinidad and Tobago",
        "capital": "Port of Spain",
        "region": "Americas",
        "alpha2Code": "TT",
        "flag": "https://restcountries.eu/data/tto.svg"
    },
    {
        "name": "Tunisia",
        "capital": "Tunis",
        "region": "Africa",
        "alpha2Code": "TN",
        "flag": "https://restcountries.eu/data/tun.svg"
    },
    {
        "name": "Turkey",
        "capital": "Ankara",
        "region": "Asia",
        "alpha2Code": "TR",
        "flag": "https://restcountries.eu/data/tur.svg"
    },
    {
        "name": "Turkmenistan",
        "capital": "Ashgabat",
        "region": "Asia",
        "alpha2Code": "TM",
        "flag": "https://restcountries.eu/data/tkm.svg"
    },
    {
        "name": "Turks and Caicos Islands",
        "capital": "Cockburn Town",
        "region": "Americas",
        "alpha2Code": "TC",
        "flag": "https://restcountries.eu/data/tca.svg"
    },
    {
        "name": "Tuvalu",
        "capital": "Funafuti",
        "region": "Oceania",
        "alpha2Code": "TV",
        "flag": "https://restcountries.eu/data/tuv.svg"
    },
    {
        "name": "Uganda",
        "capital": "Kampala",
        "region": "Africa",
        "alpha2Code": "UG",
        "flag": "https://restcountries.eu/data/uga.svg"
    },
    {
        "name": "Ukraine",
        "capital": "Kiev",
        "region": "Europe",
        "alpha2Code": "UA",
        "flag": "https://restcountries.eu/data/ukr.svg"
    },
    {
        "name": "United Arab Emirates",
        "capital": "Abu Dhabi",
        "region": "Asia",
        "alpha2Code": "AE",
        "flag": "https://restcountries.eu/data/are.svg"
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "capital": "London",
        "region": "Europe",
        "alpha2Code": "GB",
        "flag": "https://restcountries.eu/data/gbr.svg"
    },
    {
        "name": "United States of America",
        "capital": "Washington, D.C.",
        "region": "Americas",
        "alpha2Code": "US",
        "flag": "https://restcountries.eu/data/usa.svg"
    },
    {
        "name": "Uruguay",
        "capital": "Montevideo",
        "region": "Americas",
        "alpha2Code": "UY",
        "flag": "https://restcountries.eu/data/ury.svg"
    },
    {
        "name": "Uzbekistan",
        "capital": "Tashkent",
        "region": "Asia",
        "alpha2Code": "UZ",
        "flag": "https://restcountries.eu/data/uzb.svg"
    },
    {
        "name": "Vanuatu",
        "capital": "Port Vila",
        "region": "Oceania",
        "alpha2Code": "VU",
        "flag": "https://restcountries.eu/data/vut.svg"
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "capital": "Caracas",
        "region": "Americas",
        "alpha2Code": "VE",
        "flag": "https://restcountries.eu/data/ven.svg"
    },
    {
        "name": "Viet Nam",
        "capital": "Hanoi",
        "region": "Asia",
        "alpha2Code": "VN",
        "flag": "https://restcountries.eu/data/vnm.svg"
    },
    {
        "name": "Wallis and Futuna",
        "capital": "Mata-Utu",
        "region": "Oceania",
        "alpha2Code": "WF",
        "flag": "https://restcountries.eu/data/wlf.svg"
    },
    {
        "name": "Western Sahara",
        "capital": "El Aaiún",
        "region": "Africa",
        "alpha2Code": "EH",
        "flag": "https://restcountries.eu/data/esh.svg"
    },
    {
        "name": "Yemen",
        "capital": "Sana'a",
        "region": "Asia",
        "alpha2Code": "YE",
        "flag": "https://restcountries.eu/data/yem.svg"
    },
    {
        "name": "Zambia",
        "capital": "Lusaka",
        "region": "Africa",
        "alpha2Code": "ZM",
        "flag": "https://restcountries.eu/data/zmb.svg"
    },
    {
        "name": "Zimbabwe",
        "capital": "Harare",
        "region": "Africa",
        "alpha2Code": "ZW",
        "flag": "https://restcountries.eu/data/zwe.svg"
    }
]

