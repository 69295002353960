import React from 'react';

export default function ReportHeader(props) {
    const {handleToggleReport, reportName } = props;

  return (
    <div>
    <div className="search-book">
    <section className=" layout_padding">
          <form id="contact">
            <div className='row'>
               <fieldset className='search-book-form d-inline-flex p-2'>
                 <button type="submit" className={reportName==="Issue"?'toggle_report_orginal':'toggle_report_selected'} onClick={event=>handleToggleReport(event, "Issue")}>Issue Report</button>
                 <button type="submit" id="form-submit" className={reportName==="Books"?'toggle_report_orginal':'toggle_report_selected'} onClick={event=>handleToggleReport(event, "Books")}>Books Report</button>
                 <button type="submit" id="form-submit" className={reportName==="Custom"?'toggle_report_orginal':'toggle_report_selected'} onClick={event=>handleToggleReport(event, "Custom")}>Custom Report</button>
               </fieldset>
            </div>
          </form>
          </section>
      </div>
</div>
  )
}
