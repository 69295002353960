import React, { useEffect } from 'react';
import logo from '../../assets/logo/VJLOGO.png';
import './Preloader.css';
export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      {show}
      <img className="loader-element animate__animated animate__jackInTheBox" src={logo} height={180} />
    </div>
  );
};
