import React from "react";
import { Chart } from "react-google-charts";


export function ChartsGoogle(props) {
    const {regYearCount, titleMsg}=props;

    const options = {
        title: titleMsg,
        isStacked: true,
        height: 300,
        legend: { position: "none" },
        vAxis: { minValue: 0 },
      };

  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={regYearCount}
      options={options}
    />
  );
}
