import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import Preloader from '../../components/Preloader/Preloader';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
const capsRegx=/[A-Z ]+/;


export default function ForgotPassword() {
  const { validateLogin, loginValidated, setLoginValidated, loggedUser } = useContext(ProductContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername]= useState('');
  const [confirmationCode, setConfirmationCode]= useState('');
  const [newPassword, setNewPassword] = useState('');
  const [nextPage, setNextPage]=useState(false);  
  const [loaded, setLoaded] = useState(true);
  const [newpasswordFocused, setNewPasswordFocused] = useState(false);
  const [newpasswordValidity, setNewPasswordValidity] = useState({
    minChar: null,
    number: null,
    specialChar: null,
    capsChar:null
  });

  useEffect(() => {
    if(!loggedUser.isAuthenticated && !loginValidated){
      setLoginValidated(true);
      validateLogin();
    }
  }, [])

  const handleSendAuthEmail=async(event)=>{
    event.preventDefault();
    try {
      if(username===""){
        enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
      }else{
      setLoaded(false);
      const output = await resetPassword({ username });
      //console.log("Output : ", output);
      enqueueSnackbar("Password Reset initiated!",{variant:'info'});
      handleResetPasswordNextSteps(output);
      setLoaded(true);
      }
    } catch (error) {
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
    }  
   // setNextPage(true);
  }

  function handleResetPasswordNextSteps(output) {
    setLoaded(false);
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        enqueueSnackbar(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,{variant:'info'});
        setNextPage(true);
        setLoaded(true);
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        enqueueSnackbar('Password Reset Successful!',{variant:'info'});
        setLoaded(true);
        break;
    }
  
  }

  const handleConfirmResetPassword =async(event)=>{
    event.preventDefault();
    try {
      if(confirmationCode===""){
        enqueueSnackbar("Enter your code sent to your registered eamil address!",{variant:'info'});
      }else if(newPassword===""){
        enqueueSnackbar("Enter your new Password!",{variant:'info'});
      }else if(username===""){
        enqueueSnackbar("Enter your registered eamil address!",{variant:'info'});
      }else{
      setLoaded(false);
      await confirmResetPassword({ username, confirmationCode, newPassword })
      .then((res)=>{
        enqueueSnackbar("Your Password has been changed successfully! Login back with your New Password!",{variant:'info'});
      setLoaded(true);
      navigate("/auth/login");
      })
      .catch((error)=>{
        setLoaded(true);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      })
    }
    } catch (error) {
      setLoaded(true);
      enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    }  
  }

  const onNewChangePassword = password => {
    setNewPassword(password);
    
    setNewPasswordValidity({
        minChar: password.length >= 8 ? true : false,
        number: isNumberRegx.test(password) ? true : false,
        specialChar: specialCharacterRegx.test(password) ? true : false,
        capsChar:capsRegx.test(password)?true:false
    });
    };
    

  return (
    <div>
          <Helmet>
      <title>Forgot Password - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Forgot Password - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Forgot Password - Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

        {!loaded &&  <Preloader show={loaded ? false : true} /> }
        <div className='p-3'>
        <div className='container card justify-content-center' style={{borderRadius:"30px"}}>

         <div className="text-center p-3">
          <div className='text-center'>
          <h1>Forgot Password</h1>
          </div>
                    <div className="contact-form text-center m-3">
                    <div className="row d-flex justify-content-center p-2">
                    <div className="col-lg-2 col-md-12 col-sm-12">
                          <label>Username</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="email" type="email" className="form-control input-sm" id="email" placeholder="Email Address" required 
                                onChange={event=>setUsername(event.target.value)}
                                value={username}
                                disabled={nextPage}/> 
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center p-2">

                            {nextPage ?<><div className="row">
                              <div className="col-lg-2 col-md-12 col-sm-12">
                              <label>Enter Code</label>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="password" type="password" className="form-control" id="password" placeholder="Password" required 
              onChange={event=>setConfirmationCode(event.target.value)}
              value={confirmationCode}/>
                              </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-2 col-md-12 col-sm-12">
                                <label>New Password</label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                <input name="password" type="password" className="form-control" id="password" placeholder="Password" required 
                onFocus={() => setNewPasswordFocused(true)}
                onChange={e => onNewChangePassword(e.target.value)}            
              value={newPassword}/>
                              </div>
                            </div></>:null}
                            {newpasswordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={newpasswordValidity}
                                    />
                                )}
                                                          </div>
                            <div className="row d-flex justify-content-center ">
                            <div>
                              <Link to="/login">
                              Sign In ?
                              </Link>
                            </div>

                            {!nextPage?<div className="col-lg-12  text-center">
                              <fieldset>
                                <button type="submit" className="btn-box mt-3" onClick={event=>handleSendAuthEmail(event)}>Generate Code</button>
                              </fieldset>
                            </div>:<div className="col-lg-12  text-center">
                              <fieldset>
                                <button type="submit" className="btn-box mt-3" onClick={event=>handleConfirmResetPassword(event)}>Reset Password</button>
                              </fieldset>
                            </div>}
                            </div>

                    </div>
                </div>
            </div>
    </div>
    </div>
  )
}
