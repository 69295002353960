import React from 'react';
import { Table } from 'react-bootstrap';

export default function SearchTableView(props) {
    const {searchResult, handleShow} = props;

  return (
    <div className='p-4'>
        {searchResult.length>0 ? <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Accession No</th>
          <th>Book Title</th>
          <th>Author</th>
          <th>Classification No</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {searchResult.map((item,index)=>{return(
        <tr onClick={event=>handleShow(event, item)}>
          <td>{index+1}</td>
          <td>{item.accessionNo}</td>
          <td>{item.bookTitle}</td>
          <td>{item.bookAuthor}</td>
          <td>{item.bookClassificationNo}</td>
          <td>{item.bookStatus}</td>
        </tr>
      )})}
      </tbody>
      </Table>:null}
    </div>
  )
}
