import React from 'react';
import { Table } from 'react-bootstrap';

export default function OffcanvasTemplate(props) {
    const { selectedBook } = props;
  return (
    <div>
        <div className='text-primary container' >
            <Table striped bordered hover>
        <tr>
            <th>Book Name</th>
            <td><i className='fa fa-book'></i></td>
            <td>
        <p className="features-title">{selectedBook.bookTitle}</p>
        </td></tr>
        <tr>
            <th>Author Name</th>
            <td><i className="fa fa-user" aria-hidden="true"></i></td>
            <td>            <h6 className='text-start'> {selectedBook.bookAuthor}</h6>
        </td></tr>
        <tr>
            <th>Book Status</th>
            <td>{selectedBook.bookStatus==="Active"?<i className="fa fa-check" aria-hidden="true"></i>:selectedBook.bookStatus==="Issued"?
                    <i class="fa fa-hourglass-half" aria-hidden="true"></i>:null}</td>
            <td>{selectedBook.bookStatus}</td>
        </tr>
        <tr>
            <th>Book Classification</th>
            <td><i class="fa fa-object-group" aria-hidden="true"></i><i class="fa fa-th-large" aria-hidden="true"></i>
</td>
            <td>{selectedBook.bookClassificationNo}</td>
        </tr><tr>
            <th>Publisher Name </th>
            <td><i className="fa fa-print" aria-hidden="true"></i></td>
            <td>{selectedBook.bookPublisherName}</td>
        </tr><tr>
            <th>Publishing Year</th>
            <td><i class="fa fa-calendar" aria-hidden="true"></i></td>
            <td>{selectedBook.bookPublishYear}</td>
        </tr>
    </Table>            
    </div>
    </div>
  )
}
