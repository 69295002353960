import React,{useEffect, useState} from 'react';
import { Col, Row, Form, Card } from 'react-bootstrap';
import { books_category, author_details, publisher_details, country_list } from '../../ProData';
import AutoComplete from '../../components/AutoComplete';
import './BookForm.css';

export default function BooksForm(props) {
    const {bookDetails, setBookDetails, viewOnly, formType, dispMsg} = props;

    useEffect(()=>{
     // return()=>setBookDetails({...bookDetails, autoGenerate:true});      
    },[])

    const handleSelection=(e)=>{
     // e.preventDefault();
      setBookDetails({...bookDetails, autoGenerate:!bookDetails.autoGenerate});
    }

    const handleBookCategoryChange=(event)=>{
      const tbookno=books_category.filter((item)=>item.categoryName===event.target.value);
      //console.log(tbookno);
      setBookDetails({...bookDetails, 
        bookCategory:event.target.value,
        bookClassificationNo:tbookno[0].classficationNo
      });

    }

    const handleCategorySelection=(inputValue)=>{
      var filteredValue="OTHERS";
      console.log("input value :", inputValue);
      if(inputValue.length>=3){
        const tinputValue= parseInt(inputValue.substring(0,3),10);
        console.log("3 char : ", tinputValue);
        const tempFilter= books_category.filter((item)=>item.categoryID===tinputValue && item.categoryDisplay==="Yes");
        console.log("filter : ", tempFilter);
        if(tempFilter.length>0){
          filteredValue=tempFilter[0].name;
        }
      }
      console.log("filteredvalue :", filteredValue);
      setBookDetails({...bookDetails, bookCategory:filteredValue,
        bookClassificationNo:inputValue
      });
    }

    
  return (
    <Card style={{padding:"0px"}}>
      <Card.Body>
        <Form>
        {/*<div style={{backgroundColor:"#063547", borderRadius:"25px", color:"white", textAlign:"center"}}>
          <h1>{dispMsg}</h1>
          </div>*/}
          {formType==="New" || formType==="Clone" ? <Row>
          <Col lg={6} className="mb-3">
              <Form.Group id="accessionNo">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Input Accession No"
                isValid={bookDetails.autoGenerate}
                onClick={(e) => handleSelection(e) }
              />
              </Form.Group>
            </Col>
          {!bookDetails.autoGenerate && <Col lg={6} className="mb-3">
              <Form.Group id="bookNo">
                <Form.Label>Accession No</Form.Label>
                <Form.Control required type="text"  
                value={bookDetails.accessionNo}  disabled={formType==="Edit" || formType==="View"?true:false}
                onChange={event=>{setBookDetails({...bookDetails, accessionNo:(event.target.value).toUpperCase()})}}
               />
              </Form.Group>
            </Col>}
            
          </Row>:null}
        <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="bookName">
                <Form.Label>Book Name</Form.Label>
                <Form.Control required type="text"  
                value={bookDetails.bookTitle}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookTitle:(event.target.value).toUpperCase()})}}
               />
              </Form.Group>
            </Col>
            <Col lg={6} className="mb-3">
              <Form.Group id="authorName">
                <Form.Label>Author Name</Form.Label>
                        {/*<div className="search-bar-container">
                            <AutoComplete
                                data={author_details}
                                onSelect={country => setBookDetails({...bookDetails, bookAuthor:country.name})}
                                viewOnly={viewOnly}
                                item={bookDetails.bookAuthor}
                            />
                            {!viewOnly && <i className="fa fa-search search-bar-icon" aria-hidden="true"></i>}
                        </div>*/}
                <Form.Control required type="text"  
                value={bookDetails.bookAuthor}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookAuthor:(event.target.value).toUpperCase()})}}
               />

              </Form.Group>
            </Col>
            {/*<Col lg={3} className="mb-3">
            <Form.Group id="authorName">
                <Form.Label>Author Name</Form.Label>
            <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                disabled={viewOnly}
                value={bookDetails.bookAuthor}
                onChange={event=>{setBookDetails({...bookDetails, bookAuthor:event.target.value})}}>
                      {author_details.map((item, index)=>
                  <option value={item.authorName} key={index}>{item.authorName}</option>)}
                  </Form.Select>
                  </Form.Group>
            </Col>*/}
          </Row>
          <Row>
            <Col lg={12} className="mb-3">
              <Form.Group id="bookSubTitle">
                <Form.Label>Book Sub Title</Form.Label>
                <Form.Control required type="text"  
                value={bookDetails.bookSubTitle}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookSubTitle:(event.target.value).toUpperCase()})}}
               />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Form.Group id="publisherName">
                <Form.Label>Publisher Name</Form.Label>
                {/*<div className="search-bar-container">
                            <AutoComplete
                                data={publisher_details}
                                onSelect={country => setBookDetails({...bookDetails, bookPublisherName:country.name})}
                                viewOnly={viewOnly}
                                item={bookDetails.bookPublisherName}

                            />
                  {!viewOnly && <i className="fa fa-search search-bar-icon" aria-hidden="true"></i>}
                </div>*/}
                <Form.Control required type="text"  
                value={bookDetails.bookPublisherName}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookPublisherName:(event.target.value).toUpperCase()})}}
               />

               {/*<Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                disabled={viewOnly}
                value={bookDetails.bookPublisherName}
                onChange={event=>{setBookDetails({...bookDetails, bookPublisherName:(event.target.value)})}}
                    >
                      {publisher_details.map((item, index)=>
                  <option value={item.publisherName} key={index}>{item.publisherName}</option>)}
                  </Form.Select>*/}
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Publisher Location</Form.Label>
                <Form.Control required type="text" 
                value={bookDetails.bookPublisherLocation} disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookPublisherLocation:(event.target.value).toUpperCase()})}}                
                />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Publish Year</Form.Label>
                <Form.Control required type="text" 
                value={bookDetails.bookPublishYear} disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookPublishYear:(event.target.value).toUpperCase()})}}                
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col lg={4} className="mb-3">
              <Form.Group id="bookClassificationNo">
                <Form.Label>Book Classification No</Form.Label>
                <Form.Control required type="text" value={bookDetails.bookClassificationNo}
                    disabled={viewOnly} 
                    onChange={(event)=>{handleCategorySelection(event.target.value)}}
                />

              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="bookCategory">
                <Form.Label>Book Category</Form.Label>
                <Form.Control required type="text"
                disabled
                value={bookDetails.bookCategory}/>
              </Form.Group>
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Group id="bookClassificationNo">
                <Form.Label>Author Mark</Form.Label>
                <Form.Control required type="text" value={bookDetails.authorMark}
                    disabled ={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, authorMark:(event.target.value).toUpperCase()})}}
                />

              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Status</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                disabled={viewOnly}
                value={bookDetails.bookStatus}
                onChange={event=>{setBookDetails({...bookDetails, bookStatus:event.target.value})}} 
                    >
                  <option value="Active">Active</option>
                  <option value="In Active">In Active</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="isbn">
                <Form.Label>ISBN</Form.Label>
                <Form.Control required type="text" value={bookDetails.isbn}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, isbn:(event.target.value).toUpperCase()})}}
                />

              </Form.Group>
            </Col>
          
            <Col lg={3} className="mb-3">
              <Form.Group id="applicantName">
                <Form.Label>Book Price</Form.Label>
                <Form.Control required type="text" value={bookDetails.bookPrice}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, bookPrice:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="bookPages">
                <Form.Label>Book Pages</Form.Label>
                <Form.Control required type="text" value={bookDetails.bookPages}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, bookPages:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col lg={3} className="mb-3">
              <Form.Group id="bookScope">
                <Form.Label>Book Scope</Form.Label>
                <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                disabled={viewOnly}
                value={bookDetails.bookScope}
                onChange={event=>{setBookDetails({...bookDetails, bookScope:event.target.value})}} 
                    >
                  <option value="Circulation">Circulation</option>
                  <option value="E-Books">E-Books</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="bookEdition">
                <Form.Label>Book Edition</Form.Label>
                <Form.Control required type="text" value={bookDetails.bookEdition}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, bookEdition:(event.target.value).toUpperCase()})}}
                />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="bookEdition">
                <Form.Label>Copies</Form.Label>
                <Form.Control required type="number" value={bookDetails.bookCopies}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, bookCopies:event.target.value})}}
                />
              </Form.Group>
            </Col>
            <Col lg={3} className="mb-3">
              <Form.Group id="bookEdition">
                <Form.Label>Book Dimension</Form.Label>
                <Form.Control required type="text" value={bookDetails.bookDimension}
                    disabled={viewOnly} 
                    onChange={(event)=>{setBookDetails({...bookDetails, bookDimension:event.target.value})}}
                />
              </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col lg={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Book Keywords</Form.Label>
                <Form.Control required type="text" 
                value={bookDetails.bookKeywords}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookKeywords:(event.target.value).toUpperCase()})}}
               />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Book Abstract</Form.Label>
                <Form.Control required as="textarea" rows={3} 
                value={bookDetails.bookAbstract}  disabled={viewOnly}
                onChange={event=>{setBookDetails({...bookDetails, bookAbstract:(event.target.value).toUpperCase()})}}
               />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        </Card.Body>
        </Card>
  )
}
